import { createAction, createReducer } from 'redux-act';

import {
  GET_DISCOVERIES_LABELS,
  GET_DISCOVERIES_LABELS_SUCCESS,
  GET_TOP_LABELS,
  GET_TOP_LABELS_SUCCESS,
  GET_INTEL_DISCOVERIES_LABELS,
  GET_INTEL_DISCOVERIES_LABELS_SUCCESS
} from "../actions/_actionsTypes";

export const getDiscoveriesLabels = createAction(GET_DISCOVERIES_LABELS, data => data);
export const getDiscoveriesLabelsSuccess = createAction(GET_DISCOVERIES_LABELS_SUCCESS, (data) => data);

export const getIntelDiscoveriesLabels = createAction(GET_INTEL_DISCOVERIES_LABELS, data => data);
export const getIntelDiscoveriesLabelsSuccess = createAction(GET_INTEL_DISCOVERIES_LABELS_SUCCESS, (data) => data);

export const getTopLabels = createAction(GET_TOP_LABELS, data => data);
export const getTopLabelsSuccess = createAction(GET_TOP_LABELS_SUCCESS, data => data);

export const labelsReducer = createReducer({

  [GET_DISCOVERIES_LABELS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      tagsList: payload.data
    }
  },
  [GET_INTEL_DISCOVERIES_LABELS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      intelTagsList: payload.data
    }
  },
  [GET_TOP_LABELS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      topTagsWithProducts: payload.data
    }
  },
}, {});