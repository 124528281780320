import React from "react";
import { get, chain } from "lodash";
import { durationFormatter } from "../../../../helpers/numbersHelper";
import { Tooltip } from "antd";



const PerformanceEventLine = ({
  width,
  selectedMode,
  subEventHover,
  name,
  selectedEventSubevents,
  groupedEvents,
  data,
}) => {

  //return <h2>33</h2>

  return (
    <div
      style={{
        height: "100%",
        width: width + "%"
      }}
      className="perf-event-line-new"
    >
      {data.representative_sample.sub_events.map((subEvent, index, arr) => {
        const eventStartOffset =
          data.representative_sample.event_start_offset_s;

        let subeventName = subEvent.title;
        const metadataEventName = chain(selectedEventSubevents)
          .find(el => el.name === subEvent.name)
          .get("title")
          .value();
        if (!subeventName) subeventName = metadataEventName;
        const isDotDisplayed = groupedEvents.find(
          el => el.name === subEvent.name
        );

        const isHovered = (get(subEventHover, "name") === subEvent.name && selectedMode)
        if (!isDotDisplayed) {
          return (
            <div
              key={index}
              style={{
                width: `${subEvent.percentage}%`
              }}
              className={`performance-metrics__bars-content-bar-part ${name} no-radius`}
            />
          );
        }

        return (
          <div
            key={index}
            style={{
              width: `${subEvent.percentage}%`,
              zIndex: `${arr.length - index - 1}`
            }}
            className={`performance-metrics__bars-content-bar-part ${name}`}
          >
            {index === arr.length - 1 ? (
              <span className={`part-dot last ${isHovered && "hovered"}`}>
                <div className="part-hover" />
                <span className="inner" />
              </span>
            ) : (
              <Tooltip
                mouseEnterDelay={0}
                mouseLeaveDelay={100}
                open={isHovered && !!isDotDisplayed}
                overlayStyle={{
                  maxWidth:
                    subeventName && subeventName.length > 15
                      ? subeventName.length * 9 + "px"
                      : "130px"
                }}
                title={
                  <>
                    <div>{subeventName}</div>
                    {isDotDisplayed.related.map(subEvent => {

                      return (
                        <div key={subEvent} className="related-subevent">
                          <div>{subEvent ? subEvent.title : ''}</div>
                        </div>
                      );
                    })}
                    <div className="sub-event-value">
                      {subEvent ? subEvent.video_time : ''}
                      <span className="sub-event-sec"> SEC</span>
                    </div>
                  </>
                }
                overlayClassName="bar-subevent-tooltip"
              >
                <span className="part-dot" >
                  <div className="part-hover"></div>
                  <span className={`inner ${isHovered && "hovered"}`}></span>
                </span>
              </Tooltip>
            )}
            {index === arr.length - 1 && (
              <>
                <div
                  className={`event-finished-indicator ${name} ${isHovered &&
                    "hovered"}`}
                >
                  END
                </div>
                <span className="part-time">
                  {
                    durationFormatter(
                      ((subEvent.end_ts_s ||
                        data.representative_sample.event_end_offset_s) -
                        eventStartOffset) *
                      1000,
                      0
                    ).formattedNumberString
                  }
                </span>
              </>
            )}
            {index === 0 && (
              <span className="part-time-start">0s
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
};

function areEqual(prevProps, nextProps) {
  if (prevProps.width !== nextProps.width) {
    return false;
  }
  if (
    nextProps.selectedMode &&
    nextProps.subEventHover &&
    !prevProps.subEventHover
  )
    return false;
  if (
    prevProps.selectedMode &&
    !nextProps.selectedMode &&
    prevProps.subEventHover &&
    !nextProps.subEventHover
  )
    return false;
  if (prevProps.groupedEvents.length !== nextProps.groupedEvents.length)
    return false;

  if (
    prevProps.subEventHover &&
    !nextProps.subEventHover &&
    nextProps.selectedMode
  ) {
    return false;
  }
  if (
    prevProps.subEventHover !== nextProps.subEventHover) { return false }

  return true;
}

export default React.memo(PerformanceEventLine, areEqual);
