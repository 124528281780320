import React, { useState, useEffect } from 'react'

import play_store_icon from "../../assets/images/icons/playstore_icon_big.png"
import app_store_icon from "../../assets/images/icons/appstore_icon_big.png"
import { Tooltip, } from "antd";
import Icon  from "@ant-design/icons";
import { Link } from "react-router-dom";
import NewLoader from "../../common/NewLoader/NewLoader";

import chevron_down_green_ico from "../../assets/images/icons/chevron_down_green.svg"
import chevron_up_red_ico from "../../assets/images/icons/chevron_up_red.svg"
import {isEmpty} from "lodash/lang";

const getZeroState = (platform) => {
  return <div className="empty-state-bench">
    <div className="empty-logo">
      {platform === "Android" && <img src={play_store_icon} />}
      {platform === "iOS" && <img src={app_store_icon} />}
    </div>
    <div className="descr-1">
      There are no {platform} devices <br />
      being actively researched for this app.
    </div>
    <div className="descr-2">
      Please contact your success <br />
      manager for more details.
    </div>
  </div>
}

export default function BenchRanks({
  data,
  deviceModels,
  product,
  locations,
  eventTypes,
  compareRanks,
  ranksLoading,
  categoriesDevices
}) {

  const [showAll, showAllChange] = useState(data ? Object.keys(data).map(dev => ({ [dev]: false })) : [])

  useEffect(() => {
    if (data) {
      showAllChange(Object.keys(data).map(dev => ({ [dev]: false })))
    }
  }, [data])

  if (ranksLoading || !data || !compareRanks) {
    return <div className="product-bench-ranks">
      <h2 className="pr-bench-header">Benchmark Ranks Overview for {product.name}</h2>
      <NewLoader />
    </div>
  }

  const thereIsAndroidApp = categoriesDevices.some(dev => dev.platform === "Android") && product.apps.find(app => app.platform === "Android")
  const thereIsiOSApp = categoriesDevices.some(dev => dev.platform === "iOS") && product.apps.find(app => app.platform === "iOS")

  return (
    <div className="product-bench-ranks">
      <h2 className="pr-bench-header">Benchmark Ranks Overview for {product.name}</h2>

      <div className="pr-bench-content">
        {Object.keys(data).map(d => {
          const location = locations.find(loc => loc.origData.some(someDevice => someDevice[0] === d)).loc
          const os_ver = categoriesDevices.find(device=> device.model === d).os_version;
          const device = deviceModels.find(dev => dev.model === d)
          if (!device) { return }
          const events = data[device.model]
          const app = product.apps.find(a => a.platform === device.platform)
          if (!app) {
            return
          }
          let eventsWithApp = Object.keys(events).filter(e => data[d][e].find(ev => ev.app_id === app.id)).sort((a, b) => {
            if (a === 'launch') {
              return -1
            }
            if (b === 'launch') {
              return 1
            }
            return 0
          });

          let fullCountEvents = eventsWithApp.length
          if (!showAll[d] && eventsWithApp.length > 4) {
            eventsWithApp = eventsWithApp.slice(0, 4)
          }
          return <div className={`pr-b-section pr-b-section-${device.platform.toLowerCase()}`} key={d}>
            <h3>
              {device.platform === "Android" && <img src={play_store_icon} />}
              {device.platform === "iOS" && <img src={app_store_icon} />}
              <span className="plat">{device.platform}</span>
              ({device.product_name})
            </h3>
            {eventsWithApp.length > 0 ?
              <div className="grid-events">{eventsWithApp.map((ev, i) => {

                const event = eventTypes.find(evT => ev === evT.name)
                const rec = events[ev].find(e => e.app_id === app.id)
                const recIndex = events[ev].findIndex(e => e.app_id === app.id)
                if (!rec) { return null }
                let color
                if (recIndex / events[ev].length < 0.25) {
                  color = 'green'
                } else if (recIndex / events[ev].length < 0.75) {
                  color = 'orange'
                } else {
                  color = 'red'
                }

                const cmpr = compareRanks.find(cr => cr.segment.app_id === app.id && cr.segment.event_name === ev)

                let diff = 0;
                let diffPerc = 1;
                if (cmpr && cmpr['1st'] && cmpr['2nd']) {
                  diff = cmpr['2nd'].duration_s - cmpr['1st'].duration_s;
                  diffPerc = cmpr['2nd'].duration_s / cmpr['1st'].duration_s;
                }

                return <Link to={`/benchmark/ranks?model_in=${d}&loc_in=${location}&event_in=${ev}&category_in=&os_version_in=${os_ver}`} key={ev}>
                  <div className={`ev-section `}>
                    <div className="header-wrap" >
                      <h3>
                        {event.title}
                      </h3>
                      <Tooltip placement="top" title={event.description} >
                        <Icon type="info-circle" className="ranks-filter-info" />
                      </Tooltip>
                    </div>
                    <div className="content-wrap">
                      <div className="rank-wrap">
                        <div className={`rank-value ${color}`}>
                          <span className="sharp">#</span>
                          {recIndex + 1}
                          <div className="extra-info">Out Of <strong>{events[ev].length}</strong> Apps</div>
                        </div>
                        <div className="rank-text">App Rank On Event</div>
                      </div>
                      <div className="time-wrap">
                        <div className="time-value">
                          {rec.duration_s.toFixed(1)}
                          <span className="sec">sec</span>
                          {Math.abs(diff) > 0.02 ? <div className="extra-info">
                            <span className={`${diff > 0 ? 'red' : 'green'}`}>{diff.toFixed(2)}s</span>
                            <img src={diff > 0 ? chevron_up_red_ico : chevron_down_green_ico} className="chevron" />
                            <span className="perc">({diffPerc.toFixed(2)}%)</span>
                          </div> :
                            <div className="extra-info">
                              <span>-</span>
                            </div>
                          }
                        </div>
                        <div className="time-text">Event Duration</div>
                      </div>
                    </div>
                  </div>
                </Link>
              })}</div>
              :
              getZeroState(device.platform)
            }

            {!showAll[d] && fullCountEvents > 4 &&
              <div className="show-more-events" onClick={() => showAllChange({ ...showAll, [d]: true })}>
                +{fullCountEvents - 4} More Events
              </div>}
          </div>
        })}
        {(!thereIsAndroidApp || isEmpty(data)) && <div className={`pr-b-section pr-b-section-android`}>
          <h3>
            <img src={play_store_icon} />
            <span className="plat">Android</span>
          </h3>
          {getZeroState('Android')}
        </div>}
        {(!thereIsiOSApp || isEmpty(data)) && <div className={`pr-b-section pr-b-section-ios`}>
          <h3>
            <img src={app_store_icon} />
            <span className="plat">iOS</span>
          </h3>
          {getZeroState('iOS')}
        </div>}
      </div>
    </div>
  )
}
