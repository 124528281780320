import React from 'react'
import { connect } from 'react-redux'
import { updateViewportMeta } from 'shared/mobile-fix';


const ChangePageMonitor = (data) => {
  if (data.history.action === "PUSH") {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }
  if (data.analytics) {
    data.analytics.analytic(`Change page`, {Target: data.location.pathname, searchParams: data.location.search});
  }
  updateViewportMeta();
  return null
};

const mapStateToProps = ({ appData }) => ({
  analytics: appData.user.analytics
})
export default connect(mapStateToProps, {})(ChangePageMonitor)
