import React, { Component } from "react";

import moment from "moment"
import { Tabs, Button } from 'antd';
import Icon from "@ant-design/icons";
import Slider from "react-slick";



import ViewExperimentImage from '../viewExperiment/viewExperimentImage/ViewExperimentImage';
import ViewExperimentText from '../viewExperiment/viewExperimentText/ViewExperimentText'
import ModalBroadwayTheater from "../ModalBroadwayTheater/ModalBroadwayTheater"

import ViewExperimentArrowSlick from "../viewExperiment/viewExperimentArrowSlick/ViewExperimentArrowSlick";

import { Consts } from "../../../shared/consts";


import control_icon from "../../../assets/images/icons/control-icon.svg"


const { TabPane } = Tabs;

const slickSettings = {
  dots: true,
  speed: 200,
  infinite: false,
  slidesToScroll: 1,
  adaptiveWidth: true,
  nextArrow: <ViewExperimentArrowSlick direction="next" page="marketing" />,
  prevArrow: <ViewExperimentArrowSlick direction="prv" page="marketing" />
};

class ViewExperimentTabs extends Component {
  constructor(props) {
    super(props);

    const { ABTestData, insight } = this.props;

    let tab = ''
    if (ABTestData && ABTestData.status === 'integrated' && insight.variants.find(v => v.id === ABTestData.champion_variant_id)) {
      tab = String(ABTestData.champion_variant_id)
    } else if (insight.type === 'ab_test' && insight.variants.find(v => !v.control)) {
      tab = String(insight.variants.find(v => !v.control).id)
    }

    this.state = {
      modalIsOpen: false,
      modalImagesUrl: [],
      modalImagesType: '',
      isMobile: false,
      curTab: tab,
      zoomOptions: {
        enable: false,
        x: 0,
        y: 0
      },
      currentModalSlides: {
        'left': 0,
        'right': 0,
      },
      removeHighlights: false,
    };
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.handleWindowSizeChange);
    this.handleWindowSizeChange();
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ isMobile: window.innerWidth <= Consts.mobileSize });
  };

  openModal = () => {
    this.setState({
      modalIsOpen: true
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
      zoomOptions: {
        enable: false,
        x: 0,
        y: 0
      }
    });
  };

  imageClick = () => {
    const { firstHeader } = this.state;
    if (firstHeader !== "") {
      this.setState({
        modalIsOpen: true,
      });
    }
  };

  commonZoomChanged = (enable, x, y) => {
    this.setState({
      zoomOptions: {
        enable,
        x,
        y
      }
    })
  }

  changeRemoveHighlights = (forceState) => {
    this.setState({
      removeHighlights: forceState !== undefined ? forceState : !this.state.removeHighlights
    })
  }

  render() {
    const {
      modalIsOpen,
    } = this.state;
    const { insight, reachRate, ABTestData, isBlurred } = this.props;

    let typeDefaultState;
    if (insight.type === "ab_test") {
      typeDefaultState = ['ab_off', 'current']
    } else {
      typeDefaultState = ['previous']
    }


    let controlImgs, nonControlImgs

    if (insight.type === "ab_test") {
      controlImgs = insight.variants.filter(v => v.control).reduce((acc, el) => [...acc, ...el.assets], [])
      nonControlImgs = insight.variants.filter(v => !v.control).reduce((acc, el) => [...acc, ...el.assets], [])
    } else {
      controlImgs = insight.assets.filter(img => typeDefaultState.includes(img.type))
      nonControlImgs = insight.assets.filter(img => !typeDefaultState.includes(img.type))
    }

    if ((nonControlImgs.length === 0 || controlImgs.length === 0) && (nonControlImgs.length + controlImgs.length > 1) && window.innerWidth > 520) {
      slickSettings.slidesToShow = 2
    } else {
      slickSettings.slidesToShow = 1
    }
    const reachRateDates = reachRate && Object.keys(reachRate)
    let reachRateToShow = reachRate && nonControlImgs[0] && nonControlImgs[0].varientValue && nonControlImgs.map(nonControlImg => {
      const reachRateTemp = reachRate[reachRateDates[reachRateDates.length - 1]]

      return reachRateTemp && reachRateTemp[nonControlImg.varientValue]
    });
    const controlReachRate = controlImgs[0] && reachRate[reachRateDates[reachRateDates.length - 1]] && reachRate[reachRateDates[reachRateDates.length - 1]][controlImgs[0].varientValue]

    reachRateToShow = reachRateToShow ? reachRateToShow : [0];

    // 1. by default take reach rate of control.
    // 2. if it doesn't exist take the remainder of other variants.
    // 3. if even that doesn't exist then control reach rate is just zero.

    const getInitSlide = (modal, sliderKey) => {
      return this[sliderKey] ? this[sliderKey] : 0
    }
    const setInitSlide = (modal, sliderKey, slIndex) => {
      this[sliderKey] = slIndex
    }


    const checkEnableHighlights = () => {
      const curSlides = this.state.currentModalSlides
      return (controlImgs[curSlides.left]?.has_highlights && controlImgs[curSlides.left]?.url_blurred) ||
        (nonControlImgs[curSlides.right]?.url_blurred && nonControlImgs[curSlides.right]?.has_highlights)

    }



    const changeModalSlide = (side, slide) => {
      this.setState({
        currentModalSlides: {
          ...this.state.currentModalSlides,
          [side]: slide
        }
      })
      this.changeRemoveHighlights(false)
    }

    const getHighlightButton = (modal) => modal ?
      checkEnableHighlights() ?
        <div className='highlights-btn' onClick={() => this.changeRemoveHighlights()}>
          <Button>
            {this.state.removeHighlights ? 'Show Highlights' : 'Hide Highlights'}
          </Button>
        </div>
        :
        <div className='highlights-btn disable' >
          <Button disabled={true}>
            Hide Highlights
          </Button>
        </div>
      : null

    const renderTabs = (modal) => <div
      className={`view__experiment-tabs ${isBlurred ? 'blurred' : ''}`}
    >

      {/* //LEFT */}
      {controlImgs && controlImgs.length > 0 &&

        <div>
          {modal && <img className="cover-size" src={[...controlImgs, ...nonControlImgs].find(a => [a.asset_type, a.format].includes('image'))?.url_annotated} />}
          <div className={`control-image ${slickSettings.slidesToShow === 2 ? 'long' : ''} 
          ${(controlImgs[0].orientation === 'portrait' && nonControlImgs.length > 0 && nonControlImgs[0].orientation === 'landscape') ||
          (controlImgs[0].orientation === 'landscape' && nonControlImgs.length > 0 && nonControlImgs[0].orientation === 'portrait') ? 'different-orientations' : ''}`}>
            {insight.type === "ab_test" && <><h5>Current</h5>
              <h2>
                <img src={control_icon} />
                Control
              </h2>
            </>}
            {["screen_change", "new_feature", "removed_feature"].includes(insight.type) &&
              <h2>Previous</h2>
            }
            {['indication'].includes(insight.type) &&
              <h2>Current Experience</h2>
            }

            {/* //LEFT AB TEST*/}


            {insight.type === "ab_test" ?
              <div className={`slider-wrapper slider-count-${insight.variants.find(v => v.control).assets.length}`}>
                <Slider
                  {
                    ...{
                      ...slickSettings,
                      initialSlide: getInitSlide(modal, `abTestSl-L`),
                      ...(modal ? { afterChange: (current) => { changeModalSlide('left', current) } } : {})

                    }
                  }
                  key={`abTestSl-L-${modal ? 'modal' : ''}`}
                  beforeChange={(oldIndex, slIndex) => setInitSlide(modal, 'abTestSl-L', slIndex)}
                >
                  {insight.variants.find(v => v.control).assets.map((img, i) =>
                    (
                      <>
                        <ViewExperimentImage key={i}
                          onClick={() => this.imageClick()}
                          commonZoomChanged={this.commonZoomChanged}
                          commonZoom={this.state.zoomOptions}
                          imageSrc={this.state.removeHighlights && img.url_blurred && modal ? img.url_blurred : img.url_annotated}
                          blurredImg={img.url_blurred}
                          videoSrc={img.url_annotated}
                          type={img.format}
                          deviceOrientation={img.orientation}
                          modal={modal}
                          currentModalSlides={this.state.currentModalSlides}
                        />
                        <ViewExperimentText text={img.text} />
                      </>
                    ))}
                </Slider>
              </div>
              :
              <div className={`slider-wrapper slider-count-${controlImgs.length}`}>
                <Slider
                  {
                    ...{
                      ...slickSettings,
                      initialSlide: getInitSlide(modal, `NonAbTestSl-L`),
                      ...(modal ? { afterChange: (current) => { changeModalSlide('left', current) } } : {})

                    }
                  }
                  key={`NonAbTestSl-L-${modal ? 'modal' : ''}`}
                  beforeChange={(oldIndex, slIndex) => setInitSlide(modal, 'NonAbTestSl-L', slIndex)}
                >
                  {/* //LEFT NON  AB TEST*/}
                  {controlImgs && controlImgs.map((img, i) =>
                    (
                      <>
                        <ViewExperimentImage key={i}
                          onClick={() => this.imageClick()}
                          commonZoomChanged={this.commonZoomChanged}
                          commonZoom={this.state.zoomOptions}
                          imageSrc={this.state.removeHighlights && img.url_blurred && modal ? img.url_blurred : img.url_annotated}
                          videoSrc={img.url_annotated}
                          blurredImg={img.url_blurred}
                          type={img.format}
                          deviceOrientation={img.orientation}
                          modal={modal}
                          currentModalSlides={this.state.currentModalSlides}
                        />
                        <ViewExperimentText text={img.text} />
                      </>
                    ))}
                </Slider>
              </div>
            }


          </div>
        </div>}


      {/* //RIGHT */}
      {
        nonControlImgs && nonControlImgs.length > 0 &&
        <div>
          {modal && <img className="cover-size" src={[...nonControlImgs, ...controlImgs].find(a => [a.asset_type, a.format].includes('image'))?.url_annotated} />}
          <div className={`variants-images ${slickSettings.slidesToShow === 2 ? 'long' : ''} status-${ABTestData ? ABTestData.status : ''}
     variant-${ABTestData ? insight.variants.filter(v => !v.control).findIndex(v => v.id === ABTestData.champion_variant_id) : ''} ${controlImgs.length === 0 ? 'no-control' : ''}
      ${ABTestData && ABTestData.status === "integrated" &&
              this.state.curTab === String(ABTestData.champion_variant_id) ? 'cur-tab-integr' : ''}`}>

            {/* {Object.keys(reachRate)[0] && !isNaN(reachRateToShow[0]) && <ReachRate variant={'Variant ' + this.state.curTab} rate={reachRateToShow[0]} reverse={true} />} */}



            {insight.type === "ab_test" && <h5>Variants</h5>}

            {["screen_change", "new_feature", "removed_feature"].includes(insight.type) && <h2 className="control-image-h2">Current</h2>}

            {["indication"].includes(insight.type) && (nonControlImgs.length > 0)
              && <h2 className="control-image-h2">Experience In-Development</h2>}

            {/* //RIGHT ABTEST */}
            {insight.type === "ab_test" && <Tabs activeKey={this.state.curTab} className="tabs" animated={{ inkBar: true, tabPane: false }} onChange={(tab) => this.setState({ curTab: tab })}>

              {insight.variants.filter(v => !v.control).map((v, index) => (
                <TabPane tab={`Variant ${v.type || ''}`}
                  key={v.id} >
                  <div className={`slider-wrapper slider-count-${v.assets.length}`}>
                    <Slider {
                      ...{
                        ...slickSettings,
                        initialSlide: getInitSlide(modal, `abTestSl-R-V${index}`),
                        ...(modal ? { afterChange: (current) => { changeModalSlide('right', current) } } : {})

                      }
                    }
                    key={`abTestSl-R-V${index}-${modal ? 'modal' : ''}`}
                    beforeChange={(oldIndex, slIndex) => setInitSlide(modal, `abTestSl-R-V${index}`, slIndex)}
                    >
                      {v.assets.map((img, i) => (<>
                        <ViewExperimentImage key={i}
                          onClick={() => this.imageClick()}
                          commonZoomChanged={this.commonZoomChanged}
                          commonZoom={this.state.zoomOptions}
                          imageSrc={this.state.removeHighlights && img.url_blurred && modal ? img.url_blurred : img.url_annotated}
                          videoSrc={img.url_annotated}
                          blurredImg={img.url_blurred}
                          type={img.format}
                          data-typeid="view-experiment"
                          deviceOrientation={img.orientation}
                          modal={modal}
                          currentModalSlides={this.state.currentModalSlides}
                        />
                        {img.text && <ViewExperimentText text={img.text} />}
                      </>))}
                    </Slider>
                  </div>
                </TabPane>
              ))}

            </Tabs>}

            {/* //RIGHT NON - ABTEST */}
            {["screen_change", "new_feature", "indication", "removed_feature"].includes(insight.type) &&
              <div className={`slider-wrapper slider-count-${nonControlImgs.length}`}>
                <Slider
                  {
                    ...{
                      ...slickSettings,
                      initialSlide: getInitSlide(modal, `NonAbTestSl-R`),
                      ...(modal ? { afterChange: (current) => { changeModalSlide('right', current) } } : {})
                    }
                  }
                  key={`NonAbTestSl-R-${modal ? 'modal' : ''}`}
                  beforeChange={(oldIndex, slIndex) => setInitSlide(modal, 'NonAbTestSl-R', slIndex)}
                >
                  {nonControlImgs.map((img, i) => (<>
                    <ViewExperimentImage key={i}
                      onClick={() => this.imageClick()}
                      commonZoomChanged={this.commonZoomChanged}
                      commonZoom={this.state.zoomOptions}
                      imageSrc={this.state.removeHighlights && img.url_blurred && modal ? img.url_blurred : img.url_annotated}
                      videoSrc={img.url_annotated}
                      blurredImg={img.url_blurred}
                      type={img.format}
                      data-testid="view-experiment"
                      deviceOrientation={img.orientation}
                      modal={modal}
                      currentModalSlides={this.state.currentModalSlides}
                    />
                    {img.text && <ViewExperimentText text={img.text} />}
                  </>))}
                </Slider>
              </div>
            }
          </div>
        </div>
      }
      {getHighlightButton(modal)}
    </div>

    return (
      <>
        {ABTestData && ABTestData.status === "integrated" && insight.variants.filter(v => !v.control).length > 1 && <div className="block-status-descr integrated">
          <Icon type="check-circle" theme="filled" />
          <h3>Experiment Integrated - </h3>

          <span className="text-descr">
            Variant {ABTestData.champion_variant_id && insight.variants.find(v => v.id === ABTestData.champion_variant_id)
              ? insight.variants.find(v => v.id === ABTestData.champion_variant_id).type : ''} was fully released</span>

          {insight.end_time &&
            <span>, {moment(insight.end_time).diff(moment(insight.release_date), 'days')} days after being introduced.</span>
          }
        </div>}

        {ABTestData && ABTestData.status === "abandoned" && <div className="block-status-descr abandoned">
          <Icon type="close-circle" theme="filled" />
          <h3>Experiment Abandoned - </h3>
          <span className="text-descr"> No longer being tested</span>
          {insight.end_time &&
            <span>, {moment(insight.end_time).diff(moment(insight.release_date), 'days')} days after being introduced.</span>
          }
        </div>}
        {!modalIsOpen && renderTabs(false)}
        {modalIsOpen && <ModalBroadwayTheater
          modalIsOpen={modalIsOpen}
          closeModal={this.closeModal}
          platform={this.props.app.metadata.platform}
          removeHighlights={this.state.removeHighlights}
          changeRemoveHighlights={() => this.changeRemoveHighlights()}
          content={renderTabs(true)}
        />}
      </>
    );
  }
}

export default ViewExperimentTabs;
