import React, { useCallback, useState, useEffect } from "react";
import awsService from "../../../../services/awsService";
import { Button, Form } from "antd";
import { InputText } from "../../../../components/inputText/InputText";

let _intervalId = null;

export const ConfirmPasswordView =
  ({ form, next, username, setConfirmationCode, ...props }) => {
    const [loading, setLoading] = useState(false);
    const [counter, setCounter] = useState(0);

    const handleSubmit = useCallback(
      values => {

        setLoading(true);

        // TODO: check for correct activation code
        setConfirmationCode(values.confirmation);
        next();
      },
      [next, setConfirmationCode]
    );

    useEffect(() => {
      return () => {
        // Clear interval if button to proceed is clicked
        // while stopwatch is running
        if (_intervalId) clearInterval(_intervalId);
      };
    }, []);
    const handleSendConfirmationAgain = useCallback(() => {
      const aws = new awsService();
      setLoading(true);
      setCounter(5);
      _intervalId = setInterval(() => {
        setCounter(counter => {
          if (counter < 0) {
            clearInterval(_intervalId);
          }
          return counter - 1;
        });
      }, 1000);

      setLoading(false);
      aws.forgotPassword(username).finally(() => { });
    }, [username]);

    return (
      <div className="confirm-password-view">
        <div className="confirm-password-view__text">
          We sent a verification code to your mail
        </div>

        <div className="confirm-password-view__text-extra">
          Please enter the code here:
        </div>
        <Form className="confirm-password-view__form" name="ConfirmPasswordForm" onFinish={handleSubmit}>
          <Form.Item name="confirmation" rules={[{ required: true, message: "Please enter code" }]}>
            <InputText
              label="Verification Code"
              placeholder="Verification Code"
            />
          </Form.Item>
          <Button block loading={loading} type="primary" htmlType="submit">
            Reset Password
          </Button>
        </Form>
        <p className="confirm-password-view__send-again-text">
          {counter <= 0 ? (
            <>
              Didn’t receive a mail?{" "}
              <span
                className="confirm-password-view__send-again-text-btn"
                onClick={handleSendConfirmationAgain}
              >
                Send Again
              </span>
            </>
          ) : (
            <>You can try again in {counter}</>
          )}
        </p>
      </div>
    );
  }
