import React, { useCallback, useState, useRef, useEffect } from "react";
import { Line } from "react-chartjs-2";
import moment from "moment";

import { OverviewAnalyzedVersion } from "./OverviewAnalyzedVersion";
import { connect } from "react-redux";
import { compose } from "redux";
import { analyzedVersionsSelector } from "./overview.selectors";
import { Row, Col, Tooltip, Select, Dropdown } from "antd";

import { withRouter } from "react-router-dom";
import FilterSelectBox from "../../../components/FiltersSelectBox/index"
import NewLoader from "../../../common/NewLoader/NewLoader";

import black_chevron_ico from "../../../assets/images/icons/chevron_black.svg"

import { STATE_LABEL } from '../../products-discoveries/versionsSlider/VersionsSlider'

import Slider from "react-slick";
import ViewExperimentArrowSlick from "../../../components/discoveryView/viewExperiment/viewExperimentArrowSlick/ViewExperimentArrowSlick";
import "@lottiefiles/lottie-player";
import { getBeingAnalyzedVersions, getCompletedVersionsWithDiscoveries } from "../../../redux/acts/overview"

import VersionsListHeader from "../../products-discoveries/versionsList/VersionsListHeader/VersionsListHeader"
import defaultLogo from "../../../assets/images/watchful_icon_gray.svg"

const CHART_WIDTH = window.innerWidth > 1025 ? 650 : window.innerWidth - 350
const CHART_HEIGHT = 228

const now = new Date().getUTCFullYear();
const years = Array(now - (now - 5)).fill('').map((v, idx) => now - idx);
const fullMonths = moment.months()

const { Option, OptGroup } = Select

const OverviewAnalyzedVersions = ({
  analyzedVersions,
  versions,
  history,
  apps,
  ownApps,
  getBeingAnalyzedVersions,
  getCompletedVersionsWithDiscoveries,
  relevantInsightsByApps
}) => {
  const slider = useRef();
  let coordsGetted = false;

  const [currentDate, currentDateChange] = useState(moment())
  const [locations, setLocations] = useState([]);
  const [visibleVersions, visibleVersionsChange] = useState(null)
  const [typeTimeRange, typeTimeRangeChange] = useState('presets')
  const [prevDays, prevDaysChange] = useState(-30)

  const slickSettings = {
    dots: false,
    speed: 500,
    infinite: false,
    variableWidth: true,
    slidesToScroll: 1,
    slidesToShow: window.innerWidth > 1025 ? 5 : window.innerWidth > 790 ? 4 : 3,
    nextArrow: <ViewExperimentArrowSlick page="overview" direction="next" slider={slider} />,
    prevArrow: <ViewExperimentArrowSlick page="overview" direction="prv" slider={slider} />
  };

  const getCoords = ctx => {
    if (coordsGetted && visibleVersions && visibleVersions.length == locations.length) { return }
    const _meta = ctx.controller.data.datasets[0]._meta;
    const meta = _meta[Object.keys(_meta)[0]];
    if (meta) {
      const positions = meta.data.reduce((acc, pos) => {
        // We must add offset by x and change z for apps with the same coords
        // We must add shadow for apps, except single/last element
        const sameXY = acc.filter(el => el._x === pos._model.x && el.y === pos._model.y)
        if (sameXY[sameXY.length - 1]) {
          sameXY[sameXY.length - 1].last = false;
        }

        const coors = {
          _x: pos._model.x,
          x: pos._model.x + (sameXY.length * 15),
          y: pos._model.y,
          z: sameXY.length,
          last: true
        }
        return [...acc, coors]
      }, [])
      setLocations(positions);
      coordsGetted = true;
    }
  }

  useEffect(() => {
    if (!analyzedVersions) {
      getBeingAnalyzedVersions({ days_ago: 14 })
    }
  }, [])

  useEffect(() => {
    if (versions && versions.length) {
      visibleVersionsChange(versions.filter(el => el.is_visible || el.is_customer_own_app) || [])
    } else if (versions && versions.length == 0) {
      visibleVersionsChange([])
    }
  }, [versions])

  useEffect(() => {
    setLocations([])
    visibleVersionsChange(null)
    requestGetCompletedVersionsWithDiscoveries()
  }, [currentDate, prevDays, typeTimeRange])

  const requestGetCompletedVersionsWithDiscoveries = () => {
    //debugger
    if (typeTimeRange === 'presets') {
      getCompletedVersionsWithDiscoveries({
        from_date: moment().add(prevDays, 'days').format('YYYY-MM-DD'),
        until_date: moment().add(prevDays + 30, 'days').format('YYYY-MM-DD')
      })
    } else if (typeTimeRange === 'months') {
      getCompletedVersionsWithDiscoveries({
        from_date: moment(currentDate).date(1).format('YYYY-MM-DD'),
        until_date: moment(currentDate).add(1, 'month').date(1).format('YYYY-MM-DD')
      })
    }

  }

  const handleClick = useCallback(
    (selected, app) => {
      history.push(`/intelligence/versions/${app.metadata.id}/${selected.id}`);
    },
    [history]
  );

  const handleClickDiscovery = useCallback(
    (selected, app, disID) => {
      history.push(`/intelligence/versions/${app.metadata.id}/${selected.id}/${disID}`);
    },
    [history]
  );

  const getDateLabels = useCallback(() => {
    if (typeTimeRange === 'presets') {
      const daysBefore = 30;
      const dates = [...Array(daysBefore)].map((el, ind) => {
        return moment(moment().add(prevDays + 30, 'days')).subtract(ind, "days");
      });
      return dates.reverse().map(date => date.format("DD/MM"));
    } else if (typeTimeRange === 'months') {
      return Array.from(Array(moment(currentDate).daysInMonth()).keys()).map(d => moment(currentDate).date(d + 1).format('DD/MM'))
    }
  }, [currentDate, prevDays, typeTimeRange]);

  const handleMeaningfulDescr = (criticalDiscoveries, version, e) => {
    if (criticalDiscoveries.length > 1) {
      history.push(`/intelligence/versions/${version.app_id}/${version.id}/`);
    } else {
      history.push(`/intelligence/versions/${version.app_id}/${version.id}/${criticalDiscoveries[0].id}`);
    }
    e.stopPropagation()
  }

  const criticalDiscoveriesByApps = {};
  Object.keys(relevantInsightsByApps).forEach(appID => {
    criticalDiscoveriesByApps[appID] = relevantInsightsByApps[appID].filter(dis => {
      if (typeTimeRange === 'presets') {
        return dis.relevancy > 0 && moment(dis.start_time).isBetween(moment().add(prevDays, 'days').format('YYYY-MM-DD'),
          moment().add(prevDays + 30, 'days').format('YYYY-MM-DD'))
      } else if (typeTimeRange === 'months') {
        return dis.relevancy > 0 && moment(dis.start_time).isBetween(moment(currentDate).date(1).format('YYYY-MM-DD'),
          moment(currentDate).add(1, 'month').date(1).format('YYYY-MM-DD'))
      }

    })
  })

  return (
    <div>
      <div className="overview-page__analyzed-versions">
        {!visibleVersions && <div className="all-dis-loader">
          <NewLoader />
        </div>}
        <div className="prev-next-buttons">
          <ViewExperimentArrowSlick page="new-marketing" direction="prev" className="new-marketing"
            onClick={() => {
              if (typeTimeRange === "months") {
                let nD = moment(currentDate).subtract(1, 'month')
                currentDateChange(nD)
              } else if (typeTimeRange === "presets") {
                prevDaysChange(prevDays - 30)
              }
            }} />


          {typeTimeRange === "months" && moment(currentDate).day(0).add(1, 'month').isBefore(moment()) && <ViewExperimentArrowSlick page="new-marketing" direction="next" className="new-marketing"
            onClick={() => {
              let nD = moment(currentDate).add(1, 'month')
              currentDateChange(nD)
            }}
          />}
          {typeTimeRange === "presets" && prevDays < -30 && <ViewExperimentArrowSlick page="new-marketing" direction="next" className="new-marketing"
            onClick={() => {
              prevDaysChange(prevDays + 30)
            }}
          />}

        </div>
        <div className="dates-selectbox">


          <FilterSelectBox
            className="simple sorting-select month-selectbox"
            testid="month-selectbox"
            style={{ marginRight: '1rem' }}
            value={typeTimeRange === "months" ? currentDate.format('MMM') : prevDays === -30 ? -30 : 'DATE RANGE'}
            onChange={month => {
              if (month === -30) {
                typeTimeRangeChange('presets')
                prevDaysChange(-30)
              } else {
                typeTimeRangeChange('months')
                let nD = moment(currentDate).month(month)
                currentDateChange(nD)
              }

            }}
            suffixIcon={
              <img
                className={`chevron`}
                src={black_chevron_ico}
                alt="chevron"
              />
            }
          >
            <OptGroup label="Presets">
              <Option value={-30} key={-30}>Last 30 Days</Option>
            </OptGroup>
            <OptGroup label="Months">
              {moment.monthsShort().filter(m => moment(currentDate).month(m).isBefore(moment())).map((m, i) => (
                <Option value={m} key={i} data-testid={`month-${i}`}>{fullMonths[i].toUpperCase()}</Option>
              ))}
            </OptGroup>

          </FilterSelectBox>


          <FilterSelectBox
            value={typeTimeRange === "months" ? currentDate.format('YYYY') : 'YEAR'}
            className="simple sorting-select year-selectbox"
            disabled={typeTimeRange !== "months"}
            onChange={year => {
              let nD = moment(currentDate).year(year)
              if (year === moment().year() && nD.month() > moment().month()) {
                nD.month(moment().month())
              }
              typeTimeRangeChange('months')
              currentDateChange(nD)
            }}
            suffixIcon={
              <img
                className={`chevron`}
                src={black_chevron_ico}
                alt="chevron"
              />
            }
          >
            {years.map((y, i) => (
              <Option value={y} key={i}>{y}</Option>
            ))}
          </FilterSelectBox>
        </div>
        <div className="chart-container">
          <Line
            width={CHART_WIDTH}
            height={CHART_HEIGHT}
            data={{
              labels: getDateLabels(),
              datasets: [
                {
                  pointRadius: 0,
                  pointBackgroundColor: "transparent",
                  pointHoverRadius: 10,
                  pointRotation: false,
                  data: visibleVersions || []
                }
              ]
            }}
            plugins={[
              {
                afterRender: getCoords,
                resize: () => { coordsGetted = false }
              }
            ]}
            options={{
              scales: {
                yAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      labelString: "Discoveries                                             ",
                      fontColor: "#1A1818",
                      fontSize: 12,
                    },
                    ticks: {
                      callback: function (value, index, values) {
                        const val = Number(value)
                        return Math.floor(val) === val ? value : null;
                      },
                      min: 0
                    }
                  }
                ],
                xAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      labelString: "Release Date                                                                                                                                                                                                                                         ",
                      fontColor: "#1A1818",
                      fontSize: 12
                    }
                  }
                ]
              },
              tooltips: {
                enabled: false
              },
              showLines: false,
              legend: {
                display: false
              }
            }}
          />
          {locations.map((location, ind) => {
            const selected = visibleVersions[ind];
            if (!selected) { return null }
            const app = apps.find((app) => app.metadata.id === selected.app_id) ||
              ownApps.find((app) => app.metadata.is_customer_own_app && app.metadata.id === selected.app_id)
            if (!app) { console.log(`There isn't app with ${selected.id}`); return null }
            const releaseDate = selected.release_date
            let discoveriesByTypes = {}, moreDiscoveries = 0
            if (selected.insights && selected.insights.length > 0) {
              selected.insights.slice(0, 3).forEach(ins => {
                if (!discoveriesByTypes[ins.type]) { discoveriesByTypes[ins.type] = [] }
                discoveriesByTypes[ins.type].push(ins)
              });
              if (criticalDiscoveriesByApps[app.metadata.id]) {
                Object.keys(discoveriesByTypes).forEach(type => {
                  discoveriesByTypes[type].sort((a, b) => criticalDiscoveriesByApps[app.metadata.id].find(dis => dis.id == a.id) ? -1 : 1)
                })
              }

              moreDiscoveries = selected.insights.length - 3
            }

            return (
              <Tooltip key={`${location.x}-${location.y}`}
                overlayClassName="version-tooltip"
                // placement="topLeft"
                title={
                  <div className="versions-tooltip-wrapper">
                    <div className={`app-version-data ${selected.is_customer_own_app ? 'own-app' : ''}`} onClick={!selected.is_customer_own_app ? () => { handleClick(selected, app) } : ""}>
                      <Row type="flex" gutter={9} align="middle">
                        <Col>
                          <img
                            src={app.metadata.icon}
                            width="30"
                            height="30"
                            alt="ic"
                          />
                        </Col>
                        <Col>
                          <div className="title">{app.metadata.display_name}</div>
                          <div className="date">
                            {moment(releaseDate).format("DD MMM YYYY")}
                          </div>

                        </Col>
                      </Row>
                      <div className="version-platform">
                        <div>
                          <span className={`platform app-platform-${app.metadata.platform.toLowerCase()}`}>
                            {app.metadata.platform}
                          </span>
                        </div>
                        <div className="version">Ver. {app.metadata.platform === 'Web' ? moment(releaseDate).format("MM.DD.YYYY") : selected.release_name}</div>
                      </div>
                      {!selected.is_customer_own_app && <div className="status-insigths">
                        <div className={`state state-${selected.state}`}>
                          <span>
                            {STATE_LABEL[selected.state] || selected.state}
                          </span>
                        </div>
                        <div className="insights">
                          {selected.y} {selected.y === 1 ? `discovery` : `discoveries`}
                        </div>
                      </div>
                      }
                      {!selected.is_customer_own_app && criticalDiscoveriesByApps[selected.app_id] && criticalDiscoveriesByApps[selected.app_id].length > 0 &&
                        criticalDiscoveriesByApps[selected.app_id].find(dis => dis.release_id == selected.id) &&
                        <div className="critical-discoveries-cnt"
                          onClick={(e) => handleMeaningfulDescr(criticalDiscoveriesByApps[selected.app_id], selected, e)}>
                          <div className={`importance-indicator importance-indicator-animating`}/>
                          Meaningful Discoveries
                        </div>
                      }
                    </div>
                    {!selected.is_customer_own_app && Object.keys(discoveriesByTypes).length > 0 && <div className="versions-discoveries-by-type">
                      {Object.keys(discoveriesByTypes).map((type, i) => <div key={i}>
                        <VersionsListHeader type={`${type}s`} data={discoveriesByTypes[type]} count={discoveriesByTypes[type].length} />
                        <div className="discoveries-by-type-wrapper">

                          {discoveriesByTypes[type].map((disByType, j) => <div key={j} className="discoveries-by-type"
                            onClick={() => handleClickDiscovery(selected, app, disByType.id)}>
                            {criticalDiscoveriesByApps[app.metadata.id] &&
                              criticalDiscoveriesByApps[app.metadata.id].find(ins => ins.id === disByType.id) &&
                              <div className={`importance-indicator importance-indicator-animating`}/>}
                            <div className="discoveries-by-type-title">{disByType.title}</div>
                            <div className={`discoveries-by-type-image ${disByType.thumbnail ? '' : 'def'}`}>
                              <img src={disByType.thumbnail || defaultLogo} />
                            </div>
                          </div>)}
                        </div>
                      </div>)}
                    </div>}
                    {!selected.is_customer_own_app && moreDiscoveries > 0 && <div className="more-discoveries" onClick={(e) => handleClick(selected, app, e)}>+{moreDiscoveries}</div>}
                  </div>
                }
              >
                <div
                  className={`version-item ${selected.y > 0 ? "" : "not-clickable"} ${location.z > 0 ? "mult" : ""} ${selected.is_customer_own_app ? 'customer-own-app' : ""}`}
                  style={{
                    top: location.y - 15,
                    left: location.x - 15,
                    zIndex: 100 + location.z,
                    backgroundImage: `url('${app.metadata.icon}')`
                  }}
                  onClick={selected.y > 0 && !selected.is_customer_own_app ? () => { handleClick(selected, app) } : ""}
                >
                  {!selected.is_customer_own_app && criticalDiscoveriesByApps[selected.app_id] && criticalDiscoveriesByApps[selected.app_id].length > 0 &&
                    criticalDiscoveriesByApps[selected.app_id].find(dis => dis.release_id === selected.id) &&
                    <div className={`importance-indicator importance-indicator-animating`}/>
                  }
                </div>
              </Tooltip>
            );
          })}
        </div>
        <div className="release-radar-sep"/>
        <div className="title">Currently Being Analyzed
          <div className="record-symbol" />
        </div>
        <div className="analyzed-apps" >
          {analyzedVersions && <Slider {...slickSettings} ref={slider}>
            {[...analyzedVersions].map(version => {

              const app = apps.find((app) => app.metadata.id === version.app_id)
              if (!app) { console.log(`There isn't app with ${version.id}`); return null }
              return <OverviewAnalyzedVersion
                key={version.id}
                icon={app.metadata.icon}
                title={app.metadata.display_name}
                version={version.release_name}
                children={version.childVersions.map(cv => ({
                  ...cv,
                  platform: app.metadata.platform,
                  icon: app.metadata.icon,
                  title: app.metadata.display_name,
                }))}
                platform={app.metadata.platform}
              />
            })}
          </Slider>}
        </div>
      </div>
    </div>
  );
};

export default compose(connect(analyzedVersionsSelector, {
  getBeingAnalyzedVersions,
  getCompletedVersionsWithDiscoveries
}), withRouter)(OverviewAnalyzedVersions);
