// GET_APPS_METADATA
export const GET_APPS_METADATA = "get_apps_metadata";
export const GET_APPS_METADATA_SUCCESS = "get_apps_metadata_success";
export const GET_APPS_METADATA_FAILED = "get_apps_metadata_failed";
// GET_APP_DATA
export const GET_APP_DATA = "get_app_data";
export const GET_APP_DATA_SUCCESS = "get_app_data_success";
export const GET_APP_DATA_FAILED = "get_app_data_failed";
export const ALL_APPS_DATA_FETCHED = "ALL_APPS_DATA_FETCHED"

export const GET_ALL_APPS_DATA = "GET_ALL_APPS_DATA";
export const GET_RELEVANT_APPS_INSIGHTS = "GET_RELEVANT_APPS_INSIGHTS";
// GET_VERSION_INFO
export const GET_VERSION_INFO = "get_version_info";
export const GET_VERSION_INFO_SUCCESS = "get_version_info_success";
export const GET_VERSION_INFO_FAILED = "get_version_info_failed";
// GET_ALL_RELEVANT_DISCOVERIES
export const GET_ALL_RELEVANT_DISCOVERIES = "GET_ALL_RELEVANT_DISCOVERIES";
export const GET_ALL_RELEVANT_DISCOVERIES_FAILED =
  "GET_ALL_RELEVANT_DISCOVERIES_FAILED";
export const GET_ALL_RELEVANT_DISCOVERIES_SUCCESS =
  "GET_ALL_RELEVANT_DISCOVERIES_SUCCESS";
// SET DATA FROM DIRECT_LINK
export const SET_APPS_DATA = "set_apps_data";
// SET SITE VERSION
export const SET_SITE_VERSION = "set_site_version";
// INIT DASHBOARD
export const INIT_DASHBOARD = "init_dashboard";
// INIT APP DATA
export const INIT_APP_DATA = "init_app_data";
// SET LAST AB TEST
export const SET_LAST_AB_TEST = "set_last_ab_test";
export const INIT_LAST_AB_TEST = "init_last_ab_test";
// create new user if does not exist already
export const CREATE_NEW_USER = 'CREATE_NEW_USER';
export const CREATE_NEW_USER_SUCCESS = 'CREATE_USER_SUCCESS';
// SET USER
export const SET_USER = "set_user";
export const SET_USER_SUCCESS = "set_user_success";
export const SET_USER_FAILED = "set_user_failed";
// SET USERS
export const SET_USERS = "set_users";
export const SET_USERS_SUCCESS = "set_users_success";
export const SET_USERS_FAILED = "set_users_failed";
// IMPERSONATE USER
export const SET_IMPERSONATE_USER = "set_impersonate_user";
// SET APP INDEX
export const SET_APP_INDEX = "set_app_index";
export const SET_APP_INDEX_BY_ID = "SET_APP_INDEX_BY_ID";

// SET AB TEST CHART
export const SET_MAIN_LOADER = "set_main_loader";
export const CURRENT_REQUESTS_ADD = "current_requests_add";
export const CURRENT_REQUESTS_REMOVE = "current_requests_remove";
// GET AB TEST CHART
export const GET_AB_TEST_TIMELINE = "get_ab_test_chart";
export const GET_AB_TEST_TIMELINE_SUCCESS = "get_ab_test_chart_success";
export const GET_AB_TEST_TIMELINE_FAILED = "get_ab_test_chart_failed";
// GET AB REACH RATE CHART
export const GET_REACH_RATE_CHART = "get_reach_rate_chart";
export const GET_REACH_RATE_CHART_SUCCESS = "get_reach_rate_chart_success";
export const GET_REACH_RATE_CHART_FAILED = "get_reach_rate_chart_failed";
// GET RAW AB TESTS DATA
export const GET_RAW_AB_TEST_DATA = "get_raw_ab_test_data";
export const GET_RAW_AB_TEST_DATA_SUCCESS = "get_raw_ab_test_data_success";
export const GET_RAW_AB_TEST_DATA_FAILED = "get_raw_ab_test_data_failed";
// SET AB TEST CHART
export const SET_AB_TESTS_CHART = "set_ab_tests_chart";

// GET PERFORMANCE CHART
export const GET_PERFORMANCE_APPS = "GET_PERFORMANCE_APPS";
export const GET_PERFORMANCE_APPS_SUCCESS = "GET_PERFORMANCE_APPS_SUCCESS";
export const GET_PERFORMANCE_APPS_FAILURE = "GET_PERFORMANCE_APPS_FAILURE";
export const GET_PERFORMANCE_EVENT_CHART = "GET_PERFORMANCE_EVENT_CHART";
export const GET_PERFORMANCE_EVENT_CHART_SUCCESS =
  "GET_PERFORMANCE_EVENT_CHART_SUCCESS";
export const GET_PERFORMANCE_EVENT_CHART_FAILED =
  "GET_PERFORMANCE_EVENT_CHART_FAILED";
// PERFORMANCE
export const GET_PERFORMANCE_PAGE_METADATA = "GET_PERFORMANCE_PAGE_METADATA";
export const GET_PERFORMANCE_PAGE_METADATA_SUCCESS =
  "GET_PERFORMANCE_PAGE_METADATA_SUCCESS";
export const GET_PERFORMANCE_EVENTS_LIST = "get_performance_events_list";
export const GET_PERFORMANCE_EVENTS_LIST_SUCCESS =
  "get_performance_events_list_success";
export const GET_PERFORMANCE_EVENTS_LIST_FAILED =
  "get_performance_events_list_failed";
export const GET_PERFORMANCE_DEVICE_MODELS = "GET_PERFORMANCE_DEVICE_MODELS";
export const GET_PERFORMANCE_DEVICE_MODELS_SUCCESS =
  "GET_PERFORMANCE_DEVICE_MODELS_SUCCESS";
export const GET_PERFORMANCE_EVENT_HISTORY_DATA =
  "GET_PERFORMANCE_EVENT_HISTORY_DATA";
export const GET_PERFORMANCE_EVENT_HISTORY_DATA_SUCCESS =
  "GET_PERFORMANCE_EVENT_HISTORY_DATA_SUCCESS";
export const CLEAR_PERFORMANCE_PAGE_DATA = "CLEAR_PERFORMANCE_PAGE_DATA";
export const CLEAR_PERFORMANCE_PAGE_METADATA =
  "CLEAR_PERFORMANCE_PAGE_METADATA";

export const GET_PERFORMANCE_EVENTS_DATA = 'GET_PERFORMANCE_EVENTS_DATA'
export const GET_PERFORMANCE_EVENTS_DATA_SUCCESS = 'GET_PERFORMANCE_EVENTS_DATA_SUCCESS'
export const GET_PERFORMANCE_EVENTS_FAILED = 'GET_PERFORMANCE_EVENTS_FAILED'

// CREATE DIRECT LINK DATA
export const CREATE_LINK_DATA = "create_link_data";
export const CREATE_LINK_DATA_SUCCESS = "create_link_data_success";
export const CREATE_LINK_DATA_FAILED = "create_link_data_failed";
// INIT DIRECT LINK DATA
export const INIT_LINK_DATA = "init_link_data";
// DIRECT LINK
export const GET_LINK_DATA = "get_link_data";
export const GET_LINK_DATA_SUCCESS = "get_link_data_success";
export const GET_LINK_DATA_FAILED = "get_link_data_failed";

export const UPDATE_LINK_DATA = "update_link_data";
export const UPDATE_LINK_DATA_SUCCESS = "update_link_data_success";

export const GET_RECIPIENTS = "GET_RECIPIENTS"
export const GET_RECIPIENTS_SUCCESS = "GET_RECIPIENTS_SUCCESS"

export const SEND_SHARE_EMAIL = "SEND_SHARE_EMAIL"
export const SEND_SHARE_EMAIL_SUCCESS = "SEND_SHARE_EMAIL_SUCCESS"

// IMPORTANCE & VIEWED INDICATORS

export const SET_SEEN_STATUS = "SET_SEEN_STATUS";
export const SET_SEEN_STATUS_SUCCESS = "SET_SEEN_STATUS_SUCCESS";

export const SET_DISCOVERY_RATE = "SET_DISCOVERY_RATE";
export const SET_DISCOVERY_RATE_SUCCESS = "SET_DISCOVERY_RATE_SUCCESS";

export const SET_VIEWED_STATUS = "SET_VIEWED_STATUS";
export const SET_VIEWED_STATUS_SUCCESS = "SET_VIEWED_STATUS_SUCCESS";
export const SET_VIEWED_STATUS_FAILED = "SET_VIEWED_STATUS_FAILED";
export const REMOVE_RELEVANT_READ_INSIGHTS = "REMOVE_RELEVANT_READ_INSIGHTS"

export const GET_UNSEEN_CRITICAL_INSIGHTS = "GET_UNSEEN_CRITICAL_INSIGHTS"
export const GET_UNSEEN_CRITICAL_INSIGHTS_SUCCESS = "GET_UNSEEN_CRITICAL_INSIGHTS_SUCCESS"

export const GET_UNSEEN_CRITICAL_INSIGHTS_PER_RELEASE = "GET_UNSEEN_CRITICAL_INSIGHTS_PER_RELEASE"
export const GET_UNSEEN_CRITICAL_INSIGHTS_PER_RELEASE_SUCCESS = "GET_UNSEEN_CRITICAL_INSIGHTS_PER_RELEASE_SUCCESS"

export const SET_ALL_INSIGHTS_VIEWED = "SET_ALL_INSIGHTS_VIEWED"
export const SET_ALL_INSIGHTS_VIEWED_SUCCESS = "SET_ALL_INSIGHTS_VIEWED_SUCCESS"

// Liked Actions
export const LIKE_INSIGHT = "LIKE_INSIGHT";
export const UNLIKE_INSIGHT = "UNLIKE_INSIGHT";
export const INSIGHT_LIKE_INTERACTION_SUCCESS =
  "INSIGHT_LIKE_INTERACTION_SUCCESS";
// As A question
export const ASK_INSIGHT_QUESTION = "ASK_INSIGHT_QUESTION";
export const SET_ASK_INSIGHT_QUESTION_SUCCESS =
  "SET_ASK_INSIGHT_QUESTION_SUCCESS";
// Search filter by apps
export const SEARCH_APP_FILTER = "SEARCH_APP_FILTER";
export const CLEAR_APP_FILTER = "CLEAR_APP_FILTER";

// GET STATS DATA FOR APP
export const GET_PERFORMANCE_STATS_VERSIONS_DATA_FOR_APP = "GET_PERFORMANCE_STATS_VERSIONS_DATA_FOR_APP";
export const GET_PERFORMANCE_STATS_VERSIONS_DATA_FOR_APP_SUCCESS = "GET_PERFORMANCE_STATS_VERSIONS_DATA_FOR_APP_SUCCESS";



export const GET_PERFORMANCE_RANKS = "GET_PERFORMANCE_RANKS";
export const GET_PERFORMANCE_RANKS_SUCCESS = "GET_PERFORMANCE_RANKS_SUCCESS";

export const GET_PERFORMANCE_LATEST_UPDATE = "GET_PERFORMANCE_LATEST_UPDATE";
export const GET_PERFORMANCE_LATEST_UPDATE_SUCCESS = "GET_PERFORMANCE_LATEST_UPDATE_SUCCESS";

export const GET_PERFORMANCE_RANKS_COMPARE = "GET_PERFORMANCE_RANKS_COMPARE";
export const GET_PERFORMANCE_RANKS_COMPARE_SUCCESS = "GET_PERFORMANCE_RANKS_COMPARE_SUCCESS";

export const GET_MARKETING_CAMPAIGNS = "GET_MARKETING_CAMPAIGNS";
export const GET_MARKETING_CAMPAIGNS_SUCCESS = "GET_MARKETING_CAMPAIGNS_SUCCESS";

export const GET_USER_NAME = "GET_USER_NAME";
export const GET_USER_NAME_SUCCESS = "GET_USER_NAME_SUCCESS";

export const SET_USER_NAME = "SET_USER_NAME";
export const SET_USER_NAME_SUCCESS = "SET_USER_NAME_SUCCESS";
export const SET_USER_NAME_FAILED = 'SET_USER_NAME_FAILED';
export const SET_USER_NAME_SUCCESS_RESET = "SET_USER_NAME_SUCCESS_RESET";

export const SET_USER_PASS = "SET_USER_PASS";
export const SET_USER_PASS_ERROR = "SET_USER_PASS_ERROR";
export const SET_USER_PASS_SUCCESS = "SET_USER_PASS_SUCCESS";
export const SET_USER_PASS_SUCCESS_RESET = "SET_USER_PASS_SUCCESS_RESET";

export const SET_USER_PIC = "SET_USER_PIC";
export const SET_USER_PIC_ERROR = "SET_USER_PIC_ERROR";
export const SET_USER_PIC_SUCCESS = "SET_USER_PIC_SUCCESS";

export const DELETE_USER_PIC = "DELETE_USER_PIC";
export const DELETE_USER_PIC_SUCCESS = "DELETE_USER_PIC_SUCCESS";

export const SET_CAMPAIGN_DEMO_MODE = "SET_CAMPAIGN_DEMO_MODE";

export const GET_DISCOVERIES = "GET_DISCOVERIES"
export const GET_DISCOVERIES_SUCCESS = "GET_DISCOVERIES_SUCCESS"

export const GET_DISCOVERIES_SEARCH = "GET_DISCOVERIES_SEARCH"
export const GET_DISCOVERIES_SEARCH_SUCCESS = "GET_DISCOVERIES_SEARCH_SUCCESS"

export const GET_PRODUCTS = "GET_PRODUCTS"
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS"

export const GET_PRODUCTS_STATS = "GET_PRODUCTS_STATS"
export const GET_PRODUCTS_STATS_SUCCESS = "GET_PRODUCTS_STATS_SUCCESS"

export const GET_PRODUCT_DISCOVERIES = "GET_PRODUCT_DISCOVERIES"
export const GET_PRODUCT_DISCOVERIES_SUCCESS = "GET_PRODUCT_DISCOVERIES_SUCCESS"
export const GET_TRACKED_SINCE = 'GET_TRACKED_SINCE';
export const GET_TRACKED_SINCE_SUCCESS = 'GET_TRACKED_SINCE_SUCCESS';

export const SET_ALL_DISCOVERIES_AS_SEEN = "SET_ALL_DISCOVERIES_AS_SEEN"
export const SET_ALL_DISCOVERIES_AS_SEEN_SUCCESS = "SET_ALL_DISCOVERIES_AS_SEEN_SUCCESS"

export const GET_SIGNIFICANT_UNSEEN_DISCOVERIES = "GET_SIGNIFICANT_UNSEEN_DISCOVERIES"
export const GET_SIGNIFICANT_UNSEEN_DISCOVERIES_SUCCESS = "GET_SIGNIFICANT_UNSEEN_DISCOVERIES_SUCCESS"

//personas
export const GET_PERSONAS = 'GET_PERSONAS';
export const GET_PERSONAS_SUCCESS = 'GET_PERSONAS_SUCCESS';
export const GET_PERSONAS_FAILURE = 'GET_PERSONAS_FAILURE';


//Get DISCOVERY data (with related DISCOVERIES)
export const GET_DISCOVERY_DATA = 'GET_DISCOVERY_DATA'
export const GET_DISCOVERY_DATA_SUCCESS = 'GET_DISCOVERY_DATA_SUCCESS'

//SET_EMAIL_PREFERENCES
export const SET_EMAIL_PREFERENCES = 'SET_EMAIL_PREFERENCES';
export const SET_EMAIL_PREFERENCES_SUCCESS = 'SET_EMAIL_PREFERENCES_SUCCESS';

// CHANGE_PASSWORD_ON_FIRST_LOGIN
export const CHANGE_PASSWORD_ON_FIRST_LOGIN = 'CHANGE_PASSWORD_ON_FIRST_LOGIN';
export const CHANGE_PASSWORD_ON_FIRST_LOGIN_SUCCESS = 'CHANGE_PASSWORD_ON_FIRST_LOGIN_SUCCESS';
export const CHANGE_PASSWORD_ON_FIRST_LOGIN_FAILURE = 'CHANGE_PASSWORD_ON_FIRST_LOGIN_FAILURE';

export const SEND_NEW_CHANGE_PWD_MAIL = 'SEND_NEW_CHANGE_PWD_MAIL';
export const SEND_NEW_CHANGE_PWD_MAIL_SUCCESS = 'SEND_NEW_CHANGE_PWD_MAIL_SUCCESS';
export const CHECK_NONCE_EXPIRED = 'CHECK_NONCE_EXPIRED';
export const CHECK_NONCE_EXPIRED_SUCCESS = 'CHECK_NONCE_EXPIRED_SUCCESS';
export const CHECK_NONCE_EXPIRED_FAILURE = 'CHECK_NONCE_EXPIRED_FAILURE';

export const GET_DISCOVERIES_LABELS = 'GET_DISCOVERIES_LABELS'
export const GET_DISCOVERIES_LABELS_SUCCESS = 'GET_DISCOVERIES_LABELS_SUCCESS'

export const GET_INTEL_DISCOVERIES_LABELS = 'GET_INTEL_DISCOVERIES_LABELS'
export const GET_INTEL_DISCOVERIES_LABELS_SUCCESS = 'GET_INTEL_DISCOVERIES_LABELS_SUCCESS'

// BiWeekly
export const GET_BIWEEKLY_BY_ID = 'GET_BIWEEKLY_BY_ID';
export const GET_BIWEEKLY_BY_ID_SUCCESS = 'GET_BIWEEKLY_BY_ID_SUCCESS';
export const SET_RATE_BIWEEKLY_BY_ID = 'SET_RATE_BIWEEKLY_BY_ID';
export const SET_RATE_BIWEEKLY_BY_ID_SUCCESS = 'SET_RATE_BIWEEKLY_BY_ID_SUCCESS';
export const SEND_SHARE_BIWEEKLY_EMAIL = 'SEND_SHARE_BIWEEKLY_EMAIL';
export const SEND_SHARE_BIWEEKLY_EMAIL_SUCCESS = 'SEND_SHARE_BIWEEKLY_EMAIL_SUCCESS';


// GRAPHS
export const GET_GRAPHS_BY_APP = 'GET_GRAPHS_BY_APP';
export const GET_GRAPHS_BY_APP_SUCCESS = 'GET_GRAPHS_BY_APP_SUCCESS';

export const GET_GRAPH_BY_ID = 'GET_GRAPH_BY_ID';
export const GET_GRAPH_BY_ID_SUCCESS = 'GET_GRAPH_BY_ID_SUCCESS';

//Intel App
export const GET_DISCOVERIES_BY_PRODUCTS = 'GET_DISCOVERIES_BY_PRODUCTS';
export const GET_DISCOVERIES_BY_PRODUCTS_SUCCESS = 'GET_DISCOVERIES_BY_PRODUCTS_SUCCESS';
export const GET_AB_TESTS = 'GET_AB_TESTS';
export const GET_AB_TESTS_SUCCESS = 'GET_AB_TESTS_SUCCESS';

export const GET_TOP_LABELS = 'GET_TOP_LABELS'
export const GET_TOP_LABELS_SUCCESS = 'GET_TOP_LABELS_SUCCESS'

//Access Request
export const GET_ACCESS_REQUESTS = 'GET_ACCESS_REQUESTS';
export const GET_ACCESS_REQUESTS_SUCCESS = 'GET_ACCESS_REQUESTS_SUCCESS';

export const SET_ACCESS_REQUEST_STATUS = 'SET_ACCESS_REQUEST_STATUS';
export const SET_ACCESS_REQUEST_STATUS_SUCCESS = 'SET_ACCESS_REQUEST_STATUS_SUCCESS';

//shared insight data
export const SET_SHARED_INSIGHT_DATA = 'SET_SHARED_INSIGHT_DATA';