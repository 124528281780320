import axios from "axios";
import config from "../../shared/config";

import moment from "moment"

export const getProducts = () => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/products`
  return axios.get(url);
};

export const getProductStats = (productID) => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/products/${productID}/stats?bin_size=week&from_date=${moment().subtract(1, 'year').format('YYYY-MM-DD')}`
  return axios.get(url);
};

export const setAllDiscoveriesForProductAsSeen = ({ productID, relevancy_gte }) => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/products/${productID}/insights/all/read${relevancy_gte ? '?relevancy_gte=1' : ''}`
  return axios.put(url);
};

export const getSignificantUnseenDiscoveries = (data) => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/insight?num_items=40&app_id_only=${data.apps.join(',')}&order_by_start_time=desc&page_num=1&query=&days_ago=1825&read=0&relevancy_gte=1`
  return axios.get(url);

}

export const getTrackedSince = product_id => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/products/${product_id}/releases/first`
  return axios.get(url);
}

export const setDiscoveryRateAPI = (insight_id, rate) => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/insight/${insight_id}/rating`
  return axios.put(url, { rating: rate });
}


//PRODUCT INTEL
export const getDiscoveryByProductAPI = (product_id) => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/products/${product_id}/insights`
  return axios.get(url);
}

export const getABTestsAPI = ({ gets }) => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/insight?${gets}&normalize=1`
  return axios.get(url);
}

export const getTopLabelsAPI = ({ gets }) => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/insight/labels/top?${gets}&products_limit=6`
  return axios.get(url);
}