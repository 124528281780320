import React, { useMemo, useState } from "react";
import "./AppDrawer.scss";
import { Checkbox, Input, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import clsx from "clsx";
import { isMobileDevice } from "containers/MarketingOverview/utils/helpers";
import x_ico from "assets/images/icons/x-no-outline.svg";
import { CloseShowResults } from "components/CloseShowResults";

type App = {
  id: number;
  name: string;
  thumbnail: string;
};

type Props = {
  appsList: App[];
  currentAppsIds: number[];
  onSelectApp: (app: App) => void;
  onSelectAll?: () => void;
  onClearSelection?: () => void;
  minAppsSelected?: boolean;
  closeDrawer?: () => void;
  disableSelectAll?: boolean;
};

const MAX_APP_NAME = 20;

const fitToMaxLength = (str: string, maxLength: number) => {
  if (str.length <= maxLength) {
    return str;
  }

  return `${str.substring(0, maxLength)}...`;
};

export const AppDrawer = ({
  appsList,
  currentAppsIds,
  onSelectApp,
  onClearSelection,
  onSelectAll,
  minAppsSelected = true,
  closeDrawer,
  disableSelectAll,
}: Props) => {
  const isMobile = isMobileDevice();
  const [searchApp, setSearchApp] = useState("");

  const isAllAppsSelected = useMemo(
    () => currentAppsIds.length === appsList.length,
    [currentAppsIds, appsList]
  );

  const filteredApps = useMemo(
    () =>
      appsList
        .filter((app) =>
          app.name.toLowerCase().includes(searchApp.toLowerCase())
        )
        .map((app) => ({
          ...app,
          isSelected: currentAppsIds.includes(app.id),
        })),
    [appsList, searchApp, currentAppsIds]
  );

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchApp(e.target.value);
  };

  const onAllToggle = () => {
    if (isAllAppsSelected) {
      onClearSelection?.();
    } else {
      onSelectAll?.();
    }
  };

  return (
    <div className="app-drawer-container">
      <div className="app-drawer-top-section">
        {isMobile && (
          <div className="app-drawer-close" onClick={closeDrawer}>
            <img src={x_ico} />
          </div>
        )}
        <div className="apps-count">
          {currentAppsIds.length > 0
            ? `${currentAppsIds.length} Apps Selected`
            : "Start your journey with an app"}
          {currentAppsIds.length === 0 && (
            <span>Please select at least one app to proceed...</span>
          )}
        </div>
        <div className="apps-search">
          <Input
            size="large"
            placeholder="Search For App"
            prefix={<SearchOutlined />}
            onChange={onSearchChange}
            value={searchApp}
          />
        </div>
        {!disableSelectAll && (
          <div className="apps-selection-top">
            <Checkbox
              checked={isAllAppsSelected}
              onChange={onAllToggle}
              className="apps-select-all"
            >
              All
            </Checkbox>
            <div className="clear-selection" onClick={onClearSelection}>
              Clear Selection
            </div>
          </div>
        )}
      </div>
      <div
        className="apps-selection"
        style={isMobile ? { paddingBottom: "100px" } : {}}
      >
        {filteredApps.map((app) => (
          <Tooltip
            overlayClassName="info-header-tooltip"
            key={app.id}
            title={
              minAppsSelected && app.isSelected && currentAppsIds.length === 1
                ? "Minimum 1 App Selected"
                : ""
            }
            placement="top"
          >
            <div
              className={clsx("app-selection-item", {
                selected: app.isSelected,
                disabled:
                  minAppsSelected &&
                  app.isSelected &&
                  currentAppsIds.length === 1,
              })}
              onClick={() => {
                if (
                  minAppsSelected &&
                  app.isSelected &&
                  currentAppsIds.length === 1
                )
                  return;

                onSelectApp(app);
              }}
            >
              <Checkbox checked={app.isSelected} />
              <img src={app.thumbnail} />
              <div className="app-name">
                {fitToMaxLength(app.name, MAX_APP_NAME)}
              </div>
            </div>
          </Tooltip>
        ))}
      </div>
      {isMobile && closeDrawer && (
        <div className="apps-close-mobile">
          <CloseShowResults onClose={closeDrawer} />
        </div>
      )}
    </div>
  );
};
