import * as actionTypes from "../actions/_actionsTypes";
import { isEqual } from "lodash";

const INIT_STATE = {
  apps: undefined,
  deviceModels: undefined,
  locations: undefined,
  categories: undefined,
  eventTypes: undefined,
  infrastructure: undefined,
  categoriesEvents: undefined,
  categoriesDevices: undefined,

  isFetchingPerformancePageMetadata: false,

  isFetchingPerformancePageData: false,
  preferredApps: undefined,
  ranks: undefined,
  ranksLoading: false,
  compareRanks: null,

  //new model
  currentEvents: [],
};

export default function(state = INIT_STATE, action) {
  switch (action.type) {
  case actionTypes.CLEAR_PERFORMANCE_PAGE_METADATA: {
    return {
      ...state,
      apps: undefined,
      deviceModels: undefined
    };
  }

  case actionTypes.GET_PERFORMANCE_PAGE_METADATA: {
    return { ...state, isFetchingPerformancePageMetadata: true };
  }

  case actionTypes.GET_PERFORMANCE_PAGE_METADATA_SUCCESS: {
    const modInfrastrucute = [...action.payload.infrastructure]

    return {
      ...state,
      apps: action.payload.apps || undefined,
      deviceModels:
          action.payload.deviceModels || undefined,
      isFetchingPerformancePageMetadata: false,
      preferredApps: action.payload.preferredApps || undefined,
      locations: action.payload.locations,
      categories: action.payload.categories,
      eventTypes: action.payload.eventTypes,
      categoriesEvents: action.payload.categoriesEvents,
      categoriesDevices: action.payload.categoriesDevices,
      infrastructure: modInfrastrucute
    };
  }

  case actionTypes.GET_PERFORMANCE_EVENTS_DATA: {
    return { ...state, isFetchingPerformancePageData: true };
  }
  case actionTypes.GET_PERFORMANCE_EVENTS_FAILED: {
    return { ...state, isFetchingPerformancePageData: false };
  }

  case actionTypes.GET_PERFORMANCE_RANKS: {
    return {
      ...state,
      ranksLoading: true,
    };
  }


  case actionTypes.GET_PERFORMANCE_RANKS_SUCCESS: {
    return {
      ...state,
      ranksLoading: false,
      ranks: action.payload.data.data
    };
  }



  case actionTypes.GET_PERFORMANCE_EVENTS_DATA_SUCCESS: {

    return {
      ...state,
      currentEvents: action.payload,
      isFetchingPerformancePageData: false
    }

  } 

  case actionTypes.GET_PERFORMANCE_STATS_VERSIONS_DATA_FOR_APP_SUCCESS: {
    const perfApps = [...state.apps]
    const statsResult = action.payload.statsResult
    const versionsResult = action.payload.versionsResult
    const appID = Object.keys(statsResult)[0]
    perfApps.forEach((app, i) => {
      if (+app.id === +appID) {
        perfApps[i] = {
          ...app,
          ...statsResult[appID],
          versions: versionsResult
        }
      }
    })
    return {
      ...state,
      apps: perfApps
    }
  }

  case actionTypes.GET_PERFORMANCE_RANKS_COMPARE_SUCCESS: {
    const data = action.payload.data.data

    return {
      ...state,
      compareRanks: data
    }
  }

  default: {
    return state;
  }
  }
}
