import React from "react"
import { datadogRum } from '@datadog/browser-rum';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV === "production") {
      datadogRum.addError(error, {
        message: `Fatal Error -  ${error}`,
        extraData: `Error info - ${JSON.stringify(errorInfo)}`,
      });
      window.location.href = "/error.html"
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return null;
    }

    return this.props.children;
  }
}

export default ErrorBoundary