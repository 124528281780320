/* eslint-disable no-useless-escape */
import React from "react";
import moment from "moment";
import { ViewTagsSearchRow } from "../../../components/discoveryView/viewTags/ViewTags";
import { isEmpty } from "lodash";
import DefaultThumbnail from "../../../assets/images/watchful_icon_gray.svg";
import { Img } from "react-image";

import {intelDiscoveryTypeDictionary, PL_MARKETING} from "../../../shared/consts"

import { getDiscoveriesStartTime } from "../../../helpers/generalHelpers"
import {MARKETING} from "../../intelligence/Marketing/configsSubtypes";


const DESCRIPTION_NUMBER_OF_WORDS_BUFFER = 3;


function SearchRowThumbnail({ appIcon, insightThumbnail }) {
  return (
    <div className="header__search-row-thumbnail">
      <Img
        className={`header__search-row-thumbnail-insight-icon ${!insightThumbnail ? 'def' : ''}`}
        src={[insightThumbnail, DefaultThumbnail]}
        alt="insight-thumbnail"
        loader={
          <img
            src={DefaultThumbnail}
            alt="loading"
            className={`header__search-row-thumbnail-insight-loader  ${!insightThumbnail ? 'def' : ''}`}
          />
        }
      />
      <Img
        className="header__search-row-thumbnail-app-icon"
        src={appIcon}
        alt="app-icon"
      />
    </div>
  );
}

function SearchRowContent({ insight, currentQuery }) {
  currentQuery = currentQuery.toLowerCase().trim();
  const currentQueryWords = currentQuery
    .replace(/\?/g, '')
    .toLowerCase()
    .trim()
    .split(" ");
  const constructTitle = () => {
    const rawTitle = insight.title
    let parts = rawTitle.split(
      new RegExp(
        `(${currentQueryWords
          .join("|")
          .replace(/[-\/\\^$*+?.()|[\]{}]/g, "\$&")})`,
        "gi"
      )
    );

    return (
      <span>
        {" "}
        {parts.map((part, i) => (
          <span
            key={i}
            className={
              currentQuery !== "" && part &&
                currentQuery
                  .toLowerCase()
                  .trim()
                  .includes(part.toLowerCase().trim())
                ? "highlight"
                : ""
            }
          >
            {part}
          </span>
        ))}{" "}
      </span>
    );
  };

  const constructSubTitle = () => {
    let rawTitle = insight.description;
    let splittedRaw = rawTitle.split(" ");
    const indexOfQuery = splittedRaw.findIndex(word =>
      word
        .toLowerCase()
        .trim()
        .includes(currentQuery)
    );
    if (indexOfQuery !== -1) {
      let splittedRawSliceStartIndex =
        indexOfQuery - DESCRIPTION_NUMBER_OF_WORDS_BUFFER;
      splittedRawSliceStartIndex =
        splittedRawSliceStartIndex > 0 ? splittedRawSliceStartIndex : 0;
      splittedRaw = splittedRaw.slice(splittedRawSliceStartIndex);
      if (splittedRawSliceStartIndex > 0) {
        // add a '...' prefix
        splittedRaw[0] = `...${splittedRaw[0]}`;
      }
      rawTitle = splittedRaw.join(" ").trim();
    }
    let parts = rawTitle.split(
      new RegExp(
        `(${currentQueryWords
          .join("|")
          .replace(/[-\/\\^$*+?.()|[\]{}]/g, "\$&")})`,
        "gi"
      )
    );
    return (
      <span>
        {" "}
        {parts.map((part, i) => (
          <span
            key={i}
            className={
              currentQuery !== "" &&
                currentQuery
                  .toLowerCase()
                  .trim()
                  .includes(part.toLowerCase().trim())
                ? "highlight"
                : ""
            }
          >
            {part}
          </span>
        ))}{" "}
      </span>
    );
  };
  const isMarketingDiscovery = insight.platform === PL_MARKETING;

  return (
    <div className="header__search-row-content">
      <div className="header__search-row-content-title">{constructTitle()}</div>
      <div className="header__search-row-content-subtitle">
        {!isEmpty(insight.description) && constructSubTitle()}
      </div>
      <div className="header__search-row-content-data">
        {isMarketingDiscovery ?
          <div>
            <span>{intelDiscoveryTypeDictionary[insight.type]}</span>
            <span>
              {getDiscoveriesStartTime(insight)}
            </span>
          </div>
          :
          <div>
            <span>{intelDiscoveryTypeDictionary[insight.type]}</span>
            <span>
              {moment(insight.started_date || insight.release_date).format("ll")}
            </span>
          </div>
        }
      </div>
      <div className="header__search-row-tags-type">
        <ViewTagsSearchRow tags={insight.labels || []} />
      </div>

    </div>
  );
}

export default function SearchResultRow({ searchRowData, currentQuery }) {
  return (
    <div className="header__search-row-inner-wrapper">
      <SearchRowThumbnail
        appIcon={searchRowData.metadata.icon}
        insightThumbnail={searchRowData.insight.thumbnail}
      />
      <SearchRowContent
        insight={searchRowData.insight}
        currentQuery={currentQuery}
      />
    </div>
  );
}
