import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getMarketingCampaigns, setInsightAsSeen } from "../../../redux/actions/dashboardActions";
import FilterTypes, { MATCH_TYPES } from "./FilterTypes"

import { Tabs, Button, Popover } from 'antd';
import GalleryDiscoveries from '../../dash-discoveries/GalleryDiscoveries'
import FilterSearchQuery from "../../dash-discoveries/FilterSearchQuery"
import FilterTime from '../../dash-discoveries/FilterTime'

import { getPersonas } from '../../../redux/acts/personas';

import { daysAgoList, daysAgoListRestricted, ALL_TIME, RESTRICTED_DOORDASH_EMAILS } from "../../../shared/consts"
import { marketingCampaignsTypes } from "../../../shared/consts"

import Profiles from "./Profiles"

import AppDrawer from "../../../common/appDrawer/AppDrawer"
import VersionsEmpty from "../../products-discoveries/versionsEmpty/versionsEmpty"

import { getDiscoveriesLabels } from "../../../redux/acts/labels"

import moment from 'moment'

import { INSIGHT_CAMPAIGN_SUBTYPE, UNSEEN, VIEWED, MEANINGFUL } from "./configsSubtypes"
const { TabPane } = Tabs;


const TAB_CAMPAIGNS = {
  '1': {
    name: "email_campaigns",
    label: "Email",
    description: "Up-to-date main mass-marketing email campaigns, sent to registered users",
    disabled: false
  },
  '2': {
    name: "inapp_campaigns",
    label: "In-App / On-Site",
    description: "Monitor your competitor’s app & website for promotional banners, pop-ups, hero promotions, etc.",
    disabled: false
  },
  '3': {
    name: "push_notification_campaigns",
    label: "Push Notification",
    description: "Push notifications delivered to registered users",
    needLicence: 'marketing.push_notification_campaigns',
    disabled: true
  },
  '4': {
    name: "paid_ads_campaigns",
    label: "Paid Ads",
    description: "",
    disabled: true
  },
  '5': {
    name: "paid_search_campaigns",
    label: "Paid Search",
    description: "",
    disabled: true
  },
}






export const IntelligenceMarketing = (props) => {
  const { campaigns, marketingApps, analytics, user, personas, products, discoveriesData } = props;
  const url = new URLSearchParams(props.history.location.search)
  const [currentTab, changeDefTab] = useState(url.get('tab') || 1)
  const [aggrPersonas, aggrPersonasChange] = useState(null)

  const [filteredType, filteredTypeChange] = useState(url.get('filteredType') ? url.get('filteredType').split(",") : [])
  const [daysAgo, daysAgoChange] = useState(url.get('daysAgo') || ALL_TIME)
  const [filterStatus, filterStatusChange] = useState(url.get('filterStatus') ? url.get('filterStatus').split(",") : [])
  const [filteredTags, filteredTagsChange] = useState(url.get('filteredTags') ? url.get('filteredTags').split(",") : [])
  const [matchType, setMatchType] = useState(MATCH_TYPES[0]);
  const [searchQuery, searchQueryChange] = useState(url.get('searchQuery') || '')

  const [listOfFirstInsight, listOfFirstInsightChange] = useState({})

  const [currentApp, currentAppChange] = useState(null)

  const [isOpenTimeFilter, isOpenTimeFilterChange] = useState(false)

  const [customRange, сustomRangeСhange] = useState(!!url.get('customRange'))

  const [customRangeTime, customRangeTimeChange] = useState(url.get('customRangeTime') ? [moment(url.get('customRangeTime').split(",")[0]),

    moment(url.get('customRangeTime').split(",")[1])] : [moment(), moment()])

  Object.keys(TAB_CAMPAIGNS).forEach(tabNumber => {
    if (TAB_CAMPAIGNS[tabNumber].needLicence && props.userData.pages.includes(TAB_CAMPAIGNS[tabNumber].needLicence)) {
      TAB_CAMPAIGNS[tabNumber].disabled = false
    }
  })

  const isRestrictedUser = RESTRICTED_DOORDASH_EMAILS.includes(user.email) && currentApp && currentApp.product_id === 1008 ? true :false;
  const [daysList, setDaysList] = useState(daysAgoList);

  useEffect(() => {
    if(isRestrictedUser && daysAgo === ALL_TIME) {
      daysAgoChange(daysAgoListRestricted[0].value);
    }

    if(isRestrictedUser) {
      setDaysList(daysAgoListRestricted);
    }
  }, [isRestrictedUser, daysAgo])

  useEffect(() => {
    if (!currentApp) {
      return
    }

    const filters = {
      app: currentApp.id,
      tab: currentTab,
      filteredType: filteredType,
      daysAgo: daysAgo,
      filterStatus: filterStatus,
      filteredTags: filteredTags,
      searchQuery: searchQuery,
      customRange: customRange ? '1' : '',
    }
    if (customRange) {
      filters['customRangeTime'] = customRangeTime.map(date => date.format('YYYY-MM-DD'))
    } else {
      filters['days_ago'] = daysAgo
    }

    const gets = new URLSearchParams(filters).toString()
    props.history.replace(`${props.history.location.pathname}?${gets}`);
  }, [currentApp,
    currentTab,
    filteredType,
    daysAgo,
    filterStatus,
    filteredTags,
    matchType,
    searchQuery,
    customRangeTime])

  useEffect(() => {

    if (props.personas.length === 0) {
      props.getPersonas()
    }
    if (!props.tagsList) {
      props.getDiscoveriesLabels()
    }
  }, [])


  const handleChangeTab = (tab) => {
    const prevTab = currentTab;
    changeDefTab(tab);
    searchQueryChange('')
    props.history.replace(`/marketing?app=${currentApp.id}&tab=${tab}`);
    analytics.analytic(`Change tab in Marketing Campaigns`, {
      current_tab: marketingCampaignsTypes[TAB_CAMPAIGNS[tab].name],
      previous_tab: marketingCampaignsTypes[TAB_CAMPAIGNS[prevTab].name],
      app: currentApp.display_name,
    })

  }



  const handleAppChange = (app) => {
    currentAppChange(app)
    filteredTypeChange([])
    filteredTagsChange([])
    props.history.replace(`/marketing?app=${app.id}&tab=${currentTab}`);
    analytics.analytic(`Change app in Marketing Campaigns`, {
      app: app.display_name,
    })
  }

  const checkFilter = cam => {
    if (!customRange && Math.abs(moment(cam.cam.start_time || cam.cam.created).diff(moment(), 'days')) > Number(daysAgo)) {
      return false
    }

    let passStatus = false, passTag = false, passQuery = false, passCustomRange = false, passApp = false, passType = false

    if (cam.app.id === currentApp.id) {
      passApp = true
    }
    if (filteredType.length === 0 || cam.cam.sub_type && filteredType.includes(INSIGHT_CAMPAIGN_SUBTYPE.find(sbT => sbT.name === cam.cam.sub_type).label)) {
      passType = true
    }

    if (customRange) {
      let from = moment(customRangeTime[0]).hours(0).minutes(0).seconds(0).subtract(1, 'seconds')
      let to = moment(customRangeTime[1]).hours(23).minutes(59).seconds(59)
      passCustomRange = moment(cam.cam.start_time || cam.cam.created).isBetween(from, to)
    } else {
      passCustomRange = true
    }
    const thisDiscoveryData = discoveriesData.find(d => cam.cam.id === d.insight_id);
    if (filterStatus.length === 0) {
      passStatus = true
    } else {
      if (filterStatus.includes(MEANINGFUL) && thisDiscoveryData && thisDiscoveryData.relevancy > 0) {
        passStatus = true
      }
      if (filterStatus.includes(UNSEEN) && thisDiscoveryData && !thisDiscoveryData.read_at) {
        passStatus = true
      }
      if (filterStatus.includes(VIEWED) && thisDiscoveryData && thisDiscoveryData.read_at) {
        passStatus = true
      }
    }
    if (filteredTags.length === 0) {
      passTag = true
    } else {
      if (matchType === MATCH_TYPES[0] ?
        cam.cam.labels.filter(x => filteredTags.includes(x)).length > 0 :
        filteredTags.every(tag => cam.cam.labels.includes(tag)) > 0) {
        passTag = true
      }
    }
    if (searchQuery === '') {
      passQuery = true
    } else {
      if (cam.cam.title.toLowerCase().includes(searchQuery.toLowerCase())) {
        passQuery = true
      }
    }
    return passStatus && passTag && passQuery && passCustomRange && passApp && passType
  }

  useEffect(() => {

    if (campaigns && Object.values(campaigns)[0]) {
      let all_campaigns = Object.values(campaigns).flat()
      let data = {}
      all_campaigns.forEach(cam => {
        if (!data[cam.app.id]) {
          data[cam.app.id] = cam.cam
        } else if (moment(data[cam.app.id].start_time).isAfter(moment(cam.cam.start_time))) {
          data[cam.app.id] = cam.cam
        }
      })
      listOfFirstInsightChange(data)
    }

  }, [campaigns])

  useEffect(() => {
    if (!currentApp && marketingApps) {
      let app = marketingApps.find(app => app.id == url.get('app')) || marketingApps[0]
      if (!app) {
        return
      }
      currentAppChange(app)
    }
  }, [marketingApps])


  useEffect(() => {
    if (!props.campaignsFetched) {
      props.getMarketingCampaigns();
    }
  }, [])

  useEffect(() => {
    if (!currentApp) { return }
    let prod = products.find(p => p.apps.find(a => a.id === currentApp.id))
    let filteredPersonas = personas.filter(per => per.product_ids.includes(prod.id) && per.active).reduce((acc, el) => {
      acc[el.location] = (acc[el.location] ? acc[el.location] + 1 : 1)
      return acc
    }, {})
    aggrPersonasChange(filteredPersonas)
  }, [currentApp])


  const getCountCampaignsByTime = (tab) => {
    return currentCampaignsByType[TAB_CAMPAIGNS[tab].name].filter(checkFilter).length
  }

  if (!currentApp) {
    return <h1>There are no marketing apps</h1>
  }

  if (!campaigns) {
    return
  }
  let currentCampaignsByType = {}, currentCampaignsByTypeData = {}

  Object.keys(campaigns).forEach(camType => {
    currentCampaignsByType[camType] = campaigns[camType].filter(cam => cam.app.id == currentApp.id)
    currentCampaignsByTypeData[camType] = []

    INSIGHT_CAMPAIGN_SUBTYPE.forEach(sT => {
      let listCampBySubType = currentCampaignsByType[camType].filter(
        ins => ins.cam.sub_type == sT.name)

      let obj_data = {
        name: `${camType}_${sT.name}`,
        data: listCampBySubType,
        count: listCampBySubType.length,
        ico: sT.icon,
        label: sT.label
      }

      currentCampaignsByTypeData[camType].push(obj_data)

    })
  })

  // TEMP. There is bug on server side - each tag can be doubled a lot of times. We take uniq set
  const tags = [...Object.values(campaigns).flat()].reduce((acc, ins) => {
    if (ins.cam.labels) {
      return [...new Set([...acc, ...ins.cam.labels])]
    } else {
      return acc
    }
  }, [])

  const filteredCampaignsCount = currentCampaignsByTypeData[TAB_CAMPAIGNS[currentTab].name].reduce((acc, cat) => (
    filteredType.length === 0 || filteredType.includes(cat.label) ?
      acc + cat.data.filter(checkFilter).length : acc
  ), 0)

  return (
    <div className="intelligence-marketing">

      {currentApp && aggrPersonas && <Profiles appID={currentApp.id} userQty={aggrPersonas} />}
      <h1 className="h1-title">
        <strong>Marketing</strong> Campaigns
      </h1>
      {marketingApps && currentApp && <AppDrawer appsList={marketingApps} selectedApp={currentApp} appChange={handleAppChange} page="marketing" isIntelligence={true} listOfFirstInsight={listOfFirstInsight} />}

      <div className="marketing__sub-header-new">
        <Popover placement="bottom" title={null} content={<FilterTypes
          filteredTypeChange={filteredTypeChange}
          filterStatusChange={filterStatusChange}
          filteredTagsChange={filteredTagsChange}
          tags={tags}
          filteredTags={filteredTags}
          filteredType={filteredType}
          filterStatus={filterStatus}
          currentCampaignsDataSet={Object.values(currentCampaignsByTypeData).flat()}

          currentCampaignsSet={Object.values(currentCampaignsByType).flat()}
          setMatchType={setMatchType}
          matchType={matchType}
          discoveriesData={discoveriesData}
        />}
        trigger="click" overlayClassName={`filter-wrap marketing-filter-type-wrapper`}>
          <Button className="filter-btn-new">
            Filters
            <span className="filter-count">
              {filteredType.length + filterStatus.length + filteredTags.length}
            </span>
          </Button>
        </Popover>
        <span className="sep" />

        <Popover placement="bottomRight" title={null}
          visible={isOpenTimeFilter}
          onVisibleChange={isOpenTimeFilterChange}
          content={
            <FilterTime
              isOpen={isOpenTimeFilter}
              daysAgoList={daysList}
              daysAgo={daysAgo}
              daysAgoChange={daysAgoChange}
              customRange={customRange}
              сustomRangeСhange={сustomRangeСhange}
              customRangeTime={customRangeTime}
              customRangeTimeChange={customRangeTimeChange}
            />} trigger="click" overlayClassName={`filter-wrap all-discoveries all-discoveries-time`}
          overlayStyle={{ width: (customRange ? '75rem' : '18rem') }}>
          <Button className="filter-btn-new">
            {customRange ? 'Custom Range' : daysAgoList.find(d => d.value == daysAgo).title}
          </Button>
        </Popover>

      </div>

      <Tabs defaultActiveKey={currentTab} className="tabs" animated={{ inkBar: true, tabPane: false }} onChange={handleChangeTab}>
        {Object.entries(TAB_CAMPAIGNS).map(([tab, tabData]) => {
          if (tabData.disabled) {
            return <TabPane tab={tabData.label} key={tab} disabled />
          } else {
            return <TabPane tab={<div>
              {TAB_CAMPAIGNS[tab].label}
              <strong>{getCountCampaignsByTime(tab)}</strong>
              <span style={{ fontWeight: 'normal' }}>/{currentCampaignsByType[TAB_CAMPAIGNS[tab].name].length}</span>
            </div>}
            key={tab}>
              <h3 className="title">{TAB_CAMPAIGNS[tab].label} Campaigns</h3>
              <div className="extra-text">
                {TAB_CAMPAIGNS[tab].description}
              </div>

              <FilterSearchQuery
                searchQuery={searchQuery}
                searchQueryChange={searchQueryChange}
              />

              {filteredCampaignsCount === 0 && <VersionsEmpty campaignMode={true} />}

              {filteredCampaignsCount !== 0 && currentCampaignsByTypeData[TAB_CAMPAIGNS[tab].name].map(data => {
                const ds = data.data.filter(checkFilter).sort((a, b) => moment(a.cam.start_time || a.cam.created).isAfter(b.cam.start_time || b.cam.created) ? -1 : 1).map(d => d.cam)
                return (
                  (filteredType.length === 0 || filteredType.includes(data.label)) && data.data.filter(checkFilter).length !== 0
                    ?
                    <>
                      {data.label && <h3 className="cat-title">
                        <img src={data.ico} style={data.icoStyle || { position: 'relative', top: '-0.1rem' }} />
                        {data.label} ({data.data.filter(checkFilter).length})</h3>}
                      <div className="campaigns-wrap">
                        <GalleryDiscoveries
                          loadingFilteredDiscoveries={false}
                          filteredDiscoveries={ds}
                          apps={marketingApps}
                          showType={false}
                          user={user}
                          analytics={analytics}
                          productName={null}
                          handleClick={null}
                          usePagination={false}
                          source={"marketing_campaigns_page"}
                        />
                      </div>
                    </>
                    :
                    null
                )
              })}

            </TabPane>
          }
        })}
      </Tabs>

    </div>
  )
}


export default connect(({ appData, overview, personas, userData, labels, userDiscoveries }) => {
  return {
    user: appData.user,
    userData: userData,
    campaigns: Object.keys(marketingCampaignsTypes).reduce((acc, el) => ({ ...acc, [el]: overview[el] }), {}),
    campaignsFetched: overview.campaignsFetched,
    marketingApps: overview.marketingApps,
    analytics: appData.user.analytics,
    personas: personas,
    products: overview.products,
    tagsList: labels.tagsList,
    discoveriesData: userDiscoveries.discoveries,
  }
}, {
  getMarketingCampaigns,
  setInsightAsSeen,
  getPersonas,
  getDiscoveriesLabels,
})(IntelligenceMarketing)
