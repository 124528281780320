import { ResetPasswordView } from "./ResetPasswordView";
import { ConfirmPasswordView } from "./ConfirmPasswordView";
import { SetNewPasswordView } from "./SetNewPasswordView";
import { SuccessPasswordView } from "./SuccessPasswordView";

export const passwordResetSteps = [
  {
    component: ResetPasswordView,
    title: "Recover Password",
    noPopupOnClose: true
  },
  { component: ConfirmPasswordView, title: "Recover Password" },

  { component: SetNewPasswordView, title: "Change Password" },
  { component: SuccessPasswordView, title: "Success" }
];
