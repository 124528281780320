import React, { ReactElement } from 'react'
import { LineChart } from "@carbon/charts-react";
import "@carbon/charts/styles.css";
import { merge } from "lodash"
import "./WLineChart.scss"
import { setOfColors } from "../commonSettings";

import { IDataPoint } from "../../../interfaces/Product"

const defOptions = {
  axes: {
    bottom: {
      title: "",
      mapsTo: "key",
      scaleType: "labels",
      ticks: {

      },

    },
    left: {
      mapsTo: "value",
      title: "",
      margins: {
        left: 100
      },
      ticks: {
        formatter: (data: string) => {
          return Number(data) % 1 == 0 ? data : ''
        }
      },
      scaleType: 'linear'
    }
  },
  points: {
    filled: true,
    fillOpacity: 0.8,
    radius: 3,
  },
  legend: {
    enabled: false,
  },
  color: {
    scale: {}
  },
  height: "40rem",
}


interface Props {
  data: IDataPoint[],
  options: any
}


const WLineChart = ({ data, options = {} }: Props): ReactElement => {


  const fullOptions = merge(defOptions, options)

  //@ts-ignore
  const setOfGroup = [...new Set(data.map(d => d.group))]
  //@ts-ignore
  const setOfLabel = [...new Set(data.map(d => d.key))]
  setOfGroup.forEach((g, i) => {
    //@ts-ignore
    fullOptions.color.scale[g] = setOfColors[i]
  })
  fullOptions.axes.bottom.ticks.values = setOfLabel

  return (
    <div className="line-chart-wrapper">
      <LineChart
        data={data}
        //@ts-ignore
        options={defOptions} />
      <div className="legend">
        {setOfGroup.map((g, i) => <div className="legend-item" key={i}>
          <div className="legend-color" style={{ backgroundColor: setOfColors[i] }}></div>
          <div className="legend-value">{g}</div>
        </div>)}
      </div>
    </div>
  )
}

export default WLineChart