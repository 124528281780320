import { Breadcrumb } from "antd";
import React, { useEffect } from "react";
import "./MarketingCampaignsPage.scss";
import { MarketingCampaignsFilters } from "./components/MarketingCampaignsFilters";
import { MarketingCampaignsTable } from "./components/MarketingCampaignsTable";
import { RouteComponentProps, withRouter } from "react-router";
import { useCampaignsFilters } from "./hooks/useCampaignsFilters";
import { useCampaigns } from "./hooks/useCampaigns";
import { trackMarketingEvent } from "./utils/analytics";

type Props = RouteComponentProps;

const MarketingCampaignsPageBase = ({ history }: Props) => {
  const {
    apps,
    channels,
    userTypes,
    userRoleTypes,
    insights,
    totalDbInsights,
    selectedInsight,
    isLoading,
    fetchNextPage,
    focusAreas,
  } = useCampaigns({
    history,
  });

  const { channelId, insightId, handleChangeFilter, ...rest } =
    useCampaignsFilters({
      history,
      apps,
      userTypes,
      userRoleTypes,
    });

  useEffect(() => {
    // layout fixes, js because changing system globals
    const mainContainer: any = document.querySelector(".dashboard__container");

    if (!mainContainer) return;

    // remove right spacing from all the pages
    // it caused page to scroll horizontally on smaller screens
    // mainContainer.style.gridTemplateColumns = "27rem auto 120rem";

    // analytics when user enters
    trackMarketingEvent("screen_enter", null);
  }, []);

  return (
    <div className="mkc-container">
      <MarketingCampaignsFilters
        apps={apps}
        handleChangeFilter={handleChangeFilter}
        userTypes={userTypes}
        userRoleTypes={userRoleTypes}
        focusAreas={focusAreas}
        {...rest}
      />
      <div className="mkc-table">
        {rest.currentApp && (
          <MarketingCampaignsTable
            fetchNextPage={fetchNextPage}
            totalDBRowCount={totalDbInsights}
            isFetching={isLoading}
            totalFetched={insights.length}
            channels={channels}
            tableData={insights}
            channelId={channelId}
            handleChangeFilter={handleChangeFilter}
            selectedInsight={selectedInsight}
            userTypes={userTypes}
            userRoleTypes={userRoleTypes}
            appId={rest.appId}
            currentApp={rest.currentApp}
          />
        )}
      </div>
    </div>
  );
};

export const MarketingCampaignsPage = withRouter(MarketingCampaignsPageBase);
