import axios from "axios";
import config from "../../shared/config";

export const getBiWeekly = (highlight_id: string) => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/insight/highlights/${highlight_id}`
  return axios.get(url);
};


export const setRateBiWeeklyAPI = (highlight_id: string, rate: 0) => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/insight/highlights/${highlight_id}/rate`

  return axios.post(url, {
    rate
  });
};