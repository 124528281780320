import React, { useState } from 'react'
import ViewTags from "../../components/discoveryView/viewTags/ViewTags";
import { DiscoveryTypeBadge } from "../../components/discoveryCard/discoveryCard"
import { Button } from "antd";
import Icon  from "@ant-design/icons";
import ViewCopyButton from "../../components/discoveryView/viewCopyButton/viewCopyButton";
import SneakPeakInsightModal from "../../components/sneakPeakDiscoveryModal/SneakPeakInsightModal"


import defaultLogo from "../../assets/images/watchful_icon_gray.svg"
import {MARKETING} from "../intelligence/Marketing/configsSubtypes";
import {PL_MARKETING} from "../../shared/consts";

export const DiscoveryCardBig = ({ dis, app, isMarketingDiscovery, handleClick, analytics, source='' }) => {

  return <div className="big-discovery-card-cont" key={dis.id}>
    <div className="versions__list__item-importance-indicator importance-indicator-animating"/>
    <div className="share-button-wrapper">
      <ViewCopyButton
        insightId={dis.id}
        metadata={app}
        analytics={analytics}
        title={dis.title}
        id={dis.id}
        page="overview"
        key={dis.id}
        insightType={dis.type}
      />
    </div>
    <div className="big-discovery-card" onClick={() => handleClick(dis, isMarketingDiscovery, app)}>
      <div className={`dis-img  ${dis.thumbnail ? '' : 'default'}`}>
        <img src={dis.thumbnail || defaultLogo} className={`insight-thumb`} />
      </div>
      <div className="dis-data">
        <h2>{dis.title}</h2>
        <div className="dis-descr">
          {dis.description}
        </div>
        <div className="dis-tags-app-data">
          <ViewTags tags={[dis.labels ? [...dis.labels] : [], 'significant discovery'].flat()} />
          <div className="app-data">
            {app && <div className="app-name-icon">
              <img src={app.thumbnail} />
              {app.name}
            </div>
            }

            <DiscoveryTypeBadge isMarketingDiscovery={isMarketingDiscovery} />
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default function UnseenSignificantDiscoveries({
  discoveries,
  apps,
  handleSeeAll,
  analytics,
  markAllAsSeenHandle,
  markAllAsSeenRequestStatus,
  product,
  user,
  source,
}) {

  const [currentItem, currentItemChange] = useState(null)
  const [visibilitySneakPeak, visibilitySneakPeakChange] = useState(false)

  const handleClick = (dis, isMarketingDiscovery, app) => {

    visibilitySneakPeakChange(true)
    currentItemChange({
      item: {
        ...dis,
        ...app,
        metadata: app,
        app_id: app.id,
        id: dis.id,
        insightId: dis.id,
      },
      key: 0,
      insightOrReport: 'insight',
      isMarketingDiscovery: isMarketingDiscovery
    })

  }

  if ((!discoveries || discoveries.length == 0) && !visibilitySneakPeak) {
    return null
  }

  return (
    <div className="unseen-sig-dis">
      <h3 className="title">New Significant Discoveries</h3>
      <div className="mark-all-seen-btn">
        <Button className="ant-btn-dark mark-all-dis-sign-seen" onClick={markAllAsSeenHandle}>
          Mark All as Seen
          {markAllAsSeenRequestStatus === 'loading' && <Icon type="loading" />}
        </Button>
      </div>
      <div className="big-discovery-card-wrapper">
        {discoveries.slice(0, 2).map(dis => {
          const app = apps.find(a => a.id == dis.app_id)
          const isMarketingDiscovery = dis.platform === PL_MARKETING

          return <DiscoveryCardBig app={app} dis={dis} isMarketingDiscovery={isMarketingDiscovery} handleClick={handleClick} analytics={analytics} user={user} source={source}/>
        })}
      </div>

      <div className="unseen-sig-dis-all-btn">
        <Button className="ant-btn-dark" onClick={handleSeeAll}>
          See All Significant Discoveries on {product.name}
        </Button>
      </div>

      {currentItem && currentItem.item &&
        <SneakPeakInsightModal
          closeModalAndRemoveViewedInsights={() => visibilitySneakPeakChange(false)}
          currentItem={currentItem}
          //changeItem={changeItem}
          visibility={visibilitySneakPeak}
          insights={[currentItem]}

          analytics={analytics}
          user={user}
          isMarketingDiscovery={currentItem.isMarketingDiscovery}
          source={source}
        />
      }

    </div>
  )
}
