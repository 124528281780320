import React from "react";
import "./styles.scss";
import chevron_left from "assets/images/icons/context-timeline/chevron-left.svg";
import chevron_right from "assets/images/icons/context-timeline/chevron-right.svg";

type Props = {
  total: number;
  outOfTotal: number;
  onNext: () => void;
  onPrevious: () => void;
};

export const SelectedEventCount = ({
  onNext,
  onPrevious,
  outOfTotal,
  total,
}: Props) => {
  return (
    <div className="sec-container">
      <span>
        ({outOfTotal}/{total})
      </span>
      <div className="arrows">
        <img src={chevron_left} className="arrow" onClick={onPrevious} />
        <img src={chevron_right} className="arrow" onClick={onNext} />
      </div>
    </div>
  );
};
