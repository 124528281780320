import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router";
import TopicsView from "./TopicsView"
import moment from 'moment'
import { MATCH_TYPES } from "../intelligence/Marketing/FilterTypes";

import { getTopLabels } from "../../redux/acts/labels"
import { useDebounce } from "../../helpers/customHooks"
import { getIntelDiscoveriesLabels } from "../../redux/acts/labels"
import { getDiscoveries } from "../../redux/actions/overviewActions"
import { PL_ANDROID, PL_IOS, PL_WINDOWS, PL_CHROME, daysAgoList, PL_WEB } from "../../shared/consts";

import { useIsFeatureByUserID } from "../../helpers/features"




export const TopicsPage = ({
  getTopLabels,
  getIntelDiscoveriesLabels,
  getDiscoveries,

  products,
  analytics,
  user,
  userDiscoveries,
  topLabels,
  tagsList,
  history,
  apps,

  loadingFilteredDiscoveries,
  discoveries
}) => {

  const TopicsPageAccess = useIsFeatureByUserID('topics', user.email)

  const url = new URLSearchParams(history.location.search)

  const u = (k) => url.get(k)

  const [daysAgo, daysAgoChange] = useState(u('daysAgo') ? Number(u('daysAgo')) : 30)
  const [customRange, сustomRangeСhange] = useState(u('customRange') ? true : false)

  const [customRangeTime, customRangeTimeChange] = useState(u('customRangeTime') ? [moment(u('customRangeTime').split(",")[0]),

    moment(u('customRangeTime').split(",")[1])] : [moment(), moment()])
  const [platforms, platformsChange] = useState(u('platforms') ? u('platforms').split(",") : [])
  const [filterTags, filterTagsChange] = useState(u('label_ids_any') ? u('label_ids_any').split(",") : u('label_ids_all') ? u('label_ids_all').split(",") : [])
  const [filterTagsMatch, setFilterTagsMatch] = useState(u('label_ids_any') ? MATCH_TYPES[0] : u('label_ids_all') ? MATCH_TYPES[1] : MATCH_TYPES[0])

  const [currentProducts, currentProductsChange] = useState(u('product_ids') ? u('product_ids').split(",").map(a_id => Number(a_id)) : [])

  const [currentPage, currentPageChange] = useState(u('currentPage') ? Number(u('currentPage')) : 1)
  const [currentPageSize, currentPageSizeChange] = useState(u('currentPageSize') ? Number(u('currentPageSize')) : 40)

  const updateURL = () => {
    let dataForURL = {
      daysAgo,
      platforms,
      product_ids: currentProducts.join(),
      customRange: customRange ? '1' : '',
      customRangeTime: customRangeTime.map(date => date.format('YYYY-MM-DD')),
      [filterTagsMatch === MATCH_TYPES[0] ? 'label_ids_any' : 'label_ids_all']: filterTags.join()
    }
    let getsURL = new URLSearchParams(dataForURL).toString()
    history.replace(`${history.location.pathname}?${getsURL}`);
  }

  const requestDiscoveries = (page = 1) => {
    const appsFromProducts =
      currentProducts.reduce((acc, product_id) => [...acc, ...products.find(p => p.id === product_id).apps.map(app => app.id)], []).join()
    const vars = {
      num_items: currentPageSize,
      page_num: page,
      app_id_only: appsFromProducts,
      type: ['ab_test', 'indication', 'screen_change', 'new_feature', 'removed_feature']
    }

    if (customRange) {
      vars['start_time_gte'] = customRangeTime[0].format('YYYY-MM-DD')
      vars['start_time_lte'] = moment(customRangeTime[1]).format('YYYY-MM-DD')
    } else {
      vars['start_time_gte'] = moment().subtract(daysAgo, 'days').format('YYYY-MM-DD')
      vars['start_time_lte'] = moment().format('YYYY-MM-DD')
    }
    if (platforms.length) {
      vars['platform'] = platforms
    } else {
      vars['platform'] = [PL_ANDROID, PL_IOS, PL_WINDOWS, PL_CHROME, PL_WEB]
    }

    const labelsField = filterTagsMatch === MATCH_TYPES[0] ? 'label_ids_any' : 'label_ids_all';
    if (filterTags.length > 0) {
      vars[labelsField] = filterTags.join()
    } else {
      vars[labelsField] = ''
    }

    const gets = new URLSearchParams(vars).toString()
    getDiscoveries({
      gets: gets
    })
    analytics.analytic('get discoveries', vars)
  }

  const requestTopLabels = () => {
    const vars = {}
    if (customRange) {
      vars['from_date'] = customRangeTime[0].format('YYYY-MM-DD')
      vars['until_date'] = moment(customRangeTime[1]).add(1, 'days').format('YYYY-MM-DD')
    } else {
      vars['from_date'] = moment().subtract(daysAgo, 'days').format('YYYY-MM-DD')
      vars['until_date'] = moment().format('YYYY-MM-DD')
    }
    if (platforms.length) {
      vars['platforms'] = platforms
    } else {
      vars['platforms'] = [PL_ANDROID, PL_IOS, PL_WINDOWS, PL_CHROME, PL_WEB]
    }
    if (currentProducts.length > 0) {
      vars['product_ids'] = currentProducts.join()
    }
    const gets = new URLSearchParams(vars).toString()

    getTopLabels({
      gets: gets
    })
    getIntelDiscoveriesLabels({
      gets: gets
    })
    analytics.analytic('get top labels', vars)
  }

  const debouncedRequestDiscoveries = useDebounce(requestDiscoveries, 800)
  const debouncedRequestLabels = useDebounce(requestTopLabels, 800)

  useEffect(debouncedRequestDiscoveries, [
    daysAgo,
    customRange,
    customRangeTime,
    filterTags,
    filterTagsMatch,
    platforms,
    currentProducts
  ])

  useEffect(debouncedRequestLabels, [
    daysAgo,
    customRange,
    customRangeTime,
    filterTags,
    platforms,
    currentProducts,
  ])

  useEffect(updateURL, [
    daysAgo,
    customRange,
    customRangeTime,
    filterTags,
    filterTagsMatch,
    platforms,
    currentProducts,
  ])

  const onShowSizeChange = (current, pageSize) => {
    currentPageSizeChange(pageSize)
    currentPageChange(1)
  }

  const onPageChange = (page) => {
    currentPageChange(page)
    requestDiscoveries(page)
  }


  if (!TopicsPageAccess) {
    return null
  }

  return (
    <TopicsView
      products={products}
      analytics={analytics}
      user={user}
      userDiscoveries={userDiscoveries}
      topLabels={topLabels}
      apps={apps}

      tagsList={tagsList}
      filterTags={filterTags}
      filterTagsChange={filterTagsChange}
      filterTagsMatch={filterTagsMatch}
      setFilterTagsMatch={setFilterTagsMatch}
      platforms={platforms}
      platformsChange={platformsChange}
      daysAgo={daysAgo}
      daysAgoChange={daysAgoChange}
      customRange={customRange}
      сustomRangeСhange={сustomRangeСhange}
      customRangeTime={customRangeTime}
      customRangeTimeChange={customRangeTimeChange}

      currentProducts={currentProducts}
      currentProductsChange={currentProductsChange}

      loadingFilteredDiscoveries={loadingFilteredDiscoveries}
      discoveries={discoveries}
      currentPage={currentPage}
      currentPageSize={currentPageSize}
      onShowSizeChange={onShowSizeChange}
      onPageChange={onPageChange}

    />
  )
}

const mapStateToProps = (state) => ({
  products: state.overview.products,
  analytics: state.appData.user.analytics,
  apps: state.appData.apps,
  user: state.appData.user,
  userDiscoveries: state.userDiscoveries.discoveries,
  topLabels: state.labels.topTagsWithProducts,
  tagsList: state.labels.intelTagsList,
  loadingFilteredDiscoveries: state.overview.loadingFilteredDiscoveries,
  discoveries: state.overview.filteredDiscoveries,
})

const mapDispatchToProps = {
  getTopLabels,
  getIntelDiscoveriesLabels,
  getDiscoveries
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopicsPage))