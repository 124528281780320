import React, { Component } from "react";

import axios from "axios";
import "./common/mixpanel/Mixpanel";
import { FlagsProvider } from "react-unleash-flags";

import Login from "./containers/login/Login";
import GuestShare from "./containers/GuestShare/GuestShare"
import ChangePWDOnFirstLogin from "./containers/login/ChangePWDOnFirstLogin/ChangePWDOnFirstLogin";
import Dashboard from "./containers/dashboard/Dashboard";

import NotFound from "./common/notFound/NotFound";
import MainLoader from "./common/mainLoader/MainLoader";
import AcceptingInvite from "./containers/AcceptingInvite"
import GuestEmailValidation from "./containers/GuestEmailValidation"

import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import "./sass-config/main.scss";
import './monitoring/datadog'

import ChangePageMonitor from "./ChangePageMonitor"
import { updateViewportMeta } from "shared/mobile-fix";

const unleashConfig = {
  appName: "production",
  url: "https://gitlab.com/api/v4/feature_flags/unleash/9904514",
  instanceId: "56n6ziWU7DMXsudNjw8U",
}

export const updateAxiosToken = (userToken) => {
  axios.interceptors.request.use(config => {
    if (
      /api(-staging|-dev|-integ-1-6715qvatlq|)\..*watchful/.exec(config.url) ||
      config.url.includes("localhost")
    ) {
      // Attach the token to each of the calls to the watchful api
      config.headers.Authorization = `Bearer ${userToken}`;
    }
    return config;
  });
}

class App extends Component {
  // TODO: remove mobile fix
  componentDidMount() {
    window.addEventListener('resize', updateViewportMeta);
    updateViewportMeta();
    setTimeout(() => updateViewportMeta(), 3000)
  }

  // TODO: remove mobile fix
  componentWillUnmount() {
    window.removeEventListener('resize', updateViewportMeta);
  }

  render() {
    return (
      <BrowserRouter>
        <LastLocationProvider>
          <FlagsProvider config={unleashConfig}>
            <Route component={ChangePageMonitor} />
            <div className="App">
              <Switch>
                {/* Basically a 404 error message. 2022 - it doesn't work, need to change design */}
                <Route exact path="/404" component={NotFound} />
                

                <Route exact path="/link/:id" component={GuestShare} />
                <Route path="/link-bi-weekly/:highlight_id/" component={GuestShare} />


                <Route exact path="/changePWD/:email/:nonce" component={ChangePWDOnFirstLogin} />
                <Route exact path="/login" component={Login} />

                <Route exact path="/invitation/:invitation_id" component={AcceptingInvite} />
                <Route exact path="/guest-email-validation/:guest_email_valid_token" component={GuestEmailValidation} />

                <Route path="/" component={Dashboard} />
                {/* More routes are configured in the dashboard component */}
              </Switch>
              <MainLoader global={true} />
            </div>
          </FlagsProvider>
        </LastLocationProvider>
      </BrowserRouter>
    );
  }
}


export default App;
