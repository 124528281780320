import React from 'react'
import { Button, Modal } from 'antd';

import sub_hint_img_png from "../../../assets/images/sub-hint-img.png"
import sub_hint_red_arrow_ico from "../../../assets/images/sub-hint-red-arrow.svg"

export default function SubscriptionInitHint({
  visible,
  onClose,
  onGoToSubscriptions
}) {

  return (

    <Modal
      title={null}
      open={visible}
      className="modal-subscription-hint"
      width={500}
      footer={null}
      onCancel={onClose}
    >
      <div className="subscription-hint-wrapper">
        <div className="image-wrapper">
          <img src={sub_hint_img_png} />
        </div>
        <div className="subscription-hint-right">
          <div className="subscription-hint-right-header">
            <div className="subscription-hint-red-arrow">
              <img src={sub_hint_red_arrow_ico} />
            </div>
            <h2>Your Subscription was created!</h2>
          </div>

          <div className="subscription-hint-descr">
            You can view and manage your subscriptions through the <strong>"My Subscriptions"</strong>  page located on top of the page inside your personal area.
          </div>
          <div className="subscription-hint-buttons">
            <Button key="done" type="default" onClick={onClose}>
              Done
            </Button>
            <Button key="done" type="primary" onClick={onGoToSubscriptions}>
              Go to My subscriptions
            </Button>

          </div>

        </div>
      </div>
    </Modal>)
}