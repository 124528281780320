import React, { ReactElement } from 'react'
import { Select } from 'antd';

interface Props {
  style?: any,
  className?: string,
  popupClassName?: string,
  value?: string,
  onChange: (value: string) => void,
  children: any,
  black?: boolean
}

const CSelectBox = ({
  style,
  className = '',
  popupClassName = '',
  value,
  onChange,
  children,
  black = false,
}: Props): ReactElement => {
  return (
    <div className="carbon-select-box-wrapper">
      <Select
        className={`carbon-select ${black ? 'carbon-select-black' : 'carbon-select-white'} ${className}`}
        popupClassName={`carbon-select-dropdown ${black ? 'carbon-select-black-dropdown' : 'carbon-select-white-dropdown'} ${popupClassName}`}
        style={style}
        value={value}
        onChange={onChange}
      >
        {children}
      </Select>
    </div>
  )
}

CSelectBox.Option = Select.Option

export default CSelectBox