import {
  takeLatest,
  call,
  put,
  fork, takeEvery,
} from "redux-saga/effects";
import * as api from "../api/biWeeklyApi";
import { getBiWeeklyHighlightsSuccess } from '../acts/biweekly'

import * as aT from "../actions/_actionsTypes";


function* getBiWeeklySagas(action: object) {
  // @ts-ignore
  const result = yield call(api.getBiWeekly, action.payload)
  yield put(getBiWeeklyHighlightsSuccess(result.data))
}

function* setRateBiWeeklySagas(action: object) {
  // @ts-ignore
  yield call(api.setRateBiWeeklyAPI, action.payload.highlight_id, action.payload.rate)
}

function* watchBiWeekly() {
  yield takeEvery(aT.GET_BIWEEKLY_BY_ID, getBiWeeklySagas);
}

function* watchSetRateBiWeekly() {
  yield takeEvery(aT.SET_RATE_BIWEEKLY_BY_ID, setRateBiWeeklySagas);
}

const biWeeklySagas = [
  fork(watchBiWeekly),
  fork(watchSetRateBiWeekly)
];

export default biWeeklySagas;

