import { createAction, createReducer } from 'redux-act';
import {
  IReport,
  IReportsState,
  IShare,
} from '../../interfaces/Reports';

export const GET_REPORTS = 'GET_REPORTS'
export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS'
export const GET_REPORTS_FAILURE = 'GET_REPORTS_FAILURE'
export const SEND_REPORT_SHARE = 'SEND_REPORT_SHARE';
export const SEND_REPORT_SHARE_SUCCESS = 'SEND_REPORT_SHARE_SUCCESS';
export const SEND_REPORT_SHARE_FAILED = 'SEND_REPORT_SHARE_FAILED';

export const getReports = createAction(GET_REPORTS);
export const getReportsSuccess = createAction(GET_REPORTS_SUCCESS, (res: any) => res.data);
export const getReportsFailure = createAction(GET_REPORTS_FAILURE);

export const SET_REPORTS_NOTIFICATIONS_SEEN = 'SET_REPORTS_NOTIFICATIONS_SEEN'
export const SET_REPORTS_NOTIFICATIONS_SEEN_SUCCESS = 'SET_REPORTS_NOTIFICATIONS_SEEN_SUCCESS'

export const setReportsNotificationsSeen = createAction(SET_REPORTS_NOTIFICATIONS_SEEN, (data: number[]) => data);
export const setReportsNotificationsSeenSuccess = createAction(SET_REPORTS_NOTIFICATIONS_SEEN_SUCCESS, (res: any) => res.data);

export const SET_REPORTS_NOTIFICATIONS_READ = 'SET_REPORTS_NOTIFICATIONS_READ'
export const SET_REPORTS_NOTIFICATIONS_READ_SUCCESS = 'SET_REPORTS_NOTIFICATIONS_READ_SUCCESS'

export const setReportsNotificationsRead = createAction(SET_REPORTS_NOTIFICATIONS_READ, (data: number[]) => data);
export const setReportsNotificationsReadSuccess = createAction(SET_REPORTS_NOTIFICATIONS_READ_SUCCESS, (res: any) => res.data);

export const sendReportShare = createAction(SEND_REPORT_SHARE, (data: IShare) => data);
export const sendReportShareSuccess = createAction(SEND_REPORT_SHARE_SUCCESS);
export const sendReportShareFailed = createAction(SEND_REPORT_SHARE_FAILED);

const initState: IReportsState = {
  reports: [],
  markReportsAllSeenLoading: false,
  sharingReport: false,
}

export const reportReducer = createReducer({
  [GET_REPORTS_SUCCESS]: (state: IReportsState, reports: IReport[]): IReportsState => {
    return {
      ...state,
      reports,
      markReportsAllSeenLoading: false
    }
  },
  [SET_REPORTS_NOTIFICATIONS_SEEN_SUCCESS]: (state: IReportsState, payload: any, field: string) => updateReport(state, payload, 'app_seen_at'),
  [SET_REPORTS_NOTIFICATIONS_READ_SUCCESS]: (state: IReportsState, payload: any, field: string) => updateReport(state, payload, 'app_read_at'),
  [SEND_REPORT_SHARE]: (state: IReportsState, payload: any) => {
    return {
      ...state,
      sharingReport: true
    }
  },
  [SEND_REPORT_SHARE_SUCCESS]: (state: IReportsState, payload: any) => {
    return {
      ...state,
      sharingReport: false
    }
  },
  [SEND_REPORT_SHARE_FAILED]: (state: IReportsState, payload: any) => {
    return {
      ...state,
      sharingReport: false
    }
  },

}, initState);


const updateReport = (state: IReportsState, payload: any, field: string): IReportsState => {
  let modReports = [...state.reports]
  payload.forEach((n: any) => {
    let index = modReports.findIndex(md => md.id == n.report_id)
    if (index !== -1) {
      //@ts-ignore
      modReports[index][field] = n[field]
    }
  })
  return {
    ...state,
    reports: modReports,
    markReportsAllSeenLoading: false,
  }

}