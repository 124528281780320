import React, { Component, useEffect, useState, FC, ReactElement } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { getAppData } from "../../redux/actions/dashboardActions";
import { getAllAppsVersionInfo } from "../../redux/actions/overviewActions"
import { getDiscoveriesByProduct } from "../../redux/acts/product_intel"
import { getGraphsByApp } from "../../redux/acts/graphs"

import { getTrackedSince } from "../../redux/actions/productActions";
import { getDiscoveriesLabels } from "../../redux/acts/labels"

import { useIsFeatureByUserID } from "../../helpers/features"

import IntelligenceAppView from "./IntelligenceProductView"
import { PL_MARKETING } from "../../shared/consts";


const IntelligenceProductPage = ({
  user,
  history,
  match,
  tagsList,
  products,
  analytics,
  getDiscoveriesLabels,
  getDiscoveriesByProduct,
  productsWithDiscoveries,
  unSeenCriticalInsights,
  getAllAppsVersionInfo,
  relevantInsights,

  getGraphsByApp,
  allInsights,
  userData,
  appsWithGraphs,
  userDiscoveries
}) => {

  const { product_id } = match.params;

  const appGraphAccess = useIsFeatureByUserID('app_graph', user.email)

  useEffect(() => {
    if (!tagsList) {
      getDiscoveriesLabels()
    }
    if (!relevantInsights) {
      getAllAppsVersionInfo()
    }
  }, [])


  useEffect(() => {
    if (match.params.product_id && !getDiscoveriesByProduct[Number(match.params.product_id)]) {
      getDiscoveriesByProduct(match.params.product_id)
    }
  }, [match.params.product_id])

  const pr = productsWithDiscoveries[product_id]

  return <div className="intel-product-wrapper">
    <IntelligenceAppView
      products={products ? products.filter(p => p.apps.find(a => a.has_experiments) && p.apps.find(a => a.platform !== PL_MARKETING)) : []}
      product={pr}
      //product={pr ? Object.keys(pr).reduce((acc, appID) => pr[appID].versions.find(v => v.is_visible) ? { ...acc, appID: pr[appID] } : acc, {}) : null}
      productCommonData={products.find(p => p.id === Number(product_id))}
      product_id={product_id}
      unSeenCriticalInsights={unSeenCriticalInsights}
      tagsList={tagsList}
      analytics={analytics}
      getGraphsByApp={getGraphsByApp}
      allInsights={allInsights}
      appGraphAccess={appGraphAccess}
      user={user}
      userData={userData}
      appsWithGraphs={appsWithGraphs}
      relevantInsights={relevantInsights}
      userDiscoveries={userDiscoveries}
    />
  </div>
}



export default withRouter(
  connect(state => {
    return {
      apps: state.appData.apps,
      onlyPerformanceMode: state.appData.onlyPerformanceMode,
      analytics: state.appData.user.analytics,
      user: state.appData.user,
      products: state.overview.products,
      unSeenCriticalInsights: state.overview.unSeenCriticalInsights,
      productTrackedSince: state.overview.productTrackedSince,
      relevantInsights: state.overview.relevantInsights ? state.overview.relevantInsights.map(el => ({
        ...el.insight,
        ...el.metadata,
        metadata: el.metadata,
        insightId: el.insight.id
      })) : null,
      tagsList: state.labels.tagsList,
      userData: state.userData,
      allInsights: state.appData.allInsights,
      appsWithGraphs: state.graphs.appsWithGraphs,
      productsWithDiscoveries: state.productDiscoveries.productsWithDiscoveries,
      userDiscoveries: state.userDiscoveries.discoveries
    }
  }, {
    getAppData,
    getAllAppsVersionInfo,
    getDiscoveriesLabels,
    getTrackedSince,
    getGraphsByApp,
    getDiscoveriesByProduct,
  })(IntelligenceProductPage)
)
