

import { SET_VIEWED_STATUS_SUCCESS, SET_DISCOVERY_RATE_SUCCESS, INSIGHT_LIKE_INTERACTION_SUCCESS } from "../actions/_actionsTypes";
import { createAction } from 'redux-act';

export const GET_USER_DISCOVERIES = 'GET_USER_DISCOVERIES'
export const GET_USER_DISCOVERIES_SUCCESS = 'GET_USER_DISCOVERIES_SUCCESS'
export const getUserDiscoveries = createAction(GET_USER_DISCOVERIES);
export const getUserDiscoveriesSuccess = createAction(GET_USER_DISCOVERIES_SUCCESS, (data) => data);

export const POST_USER_DISCOVERY_COMMENT = 'POST_USER_DISCOVERY_COMMENT'
export const POST_USER_DISCOVERY_COMMENT_SUCCESS = 'POST_USER_DISCOVERY_COMMENT_SUCCESS'
export const postUserDiscoveryComment = createAction(POST_USER_DISCOVERY_COMMENT);
export const postUserDiscoveryCommentSuccess = createAction(POST_USER_DISCOVERY_COMMENT_SUCCESS, (data) => data);

export const EDIT_USER_DISCOVERY_COMMENT = 'EDIT_USER_DISCOVERY_COMMENT'
export const EDIT_USER_DISCOVERY_COMMENT_SUCCESS = 'EDIT_USER_DISCOVERY_COMMENT_SUCCESS'
export const editUserDiscoveryComment = createAction(EDIT_USER_DISCOVERY_COMMENT, (data) => data);
export const editUserDiscoveryCommentSuccess = createAction(EDIT_USER_DISCOVERY_COMMENT_SUCCESS, (data) => data);

export const DELETE_USER_DISCOVERY_COMMENT = 'DELETE_USER_DISCOVERY_COMMENT'
export const DELETE_USER_DISCOVERY_COMMENT_SUCCESS = 'DELETE_USER_DISCOVERY_COMMENT_SUCCESS'
export const deleteUserDiscoveryComment = createAction(DELETE_USER_DISCOVERY_COMMENT, (data) => data);
export const deleteUserDiscoveryCommentSuccess = createAction(DELETE_USER_DISCOVERY_COMMENT_SUCCESS, (data) => data);

const INIT_STATE = {
  discoveries: []
};

export default function (state = INIT_STATE, action) {
  switch (action.type) {
  case GET_USER_DISCOVERIES_SUCCESS: {
    return {
      ...state,
      discoveries: action.payload.data
    };
  }
  case SET_VIEWED_STATUS_SUCCESS: {
    const discoveriesCopy = state.discoveries.map(d => d.insight_id === Number(action.payload.insightID) ?
      {
        ...d,
        read_at: action.payload.readAt
      }
      :
      d
    );
    if (!discoveriesCopy.find(d => d.insight_id === Number(action.payload.insightID))) {
      discoveriesCopy.push({
        insight_id: Number(action.payload.insightID),
        read_at: action.payload.readAt
      })
    }
    return {
      ...state,
      discoveries: discoveriesCopy
    };
  }

  case INSIGHT_LIKE_INTERACTION_SUCCESS: {
    const insightID = Number(Object.keys(action.payload)[0])
    const likedAt = Object.values(action.payload)[0]

    const discoveriesCopy = state.discoveries.map(d => d.insight_id === insightID ?
      {
        ...d,
        liked_at: likedAt
      }
      :
      d
    );
    if (!discoveriesCopy.find(d => d.insight_id === insightID)) {
      discoveriesCopy.push({
        insight_id: insightID,
        liked_at: likedAt
      })
    }
    return {
      ...state,
      discoveries: discoveriesCopy
    };
  }

  case SET_DISCOVERY_RATE_SUCCESS: {
    let updatedData = action.payload.data
    const discoveriesCopy = state.discoveries.map(d => d.insight_id === Number(updatedData.id) ?
      {
        ...d,
        rating: updatedData.rating_user,
        rating_count: updatedData.rating_count,
        rating_global: updatedData.rating_global,
        rating_user: updatedData.rating_user
      }
      :
      d
    );
    if (!discoveriesCopy.find(d => d.insight_id === Number(updatedData.id))) {
      discoveriesCopy.push({
        insight_id: Number(updatedData.id),
        rating: updatedData.rating_user
      })
    }
    return {
      ...state,
      discoveries: discoveriesCopy
    };
  }

  default:
    return state;
  }
}
