import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { Popover, Button, Modal } from "antd"
import Icon  from "@ant-design/icons";

import { getProducts, getProductsStats, setAllDiscoveriesForProductAsSeen } from "../../redux/actions/overviewActions"
import { getPerformanceRanks, getPerformanceRanksCompare, clearPerformancePageData } from "../../redux/actions/performanceActions"

import { getSignificantUnseenDiscoveries, getDiscoveries } from "../../redux/actions/productActions"

import GalleryDiscoveries from '../dash-discoveries/GalleryDiscoveries'
import FilterType from '../dash-discoveries/FilterType'
import NewLoader from "../../common/NewLoader/NewLoader";
import VersionsEmpty from "../products-discoveries/versionsEmpty/versionsEmpty"
import { setNotificationsRead } from "../../redux/acts/notifications"
import {
  getPerformanceEventsData,
} from "../../redux/actions/performanceActions";

import {ALL_TIME, PL_MARKETING} from '../../shared/consts.js'
import { useDebounce } from "../../helpers/customHooks"
import StackedBar from "../../components/product/StackedBar"
import ProductStatusBar from "../../components/product/ProductStatusBar";
import { getUnseenCriticalInsights } from "../../redux/actions/overviewActions"
import BenchRanks from "../../components/product/BenchRanks"
import ProductNotifications from "./ProductNotifications"
import UnseenSignificantDiscoveries from "./UnseenSignificantDiscoveries"

import defaultDataSet from './constForChartBar'
import { getExistingPlatforms, isMarketingForProduct } from '../../helpers/productHelper';

import PerformanceEventHistoryGraph from "./PerformanceEventHistoryGraph";


import { datadogRum } from '@datadog/browser-rum';

const { confirm } = Modal;
const intelligenceRegExp = '(ab_test|screen_change|indication|new_feature|remove_feature)';
export const ProductsPage = ({
  products,
  history,
  match,
  apps,
  perfApps,
  productStats,
  analytics,
  user,
  userData,
  discoveries,
  loadingFilteredDiscoveries,
  unSeenCriticalInsights,
  markAllAsSeenRequestStatus,
  getDiscoveries,
  getProducts,
  getProductsStats,
  getUnseenCriticalInsights,
  setAllDiscoveriesForProductAsSeen,

  getSignificantUnseenDiscoveries,
  significantUnseenDiscoveries,

  deviceModels,
  categories,
  eventTypes,
  categoriesEvents,
  categoriesDevices,
  rawRanks,
  getPerformanceRanks,
  getPerformanceRanksCompare,
  compareDate,
  ranksLoading,

  currentEvents,
  getPerformanceEventsData,
  clearPerformancePageData,
  currentRequests,
  setNotificationsRead,
  markAllSeenLoading,
  locations,
}) => {
  const productID = Number(match.params.productID);

  const [modProductStats, modProductStatsChange] = useState(null)
  const [chartLabels, chartLabelsChange] = useState([])
  const [product, productChange] = useState(null)

  const [currentPage, currentPageChange] = useState(1)
  const [currentPageSize, currentPageSizeChange] = useState(40)

  const [filterIntelEnabled, filterIntelEnabledChange] = useState(false)
  const [filterMarkEnabled, filterMarkEnabledChange] = useState(false)
  const [filterType, filterTypeChange] = useState([])
  const [filterEmailSubtype, filterEmailSubtypeChange] = useState([])
  const [filterInAppSubtype, filterInAppSubtypeChange] = useState([])
  const [filterPushNotificationSubtype, filterPushNotificationSubtypeChange] = useState([])

  const [sortOrder, sortOrderChange] = useState('desc')

  const [formatRanks, formatRanksChange] = useState(null)
  const [compareRanks, compareRanksChange] = useState(null)

  const [graphData, graphDataChange] = useState(null)

  let isIntelligenceAvailable = product ? getExistingPlatforms(product).some(el => el) : false;
  let isMarketingAvailable = product ? isMarketingForProduct(product) : false;
  let isBenchmarkOnly = !isIntelligenceAvailable && !isMarketingAvailable

  useEffect(()=> {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, [])
  const handleScroll = (e) => {
    let tooltip = document.querySelector('.chartjs-tooltip-product')
    if (tooltip){
      tooltip.remove()
    }
  }


  useEffect(() => {
    graphDataChange(currentEvents)
  }, [currentEvents])

  useEffect(() => {
    if (!categoriesDevices || !product || !product.apps.some(app => app.has_performance)) {
      return
    }

    const productAppAndroid = product.apps.find(a => a.has_performance && a.platform === 'Android')
    const appAndroid = productAppAndroid ? perfApps.find(a => a.id === productAppAndroid.id && a.platform === 'Android') : null

    const productAppIOS = product.apps.find(a => a.has_performance && a.platform === 'iOS')
    const appIOS = productAppIOS ? perfApps.find(a => a.id === productAppIOS.id && a.platform === 'iOS') : null

    const androidDevice = appAndroid?.device_models.sort((a, b) => {
      if (a.priority > b.priority) return 1
      if (a.priority < b.priority) return -1
      return 0
    }
    )[0]
    const iosDevice = appIOS?.device_models.sort((a, b) => a.priority > b.priority ? 1 : -1)[0]

    if (!iosDevice && !androidDevice) {
      return
    }

    const filters = {
      model_in: `${iosDevice ? `${iosDevice.model},` : ''}${androidDevice ? androidDevice.model : ''}`,
    }
    filters.from_date = moment().subtract(2, "months").format("YYYY-MM-DD");
    getPerformanceRanks(filters)

    //compare between daysAgo and ~2months before
    let dates = {
      begin1range: moment().subtract(120, "days").format("YYYY-MM-DD"),
      end1range: moment().subtract(61, "days").format("YYYY-MM-DD"),
      begin2range: moment().subtract(60, "days").format("YYYY-MM-DD"),
      end2range: moment().format("YYYY-MM-DD"),
    };
    getPerformanceRanksCompare(dates, `${iosDevice ? `${iosDevice.model},` : ''}${androidDevice ? androidDevice.model : ''}`, product.apps.map(a => a.id).join(','))
  }, [categoriesDevices, product])

  useEffect(() => {
    if (products) {
      productChange(products.find(p => p.id === productID))
    } else {
      getProducts()
    }

    getProductsStats({ productID })
    if (!process.env.JEST_WORKER_ID) {
      analytics.analytic('ProductsPage', { productID: productID });
    }
  }, [productID]);

  useEffect(() => {
    if (compareDate) {
      compareRanksChange(compareDate)
    }
  }, [compareDate])

  useEffect(() => {
    if (!rawRanks) {
      return
    }

    let modRanks = rawRanks.reduce((acc, t) => {
      if (!acc[t.model]) { acc[t.model] = {} };
      if (acc[t.model][t.event_name]) {
        acc[t.model][t.event_name].push(t)
      } else {
        acc[t.model][t.event_name] = [t]
      };
      return acc
    }, {})
    formatRanksChange(modRanks)
  }, [rawRanks])

  useEffect(() => {
    if (!products) {
      return
    }
    productChange(products.find(p => p.id === productID))
  }, [products]);

  useEffect(() => {
    graphDataChange(null)
    clearPerformancePageData({})
    if (!product) {
      return
    }
    requestDiscoveries()
    getSignificantUnseenDiscoveries({
      apps: product.apps.map(app => app.id)
    })
    if (product.apps.some(app => app.has_performance) && !product.apps.some(app => app.has_experiments) && !product.apps.some(app => app.platform === PL_MARKETING)) {
      console.log('Performance mode only')
      requestTimeline()
    }
  }, [product]);

  useEffect(() => {
    getUnseenCriticalInsights()
  }, [])

  useEffect(() => {
    if (markAllAsSeenRequestStatus === 'completed') {
      requestDiscoveries()
      getSignificantUnseenDiscoveries({
        apps: product.apps.map(app => app.id)
      })
    }
  }, [markAllAsSeenRequestStatus])

  useEffect(() => {
    if (!productStats) { return }
    let modData = defaultDataSet.map(def => ({
      ...def,
      data: productStats.map(pr_data => pr_data.values[def.name] || 0)
    }))
    console.log(productStats)
    modData.max = Math.max(...productStats.map(pr_data => Object.values(pr_data.values).reduce((acc, el) => acc + Number(el), 0) || 0))
    let labels = productStats.map(pr_data =>
      [`${moment(pr_data.bin_dates[0]).format('MMM DD')} - ${moment(pr_data.bin_dates[1]).format('DD')}`.toUpperCase(), moment(pr_data.bin_dates[0]).format('YYYY')])

    modData = modData.filter(data => {
      console.log(data)
      return (isMarketingAvailable && new RegExp('_campaign').test(data.name)) ||
        (isIntelligenceAvailable && new RegExp(intelligenceRegExp).test(data.name))
    })

    modProductStatsChange(modData)
    chartLabelsChange(labels)
  }, [productStats])


  const requestDiscoveries = (page) => {
    if (!product) { return }
    const appsFromProduct = product.apps.map(app => app.id)
    const vars = {
      num_items: currentPageSize,
      app_id_only: appsFromProduct,
      order_by_start_time: sortOrder,
      page_num: page || 1,
      days_ago: ALL_TIME,
      read: 0
    }
    if (filterType.length > 0) {
      vars['type'] = filterType.join()
    } else {
      vars['type'] = ''
    }
    if (filterInAppSubtype.length > 0) {
      vars['type'] += `, inapp_campaign: ${filterInAppSubtype.join('|')}`
    }
    if (filterEmailSubtype.length > 0) {
      vars['type'] += `, email_campaign: ${filterEmailSubtype.join('|')}`
    }
    if (filterPushNotificationSubtype.length > 0) {
      vars['type'] += `, push_notification_campaign: ${filterPushNotificationSubtype.join('|')}`
    }

    if (vars['type'] == '') {
      delete vars['type']
    }
    if (vars['type'] && vars['type'][0] == ',') {
      vars['type'] = vars['type'].slice(1)
    }

    if (!page) {
      currentPageChange(1)
    }

    const gets = new URLSearchParams(vars).toString()

    getDiscoveries({
      gets: gets
    })
  }

  const debouncedRequest = useDebounce(requestDiscoveries, 800)

  useEffect(debouncedRequest,
    [
      // sortOrder,
      currentPageSize,
      filterType,
      filterEmailSubtype,
      filterInAppSubtype,
      filterPushNotificationSubtype,
      filterIntelEnabled,
      filterMarkEnabled,
      sortOrder
    ])

  const requestTimeline = (event = 'launch') => {
    let listEvents = [];

    const productAppAndroid = product.apps.find(a2 => a2.has_performance && a2.platform === 'Android')
    if (productAppAndroid && androidDevice) {
      const appAndroid = perfApps.find(a => a.id === productAppAndroid.id && a.platform === 'Android')
      if (appAndroid) {
        let eventID = appAndroid.performance_events.find(ev => ev.name === event).id
        listEvents.push({
          id: eventID,
          filters: [
            { filterType: 'device_model', filterValue: androidDevice.model },
            { filterType: 'os_version', filterValue: androidDevice.os_version },
            { filterType: 'orchestrator_location', filterValue: userData.locations[0] },
            { filterValue: moment().utc().subtract(30, "days").startOf("day").toISOString(), filterType: "from_date" }
          ]
        })
      }
    }

    const productAppIOS = product.apps.find(a2 => a2.has_performance && a2.platform === 'iOS')
    if (productAppIOS && iosDevice) {
      const appiOS = perfApps.find(a => a.id == productAppIOS.id && a.platform === 'iOS')
      if (appiOS) {
        let eventID = appiOS.performance_events.find(ev => ev.name === event).id
        listEvents.push({
          id: eventID,
          filters: [
            { filterType: 'device_model', filterValue: iosDevice.model },
            { filterType: 'os_version', filterValue: iosDevice.os_version },
            { filterType: 'orchestrator_location', filterValue: userData.locations[0] },
            { filterValue: moment().utc().subtract(30, "days").startOf("day").toISOString(), filterType: "from_date" }
          ]
        })
      }
    }
    getPerformanceEventsData(listEvents);
  }

  const handleChangeEventGraph = (event) => {
    console.log(event)
    requestTimeline(event)
  }

  const onShowSizeChange = (current, pageSize) => {
    currentPageSizeChange(pageSize)
    currentPageChange(1)
  }

  const onPageChange = (page) => {
    currentPageChange(page)
    requestDiscoveries(page)
  }

  const markAllAsSeenHandle = (params = {}) => {
    confirm({
      className: "confirm-mark-seen-dis",
      title: 'Are you sure?',
      content: `This will mark all unseen ${params.relevancy_gte ? 'significant' : ''} dicoveries as seen and will remove them from this section`,
      okText: "I'm Sure",
      centered: true,
      maskClosable: true,
      onOk() {
        setAllDiscoveriesForProductAsSeen({ ...params, productID: product.id })
      },
      okButtonProps: {
        type: 'default',
        className: 'ant-btn-dark'
      },
      onCancel() { },
    });
  }

  const getRelatedEventTypes = () => {
    let relEvents = eventTypes.filter(ev => {
      const relatedEvents = perfApps.filter(app => product.apps.find(a => a.has_performance && a.id === app.id)).reduce((acc, app) => [...acc, ...app.performance_events], [])
      return relatedEvents && relatedEvents.find(event => event.name === ev.name)
    })
    return relEvents
  }

  const handlerNotificationClick = (ntf) => {
    if (ntf.link) {
      try {
        history.push(ntf.link[0] === '/' ? `${ntf.link}` : `/${ntf.link}`)
        analytics.analytic('Click notification',
          {
            type: ntf.action_type,
            link: ntf.link,
            title: ntf.title,
          })

      } catch (er) {
        datadogRum.addError(er, {
          message: 'Click on broken link in notification center',
          link: ntf.link,
          notification_id: ntf.id
        });
      }
    }
    setNotificationsRead([ntf.id])
  }

  const handleMarkAllSeen = (noteIDS) => {
    setNotificationsRead(noteIDS)
  }

  if (!products || !product) {
    return <div className="all-dis-loader">
      <NewLoader />
    </div>
  }

  const selectedProductQTY = filterType.length + filterEmailSubtype.length + filterInAppSubtype.length + filterPushNotificationSubtype.length;
  const androidDevice = categoriesDevices.find(dev => dev.platform === "Android")
  const iosDevice = categoriesDevices.find(dev => dev.platform === "iOS")



  return (
    <div className="products-page">
      <h1 className="h1-title">
        Currently <strong>Tracking</strong>
      </h1>
      <div className="pr-header">
        <div className="img-wrapper">
          <img src={product.thumbnail} />
        </div>
        <div>
          <h2>{product.name}</h2>
          <div className="app-owner">
            by <span className="owner-name">
              {product.apps.find(a => a.developer) ? product.apps.find(app => app.developer).developer : ''}
            </span>
          </div>
        </div>
        <ProductStatusBar
          product={product}
          history={history}
          perfApps={perfApps}
          unSeenCriticalInsights={unSeenCriticalInsights || {}}
        />
      </div>
      <div className="hr-row-3"/>



      {!isBenchmarkOnly && modProductStats && <StackedBar data={{
        labels: chartLabels,
        datasets: modProductStats
      }}
      handleClick={(url) => { setTimeout(() => history.push(url), 100) }} product={product}
      maxValue={modProductStats.max} />}

      <div className="product-changes-wrapper">
        <ProductNotifications
          apps={product.apps.map(a => a.id)}
          productName={product.name}
          handlerNotificationClick={handlerNotificationClick}
          handleMarkAllSeen={handleMarkAllSeen}
          markAllSeenLoading={markAllSeenLoading} />
      </div>

      {product.apps && product.apps.some(app => app.has_performance) && <BenchRanks
        data={formatRanks}
        deviceModels={deviceModels}
        categories={categories}
        product={product}
        categoriesDevices={categoriesDevices}
        eventTypes={eventTypes}
        compareRanks={compareRanks}
        ranksLoading={ranksLoading && currentRequests.length === 0}
        locations={locations}
      />}

      {graphData && (graphData[0] || graphData[1]) && <PerformanceEventHistoryGraph
        isCompareMode={true}

        mainEventMetadata={graphData[0] && {
          ...graphData[0].data,
          name: `Android (${androidDevice ? deviceModels.find(d => d.model === androidDevice.model).product_name : ''})`
        }}
        compareEventMetadata={graphData[1] && {
          ...
          graphData[1].data,
          name: `iOS (${iosDevice ? deviceModels.find(d => d.model === iosDevice.model).product_name : ''})`
        }}
        mainEventHistoryData={graphData[0] ? graphData[0].history.duration_s : []}
        compareEventHistoryData={graphData[1] ? graphData[1].history.duration_s : []}

        isProductPage={true}
        eventTypes={getRelatedEventTypes()}
        handleChangeEventGraph={handleChangeEventGraph}
      />}

      <UnseenSignificantDiscoveries
        discoveries={significantUnseenDiscoveries ? significantUnseenDiscoveries.map(dis => Object.values(dis)[0]) : []}
        apps={product.apps}
        handleSeeAll={() => history.push(`/all-discoveries?product_ids=${product.id}&filterStatus=meaningful`)}
        analytics={analytics}
        markAllAsSeenHandle={() => markAllAsSeenHandle({ relevancy_gte: 1 })}
        markAllAsSeenRequestStatus={markAllAsSeenRequestStatus}
        product={product}
        user={user}
        source={'product_page'}
      />
      {!isBenchmarkOnly && <div className="gallery-discoveries-wrap">
        <h3 className="title">Unseen Discoveries on {product.name}</h3>

        <Button className="ant-btn-dark mark-all-dis-seen" onClick={markAllAsSeenHandle}>
          Mark All as Seen
          {markAllAsSeenRequestStatus === 'loading' && <Icon type="loading" />}
        </Button>
        {discoveries.meta && <Popover placement="bottomRight" title={null} content={
          <FilterType
            filterType={filterType}
            filterTypeChange={filterTypeChange}
            filterEmailSubtype={filterEmailSubtype}
            filterEmailSubtypeChange={filterEmailSubtypeChange}
            filterInAppSubtype={filterInAppSubtype}
            filterInAppSubtypeChange={filterInAppSubtypeChange}
            filterPushNotificationSubtype={filterPushNotificationSubtype}
            filterPushNotificationSubtypeChange={filterPushNotificationSubtypeChange}
            filterIntelEnabled={filterIntelEnabled}
            filterIntelEnabledChange={filterIntelEnabledChange}
            filterMarkEnabled={filterMarkEnabled}
            filterMarkEnabledChange={filterMarkEnabledChange}
            counts={discoveries.meta.num_by_type}
            selectedProductQTY={selectedProductQTY}
            sortOrder={sortOrder}
            sortOrderChange={sortOrderChange}
            page="product-page"
            availableFeatures={{ isMarketingAvailable, isIntelligenceAvailable }}
          />
        } trigger="click" overlayClassName={`filter-wrap all-discoveries all-discoveries-type product-page`}>
          <Button className="filter-btn">
            <Icon type="filter" />
            Filters
            {<span className="filter-count">
              {selectedProductQTY}
            </span>}
          </Button>
        </Popover>}

        {!loadingFilteredDiscoveries && discoveries.data && discoveries.data.length > 0 && <GalleryDiscoveries
          loadingFilteredDiscoveries={loadingFilteredDiscoveries}
          filteredDiscoveries={discoveries.data}
          apps={apps.map(a => a.metadata)}
          currentPage={currentPage}
          onPageChange={onPageChange}
          currentPageSize={currentPageSize}
          onShowSizeChange={onShowSizeChange}
          totalDiscoveries={discoveries.meta.num_total}
          user={user}
          analytics={analytics}
          productName={product.name}
          handleClick={() => history.push(`/all-discoveries?product_ids=${product.id}`)}
          source={'product_page'}
        />


        }
        {!loadingFilteredDiscoveries && discoveries.data && discoveries.data.length === 0 &&
          <VersionsEmpty campaignMode={false} hideDescription={true} />}

        {loadingFilteredDiscoveries &&
          <div className="all-dis-loader">
            <NewLoader />
          </div>}
      </div>
      }
    </div>
  )
}

const mapStateToProps = ({ appData, overview, performance, product, userData, notifications }) => ({
  apps: appData.apps,
  currentRequests: appData.currentRequests,
  analytics: appData.user.analytics,
  user: appData.user,
  userData: userData,
  products: overview.products,
  productStats: overview.productStats,
  discoveries: product.productDiscoveries || [],
  loadingFilteredDiscoveries: product.loadingProductDiscoveries,
  markAllAsSeenRequestStatus: overview.markAllAsSeenRequestStatus,

  perfApps: performance.apps || [],
  deviceModels: performance.deviceModels,
  categories: performance.categories,
  eventTypes: performance.eventTypes || [],

  categoriesEvents: performance.categoriesEvents,
  categoriesDevices: performance.categoriesDevices,
  rawRanks: performance.ranks,
  ranksLoading: performance.ranksLoading,
  compareDate: performance.compareRanks ? performance.compareRanks.comparison : null,

  significantUnseenDiscoveries: product.significantUnseenDiscoveries,

  unSeenCriticalInsights: overview.unSeenCriticalInsights,

  currentEvents: performance.currentEvents,
  markAllSeenLoading: notifications.markAllSeenLoading,
  locations: performance.infrastructure,
})

const mapDispatchToProps = {
  getDiscoveries,
  getProducts,
  getProductsStats,
  setAllDiscoveriesForProductAsSeen,
  getPerformanceRanks,
  getPerformanceRanksCompare,
  getSignificantUnseenDiscoveries,
  getUnseenCriticalInsights,
  getPerformanceEventsData,
  clearPerformancePageData,
  setNotificationsRead
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsPage)
