import React from "react";
import "./UserFlowsFilter.scss";
import { UserFlow } from "../../../../interfaces/UserJourneys";

type Props = {
  flows: UserFlow[];
  selectedFlowId?: number;
  selectUserFlow: (flowId: number) => void;
};

export const UserFlowsFilter = ({
  flows,
  selectUserFlow,
  selectedFlowId,
}: Props) => {
  return (
    <div className="user-flows-filter">
      {flows.map((flow) => (
        <div
          key={flow.id}
          className={`user-flow-filter-item ${
            selectedFlowId === flow.id ? "selected" : ""
          }`}
          onClick={() => selectUserFlow(flow.id)}
        >
          {flow.name}
        </div>
      ))}
    </div>
  );
};
