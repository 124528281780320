import { Drawer, Modal, Spin, Tooltip, message } from "antd";
import ViewCopyButton from "components/discoveryView/viewCopyButton/viewCopyButton";
import MoreOptions from "components/discoveryView/viewHeader/MoreOptions";
import React, { useState } from "react";
import browsers_ico from "assets/images/icons/browsers.svg";
import favorite_ico from "assets/images/icons/favorite.svg";
import favorite_filled_ico from "assets/images/icons/favorite-filled.svg";
import x_circle_ico from "assets/images/icons/x-circle.svg";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import storageService from "services/storageService";
import config from "shared/config";
import dis_error_img_ico from "assets/images/new_design/dis-error-img.svg";
import { isMobileDevice } from "containers/MarketingOverview/utils/helpers";
import vertical_dots_ico from "assets/images/icons/newIntel/vertical-dots.svg";
import share_ico from "assets/images/icons/share.svg";
import translate_ico from "assets/images/icons/translate.svg";
import download_ico from "assets/images/icons/Download.svg";
import { handleShareClick } from "containers/BiWeeklyGallery/BiweeklyUtils";

type Props = {
  insightId: number;
  appId: string;
  appName: string;
  title: string;
  type: string;
  onClose: () => void;
  user: any;
  userDiscoveries?: any[];
  likeInsight?: Function;
  unlikeInsight?: Function;
};

export const ModalActions = ({
  appId,
  appName,
  insightId,
  title,
  type,
  onClose,
  user,
  userDiscoveries,
  likeInsight,
  unlikeInsight,
}: Props) => {
  const isMobile = isMobileDevice();
  const userDis = userDiscoveries?.find(
    (uD: any) => uD.insight_id === insightId
  );
  const isLiked = userDis
    ? (userDis.liked_at && !userDis.unliked_at) ||
      (userDis.unliked_at &&
        moment(userDis.unliked_at).isBefore(moment(userDis.liked_at)))
    : false;
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  const handleOpenInNewTab = () => {
    window.open(window.location.href, "_blank");
  };

  const handleStarClick = () => {
    if (isLiked) {
      unlikeInsight?.({ insightID: insightId });
    } else {
      likeInsight?.({ insightID: insightId });
    }
  };

  const handleDownload = async (format: string) => {
    const storage = new storageService();
    const token = storage.getItem("usertoken");
    const hideLoader = message.loading({
      content: `Rendering and downloading ${format}...`,
      duration: 0,
      icon: (
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                color: "white",
              }}
              spin
            />
          }
        />
      ),
    });

    const res = await fetch(
      `${config.dataApi.backendUrl}/insight/${insightId}/${format}/None/None`,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    if (res.ok) {
      const link = document.createElement("a");
      let blobUrl = "";
      if (format !== "pdf") {
        const blob = await res.blob();
        const newBlob = new Blob([blob]);

        blobUrl = window.URL.createObjectURL(newBlob);
      } else {
        const body = await res.json();
        blobUrl = body.url;
      }

      link.href = blobUrl;
      link.setAttribute("download", `${title}.${format}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } else {
      const modal = Modal.info({
        title: <h2>There was an error downloading your file</h2>,
        className: "discovery-error-loading",
        centered: true,
        width: 370,
        content: (
          <div>
            <p>Please try again later</p>
            <div className="error-download-img">
              <img src={dis_error_img_ico} />
            </div>
          </div>
        ),
        onOk() {
          modal.destroy();
        },
        okText: "Close",
        okType: "default",
      });
    }
    setTimeout(hideLoader, 2500);
  };

  return !isMobile ? (
    <div className="ipm-preview-right-header">
      <Tooltip overlayClassName="info-header-tooltip" title="Open In New Tab">
        <div className="ipm-preview-btn" onClick={handleOpenInNewTab}>
          <img src={browsers_ico} />
        </div>
      </Tooltip>
      <Tooltip overlayClassName="info-header-tooltip" title="Save To Favorites">
        <div
          className="ipm-preview-btn"
          style={{ padding: "8px 10px" }}
          onClick={handleStarClick}
        >
          <img
            style={{ maxWidth: "16px" }}
            src={isLiked ? favorite_filled_ico : favorite_ico}
          />
        </div>
      </Tooltip>
      <Tooltip overlayClassName="info-header-tooltip" title="Share">
        <div className="ipm-preview-btn">
          <ViewCopyButton
            insightId={insightId}
            metadata={{
              id: appId,
              platform: null,
              name: appName,
            }}
            analytics={{ analytic: () => {} }}
            title={title}
            id={appId}
            page="campaigns"
            insightType={type}
          />
        </div>
      </Tooltip>
      <Tooltip overlayClassName="info-header-tooltip" title="More Options">
        <div className="ipm-preview-btn">
          <MoreOptions
            page="campaigns"
            downloadCallback={handleDownload}
            insightID={insightId}
            app={{ name: null, platform: null }}
            discovery={{ title: null, type: null }}
            defaultEmail={user?.email}
            currentInsightURL={
              window.location.pathname + window.location.search
            }
            metadata={{}}
          />
        </div>
      </Tooltip>
      <Tooltip overlayClassName="info-header-tooltip" title="Close">
        <div className="ipm-preview-btn no-bg" onClick={onClose}>
          <img src={x_circle_ico} />
        </div>
      </Tooltip>
    </div>
  ) : (
    <div className="mobile-actions">
      <img src={vertical_dots_ico} onClick={() => setMobileDrawerOpen(true)} />
      <Drawer
        title={null}
        placement="bottom"
        closable={false}
        open={mobileDrawerOpen}
        onClose={() => setMobileDrawerOpen(false)}
        className="mobile-actions-drawer"
        height={240}
      >
        <div className="actions-container">
          <div className="action">
            <img src={share_ico} onClick={handleShareClick} />
            <span>Share</span>
          </div>
          <div className="action">
            <img src={translate_ico} />
            <span>Translate</span>
          </div>
          <div className="action" onClick={handleStarClick}>
            <img src={isLiked ? favorite_filled_ico : favorite_ico} />
            <span>Mark</span>
          </div>
          <div className="action" onClick={() => handleDownload("pdf")}>
            <img src={download_ico} />
            <span>Download PDF</span>
          </div>
        </div>
      </Drawer>
    </div>
  );
};
