import axios from "axios";
import config from "../../shared/config";

export const getSubscriptionsApi = () => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/subscriptions`
  return axios.get(url);
};

export const createSubscriptionApi = (data: any) => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/subscriptions`
  return axios.post(url, data);
};

//@ts-ignore
export const editSubscriptionApi = ({ id, data, all }) => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/subscriptions/${all ? 'all' : id}`
  return axios.put(url, data);
};

//@ts-ignore
export const deleteSubscriptionsApi = ({ id }) => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/subscriptions/${id}`
  return axios.delete(url);
};

//@ts-ignore
export const pushSubscriptionUpdatAPI = (date) => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/user/preferences/subscriptions/notifications`
  return axios.put(url, {subs_modal_show_at: date});
};
