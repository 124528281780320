import { createAction, createReducer } from 'redux-act';
import { IGraphState } from "../../interfaces/Graph"
import { GET_GRAPHS_BY_APP, GET_GRAPHS_BY_APP_SUCCESS, GET_GRAPH_BY_ID, GET_GRAPH_BY_ID_SUCCESS } from "../actions/_actionsTypes";

export const getGraphsByApp = createAction(GET_GRAPHS_BY_APP, (app_id) => app_id);
export const getGraphsByAppSuccess = createAction(GET_GRAPHS_BY_APP_SUCCESS, (data) => data);

export const getGraphByID = createAction(GET_GRAPH_BY_ID, (graph_id) => graph_id);
export const getGraphByIDSuccess = createAction(GET_GRAPH_BY_ID_SUCCESS, (data) => data);

const initState: IGraphState = {
  appsWithGraphs: {},
  graphs: {}
}

export const graphsReducer = createReducer({
  [GET_GRAPHS_BY_APP_SUCCESS]: (state: object, payload: any): IGraphState | any => {
    const appsWithGraphsCopy = {
      //@ts-ignore
      ...state.appsWithGraphs,
      [payload.app_id]: payload.data.data.graphs
    }
    return {
      ...state,
      appsWithGraphs: appsWithGraphsCopy
    }
  },
  [GET_GRAPH_BY_ID_SUCCESS]: (state: object, payload: any): IGraphState | any => {
    const graphsCopy = {
      //@ts-ignore
      ...state.graphs,
      [payload.graph_id]: payload.data.data
    }
    return {
      ...state,
      graphs: graphsCopy
    }
  },  
}, initState);