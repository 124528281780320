import React, { useState, useEffect, FC, ReactElement } from 'react';
import { IDisWithApp, IDataPoint } from '../../../../interfaces/Product';
import moment from "moment";
import './PopularDaysChart.scss';
import WGroupedBarChart, { namesDays, Iimage } from '../../../../components/charts/GroupedBarChart/WGroupedBarChart';

interface EmailChartProps {
  discoveries: Array<IDisWithApp>,
}


const PopularDaysChart: FC<EmailChartProps> = ({
  discoveries,

}): ReactElement | null => {
  const getOptions = () => {
    return {
      height: '35rem'
    }
  };

  const getGroupedData = (discoveries: IDisWithApp[]): IDataPoint[] => {

    let allNames: Set<string> = new Set;
    let allDates = namesDays;
    discoveries.forEach(dis => {
      allNames.add(dis.app.display_name)
    })
    const uniqueNames: string[] = Array.from(allNames);
    let groupedData: IDataPoint[] = [];
    for (let name of uniqueNames) {
      for (let date of allDates) {
        groupedData.push({
          group: date,
          key: name,
          value: 0
        })
      }
    }

    discoveries.forEach((dis: IDisWithApp) => {
      groupedData.forEach((item: IDataPoint) => {
        if (item.key === dis.app.display_name && item.group === namesDays[moment(dis.cam.start_time).day()]) {
          item.value++;
        }
      })
    })

    return groupedData
  };


  let groupedData: IDataPoint[] = [];



  groupedData = getGroupedData(discoveries);
  const setOfGroup = Array.from(new Set(discoveries.map(d => d.app.display_name)))

  const uniqueApps = setOfGroup.map((name: string): Iimage => {
    const thisDiscovery = discoveries.find(dis => dis.app.display_name === name)
    return {
      text: name,
      //@ts-ignore
      icon: thisDiscovery.app.icon
    }
  })
  return <div className={'main-container'}>
    <WGroupedBarChart options={getOptions()} data={groupedData} images={uniqueApps} groupLegend={namesDays} />
  </div>
}
export default PopularDaysChart;

