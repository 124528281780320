import React, { useEffect } from 'react'
import { withRouter } from "react-router";
import { connect } from "react-redux";

const FallbackOldURLS = ({
  match,
  products,
  history
}) => {
  useEffect(() => {
    const { type, app_id, release_id } = match.params;
    let product
    if (app_id) {
      product = products.find(p => p.apps.find(a => a.id === Number(app_id)))
    }
    if (release_id) {
      history.replace(`/product_intelligence/${product.id}?groupBy=release&releasePlatformID=${app_id}&versionID=${release_id}`)
      return
    }
    if (!release_id && app_id) {
      history.replace(`/product_intelligence/${product.id}?groupBy=release&releasePlatformID=${app_id}`)
      return
    }
  }, [])

  return (
    <div></div>
  )
}

export default withRouter(connect(state => ({
  products: state.overview.products
}), {})(FallbackOldURLS))