import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getABTestTimeLine,
  getReachRate,
} from "../../redux/actions/abTestsActions";
import { reachRateSelector } from '../../components/discoveryView/viewExperimentTabs/ReachRateSelector';
import {
  getDiscoveryData
} from "../../redux/actions/dashboardActions";

import { setInsightAsViewed } from "../../redux/actions/dashboardActions";

import { setNotificationForInsightRead } from "../../redux/acts/notifications"
import { withRouter } from "react-router-dom";
import { getDiscoveriesByProduct } from "../../redux/acts/product_intel"
import { postUserDiscoveryComment, editUserDiscoveryComment, deleteUserDiscoveryComment } from "../../redux/acts/userDiscoveries"
import DiscoveryView from "../../components/discoveryView/DiscoveryView"

import { isUserAdmin } from "../../helpers/usersHelper";


import { compareStrings } from "../../services/stringsService";

import NewLoader from "../../common/NewLoader/NewLoader";

import "./Discovery.scss"
import {siteVersions} from "../../shared/consts";

class Discovery extends Component {

  constructor(props) {
    super(props);
    const { app_id } = this.props.match.params;
    const { apps } = this.props;

    this.state = {
      discovery: null,
      translateType: "",
      isAdmin: false,
      currentItem: '',
      visibilitySneakPeak: false,
      loadingFullDiscovery: false,
      app: apps.find(a => a.metadata.id == app_id)
    };
  }


  componentDidMount() {
    const { products, productsWithDiscoveries } = this.props;
    const { id, release_id, type, app_id } = this.props.match.params;

    this.checkAdmin();

    if (this.props.siteVersion !== "demo" && this.props.match.params.id) {
      this.props.setInsightAsViewed(this.props.match.params.id);
      this.props.setNotificationForInsightRead(this.props.match.params.id)
    }
    if (!products) return
    const product = products.find(p => p.apps.find(a => a.id === Number(app_id)))
    if (!!product && !productsWithDiscoveries[product.id]) {
      this.props.getDiscoveriesByProduct(product.id)
    }
  }

  componentDidUpdate = (prevProps, prvState) => {
    const { apps } = this.props;
    const { id, app_id } = this.props.match.params;
    const { allInsights, ABTestData, user } = this.props

    const dis = allInsights.find(dis => dis.insight.id === Number(id))

    if (dis && dis.insight.ab_test_id && prvState.loadingFullDiscovery && !this.state.loadingFullDiscovery) {
      this.props.getReachRate(dis.insight.ab_test_id);
      if (!ABTestData || !ABTestData.find(d => d.id === dis.ab_test_id)) {
        this.props.getABTestTimeLine(dis.insight.ab_test_id);
      }
    }

    if (!user.email && dis && !this.state.discovery) {
      this.setState({
        discovery: dis.insight,
        app: apps.find(a => a.metadata.id == app_id),
        loadingFullDiscovery: false,
      })
    }

    if (dis && dis.insight.fullLoaded &&
      (this.state.loadingFullDiscovery || (this.state.discovery && this.state.discovery.id !== Number(id)) || !this.state.discovery)) {
      this.setState({
        discovery: dis.insight,
        app: apps.find(a => a.metadata.id == app_id),
        loadingFullDiscovery: false,
      })
    }

    //Update Comments
    if (dis?.insight && dis.insight.comments && (dis?.insight?.comments.length !== this.state?.discovery?.comments?.length) ||
      (dis?.insight?.lastCommentUpdate !== this.state?.discovery?.lastCommentUpdate)) {
      this.setState({
        discovery: dis.insight,
      })
    }

    if ((!dis && !this.state.loadingFullDiscovery) || (dis && !dis.insight.fullLoaded && !this.state.loadingFullDiscovery)) {
      this.setState({
        loadingFullDiscovery: true,

      })
      if (this.props.user) {
        this.props.getDiscoveryData({ insightID: id })
      }

    }
  }


  renderTab = type => {
    return <span className={`tab__header tab__header-${type}`}>{type}</span>;
  };

  getChartTabPaneShow = ({ chartData, type }) => {
    const chartTablShow = compareStrings(type, "ab_test") ? true : false;
    const chartDisabled = chartData.length <= 0;
    return { chartTablShow, chartDisabled };
  };

  checkAdmin = () => {
    const { user, impersonateUser } = this.props;
    let isAdmin = false;
    // If we're impersonating a user, check if the impersonated user is an admin
    if (this.props.impersonateUser) {
      isAdmin =
        this.props.users.find(u => u.Username === impersonateUser).isAdmin ||
        false;
    } else {
      // No impersonated user
      if (isUserAdmin(user)) {
        isAdmin = true;
      }
    }
    this.setState({ isAdmin });
  };

  clickedBack = () => {
    const { app_id } = this.props.match.params;
    const { products } = this.props;
    const product = products.find(p => p.apps.find(a => a.id === Number(app_id)))


    const {
      location: { state },
      history
    } = this.props;
    if (state && state.prevPath) {
      console.log(`goback to ${state.prevPath}`)
      history.goBack()
    } else {
      history.push(`/product_intelligence/${product.id}`)
    }
  };

  getRelatedDiscovery = (id) => {
    const { discovery } = this.state;

    if (!discovery.related_insights) { return null }
    let ins = discovery.related_insights.find(ins => ins.id === id)
    if (!ins) { return null }
    return {
      discovery: ins,
      app: this.props.apps.find(app => app.metadata.id == ins.app_id).metadata
    }
  }

  render() {
    const { discovery, isAdmin, app } = this.state;
    const {
      analytics,
      siteVersion,
      demoUser,
      ABTestData,
      user,
      userData,
      history,
      location,
      reachRate,
      productsWithDiscoveries,
      products,
      insightLinkCode,
      guest,
      postUserDiscoveryComment,
      editUserDiscoveryComment,
      deleteUserDiscoveryComment
    } = this.props;
    if (!discovery) {
      return <div className="discovery-loading-wrapper">
        <NewLoader />
      </div>;
    }
    const { id, package_name, release_id, type, app_id } = this.props.match.params;
    const product = products ? products.find(p => p.apps.find(a => a.id === Number(app_id))) : null

    let version

    if (product) {
      if (productsWithDiscoveries &&
        productsWithDiscoveries[product.id] &&
        productsWithDiscoveries[product.id][Number(app_id)] &&
        productsWithDiscoveries[product.id][Number(app_id)].versions.find(ver => ver.id === Number(release_id))
      ) {
        version = productsWithDiscoveries[product.id][Number(app_id)].versions.find(ver => ver.id === Number(release_id))
      }
    }


    return <div className="usual-discovery-wrap">
      <DiscoveryView
        user={user}
        analytics={analytics}
        discovery={discovery}
        forceLoader={this.state.loadingFullDiscovery}
        app={app}
        version={version}
        history={history}
        siteVersion={siteVersion}
        demoUser={demoUser}
        demoMode={siteVersion === 'demo'}
        isAdmin={isAdmin}
        ABTestData={discovery.ab_test_id ? ABTestData.find(d => d.id === discovery.ab_test_id) : null}
        location={location}
        relatedInsights={siteVersion === siteVersions.DEMO ? [] : discovery.related_insight_ids ? discovery.related_insight_ids.map(rel_id => this.getRelatedDiscovery(rel_id)).filter(rel => rel) : null}
        clickBack={this.clickedBack}
        reachRate={reachRate}
        product={product}
        guest={guest}
        insightLinkCode={insightLinkCode}
        postUserDiscoveryComment={postUserDiscoveryComment}
        editUserDiscoveryComment={editUserDiscoveryComment}
        deleteUserDiscoveryComment={deleteUserDiscoveryComment}
        userData={userData}
        relatedCallback={(insightID) => this.props.getDiscoveryData({ insightID })}
      />
    </div>
  }
}

const mapStateToProps = (state) => {
  const { appData, abTestsData, linkData, productDiscoveries, userData } = state;
  return {
    apps: appData.apps,
    analytics: appData.user.analytics,
    ABTestData: abTestsData.data,
    user: appData.user,
    impersonateUser: appData.impersonateUser,
    users: appData.users,
    userData: userData,
    siteVersion: appData.siteVersion,
    demoUser: linkData.user,
    guest: linkData.guest,
    insightLinkCode: linkData.link_code,
    allInsights: appData.allInsights,
    reachRate: reachRateSelector(state),
    products: state.overview.products,
    productsWithDiscoveries: productDiscoveries.productsWithDiscoveries,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getABTestTimeLine,
    getReachRate,
    setInsightAsViewed,
    setNotificationForInsightRead,
    getDiscoveryData,
    getDiscoveriesByProduct,
    postUserDiscoveryComment, editUserDiscoveryComment, deleteUserDiscoveryComment
  })(Discovery)
);
