import {AnyAction} from 'redux';
import {
  takeLatest,
  call,
  put,
  fork, takeEvery,
} from "redux-saga/effects";
import * as reportsApi from "../api/reportsApi";
import {
  GET_REPORTS,
  SET_REPORTS_NOTIFICATIONS_SEEN,
  SET_REPORTS_NOTIFICATIONS_READ,
  SEND_REPORT_SHARE,
  getReportsSuccess,
  setReportsNotificationsSeenSuccess,
  setReportsNotificationsReadSuccess,
  sendReportShareSuccess,
  sendReportShareFailed,
} from '../acts/reports'

//handleres
function* getReportsHandler() {
  // @ts-ignore
  const result = yield call(reportsApi.getReportsAPI)
  yield put(getReportsSuccess(result.data))
}

function* setReportsNotificationsReadHandler(action: AnyAction) {
  // @ts-ignore
  const result = yield call(reportsApi.setReportsNotificationsRead, action.payload)
  yield put(setReportsNotificationsReadSuccess(result.data))
}

function* setReportsNotificationsSeenHandler(action: AnyAction) {

  // @ts-ignore
  const result = yield call(reportsApi.setReportsNotificationsSeen, action.payload)
  yield put(setReportsNotificationsSeenSuccess(result.data))
}

function* sendReportShareHandler(action: AnyAction) {
  try {
    yield call(reportsApi.sendReportsShare, action.payload)
    yield put(sendReportShareSuccess())
  } catch (err){
    yield put(sendReportShareFailed())
  }

}
//watchers
function* watchReports() {
  yield takeEvery(GET_REPORTS, getReportsHandler);
  yield takeEvery(SET_REPORTS_NOTIFICATIONS_READ, setReportsNotificationsReadHandler)
  yield takeEvery(SET_REPORTS_NOTIFICATIONS_SEEN, setReportsNotificationsSeenHandler)
  yield takeEvery(SEND_REPORT_SHARE, sendReportShareHandler)
}


const reportsSagas = [
  fork(watchReports)
];

export default reportsSagas;