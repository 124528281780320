export type App = {
  id: number;
  icon: string;
  displayName: string;
  isCustomerOwnApp: boolean;
};

export type ChartValuesToggle = "numbers" | "percentages";

export const CAMPAIGN_TYPES = {
  MASS: "mass",
  PERSONALIZED: "personalized",
};
