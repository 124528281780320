const genBiWeeklyLink = biWeeklyHighlightID => `${window.location.protocol}//${window.location.host}/link-bi-weekly/${biWeeklyHighlightID}`

export const handleShareClick = (organization, biWeeklyHighlightID, showShareModalChange, analytics) => {
  showShareModalChange(true)

  analytics.analytic('Open bi-weekly gallery sharing',
    {
      org: organization.name,
      org_id: organization.id,
      url: genBiWeeklyLink(biWeeklyHighlightID)
    })
}