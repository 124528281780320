
export const sendShareMail = (reportForShare, recipientForShare, sendReportShare, commentForShare, shareModalShowChange, analytics) => {

  if (reportForShare && recipientForShare) {
    sendReportShare({
      report_id: reportForShare.id,
      //@ts-ignore
      to: [recipientForShare],
      comment: commentForShare,
    });
    shareModalShowChange(false);
    analytics.analytic(`User shared report ${reportForShare.id} - ${reportForShare.name} with ${recipientForShare}: ${commentForShare}`)
  }
}