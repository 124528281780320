import React from "react";
import info_ico from "assets/images/icons/info-new-dark.svg";
import "./HeadingWithTooltip.scss";
import { Switch, Tooltip } from "antd";
import { ChartValuesToggle } from "containers/MarketingOverview/utils/types";
import clsx from "clsx";

type Props = {
  title: string;
  tooltip: string;

  showToggle?: boolean;
  currentToggle?: ChartValuesToggle;
  onToggle?: (val: ChartValuesToggle) => void;

  showSwitch?: boolean;
  currentSwitch?: boolean;
  onSwitch?: (val: boolean) => void;
};

export const HeadingWithTooltip = ({
  title,
  tooltip,

  onToggle,
  showToggle,
  currentToggle,

  currentSwitch,
  onSwitch,
  showSwitch,
}: Props) => {
  return (
    <div className="heading-tooltip-container">
      <h3 className="heading-tooltip-title">{title}</h3>
      {/* <Tooltip
        title={tooltip}
        placement="top"
        overlayClassName="info-header-tooltip"
      >
        <img src={info_ico} />
      </Tooltip> */}
      {showToggle && (
        <div className="heading-chart-percentage-toggle">
          <div
            className={clsx("heading-chart-percentage", {
              active: currentToggle === "percentages",
            })}
            onClick={() => onToggle?.("percentages")}
          >
            Percentage
          </div>
          <div
            className={clsx("heading-chart-percentage", {
              active: currentToggle === "numbers",
            })}
            onClick={() => onToggle?.("numbers")}
          >
            Numbers
          </div>
        </div>
      )}
      {showSwitch && (
        <div className="heading-chart-switch-toggle">
          <Switch size="small" checked={currentSwitch} onChange={onSwitch} />
          Trends Line
        </div>
      )}
    </div>
  );
};
