import { createAction, createReducer } from 'redux-act';
import { IOverviewState } from "../../interfaces/Overview"
import { SET_DISCOVERY_RATE_SUCCESS } from "../actions/_actionsTypes"

// GET_COMPLETED_VERSIONS_WITH_DISCOVERIES 
export const GET_COMPLETED_VERSIONS_WITH_DISCOVERIES = 'GET_COMPLETED_VERSIONS_WITH_DISCOVERIES'
export const GET_COMPLETED_VERSIONS_WITH_DISCOVERIES_SUCCESS = 'GET_COMPLETED_VERSIONS_WITH_DISCOVERIES_SUCCESS'
export const getCompletedVersionsWithDiscoveries = createAction(GET_COMPLETED_VERSIONS_WITH_DISCOVERIES, (data) => data);
export const getCompletedVersionsWithDiscoveriesSuccess = createAction(GET_COMPLETED_VERSIONS_WITH_DISCOVERIES_SUCCESS, (data) => data);


// GET_BEING_ANALYZED_VERSIONS 
export const GET_BEING_ANALYZED_VERSIONS = 'GET_BEING_ANALYZED_VERSIONS'
export const GET_BEING_ANALYZED_VERSIONS_SUCCESS = 'GET_BEING_ANALYZED_VERSIONS_SUCCESS'
export const getBeingAnalyzedVersions = createAction(GET_BEING_ANALYZED_VERSIONS, (data) => data);
export const getBeingAnalyzedVersionsSuccess = createAction(GET_BEING_ANALYZED_VERSIONS_SUCCESS, (data) => data);

//FEATURED
export const GET_FEATURED_DISCOVERIES = 'GET_FEATURED_DISCOVERIES'
export const GET_FEATURED_DISCOVERIES_SUCCESS = 'GET_FEATURED_DISCOVERIES_SUCCESS'
export const getFeaturedDiscoveries = createAction(GET_FEATURED_DISCOVERIES);
export const getFeaturedDiscoveriesSuccess = createAction(GET_FEATURED_DISCOVERIES_SUCCESS, (data) => data);

//MARKED
export const GET_MARKED_DISCOVERIES = 'GET_MARKED_DISCOVERIES'
export const GET_MARKED_DISCOVERIES_SUCCESS = 'GET_MARKED_DISCOVERIES_SUCCESS'
export const getMarkedDiscoveries = createAction(GET_MARKED_DISCOVERIES);
export const getMarkedDiscoveriesSuccess = createAction(GET_MARKED_DISCOVERIES_SUCCESS, (data) => data);

const initState: IOverviewState = {
  completedVersionsWithDiscoveries: null,
  currentlyBeingAnalyzedVersions: null,
  featuredDiscoveries: null,
  markedDiscoveries: null
}

export const newOverviewReducer = createReducer({
  [GET_COMPLETED_VERSIONS_WITH_DISCOVERIES]: (state: IOverviewState): IOverviewState => {
    return {
      ...state,
      completedVersionsWithDiscoveries: null
    }
  },
  [GET_COMPLETED_VERSIONS_WITH_DISCOVERIES_SUCCESS]: (state: IOverviewState, payload: any): IOverviewState => {

    //there is some buisness logic
    payload.data.forEach((ver: any) => {
      if (ver.is_customer_own_app) {
        ver.insight_cnt = 0
        ver.insights = []
      }
    })

    return {
      ...state,
      completedVersionsWithDiscoveries: payload.data || []
    }
  },

  [GET_BEING_ANALYZED_VERSIONS_SUCCESS]: (state: IOverviewState, payload: any): IOverviewState => {
    return {
      ...state,
      currentlyBeingAnalyzedVersions: payload.data || []
    }
  },
  [GET_FEATURED_DISCOVERIES_SUCCESS]: (state: IOverviewState, payload: any): IOverviewState => {
    return {
      ...state,
      featuredDiscoveries: payload.data || []
    }
  },
  [GET_MARKED_DISCOVERIES_SUCCESS]: (state: IOverviewState, payload: any): IOverviewState => {
    return {
      ...state,
      markedDiscoveries: payload.data || []
    }
  },
  [SET_DISCOVERY_RATE_SUCCESS]: (state: IOverviewState, payload: any): IOverviewState => {
    if (!state.featuredDiscoveries) {
      return {
        ...state,
      }
    }

    //@ts-ignore
    let featuredDiscoveriesCopy = state.featuredDiscoveries.map(fd => {

      //@ts-ignore
      if (fd[payload.data.id]) {
        return {
          [payload.data.id]: {
            //@ts-ignore
            ...fd[payload.data.id],
            ...payload.data
          }
        }
      } else {
        return fd
      }
    })

    return {
      ...state,
      //@ts-ignore
      featuredDiscoveries: featuredDiscoveriesCopy
    }
  },
}, initState);