import React from "react";

import wf_logo_ico from "../../../../assets/images/watchful_icon_gray.svg"

import "./performanceNoData.scss"

export default function PerformanceNoData() {

  return (
    <div id="performance-summary" className="performance-summary performance-no-data">
      <img src={wf_logo_ico} />
    </div>
  );
}
