import React from "react";
import "./MarketingAggressiveness.scss";
import { App } from "containers/MarketingOverview/utils/types";
import clsx from "clsx";

type AppWithInsights = App & {
  insightsCount: number;
};

type Props = {
  apps: AppWithInsights[];
};

export const MarketingAggressiveness = ({ apps }: Props) => {
  return (
    <div className="ma-cards-container">
      {apps.map((app) => (
        <div
          className={clsx("ma-app-card", {
            "customer-app": app.isCustomerOwnApp,
          })}
          key={app.id}
        >
          <img className="ma-app-card-image" src={app.icon} />
          <div className="ma-app-card-name">{app.displayName}</div>
          <div className="ma-app-card-count">{app.insightsCount}</div>
        </div>
      ))}
    </div>
  );
};
