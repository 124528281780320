import React, { useState, useEffect, useCallback } from "react";
import { Select } from "antd";
import Chevron from "../../assets/images/icons/chevron.svg";
import Chevron_black from "../../assets/images/icons/chevron_black.svg";

export default function WFSelect({
  black = true,
  options = [],
  defaultOptionIndex = 0,
  className = "",
  selectionHandler,
  width
}) {
  const { Option } = Select;
  const [selectOpen, setSelectOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(undefined);

  const memoizedChangeHandler = useCallback(
    value => {
      setSelectedValue(value);
      selectionHandler(value);
    },
    [selectionHandler]
  );

  useEffect(() => {
    if (!selectedValue && options.length > 0 && options[defaultOptionIndex]) {
      // set default value
      memoizedChangeHandler(options[defaultOptionIndex].value);
    }
  }, [selectedValue, options, memoizedChangeHandler, defaultOptionIndex]);

  return (
    <div className="wf-select">
      <Select
        value={selectedValue}
        className={className}
        defaultActiveFirstOption={false}
        onChange={memoizedChangeHandler}
        onDropdownVisibleChange={open => setSelectOpen(open)}
        style={{ width: width || 200 }}
        suffixIcon={
          <img
            className={`chevron ${selectOpen ? "flipped" : ""}`}
            src={black ? Chevron_black : Chevron}
            alt="chevron"
          />
        }
      >
        {options.map(option => (
          <Option key={option.value} value={option.value}>
            {option.title}
          </Option>
        ))}
      </Select>
    </div>
  );
}
