import { takeLatest, call, all, fork, put, takeEvery, select } from "redux-saga/effects";
import * as actionTypes from "../actions/_actionsTypes";
import * as performanceActions from "../actions/performanceActions";
import * as api from "../api/performanceApi";

import { calcEventLine, manipulateRawHistoryBackendData2 } from "./helpers/perfHelpers"

import { isEmpty } from "lodash";



function* getPerformancePageMetadata(action) {
  try {
    yield put({
      type: actionTypes.CURRENT_REQUESTS_ADD,
      payload: 'getPerformancePageMetadata'
    });

    const [performanceApps, performanceConfiguration,
      Infrastructure, CategoriesDevices, CategoriesEvents] = yield all([
      call(api.getPerformanceApps),
      call(api.getPerformanceConfiguration),
      call(api.getPerformanceConfigurationInfrastructure, action.payload),
      call(api.getPerformanceConfigurationCategoriesDevices),
      call(api.getPerformanceConfigurationCategoriesEvents)
    ]);

    yield put({
      type: actionTypes.CURRENT_REQUESTS_REMOVE,
      payload: 'getPerformancePageMetadata'
    });

    Infrastructure.data.infrastructure.forEach(loc => {
      loc.origData = loc.models;
      loc.models = loc.models.map(model => `${model[0]} ${model[1]} ${model[2]}`)
    });

    const performanceAppsWithSortedEvents = [...performanceApps.data.apps].map(
      app => {
        if (app.device_models) {
          app.device_models = app.device_models.sort((modelA, modelB) => {
            return modelA.priority - modelB.priority
          });
        }
        app.performance_events = app.performance_events.sort((a, b) => {
          if (a.title < b.title) return -1;
          if (a.title > b.title) {
            return 1;
          }
          return 0;
        });
        return app;
      }
    );

    let deviceModelsArr = performanceConfiguration.data.device_models;

    yield put(
      performanceActions.getPerformancePageMetadataSuccess({
        apps: performanceAppsWithSortedEvents,
        deviceModels: deviceModelsArr,
        preferredApps: performanceApps.data.preferred_apps,
        categories: performanceConfiguration.data.categories,
        locations: performanceConfiguration.data.locations,
        eventTypes: performanceConfiguration.data.event_types,
        infrastructure: Infrastructure.data.infrastructure,
        categoriesDevices: CategoriesDevices.data.categories_devices,
        categoriesEvents: CategoriesEvents.data.categories_events
      })
    );
  } catch (e) {
    console.log(e);
  }

}

function* watchGetPerformancePageMetadata() {
  yield takeLatest(
    actionTypes.GET_PERFORMANCE_PAGE_METADATA,
    getPerformancePageMetadata
  );
}

function* getPerfEventData(action) {
  const listEvents = action.payload;
  let listEventsRawData, listEventsData = [];

  try {
    const currentEvents = yield select(state => state.performance.currentEvents);
    listEventsRawData = yield all(listEvents.filter(ev => ev).map(event => {
      let tempCurEvt = null;
      let key = `${event.id}-${event.filters.map(f => f.filterValue)}`
      let curEvt = currentEvents.find(curEv => curEv.key === key)

      if (curEvt) {
        let data = { data: null };
        let history = { data: null };

        data.data = curEvt.data
        history.data = curEvt.history
        // Sorry, a lot of data in data
        tempCurEvt = [{ data: data }, { data: { data: data.data.representative_sample } }, history]
        tempCurEvt.isExis = true
      }

      return tempCurEvt || all([
        call(api.getPerformanceEventDataAverages, event.id, event.filters),
        call(api.getPerformanceEventDataReprSample, event.id, event.filters),
        call(api.getPerformanceEventHistoryData, event.id, event.filters, event.allMetrics)
      ])
    }))
    listEventsRawData.forEach((evRawData, i) => {
      listEventsData[i] = {
        id: listEvents[i].id,
        data: {
          ...isEmpty(evRawData[0].data) ? {} : evRawData[0].data.data,
          representative_sample: evRawData.isExis ?
            evRawData[1].data.data :
            evRawData[1].data.data ? calcEventLine(evRawData[1].data.data) : null,
        },
        history: evRawData.isExis ? evRawData[2].data : manipulateRawHistoryBackendData2(evRawData[2].data || []),
        key: `${listEvents[i].id}-${listEvents[i].filters.map(f => f.filterValue)}`,
        _time: (new Date()).getTime()
      }
    })

    yield put(
      performanceActions.getPerformanceEventsDataSuccess(listEventsData)
    );

  } catch (e) {
    console.log(e);
    yield put({
      type: actionTypes.GET_PERFORMANCE_EVENTS_FAILED
    })
  }

}

function* getPerformanceRanks(action) {
  if (action.payload.loader) {
    yield put({
      type: actionTypes.CURRENT_REQUESTS_ADD,
      payload: 'getPerformanceRanks'
    });
  }

  const result = yield call(api.getPerformanceRanks, action.payload);
  if (action.payload.loader) {
    yield put({
      type: actionTypes.CURRENT_REQUESTS_REMOVE,
      payload: 'getPerformanceRanks'
    });
  }
  yield put(performanceActions.getPerformanceRanksSuccess({
    data: result.data
  }))
}


function* getPerformanceRanksCompare(action) {
  const result = yield call(api.getPerformanceRanksCompare, action.payload.dates,
    action.payload.currentDevice,
    action.payload.currentOSVersion,
    action.payload.app_ids);
  yield put(performanceActions.getPerformanceRanksCompareSuccess({
    data: result.data
  }))
}


function* watchGetPerformanceEventsData() {
  yield takeLatest(
    actionTypes.GET_PERFORMANCE_EVENTS_DATA,
    getPerfEventData
  );
}

function* watchGetPerformanceRanks() {
  yield takeLatest(
    actionTypes.GET_PERFORMANCE_RANKS,
    getPerformanceRanks
  );
}

function* watchGetPerformanceRanksCompare() {
  yield takeLatest(
    actionTypes.GET_PERFORMANCE_RANKS_COMPARE,
    getPerformanceRanksCompare
  );
}

const performanceSagas = [
  fork(watchGetPerformancePageMetadata),
  fork(watchGetPerformanceEventsData),
  fork(watchGetPerformanceRanks),
  fork(watchGetPerformanceRanksCompare)
];

export default performanceSagas;
