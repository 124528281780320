import React, { useState, useEffect } from 'react'
import { Button, Input, Modal } from 'antd'

import DisComment from "./DisComment"

import "./DisComments.scss"


export default function Comments({
  insightID,
  userData,
  comments = [],

  postUserDiscoveryComment,
  editUserDiscoveryComment,
  deleteUserDiscoveryComment,

  analytics,
  discovery,
  app,
  product
}) {
  const [showAllCommModal, showAllCommModalChange] = useState(false)
  const [commentText, commentTextChange] = useState('')

  useEffect(() => {
    if (showAllCommModal) {
      document.querySelector('.ac-list').scrollTop = document.querySelector('.ac-list').scrollHeight
    }
  }, [comments])

  const postMessageHandle = () => {
    console.log(commentText)
    postUserDiscoveryComment({
      insight_id: insightID,
      comment_text: commentText
    })
    commentTextChange('')
    analytics.analytic('User commented', {
      insight_name: discovery.title,
      insight_type: discovery.type,
      product: product.name,
      platform: app.metadata.platform,
      insight_URL: window.location.href,
      comment_text: commentText
    })
  }

  const editUserDiscoveryCommentHandle = (updComment) => {
    editUserDiscoveryComment({
      ...updComment,
      insight_id: insightID
    })
    analytics.analytic('User edited a comment', {
      insight_name: discovery.title,
      insight_type: discovery.type,
      product: product.name,
      platform: app.metadata.platform,
      insight_URL: window.location.href,
      comment_old_text: comments.find(c => c.id === updComment.comment_id).body,
      comment_new_text: updComment.comment_text,
    })
  }

  const deleteUserDiscoveryCommentHandle = (delCommentID) => {
    deleteUserDiscoveryComment({
      insight_id: insightID,
      comment_id: delCommentID
    })
    analytics.analytic('User deletes a comment', {
      insight_name: discovery.title,
      insight_type: discovery.type,
      product: product.name,
      platform: app.metadata.platform,
      insight_URL: window.location.href,
      comment_text: comments.find(c => c.id === delCommentID).body,
    })
  }

  const openAllCommentsModal = () => {
    showAllCommModalChange(true)
    commentTextChange('')
    analytics.analytic('User expanded comments window', {
      insight_name: discovery.title,
      insight_type: discovery.type,
      product: product.name,
      platform: app.metadata.platform,
      insight_URL: window.location.href,
    })
  }

  const closeAllCommentsModal = () => {
    showAllCommModalChange(false)
    commentTextChange('')
  }

  return (
    <div className='dis-comments-list'>
      <h3 className='dcl-title'>Comments</h3>
      {comments.length > 2 && <div className='dcl-comments-more' onClick={openAllCommentsModal}> + {comments.length - 2} comments</div>}
      <div className='dcl-comments'>
        {comments.filter((c, i) => i > comments.length - 3).map(c =>
          <DisComment
            key={c.id}
            comment={c}
            userID={userData.id}
            editUserDiscoveryCommentHandle={editUserDiscoveryCommentHandle}
            deleteUserDiscoveryCommentHandle={deleteUserDiscoveryCommentHandle}
          />
        )}
      </div>
      <div className='dcl-add-comment'>
        <Input value={showAllCommModal ? '' : commentText} onChange={(e) => commentTextChange(e.target.value)} className="dcl-add-comment-input" placeholder='Add comment, reply...' />
        <div className='dcl-add-comment-buttons'>
          <Button className='dcl-add-comment-buttons-cancel' onMouseDown={() => commentTextChange('')}>Cancel</Button>
          <Button disabled={commentText === ''} className='dcl-add-comment-buttons-post black' onMouseDown={postMessageHandle}>Post</Button>
        </div>
      </div>
      {showAllCommModal && <Modal
        title="Comments"
        open={showAllCommModal}
        onOk={closeAllCommentsModal}
        onCancel={closeAllCommentsModal}
        wrapClassName='modal_comments'
        footer={null}
      >
        <div className='ac-list'>
          {comments.map(c =>
            <DisComment
              key={c.id}
              comment={c}
              userID={userData.id}
              editUserDiscoveryCommentHandle={editUserDiscoveryCommentHandle}
              deleteUserDiscoveryCommentHandle={deleteUserDiscoveryCommentHandle}
            />
          )}
        </div>
        <div className='ac-input-wrap'>
          <div className='ac-add-comment'>
            <Input value={commentText} onChange={(e) => commentTextChange(e.target.value)} className="ac-add-comment-input" placeholder='Add comment, reply...' />
            <div className='ac-add-comment-buttons'>
              <Button className='ac-add-comment-buttons-cancel' onMouseDown={() => commentTextChange('')}>Cancel</Button>
              <Button disabled={commentText === ''} className='ac-add-comment-buttons-post black' onMouseDown={postMessageHandle}>Post</Button>
            </div>
          </div>
        </div>
      </Modal>}
    </div>
  )
}
