import _ from "lodash";
import analyticsService from "services/analyticsService";

export type ChartNames =
  | "Marketing Campaign Volume"
  | "Campaign Channels Breakdown"
  | "Campaign Calendar"
  | "Share Of Voice"
  | "Marketing Campaigns Per User Type";

type MarketingOverviewEvents = {
  overview_enter: null;
  overview_app_filter: {
    selected_app_names: string[];
  };
  overview_comparison_mode_changed: {
    state: "on" | "off";
  };
  overview_time_interval_filter: {
    number_of_days: number;
  };
  overview_channel_changed: {
    selected_channel_names: string[];
  };
  overview_chart_viewed: {
    chart_name: ChartNames;
  };
  overview_chart_clicked: {
    chart_name: ChartNames;
  };
  overview_exit: {
    seconds_spent: number;
  };
  chart_category_clicked: {
    chart_name: ChartNames;
    app_names: string[];
    category_name: string;
    is_selected: boolean;
  };
  chart_view_changed: {
    chart_name: ChartNames;
    view_name: string;
  };
};

export const eventsData = new Map<keyof MarketingOverviewEvents, any>();

export const trackMarketingOverviewEvent = <
  T extends keyof MarketingOverviewEvents
>(
  event: T,
  data: MarketingOverviewEvents[T]
) => {
  analyticsService.getInstance().analytic(event, data);
};
