import { intelDiscoveryTypeDictionary } from "../../shared/consts"

const defaultDataSet = [
  {
    name: 'ab_test',
    backgroundColor: "#003E7B",
    hoverBackgroundColor: "#003E7B",
    label: intelDiscoveryTypeDictionary['ab_test'].toUpperCase()
  },
  {
    name: 'screen_change',
    backgroundColor: "#006498",
    hoverBackgroundColor: "#006498",
    label: intelDiscoveryTypeDictionary['screen_change'].toUpperCase()
  },
  {
    name: 'indication',
    backgroundColor: "#5388B2",
    hoverBackgroundColor: "#5388B2",
    label: intelDiscoveryTypeDictionary['indication'].toUpperCase()
  },
  {
    name: 'new_feature',
    backgroundColor: "#8CAFCA",
    hoverBackgroundColor: "#8CAFCA",
    label: intelDiscoveryTypeDictionary['new_feature'].toUpperCase()
  },
  {
    name: 'email_campaign',
    backgroundColor: "#C3D3E1",
    hoverBackgroundColor: "#C3D3E1",
    label: 'Email Campagin'.toUpperCase()
  },
  {
    name: 'inapp_campaign',
    backgroundColor: "#DDE5EB",
    hoverBackgroundColor: "#DDE5EB",
    label: 'In-App/On-Site Campagin'.toUpperCase()
  },
]

export default defaultDataSet