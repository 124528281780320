import moment from 'moment';
import android_logo from "../assets/images/icons/play-store-ic.svg";
import ios_logo from "../assets/images/icons/App-store-ic.svg";
import web_logo from "../assets/images/icons/Web-ic.svg";
import chrome_logo from "../assets/images/icons/chrome-ic.svg";
import windows_logo from "../assets/images/icons/windowns-ic.svg";

export const RGBAToHexA = (r, g, b, a) => {
  r = r.toString(16);
  g = g.toString(16);
  b = b.toString(16);
  a = Math.round(a * 255).toString(16);

  if (r.length === 1) r = "0" + r;
  if (g.length === 1) g = "0" + g;
  if (b.length === 1) b = "0" + b;
  if (a.length === 1) a = "0" + a;

  return "#" + r + g + b + a;
};

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export const escapeJSONString = (jsonStr) => {
  return jsonStr
    .replace(/[\b]/g, '\\b')
    .replace(/[\f]/g, '\\f')
    .replace(/[\n]/g, '\\n')
    .replace(/[\r]/g, '\\r')
    .replace(/[\t]/g, '\\t');
}

export const getDiscoveriesStartTime = (discovery) => moment(discovery.start_time).format('ll')


export const lexicographicSort= (obj1, obj2, fieldName) =>{
  const a = obj1[fieldName].toLowerCase();
  const b = obj2[fieldName].toLowerCase();
  if (a < b)
    return -1;
  if (a > b)
    return 1;

  return 0;
}
export const getDeviceModel = (deviceModel) =>
  `${deviceModel.product_name} ${deviceModel.platform}`;

export const getPlatformImage = (platform) => {
  if (platform === 'Android') return android_logo
  if (platform === 'iOS') return  ios_logo
  if (platform === 'Web') return web_logo
  if (platform === 'Chrome Extension') return chrome_logo
  if (platform === 'Windows') return windows_logo
}

export const PRIORITY_TYPES = {
  very_low: 'Very low',
  low: 'Low',
  medium: 'Medium',
  high: 'High',
  very_high: 'Very high',
};
