import React, { useState, useEffect } from 'react'
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import './SearchBar.scss';

export default function SearchBar({
  searchables,
  fieldToSearch,
  placeHolder,
  onChange,
  searchWord,
  searchWordChange,
  hideIcon,
}) {


  useEffect(() => {
    if (!searchables || searchables.length === 0) { return }
    const filteredSearchables = searchables.filter(searchable => {
      return searchable[fieldToSearch].toLowerCase().includes(searchWord.toLowerCase());
    });
    onChange(filteredSearchables);
  }, [searchWord])

  if (!searchables || searchables.length === 0)
    return null

  const handleInputChange = (inputText) => {
    searchWordChange(inputText)
  };


  return (
    <div className="search">

      <Input prefix={hideIcon ? null : <SearchOutlined />} value={searchWord}
        placeholder={placeHolder || 'Search...'}
        allowClear={true}
        onChange={e => {
          handleInputChange(e.target.value, searchables);
        }}
        className={'searchbar'}
      />
    </div>
  )
}