import * as actionTypes from '../actions/_actionsTypes';

const INIT_STATE = {
  data: {},
  status: 'running',
  link: '',
  user: {},
  recipients: null,
  sendEmailStatus: null,
  guest: null
};

export default function (state = INIT_STATE, action) {
  switch (action.type) {
  case actionTypes.GET_LINK_DATA_SUCCESS: {
    const { data: { insight_data, user, link_code, guest } } = action.payload;
    return {
      ...state,
      user,
      link_code, 
      guest: guest,
      data: insight_data,
      status: 'success',
    };
  }
  case actionTypes.GET_LINK_DATA_FAILED: {
    return {
      ...state,
      status: 'failed',
    };
  }
  case actionTypes.CREATE_LINK_DATA: {
    return {
      ...state,
      status: 'running',
    };
  }
  case actionTypes.CREATE_LINK_DATA_SUCCESS: {
    const { link, insightId } = action.payload;
    let modLink = state.link || {}
    modLink[insightId] = link
    return {
      ...state,
      link: modLink,
      status: 'success'
    };
  }
  case actionTypes.CREATE_LINK_DATA_FAILED: {
    return {
      ...state,
      status: 'failed',
    };
  }
  case actionTypes.INIT_LINK_DATA: {
    return INIT_STATE;
  }
  case actionTypes.GET_RECIPIENTS_SUCCESS: {
    return {
      ...state,
      recipients: action.payload,
    };
  }
  case actionTypes.SEND_SHARE_EMAIL: {
    return {
      ...state,
      sendEmailStatus: 'sending',
    };
  }
  case actionTypes.SEND_SHARE_EMAIL_SUCCESS: {
    return {
      ...state,
      sendEmailStatus: 'finish',
    };
  }
  case actionTypes.SEND_SHARE_BIWEEKLY_EMAIL: {
    return {
      ...state,
      sendEmailStatus: 'sending',
    };
  }
  case actionTypes.SEND_SHARE_BIWEEKLY_EMAIL_SUCCESS: {
    return {
      ...state,
      sendEmailStatus: 'finish',
    };
  }
  default: {
    return state;
  }
  }
};