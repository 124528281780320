import { combineReducers } from "redux";
import ABTestsReducer from "./abTestsReducer";
import DashboardReducer from "./dashboardReducer";
import DirectLinkReducer from "./directLinkReducer";
import OverviewReducer from "./overviewReducer";
import PerformanceReducer from "./performanceReducer";

import ProductReducer from "./productReducer";
import UserReducer from "./userReducer";

//new Format with ACTS
import { BiWeeklyReducer } from "../acts/biweekly";
import { graphsReducer } from "../acts/graphs";
import { labelsReducer } from "../acts/labels";
import { notificationsReducer } from "../acts/notifications";
import organizationReducer from "../acts/organization";
import { newOverviewReducer } from "../acts/overview";
import { personasReducer } from "../acts/personas";
import { productsDiscoveriesReducer } from "../acts/product_intel";
import { reportReducer } from "../acts/reports";
import { subscriptionsReducer } from "../acts/subscriptions";
import UserDiscoveriesReducer from "../acts/userDiscoveries";
import { userJourneysReducer } from "../acts/userJourenys";

export default combineReducers({
  appData: DashboardReducer,
  abTestsData: ABTestsReducer,
  performance: PerformanceReducer,
  linkData: DirectLinkReducer,
  overview: OverviewReducer,
  userData: UserReducer,
  product: ProductReducer,
  personas: personasReducer,
  notifications: notificationsReducer,
  newOverviewReducer: newOverviewReducer,
  reports: reportReducer,
  labels: labelsReducer,
  subscriptions: subscriptionsReducer,
  biWeekly: BiWeeklyReducer,
  graphs: graphsReducer,
  productDiscoveries: productsDiscoveriesReducer,
  userDiscoveries: UserDiscoveriesReducer,
  organization: organizationReducer,
  userJourneys: userJourneysReducer,
});
