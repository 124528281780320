import React, { FC, ReactElement } from 'react';
import "./legal.scss";

interface IProps {

}

const Privacy: FC<IProps> = ({

}): ReactElement => {

  return (
    <div className="eula-wrapper">
      <h1 className="lean">Privacy <strong className="fat">Policy</strong></h1>
      <p className={`header large-font`}>
            WATCHFUL TECHNOLOGIES
      </p>
      <p className="header">
            PRIVACY STATEMENT
      </p>
      <p>

            Watchful Technologies Ltd. (“Watchful,” “we," or “us”) is committed to protecting the privacy of its clients (the “Clients”) and their authorized users of the Watchful Offerings. This statement (the “Privacy Statement”) describes our privacy practices in relation to each Client’s use of the Watchful Offerings.
      </p>
      <div className={'place-holder'}/>
      <p>
        <strong>
            A. Definitions
        </strong>
      </p>
      <p> <strong>“Confidential Information”</strong> means, but is not limited to, any non-public information, knowhow, data, trade secret, process, technique, program, design, formula, financial, commercial, sales or programming matters, Client information, computer programs, studies, works in progress, and other data, in oral, written, graphic, electronic, or any other form or medium whatsoever, which is disclosed to Watchful. The term “Confidential Information” does not include information which is (i) now or which becomes publicly known or available through no act or failure on the part of Watchful; (ii) actually known to Watchful prior to the time of receipt of such Confidential Information; (iii) furnished to Watchful by a third party who has rightfully obtained the Confidential Information without restriction on disclosure; or (iv) is independently developed by Watchful without the use of or reference to a Client’s Confidential Information that does not otherwise contravene the terms and provisions of this Agreement.
         
      </p>
      <p>
        <strong>“Offerings”</strong> means Watchful’s proprietary products and solutions, and related services.
              
      </p>
      <p>
        <strong>“Personal Data”</strong> means first and last name, phone number, zip code, e-mail, professional title and company name.
      </p>
      <p>
        <strong>“Purpose”</strong> means the engagement memorialized by that certain master services agreement entered into by and between Watchful and Client.
      </p>
      <p>
        <strong>“Representatives”</strong> means all principals, directors, officers, employees, agents, sub-contractors and other representatives of Watchful.
      </p>
      <div className={'place-holder'}/>
      <p>
        <strong>
            B. Client Confidential Information
        </strong>
      </p>
      <p>
            Watchful shall not disclose any Confidential Information disclosed to it by a Client to any person other than to its Representatives who need to know and in such event only to the extent necessary for the Purpose. Watchful shall not disclose the fact that the Confidential Information has been made available to it pursuant to this Agreement.
      </p>
      <div className={'place-holder'}/>
      <p>
        <strong>
            C. Personal data you provide to us
        </strong>
      </p>
      <p>
            We may collect Personal Data from you as part of our normal business practices and in the administration of our Client relationships. To request the removal of any Personal Data you’ve supplied to us, please send an email to privacy@watchful.ai. In some cases, we may not be able to remove your Personal Data, in which case we will let you know if we are unable to do so and why.
      </p>
      <div className={'place-holder'}/>
      <p>
        <strong>
            D. Disclosure of Personal Data
        </strong>
      </p>
      <p>
            Watchful may share a Client’s Confidential Information and/or Personal Data with its affiliated businesses as part of Watchful’s business operations and for the administration of the Offerings. We may also appoint third-party service providers (who will operate under our instructions) to assist us in carrying out the foregoing, subject to appropriate and customary security measures.
            Watchful contractually requires its agents, service providers and affiliates who may process the Confidential Information and/or Personal Data of its Clients which is related to the Purpose to provide the same level of protection as is either required under applicable privacy and data protection laws or standards that are otherwise followed by Watchful to protect its own Confidential Information (collectively, the “Principles”). Watchful does not transfer Confidential Information of its Clients or Personal Data to a third party for the third party’s own use, but only for the Purpose outlined above.
      </p>
      <div className={'place-holder'}/>
      <p>
        <strong>
            E. De-identified Data
        </strong>
      </p>
      <p>
            We may de-identify and aggregate certain data we collect such that the data no longer identifies or can be linked to a particular Client or an individual data subject (“De-identified Data”) subject to the terms of any applicable Client agreements. We may use this data to improve our Offerings, analyze trends, publish market research, and for other marketing, research, or statistical purposes and may disclose such data to third parties for these specific purposes.  This Privacy Statement does not restrict our use and processing of such data.
      </p>
      <div className={'place-holder'}/>
      <p>
        <strong>
            F. Security
        </strong>
            Watchful aims to safeguard and protect each Client’s Confidential Information and Personal Data from unauthorized access, improper use or disclosure, unauthorized modification or unlawful destruction or accidental loss, and Watchful uses and maintains certain reasonable processes, systems, and technologies to do so. However, no environment is completely secure or error-free and that these processes, systems, and technologies utilized and maintained by Watchful are subject to compromise. Accordingly, we cannot be held responsible for unauthorized or unintended access that is beyond our control.
      </p>
      <div className={'place-holder'}/>
      <p>
        <strong>
            G. Retention of Data
        </strong>
      </p>
      <p>
            We apply a general rule of keeping our Client’s Confidential Information and Personal Data only for as long as we have a legitimate interest to do so, or to the extent we are required to do so in accordance with legal, tax, and accounting requirements.
      </p>
      <div className={'place-holder'}/>
      <p>
        <strong>
            H. Updates to this Privacy Statement
        </strong>
      </p>
      <p>
            From time to time, we may change the terms of this Privacy Statement. Changes will take effect once they are posted online and by accessing and/or using the Offerings after we make any such changes hereto, and you are deemed to have accepted such changes. If you do not agree with any of the amended terms, you must avoid any further use of the Offerings provided thereon.
      </p>
      <div className={'place-holder'}/>
      <p>
        <strong>
            I. Contact Information
        </strong>
      </p>
      <p>
            If you have any questions in relation to this Notice, please contact us by sending an email to <a href="mailto:info@watchful.ai">info@watchful.ai</a>.
      </p>
      <p>  
          This Privacy Statement was last revised in November 2021
      </p>
    </div>
  );
}



export default Privacy;