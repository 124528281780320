import React, { useEffect } from "react";
import "./IntelPage.scss";
import { IntelFilters } from "./components/IntelFilters/IntelFilters";
import { TypeSelect } from "./components/TypeSelect";
import { Col, Row } from "antd";
import { SidebarFilters } from "./components/SidebarFilters";
import { CardsList } from "./components/CardsList";
import { RouteComponentProps, withRouter } from "react-router";
import { useIntelFilters } from "./hooks/useIntelFilters";
import { useIntelData } from "./hooks/useIntelData";
import { InsightPreviewModal } from "./components/InsightPreviewModal";
import { isMobileDevice } from "containers/MarketingOverview/utils/helpers";
import { trackIntelEvent } from "./utils/analytics";

type Props = RouteComponentProps;

const IntelPageBase = ({ history }: Props) => {
  const {
    productIds,
    customRange,
    customRangeTime,
    daysAgo,
    experiments,
    features,
    handleChangeFilter,
    platforms,
    type,
    insightId,
    personaIds,
    startTimeGte,
    startTimeLt,
    limit,
    offset,
  } = useIntelFilters({ history });

  const {
    insights,
    selectedInsight,
    abTest,
    products,
    personas,
    summaryByType,
    experimentsFilterCounts,
    featuresFilterCounts,
    fetchMoreInsights,
    hasMoreInsights,
  } = useIntelData({
    handleChangeFilter,
    filters: {
      insightId,
      platforms,
      productIds,
      startTimeGte,
      startTimeLt,
      abTestStatuses: experiments,
      type,
      features,
      personaIds,
      limit,
      offset,
    },
  });

  useEffect(() => {
    trackIntelEvent("intel_enter", null);
  }, []);

  const handleInsightModalClose = () => {
    handleChangeFilter("insight_id", "");
  };

  const isMobile = isMobileDevice();

  return (
    <div className="i-container">
      <IntelFilters
        platforms={platforms}
        customRange={customRange}
        customRangeTime={customRangeTime}
        daysAgo={daysAgo}
        products={products}
        selectedProductIds={productIds}
        personas={personas}
        selectedPersonaIds={personaIds}
        handleUpdateFilter={handleChangeFilter}
      />
      <Row wrap={isMobile ? true : false} gutter={[24, 24]}>
        <Col xs={24} flex={!isMobile ? "200px" : undefined}>
          <SidebarFilters
            types={summaryByType}
            selectedExperiments={experiments}
            selectedFeatures={features}
            selectedType={type}
            handleChangeFilter={handleChangeFilter}
            experimentsFilterCounts={experimentsFilterCounts}
            featuresFilterCounts={featuresFilterCounts}
          />
        </Col>
        <Col xs={24} flex={!isMobile ? "auto" : undefined}>
          {insights.length > 0 && (
            <CardsList
              insights={insights}
              handleChangeFilter={handleChangeFilter}
              fetchMoreInsights={fetchMoreInsights}
              hasMoreInsights={hasMoreInsights}
            />
          )}
        </Col>
      </Row>
      {selectedInsight && (
        <InsightPreviewModal
          open={!!selectedInsight}
          onClose={handleInsightModalClose}
          appId={selectedInsight.app.id.toString()}
          appName={selectedInsight.app.displayName}
          assets={selectedInsight.assets}
          date={selectedInsight.start_time}
          focusArea={selectedInsight.focus_area || ""}
          insightDescription={selectedInsight.description || ""}
          insightId={selectedInsight.id}
          labels={selectedInsight.labels || []}
          priority={selectedInsight.priority}
          title={selectedInsight.title}
          type={selectedInsight.type}
          /* Temporary disable user types filter SQUAD2-1329 */
          // userTypes={personas}
          // userTypesSeen={selectedInsight.personas}
          appIcon={selectedInsight.app.icon || ""}
          platform={selectedInsight.platform || ""}
          releaseName={selectedInsight.release_name || ""}
          variants={selectedInsight.variants || []}
          personaLocations={selectedInsight.persona_locations || []}
          abTestData={abTest}
        />
      )}
    </div>
  );
};

export const IntelPage = withRouter(IntelPageBase);
