import * as actionTypes from './_actionsTypes';

export const getABTestTimeLine = (id) => ({
  type: actionTypes.GET_AB_TEST_TIMELINE,
  payload: {
    id
  }
});

export const getABTestTimeLineSuccess = (data) => ({
  type: actionTypes.GET_AB_TEST_TIMELINE_SUCCESS,
  payload: data
});

export const getABTestTimeLineFailed = () => ({
  type: actionTypes.GET_AB_TEST_TIMELINE_FAILED,
});

export const getReachRate = (testID) => ({
  type: actionTypes.GET_REACH_RATE_CHART,
  payload: {
    testID
  }
});

export const getReachRateChartSuccess = (reachRate) => ({
  type: actionTypes.GET_REACH_RATE_CHART_SUCCESS,
  payload: {
    reachRate
  }
});

export const getReachRateChartFailed = () => ({
  type: actionTypes.GET_REACH_RATE_CHART_FAILED,
});

