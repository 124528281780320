export const Channels = {
  email_campaign: "email_campaign",
  push_notification_campaign: "push_notification_campaign",
  inapp_campaign: "inapp_campaign",
};

export const ChannelToLabel = {
  [Channels.email_campaign]: "Email",
  [Channels.push_notification_campaign]: "Push",
  [Channels.inapp_campaign]: "In-App",
};

export const LabelToChannel = {
  Email: Channels.email_campaign,
  Push: Channels.push_notification_campaign,
  "In-App": Channels.inapp_campaign,
};

export const ChannelToColor = {
  [Channels.email_campaign]: "#7891E5",
  [Channels.push_notification_campaign]: "#AFC2FF",
  [Channels.inapp_campaign]: "#E3E9FB",
};

export const CHART_COLORS = [
  { color: "#506199", isLight: false },
  { color: "#7891E5", isLight: false },
  { color: "#AFC2FF", isLight: false },
  { color: "#E3E9FB", isLight: true },
  { color: "#D7D9E0", isLight: true },
  { color: "#DFD0FF", isLight: true },
  { color: "#FFBDE4", isLight: true },
  { color: "#A88DEB", isLight: false },
  { color: "#BF64EA", isLight: false },
  { color: "#FE418E", isLight: false },
  { color: "#E02955", isLight: false },
  { color: "#E51DAB", isLight: false },
  { color: "#E86F3C", isLight: false },
  { color: "#4497F2", isLight: false },
  { color: "#221E63", isLight: false },
  { color: "#5DA283", isLight: false },
  { color: "#610FCE", isLight: false },
  { color: "#971A64", isLight: false },
  { color: "#329210", isLight: false },
  { color: "#F6BAA1", isLight: true },
];
