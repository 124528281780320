import { gql } from "@apollo/client";

export const GET_TIMELINE_APPS = gql`
  query GetTimelineApps {
    marketing {
      apps(hasInsights: true) {
        id
        displayName
        icon
      }
    }
  }
`;

export const GET_TIMELINE_PERSONAS = gql`
  query GetTimelinePersonas($appId: Int!) {
    marketing {
      personas(appId: $appId) {
        id
        title
        description
        tariffPlan
        activeSince
        browsingCadence
        purchaseBehaviour
        gender
        birthDate
        location
        roles {
          id
          name
        }
      }
    }
  }
`;

export const GET_TIMELINE_DATA = gql`
  query GetTimelineData($appId: Int!, $personaId: Int!) {
    marketing {
      contextTimeline(appId: $appId, personaId: $personaId) {
        events {
          id
          name
        }
        insightsByDate {
          date
          insights {
            id
            type
          }
        }
        eventsByDate {
          date
          events {
            id
            name
            description
          }
        }
      }
    }
  }
`;

export const GET_INSIGHTS_BY_IDS = gql`
  query GetInsightsByIds($appId: Int!, $personaId: Int!, $insightIds: [Int!]!) {
    marketing {
      contextTimeline(appId: $appId, personaId: $personaId) {
        insights(ids: $insightIds) {
          id
          type
          title
          startTime
          description
          thumbnail
          thumbnailOriginal
        }
      }
    }
  }
`;
