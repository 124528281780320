import * as actionTypes from "./_actionsTypes";

export const clearPerformancePageMetadata = () => ({
  type: actionTypes.CLEAR_PERFORMANCE_PAGE_METADATA
});

export const clearPerformancePageData = ({ compareOnly = false }) => ({
  type: actionTypes.CLEAR_PERFORMANCE_PAGE_DATA,
  payload: { compareOnly }
});

export const getPerformancePageMetadata = (data) => ({
  type: actionTypes.GET_PERFORMANCE_PAGE_METADATA,
  payload: data
});

export const getPerformancePageMetadataSuccess = (data) => ({
  type: actionTypes.GET_PERFORMANCE_PAGE_METADATA_SUCCESS,
  payload: data
});

export const getPerformanceEventsData = (data) => ({
  type: actionTypes.GET_PERFORMANCE_EVENTS_DATA,
  payload: data
});

export const getPerformanceEventsDataSuccess = (data) => ({
  type: actionTypes.GET_PERFORMANCE_EVENTS_DATA_SUCCESS,
  payload: data
});



export const getPerformanceDeviceModels = () => ({
  type: actionTypes.GET_PERFORMANCE_DEVICE_MODELS
});

export const getPerformanceDeviceModelsSuccess = ({ data }) => ({
  type: actionTypes.GET_PERFORMANCE_DEVICE_MODELS_SUCCESS,
  payload: data
});

export const getPerformanceApps = () => ({
  type: actionTypes.GET_PERFORMANCE_APPS
});

export const getPerformanceAppsSuccess = ({ data }) => ({
  type: actionTypes.GET_PERFORMANCE_APPS_SUCCESS,
  payload: data
});

export const getPerformanceEventsList = () => ({
  type: actionTypes.GET_PERFORMANCE_EVENTS_LIST
});

export const getPerformanceRanks = (filters, loader) => ({
  type: actionTypes.GET_PERFORMANCE_RANKS,
  payload: {
    filters,
    loader
  }
});

export const getPerformanceRanksSuccess = data => ({
  type: actionTypes.GET_PERFORMANCE_RANKS_SUCCESS,
  payload: {
    data
  }
});

export const getPerformanceRanksCompare = (dates, currentDevice, currentOSVersion, app_ids) => ({
  type: actionTypes.GET_PERFORMANCE_RANKS_COMPARE,
  payload: {
    dates,
    currentDevice,
    currentOSVersion,
    app_ids
  }
});

export const getPerformanceRanksCompareSuccess = data => ({
  type: actionTypes.GET_PERFORMANCE_RANKS_COMPARE_SUCCESS,
  payload: {
    data
  }
});


