import React from 'react'
import { Button } from 'antd'
import { Link } from "react-router-dom";
import "./style.scss"
import Intel_abtests_ico from "../../assets/images/new_design/selected/ab-tests-selected.svg"
import { useIsFeatureByUserID } from "../../helpers/features"

export default function SeeAllABTests({
  link,
  text,
  user
}) {

  const ABTestPageAccess = useIsFeatureByUserID('ab_tests_page', user.email)

  if (!ABTestPageAccess) {
    return null
  }

  return (
    <div className='see-all-btn'>
      <Link to={link}>
        <Button>
          <span className='see-all-btn-icon'>
            <img src={Intel_abtests_ico} />
          </span>
          {text}
        </Button>
      </Link>
    </div>
  )
}
