import React, { useState, useCallback } from "react";
import {
  EyeOutlined,
  EyeInvisibleOutlined
} from '@ant-design/icons';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { get } from "lodash";

import "./InputText.scss";

// Wrapper to replace for material
export const InputText = ({ password, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  // console.log(props);
  const getType = useCallback(() => {
    if (password) {
      return showPassword ? "text" : "password";
    }
    return props.type;
  }, [password, props.type, showPassword]);
  return (
    <TextField
      {...props}
      fullWidth
      className={`material-input-field ${props.className}`}
      variant="outlined"
      error={get(props, "data-__field.errors", []).length}
      helperText={get(props, "data-__field.errors", []).map(el => (
        <div className="error-message">{el.message}</div>
      ))}
      type={getType()}
      InputProps={{
        endAdornment: password ? (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
            >
              {showPassword ? (
                <EyeOutlined style={{fontSize: 18}} />
              ) : (
                <EyeInvisibleOutlined style={{fontSize: 18}} />
              )}
            </IconButton>
          </InputAdornment>
        ) : null,
        style: {
          fontSize: 17
        }
      }}
      InputLabelProps={{
        style: {
          fontSize: 18
        }
      }}
    />
  );
};
