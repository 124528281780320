import moment from "moment";
import React, { useEffect, useState } from "react";
import chat_teardrop_ico from "../../../../assets/images/icons/chat-teardrop-text.svg";
import tap_ico from "../../../../assets/images/icons/tap.svg";
import ModalBroadwayTheater from "../../../../components/discoveryView/ModalBroadwayTheater/ModalBroadwayTheater";
import { UserFlowAsset } from "../../../../interfaces/UserJourneys";
import { getUserFlowsSet } from "../../../../redux/acts/userJourenys";
import { Persona, Version } from "../../utils/consts";
import { PersonaSelect } from "../common/PersonaSelect";
import { CanvasedImage } from "./CanavsedImage";
import "./UserJourneyCarousel.scss";

type Props = {
  appName: string;
  appLogo: string;
  images: UserFlowAsset[];
  anchorId: string;
  personas: Persona[];
  onUserFlowHistory: (personaId: number) => void;
  isHistoryOpen: boolean;
  userFlowPersonaId: number;
  getUserFlowsSet: typeof getUserFlowsSet;
  appId: number;
  userFlowSetId: number;
  version: Version;
};

const AssetDims = {
  portrait: {
    width: {
      normal: 280,
      fullscreen: 360,
    },
    height: {
      normal: 640,
      fullscreen: 840,
    },
  },
  landscape: {
    width: {
      normal: 640,
      fullscreen: 1350,
    },
    height: {
      normal: 280,
      fullscreen: 600,
    },
  },
};

export const UserJourneyCarousel = ({
  anchorId,
  appName,
  appLogo,
  images,
  personas,
  onUserFlowHistory,
  isHistoryOpen,
  userFlowPersonaId,
  getUserFlowsSet,
  appId,
  userFlowSetId,
  version,
}: Props) => {
  const [previewModal, setPreviewModal] = useState<{
    open: boolean;
    image: UserFlowAsset | null;
  }>({
    open: false,
    image: null,
  });
  const [persona, setPersona] = useState<Persona | null>(null);

  useEffect(() => {
    const selectedPersona = personas.find(({ id }) => id === userFlowPersonaId);

    if (selectedPersona) {
      setPersona(selectedPersona);
    }
  }, [personas]);

  const handlePreviewClose = () => {
    setPreviewModal({ open: false, image: null });
  };

  const handlePreviewOpen = (image: UserFlowAsset) => () => {
    setPreviewModal({ open: true, image });
  };

  const handlePersonaFilterSelect = (personaId: number) => {
    const newPersona = personas.find(({ id }) => id === personaId);

    if (newPersona) {
      setPersona(newPersona);
      getUserFlowsSet({
        app_ids: [appId.toString()],
        persona_id: personaId.toString(),
        user_flow_id: anchorId.toString(),
        user_flow_set_id: userFlowSetId.toString(),
      });
    }
  };

  const handleChangesHistoryClick = () => {
    if (!persona) return;

    onUserFlowHistory(persona.id);
  };

  return (
    <div className="uj-carousel-container" id={anchorId}>
      <div className="uj-carousel-heading">
        {isHistoryOpen ? (
          <React.Fragment>
            <div className="uj-history-app-container">
              <div className="uj-history-app">{appName}</div>
              <div className="uj-history-version">
                Version: {version.release_name} |{" "}
                {moment(version.release_date).format("DD/MM/YYYY")}
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <img className="uj-carousel-app-logo" src={appLogo} />
            <h3 className="uj-carousel-name">{appName}</h3>
            {!isHistoryOpen && (
              <PersonaSelect
                handlePersonaFilterSelect={handlePersonaFilterSelect}
                persona={persona}
                personas={personas}
              />
            )}
          </React.Fragment>
        )}
        {images.length > 0 && (
          <div className="uj-carousel-btns-container">
            {!isHistoryOpen && (
              <button
                className="uj-changes-history-btn"
                onClick={handleChangesHistoryClick}
              >
                Changes History
              </button>
            )}
            <button className="uj-related-ins-btn">Related Insights</button>
          </div>
        )}
      </div>
      {images.length > 0 && (
        <div className="uj-carousel-images">
          {images.map((image, idx) => (
            <div className="uj-carousel-image-container">
              <div className="uj-carousel-image-heading">
                <img src={chat_teardrop_ico} />
                <div className="uj-carousel-uj-status">
                  {idx + 1}
                  <img src={tap_ico} />
                </div>
              </div>
              <CanvasedImage
                imageObject={image}
                canvasHeight={
                  AssetDims[image.orientation || "portrait"].height.normal
                }
                canvasWidth={
                  AssetDims[image.orientation || "portrait"].width.normal
                }
                onClick={handlePreviewOpen(image)}
              />
            </div>
          ))}
        </div>
      )}
      <ModalBroadwayTheater
        modalIsOpen={previewModal.open}
        closeModal={handlePreviewClose}
        platform={""}
        content={
          <div className="uj-image-preview-container">
            <CanvasedImage
              // @ts-ignore
              imageObject={previewModal.image}
              canvasHeight={
                AssetDims[previewModal.image?.orientation || "portrait"].height
                  .fullscreen
              }
              canvasWidth={
                AssetDims[previewModal.image?.orientation || "portrait"].width
                  .fullscreen
              }
            />
          </div>
        }
      />
    </div>
  );
};
