import clsx from "clsx";
import { useContextTimeline } from "pages/ContextTimeline/context";
import { Event } from "pages/ContextTimeline/context/types";
import React, { useMemo } from "react";
import { SelectedEventCount } from "../selected-event-count";
import moment from "moment";

type Props = {
  event: Event;
};

export const EventListItem = ({ event }: Props) => {
  const { eventName, handleChangeFilter, eventsByDateList, date } =
    useContextTimeline();

  const onEventSelect = () => {
    if (eventName === event.name) return;

    handleChangeFilter?.("event_name", event.name);

    // when the event is selected select the latest date that includes that event
    const latestEventDate = eventsByDateList
      ?.slice()
      ?.reverse()
      ?.find((eventByDate) =>
        eventByDate.events.find((e) => e.name === event.name)
      )?.date;

    if (latestEventDate) {
      handleChangeFilter?.("date", latestEventDate);
    }
  };

  const onNextEvent = () => {
    const nextEventDate = eventsByDateList?.find((eventByDate) => {
      const containsSelectedEvent = eventByDate.events.find(
        (event) => event.name === eventName
      );

      if (moment(eventByDate.date).isAfter(date) && containsSelectedEvent) {
        return true;
      }
    })?.date;

    if (nextEventDate) {
      handleChangeFilter?.("date", nextEventDate);
    }
  };

  const onPrevEvent = () => {
    const prevEventDate = eventsByDateList
      ?.slice()
      ?.reverse()
      ?.find((eventByDate) => {
        const containsSelectedEvent = eventByDate.events.find(
          (event) => event.name === eventName
        );

        if (moment(eventByDate.date).isBefore(date) && containsSelectedEvent) {
          return true;
        }
      })?.date;

    if (prevEventDate) {
      handleChangeFilter?.("date", prevEventDate);
    }
  };

  const eventsWithSameName = useMemo(
    () =>
      eventsByDateList?.filter((eventByDate) =>
        eventByDate.events.find((e) => e.name === event.name)
      ),
    [eventsByDateList, event.name]
  );

  const positionOutOfTotal = useMemo(() => {
    // find index of the current date
    const index = eventsWithSameName?.findIndex(
      (eventByDate) =>
        moment(eventByDate.date).format("DD/MM/YYYY") ===
        moment(date).format("DD/MM/YYYY")
    );

    return (index || 0) + 1;
  }, [eventsWithSameName, date]);

  return (
    <div
      className={clsx("event", {
        selected: eventName === event.name,
      })}
      onClick={onEventSelect}
      key={event.id}
    >
      <span className="event-title">{event.name}</span>
      <span className="event-count">
        {eventName === event.name ? (
          <SelectedEventCount
            total={eventsWithSameName?.length || 0}
            outOfTotal={positionOutOfTotal}
            onNext={onNextEvent}
            onPrevious={onPrevEvent}
          />
        ) : (
          `(${eventsWithSameName?.length || 0})`
        )}
      </span>
    </div>
  );
};
