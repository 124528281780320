import { createAction, createReducer } from 'redux-act';
import { INotification, INotificationState } from '../../interfaces/Notification';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONSS_SUCCESS'
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONSS_FAILURE'

export const SET_NOTIFICATIONS_READ = 'SET_NOTIFICATIONS_READ'
export const SET_NOTIFICATIONS_READ_SUCCESS = 'SET_NOTIFICATIONS_READ_SUCCESS'
export const SET_NOTIFICATIONS_READ_FAILURE = 'SET_NOTIFICATIONS_READ_FAILURE'

export const SET_NOTIFICATIONS_SEEN = 'SET_NOTIFICATIONS_SEEN'
export const SET_NOTIFICATIONS_SEEN_SUCCESS = 'SET_NOTIFICATIONS_SEEN_SUCCESS'
export const SET_NOTIFICATIONS_SEEN_FAILURE = 'SET_NOTIFICATIONS_SEEN_FAILURE'

export const SET_NOTIFICATIONS_FOR_INSIGHT_READ = 'SET_NOTIFICATIONS_FOR_INSIGHT_READ'
export const SET_NOTIFICATIONS_FOR_INSIGHT_READ_SUCCESS = 'SET_NOTIFICATIONS_FOR_INSIGHT_READ_SUCCESS'
export const SET_NOTIFICATIONS_FOR_INSIGHT_READ_FAILURE = 'SET_NOTIFICATIONS_FOR_INSIGHT_READ_FAILURE'

export const getNotifications = createAction(GET_NOTIFICATIONS);
export const getNotificationsSuccess = createAction(GET_NOTIFICATIONS_SUCCESS, (res: any) => res.data);
export const getNotificationsFailure = createAction(GET_NOTIFICATIONS_FAILURE);

export const setNotificationsRead = createAction(SET_NOTIFICATIONS_READ, (data) => data);
export const setNotificationsReadSuccess = createAction(SET_NOTIFICATIONS_READ_SUCCESS, (res: any) => res.data);
export const setNotificationsReadFailure = createAction(SET_NOTIFICATIONS_READ_FAILURE);

export const setNotificationsSeen = createAction(SET_NOTIFICATIONS_SEEN, (data) => data);
export const setNotificationsSeenSuccess = createAction(SET_NOTIFICATIONS_SEEN_SUCCESS, (res: any) => res.data);
export const setNotificationsSeenFailure = createAction(SET_NOTIFICATIONS_SEEN_FAILURE);

export const setNotificationForInsightRead = createAction(SET_NOTIFICATIONS_FOR_INSIGHT_READ, (insight_id) => insight_id);
export const setNotificationForInsightReadSuccess = createAction(SET_NOTIFICATIONS_FOR_INSIGHT_READ_SUCCESS, (res: any) => res.data);
export const ssetNotificationForInsightReadFailure = createAction(SET_NOTIFICATIONS_FOR_INSIGHT_READ_FAILURE);

const initState: INotificationState = {
  notifications: [],
  markAllSeenLoading: false
}

const updateNotification = (state: INotificationState, payload: any, field: string): INotificationState => {
  let modNotification = [...state.notifications]
  payload.forEach((n: any) => {
    let index = modNotification.findIndex(md => md.id == n.notification_id)
    if (index !== -1) {
      //@ts-ignore
      modNotification[index][field] = n[field]
    }
  })
  return {
    ...state,
    notifications: modNotification,
    markAllSeenLoading: false,
  }

}

export const notificationsReducer = createReducer({
  [GET_NOTIFICATIONS_SUCCESS]: (state: INotificationState, notes: INotification[]): INotificationState => {
    return {
      ...state,
      notifications: notes
    }
  },


  [SET_NOTIFICATIONS_READ]: (state: INotificationState, payload: any): INotificationState => {
    return {
      ...state,
      markAllSeenLoading: true,
    }
  },
  [SET_NOTIFICATIONS_FOR_INSIGHT_READ_SUCCESS]: (state: INotificationState, payload: any, field: string) => updateNotification(state, payload, 'app_read_at'),
  [SET_NOTIFICATIONS_READ_SUCCESS]: (state: INotificationState, payload: any, field: string) => updateNotification(state, payload, 'app_read_at'),
  [SET_NOTIFICATIONS_SEEN_SUCCESS]: (state: INotificationState, payload: any, field: string) => updateNotification(state, payload, 'app_seen_at'),
  [SET_NOTIFICATIONS_READ_FAILURE]: (state: INotificationState, payload: any): INotificationState => {
    return {
      ...state,
      markAllSeenLoading: false,
    }
  },
}, initState);