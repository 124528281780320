import React, { useEffect, useState } from 'react'
import { Button, Select, Input } from 'antd'

import storageService from "../../../services/storageService";
import config from "../../../shared/config";


import { validateEmail } from "../../../helpers/usersHelper"

import watchful_icon from "../../../assets/images/new_design/new_logo_ico.svg"
import "./SuggestionValidationEmail.scss"

const ROLES = [{
  key: 'strategy',
  label: 'Strategy',
},
{
  key: 'product',
  label: 'Product',
},
{
  key: 'marketing',
  label: 'Marketing',
},
{
  key: 'management',
  label: 'Management',
},
{
  key: 'other',
  label: 'Other',
}

]

const storage = new storageService();

export default function SuggestionValidationEmail({
  discovery,
  app,
  demoUser,
  analytics,
  insightLinkCode
}) {
  const [mail, mailChange] = useState('')
  const [role, roleChange] = useState()
  const [hideForm, hideFormChange] = useState(false)

  const [emailSend, emailSendChange] = useState(false)
  const [reqStatus, reqStatusChange] = useState(null)
  const [alreadyValidated, alreadyValidatedChange] = useState(false)

  useEffect(() => {
    if (reqStatus) {
      reqStatusChange(null)
    }
  }, [mail])

  const sendEmailRequest = async () => {
    if (mail && validateEmail(mail) && role) {
      const rawRsponse = await fetch(`${config.dataApi.backendUrl}/user/guests`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          "email": mail,
          "role": role,
          "link_code": insightLinkCode
        })
      });
      if (rawRsponse.status === 201 || rawRsponse.status === 200) {
        const res = await rawRsponse.json()
        if (res.data && res.data.insight_access_token) {
          storage.setItem('guest_token', res.data.insight_access_token)
          reqStatusChange('already_validated')
          setTimeout(() => {
            alreadyValidatedChange(true)
          }, 2000)
          sendAnalyticData(true, 'validated_guest')
          return
        }

        if (res.status === 'ok' && res.msg === "verification email has been sent") {
          emailSendChange(true)
          sendAnalyticData(true, 'new_guest')
        }

      }
      if (rawRsponse.status === 400) {
        const res = await rawRsponse.json()

        if (res.status === 'error' && res.msg === "incorrect email domain") {
          reqStatusChange('wrong_domain')
          sendAnalyticData(false, 'new_guest')
          return
        }
      }
    } else {
      reqStatusChange('incorrect_domain')
      setTimeout(() => {
        reqStatusChange(null)
      }, 2500)
    }
  }

  const sendAnalyticData = (ok_domain, user_type) => {
    analytics.analytic('Guest submitted validation request', {
      generated_by: demoUser,
      insight_name: discovery.title,
      insight_type: discovery.type,
      product: app.metadata.app_name,
      platform: app.metadata.platform,
      insight_url: window.location.href,
      guest_email: mail,
      guest_department: role,
      is_right_domain: ok_domain ? 'yes' : 'no',
      user_type: user_type,
      request_id: ''
    })
  }

  if (alreadyValidated) {
    return null
  }



  return (
    <div className={`suggestion-valid-email ${reqStatus}`} >
      <div className="blurry-back"></div>
      {emailSend ?
        <div className="modal-valid-form-thanks">
          <img src={watchful_icon} className="svem-logo" />
          <h3 className="svem-title">
            Thanks
          </h3>
          <div className='svem-descr-thanks'>
            Verification email has been sent to
            <div>{mail}</div>
            Click the link in the email to complete your access verification.
          </div>
          <div className='svem-descr-resend'>
            If you have not received the email after few minutes, please check your spam folder or  <span className='resend-email' onClick={sendEmailRequest}>click here</span> to resend the email.
          </div>
        </div>
        :
        <div className="modal-valid-form">
          {!hideForm && <div className='svem-close' onClick={() => hideFormChange(true)}>Dismiss <span>x</span></div>}
          {!hideForm && <img src={watchful_icon} className="svem-logo" />}
          <h3 className="svem-title">
            For full access to this discovery
          </h3>
          {hideForm ?
            <div className='svem-open-form-wrap'>
              <Button className="antd-red-button svem-open-form" onClick={() => hideFormChange(false)}>Validate my email</Button>
            </div>
            : <div>
              <div className="svem-descr">Please confirm your work email and your relevant area. We will email you a validation link.</div>
              <div className='svem-form'>
                <div className='svem-status'>
                  {reqStatus === 'already_validated' && <div className='svem-status-text'>Your email has already been validated. <strong> Enjoy the full devovery!</strong></div>}
                  {reqStatus === 'incorrect_domain' && <div className='svem-status-text'>Incorrect email</div>}
                  {reqStatus === 'wrong_domain' && <div>
                    <div className='svem-status-text'>Email from the domain {mail.substring(mail.lastIndexOf("@") + 1)} can’t access this insight.</div>
                    <div className='svem-status-descr'>If you feel like you should be able to access this email, let us know by clicking
                      <a className='open-email-app-link' href="mailto:team@watchful.ai?subject=Requesting guest access (domain not accepted)">here.</a>
                    </div>
                  </div>}
                </div>
                <Input type='email' value={mail} onChange={(e) => mailChange(e.target.value)} placeholder="Your company email" />
                <Select value={role} onChange={roleChange} className="antd-black-select" placeholder={role ? '' : "Choose your relevant area"} popupClassName="antd-black-select-dropdown">
                  {ROLES.map(role => <Select.Option key={role.key} value={role.key}>{role.label}</Select.Option>)}
                </Select>
                <Button disabled={!mail || !role} className="antd-red-button" onClick={sendEmailRequest}>Send a validation</Button>
              </div>
            </div>}
        </div>
      }

    </div>
  )
}
