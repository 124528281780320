import React, { ReactElement } from 'react'
import { INotification } from '../../../interfaces/Notification';
import moment from "moment"

import time_ico from "../../../assets/images/new_design/icons/time.svg"
import android_logo from "../../../assets/images/new_design/icons/playstore.svg"
import ios_logo from "../../../assets/images/new_design/icons/appstore.svg"
import web_logo from "../../../assets/images/new_design/icons/web2.svg"
import marketing_ico from "../../../assets/images/new_design/icons/marketing_icon2.svg"
import chrome_ico from "../../../assets/images/new_design/icons/ic-chrome-selected.svg"
import win_ico from "../../../assets/images/new_design/icons/ic-windows-selected.svg"
import { PL_ANDROID, PL_IOS, PL_WEB, PL_WINDOWS, PL_MARKETING, PL_CHROME } from '../../../shared/consts';
interface Props {
  notification: INotification,
  handlerNotificationClick: (notification: INotification) => void,
  options: any
}

export const ICONS_MAP = {
  [PL_ANDROID]: <img src={android_logo} className={`app-overlay-platform Android`} />,
  [PL_IOS]: <img src={ios_logo} className={`app-overlay-platform iOS}`} />,
  [PL_WEB]: <img src={web_logo} className={`app-overlay-platform Web`} />,
  [PL_MARKETING]: <img src={marketing_ico} className={`app-overlay-platform Marketing`} />,
  [PL_WINDOWS]: <img src={win_ico} className={`app-overlay-platform windows`} />,
  [PL_CHROME]: <img src={chrome_ico} className={`app-overlay-platform chrome_extension`} />
}

export default function Note({
  notification,
  handlerNotificationClick,
  options
}: Props): ReactElement {


  return (
    <div className={`notification-item ${!notification.app_read_at ? 'unread' : ''} ${notification.link ? 'clickable' : ''}`}
      onClick={() => notification.link ? handlerNotificationClick(notification) : ''} data-testid={`note ${notification.id}`}
      {...(notification.link && { 'data-test-link': notification.link })}
    >
      <div className="ntf-app-data">
        {notification.icon
          ?
          <div className="app-icon-wrapper">
            {/*@ts-ignore*/}
            {ICONS_MAP[notification.icon_platform_overlay]}
            <img src={notification.icon} className="app-icon" />
          </div>

          :
          <div className="empty-icon"></div>
        }
      </div>
      <div className="ntf-descr">
        <div className="ntf-descr-text">{notification.description}</div>

        {!options.hideTime && <div className="ntf-time-diff">
          <img src={time_ico} />
          {
            options.humanize ?
              //@ts-ignore
              `${moment.duration(moment() - moment(notification.time)).humanize()} ago`
              : moment(notification.time).format(options.format)
          } </div>}

      </div>
    </div>
  )
}
