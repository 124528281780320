import { Col, Input, Spin } from "antd";
import React from "react";
import "./styles.scss";
import { useIsOpen } from "pages/ContextTimeline/hooks/useIsOpen";
import chevron_left_ico from "assets/images/icons/context-timeline/chevron-left.svg";
import chevron_right_ico from "assets/images/icons/context-timeline/chevron-right.svg";
import chevron_up_ico from "assets/images/icons/context-timeline/chevron-up.svg";
import chevron_down_ico from "assets/images/icons/context-timeline/chevron-down.svg";
import avatar_ico from "assets/images/icons/context-timeline/avatar.svg";
import { useContextTimeline } from "pages/ContextTimeline/context";
import { SearchOutlined } from "@ant-design/icons";
import { useSearch } from "pages/ContextTimeline/hooks/useSearch";
import { EventListItem } from "./event-list-item";
import clsx from "clsx";
import moment from "moment";

export const Sidebar = () => {
  const { isOpen: isPersonaOpen, toggleIsOpen: togglePersona } = useIsOpen({
    isOpenDefault: false,
  });
  const { search, onSearch } = useSearch();
  const {
    selectedApp,
    eventsList,
    eventsByDateList,
    selectedPersona,
    isSidebarOpen,
    openSidebar,
    closeSidebar,
    isLoading,
  } = useContextTimeline();

  const filteredEvents = eventsList?.filter((event) =>
    event.name.toLowerCase().includes(search.toLowerCase())
  );

  const emailCommunications = (eventsByDateList || []).reduce((acc, curr) => {
    const { events } = curr;

    const total = events.reduce((acc, curr) => {
      const { insights } = curr;
      const email = insights.filter((i) => i.type.includes("email"));
      return acc + email.length;
    }, 0);

    return acc + total;
  }, 0);

  const pushCommunications = (eventsByDateList || []).reduce((acc, curr) => {
    const { events } = curr;

    const total = events.reduce((acc, curr) => {
      const { insights } = curr;
      const email = insights.filter((i) => i.type.includes("push"));
      return acc + email.length;
    }, 0);

    return acc + total;
  }, 0);

  return (
    <Col
      flex={isSidebarOpen ? "366px" : "22px"}
      className={clsx("ctl-sidebar", {
        collapsed: !isSidebarOpen,
      })}
    >
      <Spin spinning={isLoading}>
        <div className="ctl-sidebar-header">
          {isSidebarOpen ? (
            <div className="ctl-sidebar-collapse" onClick={closeSidebar}>
              <span>Collapsed</span>
              <img src={chevron_left_ico} />
            </div>
          ) : (
            <div className="ctl-sidebar-expand" onClick={openSidebar}>
              <img src={chevron_right_ico} />
            </div>
          )}
        </div>
        {isSidebarOpen && selectedApp && (
          <div className="ctl-current-app">
            <img src={selectedApp.icon} />
            <span>{selectedApp.displayName}</span>
          </div>
        )}
        {isSidebarOpen && selectedPersona && (
          <div className="ctl-current-persona">
            <div className="ctl-current-persona-header">
              <span>User Type</span>
              <img
                src={isPersonaOpen ? chevron_down_ico : chevron_up_ico}
                onClick={togglePersona}
              />
            </div>
            <div className="ctl-persona-details">
              <img src={selectedPersona.icon || avatar_ico} />
              <div className="persona-title-info">
                <span className="persona-title">{selectedPersona.title}</span>
                <span className="persona-active-for">
                  {selectedPersona.activeFor
                    ? `Active For ${moment().diff(
                        selectedPersona.activeFor,
                        "day"
                      )} Days`
                    : ""}
                </span>
              </div>
            </div>
            {isPersonaOpen && (
              <div className="persona-more-details">
                <div className="persona-field-group">
                  {selectedPersona.location && (
                    <div className="persona-field">
                      <div className="field-name">Location</div>
                      <div className="field-value">
                        {selectedPersona.location}
                      </div>
                    </div>
                  )}
                  {selectedPersona.browsingCadence && (
                    <div className="persona-field">
                      <div className="field-name">Browsing Cadence</div>
                      <div className="field-value">
                        {selectedPersona.browsingCadence}
                      </div>
                    </div>
                  )}
                  {selectedPersona.purchaseBehavior && (
                    <div className="persona-field">
                      <div className="field-name">Purchase Behavior</div>
                      <div className="field-value">
                        {selectedPersona.purchaseBehavior}
                      </div>
                    </div>
                  )}
                  {selectedPersona.subscription && (
                    <div className="persona-field">
                      <div className="field-name">Subscription</div>
                      <div className="field-value">
                        {selectedPersona.subscription}
                      </div>
                    </div>
                  )}
                </div>
                <div className="persona-field-group">
                  {selectedPersona.gender && (
                    <div className="persona-field">
                      <div className="field-name">Gender</div>
                      <div className="field-value">
                        {selectedPersona.gender}
                      </div>
                    </div>
                  )}
                  {selectedPersona.age && (
                    <div className="persona-field">
                      <div className="field-name">Age</div>
                      <div className="field-value">{selectedPersona.age}</div>
                    </div>
                  )}
                </div>
                <div className="persona-field-group">
                  <div className="persona-field">
                    <div className="field-name">Marketing Communications</div>
                    <div className="field-value">
                      {emailCommunications + pushCommunications}
                    </div>
                  </div>
                  <div className="persona-field">
                    <div className="field-name">Email Campaign</div>
                    <div className="field-value">{emailCommunications}</div>
                  </div>
                  <div className="persona-field">
                    <div className="field-name">Push Notifications</div>
                    <div className="field-value">{pushCommunications}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {isSidebarOpen && filteredEvents && (
          <div className="ctl-events-list">
            <div className="events-list-header">
              <div className="events-list-title">
                Events List ({filteredEvents.length})
              </div>
              <Input
                className="search-input"
                placeholder="Search Event"
                suffix={<SearchOutlined />}
                style={{ width: "100%" }}
                value={search}
                onChange={(e) => onSearch(e.target.value)}
              />
            </div>
            <div className="events">
              {filteredEvents.map((event) => (
                <EventListItem event={event} />
              ))}
            </div>
          </div>
        )}
      </Spin>
    </Col>
  );
};
