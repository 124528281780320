import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import UserProfile from './UserProfile';
import { Tabs, message } from "antd"

import "cropperjs/dist/cropper.css";

import './phone.scss';
import {
  setUserName,
  setUserPass,
  setUserPic,
  deleteUserPic,
  setUserPassSuccessReset,
  setUserDataSuccessReset,
  emailPrefSet,
  getAccessRequests,
  setAccessRequestStatus,
} from "../../redux/actions/userActions"

import {
  getOrganizationUsers,
  deleteOrganizationUser,
  sendInviteForUser,
  cancelInviteForUser,
  resendInviteForUser
} from "../../redux/acts/organization"

import EmailPreferences from "./EmailPreferences";
import { getSubscriptions } from "../../redux/acts/subscriptions"
import UsersManagement from "./UsersManagement"
import { editSubscription } from "../../redux/acts/subscriptions"
const { TabPane } = Tabs;

export const Account = ({
  user,
  userData,
  setUserName,
  history,
  setUserPass,
  setUserPic,
  deleteUserPic,
  setUserPassSuccessReset,
  setUserDataSuccessReset,
  analytics,
  emailPrefSet,
  getSubscriptions,
  subscriptions,
  editSubscription,
  getAccessRequests,
  accessRequests,
  setAccessRequestStatus,

  getOrganizationUsers,
  orgUsers,
  deleteOrganizationUser,
  sendInviteForUser,
  cancelInviteForUser,
  sendInviteError,
  sendInviteSuccess,
  cancelInviteError,
  resendInviteError,
  resendInviteForUser
}) => {

  //vars filter
  const url = new URLSearchParams(history.location.search)

  const [activeTab, activeTabChange] = useState(url.get('tab') ? url.get('tab') : 'user')

  useEffect(() => {
    getSubscriptions()
    if (userData.is_org_admin) {
      getAccessRequests()
      getOrganizationUsers()
    }
  }, [])

  const changeTabHandle = (tab) => {
    activeTabChange(tab)
    history.replace(`/account?tab=${tab}`);
  }

  const editSubscriptionHandle = (key, value, subscription) => {
    if (subscription === 'all') {
      let data = {
        [key]: value
      }
      editSubscription({ all: true, data: data })
    } else {
      let data = {
        ...subscription,
        "label_ids": subscription.labels.map(l => l.id),
        "product_ids": subscription.products.map(p => p.id),
        [key]: value
      }
      editSubscription({ id: subscription.id, data: data })
    }
    message.loading({ content: 'saving...', duration: 0, icon: <div /> })
  }

  return (
    <div className="account-page">
      <h1 className="h1-title">
        Account <strong>Settings</strong>
      </h1>
      <div className="wrapper-tabs">
        <Tabs animated={{ inkBar: true, tabPane: false }} activeKey={activeTab} onChange={(tab) => changeTabHandle(tab)}>
          <TabPane tab={`Personal Details`} key="user">
            <UserProfile
              user={user}
              userData={userData}
              setUserName={setUserName}
              history={history}
              setUserPass={setUserPass}
              setUserPic={setUserPic}
              deleteUserPic={deleteUserPic}
              setUserPassSuccessReset={setUserPassSuccessReset}
              setUserDataSuccessReset={setUserDataSuccessReset}
              analytics={analytics} />
          </TabPane>
          <TabPane tab={'Email Preferences'} key="email" data-testid="email-preferences-tab">
            <EmailPreferences
              userData={userData}
              emailPrefChangesHandle={emailPrefSet}
              editSubscriptionHandle={editSubscriptionHandle}
              subscriptions={subscriptions}
            />
          </TabPane>
          {userData.is_org_admin && <TabPane tab={'User Management'} key="access-request" data-testid="users-management-tab">
            <UsersManagement
              accessRequests={accessRequests}
              setAccessRequestStatus={setAccessRequestStatus}
              analytics={analytics}
              orgUsers={orgUsers}
              deleteOrganizationUser={deleteOrganizationUser}
              sendInviteForUser={sendInviteForUser}
              cancelInviteForUser={cancelInviteForUser}
              sendInviteError={sendInviteError}
              sendInviteSuccess={sendInviteSuccess}
              cancelInviteError={cancelInviteError}
              resendInviteForUser={resendInviteForUser}
              resendInviteError={resendInviteError}
            />
          </TabPane>}
        </Tabs>
      </div>
    </div>
  )
}

const mapStateToProps = ({ appData, userData, subscriptions, organization }) => ({
  user: appData.user,
  analytics: appData.user.analytics,
  userData,
  subscriptions: subscriptions.subscriptionsList,
  accessRequests: organization.accessRequests,
  orgUsers: organization.orgUsers,
  sendInviteError: organization.sendInviteError,
  sendInviteSuccess: organization.sendInviteSuccess,
  cancelInviteError: organization.cancelInviteError,
  resendInviteError: organization.resendInviteError
})

const mapDispatchToProps = {
  setUserName,
  setUserPass,
  setUserPic,
  deleteUserPic,
  setUserPassSuccessReset,
  setUserDataSuccessReset,
  emailPrefSet,
  getSubscriptions,
  editSubscription,
  getAccessRequests,
  setAccessRequestStatus,
  getOrganizationUsers,
  deleteOrganizationUser,
  sendInviteForUser,
  cancelInviteForUser,
  resendInviteForUser
}

export default connect(mapStateToProps, mapDispatchToProps)(Account)
