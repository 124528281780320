import { useEffect, useMemo, useState } from "react";
import { App, UserRoleType, UserType } from "../utils/types";
import { CAMPAIGNS_FILTERS, ITEMS_PER_PAGE } from "../utils/conts";
import moment from "moment";
import { ArraySchema } from "yup";

export type ChangeCampaignsFilterType = (
  filter: string,
  newValue: string | string[]
) => void;

type Props = {
  history: any;
  apps: App[];
  userTypes: UserType[];
  userRoleTypes: UserRoleType[];
};

export const useCampaignsFilters = ({
  history,
  apps,
  userTypes,
  userRoleTypes,
}: Props) => {
  const params = new URLSearchParams(history.location.search);
  const appId = params.get(CAMPAIGNS_FILTERS.app_id) || "";
  const insightId = params.get(CAMPAIGNS_FILTERS.insight_id) || "";
  const selectedUserTypes = params.getAll(CAMPAIGNS_FILTERS.user_types) || "";
  const selectedUserRoleTypes =
    params.getAll(CAMPAIGNS_FILTERS.user_role_types) || "";
  const daysAgo = params.get(CAMPAIGNS_FILTERS.days_ago)
    ? parseInt(params.get(CAMPAIGNS_FILTERS.days_ago) || "30")
    : 30;
  const channelId = params.get(CAMPAIGNS_FILTERS.channel) || "";
  const customRange = !!(
    params.get(CAMPAIGNS_FILTERS.custom_range) &&
    params.get(CAMPAIGNS_FILTERS.custom_range) !== "false"
  );
  const selectedFocusAreas = params.getAll(CAMPAIGNS_FILTERS.focus_areas) || [];

  const customRangeTimes = params.getAll(CAMPAIGNS_FILTERS.custom_range_time);
  const customRangeTime =
    customRangeTimes.length > 1
      ? [moment(customRangeTimes[0]), moment(customRangeTimes[1])]
      : [moment(), moment()];

  const currentApp = useMemo(
    () => apps.find((app) => app.id === Number(appId)),
    [apps, appId]
  );

  const handleChangeFilter: ChangeCampaignsFilterType = (
    filter: string,
    newValue: string | string[]
  ) => {
    if (Array.isArray(newValue)) {
      params.delete(filter);
      newValue.forEach((value) => {
        params.append(filter, value);
      });
    } else {
      params.set(filter, newValue);
    }

    if (
      filter !== CAMPAIGNS_FILTERS.limit &&
      !params.get(CAMPAIGNS_FILTERS.limit)
    ) {
      params.set(CAMPAIGNS_FILTERS.offset, "0");
      params.set(CAMPAIGNS_FILTERS.limit, ITEMS_PER_PAGE.toString());
    }

    history.replace({
      search: params.toString(),
    });
  };

  useEffect(() => {

    if (!appId && apps.length) {
      handleChangeFilter(CAMPAIGNS_FILTERS.app_id, apps[0].id.toString());
    }

    if (userTypes && selectedUserRoleTypes.length === 0 && selectedUserTypes.length === 0) {
      handleChangeFilter(
        CAMPAIGNS_FILTERS.user_types,
        userTypes
          .filter((type) => type.roles == null || type.roles.length === 0)
          .map((type) => type.id.toString())
      );
    }

    if (userRoleTypes && selectedUserRoleTypes.length === 0 && selectedUserTypes.length === 0) {
      handleChangeFilter(
        CAMPAIGNS_FILTERS.user_role_types,
        userRoleTypes.map((type) => type.id.toString())
      );
    }
  }, [apps, userTypes, userRoleTypes]);

  return {
    currentApp,
    appId,
    insightId,
    selectedUserTypes,
    selectedUserRoleTypes,
    daysAgo,
    channelId,
    customRange,
    customRangeTime,
    handleChangeFilter,
    selectedFocusAreas,
  };
};
