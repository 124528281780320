import React, { useState, useEffect, useRef, useCallback } from "react";
import { durationFormatter } from "../../../../helpers/numbersHelper";
import { Badge, Button } from "antd";
import PerformanceVideoManager from "./PerformanceVideoManager";
import { chain, get, minBy, memoize } from "lodash";
import PerformanceGrayBar from "./PerformanceGrayBar";
import PerformanceEventLine from "./PerformanceEventLine";

import video_play_ico from "../../../../assets/images/new_design/video_play_ico.svg"
import video_pause_ico from "../../../../assets/images/new_design/video_pause_ico.svg"
import video_stop_ico from "../../../../assets/images/new_design/video_stop_ico.svg"
import { COMPARE_COLOR_1 as mainColor } from "../comparePageUtils"
import { COMPARE_COLOR_2 as compareColor } from "../comparePageUtils"

import "./performanceVideoManager.scss"
import "./PerformanceEventMetrics.scss"

export default function PerfVideoRepr({
  isCompareMode,
  mainEvData,
  mainEvApp,
  compEvData,
  compEvApp,
}) {

  const [cursorPosition, cursorPositionChange] = useState({
    px: 0,
    percentage: 0,
    currentTime: 0
  })
  const [videosState, videosStateChange] = useState({
    main: null,
    compare: null
  })
  const [isPlayingMode, isPlayingModeChange] = useState(false)
  const [isPlayingModePause, isPlayingModePauseChange] = useState(false)
  const [subEventHover, subEventHoverChange] = useState(false)


  let timeout = useRef(null)

  useEffect(() => {
    setVideosState()
  }, [mainEvData, compEvData])

  useEffect(() => {
    const barsContent = document.getElementById("bars-content");
    if (barsContent) {
      barsContent.addEventListener("mousemove", handleMouseMove);
    }
    return () => {
      if (barsContent) {
        barsContent.removeEventListener("mousemove", handleMouseMove);
      }
    }

  }, [videosState, isPlayingMode])

  useEffect(() => {

    const barsContent = document.getElementById("bars-content");
    if (!barsContent) {
      return
    }
    const rect = barsContent.getBoundingClientRect();
    const Q = 0.01

    if (isPlayingMode) {
      cursorPositionChange({
        px: 0,
        percentage: 0,
        currentTime: 0
      })

      timeout.current = setInterval(() => {
        isPlayingModePauseChange(isPlayingModePause => {
          if (!isPlayingModePause) {
            cursorPositionChange(cursorPosition => {
              const fixWidth = rect.width - rect.width * 0.06

              if (cursorPosition.percentage + 100 * Q > 100) {
                clearTimeout(timeout.current)
                isPlayingModeChange(false)
                return {
                  px: fixWidth,
                  percentage: 100,
                  currentTime: videosState.longerVideoObjRef.duration
                }
              }
              return {
                px: (cursorPosition.px || 0) + fixWidth * Q,
                percentage: (cursorPosition.percentage || 0) + 100 * Q,
                currentTime: cursorPosition.currentTime + videosState.longerVideoObjRef.duration * Q
              }
            })
          }
          return isPlayingModePause
        })
      }, 60)
    } else {
      clearTimeout(timeout.current)
    }
  }, [isPlayingMode])

  const setVideosState = () => {
    const modVideosState = {}
    if (mainEvData && Object.keys(mainEvData).length > 0 && mainEvData.representative_sample) {
      modVideosState.main = {
        duration: mainEvData.representative_sample.globalEventDuration,
        start_offset: 0,
        vidPosition: 0
      };
      modVideosState.longerVideoObjRef = modVideosState.main; // The longer video is the main video by default
      modVideosState.shorterVideoObjRef = null;
    }



    if (compEvData && Object.keys(compEvData).length > 0 && compEvData.representative_sample) {
      modVideosState.compare = {
        duration: compEvData.representative_sample.globalEventDuration,
        start_offset: 0,
        vidPosition: 0
      };
      modVideosState.longerVideoObjRef = modVideosState.compare; // The longer video is the main video by default
      modVideosState.shorterVideoObjRef = null;
    }

    if (modVideosState.main && modVideosState.compare) {
      if (
        modVideosState.main.duration <
        modVideosState.compare.duration
      ) {
        // compare is the longer
        modVideosState.longerVideoObjRef = modVideosState.compare;
        modVideosState.shorterVideoObjRef = modVideosState.main;
      } else {
        // main is the longer
        modVideosState.longerVideoObjRef = modVideosState.main;
        modVideosState.shorterVideoObjRef = modVideosState.compare;
      }
    }

    videosStateChange(modVideosState)
  }

  const handleMouseMove = useCallback(e => {
    if (isPlayingMode) {
      return
    }
    if (e.preventDefault) e.preventDefault();
    if (e.stopPropagation) e.stopPropagation();
    const barsContent = document.getElementById("bars-content");
    const rect = barsContent.getBoundingClientRect();

    // offset beacause line width less than Rect.width cause END tooltip
    const offset = 0.06;

    let relativeX = e.clientX - rect.left;
    if (relativeX <= 0) {
      relativeX = 0;
    }

    // offset beacause line width less than Rect.width cause END tooltip
    if (relativeX > rect.width - (rect.width * offset)) {
      relativeX = rect.width - (rect.width * offset);
    }


    let barPercentage = Number(((relativeX / rect.width) * 100).toFixed(1));

    barPercentage = barPercentage + barPercentage * offset;

    if (barPercentage > 100) {
      barPercentage = 100;
    }


    if (barPercentage >= 0 && barPercentage <= 100) {
      const currentCursorPosition = {
        px: relativeX,
        percentage: barPercentage,
        currentTime: videosState.longerVideoObjRef.duration * (barPercentage / 100),
      };
      if (cursorPosition.px !== currentCursorPosition.px) {
        let subEvent = {
          main: mainEvData.representative_sample ? getSubEvent(relativeX, 'main', mainEvData) : false,
          compare: compEvData?.representative_sample ? getSubEvent(relativeX, 'compare', compEvData) : false
        }
        cursorPositionChange(currentCursorPosition)
        subEventHoverChange(subEvent)
      }
    }
  }, [videosState]);


  const getSubEvent = (relativeX, mode, data) => {
    const percGutter = 2.3

    const bar = Array.from(document.querySelectorAll(`.performance-metrics__bars-content-bar-part.${mode}`)) || [];
    const filledBarWidth = bar.reduce(
      (acc, bar) => acc + bar.getBoundingClientRect().width,
      0
    );
    const relativePercentage = (relativeX / filledBarWidth) * 100;
    const subEvent = data.representative_sample.sub_events.find((el, i) => Math.abs(el.absPerc - relativePercentage) < percGutter)

    return subEvent
  }

  const handlePlayChange = () => {
    if (isPlayingMode) {
      isPlayingModePauseChange(!isPlayingModePause)
    } else {
      isPlayingModeChange(true)
      isPlayingModePauseChange(false)
    }
  }

  const handleStopChange = () => {
    cursorPositionChange({
      px: 0,
      percentage: 0,
      currentTime: 0
    })
    clearTimeout(timeout.current)
    isPlayingModeChange(false)
    isPlayingModePauseChange(false)

  }

  const getCursorPositionPx = (offset = 0) => {
    return `${cursorPosition.px - 3.5 + offset}px`
  }

  const groupedSubevents = memoize(data => {
    if (!data) return [];
    const timeSpan = 0.09;
    return chain(data)
      .get("representative_sample.sub_events", [])
      .reduce((acc, subEvent) => {
        if (acc.length) {
          const prevAverageGroupTime = acc[acc.length - 1].reduce((acc, val) => val.end_ts_s + acc, 0) /
            acc[acc.length - 1].length;
          if (Math.abs(subEvent.end_ts_s.toFixed(1) - prevAverageGroupTime.toFixed(1)) <=
            timeSpan &&
            subEvent.name !== "end_time"
          ) {
            acc[acc.length - 1].push(subEvent);
            return acc;
          }
          return [...acc, [subEvent]];
        } else {
          return [[subEvent]];
        }
      }, [])
      .map(el => {
        const closest = minBy(el, "end_ts_s");
        return {
          ...closest,
          related: el.filter(el => el.name !== closest.name)
        };
      })
      .value();
  });


  if (
    !mainEvData ||
    !mainEvApp ||
    !Object.keys(mainEvApp.performance_events).length > 0
  ) {
    return null;
  }


  const groupedMainEvents = groupedSubevents(mainEvData);
  const groupedComparedEvents = groupedSubevents(compEvData);


  return (
    <div id="metrics" className="performance-metrics new">
      <div className="bars-and-video">
        <div className="titles-and-bars">
          <h3 className="title">Example user journey</h3>
          <div className="extra-text">
            See an example recording of one of the samples in the selected range
          </div>
          <div className="extra-text-2">
            Use your mouse to hover over the timeline to expose visual preview
          </div>

          <div className="performance-metrics__bars-content-wrap">
            <div className="playing-mode-change" >
              {!isPlayingMode || isPlayingModePause ?
                <img src={video_play_ico} onClick={handlePlayChange} /> :
                <img src={video_pause_ico} onClick={handlePlayChange} />}
              <img className="playing-mode-change-stop" src={video_stop_ico} onClick={handleStopChange} disabled={!isPlayingMode} />
              <span className="playing-mode-time">
                {isPlayingMode ? `${cursorPosition.currentTime.toFixed(1)}s` : '0.0s'}
              </span>
            </div>
            <div
              id="bars-content"
              className={`performance-metrics__bars-content ${isCompareMode ? "comparing" : ""
              }`}
            >
              {(videosState.main && videosState.compare) && <div className="red-mark-link" style={{ left: getCursorPositionPx(3) }} />}
              {videosState.main &&
                Object.keys(mainEvData).length > 0 &&
                <div
                  className="performance-metrics__bars-content-bar-wrapper"
                >
                  <img className="app-icon" src={mainEvApp.icon} alt="" />
                  <PerformanceGrayBar />
                  <div
                    className="red-mark main"
                    style={{
                      left: getCursorPositionPx(),
                      display: 'block',
                      visibility: subEventHover.main ? 'hidden' : 'visible'
                    }}
                  />
                  {cursorPosition.currentTime > 0 && (
                    <div className="current-time-indicator" style={{ left: getCursorPositionPx() }}>
                      {durationFormatter(cursorPosition.currentTime * 1000, 0).formattedNumberString}
                    </div>
                  )}
                  <PerformanceEventLine
                    width={
                      (videosState.main.duration /
                        videosState.longerVideoObjRef.duration) *
                      100
                    }
                    selectedEventSubevents={mainEvData.subEvent}
                    name="main"
                    selectedMode={true}
                    metadata={mainEvApp}
                    groupedEvents={groupedMainEvents}
                    subEventHover={subEventHover.main}
                    data={mainEvData}
                    handleHoverSubEvent={subEventHoverChange}
                  />
                </div>
              }
              {isCompareMode &&
                videosState.compare &&
                videosState.longerVideoObjRef &&
                Object.keys(compEvData).length > 0 && (
                <div
                  className="performance-metrics__bars-content-bar-wrapper comparing"
                >
                  <img className="app-icon" src={compEvApp.icon} alt="" />
                  <PerformanceGrayBar />
                  <div
                    className="red-mark compare"
                    style={{
                      left: getCursorPositionPx(),
                      display: 'block',
                      visibility: subEventHover.compare ? 'hidden' : 'visible'
                    }}
                  />
                  {get(videosState, "compare.vidPosition") > 0 && (
                    <div
                      className="current-time-indicator"
                      style={{ left: getCursorPositionPx() }}
                    >
                      {durationFormatter(cursorPosition.currentTime * 1000, 0).formattedNumberString}
                    </div>
                  )}
                  <PerformanceEventLine
                    width={
                      (videosState.compare.duration /
                          videosState.longerVideoObjRef.duration) *
                        100
                    }
                    selectedEventSubevents={compEvData.subEvent}
                    metadata={compEvApp}
                    name="compare"
                    selectedMode={true}
                    groupedEvents={groupedComparedEvents}
                    subEventHover={subEventHover.compare}
                    data={compEvData}
                    handleHoverSubEvent={subEventHoverChange}
                  />

                </div>
              )}
              <div className="performance-metrics__legend-footer">
                {videosState.main && videosState.compare && compEvApp &&
                  <Badge
                    color={mainColor}
                    text={mainEvApp.name}
                  />}
                {videosState.compare && videosState.main && compEvApp &&
                  <Badge
                    color={compareColor}
                    text={compEvApp.name}
                    style={{ marginLeft: "4rem" }}
                  />}
              </div>
            </div>
          </div>
        </div>
        <div className="video-wrapper" >
          <div className="video-and-overlay">
            {mainEvData && mainEvData.representative_sample && videosState.main &&
              <PerformanceVideoManager
                videoMode="main"
                videoURL={mainEvData.representative_sample.event_video}
                videoTime={cursorPosition.currentTime}
                videoCoords={{
                  start: 0,
                  end: mainEvData.representative_sample.globalEventDuration,
                  q: 1
                }}
              />
            }
            {compEvData && compEvData.representative_sample && videosState.compare &&
              <PerformanceVideoManager
                videoMode="compare"
                videoURL={compEvData.representative_sample.event_video}
                videoTime={cursorPosition.currentTime}
                videoCoords={{
                  start: 0,
                  end: compEvData.representative_sample.globalEventDuration,
                  q: 1
                }}
              />
            }
          </div>
        </div>


      </div>
    </div>
  );
}
