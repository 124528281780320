import React, { useEffect, useState } from 'react'

import ViewModal from '../../components/discoveryView/viewModal/ViewModal';
import AccessRequestModal from "../../containers/header/AccessRequestModal"

import watchful_logo_ico from "../../assets/images/watchful_logo_whitered.svg"
import discovery_viewed from "../../assets/images/icons/insight-viewed.svg"
import moment from 'moment';
import { Button } from "antd"


import "./SneakPeakBiWeeklySubHeader.scss"
import share_ico from "../../assets/images/new_design/bi-weekly-share.svg";
import {handleShareClick} from "../../containers/BiWeeklyGallery/BiweeklyUtils";

export default function SneakPeakBiWeeklySubHeader({
  dates,
  organization,
  insights,
  currentItemViewed,
  currentItem,
  biWeeklyHighlightID,
  user,
  recipients,
  sendShareBiWeeklyEmail,
  analytics,
  showShareModal,
  showShareModalChange,
  maxItemViewed,
  linkReferrer,
}) {
  const genBiWeeklyLink = biWeeklyHighlightID => `${window.location.protocol}//${window.location.host}/link-bi-weekly/${biWeeklyHighlightID}`

  const [recipientForShare, recipientForShareChange] = useState(null);
  const [commentForShare, commentForShareChange] = useState('');
  const linkUrl = genBiWeeklyLink(biWeeklyHighlightID);

  const [isRequestModalOpen, isRequestModalOpenChange] = useState(false)

  const closeModal = () => {
    showShareModalChange(false)
  }

  const sendEmail = () => {

    let data = { "link_code": biWeeklyHighlightID, "to": [recipientForShare], "comment": commentForShare }

    sendShareBiWeeklyEmail(data);
    showShareModalChange(false)
    analytics.analytic('Share bi-weekly gallery',
      {
        sender: user,
        sender_org: organization.name,
        recipient: recipientForShare,
        comment: commentForShare,
        url: genBiWeeklyLink(biWeeklyHighlightID),
      })
  }


  const handleOpenRequestModal = () => {
    isRequestModalOpenChange(true)
    analytics.analytic('Viewer opened request access dialog',
      {
        location: 'bi-weekly',
        url: genBiWeeklyLink(biWeeklyHighlightID),
        insight_name: '',
        insight_type: '',
        app_name: '',
        platform: '',
      }
    )
  }

  return (
    <div className='bi-weekly-header'>
      <div className='bi-weekly-title'>
        <img src={watchful_logo_ico} />
        {!user && <div className="request-btn-wrap"><Button onClick={handleOpenRequestModal}>Request Access</Button></div>}
      </div>
      <div className='bi-weekly-title-2'>
        <div>Bi-weekly <strong> Highlights</strong></div>
        {user && <Button type='default' className='share-btn' onClick={() => handleShareClick(organization, biWeeklyHighlightID, showShareModalChange, analytics)}>
          Share with colleagues
          <img src={share_ico} />
        </Button>}
      </div>
      <div className='bi-weekly-dates'>
        {moment(dates.date_from).format('DD/MM')} - {moment(dates.date_to).format('DD/MM')}
      </div>
      <div className='bi-weekly-descr'>
        Let's take a look at the most important discoveries of the last couple of weeks
      </div>
      <div className="ins-lines">
        {insights.map((ins, i) => <div className='ins-line-wrap' key={i}>
          <div className={`ins-line ${i <= maxItemViewed ? 'dark-green': ''} ${currentItemViewed >= i ? 'light-green' : ''}  `} />
        </div>)}
      </div>
      {showShareModal && <ViewModal
        closeModal={closeModal}
        modalShow={showShareModal}
        linkUrl={linkUrl}
        recipients={recipients}
        recipient={recipientForShare}
        recipientChange={recipientForShareChange}
        comment={commentForShare}
        commentChange={commentForShareChange}
        placeHolder={"I am sharing with you new Bi-weekly highlights with significant information. Check it Out:"}
        sendEmail={sendEmail}
        key={`modal-share-${biWeeklyHighlightID}`}
        subject="Bi-weekly Highlights"
        whoCanView="Anyone with this link, will be able to view the Bi-weekly Highlights"
      />}
      {isRequestModalOpen &&
        <AccessRequestModal
          isRequestModalOpen={isRequestModalOpen}
          isRequestModalOpenChange={isRequestModalOpenChange}
          analytics={analytics}
          sharedInsightData={{
            location: 'bi-weekly',
            url: genBiWeeklyLink(biWeeklyHighlightID),
            insight_name: '',
            insight_type: '',
            app_name: '',
            platform: '',
          }}
          linkReferrer={linkReferrer}
        />}
    </div>
  )
}
