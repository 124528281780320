import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { Badge } from "antd";
import moment from "moment";
import { cloneDeep, chain } from "lodash";
import { durationFormatter } from "../../../../helpers/numbersHelper";
import WFSelect from "../../../../common/WFSelect/WFSelect";

import { COMPARE_COLOR_1 as mainColor } from "../comparePageUtils"
import { COMPARE_COLOR_2 as compareColor } from "../comparePageUtils"

import "./performanceEventHistoryGraph.scss"



const PerformanceEventHistoryGraph = ({
  isCompareMode,
  mainEventMetadata,
  compareEventMetadata,
  mainEventHistoryData = [],
  compareEventHistoryData = [],
  isProductPage,
  eventTypes,
  handleChangeEventGraph,
  selectedSubEvent,
  selectedDaysAgoValue = 60,
  hideTitles = false,
  height,
  showDates,
  metricName = 'def',
  syncedGraphs,
  refLink,
  formatter,
  mainGradientColor,
  lineColor,
  updateSyncedIndicatorPoints,
  leftTitle,
  backgroudColor,
  showVerticalGridLines = false,
  centeredPoints = false,
  isMetrics = false
}) => {



  const basicDataset = {
    label: "",
    fill: true,
    borderWidth: 2,
    lineTension: 0,
    borderColor: lineColor ? lineColor : mainColor,
    pointBackgroundColor: lineColor ? lineColor : mainColor,
    pointBorderColor: "#FFFFFF",
    pointBorderWidth: 2,
    pointHoverRadius: 5,
    pointRadius: 3,
    hitRadius: 25,
    pointHoverBorderColor: "#FFFFFF",
    pointHoverBorderWidth: 5,
    propagate: true,
    hoverBorderWidth: 5,
    hoverBorderColor: 'red',
    data: [],

  };

  const CHART_WIDTH = 727
  const CHART_HEIGHT = height || 300

  const [selectedSubevent, setSelectedSubevent] = useState(selectedSubEvent);
  const [locations, setLocations] = useState([]);
  const [indexTooltip, indexTooltipChange] = useState(null);
  const [graphCtx, graphCtxChange] = useState(null)
  const [backColorCoords, backColorCoordsChange] = useState(null)

  let coordsGetted = false;

  useEffect(() => {
    console.log(`UPDATE GRAPH DATA - ${metricName}`)
    setTimeout(() => { coordsGetted = false;  getCoords() }, 500)
  }, [mainEventHistoryData, compareEventHistoryData])

  useEffect(() => {
    if (updateSyncedIndicatorPoints) {
      updateSyncedIndicatorPoints(locations)
    }
  }, [locations])

  const getChartOptions = ({ maxValue }) => {
    return {

      spanGaps: true,
      legend: {
        display: false
      },
      hover: {
        mode: 'index',
        intersect: false,
        animationDuration: 0,
      },
      animation: {
        duration: 10
      },
      responsiveAnimationDuration: 0,
      tooltips: {
        //position: "nearest",
        mode: 'index',
        intersect: false,
        enabled: false,

        itemSort: (a, b) => {
          return b.yLabel - a.yLabel;
        },
        custom: function (tooltip) {
          // Tooltip Element
          if (syncedGraphs) {
            syncedGraphs.forEach(sG => {
              if (sG.gr) {
                showLabel(sG.gr.current, tooltip, sG.name, sG.formatter)
              }
            })
          } else {
            showLabel(this._chart, tooltip, metricName)
          }
        },
        callbacks: {
          title: tooltipItem => moment(chartData.datasets[tooltipItem[0].datasetIndex]?.origData[tooltipItem[0].index]?.date).format("MMM D, YYYY"),
          label: function (tooltipItem) {
            return durationFormatter(tooltipItem.yLabel, 0, 2)
              .formattedNumber;
          }
        }
      },
      scales: {
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: '',
              fontColor: '#8C8C8C',
              fontSize: 10,
              padding: 5,
              fontFamily: "'Inter', sans-serif"
            },
            gridLines: {
              tickMarkLength: 0,
              drawBorder: true,
              color: "rgba(237,235,235, 0.7)",
              zeroLineColor: "rgba(237,235,235, 0.7)"
            },
            ticks: {
              //Math.floor( * 1.02),
              suggestedMax: maxValue && Math.round(maxValue / 10 ** (String(maxValue).length - 1)) * 10 ** (String(maxValue).length - 1),
              stepSize: Math.floor(Math.round(maxValue / 10 ** (String(maxValue).length - 1)) * 10 ** (String(maxValue).length - 1) / 5),
              suggestedMin: 0,
              padding: 10,
              fontFamily: "Roboto Mono", //"'Inter', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
              fontSize: 10,
              fontColor: "#c6c6c6",
              beginAtZero: true,

              callback: function (value, index, values) {
                return isMetrics && mainEventHistoryData.length === 0 ? '           ' :
                  value === 0 ? alignLengthLabel(0) : alignLengthLabel(formatter ? formatter(value, 1).formattedNumberString : durationFormatter(value, 0).formattedNumberString)
              }
            }
          }
        ],
        xAxes: [
          {
            offset: centeredPoints,
            gridLines: {
              color: "rgba(237,235,235, 0.7)",
              zeroLineColor: "rgba(237,235,235, 0.7)",
              display: showVerticalGridLines,
            },
            scaleLabel: {
              display: true,
              labelString: '',
              fontColor: '#8C8C8C',
              fontSize: 10,
              padding: 36,
              fontFamily: "'Inter', sans-serif"
            },
            ticks: {
              padding: 10,
              beginAtZero: true,
              autoSkip: locations.length > 50,
              maxTicksLimit: 25,
              display: showDates,
              fontFamily:
                "Roboto Mono",
              fontSize: 10,
              autoSkipPadding: 10,
              maxRotation: 45,
              minRotation: 45,
              fontColor: "#c6c6c6",
              callback: function (value) {
                return moment(value).format("DD/MM");
              }
            }
          }
        ]
      },
    };
  };

  const lengthLabel = 14

  const alignLengthLabel = (str) => ' '.repeat(lengthLabel - String(str).length).concat(str)

  const showLabel = (chart, tooltip, metricName, formatter) => {
    var tooltipEl = document.getElementById(`chartjs-tooltip-history-graph-${metricName}`);
    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.classList.add('chartjs-tooltip-history-graph')
      tooltipEl.id = `chartjs-tooltip-history-graph-${metricName}`;
      tooltipEl.innerHTML = "<table></table>";
      if (!chart || !chart.canvas.parentNode) {
        return
      }
      chart.canvas.parentNode.appendChild(tooltipEl);
    }
    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      indexTooltipChange(null);
      return;
    }
    let dataPoint1 = Object.values(chart.data.datasets[0]._meta)[0].data[tooltip.dataPoints[0].index],
      dataPoint2 = Object.values(chart.data.datasets[1]._meta)[0].data[tooltip.dataPoints[0].index]

    // Set caret Position
    tooltipEl.classList.remove("above", "below", "no-transform");
    if (tooltip.yAlign) {
      tooltipEl.classList.add(tooltip.yAlign);
    } else {
      tooltipEl.classList.add("no-transform");
    }
    function getBody(bodyItem) {
      return bodyItem.lines;
    }
    // Set Text
    if (!formatter) {
      if (tooltip.body) {
        var titleLines = tooltip.title || [];
        var bodyLines = tooltip.body.map(getBody);
        var innerHtml = "<thead>";
        titleLines.forEach(function (title) {
          innerHtml += "<tr><th>" + title + "</th></tr>";
        });
        innerHtml += "</thead><tbody>";
        bodyLines.forEach(function (body, i) {
          var colors = tooltip.labelColors[i];
          var style = "background:" + colors.backgroundColor;
          style += "; border-color:" + colors.borderColor;
          style += "; border-width: 2px";
          var span =
            '<span class="chartjs-tooltip-key" style="' +
            style +
            '"></span>';
          let verVal = locations.filter(loc => loc.index === tooltip.dataPoints[0].index).filter(p => p.y).sort((p1, p2) => p1.y > p2.y ? 1 : -1)[i]

          let ver = `<span class="tooltip-time">s</span><span class="tooltip-ver"> 
            (VER. ${verVal && verVal.release_id ? verVal.release_id : ''})
            ${verVal && verVal.newV ? '<span class="new-ver-tag">NEW</span>' : ''}
          </span>`
          innerHtml += "<tr><td>" + span + body + (verVal && verVal.release_id ? ver : '<span class="tooltip-time">s</span>') + "</td></tr>";
        });
        innerHtml += "</tbody>";
        var tableRoot = tooltipEl.querySelector("table");
        tableRoot.innerHTML = innerHtml;
      }
    } else {
      tooltipEl.innerHTML = formatter(chart.data.datasets[0].data[tooltip.dataPoints[0].index], 1)?.formattedNumberString
    }

    var positionY = chart.canvas.offsetTop;
    var positionX = chart.canvas.offsetLeft;
    // Display, position, and set styles for font
    //debugger
    let offsetY = dataPoint1._model.y && dataPoint2._model.y ? (dataPoint1._model.y + dataPoint2._model.y) / 2 :
      dataPoint1._model.y ? dataPoint1._model.y : dataPoint2._model.y
    //let offsetY = tooltip.dataPoints.reduce((acc, el) => acc + el.y, 0) / tooltip.dataPoints.length;

    indexTooltipChange(tooltip.dataPoints[0].index);


    if (chart.data.datasets[0].data[tooltip.dataPoints[0].index] || chart.data.datasets[1].data[tooltip.dataPoints[0].index]) {
      tooltipEl.style.display = 'block'
      tooltipEl.style.opacity = 1;
      tooltipEl.style.left = positionX + tooltip.caretX + "px";
      tooltipEl.style.top = positionY + offsetY + "px";
      tooltipEl.style.fontFamily = tooltip._bodyFontFamily;
      tooltipEl.style.fontSize = tooltip.bodyFontSize + "px";
      tooltipEl.style.fontStyle = tooltip._bodyFontStyle;
      tooltipEl.style.padding =
        tooltip.yPadding + "px " + tooltip.xPadding + "px";
    } else {
      tooltipEl.style.display = 'none'
    }


  }

  const paintBackgroudColor = ctx => {
    backColorCoordsChange({
      top: `${ctx.chartArea.top}px`,
      left: `${ctx.chartArea.left}px`,
      width: `${ctx.chartArea.right - ctx.chartArea.left}px`,
      height: `${ctx.chartArea.bottom - ctx.chartArea.top}px`,
    })


  }

  const getCoords = ctx => {

    ctx = ctx || graphCtx
    if (!ctx || locations.length > 0 || mainEventHistoryData.length === 0) {
      return
    }
    if (backgroudColor) {
      paintBackgroudColor(ctx)
    }


    const datasetA = ctx.controller.data.datasets[0];
    if (coordsGetted || datasetA.origData.length === 0) { return }

    const points = ctx.controller.data.datasets.reduce((acc, dataset) => {

      const meta = dataset._meta[Object.keys(dataset._meta)[0]];


      if (!dataset.origData.filter(d => d && d.release_id)[0]) {
        return acc
      }

      let listVersions = [dataset.origData.filter(d => d && d.release_id)[0].release_id.split('-')[0]]

      if (meta) {

        const positions = meta.data.reduce((acc, pos, i) => {
          const trimmedReleaseId = dataset.origData[i]
            && dataset.origData[i].release_id
            && dataset.origData[i].release_id.split('-')[0]
          let newVersion = trimmedReleaseId && listVersions.indexOf(trimmedReleaseId) === -1
          if (newVersion) {
            listVersions.push(trimmedReleaseId)
          }
          const coors = {
            x: pos._model.x,
            y: pos._model.y,
            index: i,
            newV: newVersion,
            dataset: dataset,
            release_id: trimmedReleaseId ? trimmedReleaseId : ''
          }
          return [...acc, coors]
        }, [])
        return [...acc, ...positions]
      } else {
        return acc
      }
    }, [])

    if (points && points[0] && points.some(p => p.y)) {
      coordsGetted = true;
      setLocations(points);
    }
  }

  const getChartBasicData = (numberOfDatasets = 1) => {
    const returnObj = { labels: [], datasets: [] };
    for (let i = 0; i < numberOfDatasets; i++) {
      returnObj.datasets.push({ ...cloneDeep(basicDataset) });
    }
    return returnObj;
  };

  const getChartData = (canvas) => {
    const ctx = canvas.getContext("2d")
    const mainColorGradientFill = ctx.createLinearGradient(100, 0, 100, height ? height : 300);

    mainColorGradientFill.addColorStop(0, mainGradientColor ? mainGradientColor : "rgba(39, 99, 255, 0.305)");
    mainColorGradientFill.addColorStop(1, "rgba(255,255,255,0)");

    const compareColorGradientFill = ctx.createLinearGradient(0, 150, 0, 400);
    compareColorGradientFill.addColorStop(0, "rgba(255, 39, 117, 0.305)")
    compareColorGradientFill.addColorStop(1, "rgba(255,255,255,0)")

    chartData.datasets[0].backgroundColor = mainColorGradientFill;
    if (compareEventHistoryData) {
      chartData.datasets[1].backgroundColor = compareColorGradientFill;
    }

    return chartData
  }

  let chartDataMaxValue = 0;

  let chartData;
  if (compareEventHistoryData) {
    chartData = getChartBasicData(2);
    chartData.datasets[1].label = "b";
    chartData.datasets[1].borderColor = compareColor;
    chartData.datasets[1].pointBackgroundColor = compareColor;
  } else {
    chartData = getChartBasicData();
  }
  chartData.datasets[0].label = "a";

  // build the lables from the bigger array, maybe one of the two returned arrays has less data than the other
  chartData.labels = [
    ...new Set(
      [...mainEventHistoryData || [], ...(compareEventHistoryData || [])]
        .filter(f => f)
        .sort((a, b) => (a.date < b.date ? -1 : 1))
        .map(dataPoint => dataPoint.date)
    )
  ];

  if (!chartData.datasets[0].origData) {
    chartData.datasets[0].origData = []
  }

  if (compareEventHistoryData && !chartData.datasets[1].origData) {
    chartData.datasets[1].origData = []
  }

  // Chart js is matching a label to a data point in a data set
  // meaning, if you have 20 labels you need to have 20 data points in each dataset you render
  // eg. labels [a,b,c,d,e,f,g,h] | datapoints [1,2,3,4,5,6,7,8] => [a:1,b:2,c:3,d:4,e:5,f:6,g:7,h:8]
  // in the next lines we can't be sure that the same size of datapoint will be in each dataset
  // so we need to find them by their dates and fill the gaps (if there are) with null values

  chartData.labels.forEach(date => {
    if (compareEventHistoryData) {
      const dataPoint =
        compareEventHistoryData.find(
          dataPoint =>
            dataPoint.date === date &&
            dataPoint.subeventName === selectedSubevent
        ) || null;
      if (dataPoint && dataPoint.value > chartDataMaxValue) {
        chartDataMaxValue = dataPoint.value;
      }
      chartData.datasets[1].origData.push(dataPoint || null)
      chartData.datasets[1].data.push((dataPoint && dataPoint.value) || null);
    }
    const dataPoint =
      mainEventHistoryData.find(
        dataPoint =>
          dataPoint.date === date && dataPoint.subeventName === selectedSubevent
      ) || null;
    if (dataPoint && dataPoint.value > chartDataMaxValue) {
      chartDataMaxValue = dataPoint.value;
    }
    chartData.datasets[0].origData.push(dataPoint || null)

    chartData.datasets[0].data.push((dataPoint && dataPoint.value) || null);
  });

  // calculate average Y for correct filling dataset
  if (compareEventHistoryData) {
    const averDataSet0 = chartData.datasets[0].data.reduce((acc, el) => el ? acc + el : acc, 0) / chartData.datasets[0].data.filter(d => d).length;
    const averDataSet1 = chartData.datasets[1].data.reduce((acc, el) => el ? acc + el : acc, 0) / chartData.datasets[1].data.filter(d => d).length;

    if (averDataSet0 === 0) {
      chartData.datasets[1].fill = 'origin';
    } else if (averDataSet1 === 0) {
      chartData.datasets[0].fill = 'origin';
    } else if (averDataSet0 > averDataSet1) {
      // chartData.datasets[0].fill = '+1'
      chartData.datasets[1].fill = 'origin';
    } else {
      // chartData.datasets[1].fill = '-1'
      chartData.datasets[0].fill = 'origin';
    }
  }


  let availableSubevents = [
    ...mainEventHistoryData.map(dataPoint => ({
      value: dataPoint.subeventName,
      title: dataPoint.subeventTitle || dataPoint.subeventName
    }))
  ];
  if (compareEventHistoryData) {
    availableSubevents.push(
      ...compareEventHistoryData.map(dataPoint => ({
        value: dataPoint.subeventName,
        title: dataPoint.subeventTitle || dataPoint.subeventName
      }))
    );
  }

  availableSubevents = [...new Set(availableSubevents)];

  if (
    availableSubevents.findIndex(subevent => subevent.value === "end_time") > -1
  ) {
    const currentEndTimeElementPosition = availableSubevents.findIndex(
      subevent => subevent.value === "end_time"
    );
    const endTimeElement = cloneDeep(
      availableSubevents.find(subevent => subevent.value === "end_time")
    );
    availableSubevents.splice(currentEndTimeElementPosition, 1);
    availableSubevents.unshift(endTimeElement);
  }
  availableSubevents = chain(availableSubevents)
    .filter(subevent => subevent.value)
    .uniqBy(el => el.value)
    .value();

  return (
    <div className="performance-history-graphs">
      {!hideTitles && <div>
        <h3 className="title">App Performance Timeline</h3>
        {!isProductPage && <div className="extra-text">
          View the historical performance by sub-event
        </div>}
        <div className="extra-text-2">
          Hover over the date for the precise measurement
        </div>
      </div>}


      <div className="performance-history-graphs__graph-wrapper">
        <div className="performance-history-graphs__header">
          {isProductPage ?
            <div className="performance-history-graphs__header__show">
              Show:{" "}
              <WFSelect
                options={eventTypes.map(e => ({ title: e.title, value: e.name }))}
                selectionHandler={selectedValue => {
                  setSelectedSubevent("end_time")
                  handleChangeEventGraph(selectedValue)
                  setLocations([])
                }
                }
              />
            </div>
            : availableSubevents.length > 0 && !hideTitles && (
              <div className="performance-history-graphs__header__show">
                Show:{" "}
                <WFSelect
                  defaultOptionIndex={selectedSubEvent &&
                    availableSubevents.findIndex(subEvent => subEvent.value === selectedSubEvent) !== -1 ? availableSubevents.findIndex(subEvent => subEvent.value === selectedSubEvent) : 0}
                  options={availableSubevents}
                  selectionHandler={selectedValue => {
                    setLocations([])
                    setTimeout(() => { coordsGetted = false; getCoords() }, 500)
                    handleChangeEventGraph(selectedValue)
                    setSelectedSubevent(selectedValue)
                  }
                  }
                />
              </div>
            )}
        </div>
        {!hideTitles && <div className="performance-metrics__legend-footer">
          {mainEventHistoryData && mainEventHistoryData.length > 0 &&
            <Badge
              color={mainColor}
              text={mainEventMetadata.name}
            />}
          {isCompareMode && compareEventMetadata && (
            <Badge
              color={compareColor}
              text={compareEventMetadata.name}
              style={{ marginLeft: "2rem" }}
            />
          )}
          {locations && locations.length > 0 &&
            <div className="new-ver-legend">
              <span className="new-ver-separator">|</span>
              <span className="new-ver-legend-sign"/>
              New Versions Release
            </div>
          }
        </div>}
        {leftTitle && <div className="left-title">{leftTitle}</div>}
        <div className="performance-history-graphs__line-wrapper">
          {backColorCoords && <div className="back-area-color" style={{
            ...backColorCoords,
            backgroundColor: 'rgba(60, 69, 84, 0.05)'
          }}/>}
          <Line
            width={CHART_WIDTH}
            height={CHART_HEIGHT}
            options={getChartOptions({ maxValue: chartDataMaxValue })}
            data={getChartData}
            plugins={[{
              afterInit: ctx => { graphCtxChange(ctx); },
              beforeInit: (chart) => {
                if (refLink) {
                  refLink.current = chart
                }
              },
              //afterDraw: getCoords,
              resize: () => { coordsGetted = false },
              beforeDraw: (chart) => {
                const { ctx } = chart;
                ctx.save();
                ctx.globalCompositeOperation = 'destination-over';
                ctx.fillStyle = 'rgba(0,0,0,0)';
                ctx.fillRect(0, 0, chart.width, chart.height);
                ctx.restore();
              }
            }]}
          />
          {locations.filter(loc => loc.newV && loc.y).map((location, ind) => (
            <div
              className={`graph-new-version ${indexTooltip == location.index ? 'hover' : ''}`}
              style={{
                top: location.y,
                left: location.x,
              }}
              key={ind}
            />
          )
          )}
        </div>

      </div>
    </div>
  );
};

export default PerformanceEventHistoryGraph;
