import React, { FC, ReactElement } from 'react'
import { Tooltip } from 'antd';
import { InfoCircleOutlined }  from "@ant-design/icons";

interface IChartContainer {
  title: string;
  tooltipText?: string;
  children: ReactElement | ReactElement[];
  titleCentered?: boolean;
  width?: string
}

const ChartContainer: FC<IChartContainer> = ({
  title,
  tooltipText,
  children,
  titleCentered = false,
  width = '100%',
}): ReactElement => {
  return (
    <div className="chart-container" style={{ width: width }}>
      <div className={`header ${titleCentered ? 'centered' : ''}`}>
        <h3 className="title-h3">
          {title}
          {tooltipText ? <Tooltip title={tooltipText} >
            <InfoCircleOutlined />
          </Tooltip> : null}
        </h3>
      </div>
      {children}
    </div>
  )
}
export default ChartContainer;
