import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'

import { Button, Collapse } from 'antd';
import { chain } from "lodash";

import OverviewWatch from "../intelligence/Overview/OverviewWatch";
import OverviewAnalyzedVersions from "../intelligence/Overview/OverviewAnalyzedVersions";
import UnseenCriticalInsights from "./UnSeenCriticalInsights"
import BenchmarkRanks from "./BenchmarkRanks"
import ProductsSmallList from "../products/ProductsSmallList"

import { getAllAppsVersionInfo } from "../../redux/actions/overviewActions"

import { getPersonas } from '../../redux/acts/personas';

import moment from "moment"

import Profiles from "../intelligence/Marketing/Profiles"

import {pagesTypes, PL_MARKETING} from "../../shared/consts";
import { getDiscoveriesLabels } from "../../redux/acts/labels"
import { getTrackedSince } from '../../redux/actions/productActions';

import { getFeaturedDiscoveries, getMarkedDiscoveries } from "../../redux/acts/overview"

const { Panel } = Collapse;

const PANEL_LABEL = {
  1: 'Intelligence',
  2: 'Benchmark',
  3: 'Marketing'
}

export const DashOverview = ({
  apps,
  unSeenCriticalInsights,
  perfApps,
  categories,
  inapp_campaigns,
  email_campaigns,
  campaignsFetched,
  getAllAppsVersionInfo,
  relevantInsights,
  user,
  analytics,
  marketingLastUpdate,
  intelligenceLastUpdate,
  benchmarkLastUpdate,
  userData,
  products,
  getPersonas,
  personas,
  getDiscoveriesLabels,
  tagsList,
  getFeaturedDiscoveries,
  featuredDiscoveries,
  getMarkedDiscoveries,
  markedDiscoveries
}) => {

  const [openPanel, openPanelChange] = useState([1, 2, 3])
  const ref = useRef(null);

  const watchedPerfApps = perfApps.map(app => {
    return {
      id: app.id,
      icon: app.icon,
      is_monitored: app.is_monitored,
      package_name: app.package,
      display_name: app.name,
      platform: app.platform,
      performance_events: app.performance_events
    };
  })
  watchedPerfApps.sort(app => app.is_monitored ? -1 : 1)


  const handleChangeCollapse = (data) => {
    let accNewState = data.reduce((acc, el) => acc + Number(el), 0),
      accCurrState = openPanel.reduce((acc, el) => acc + Number(el), 0)
    let diff = accNewState - accCurrState

    const txt = `${diff > 0 ? 'Expand' : 'Collapse'} Overview section`;
    analytics.analytic(txt, { Section: PANEL_LABEL[Math.abs(diff)] });
    openPanelChange(data)
  }

  useEffect(() => {
    if (!relevantInsights) {
      getAllAppsVersionInfo()
    }
    if (!tagsList) {
      getDiscoveriesLabels()
    }
    if (personas.length === 0) {
      getPersonas()
    }
    if (!featuredDiscoveries) {
      getFeaturedDiscoveries()
    }
    getMarkedDiscoveries()
  }, [])

  const customizeCollapseIcon = ({ isActive }) => {
    if (isActive) {
      return <div className="collapse-state-minus" >
        -
      </div>;
    }
    return <><div className="collapse-state-plus ant-collapse-arrow" >
      +
    </div>
    <div className="collapse-state-minus ant-collapse-arrow" style={{ display: 'none' }}>
        -
    </div>
    </>;
  }

  let criticalCampaign = {}
  let criticalCampaignApps = []

  if (campaignsFetched) {

    [...inapp_campaigns, ...email_campaigns].forEach(camp => {
      if (camp.cam.read_at === null && (camp.cam.relevancy && camp.cam.relevancy > 0)) {
        criticalCampaign[camp.app.id] = criticalCampaign[camp.app.id] || []
        criticalCampaign[camp.app.id].push({
          insight: camp.cam,
          ...camp.app,
          ...camp.cam,
        })
        if (!criticalCampaignApps.find(app => app.id == camp.app.id)) {
          criticalCampaignApps.push(camp.app)
        }
      }
    })
  }

  const availItems = userData.pages;

  let relevantInsightsByApps = {}

  let relevantUnreadInsightsByApps = {}

  if (relevantInsights) {
    relevantInsights.forEach(relIns => {
      if (!relevantInsightsByApps[relIns.metadata.id]) {
        relevantInsightsByApps[relIns.metadata.id] = []
        relevantUnreadInsightsByApps[relIns.metadata.id] = []
      }

      relevantInsightsByApps[relIns.metadata.id].push({
        ...relIns.metadata,
        ...relIns,
        id: relIns.insightId,
        insightId: relIns.insightId
      })

      if (!relIns.read_at) {
        relevantUnreadInsightsByApps[relIns.metadata.id].push({
          ...relIns.metadata,
          ...relIns,
          id: relIns.insightId,
          insightId: relIns.insightId
        })
      }
    })
  }

  let marketingApps

  if (products) {
    marketingApps = apps.filter(a => a.platform === PL_MARKETING).sort((app1, app2) => {
      let prod1 = products.find(p => p.apps.find(a => a.id == app1.id))
      let prod2 = products.find(p => p.apps.find(a => a.id == app2.id))

      if (!prod1 || !prod2) {

        return 0
      }
      let filteredPersonas1 = personas.filter(per => per.product_ids.includes(prod1.id) && per.active).reduce((acc, el) => {
        acc[el.location] = (acc[el.location] ? acc[el.location] + 1 : 1)
        return acc
      }, {})
      let filteredPersonas2 = personas.filter(per => per.product_ids.includes(prod2.id) && per.active).reduce((acc, el) => {
        acc[el.location] = (acc[el.location] ? acc[el.location] + 1 : 1)
        return acc
      }, {})

      if (Object.values(filteredPersonas1).length > Object.values(filteredPersonas2).length) {
        return -1
      } else {
        return 1
      }
    })
  }


  return (
    <div className="dash-overview" ref={ref}>

      <Collapse bordered={false} activeKey={openPanel} onChange={handleChangeCollapse}
        expandIcon={(props) => customizeCollapseIcon(props)}
      >
        {availItems.includes(pagesTypes.INTELLIGENCE) ?
          <Panel header={<h1 className="h1-title" data-testid="collapse" >
            <div className='header'>Product <div className='medium'>Intelligence</div>
              {intelligenceLastUpdate ?
                <>
                  <span className="sep-last-update">|</span>
                  <div className="last-update">
                  Last Update:
                    <span>
                      {moment(intelligenceLastUpdate).format("MMM DD, YYYY")}
                    </span>
                  </div>
                </> : ''}
            </div>
          </h1>} key="1">

            <div markscroll="0-0" label="Product Intelligence - apps we're watching" className="dash-intel-apps">

              <ProductsSmallList
                products={products ? products.filter(p => p.apps.find(a => a.has_experiments && a.platform !== PL_MARKETING)).map(p => ({...p, link: `/product_intelligence?product_id=${p.id}`, enabled: true})) : []}
                unSeenCriticalInsights={unSeenCriticalInsights}
                scrollToTop={true}
              />
            </div>
            <h3 className="title" markscroll="0-1" label="Product Intelligence - Most Meaningful Discoveries">Featured Discoveries</h3>
            <UnseenCriticalInsights apps={apps} unSeenCriticalInsights={featuredDiscoveries} grouping={false} isFeaturedList={true} key="featured"/>

            <h3 className="title" markscroll="0-2" label="Product Intelligence - Marked by me">Marked by me</h3>
            <UnseenCriticalInsights apps={apps} unSeenCriticalInsights={markedDiscoveries} grouping={false} isMarkedList={true} key="marked"/>

            <h3 className="title" markscroll="0-3" label="Product Intelligence - Release Radar">Release Radar</h3>
            <div className="release-radar">
              <OverviewAnalyzedVersions relevantInsightsByApps={relevantInsightsByApps} />
            </div>
            <div markscroll="1" label="Product Benchmark" />
          </Panel>
          :
          <Panel disabled={true} className="disabled-panel" header={<h1 className="h1-title">
            Product <strong >Intelligence</strong>
          </h1>} key="1" />
        }

        {availItems.includes(pagesTypes.BENCHMARK_COMPARE) ?
          <Panel header={<h1 className="h1-title">
            <div className="header">
              Product <div className="medium" >Benchmark</div>
            </div>

            {benchmarkLastUpdate ?
              <>
                <span className="sep-last-update">|</span>
                <div className="last-update">
                  Last Update:
                  <span>
                    {moment(benchmarkLastUpdate).format("MMM DD, YYYY")}
                  </span>
                </div>
              </> : ''}
          </h1>} key="2">

            <div markscroll="1-0" label="Product Benchmark - Most Meaningful Discoveries" className="dash-bench-apps">
              <OverviewWatch showMonitoring apps={watchedPerfApps} linkTo="compare" />
            </div>

            <div markscroll="1-1" label="Product Benchmark - Ranks">
              {categories && <BenchmarkRanks />}
            </div>
            <div markscroll="2" label="Marketing Campaigns"/>
          </Panel>
          :
          <Panel disabled={true} className="disabled-panel" header={<h1 className="h1-title">
            Product <strong>Benchmark</strong>
          </h1>} key="1" />
        }
        {availItems.includes(pagesTypes.MARKETING) ?
          <Panel header={<h1 className="h1-title">
            <div className='header'>
              Marketing <div className="medium">Campaigns</div>
              {marketingLastUpdate ?
                <>
                  <span className="sep-last-update">|</span>
                  <div className="last-update">
                  Last Update:
                    <span>
                      {moment(marketingLastUpdate).format("MMM DD, YYYY")}
                    </span>
                  </div>
                </> : ''}
            </div>

          </h1>} key="3">

            <div markscroll="2-0" label="Marketing Campaigns - apps we're watching">
              {marketingApps && marketingApps.length > 0 &&
                <OverviewWatch apps={marketingApps} unSeenCriticalInsights={unSeenCriticalInsights} marketing={true} />}
            </div>
            <div className="unseen-campaign"  >
              <h3 className="title" markscroll="2-1" label="Marketing Campaigns - Most Meaningful Discoveries">Most Meaningful Discoveries</h3>


              {marketingApps && <UnseenCriticalInsights apps={marketingApps} unSeenCriticalInsights={relevantUnreadInsightsByApps} marketing={true} />}
            </div>
            <div markscroll="2-2">
              <h3 className="title">Monitored Audiences</h3>
              <div className="profiles-apps">
                {products && marketingApps && marketingApps.map(app => {
                  let prod = products.find(p => p.apps.find(a => a.id == app.id))
                  if (!prod) { return null }
                  let filteredPersonas = personas.filter(per => per.product_ids.includes(prod.id) && per.active).reduce((acc, el) => {
                    acc[el.location] = (acc[el.location] ? acc[el.location] + 1 : 1)
                    return acc
                  }, {})

                  if (Object.values(filteredPersonas).length === 0) { return null }

                  return <div className="app-data" key={app.id}>
                    <img src={app.icon} /> <h3>{app.display_name}</h3>
                    <Profiles appID={app.id} userQty={filteredPersonas} />
                  </div>

                })}

              </div>

            </div>
          </Panel>
          :
          <Panel disabled={true} className="disabled-panel" header={
            <h1 className="h1-title">
              <div className='header'>
                Marketing <div className="medium">Campaigns</div>
              </div>
            </h1>} key="1" />
        }
      </Collapse>

    </div>
  )
}

const getLastReleaseDateFromReleases = (versions) => {
  if (versions && versions.length > 0) {
    let lastRelease = [...versions].sort((a, b) => moment(a.release_date) > moment(b.release_date) ? -1 : 1)[0]
    return lastRelease.release_date
  }
  return ''
}

const mapStateToProps = ({ appData, overview, performance, userData, newOverviewReducer, personas, labels, userDiscoveries }) => ({
  apps: chain(appData.apps)
    .map(app => {
      const insightsCount = overview.relevantInsights
        ? overview.relevantInsights.filter(
          el => el.app_id === app.metadata.id
        ).length
        : 0;
      return {
        id: app.metadata.id,
        icon: app.metadata.icon,
        insightsCount,
        package_name: app.metadata.package_name,
        display_name: app.metadata.display_name,
        platform: app.metadata.platform
      };
    })
    .value(),
  user: appData.user,
  relevantInsights: overview.relevantInsights ? overview.relevantInsights.map(el => ({
    ...el.insight,
    ...el.metadata,
    metadata: el.metadata,
    insightId: el.insight.id
  })) : null,
  unSeenCriticalInsights: overview.unSeenCriticalInsights,
  perfApps: performance.apps || [],
  categories: performance.categories,
  inapp_campaigns: overview.inapp_campaigns,
  email_campaigns: overview.email_campaigns,
  campaignsFetched: overview.campaignsFetched,
  analytics: appData.user.analytics,
  marketingLastUpdate: overview.marketingLastUpdate,
  benchmarkLastUpdate: performance.latestUpdate,
  userData: userData,
  intelligenceLastUpdate: getLastReleaseDateFromReleases(newOverviewReducer.completedVersionsWithDiscoveries),
  completedVersionsWithDiscoveries: newOverviewReducer.completedVersionsWithDiscoveries,
  currentlyBeingAnalyzedVersions: newOverviewReducer.currentlyBeingAnalyzedVersions,
  featuredDiscoveries: newOverviewReducer.featuredDiscoveries,
  markedDiscoveries: newOverviewReducer.markedDiscoveries,
  products: overview.products,
  personas: personas,
  productTrackedSince: overview.productTrackedSince,
  tagsList: labels.tagsList,
})

const mapDispatchToProps = {
  getAllAppsVersionInfo,
  getPersonas,
  getTrackedSince,
  getDiscoveriesLabels,
  getFeaturedDiscoveries,
  getMarkedDiscoveries
}

export default connect(mapStateToProps, mapDispatchToProps)(DashOverview)
