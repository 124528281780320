import React, { useState, useEffect, useRef, FC, ReactElement } from 'react';
import { connect } from 'react-redux';

import { getMarketingCampaigns } from "../../../redux/actions/dashboardActions";

import FullCalendarDiscoveries from "./FullCalendarDiscoveries"
import { IDisWithApp, App, Ipersona, Product, IState } from '../../../interfaces/Product'
import ChartContainer from "../../../components/chartContainer/ChartContainer"
import CSelectBox from "../../../components/UICarbonStyleControls/SelectBox"

import EmailVolumeChart from "./emailVolume/EmailVolumeChart";

import MassMarketContent from "./massmarketContent/MassMarketContent";
import { Tabs } from 'antd';
import PopularDaysChart from "./popularDays/PopularDaysChart";
import moment, { Moment } from "moment";

// import PersonalizedCampaignChart from "./pesonalisedCampainChart/PersonalizedCampaignChart";
import CompetitorsStatus from "./competitorsStatus/CompetitorsStatus";
import PersonaState from "./personaState/PersonaState"
import UserSegmentChart from "./userSegment/UserSegment"

import { getPersonas } from '../../../redux/acts/personas';
import { getProducts } from "../../../redux/actions/overviewActions";
import { getSortedProducts } from "../../products/ProductsSelector";
import { personasSelector } from "./PersonaSelector"
import analyticsService from "../../../services/analyticsService";

const { TabPane } = Tabs;

type MassMarketProps = {
  getMarketingCampaigns: () => void,
  inapp_campaigns_all: IDisWithApp[],
  email_campaigns_all: IDisWithApp[],
  marketingApps: App[],
  getPersonas: () => void,
  personas: Ipersona[],
  products: Product[],
  getProducts: () => void,
  analytics: analyticsService,
  history: any,
  discoveriesData: any[],
}

const MassMarket: FC<MassMarketProps> = ({
  getMarketingCampaigns,
  email_campaigns_all,
  marketingApps = [],
  getPersonas,
  personas,
  products,
  getProducts,
  analytics,
  history,
  discoveriesData,
}): ReactElement | null => {

  const url = new URLSearchParams(history.location.search)
  const [currentDate, currentDateChange] = useState(null)


  const [massMarketDiscoveries, massMarketDiscoveriesChange] = useState<IDisWithApp[]>([])

  const [isSticky, setSticky] = useState(false);
  const [months, monthsChange] = useState(url.get('time_frame') ? Number(url.get('time_frame')) : 3);

  const ref = useRef(null);

  const getDefDate = () => {
    //calendar_month=6&calendar_year=2020&time_frame=3
    let monthFromURL = url.get('calendar_month')
    let yearFromURL = url.get('calendar_year')
    let today = moment()
    if (monthFromURL && yearFromURL) {
      return moment().month(monthFromURL ? Number(monthFromURL) - 1 : today.month()).year(Number(yearFromURL) || today.year())
    }
    return massMarketDiscoveries.find(d => moment(d.cam.start_time).isSame(moment(), 'year') && moment(d.cam.start_time).isSame(moment(), 'month'))
      ? moment()
      : moment().subtract(1, 'month')
  }

  const currentDateChangeHandler = (date: Moment) => {
    //@ts-ignore
    currentDateChange(date)
    updateURL()
  }

  const updateURL = () => {
    if (currentDate) {
      //@ts-ignore
      history.replace(`/marketing/mass_market?calendar_month=${currentDate.month() + 1}&calendar_year=${currentDate.year()}&time_frame=${months}`);
    }
  }

  useEffect(updateURL, [months])

  useEffect(() => {
    if (!email_campaigns_all) {
      getMarketingCampaigns()
    }
    if (!personas || personas.length == 0) {
      getPersonas()
    }


    window.addEventListener('scroll', handleScroll);


    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, [])

  useEffect(() => {
    if (email_campaigns_all) {
      let data: Array<IDisWithApp> = email_campaigns_all.filter((dis: IDisWithApp) => ["mass_market", "mass_market_by_segment"].includes(dis.cam.category))
      massMarketDiscoveriesChange(data)
    }
  }, [email_campaigns_all])

  useEffect(() => {
    if (massMarketDiscoveries && massMarketDiscoveries.length > 0 && !currentDate) {
      //@ts-ignore
      currentDateChange(getDefDate())
    }
  }, [massMarketDiscoveries])

  useEffect(() => {
    if (!products) {
      getProducts()
    }
  })


  const handleScroll = () => {
    if (ref.current && document.querySelector('.ant-tabs-nav')) {
      //@ts-ignore 
      setSticky(document.querySelector('.ant-tabs-nav').offsetTop > 0)
    }
  };
  const startTime = moment().subtract(months, 'months');
  const relevantDiscoveries = massMarketDiscoveries.filter(((dis: IDisWithApp): boolean => {
    if (!["mass_market", "mass_market_by_segment"].includes(dis.cam.category)) {
      return false;
    }
    const dis_moment = moment(dis.cam.start_time);
    return dis_moment.diff(startTime, 'days') > 0;
  }))
  if (!marketingApps) {
    return null
  }
  analytics.analytic('Mass Market Page', { 'numOfRelevantDiscoveries': relevantDiscoveries.length })

  let k = 4, l = 0
  //@ts-ignore
  const competitorsAppIDs = Array.from(new Set(relevantDiscoveries.map(d => d.app.id))).reduce((acc, el) => {
    if (!acc[l]) {
      //@ts-ignore
      acc[l] = []
    }
    //@ts-ignore
    if (acc[l].length < k) {
      //@ts-ignore
      acc[l].push(el)
    } else {
      l = l + 1
    }
    return acc
  }, []);

  return (
    <div className="new-marketing-page">
      <h1 className="h1-title">
        <strong>Marketing</strong> Overview
      </h1>
      <div className={"hr-row"} />
      <h2 className={"h2-title"}>Monitored Competitors</h2>
      <div className={'which-products-were'}><div className={'bold'}>Products we’re currently monitoring</div> </div>

      <CompetitorsStatus discoveries={massMarketDiscoveries} marketingApps={marketingApps} personas={personas} products={products} />
      {currentDate && massMarketDiscoveries && massMarketDiscoveries.length > 0 &&
        //@ts-ignore
        <FullCalendarDiscoveries 
          discoveries={massMarketDiscoveries}
          currentDate={currentDate}
          currentDateChange={currentDateChangeHandler}
          products={products}
          discoveriesData={discoveriesData}
        />
      }

      <div className="wrapper-tabs">


        <Tabs animated={{ inkBar: true, tabPane: false }} className={`tabs ${isSticky ? 'sticky' : ''}`} defaultActiveKey="1"
          tabBarExtraContent={<div className={`timeframe-selectbox`} ref={ref}>
            <div className="label">
              Time Frame
            </div>
            <CSelectBox
              value={months + ' MONTHS'}
              onChange={(value: string): void => {
                monthsChange(parseInt(value))
              }}
            >{
                [3, 6, 12].map((val: number, i: number): ReactElement => <CSelectBox.Option value={val} key={i}>{val + ' MONTHS'}</CSelectBox.Option>)
              }
            </CSelectBox>
          </div>}>

          <TabPane tab={`Volume`} key="1">
            <h3 className={'title'}>Volume</h3>

            <div className={'extra-text'}>Email discoveries are analyzed to understand your competitors’ strategy regarding how much Mass Market emails are being sent and when they send it</div>
            <ChartContainer title="Mass Market Email Volume">
              <div style={{ minHeight: '25rem', padding: '1rem' }}>
                <EmailVolumeChart discoveries={relevantDiscoveries} timeFrame={months} />


              </div>
            </ChartContainer>

            <div className={"gap"} />
            <div className="popular-days-wrapper">
              <ChartContainer title="Popular days">
                <div style={{ minHeight: '25rem', padding: '1rem' }}>

                  {
                    //@ts-ignore
                    relevantDiscoveries && competitorsAppIDs && competitorsAppIDs.map(arr => {
                      //@ts-ignore
                      return <PopularDaysChart discoveries={relevantDiscoveries.filter(d => arr.find(id => id === d.app.id))} />
                    }
                    )}

                </div>
              </ChartContainer>
            </div>



          </TabPane>


          <TabPane tab="Content" key="2" >
            <h3 className={'title'}>Content</h3>
            <div className={'extra-text'}>Deep-dive into what is actually promoted in your competitors’ emails</div>

            <MassMarketContent discoveries={relevantDiscoveries} />

            <div className={"gap"} />
            {/* <div className={'mass-market-email-vo'}>Content Personalisation</div>
            <div className={'the-number-of-mass-m'}>The number of mass-market emails containing personalized content received in the specified time range from each competitor</div>
            <div className="personalized-email-wrapper">
              <PersonalizedCampaignChart discoveries={relevantDiscoveries} />
            </div> */}
          </TabPane>

          <TabPane tab="Personas" key="3" >
            <h3 className={'title'}>Persona State</h3>
            <div className={'extra-text'}>The number of mass-market emails received by Active and Inactive users in the specified time range by each competitor</div>

            <div className="persona-state-wrapper">
              <PersonaState personas={personas} products={products} />
            </div>
            <div className={"gap"} />

            <div className={'mass-market-email-vo'}>User Segmentation</div>
            <div className={'the-number-of-mass-m'}>The number of mass-market emails received by different user segments in the specified time range from each competitor</div>
            <div className="segment-chart-wrapper">
              {relevantDiscoveries && relevantDiscoveries.length > 0 &&
                <UserSegmentChart discoveries={relevantDiscoveries} timeFrame={months} />
              }
            </div>
          </TabPane>

        </Tabs>

      </div>


    </div>
  )
}

export default connect((state: IState) => {
  return {
    user: state.appData.user,
    email_campaigns_all: state.overview.email_campaigns,
    campaignsFetched: state.overview.campaignsFetched,
    marketingApps: state.overview.marketingApps,
    analytics: state.appData.user.analytics,
    personas: personasSelector(state),
    products: getSortedProducts(state),
    discoveriesData: state.userDiscoveries.discoveries,
  }
}, {
  getMarketingCampaigns,
  getPersonas,
  getProducts,
})(MassMarket)

