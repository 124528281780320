import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { getGraphByID } from "../../redux/acts/graphs"

import { useIsFeatureByUserID } from "../../helpers/features"
import GraphView from "./GraphView"
import { getAppData } from "../../redux/actions/dashboardActions";


export const GraphPage = ({
  match,
  location,
  history,

  apps,
  getGraphByID,
  graphs,
  user,
  allInsights,
  getAppData,
}) => {

  const appGraphAccess = useIsFeatureByUserID('app_graph', user.email)
  const graph = graphs[match.params.graph_id]


  const graphNetwork = useRef(null);
  window.__graphNetwork = graphNetwork

  useEffect(() => {
    getGraphByID(match.params.graph_id)
  }, []);

  useEffect(() => {
    if (graph && graph.app_id && allInsights.length===0) {
      getAppData({app_id: graph.app_id, load_only: true});
    }

  },[graph]);

  if (!graphs[match.params.graph_id] || !appGraphAccess) { return null }


  const app = apps.find(a => a.metadata.id === graph.app_id)

  return (
    <GraphView
      graph={graph}
      app={app}
      user={user}
      graphID={graph.nodes.find(n => n.labels[0] === 'root_state').id}
      allInsights={allInsights}
      goBackHandle={() => {
        history.push(location.pathname.substr(0, location.pathname.lastIndexOf("/")));
      }}
    />
  )
}

const mapStateToProps = ({ graphs, appData }) => ({
  graphs: graphs.graphs,
  apps: appData.apps,
  user: appData.user,
  allInsights: appData.allInsights,
})

const mapDispatchToProps = { getGraphByID,  getAppData}

export default connect(mapStateToProps, mapDispatchToProps)(GraphPage)