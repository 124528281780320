import {
  takeLatest,
  call,
  put,
  fork, takeEvery,
} from "redux-saga/effects";
import * as subscriptionsApi from "../api/subscriptionsApi";
import {
  CREATE_SUBSCRIPTION,
  GET_SUBSCRIPTIONS,
  EDIT_SUBSCRIPTION,
  DELETE_SUBSCRIPTION,
  PUSH_SUBSCRIPTION_UPDATE,

  getSubscriptionsSuccess,
  createSubscriptionSuccess,
  editSubscriptionSuccess,
  deleteSubscriptionSuccess,
  createSubscriptionFailed,
  editSubscriptionFailed,
  deleteSubscriptionFailed
} from '../acts/subscriptions'

//--------------------


//handlers
function* getSubscriptionsSaga() {
  // @ts-ignore
  const result = yield call(subscriptionsApi.getSubscriptionsApi)
  yield put(getSubscriptionsSuccess(result.data))
}

function* createSubscriptionSaga(action: object) {
  try {
    // @ts-ignore
    const result = yield call(subscriptionsApi.createSubscriptionApi, action.payload)
    yield put(createSubscriptionSuccess(result.data))
  } catch {
    yield put(createSubscriptionFailed())
  }

}

function* editSubscriptionSaga(action: object) {
  try {
    // @ts-ignore
    const result = yield call(subscriptionsApi.editSubscriptionApi, action.payload)
    yield put(editSubscriptionSuccess(result.data))
  } catch {
    yield put(editSubscriptionFailed())
  }

}

function* deleteSubscriptionSaga(action: any) {
  try {
    // @ts-ignore
    yield call(subscriptionsApi.deleteSubscriptionsApi, action.payload)
    yield put(deleteSubscriptionSuccess(action.payload))
  } catch (e) {
    yield put(deleteSubscriptionFailed())
  }
}
function* pushSubscriptionUpdateSaga(action: any) {
  try {
    // @ts-ignore
    yield call(subscriptionsApi.pushSubscriptionUpdatAPI, action.payload)
  } catch (e) {}
}
//--------------------

//watchers

function* watchGetSubscriptions() {
  yield takeEvery(GET_SUBSCRIPTIONS, getSubscriptionsSaga);
}

function* watchCreateSubscription() {
  yield takeEvery(CREATE_SUBSCRIPTION, createSubscriptionSaga);
}
function* watchEditSubscription() {
  yield takeEvery(EDIT_SUBSCRIPTION, editSubscriptionSaga);
}
function* watchDeleteSubscription() {
  yield takeEvery(DELETE_SUBSCRIPTION, deleteSubscriptionSaga);
}
function* watchPushSubscriptionUpdate() {
  yield takeEvery(PUSH_SUBSCRIPTION_UPDATE, pushSubscriptionUpdateSaga);
}
//--------------------

const subscriptionsSagas = [
  fork(watchCreateSubscription),
  fork(watchGetSubscriptions),
  fork(watchEditSubscription),
  fork(watchDeleteSubscription),
  fork(watchPushSubscriptionUpdate)
];

export default subscriptionsSagas;

