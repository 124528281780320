const l = (...a) => {}; // off console.log('config',...a);
l('process.env=',process.env);
const rt = {
  cognito: {
    USER_POOL_ID: "us-east-1_otI62zHWG",
    APP_CLIENT_ID: "1o8hfattv5d7pv8st57e1e3lum",
    IDENTITY_POOL_ID: "us-east-1:16af8c6a-afaa-4433-b678-050efe567bac"
  },
  awsRegion: "us-east-1",
  analyticUrl:
    (typeof process.env.REACT_APP_ANALYTIC_URL!=='undefined'?process.env.REACT_APP_ANALYTIC_URL:process.env.REACT_APP_ANALYTIC_URL || "https://p2xqsglwm0.execute-api.us-east-1.amazonaws.com/dev/analytic?email="),
  demoEmail: "Anonymous",
  mixpanelKey: (typeof process.env.REACT_APP_MIXPANEL_KEY!=='undefined'?process.env.REACT_APP_MIXPANEL_KEY:"c58380a774033fd7127c4ec5dc9fb43a"),
  directLinkPattern: "Watchful link @",
  dataApi: {
    backendUrl: process.env.REACT_APP_API_HOST,
    jsonsCdn: "https://jsons-prod.watchful.ai",
    jsonsStagingCdn: "https://jsons-staging.watchful.ai",
    bucket: "watchful-dashboard-public-user-jsons-prod",
    admins: "app.watchful.ai",
    service: ".s3.amazonaws.com/",
    linkCreate: "user/add_link",
    linkData: "user/get_insight",
    abTestsData:
	"https://watchful-dashboard-public-user-jsons-prod.s3.amazonaws.com/ab_tests"
  }
}
l('config=',rt);
export default rt;
