import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getMarketingCampaigns } from "../../../redux/actions/dashboardActions";
import { setInsightAsViewed, getDiscoveryData } from "../../../redux/actions/dashboardActions"

import DiscoveryView from "../../../components/discoveryView/DiscoveryView"
import { siteVersions } from "../../../shared/consts";

import { setNotificationForInsightRead } from "../../../redux/acts/notifications"
import { marketingOnlyType } from "../../../shared/consts"
import { postUserDiscoveryComment, editUserDiscoveryComment, deleteUserDiscoveryComment } from "../../../redux/acts/userDiscoveries"

export const MarketingInsightView = (props) => {

  const { user, analytics, campaigns, history,
    postUserDiscoveryComment, editUserDiscoveryComment, deleteUserDiscoveryComment,
    guest, insightLinkCode, userData, products, allInsights
  } = props
  const insightID = Number(props.match.params.insightID);
  const [insight, insightChange] = useState(null)
  const [currentList, currentListChange] = useState(null)
  const [currentIndexInList, currentIndexInListChange] = useState(null)

  const product = insight && products && products.find(p => p.apps.find(a => a.id === insight.metadata.id))

  const getRelatedDiscovery = (id) => {
    if (!insight.insight.related_insights) { return null }
    let ins = insight.insight.related_insights.find(ins => ins.id === id)
    if (!ins) { return null }
    return {
      discovery: ins,
      app: props.apps.find(app => app.metadata.id === ins.app_id).metadata
    }
  }

  useEffect(() => {

    const ins = allInsights.find(ins => ins.insight.id === insightID)
    if (!ins) {
      props.getDiscoveryData({ insightID: insightID })
    }
    insightChange(ins)
  }, [allInsights, props.match.params.insightID])

  useEffect(() => {
    if (insight){
      currentListChange(allInsights.filter(ins => ins.metadata.id === insight.metadata.id))
    }

  }, [insight])

  useEffect(() => {
    if (props.siteVersion === siteVersions.DEMO) { return }
    props.setInsightAsViewed(insightID)
    props.setNotificationForInsightRead(insightID)
  }, [])

  useEffect(() => {
    if (currentList) {
      currentIndexInListChange(currentList.findIndex(ins => ins.insight.id === insightID))
    }
  }, [currentList])

  if (!insight || !currentList || currentIndexInList === null) {
    return null
  }

  const app = insight.metadata
  const ins = insight.insight
  let htmls = ins.assets ? ins.assets.filter(asset => asset.asset_type === 'html') : null

  const relatedInsights = ins.related_insight_ids && ins.related_insight_ids ? ins.related_insight_ids.map(rel_id => getRelatedDiscovery(rel_id)) : []

  return (
    <div className="marketing-discovery-wrap">
      <DiscoveryView
        user={user}
        analytics={analytics}
        discovery={ins}
        app={insight}
        product={product}
        siteVersion={props.siteVersion}
        demoUser={props.demoUser}
        demoMode={props.siteVersion === siteVersions.DEMO}
        isAdmin={false}
        location={props.location}
        relatedInsights={props.siteVersion === siteVersions.DEMO ? [] : relatedInsights}
        htmls={htmls}
        marketingPrevNextList={{
          list: currentList,
          index: currentIndexInList,
        }}
        clickBack={() => {
          if (history.location.state && history.location.state.fromMarketing) {
            history.goBack()
          } else {
            let index = Object.keys(marketingOnlyType).findIndex(type => type === insight.insight.type)
            history.push(`/marketing?app=${app.id}&tab=${index + 1}`)
          }
        }}
        guest={guest}
        insightLinkCode={insightLinkCode}
        postUserDiscoveryComment={postUserDiscoveryComment}
        editUserDiscoveryComment={editUserDiscoveryComment}
        deleteUserDiscoveryComment={deleteUserDiscoveryComment}
        userData={userData}
        history={history}
        relatedCallback={(insightID) => props.getDiscoveryData({ insightID })}
      />
    </div>
  )
}

const mapStateToProps = ({ appData, overview, linkData, userData }) => ({
  apps: appData.apps,
  user: appData.user,
  demoUser: linkData.user,
  analytics: appData.user.analytics,
  allInsights: appData.allInsights,
  siteVersion: appData.siteVersion,
  guest: linkData.guest,
  userData: userData,
  insightLinkCode: linkData.link_code,
  products: overview.products,
})

export default connect(mapStateToProps, {
  getMarketingCampaigns,
  setInsightAsViewed,
  setNotificationForInsightRead,
  getDiscoveryData,
  postUserDiscoveryComment, editUserDiscoveryComment, deleteUserDiscoveryComment
})(MarketingInsightView)
