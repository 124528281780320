import React, { useState, useEffect, FC, ReactElement } from 'react';
import { IDisWithApp, IDataPoint } from '../../../../interfaces/Product';
import moment from "moment";
import './EmailVolumeChart.scss';
import WLineChart from "../../../../components/charts/LineChart/WLineChart";
import { customTooltip1, customLabel1 } from "../../../../components/charts/commonSettings"


interface EmailChartProps {
  discoveries: Array<IDisWithApp>,
  timeFrame: number,
}

const getOptions = (tooltipGroups: Array<string>, timeFrame: number): object => {
  return {
    axes: {
      left: {
        title: "Discoveries Found"
      },
      bottom: {
        ticks: {
          formatter: customLabel1(timeFrame)
        }
      }
    },
    height: '35rem',
    tooltip: {
      customHTML: customTooltip1(tooltipGroups, timeFrame)
    },

  };
};

const EmailVolumeChart: FC<EmailChartProps> = ({
  discoveries,
  timeFrame,

}): ReactElement | null => {

  const getGroupedData = (discoveries: IDisWithApp[], timeFrame: number): IDataPoint[] => {
    let groupedSet: IDataPoint[] = [];
    let allNames: string[] = [];
    let allDates: string[] = [];
    let dateFormat: string = timeFrame === 3 ? 'DD/MMM/YY' : 'MMM/YY';
    discoveries.forEach(dis => {
      let date: string;

      if (timeFrame === 3) {

        date = moment(dis.cam.start_time).startOf('week').format(dateFormat)
      } else {
        date = moment(dis.cam.start_time).format(dateFormat)
      }
      if (!groupedSet.some(item => item.group === dis.app.display_name && item.key === date)) {
        groupedSet.push({ group: dis.app.display_name, key: date, value: 1 })
      } else {

        groupedSet.forEach((item: any) => {
          if (item.group === dis.app.display_name && item.key === date) {
            item.value++;
          }
        })
      }

      // fill in the missing values
      allNames.push(dis.app.display_name)
      allDates.push(date)

    })
    for (let name of allNames) {
      for (let date of allDates) {
        if (!groupedSet.some(item => item.group === name && item.key === date)) {
          groupedSet.push({ group: name, key: date, value: 0 })
        }
      }
    }
    groupedSet = groupedSet.sort((a, b) => {
      return moment(a.key, dateFormat).diff(moment(b.key, dateFormat))
    })
    return groupedSet
  };


  let groupedData: IDataPoint[] = [];


  groupedData = getGroupedData(discoveries, timeFrame);
  const setOfGroup = Array.from(new Set(discoveries.map(d => d.app.display_name)))

  return <div className={'main-container'}>
    <WLineChart options={getOptions(setOfGroup, timeFrame)} data={groupedData} />
  </div>
}
export default EmailVolumeChart;