import { datadogRum } from '@datadog/browser-rum';

let env_check = process.env.NODE_ENV === "production" && process.env.REACT_APP_VERSION;
if (env_check){
  console.log('running datadog in production mode with version ' + process.env.REACT_APP_VERSION)
  datadogRum.init({
    applicationId: '78962397-420b-417a-8f63-20dfaba8fea1',
    clientToken: 'pub92daf8ebc563603d9da49439af44c34f',
    site: 'datadoghq.com',
    service:'app.watchful.ai',
    env:'prod',
    // Specify a version number to identify the deployed version of your application in Datadog
    version: process.env.REACT_APP_VERSION,
    sampleRate: 100,
    trackInteractions: true
  });
} else {
  console.log('NODE_ENV', process.env.NODE_ENV);
  console.log('REACT_APP_VERSION',process.env.REACT_APP_VERSION)
}

