import {
  takeEvery,
  takeLatest,
  call,
  fork,
  put
} from "redux-saga/effects";
import * as actionTypes from "../actions/_actionsTypes";
import * as dashboardAction from "../actions/dashboardActions";
import * as api from "../api/dashboardApi";
import { siteVersions } from "../../shared/consts";

import { getDiscoveriesLabelsSuccess, getIntelDiscoveriesLabelsSuccess } from "../acts/labels"

function* getAppsMetadata(action) {
  try {
    const { sub } = action.payload.user;

    yield put({
      type: actionTypes.CURRENT_REQUESTS_ADD,
      payload: 'getAppsMetadata'
    });

    const result = yield call(api.getAppsMetaData, sub);

    yield put(
      dashboardAction.getAppsMetadataSuccess({
        apps: result.data.data
      })
    );

    yield put(dashboardAction.setSiteVersion(siteVersions.FULL));

  } catch (e) {
    yield put(dashboardAction.getAppsMetadataFailed());
    yield put({
      type: actionTypes.ALL_APPS_DATA_FETCHED,
      payload: { status: true }
    });
  } finally {
    yield put({
      type: actionTypes.CURRENT_REQUESTS_REMOVE,
      payload: 'getAppsMetadata'
    });
  }
}

function* getAppData(action) {
  try {
    const { app_id, loadOnly } = action.payload;
    const result = yield call(api.getAppData, { app_id });
    yield put(
      dashboardAction.getAppDataSuccess({
        data: result.data.data,
        loadOnly
      })
    );
  } catch (e) {
    yield put(dashboardAction.getAppDataFailed());
  }
}

function* watchGetAllAppsData() {
  yield takeEvery(actionTypes.GET_APPS_METADATA, getAppsMetadata);
}

function* watchGetAppData() {
  yield takeEvery(actionTypes.GET_APP_DATA, getAppData);
}

function* setInsightAsViewed(action) {
  try {

    const { insightID } = action.payload;
    const result = yield call(api.setInsightAsViewed, insightID);
    const key = Object.keys(result.data)[0];

    yield put(dashboardAction.setInsightAsViewedSuccess({
      insightID: key,
      readAt: result.data[key]
    }))

  } catch (e) {
    console.log(e);
  }
  yield put({
    type: actionTypes.SET_MAIN_LOADER,
    payload: { status: false }
  });
}

function* setInsightAsSeen(action) {
  try {
    const { insightIDs } = action.payload;
    const result = yield call(api.setInsightAsSeen, insightIDs);
    const key = Object.keys(result.data)[0];

  } catch (e) {
    console.log(e);
  }
  yield put({
    type: actionTypes.SET_MAIN_LOADER,
    payload: { status: false }
  });
}

function* getDiscoveryDataSaga(action) {
  const result = yield call(api.getDiscoveryDataAPI, action.payload);

  yield put(
    dashboardAction.getDiscoveryDataSuccess({
      data: result.data.data
    })
  );
}

function* watchSetInsightAsSeen() {
  yield takeEvery(actionTypes.SET_SEEN_STATUS, setInsightAsSeen);
}

function* watchSetInsightAsViewed() {
  yield takeEvery(actionTypes.SET_VIEWED_STATUS, setInsightAsViewed);
}

function* likeInsight(action) {
  try {
    const { insightID } = action.payload;
    const result = yield call(api.likeInsight, insightID);
    yield put(dashboardAction.insightLikeInteractionSuccess(result.data));
  } catch (e) {
    console.log(e);
  }
}

function* unlikeInsight(action) {
  try {
    const { insightID } = action.payload;
    yield call(api.unlikeInsight, insightID);
    yield put(dashboardAction.insightLikeInteractionSuccess({
      [insightID]: null
    }));
  } catch (e) {
    console.log(e);
  }
}

function* watchLikeInsight() {
  yield takeLatest(actionTypes.LIKE_INSIGHT, likeInsight);
}

function* watchUnlikeInsight() {
  yield takeLatest(actionTypes.UNLIKE_INSIGHT, unlikeInsight);
}

function* askInsightQuestion(action) {
  try {
    const {
      appName,
      insightID,
      insightURL,
      userEmail,
      question
    } = action.payload;
    const result = yield call(api.askInsightQuestion, {
      appName,
      insightID,
      insightURL,
      userEmail,
      question
    });
    if (result && result.status === 200) {
      yield put({
        type: actionTypes.SET_ASK_INSIGHT_QUESTION_SUCCESS,
        payload: { status: true }
      });
    } else {
      yield put({
        type: actionTypes.SET_ASK_INSIGHT_QUESTION_SUCCESS,
        payload: { status: false }
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.SET_ASK_INSIGHT_QUESTION_SUCCESS,
      payload: { status: false }
    });
  }
}

function* getDiscoveriesLabelsSaga() {
  try {
    const result = yield call(api.getDiscoveriesLabelsAPI);
    yield put(getDiscoveriesLabelsSuccess(result.data))
  } catch (e) {
    console.log(e);
  }
}
function* getIntelDiscoveriesLabelsSaga(action) {
  try {
    const result = yield call(api.getDiscoveriesLabelsAPI, action.payload);
    yield put(getIntelDiscoveriesLabelsSuccess(result.data))
  } catch (e) {
    console.log(e);
  }
}
function* watchAskInsightQuestion() {
  yield takeEvery(actionTypes.ASK_INSIGHT_QUESTION, askInsightQuestion);
}


function* watchGetDiscoveryData() {
  yield takeEvery(actionTypes.GET_DISCOVERY_DATA, getDiscoveryDataSaga);
}
function* watchGetDiscoveriesLabelsSaga() {
  yield takeEvery(actionTypes.GET_DISCOVERIES_LABELS, getDiscoveriesLabelsSaga);
}

function* watchGetIntelDiscoveriesLabelsSaga() {
  yield takeEvery(actionTypes.GET_INTEL_DISCOVERIES_LABELS, getIntelDiscoveriesLabelsSaga);
}

const dashboardSagas = [
  fork(watchGetAppData),
  fork(watchSetInsightAsViewed),
  fork(watchLikeInsight),
  fork(watchUnlikeInsight),
  fork(watchAskInsightQuestion),
  fork(watchGetAllAppsData),
  fork(watchSetInsightAsSeen),
  fork(watchGetDiscoveryData),
  fork(watchGetDiscoveriesLabelsSaga),
  fork(watchGetIntelDiscoveriesLabelsSaga)
];

export default dashboardSagas;
