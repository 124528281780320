import { Consts } from "shared/consts";
import { CHART_COLORS } from "./consts";

export const createHexColorChangeFunc = () => {
  let currentIdx = 0;

  return () => {
    if (currentIdx >= CHART_COLORS.length) {
      currentIdx = 0;
    }

    return CHART_COLORS[currentIdx++];
  };
};

export const getPersonaTitle = (persona: any): string => {
  return Array.isArray(persona.roles) && persona.roles.length > 0
    ? persona.roles[0].name
    : persona.title;
};

export const isMobileDevice = () => {
  return window.innerWidth < Consts.mobileSize;
};

export const calculateExactPercentages = (counts: number[]): number[] => {
  const total = counts.reduce((acc, count) => acc + count, 0);
  const percentages = counts.map((count) => Math.round((count / total) * 100));

  let percentageSum = percentages.reduce(
    (acc, percentage) => acc + percentage,
    0
  );
  let discrepancy = 100 - percentageSum;

  while (discrepancy !== 0) {
    percentages.forEach((percentage, index) => {
      if (discrepancy > 0 && percentage < 100) {
        percentages[index]++;
        discrepancy--;
      } else if (discrepancy < 0 && percentage > 0) {
        percentages[index]--;
        discrepancy++;
      }
    });
  }

  return percentages;
};

export const generateRandomBrightColor = (alpha: number) => {
  alpha = Math.max(0, Math.min(1, alpha));

  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);

  const rgbaColor = `rgba(${r}, ${g}, ${b}, ${alpha})`;

  return rgbaColor;
};

export const fitStringToLength = (str: string, length: number) => {
  if (str.length <= length) {
    return str;
  }

  return `${str.slice(0, length)}...`;
};
