import _ from "lodash";
import analyticsService from "services/analyticsService";

type InsightAnalytic = {
  insight_id: number;
  insight_title: string;
  insight_type: string;
};

type IntelEvents = {
  intel_enter: null;
  intel_apps_filter: {
    selected_app_name: string;
  };
  intel_time_interval_filter: {
    number_of_days: number;
  };
  intel_platform_filter: {
    selected_platform: string;
  };
  intel_type_filter: {
    selected_type: string;
  };
  intel_sub_filters: {
    selected_sub_filter: string;
  };
  intel_insight_clicked: InsightAnalytic;
  intel_insight_viewed: InsightAnalytic;
  intel_card_viewed: InsightAnalytic;
};

export const trackIntelEvent = <T extends keyof IntelEvents>(
  event: T,
  data: IntelEvents[T]
) => {
  analyticsService.getInstance().analytic(event, data);
};
