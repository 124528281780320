import * as aT from "../actions/_actionsTypes";

const INIT_STATE = {
  significantUnseenDiscoveries: null,
  significantUnseenDiscoveriesStatus: '',
  productDiscoveries: null,
  loadingProductDiscoveries: false,
};

export default function (state = INIT_STATE, action) {
  switch (action.type) {

  case aT.GET_SIGNIFICANT_UNSEEN_DISCOVERIES: {
    return {
      ...state,
      significantUnseenDiscoveries: null,
      significantUnseenDiscoveriesStatus: 'loading'
    };
  }

  case aT.GET_SIGNIFICANT_UNSEEN_DISCOVERIES_SUCCESS: {
    return {
      ...state,
      significantUnseenDiscoveries: action.payload.data,
      significantUnseenDiscoveriesStatus: 'completed'
    };
  }

  case aT.GET_PRODUCT_DISCOVERIES: {
    return {
      ...state,
      loadingProductDiscoveries: true
    }
  }

  case aT.GET_PRODUCT_DISCOVERIES_SUCCESS: {
    return {
      ...state,
      productDiscoveries: {
        meta: action.payload.meta,
        data: action.payload.data.map(item => ({
          ...Object.values(item)[0]
        }))
      },
      loadingProductDiscoveries: false
    }
  }
  
  default:
    return state;
  }
}
