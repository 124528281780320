import React, { useEffect } from "react";
import Logo from "../../assets/images/logo.svg";

export default function NotFound() {
  useEffect(() => {
    const footer = document.getElementById("footer");
    if (footer) {
      footer.style.position = "absolute";
    }
    return () => {
      if (footer) {
        footer.style.position = "absolute";
      }
    };
  }, []);
  return (
    <div className="fourOhFour">
      <span className="fourOhFour__bg-large-text">404</span>
      <div className="fourOhFour__content-wrapper">
        <img
          className="fourOhFour__content-wrapper__logo"
          src={Logo}
          alt="watchful's logo"
        />
        <div className="fourOhFour__content-wrapper__content">
          <div className="fourOhFour__content-wrapper__content__header">
            Oops!
          </div>
          <div className="fourOhFour__content-wrapper__content__text">
            The requested page is nowhere to be found. In the meantime, click
            below to get back to your work.{" "}
          </div>
          <a href="/" className="fourOhFour__content-wrapper__content__button">
            <span>Back To Work</span>
          </a>
        </div>
      </div>
    </div>
  );
}
