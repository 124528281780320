import mixpanel from "mixpanel-browser";
import config from "../../shared/config";

if (config.mixpanelKey) mixpanel.init(config.mixpanelKey);

let env_check = (config.mixpanelKey && process.env.NODE_ENV === "production");

let actions = {
  identify: id => {
    if (env_check) {
      mixpanel.identify(id);
    }
  },
  track: (name, props) => {
    if (env_check) {
      mixpanel.track(name, props);
    }
  },
  register: superProps => {
    if (env_check) {
      mixpanel.register(superProps);
    }
  },
  people: {
    set: props => {
      if (env_check) {
        mixpanel.people.set(props);
      }
    }
  }
};

// This is not a default export intentionally.
// By exporting as a named module, searching uses of this module in the project
// become a whole lot easier because there's always the same import "{ Mixpanel }"
export let Mixpanel = actions;
