import React, { useEffect, useState } from "react";
import NewLoader from "../../common/NewLoader/NewLoader";
import GalleryAbTests from "./GalleryAbTests"
import black_chevron_ico from "../../assets/images/icons/chevron_black.svg"

import { Tabs, Select, Popover, Button } from 'antd';
import "./ABTestsPage.scss"
import FilterPlatform from "../products-discoveries/FilterPlatform"
import { PL_ANDROID, PL_IOS, PL_WINDOWS, PL_CHROME, daysAgoList } from "../../shared/consts";
import FilterTime from "../dash-discoveries/FilterTime"
import FilterSearchQuery from "../dash-discoveries/FilterSearchQuery"
import AppDrawer from "../../common/appDrawer/AppDrawer"
const { TabPane } = Tabs;

function ABTestsView({
  ABTests,
  analytics,
  user,
  activeTab,
  activeTabChange,

  products,
  types,
  typesChange,
  platforms,
  platformsChange,
  daysAgo,
  daysAgoChange,
  customRange,
  сustomRangeСhange,
  customRangeTime,
  customRangeTimeChange,
  searchQuery,
  searchQueryChange,
  currentProducts,
  currentProductsChange
}) {

  const listPlatforms = [PL_ANDROID, PL_IOS, PL_WINDOWS, PL_CHROME].filter(plat => products.find(p => p.apps.find(a => a.platform === plat))).map(pl => ({
    id: pl,
    platform: pl,
  }))

  const [isOpenTimeFilter, isOpenTimeFilterChange] = useState(false)

  const handleProductChange = (product) => {
    if (currentProducts.indexOf(product.id) !== -1) {
      currentProductsChange(currentProducts.filter(ca => ca !== product.id))
    } else {
      currentProductsChange([...currentProducts, product.id])
    }
  }

  const getABGallery = (filtABTTests, status) =>
    <GalleryAbTests
      ABTests={filtABTTests.filter(dis => searchQuery === '' ||
        (dis.title.toLowerCase().includes(searchQuery.toLowerCase()) || dis.description.toLowerCase().includes(searchQuery.toLowerCase())))}
      products={currentProducts.length ? currentProducts.map(pID => products.find(p => p.id === Number(pID))) : products}
      user={user}
      analytics={analytics}
      filter={status}
      daysAgo={daysAgo}
      customRange={customRange}
      customRangeTime={customRangeTime}
    />

  return (
    <div className="ab-tests-page">
      <h1 className="h1-title">
        <strong>Experiments & Indications</strong>
      </h1>
      <div className="ab-test-filters">



        <div className="ab-test-filter-time">



          <Popover placement="bottomRight" title={null}
            visible={isOpenTimeFilter}
            onVisibleChange={isOpenTimeFilterChange}
            content={
              <FilterTime
                isOpen={isOpenTimeFilter}
                daysAgoList={daysAgoList}
                daysAgo={daysAgo}
                daysAgoChange={daysAgoChange}
                customRange={customRange}
                сustomRangeСhange={сustomRangeСhange}
                customRangeTime={customRangeTime}
                customRangeTimeChange={customRangeTimeChange}
              />} trigger="click" overlayClassName={`filter-wrap all-discoveries all-discoveries-time ab-test-time`}
            overlayStyle={{ width: (customRange ? '75rem' : '18rem') }}>
            <Button className="filter-btn-new">
              {customRange ? 'Custom Range' : daysAgoList.find(d => d.value === daysAgo).title}
            </Button>
          </Popover>

        </div>
        <div className="ab-test-filter-platform">
          <Popover placement="bottom" title={null} content={
            <FilterPlatform
              filterStatus={platforms}
              filterStatusChange={platformsChange}
              listProductPlatforms={listPlatforms}
            />} trigger="click" overlayClassName={`filter-wrap all-discoveries all-discoveries-state`}>
            <Button className="filter-btn-new platform-mult-selector">
              {platforms.length === listPlatforms.length || platforms.length === 0 ?
                'All Platforms' :
                platforms.join(', ')
              }
            </Button>
          </Popover>
        </div>
        <div className="ab-test-filter-type">
          <Select
            value={types}
            className={`filter-select-new ab-test-type`}
            onChange={typesChange}
            popupClassName={`filter-select-new-dropdown`}
            suffixIcon={
              <img
                className={`chevron`}
                src={black_chevron_ico}
                alt="chevron"
              />
            }
          >
            <Select.Option value={''}>Experiments & Indications</Select.Option>
            <Select.Option value={'ab_test'}>Experiments</Select.Option>
            <Select.Option value={'indication'}>Indications</Select.Option>
          </Select>
        </div>
        <div className="ab-test-filter-product">
          <Popover placement="bottomLeft" title={null}
            destroyTooltipOnHide={true}
            content={
              <AppDrawer appsList={products}
                currentApps={currentProducts}
                currentAppsChange={currentProductsChange}
                isProduct={true}
                page="all-discoveries"
                appChange={handleProductChange}
                clearCurrentApps={() => currentProductsChange([])}
                className="button"
                maxAppsSelected={20}
              />
            } trigger="click" overlayClassName={`popover-appdrawer  filter-wrap  all-discoveries all-discoveries-apps`}>
            <Button className="filter-btn-new">
              Products
              {<span className={`filter-count ${currentProducts.length === 0 ? '' : 'filter-count-padding'}`}>
                {currentProducts.length === 0 || currentProducts.length === products.length ? null : currentProducts.length}
              </span>}
            </Button>
          </Popover>
        </div>
      </div>

      {!ABTests && <div className="wrapper-loader">
        <NewLoader />
      </div>
      }

      <div className="app-search-wrap">
        <FilterSearchQuery
          searchQuery={searchQuery}
          searchQueryChange={searchQueryChange}
          placeholder="Search for a specific discovery within these filters"
        />
      </div>

      {ABTests && <Tabs className="group-date-tabs" activeKey={activeTab} onChange={activeTabChange} animated={{ inkBar: true, tabPane: false }}>
        <TabPane tab={
          <div className="tab-ab-tests-name">
            Active
            <div className="tab-ab-tests-count">{ABTests.filter(dis => (dis.ab_test_status === 'active' && dis.type === 'ab_test')  || dis.type === 'indication').length}</div>
          </div>
        } key="1">
          {getABGallery(ABTests.filter(dis => (dis.ab_test_status === 'active' && dis.type === 'ab_test')  || dis.type === 'indication'), 'active')}

        </TabPane>
        <TabPane tab={
          <div className="tab-ab-tests-name">
            Integrated
            <div className="tab-ab-tests-count">{ABTests.filter(dis => dis.ab_test_status === 'integrated' && dis.type === 'ab_test').length}</div>
          </div>


        } key="2">

          {getABGallery(ABTests.filter(dis => dis.ab_test_status === 'integrated' && dis.type === 'ab_test'), 'integrated')}

        </TabPane>
        <TabPane tab={
          <div className="tab-ab-tests-name">
            Abandoned
            <div className="tab-ab-tests-count">{ABTests.filter(dis => ['abandoned', 'abandoned_no_data'].includes(dis.ab_test_status) && dis.type === 'ab_test').length}</div>
          </div>

        } key="3" >
          {getABGallery(ABTests.filter(dis =>  ['abandoned', 'abandoned_no_data'].includes(dis.ab_test_status) && dis.type === 'ab_test'), 'abandoned')}

        </TabPane>
        <TabPane tab={
          <div className="tab-ab-tests-name">
            All
            <div className="tab-ab-tests-count">{ABTests.length}</div>
          </div>
        } key="4">
          {getABGallery(ABTests, '')}
        </TabPane>
      </Tabs>}
    </div >
  )
}

export default ABTestsView