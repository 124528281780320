import React from "react";
import moment from "moment";
import { connect } from 'react-redux';
import { Button, Select, message, Modal, Popover, Tooltip, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { isEqual } from "lodash";
import config from "../../../shared/config";
import {
  compareStrings,
} from "../../../services/stringsService";
import { marketingOnlyType, siteVersions, typeInfo } from "../../../shared/consts";
import { withRouter } from "react-router-dom";
import ViewCopyButton from "../viewCopyButton/viewCopyButton";
import SeeAllABTests from "../../../components/SeeAllABTestsBTN/SeeAllABTests"

import { getDiscoveriesStartTime, PRIORITY_TYPES } from "../../../helpers/generalHelpers"
import { intelDiscoveryTypeDictionary } from "../../../shared/consts"
import storageService from "../../../services/storageService";
import SubscriptionModal from "../../../containers/subscriptions/subscriptionsModal/SubscriptionModal";

import dis_error_img_ico from "../../../assets/images/new_design/dis-error-img.svg"
import { getUserLicence } from "../../../helpers/usersHelper";
import { createSubscription } from "../../../redux/acts/subscriptions";
import { getDiscoveriesLabels } from '../../../redux/acts/labels';

import { setDiscoveryRate } from "../../../redux/actions/productActions"


import ab_test_ongoing_ico from "../../../assets/images/new_design/abtest-tl/ab-test-ongoing.svg"
import ab_test_integrated_ico from "../../../assets/images/new_design/abtest-tl/ab-test-integrated.svg"
import ab_test_abandoned_ico from "../../../assets/images/new_design/abtest-tl/ab-test-abandoned.svg"
import rate_star_ico from "../../../assets/images/new_design/rate-dis-star.svg"
import rate_star_fill_ico from "../../../assets/images/new_design/rate-dis-star-fill.svg"

import rate_count_person_ico from "../../../assets/images/new_design/rate-person.svg"
import { insightURL } from "../../../helpers/appsHelper";
import { countries } from "../../../shared/countries";
import wwf from '../../../assets/images/world-wide-flag.svg';
import { setSharedInsightData } from "../../../redux/actions/dashboardActions";
import Info from "../../../assets/images/icons/info.svg";
import MoreOptions from "./MoreOptions";


const typeDict = {
  "inapp_campaign": "In-App",
  "email_campaign": "Email",
  "push_notification_campaign": "Push Notification",
}


const ABTestStatusIco = {
  'active': { ico: ab_test_ongoing_ico, label: 'Active' },
  'integrated': { ico: ab_test_integrated_ico, label: 'Integrated' },
  'abandoned': { ico: ab_test_abandoned_ico, label: 'Abandoned' },
  'abandoned_no_data': { ico: ab_test_abandoned_ico, label: 'Abandoned (no data)' }
}

export class ViewHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      status: null,
      type: null,
      startDate: null,
      endDate: null,
      id: "",
      showDownloadError: null,
      isSubscriptionOpen: false,
      hoveringOver: props.userDiscoveries.find(uD => uD.insight_id === props.data.id)?.rating,
    };
  }

  componentDidMount = () => {
    this.setupState();
    const {
      data,
      analytics,
      siteVersion,
      app: { metadata },
      demoUser,
      isSneakPeak,
      userData,
      source,
    } = this.props;
    const {
      title,
      id,
      type,

    } = data;

    if (isSneakPeak) { return }
    let mpBody = {
      insight_name: title,
      insight_type: type,
      app_name: metadata.app_name,
      platform: metadata.platform,
      source,
      insight_url: insightURL(
        {
          isMarketing: Object.keys(marketingOnlyType).includes(type),
          insightID: id,
          versionID: data.current_release_id,
          appID: metadata.id
        })
    };

    if (!this.props.tagsList && !demoUser) {
      this.props.getDiscoveriesLabels()
    }
    if (source === 'shared_link') {
      if (compareStrings(siteVersion, siteVersions.FULL) && userData) {
        mpBody.user = userData.name
        analytics.analytic(`Validated guest opened direct link`, mpBody);
      } else {
        mpBody.generated_by = demoUser.email;
        analytics.analytic(`Non-validated guest opened direct link`, mpBody);
      }
    } else if (source !== 'sneak_peak') {
      analytics.analytic(`View insight (${metadata.platform === 'Marketing' ? 'Marketing': 'Intel'})`, mpBody);
    } else if (source === 'sneak_peak') {
      analytics.analytic(`Expanded insight preview to full (${metadata.platform === 'Marketing' ? 'Marketing': 'Intel'})`, mpBody)
    }

    this.props.setSharedInsightData(mpBody);
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      data,
    } = this.props;

    if (!isEqual(this.props.data, prevProps.data)) {
      this.setupState();
    }
    let oldRate = prevProps.userDiscoveries.find(uD => uD.insight_id === data.id)?.rating,
      curRate = this.props.userDiscoveries.find(uD => uD.insight_id === data.id)?.rating
    if ((oldRate !== curRate) || (this.props.data.id !== prevProps.data.id)) {
      this.setState({
        hoveringOver: curRate
      })
    }
  }

  setupState = () => {
    const {
      data
    } = this.props;
    const {
      title,
      id,

    } = data;
    const status = this.getStatus(data);

    const startDate = this.startDate(data);
    const endDate = data.endDate ? this.endDate(data) : null;
    this.setState({
      title,
      status,
      startDate,
      endDate,
      id,
    });
  };

  getStatus = ({ ab_test_status, type }) => {
    let status = null;
    if (compareStrings(type, "ab_test")) {
      status = ab_test_status;
    }
    return status;
  };

  startDate = data => {
    const { isMarketingDiscovery } = this.props;
    const text = getDiscoveriesStartTime(data),
      header = isMarketingDiscovery ? "Start Date" : "Date";
    return { text, header };

  };

  endDate = data => {
    const { type, ended_date, content: { end_time } } = data;
    let header = "Date";
    let endDate = null;
    if (compareStrings(type, "ab_test") && ended_date) {
      endDate = moment(ended_date).format("ll");
    }
    if (compareStrings(type, "ab_test") && end_time) {
      header = "End Date:";
      endDate = moment(end_time).format("ll");
    }
    if (compareStrings(type, "hashflag")) {
      let { endTimestamp } = data;
      endTimestamp = moment(endTimestamp).format("ll");
      endDate = endTimestamp;
    }
    return { text: endDate, header };
  };

  downloadInsight = async (format, abTestStepDiff, abTestStatus) => {
    const insight = this.props.data;
    const storage = new storageService();
    const token = storage.getItem("usertoken");
    const hideLoader = message.loading({
      content: `Rendering and downloading ${format}...`,
      duration: 0,
      icon: <Spin indicator={
        <LoadingOutlined
          style={{
            color: 'white'
          }}
          spin
        />} />
    });

    const res = await fetch(`${config.dataApi.backendUrl}/insight/${this.props.data.id}/${format}/${abTestStatus}/${abTestStepDiff}`, { headers: { "Authorization": `Bearer ${token}` } })

    this.props.analytics.analytic(`Download insight`,
      {
        name: insight.title,
        insight_type: insight.type,
        format,
        platform: this.props.app.metadata.platform,
        app: this.props.app.metadata.name,
        insight_url: insightURL({
          isMarketing: Object.keys(marketingOnlyType).includes(insight.type),
          appID: this.props.app.metadata.id,
          versionID: insight.release_id,
          insightID: insight.id,
        })
      });
    if (res.ok) {
      const link = document.createElement('a');
      let blobUrl = '';
      if (format !== 'pdf') {

        const blob = await res.blob();
        const newBlob = new Blob([blob]);

        blobUrl = window.URL.createObjectURL(newBlob);

      }
      else {
        const body = await res.json();
        blobUrl = body.url;
      }

      link.href = blobUrl;
      link.setAttribute('download', `${this.state.title}.${format}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

    } else {
      const modal = Modal.info({
        title: <h2>There was an error downloading your file</h2>,
        className: 'discovery-error-loading',
        centered: true,
        width: 370,
        content: (
          <div>
            <p>Please try again later</p>
            <div className="error-download-img">
              <img src={dis_error_img_ico} />
            </div>
          </div>
        ),
        onOk() {
          modal.destroy();
        },
        okText: 'Close',
        okType: 'default'
      });
    }
    setTimeout(hideLoader, 2500);
  }

  render() {
    const { title, type, startDate, status, endDate } = this.state;
    const {
      data: { id, release_name, priority, },
      data,
      app: { metadata },
      analytics,
      siteVersion,
      email,
      isMarketingDiscovery,
      marketingPrevNextList,
      isSneakPeak,
      userData,
      createSubscription,
      products,
      tagsList,
      ABTestData,
      product
    } = this.props;
    const fullVersion = compareStrings(siteVersion, siteVersions.FULL);
    let currentVersion,
      currentVersionInsights


    if (this.props.version) {
      currentVersion = this.props.version

      currentVersionInsights = [
        ...currentVersion.ab_tests,
        ...currentVersion.indications,
        ...currentVersion.new_features,
        ...currentVersion.screen_changes,
        ...currentVersion.removed_features
      ];

    } else if (isMarketingDiscovery && !isSneakPeak) {
      currentVersionInsights = marketingPrevNextList.list
    }

    let abTestStepDiff = ''

    if (ABTestData) {
      let index = ABTestData.insights.findIndex(ins => ins.id === id)
      if (index === -1) {
        abTestStepDiff = moment().diff(moment(data.start_time), 'days') + " days";
      } else if (ABTestData.status === 'active') {
        abTestStepDiff = moment().diff(moment(ABTestData.insights[0].start_time), 'days') + " days";
      } else if ((ABTestData.status === 'integrated') &&
        ABTestData.insights[0].start_time === ABTestData.insights[ABTestData.insights.length - 1].start_time) {
        // if test is marked as integrated but the first and the last start_time are the same ->
        // analyst marked the test as integrated on the same day which is an analyst mistake and we show it as N/A
        abTestStepDiff = 'N/A'
      } else if (ABTestData.insights.filter(ins => ins.type === "ab_test" || ins.type === "indication")) {
        abTestStepDiff = moment(ABTestData.insights[ABTestData.insights.length - 1].start_time).
          diff(moment(ABTestData.insights.filter(ins => ins.type === "ab_test" || ins.type === "indication")[0].start_time), 'days') + " days";
      }
    }

    const handleChangeUserRating = (rating) => {
      analytics.analytic("Rate an insight",
        {
          rating,
          insight_name: title,
          insight_type: data.type,
          platform: data.platform,
          app: data.app_name,
        });
      this.props.setDiscoveryRate({
        insight_id: data.id,
        rate: rating
      })
    }

    const getRateLayout = () => {
      const rt_gb = this.props.userDiscoveries.find(uD => uD.insight_id === data.id)?.rating_global || data.rating_global || 0
      const rt_usr = this.props.userDiscoveries.find(uD => uD.insight_id === data.id)?.rating || 0
      const rt_cnt = this.props.userDiscoveries.find(uD => uD.insight_id === data.id)?.rating_count || data.rating_count || 0


      const stars = <span className="view__header-item-data rating"
        onMouseLeave={() => { this.setState({ hoveringOver: rt_usr }) }}>
        {[1, 2, 3, 4, 5].map(index =>
          <img
            src={this.state.hoveringOver >= index ? rate_star_fill_ico : rate_star_ico}
            onMouseEnter={() => { this.setState({ hoveringOver: index }) }}
            onClick={() => handleChangeUserRating(index)}
            key={index}
          />
        )}
        <span className="rating-count">{rt_cnt}</span>
        <img src={rate_count_person_ico} />
      </span>



      return <div className="view__header-item">
        <span className="view__header-item-header">
          Was it meaningful?
        </span>
        {email && rt_usr ? <Popover placement="bottom"
          overlayClassName='user-rate-modal'
          content={
            <div>
              <h2>Your rate</h2>
              <div className="user-rating">
                {[1, 2, 3, 4, 5].map(index =>
                  <img src={rt_usr < index ? rate_star_ico : rate_star_fill_ico} />
                )}
              </div>
            </div>
          }>{stars}</Popover> : stars}
      </div>
    }

    const getPersonaLocations = () => {
      const allCountries = [{
        name: "World Wide",
        code: "WW",
      }, ...countries];
      return (
        <div className="view__header-item">
          <span className="view__header-item-header">
            Seen in geo
          </span>
          <div className="countries">
            {[...new Set(data.persona_locations)].map((loc, i) => (<Tooltip title={`${allCountries.find(c => c.code === loc.toUpperCase()).name}`} placement={'top'} key={i}>
              <div className="country">{loc === 'ww' ? <img src={wwf} /> : <span className={`flag-icon flag-icon-${loc.toLowerCase()}`} />}</div>
            </Tooltip>
            ))}
          </div>

        </div>
      )
    }

    const priorityUI = priority && <div className="view__header-item">
      <span className="view__header-item-header">
        Priority
      </span>
      <span className={`view__header-item-data`}>
        <span className="view__header-item-data">{PRIORITY_TYPES[priority]}</span>
      </span>
    </div>

    const platform = (this.props.app.metadata ? this.props.app.metadata.platform : this.props.app.platform)
    return (
      <div
        className={`view__header ${!fullVersion ? "view__header-demo" : ""} ${isSneakPeak ? 'sneak-peak' : ''}`}
      >
        <div className="view__header__primary">
          <h3
            className={`view__header-title ${fullVersion ? "view__header-title-full" : ""
            }`}
          >
            {title}
          </h3>

          {fullVersion && !isSneakPeak && (
            <div className="view__header__buttons">
              <ViewCopyButton
                insightId={id}
                metadata={metadata}
                analytics={analytics}
                title={title}
                id={id}
                page="overview"
                insightType={data.type}
              />

              <MoreOptions
                downloadCallback = {(val) => this.downloadInsight(val,
                  abTestStepDiff ? abTestStepDiff : 'None',
                  ABTestData ? ABTestStatusIco[ABTestData.status].label : 'None')}
                insightID={id}
                app={this.props.app.metadata}
                discovery={data}
                defaultEmail={email}
                currentInsightURL={this.props.match && this.props.match.url}
                metadata={metadata}
              />

            </div>
          )}
          {fullVersion && !isSneakPeak && !this.props.match.params.type &&
            currentVersionInsights && currentVersionInsights.length > 0 && (
            <div className="view__header__btns">
              <div className="view__header__btns-create-see">
                {['ab_test', 'indication'].includes(data.type) && product && <SeeAllABTests
                  link={`/product_intelligence/ab-tests/?daysAgo=60&types=${data.type === 'ab_test' ? 'ab_test' : 'indication'}&platforms=&activeTab=1&product_ids=${product.id}`}
                  text={data.type === 'ab_test' ? 'See All Experiments' : 'See All Indications'}
                  user={{ email }}
                />}
              </div>
            </div>
          )}
        </div>


        <div className="view__header-details">

          {isMarketingDiscovery ?


            <div className={`view__header-row ${isSneakPeak ? 'wide' : ''}`}>
              <div className="view__header-item">
                <span className="view__header-item-header">
                  Type
                </span>
                <span className="view__header-item-data">{typeDict[data.type]}</span>
              </div>

              <div className="view__header-item">
                <span className="view__header-item-header">
                  Discovery Date
                </span>
                <span className="view__header-item-data">
                  {getDiscoveriesStartTime(data)}
                </span>
              </div>
              {this.props.disRatingIsEnable && getRateLayout()}
              {priorityUI}
              {data.persona_locations && data.persona_locations.length > 0 && getPersonaLocations()}
            </div>

            :

            <div className={`view__header-row ${isSneakPeak ? 'wide' : ''}`}>
              {!isMarketingDiscovery && (
                <div className="view__header-item">
                  <span className="view__header-item-header">
                    Type
                    <Popover
                      content={typeInfo[data.type]}
                      overlayStyle={{ maxWidth: 500 }}
                      overlayClassName="antd-popover-black"
                    >
                      <img alt="type-info" src={Info} />
                    </Popover>
                  </span>
                  <span className="view__header-item-data">
                    {
                      intelDiscoveryTypeDictionary[
                        data.type
                      ]
                    }
                  </span>
                </div>
              )}

              {startDate && (
                <div className="view__header-item">
                  <span className="view__header-item-header">
                    {startDate.header}
                  </span>
                  <span className="view__header-item-data">{startDate.text}</span>
                </div>
              )}
              {endDate && endDate.text && (
                <div className="view__header-item">
                  <span className="view__header-item-header">
                    {endDate.header}
                  </span>
                  <span className="view__header-item-data">{endDate.text}</span>
                </div>
              )}
              {ABTestData && <div className="view__header-item">
                <span className="view__header-item-header">
                  Experiment duration
                </span>
                <span className="view__header-item-data  abtest-roles">
                  {abTestStepDiff}
                </span>
              </div>}
              {ABTestData && <div className="view__header-item">
                <span className="view__header-item-header">
                  Experiment tracking
                </span>
                <span className={`view__header-item-data  abtest-roles-status ${ABTestData.status}`}>
                  <div className="abtest-dis-title-ico"><img src={ABTestStatusIco[ABTestData.status].ico} /> {ABTestStatusIco[ABTestData.status].label} </div>
                </span>
              </div>}
              {release_name && platform !== 'Web' && (
                <div className="view__header-item">
                  <span className="view__header-item-header">
                    Release
                  </span>
                  <span className="view__header-item-data">{release_name}</span>
                </div>
              )}
              {this.props.disRatingIsEnable && getRateLayout()}
              {priorityUI}
              {data.persona_locations && data.persona_locations.length > 0 && getPersonaLocations()}
            </div>
          }

          {!isSneakPeak && this.state.isSubscriptionOpen &&
            <SubscriptionModal
              analytics={analytics}
              tagsList={[{ id: -1, name: 'critical insight' }, ...tagsList]}
              products={products}
              licences={getUserLicence(userData)}
              windowIsOpen={this.state.isSubscriptionOpen}
              closeWindow={(val) => {
                this.setState({
                  isSubscriptionOpen: false,
                }
                )
              }}
              subscription={
                {
                  name: '',
                  labels: [],
                  tag_filter: 'any',
                  products: products.filter(prod => this.props.app.metadata.product_id === prod.id)
                }}
              createSubscription={createSubscription}
            />}
        </div>

      </div>
    );
  }
}


export default withRouter(connect((state) => {
  return {
    userData: state.userData,
    products: state.overview.products,
    tagsList: state.labels.tagsList,
    subscriptionsInit: state.subscriptions.subscriptionsInit,
    userDiscoveries: state.userDiscoveries.discoveries
  }
}, {
  createSubscription,
  getDiscoveriesLabels,
  setDiscoveryRate,
  setSharedInsightData,
})(ViewHeader));
