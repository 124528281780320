import React from "react";
import "./MarketingCampaignsFilters.scss";
import { Popover } from "antd";
import AppDrawer from "common/appDrawer/AppDrawer";
import caret_ico from "assets/images/icons/caret-down.svg";
import { ChangeCampaignsFilterType } from "containers/MarketingCampaings/hooks/useCampaignsFilters";
import {
  App,
  FocusArea,
  UserRoleType,
  UserType,
} from "containers/MarketingCampaings/utils/types";
import { UserTypeFilter } from "../../../../components/UserTypeFilter";
import { CAMPAIGNS_FILTERS } from "containers/MarketingCampaings/utils/conts";
import { daysAgoList } from "shared/consts";
import moment, { Moment } from "moment";
import { trackMarketingEvent } from "containers/MarketingCampaings/utils/analytics";
import { FocusAreasFilter } from "./FocusAreasFilter";
import { isMobileDevice } from "containers/MarketingOverview/utils/helpers";
import { TimeFilter } from "components/TimeFilter";
import device_ico from "assets/images/icons/device.svg";
import persona_ico from "assets/images/icons/persona.svg";
import target_ico from "assets/images/icons/target.svg";
import calendar_ico from "assets/images/icons/calendar.svg";
import x_ico from "assets/images/icons/x-no-outline.svg";

type Props = {
  apps: App[];
  appId: string;
  daysAgo: number;
  customRange: boolean;
  userTypes: UserType[];
  userRoleTypes: UserRoleType[];
  selectedUserTypes: string[];
  selectedUserRoleTypes: string[];
  handleChangeFilter: ChangeCampaignsFilterType;
  customRangeTime: Moment[];
  selectedFocusAreas: string[];
  focusAreas: FocusArea[];
};

export const MarketingCampaignsFilters = ({
  appId,
  handleChangeFilter,
  daysAgo,
  userTypes,
  userRoleTypes,
  apps,
  selectedUserTypes,
  selectedUserRoleTypes,
  customRange,
  customRangeTime,
  selectedFocusAreas,
  focusAreas,
}: Props) => {
  const selectedApp = apps.find((app) => app.id.toString() === appId);
  const isMobile = isMobileDevice();
  const timeFrameStartDate = customRange
    ? customRangeTime[0]
    : moment().subtract(daysAgo, "days");
  const timeFrameEndDate = customRange ? customRangeTime[1] : moment();

  const handleSelectApp = (
    app: Omit<App, "displayName"> & { name: "string" }
  ) => {
    handleChangeFilter(CAMPAIGNS_FILTERS.app_id, app.id.toString());
    handleChangeFilter(CAMPAIGNS_FILTERS.channel, "");
    handleChangeFilter(CAMPAIGNS_FILTERS.user_types, []);
    handleChangeFilter(CAMPAIGNS_FILTERS.user_role_types, []);

    document.querySelector("#mkc-table")?.scrollTo(0, 0);
    trackMarketingEvent("app_filter", { selected_app_name: app.name });
  };

  const handleUserOrRoleTypeSelect = (
    userTypesIds: number[],
    userRoleTypeIds: number[]
  ) => {
    const userTypesSelected: UserType[] = userTypesIds
      .map((id) => userTypes.find((userType) => userType.id === id))
      .filter((userType) => userType != undefined) as UserType[];

    const userRoleTypesSelected = userRoleTypeIds
      .map((id) => userRoleTypes.find((userRoleType) => userRoleType.id === id))
      .filter((userRoleType) => userRoleType != undefined) as UserRoleType[];

    trackMarketingEvent("user_type_filter", {
      user_types_selected: userTypesSelected.map((userType) => userType.title),
      user_role_types_selected: userRoleTypesSelected.map(
        (userRoleType) => userRoleType.name
      ),
    });

    handleChangeFilter(
      CAMPAIGNS_FILTERS.user_types,
      userTypesIds.map((id) => id.toString())
    );
    handleChangeFilter(
      CAMPAIGNS_FILTERS.user_role_types,
      userRoleTypeIds.map((id) => id.toString())
    );
  };

  const handleDaysAgoChange = (daysAgo: number) => {
    trackMarketingEvent("time_interval_filter", {
      number_of_days: daysAgoList.find((d) => d.value === daysAgo)?.value || 30,
    });

    handleChangeFilter(CAMPAIGNS_FILTERS.days_ago, daysAgo.toString());
  };

  const handleCustomRangeChange = (customRange: boolean) => {
    handleChangeFilter(CAMPAIGNS_FILTERS.custom_range, customRange.toString());
  };

  const handleSelectFocusAreas = (focusAreasIds: string[]) => {
    handleChangeFilter(CAMPAIGNS_FILTERS.focus_areas, focusAreasIds);
  };

  const handleCustomRangeChangeTimeChange = (args: Moment[]) => {
    const startDate = args[0].toISOString();
    const endDate = args[1].toISOString();

    trackMarketingEvent("time_interval_filter", {
      number_of_days: Math.round(
        Math.abs(new Date(startDate).getTime() - new Date(endDate).getTime()) /
          (1000 * 60 * 60 * 24)
      ),
    });

    handleChangeFilter(CAMPAIGNS_FILTERS.custom_range_time, [
      startDate,
      endDate,
    ]);
  };

  const appsList = apps.map((app) => ({
    id: app.id,
    thumbnail: app.icon,
    name: app.displayName,
  }));

  const selectedUserTypesObjects = userTypes.filter((userType) =>
    selectedUserTypes.includes(userType.id.toString())
  );

  const selectedUserRoleTypesObject = userRoleTypes.filter((userRoleType) =>
    selectedUserRoleTypes.includes(userRoleType.id.toString())
  );

  const totalTypesSelected =
    selectedUserTypes.length + selectedUserRoleTypes.length;

  const [isAppDrawerOpen, setIsAppDrawerOpen] = React.useState(false);

  if (!appId) return null;

  return (
    <>
      <div className="mkc-heading-container">
        <h1 className="mkc-heading">Marketing Campaigns</h1>
        <div className="mkc-filters">
          <div className="mkc-campaigns-filters-container">
            <div className="select-filters-container">
              <div className="mkc-campaigns-app">
                <Popover
                  placement="bottom"
                  title={null}
                  destroyTooltipOnHide={true}
                  open={isAppDrawerOpen}
                  content={
                    <AppDrawer
                      appsList={appsList}
                      currentApps={[Number(appId)]}
                      isProduct={true}
                      page="all-discoveries"
                      appChange={(
                        selectedApp: Omit<App, "displayName"> & {
                          name: "string";
                        }
                      ): void => {
                        handleSelectApp(selectedApp);
                        setIsAppDrawerOpen(false);
                      }}
                      // @ts-ignore
                      className="button"
                      maxAppsSelected={20}
                      canClear={false}
                      selectedBgColor="#F5F5F5"
                    />
                  }
                  trigger="click"
                  onOpenChange={(newOpen) => setIsAppDrawerOpen(newOpen)}
                  overlayClassName={`popover-appdrawer  filter-wrap   all-discoveries all-discoveries-apps`}
                >
                  <div className="select-filter">
                    <div className="select-body">
                      {selectedApp ? (
                        <>
                          <img src={device_ico} />
                          <span>App</span>
                        </>
                      ) : (
                        <span>Select App</span>
                      )}
                      <img src={caret_ico} />
                    </div>
                  </div>
                </Popover>
              </div>
              <div className="mkc-user-type">
                <Popover
                  placement="bottom"
                  title={null}
                  destroyTooltipOnHide={true}
                  content={
                    <UserTypeFilter
                      onSelect={handleUserOrRoleTypeSelect}
                      selectedUserTypes={selectedUserTypesObjects}
                      selectedUserRoleTypes={selectedUserRoleTypesObject}
                      userTypes={userTypes}
                      userRoleTypes={userRoleTypes}
                    />
                  }
                  trigger="click"
                >
                  <div className="select-filter" style={{ minWidth: "150px" }}>
                    <div className="select-body">
                      <img src={persona_ico} />
                      <span>{`User Types (${totalTypesSelected})`}</span>
                      <img src={caret_ico} style={{ marginLeft: "6px" }} />
                    </div>
                  </div>
                </Popover>
              </div>
              <div className="mkc-user-type">
                <Popover
                  placement="bottom"
                  title={null}
                  destroyTooltipOnHide={true}
                  content={
                    <FocusAreasFilter
                      focusAreas={focusAreas}
                      selectedFocusAreas={selectedFocusAreas}
                      onSelectFocusArea={handleSelectFocusAreas}
                    />
                  }
                  trigger="click"
                >
                  <div className="select-filter" style={{ minWidth: "150px" }}>
                    <div className="select-body">
                      <img src={target_ico} />
                      <span>{`Focus Area (${selectedFocusAreas.length})`}</span>
                      <img src={caret_ico} style={{ marginLeft: "6px" }} />
                    </div>
                  </div>
                </Popover>
              </div>
              <div className="mkc-user-type">
                <Popover
                  placement="bottom"
                  title={null}
                  destroyTooltipOnHide={true}
                  content={
                    <TimeFilter
                      isOpen
                      daysAgoList={daysAgoList}
                      daysAgo={daysAgo}
                      daysAgoChange={handleDaysAgoChange}
                      customRange={customRange}
                      сustomRangeСhange={handleCustomRangeChange}
                      customRangeTime={customRangeTime}
                      customRangeTimeChange={handleCustomRangeChangeTimeChange}
                      oneDay={false}
                    />
                  }
                  trigger="click"
                  overlayClassName="filter-wrap all-discoveries all-discoveries-time"
                  overlayStyle={{ width: isMobile ? "35rem" : "75rem" }}
                >
                  <div className="select-filter">
                    <div className="select-body">
                      <img src={calendar_ico} />
                      <span>
                        {`${timeFrameStartDate.format(
                          "MMM DD, YYYY"
                        )} - ${timeFrameEndDate.format("MMM DD, YYYY")}`}
                      </span>
                      <img src={caret_ico} style={{ marginLeft: "10px" }} />
                    </div>
                  </div>
                </Popover>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="selected-filters">
        {selectedApp && (
          <div className="selected-filter">
            <img src={selectedApp.icon} className="selected-icon" />
            <img src={x_ico} className="close-icon" />
          </div>
        )}
        {selectedUserTypesObjects.map((userType) => (
          <div
            className="selected-filter"
            style={{
              backgroundColor: userType.bgColor,
            }}
            key={userType.id}
          >
            <span
              style={{
                color: userType.textColor,
              }}
            >
              {userType.title}
            </span>
            <img
              src={x_ico}
              className="close-icon"
              onClick={() => {
                handleUserOrRoleTypeSelect(
                  selectedUserTypes
                    .filter((id) => Number(id) !== userType.id)
                    .map((id) => Number(id)),
                  selectedUserRoleTypes.map((id) => Number(id))
                );
              }}
            />
          </div>
        ))}
        {selectedUserRoleTypesObject.map((userRoleType) => (
          <div
            className="selected-filter"
            style={{
              backgroundColor: userRoleType.bgColor,
            }}
            key={userRoleType.id}
          >
            <span
              style={{
                color: userRoleType.textColor,
              }}
            >
              {userRoleType.name}
            </span>
            <img
              src={x_ico}
              className="close-icon"
              onClick={() => {
                handleUserOrRoleTypeSelect(
                  selectedUserTypes.map((id) => Number(id)),
                  selectedUserRoleTypes
                    .filter((id) => Number(id) !== userRoleType.id)
                    .map((id) => Number(id))
                );
              }}
            />
          </div>
        ))}
        {selectedFocusAreas.map((focusArea) => (
          <div className="selected-filter" key={focusArea}>
            <span>
              {focusAreas.find((f) => f.id === Number(focusArea))?.name}
            </span>
            <img
              src={x_ico}
              className="close-icon"
              onClick={() => {
                handleSelectFocusAreas(
                  selectedFocusAreas.filter((id) => id !== focusArea)
                );
              }}
            />
          </div>
        ))}
      </div>
    </>
  );
};
