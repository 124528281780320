import { createAction, createReducer } from 'redux-act';
import {
  GET_DISCOVERIES_BY_PRODUCTS,
  GET_DISCOVERIES_BY_PRODUCTS_SUCCESS,
  GET_AB_TESTS,
  GET_AB_TESTS_SUCCESS
} from "../actions/_actionsTypes";


export const getDiscoveriesByProduct = createAction(GET_DISCOVERIES_BY_PRODUCTS, (product_id) => product_id);
export const getDiscoveriesByProductSuccess = createAction(GET_DISCOVERIES_BY_PRODUCTS_SUCCESS, (data) => data);

export const getABTests = createAction(GET_AB_TESTS, (data) => data);
export const getABTestsSuccess = createAction(GET_AB_TESTS_SUCCESS, (data) => data);

const initState = {
  productsWithDiscoveries: {},
  ABTests: null
}

export const productsDiscoveriesReducer = createReducer({
  [GET_DISCOVERIES_BY_PRODUCTS_SUCCESS]: (state, payload) => {
    let productsWithDiscoveriesCopy = {
      ...state.productsWithDiscoveries,
      [payload.product_id]: payload.data.data
    }
    return {
      ...state,
      productsWithDiscoveries: productsWithDiscoveriesCopy
    }
  },
  [GET_AB_TESTS]: (state, payload) => {
    return {
      ...state,
      ABTests: null
    }
  },
  [GET_AB_TESTS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ABTests: payload.data.data
    }
  },
}, initState);