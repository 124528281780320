import React from 'react';

const ViewExperimentText = ({ text }) => {
  return (
    <div className='view__experiment-images-text mt-s'>
      <p>{text}</p>
    </div>
  );
}

export default ViewExperimentText;