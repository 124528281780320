import React, { useEffect, useState, useRef } from "react";

import { DatePicker as DatePickerBase, Input } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
// @ts-ignore
import InputMask from "react-input-mask";
import moment from "moment";
import cal_left_ico from "assets/images/new_design/icons/antd-cal-left.svg";
import cal_right_ico from "assets/images/new_design/icons/antd-cal-right.svg";
import caret_left_ico from "assets/images/icons/newIntel/caret-left.svg";

import "./TimeFilter.scss";
import { isMobileDevice } from "containers/MarketingOverview/utils/helpers";
import clsx from "clsx";

const DatePicker: any = DatePickerBase;

type Props = {
  isOpen: boolean;
  daysAgoList: { title: string; value: number }[];
  daysAgo: number;
  daysAgoChange: (value: number) => void;
  customRange: boolean;
  сustomRangeСhange: (value: boolean) => void;
  customRangeTime: moment.Moment[];
  customRangeTimeChange: (value: moment.Moment[]) => void;
  oneDay?: boolean;
  onClose?: () => void;
};

export const TimeFilter = ({
  isOpen,
  daysAgoList,
  daysAgo,
  daysAgoChange,
  customRange,
  сustomRangeСhange,
  customRangeTime,
  customRangeTimeChange,
  oneDay = false,
  onClose,
}: Props) => {
  const [openRangePicker, openRangePickerChange] = useState(true);
  const [manStartDate, manStartDateChange] = useState(
    customRangeTime[0].format("DD/MM/YYYY")
  );
  const [manEndDate, manEndDateChange] = useState(
    customRangeTime[1].format("DD/MM/YYYY")
  );
  const isMobile = isMobileDevice();

  const popover = useRef(null);
  const popover1 = useRef(null);
  const popover2 = useRef(null);

  const getCalendarContainer = () => {
    return popover.current;
  };

  const choosePreset = (time: number) => {
    daysAgoChange(time);
    сustomRangeСhange(false);
  };

  const handleKeyDownStartDate = (e: any) => {
    if (e.key === "Enter") {
      let newMoment: any = moment(manStartDate, "DD/MM/YYYY");
      let newDate =
        newMoment.isAfter(moment()) || !newMoment._isValid
          ? moment()
          : newMoment;
      if (newDate.isAfter(customRangeTime[1])) {
        newDate = moment(customRangeTime[1]);
      }
      сustomRangeСhange(true);
      customRangeTimeChange([newDate, customRangeTime[1]]);
    }
  };

  const handleKeyDownEndDate = (e: any) => {
    if (e.key === "Enter") {
      let newMoment: any = moment(manEndDate, "DD/MM/YYYY");
      let newDate =
        newMoment.isAfter(moment()) || !newMoment._isValid
          ? moment()
          : newMoment;
      сustomRangeСhange(true);
      customRangeTimeChange([customRangeTime[0], newDate]);
    }
  };

  const handleOnTodayClick = () => {
    сustomRangeСhange(true);

    if (customRangeTime[0]) {
      customRangeTimeChange([customRangeTime[0], moment()]);
    } else {
      customRangeTimeChange([moment(), moment()]);
    }
  };

  const dateRender = (cur: any) => {
    const classList = [];
    if (moment(cur).isBetween(customRangeTime[0], customRangeTime[1])) {
      classList.push("in-range");
    }
    if (!moment(customRangeTime[0]).isSame(customRangeTime[1])) {
      if (moment(cur).isSame(customRangeTime[0])) {
        classList.push("start-date");
      }
      if (moment(cur).isSame(customRangeTime[1])) {
        classList.push("end-date");
      }
    } else if (
      moment(cur).isSame(customRangeTime[0]) &&
      moment(cur).isSame(customRangeTime[1])
    ) {
      classList.push("only-today");
    }

    return (
      <div className={`ant-picker-cell-wrap ${classList.join(" ")}`}>
        <div className="ant-picker-cell-inner">{cur.date()}</div>
      </div>
    );
  };

  useEffect(() => {
    setTimeout(() => {
      openRangePickerChange(true);
    }, 250);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      openRangePickerChange(isOpen);
    }, 200);
  }, [isOpen]);

  const renderDaysAgoList = () => {
    return daysAgoList.map((o, i) => (
      <div
        key={o.value}
        className={clsx("range-value", {
          active: !customRange && daysAgo == o.value,
        })}
        onClick={() => choosePreset(o.value)}
      >
        {o.title}
      </div>
    ));
  };

  const handleApplyClick = () => {
    handleKeyDownStartDate({ key: "Enter" });
    handleKeyDownEndDate({ key: "Enter" });
    onClose?.();
  };

  return (
    <div className="wrap-time-filter new">
      <div className="left-side">
        <h2>
          {isMobile && <img onClick={onClose} src={caret_left_ico} />}
          Time Range
        </h2>
        <h4>Presets</h4>
        {isMobile ? (
          <div className="mobile-presets">{renderDaysAgoList()}</div>
        ) : (
          renderDaysAgoList()
        )}
      </div>
      <div className="right-side">
        {isMobile && <h4>Or custom</h4>}
        <div className="manual-inputs-dates">
          <div className="manual-input-date start-date">
            <InputMask
              mask="99/99/9999"
              value={manStartDate}
              onChange={(e: any) => manStartDateChange(e.target.value)}
              onKeyDown={handleKeyDownStartDate}
            >
              {(inputProps: any) => (
                <Input
                  {...inputProps}
                  prefix={isMobile ? "" : "From: "}
                  placeholder="Start Date"
                  className="man-input"
                  autoFocus
                />
              )}
            </InputMask>
          </div>
          {!oneDay && (
            <div className="manual-input-date end-date">
              <InputMask
                mask="99/99/9999"
                value={manEndDate}
                onChange={(e: any) => manEndDateChange(e.target.value)}
                onKeyDown={handleKeyDownEndDate}
              >
                {(inputProps: any) => (
                  <Input
                    {...inputProps}
                    prefix={isMobile ? "" : "To: "}
                    placeholder="End Date"
                    className="man-input"
                    autoFocus
                  />
                )}
              </InputMask>
              <div className="today" onClick={handleOnTodayClick}>
                Today
              </div>
            </div>
          )}
        </div>
        <div className="date-picker-wrapper" ref={popover}>
          {oneDay ? (
            <DatePicker
              separator=""
              open={openRangePicker}
              getCalendarContainer={getCalendarContainer}
              value={customRangeTime[0]}
              disabledDate={(current: any) =>
                current && current > moment().endOf("day")
              }
              onChange={(date: any) => {
                сustomRangeСhange(true);
                customRangeTimeChange([date, date]);
              }}
            />
          ) : (
            <div className="wf-datepicker">
              {!isMobile && (
                <div ref={popover1}>
                  <DatePicker
                    separator=""
                    open={openRangePicker}
                    getPopupContainer={() => popover1.current}
                    value={customRangeTime[0]}
                    disabledDate={(current: any) =>
                      current && current > moment().endOf("day")
                    }
                    onChange={(date: any) => {
                      сustomRangeСhange(true);
                      customRangeTimeChange([date, customRangeTime[1]]);
                      manStartDateChange(date.format("DD/MM/YYYY"));
                    }}
                    defaultOpen
                    dateRender={dateRender}
                    popupClassName="wf-ant-range-picker-popup"
                    superNextIcon={null}
                    superPrevIcon={null}
                    prevIcon={<img src={cal_left_ico} />}
                    nextIcon={<img src={cal_right_ico} />}
                  />
                </div>
              )}
              <div ref={popover2}>
                <DatePicker
                  separator=""
                  open={openRangePicker}
                  getPopupContainer={() => popover2.current}
                  value={customRangeTime[1]}
                  defaultOpen
                  onChange={(date: any) => {
                    сustomRangeСhange(true);
                    customRangeTimeChange([customRangeTime[0], date]);
                    manEndDateChange(date.format("DD/MM/YYYY"));
                  }}
                  disabledDate={(cur: any) =>
                    !(
                      moment(cur).add(1, "day").isAfter(customRangeTime[0]) &&
                      cur.isBefore(moment().endOf("day"))
                    )
                  }
                  dateRender={dateRender}
                  popupClassName="wf-ant-range-picker-popup "
                  prevIcon={<img src={cal_left_ico} />}
                  nextIcon={<img src={cal_right_ico} />}
                />
                {isMobile && (
                  <div className="apply-btn" onClick={handleApplyClick}>
                    Apply
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
//
