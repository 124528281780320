import moment from "moment";
import { ChartValuesToggle } from "../utils/types";

export enum MarketingOverviewFiltersParams {
  app_id = "app_id",
  channel_id = "channel_id",
  days_ago = "days_ago",
  custom_range = "custom_range",
  custom_range_time = "custom_range_time",
  campaign_channel_breakdown_chart_values = "campaign_channel_breakdown_chart_values",
  per_user_type_chart_values = "per_user_type_chart_values",
  campaign_calendar_show_lines = "campaign_calendar_show_lines",
}

type MarketingOverviewFiltersTypes = {
  app_id: string[];
  channel_id: string[];
  days_ago: string;
  custom_range: string;
  custom_range_time: string[];

  per_user_type_chart_values: ChartValuesToggle;
  campaign_channel_breakdown_chart_values: ChartValuesToggle;
  campaign_calendar_show_lines: string;
};

export type HandleOverviewFilterChangeType = <
  T extends keyof MarketingOverviewFiltersTypes
>(
  filter: T,
  newValue: MarketingOverviewFiltersTypes[T]
) => void;

type Props = {
  history: any;
};

export const useOverviewFilters = ({ history }: Props) => {
  const params = new URLSearchParams(history.location.search);

  const appIds = params.getAll(MarketingOverviewFiltersParams.app_id) || []; // if empty, all apps
  const parsedAppIds = appIds.map((id) => parseInt(id));

  const channelIds =
    params.getAll(MarketingOverviewFiltersParams.channel_id) || []; // if empty, all channels

  const daysAgo = parseInt(
    params.get(MarketingOverviewFiltersParams.days_ago) || "30"
  );
  const customRange = !!(
    params.get(MarketingOverviewFiltersParams.custom_range) &&
    params.get(MarketingOverviewFiltersParams.custom_range) !== "false"
  );
  const customRangeTimes = params.getAll(
    MarketingOverviewFiltersParams.custom_range_time
  );
  const customRangeTime =
    customRangeTimes.length > 1
      ? [moment(customRangeTimes[0]), moment(customRangeTimes[1])]
      : [moment(), moment()];

  const perUserTypeChartValues = (params.get(
    MarketingOverviewFiltersParams.per_user_type_chart_values
  ) || "percentages") as ChartValuesToggle;
  const campaignChannelBreakdownChartValues = (params.get(
    MarketingOverviewFiltersParams.campaign_channel_breakdown_chart_values
  ) || "percentages") as ChartValuesToggle;

  const campaignsCalendarShowLinesValue = params.get(
    MarketingOverviewFiltersParams.campaign_calendar_show_lines
  );

  // if undefined, default to true otherwise convert to boolean
  const campaignCalendarShowLines =
    campaignsCalendarShowLinesValue === null ||
    campaignsCalendarShowLinesValue === "true";

  const handleUpdateFilter: HandleOverviewFilterChangeType = (
    filter,
    newValue
  ) => {
    const newParams = new URLSearchParams(history.location.search);

    if (Array.isArray(newValue)) {
      newParams.delete(filter);
      newValue.forEach((value) => {
        newParams.append(filter, value);
      });
    } else {
      newParams.set(filter, newValue);
    }

    history.replace({
      search: newParams.toString(),
    });
  };

  return {
    handleUpdateFilter,
    appIds: parsedAppIds,
    channelIds,
    daysAgo,
    customRange,
    customRangeTime,

    perUserTypeChartValues,
    campaignChannelBreakdownChartValues,
    campaignCalendarShowLines,
  };
};
