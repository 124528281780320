import axios from "axios";
import config from "../../shared/config";
import { IShare } from "../../interfaces/Reports";

export const getReportsAPI = () => {
  let { backendUrl } = config.dataApi;
  const url = `${backendUrl}/reports`;
  return axios.get(url);
};

export const setReportsNotificationsSeen = (ids: string) => {
  let { backendUrl } = config.dataApi;
  const url = `${backendUrl}/reports/seen`;
  return axios.put(url, { "report_ids": ids });
}

export const setReportsNotificationsRead = (ids: string) => {
  let { backendUrl } = config.dataApi;
  const url = `${backendUrl}/reports/read`;
  return axios.put(url, { "report_ids": ids });
}

export const sendReportsShare =(data: IShare) => {
  let { backendUrl } = config.dataApi;
  const url = `${backendUrl}/user/shared-mail/report`;
  return axios.post(url, data);
}