import React from 'react';
import {Button, Col, Row} from "antd";
import ViewHeader from "../discoveryView/viewHeader/ViewHeader";
import {siteVersions} from "../../shared/consts";
import ViewTags from "../discoveryView/viewTags/ViewTags";
import ViewOverview from "../discoveryView/viewOverview/ViewOverview";
import { Img } from "react-image";
import {Link} from "react-router-dom";
import blackArrowSlider from "../../assets/images/icons/blackArrowSlider.svg";
import whiteArrowSlider from "../../assets/images/icons/whiteArrowSlider.svg";
import ViewCopyButton from "../discoveryView/viewCopyButton/viewCopyButton";
import ViewHeaderChat from "../discoveryView/viewHeader/viewHeaderChat/ViewHeaderChat";

const InsightPreview = ({currentItem, ABTestData, disRatingIsEnable, biWeekly,
  insightLink, user, closeModalAndRemoveViewedInsights, analytics, isMarketingDiscovery}) => {
  const imageModalType = currentItem.item.thumbnail_orientation;

  return <div>
    <Row
      gutter={15}
      type="flex"
      align="middle"
      className="sneak-peak-modal__header"
    >
      <Col>
        <Img
          src={currentItem.item.icon}
          className="app-icon"
          alt="app-icon"
          loader={<div className="versions__list__item-loader" />}
          width="50"
          height="50"
        />
      </Col>
      <Col>
        <div className="app-name">{currentItem.item.display_name}</div>
        {!isMarketingDiscovery && <div className="platform">{currentItem.item.platform}</div>}

      </Col>
      <Col className="version-block">
        {user.email && <div className="extra-buttons">
          <ViewCopyButton
            insightId={currentItem.item.insightId}
            metadata={currentItem.item.metadata}
            analytics={analytics}
            title={currentItem.item.title}
            id={currentItem.item.insightId}
            page="overview"
            insightType={currentItem.item.type}
          />
          <ViewHeaderChat
            currentInsightURL={`/intelligence/versions/${currentItem.item.app_id}/${currentItem.item.version_id || currentItem.item.release_id}/${currentItem.item.insightId}`}
            insightId={currentItem.item.insightId}
            metadata={currentItem.item.metadata}
            analytics={analytics}
            title={currentItem.item.title}
            email={user.email}
            page="overview"
          />
        </div>}
      </Col>
    </Row><Row type="flex" className="sneak-peak-modal__body">
      <Col className="insight-info">

        <ViewHeader
          data={currentItem.item}
          app={currentItem.item.metadata}
          analytics={analytics}
          isMarketingDiscovery={isMarketingDiscovery}
          demoUser={{}}
          siteVersion={siteVersions.FULL}
          email={user.email}
          isSneakPeak={true}
          ABTestData={currentItem.item.ab_test_id ? ABTestData.find(d => d.id === currentItem.item.ab_test_id) : null}
          disRatingIsEnable={disRatingIsEnable}
        />


        <div className={'tags-wrapper'}>
          {currentItem.item.persona_roles && currentItem.item.persona_roles.length > 0 && <div className="persona-roles">
            <span className="view__header-item-header">
                Personas
            </span>
            <span className="view__header-item-data">
              {currentItem.item.persona_roles.map(role => <div className="view__header-item">{role}</div>)}
            </span>
          </div>}
          {currentItem.item.labels && currentItem.item.labels.length > 0 && (<div className={'tags-wrapper-field'} style={currentItem.item.persona_roles && currentItem.item.persona_roles.length === 0 ? {'gridColumn': '1 / 4'} : {}}>
            <h3 className="view__header-item-header">Tags</h3>
            <ViewTags tags={[...currentItem.item.labels, currentItem.item.relevancy > 0 ? "Critical Insight" : null]} />
          </div>)
          }

        </div>
        <ViewOverview longText={currentItem.item.description}/>
        {imageModalType && imageModalType === "landscape" && <div className="insight-cover-landscape">
          <Img src={currentItem.item.thumbnail_original}/>
          {currentItem.item.images_cnt > 1 && <div className="more-screenshots">
            <strong>{currentItem.item.images_cnt - 1}</strong> more available screenshots
          </div>}
        </div>}

        {biWeekly ? (user.email ?
          <div className="link-wrapper">
            <Link
              to={insightLink}
              target="_blank"
              rel="noopener noreferrer"
            >
                View Full Discovery
            </Link>
          </div>
          : null) :
          <Button className={`view-insight-btn ${currentItem.item.thumbnail_original ? '' : 'centered'}`} type="default"
            onClick={closeModalAndRemoveViewedInsights}>
            <Link
              to={{pathname: insightLink,  search:'source=sneak_peak', state: {prevPath: window.location.href}}}
            >
            View Full Discovery
              <span className="btn-arrow">
                <img src={blackArrowSlider} className="black-arrow" alt=""/>
                <img src={whiteArrowSlider} className="white-arrow" alt=""/>
              </span>
            </Link>
          </Button>}
      </Col>
      {imageModalType && imageModalType === "portrait" &&
    <Col className="insight-cover">
      <div className="insight-cover-portrait">
        <Img src={currentItem.item.thumbnail_original}/>
        {currentItem.item.images_cnt > 1 && <div className="more-screenshots">
          <strong>{currentItem.item.images_cnt - 1}</strong> more available screenshots
        </div>}
      </div>
    </Col>}
    </Row>
  </div>
};

export default InsightPreview;