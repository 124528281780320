import * as actionTypes from "./_actionsTypes";

export const askInsightQuestion = ({
  appName,
  insightID,
  insightURL,
  userEmail,
  question
}) => ({
  type: actionTypes.ASK_INSIGHT_QUESTION,
  payload: {
    appName,
    insightID,
    insightURL,
    userEmail,
    question
  }
});


export const getAppsMetadata = user => ({
  type: actionTypes.GET_APPS_METADATA,
  payload: {
    user
  }
});

export const getAppsMetadataSuccess = ({ apps }) => ({
  type: actionTypes.GET_APPS_METADATA_SUCCESS,
  payload: {
    apps
  }
});

export const getAppsMetadataFailed = () => ({
  type: actionTypes.GET_APPS_METADATA_FAILED
});

export const getAppData = ({ app_id, loadOnly }) => ({
  type: actionTypes.GET_APP_DATA,
  payload: {
    app_id,
    loadOnly
  }
});

export const getAppDataSuccess = ({ data, loadOnly }) => ({
  type: actionTypes.GET_APP_DATA_SUCCESS,
  payload: {
    data,
    loadOnly
  }
});

export const getAppDataFailed = () => ({
  type: actionTypes.GET_APP_DATA_FAILED
});

export const setAppsData = (apps, opt) => ({
  type: actionTypes.SET_APPS_DATA,
  payload: {
    apps,
    demo: opt.demo
  }
});

export const likeInsight = ({ insightID }) => ({
  type: actionTypes.LIKE_INSIGHT,
  payload: {
    insightID
  }
});

export const unlikeInsight = ({ insightID }) => ({
  type: actionTypes.UNLIKE_INSIGHT,
  payload: {
    insightID
  }
});

export const insightLikeInteractionSuccess = (data) => ({
  type: actionTypes.INSIGHT_LIKE_INTERACTION_SUCCESS,
  payload: data
});

export const setInsightAsViewed = insightID => ({
  type: actionTypes.SET_VIEWED_STATUS,
  payload: {
    insightID,
    readAt: new Date().toISOString()
  }
});

export const setInsightAsViewedSuccess = ({ insightID, readAt }) => ({
  type: actionTypes.SET_VIEWED_STATUS_SUCCESS,
  payload: {
    insightID,
    readAt
  }
});

export const setInsightAsSeen = insightIDs => ({
  type: actionTypes.SET_SEEN_STATUS,
  payload: {
    insightIDs,
    readAt: new Date().toISOString()
  }
});

export const setInsightAsSeenSuccess = ({ insightID, readAt }) => ({
  type: actionTypes.SET_SEEN_STATUS_SUCCESS,
  payload: {
    insightID,
    readAt
  }
});

export const setSiteVersion = siteVersion => ({
  type: actionTypes.SET_SITE_VERSION,
  payload: { siteVersion }
});

export const initDashboard = () => {
  return {
    type: actionTypes.INIT_DASHBOARD
  };
};

export const initAppData = () => {
  return {
    type: actionTypes.INIT_APP_DATA
  };
};

export const setUser = user => ({
  type: actionTypes.SET_USER,
  payload: {
    user
  }
});

export const setImpersonateUser = impersonateUser => ({
  type: actionTypes.SET_IMPERSONATE_USER,
  payload: {
    impersonateUser
  }
});

export const setMainLoader = status => ({
  type: actionTypes.SET_MAIN_LOADER,
  payload: {
    status
  }
});

export const getAllAppsData = user => ({
  type: actionTypes.GET_ALL_APPS_DATA,
  payload: user
});

export const setSearchFilterByApp = id => ({
  type: actionTypes.SEARCH_APP_FILTER,
  payload: id
});

export const getMarketingCampaigns = data => ({
  type: actionTypes.GET_MARKETING_CAMPAIGNS,
  payload: data || {}
});

export const loaderAddRequest = request => ({
  type: actionTypes.CURRENT_REQUESTS_ADD,
  payload: request
});

export const loaderRemoveRequest = request => ({
  type: actionTypes.CURRENT_REQUESTS_REMOVE,
  payload: request
});

export const clearFilterByApp = () => ({ type: actionTypes.CLEAR_APP_FILTER });


export const getDiscoveryData = data => ({
  type: actionTypes.GET_DISCOVERY_DATA,
  payload: data 
});

export const getDiscoveryDataSuccess = data => ({
  type: actionTypes.GET_DISCOVERY_DATA_SUCCESS,
  payload: data 
});

export const createNewUser = () => ({
  type: actionTypes.CREATE_NEW_USER,
});

export const changePasswordFirstLogin = (data) => ({
  type: actionTypes.CHANGE_PASSWORD_ON_FIRST_LOGIN,
  payload: data,
});

export const sendNewChangePWDMail = (data) => ({
  type: actionTypes.SEND_NEW_CHANGE_PWD_MAIL,
  payload: data,
});

export const checkNonceExpired = (data) => ({
  type: actionTypes.CHECK_NONCE_EXPIRED,
  payload: data,
});

export const setSharedInsightData = data => ({
  type: actionTypes.SET_SHARED_INSIGHT_DATA,
  payload: data,
})