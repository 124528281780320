import React, { useState, useEffect } from 'react'
import { Bar } from "react-chartjs-2";
import moment from 'moment'

import ViewExperimentArrowSlick from "../discoveryView/viewExperiment/viewExperimentArrowSlick/ViewExperimentArrowSlick";


const countOnPage = 10

export default function StackedBar({ data, product, handleClick }) {
  const [currentPage, currentPageChange] = useState(data.labels.length - countOnPage > 0 ? data.labels.length - countOnPage : 0)
  const [slicedDateset, slicedDatesetChange] = useState({})

  useEffect(() => {
    let modData = {
      labels: data.labels.slice(currentPage, currentPage + countOnPage),
      datasets: data.datasets.map(dset => ({ ...dset, data: dset.data.slice(currentPage, currentPage + countOnPage) }))
    }
    slicedDatesetChange(modData)
  }, [data, currentPage])

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: 'bottom',
      labels: {
        boxWidth: 8,
        usePointStyle: true,
        padding: 40,
        fontSize: 10,
        fontWeight: 600
      }
    },
    onClick: (evt, element) => {
      if (element.length > 0) {
        let labelData = slicedDateset.labels[element, element[0]._index]
        let year = labelData[1],
          month = labelData[0].split(' ')[0],
          day = labelData[0].split(' ')[1]
        let firstDate = moment().year(year).month(month).date(day),
          secondDate = moment().year(year).month(month).date(day).add(6, 'days')// BE will fetch data from this date + 6 days = 7 days
        let url = `/all-discoveries?customRange=1&product_ids=${product.id}&customRangeTime=${firstDate.format('YYYY-MM-DD')}%2C${secondDate.format('YYYY-MM-DD')}`
        setTimeout(() => {
          document.body.removeChild(document.getElementById('chartjs-tooltip'))
        }, 350)
        handleClick(url)
        //fix bug with tooltip
        if (document.getElementById('chartjs-tooltip')) {
          document.getElementById('chartjs-tooltip').style.display = 'none'
        }
      }
    },
    scales: {
      yAxes: [
        {
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: "Discoveries Found",
            fontColor: "#000000",
            fontSize: 12,
            fontFamily: 'Roboto'
          },
          ticks: {
            beginAtZero: true,
            fontColor: '#9AA1A9',
            fontSize: 11,
            padding: 25,
            stepSize: 5

          },
        },
      ],
      xAxes: [
        {
          //display: false,
          gridLines: {
            drawOnChartArea: false,
            drawBorder: true,
          },
          stacked: true,
          ticks: {
            fontColor: '#9AA1A9',
            fontSize: 11
          }
        },
      ],
    },
    tooltips: {
      // Disable the on-canvas tooltip
      enabled: false,
      mode: 'index',
      position: 'nearest',
      custom: function (tooltipModel) {
        // Tooltip Element
        var tooltipEl = document.getElementById('chartjs-tooltip');

        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.id = 'chartjs-tooltip';
          tooltipEl.classList.add('chartjs-tooltip-product');
          tooltipEl.innerHTML = '<table></table>';
          document.body.appendChild(tooltipEl);
        }

        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }

        // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add('no-transform');
        }

        function getBody(bodyItem) {
          return bodyItem.lines;
        }

        // Set Text
        if (tooltipModel.body) {
          var titleLines = tooltipModel.title || [];
          var bodyLines = tooltipModel.body.map(getBody);

          var innerHtml = '<thead>';

          const titleWithApp = `<img src=${product.thumbnail} /> <div class="app-data"> <h4> ${product.name}</h4> <div>${titleLines[0]}, ${titleLines[1]} </div></div>`
          innerHtml += '<tr><th>' + titleWithApp + '</th></tr>';
          innerHtml += '</thead><tbody>';

          bodyLines.forEach(function (body, i) {
            if (Number(tooltipModel.dataPoints[i].value) === 0) { return }
            var colors = tooltipModel.labelColors[i];
            var style = 'background:' + colors.backgroundColor;
            style += '; border-color:' + colors.borderColor;
            style += '; border-width: 2px';
            var span = '<span style="' + style + '"></span>';
            innerHtml = '<tr><td>' + span + body + '</td></tr>' + innerHtml;
          });
          innerHtml += '</tbody>';

          var tableRoot = tooltipEl.querySelector('table');
          tableRoot.innerHTML = innerHtml;
        }

        // `this` will be the overall tooltip
        var position = this._chart.canvas.getBoundingClientRect();

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
        tooltipEl.style.pointerEvents = 'none';
      }
    }
  }

  return (
    <div className="stacked-bar-container">
      <h3>Discovery Radar - {product.name}</h3>
      {currentPage > 0 &&
        <ViewExperimentArrowSlick page="product-chart" direction="prev" className="product-chart"
          onClick={() => currentPageChange(currentPage - countOnPage > 0 ? currentPage - countOnPage : 0)} />
      }
      {currentPage + countOnPage < data.labels.length &&
        <ViewExperimentArrowSlick page="product-chart" direction="next" className="product-chart"
          onClick={() => currentPageChange(currentPage + countOnPage < data.labels.length - countOnPage ? currentPage + countOnPage : data.labels.length - countOnPage)} />
      }
      <div className="stacked-bar-wrap" >
        <Bar
          data={slicedDateset}
          options={options}
          height={365}
          width={811}
        />
      </div>
    </div>
  )
}
