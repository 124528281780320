import { Tooltip } from "antd";
import clsx from "clsx";
import moment from "moment";
import React from "react";
import ios_ico from "assets/images/icons/newIntel/ios.svg";
import android_ico from "assets/images/icons/newIntel/android.svg";
import web_ico from "assets/images/icons/newIntel/web.svg";
import { INTEL_INSIGHT_TYPES_PROPS } from "pages/Intel/utils/consts";
import {
  copyToClipboard,
  getPersonaTitle,
  getPriority,
} from "containers/MarketingCampaings/utils/helpers";
import { countries } from "shared/countries";
import wwf from "assets/images/world-wide-flag.svg";
import copy_ico from "assets/images/icons/copy.svg";
import { UserType } from "containers/MarketingCampaings/utils/types";
import { ReactComponent as InfoIco } from "assets/images/icons/info-new-dark.svg";
import { isMobileDevice } from "containers/MarketingOverview/utils/helpers";

const PLATFORM_TO_IMAGE = {
  ios: ios_ico,
  android: android_ico,
  web: web_ico,
};

const PRIORITY_TO_LABEL = {
  low: "Low",
  medium: "Medium",
  high: "High",
  very_high: "Very High",
};

type Props = {
  appIcon: string;
  platform: string;
  releaseName?: string;
  type: string;
  date: string;
  title: string;
  insightId: number;
  abTestData: {
    status: string;
    insights: any[];
    id: number;
  };
  priority: string;
  labels: string[];
  personaLocations?: any[];
  focusArea: string;
  insightDescription: string;
  /* Temporary disable user types filter SQUAD2-1329 */
  // userTypes: UserType[];
  // userTypesSeen: UserType[];
};

const allCountries = [
  {
    name: "World Wide",
    code: "WW",
  },
  ...countries,
];

export const ModalInfo = ({
  appIcon,
  platform,
  releaseName,
  type,
  date,
  title,
  abTestData,
  insightId,
  priority,
  labels,
  personaLocations,
  focusArea,
  insightDescription,
  /* Temporary disable user types filter SQUAD2-1329 */
  // userTypes,
  // userTypesSeen,
}: Props) => {
  const isMobile = isMobileDevice();
  const typeProps = INTEL_INSIGHT_TYPES_PROPS[type];
  /* Temporary disable user types filter SQUAD2-1329 */
  // const isAllUserTypesSeen = userTypesSeen.length === userTypes.length;
  // const userTypesSeenWithColors = userTypesSeen.map((userType) => ({
  //   ...userType,
  //   textColor: userTypes.find((ut) => ut.id === userType.id)?.textColor,
  //   bgColor: userTypes.find((ut) => ut.id === userType.id)?.bgColor,
  // }));
  // const userTypesUnseen = userTypes.filter(
  //   (userType) => !userTypesSeen.find((seen) => seen.id === userType.id)
  // );

  let abTestStepDiff = "";

  if (abTestData) {
    let index = abTestData.insights.findIndex((ins) => ins.id === insightId);
    if (index === -1) {
      abTestStepDiff = moment().diff(moment(date), "days") + " days";
    } else if (abTestData.status === "active") {
      abTestStepDiff =
        moment().diff(moment(abTestData.insights[0].start_time), "days") +
        " days";
    } else if (
      abTestData.status === "integrated" &&
      abTestData.insights[0].start_time ===
        abTestData.insights[abTestData.insights.length - 1].start_time
    ) {
      // if test is marked as integrated but the first and the last start_time are the same ->
      // analyst marked the test as integrated on the same day which is an analyst mistake and we show it as N/A
      abTestStepDiff = "N/A";
    } else if (
      abTestData.insights.filter(
        (ins) => ins.type === "ab_test" || ins.type === "indication"
      )
    ) {
      abTestStepDiff =
        moment(
          abTestData.insights[abTestData.insights.length - 1].start_time
        ).diff(
          moment(
            abTestData.insights.filter(
              (ins) => ins.type === "ab_test" || ins.type === "indication"
            )[0].start_time
          ),
          "days"
        ) + " days";
    }
  }

  const handleCopy = (text: string) => () => {
    copyToClipboard(text);
  };

  const renderUserTypes = (
    title: string,
    isSeenInAll: boolean,
    userTypes: UserType[],
    tooltipText?: string
  ) => (
    <div className="ipm-preview-content-box">
      <div className="ipm-preview-content-box-head">
        <div className={isSeenInAll ? "ipm-seen-in-all" : ""}>{title}</div>
        <Tooltip
          overlayClassName="info-header-tooltip"
          placement="top"
          title={tooltipText}
        >
          <InfoIco />
        </Tooltip>
      </div>
      <div className="ipm-preview-content-box-body">
        {userTypes.map((userType) => (
          <div
            className="ipm-preview-content-box-user-type-container"
            style={{ backgroundColor: userType.bgColor }}
            key={userType.id}
          >
            <div
              className="ipm-preview-content-box-user-type-text"
              style={{ color: userType.textColor }}
            >
              {getPersonaTitle(userType)}
            </div>
            <div className="ipm-preview-content-box-user-type-icon-container">
              <Tooltip
                overlayClassName="info-header-tooltip"
                placement="top"
                title={userType?.description || "No description"}
              >
                <InfoIco fill={userType.textColor} />
              </Tooltip>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <>
      {!isMobile && (
        <>
          <div className="ipm-preview-left-top">
            <img src={appIcon} />
            <img src={(PLATFORM_TO_IMAGE as any)[platform.toLowerCase()]} />
            {!isMobile && (
              <div className="ipm-release-name">Release: {releaseName}</div>
            )}
            <div
              className="ipm-type"
              style={{
                color: typeProps?.textColor,
                backgroundColor: typeProps?.bgColor,
              }}
            >
              {typeProps?.label}
            </div>
            <h5 className="ipm-preview-date">
              {moment(date).format("MMM D, YYYY")}
            </h5>
          </div>

          <h3 className="ipm-preview-title">{title}</h3>
        </>
      )}
      <div className="ipm-priority">
        {abTestStepDiff && (
          <div className="ipm-ab-test-info">
            <div
              className={clsx("ab-test-status", {
                [abTestData.status]: true,
              })}
            >
              {abTestData.status}
            </div>
            <div className="tracked-since">
              Duration - <span>{abTestStepDiff}</span>
            </div>
          </div>
        )}
        <div className={`priority-${getPriority(priority)}`}>
          {(PRIORITY_TO_LABEL as any)[priority]} Priority
        </div>
      </div>
      <div className="ipm-preview-list">
        <div className="ipm-preview-list-head">Tags</div>
        {labels.map((label) => (
          <div className="ipm-preview-list-item" key={label}>
            {label}
          </div>
        ))}
      </div>
      {personaLocations && personaLocations.length > 0 && (
        <div className="ipm-preview-list">
          <div className="ipm-preview-list-head">Geo</div>
          <div className="countries">
            {[...new Set(personaLocations)].map((loc, i) => (
              <Tooltip
                title={`${
                  allCountries.find((c) => c.code === loc.toUpperCase())?.name
                }`}
                placement={"top"}
                key={i}
              >
                <div className="country">
                  {loc === "ww" ? (
                    <img src={wwf} />
                  ) : (
                    <span
                      className={`flag-icon flag-icon-${loc.toLowerCase()}`}
                    />
                  )}
                </div>
              </Tooltip>
            ))}
          </div>
        </div>
      )}
      {focusArea && (
        <div className="ipm-preview-list s-mt">
          <div className="ipm-preview-list-head">Focus Area</div>
          <div className="ipm-preview-list-item">{focusArea}</div>
        </div>
      )}
      {insightDescription && (
        <div className="ipm-preview-content-box">
          <div className="ipm-preview-content-box-head">
            <div>Insights</div>
            <Tooltip
              overlayClassName="info-header-tooltip"
              trigger="click"
              title="Copied to clipboard..."
            >
              <img src={copy_ico} onClick={handleCopy(insightDescription)} />
            </Tooltip>
          </div>
          <p className="ipm-preview-content-box-body">{insightDescription}</p>
        </div>
      )}
      {/* Temporary disable user types filter SQUAD2-1329 */}
      {/* {isAllUserTypesSeen ? (
        renderUserTypes(
          "Seen In All User Types",
          true,
          userTypesSeenWithColors,
          "All user types who received the campaign."
        )
      ) : (
        <div className="ipm-preview-double-box-container">
          {renderUserTypes(
            "User Types Seen",
            false,
            userTypesSeenWithColors,
            "All user types who received the campaign."
          )}
          {renderUserTypes(
            "User Types Unseen",
            false,
            userTypesUnseen,
            "All user types who did not receive the campaign."
          )}
        </div>
      )} */}
    </>
  );
};
