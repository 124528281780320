import React from 'react'

import { Input } from 'antd';
import {SearchOutlined} from "@ant-design/icons";

export default function FilterSearchQuery({
  searchQuery,
  searchQueryChange,
  placeholder
}) {

  return <div className="filter-by-content">
    <Input prefix={<SearchOutlined />} value={searchQuery}
      placeholder={placeholder || "Search for a specific discovery within these filters"}
      allowClear={true}
      onChange={e => {
        searchQueryChange(e.target.value);
      }} />
  </div>
}

