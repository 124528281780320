import {createSelector} from "reselect";
import {getNumberOfInsights} from "./InsightsForProduct";
import { IState, Product } from "../../interfaces/Product";



const getProducts = (state: IState): Product => state.overview.products;
const getUnSeenCriticalInsights = (state: IState) => state.overview.unSeenCriticalInsights;


export const getSortedProducts = createSelector(
  [ getProducts, getUnSeenCriticalInsights ],
  (products: Product[], unSeenCriticalInsights): Product[] => {
    if (products && unSeenCriticalInsights) {

      products.sort((productA: Product, productB: Product): number => {
        return getNumberOfInsights(productB, unSeenCriticalInsights) - getNumberOfInsights(productA, unSeenCriticalInsights)
      })
    }
    return products
  }
);