import { isNaN } from "lodash";
import { CAMPAIGNS_FILTERS, PRIORITY, TYPE_TO_CHANNEL } from "./conts";
import { UserType } from "./types";

export function generateBrightTransparentColor(alpha: number) {
  alpha = Math.max(0, Math.min(1, alpha));

  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);

  const rgbaColor = `rgba(${r}, ${g}, ${b}, ${alpha})`;

  return rgbaColor;
}

export function darkenColor(rgbaColor: string, factor = 0.6) {
  const rgbaValues: any = rgbaColor.match(/[\d.]+/g)?.map(Number);

  factor = Math.max(0, Math.min(factor, 1));

  const darkenedValues = rgbaValues
    .slice(0, 3)
    .map((value: any) => Math.round(value * (1 - factor)));

  darkenedValues.push(rgbaValues[3]);
  darkenedValues[3] = "0.8";
  const darkenedColor = `rgba(${darkenedValues.join(", ")})`;

  return darkenedColor;
}

export function copyToClipboard(text: string) {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text);
  } else {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.style.position = "fixed";
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  }
}

export function getShortenedString(str: string, maxLen: number) {
  if (typeof str !== "string") return str;

  if (str.length > maxLen) {
    return str.slice(0, maxLen).concat("...");
  }

  return str;
}

export function getPriority(priority: string) {
  if (priority.includes(PRIORITY.high)) {
    return PRIORITY.high;
  }
  if (priority.includes(PRIORITY.medium)) {
    return PRIORITY.medium;
  }
  if (priority.includes(PRIORITY.low)) {
    return PRIORITY.low;
  }

  return PRIORITY.low;
}

export function getPersonaTitle(persona: UserType) {
  return Array.isArray(persona.roles) && persona.roles.length > 0
    ? persona.roles[0]?.name
    : persona.title;
}

export function transformOldMarketingUrlToNewMarketingUrl(
  oldUrl: string,
  oldSearch: string
) {
  const oldUrlSplit = oldUrl.split("/");
  const insightId = oldUrlSplit[2] || "";
  const oldSearchParams = new URLSearchParams(oldSearch);
  const appId = oldSearchParams.get("app") || "";
  const tab = parseInt(oldSearchParams.get("tab") || "1");
  const daysAgo = oldSearchParams.get("days_ago") || "";

  if (oldUrlSplit[1] !== "marketing" || !appId) return;
  if (insightId && isNaN(insightId)) return;

  const newSearchParams = new URLSearchParams();
  newSearchParams.set(CAMPAIGNS_FILTERS.app_id, appId);
  newSearchParams.set(CAMPAIGNS_FILTERS.insight_id, insightId);
  newSearchParams.set(CAMPAIGNS_FILTERS.days_ago, daysAgo);
  newSearchParams.set(
    CAMPAIGNS_FILTERS.channel,
    Object.keys(TYPE_TO_CHANNEL)[tab - 1]
  );

  return `/marketing/campaigns?${newSearchParams.toString()}`;
}
