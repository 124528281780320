import { Button, Checkbox, Col } from "antd";
import { FocusArea } from "containers/MarketingCampaings/utils/types";
import React from "react";
import "./FocusAreasFilter.scss";

type Props = {
  focusAreas: FocusArea[];
  selectedFocusAreas: string[];
  onSelectFocusArea: (focusAreasIds: string[]) => void;
};

export const FocusAreasFilter = ({
  focusAreas,
  onSelectFocusArea,
  selectedFocusAreas,
}: Props) => {
  const selectedFocusAreasNumbers = selectedFocusAreas.map((focusArea) =>
    parseInt(focusArea)
  );

  const handleSelectFocusAreas = (selectedFocusAreas: any[]) => {
    onSelectFocusArea(selectedFocusAreas.map((id) => id.toString()));
  };

  return (
    <div className="fa-popup">
      <div className="items-selected">
        <div className="items-selected-count">
          {`${selectedFocusAreas.length} Selected `}
        </div>
        {selectedFocusAreas.length !== 0 ? (
          <Button
            onClick={() => onSelectFocusArea([])}
            className="all ant-btn-dark"
          >
            Clear
          </Button>
        ) : (
          <Button
            onClick={() =>
              onSelectFocusArea(
                focusAreas.map((userType) => userType.id.toString())
              )
            }
            className="all ant-btn-dark"
          >
            All
          </Button>
        )}
      </div>
      <div className="filters-content">
        <Checkbox.Group
          onChange={handleSelectFocusAreas}
          value={selectedFocusAreasNumbers}
        >
          <div className="filters-content-checkbox-wrapper">
            {focusAreas.map((focusArea) => (
              <Col>
                <Checkbox
                  className="fa-popup-checkbox-group-item"
                  value={focusArea.id}
                >
                  <span className="fa-popup-checkbox-group-tem-label-text">
                    {focusArea.name}
                  </span>
                </Checkbox>
              </Col>
            ))}
          </div>
        </Checkbox.Group>
      </div>
    </div>
  );
};
