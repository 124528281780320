import React, { Component, useState } from "react";

import { Tabs, Tooltip, Button } from 'antd';
import Slider from "react-slick";
import Modal from "react-modal";
import { isEmpty } from "lodash";

import ViewHeader from "./viewHeader/ViewHeader";
import ViewOverview from "./viewOverview/ViewOverview";
import PricingIframe from "../pricing/pricingIframe/PricingIframe";
import ViewExperimentTabs from "./viewExperimentTabs/viewExperimentTabs";
import RelatedDiscovery from "./relatedDiscovery/RelatedDiscovery"
import SuggestionValidationEmail from "./SuggestionValidationEmail/SuggestionValidationEmail"
import DisCommentsList from "./disComments/DisCommentsList"

import ReactJson from "react-json-view";
import ViewTags from "./viewTags/ViewTags";
import AppHeader from "./AppHeader"

import SneakPeakInsightModal from "../sneakPeakDiscoveryModal/SneakPeakInsightModal"

import ViewExperimentImage from './viewExperiment/viewExperimentImage/ViewExperimentImage';
import ViewExperimentText from './viewExperiment/viewExperimentText/ViewExperimentText'
import ViewExperimentArrowSlick from "./viewExperiment/viewExperimentArrowSlick/ViewExperimentArrowSlick";
import ABTestTimeLine from "./viewABTestTimeLine/ABTestTimeLine"

import ModalBroadwayTheater from "./ModalBroadwayTheater/ModalBroadwayTheater"


import CampaignTimeline from "./CampaignTimeline"
import { blackArrow, userTriggeredFlow } from './DiscoveryViewWidgets'
import {intelDiscoveryTypeDictionary, PL_MARKETING} from "../../shared/consts"
import NewLoader from "../../common/NewLoader/NewLoader";
import moment from "moment"

import close_modal_ico from "../../assets/images/icons/close_modal.svg"
import { useIsFeatureByUserID } from "../../helpers/features"
import {MARKETING} from "../../containers/intelligence/Marketing/configsSubtypes";


const { TabPane } = Tabs;

const slickSettings = {
  dots: true,
  speed: 500,
  infinite: false,
  slidesToScroll: 1,
  adaptiveHeight: true,
  nextArrow: <ViewExperimentArrowSlick direction="next" page="marketing" />,
  prevArrow: <ViewExperimentArrowSlick direction="prv" page="marketing" />
};


const slickGallerySettings = {
  speed: 500,
  infinite: false,
  slidesToScroll: 1,
  dots: true,
  adaptiveHeight: true,
  nextArrow: <ViewExperimentArrowSlick direction="next" page="image-gallery" />,
  prevArrow: <ViewExperimentArrowSlick direction="prv" page="image-gallery" />
};

const DiscoveryView = (props) => {
  const url = new URLSearchParams(props.history.location.search)
  const [currentItemModal, currentItemModalChange] = useState(null)
  const [visibilitySneakPeak, visibilitySneakPeakChange] = useState(false)

  const [modalIsOpen, changeModalIsOpen] = useState(false);
  const [modalImagesUrl, modalImagesUrlChange] = useState('')
  const [modalInitSlide, modalInitSlideChange] = useState(0)
  const [source, sourceChange] = useState(url.get('source') || 'direct_access');

  const { user, analytics, discovery, app, demoMode, htmls,
    siteVersion, demoUser, clickBack, relatedInsights,
    marketingPrevNextList, isAdmin, reachRate, ABTestData, history, insightLinkCode, guest } = props;

  const disRatingIsEnable = useIsFeatureByUserID('discovery_rating', user.email)

  const openImage = (imgs, index) => {
    modalInitSlideChange(index)
    modalImagesUrlChange(imgs)
    changeModalIsOpen(true)
  }

  const renderSliderItem = (item, index, experimentType, assets) => {
    const { status } = props;
    return (
      <div className='view__experiment-images__slider-container' key={index}>
        <ViewExperimentImage
          onClick={() => openImage(assets.map(img => img.url_annotated), index)}
          imageSrc={item.url_annotated}
          videoSrc={item.url_annotated}
          type={item.asset_type}
          status={status}
          experimentType={experimentType}
        />
        <ViewExperimentText text={item.text} />
      </div>
    );
  }

  const renderJsonView = (jsonData, shouldCollapseAll) => {
    const shouldCollapse = field => {
      if (shouldCollapseAll) {
        if (field.name === "root") {
          return true;
        }
        return false;
      }
      return true;
    };
    if (!isEmpty(jsonData)) {
      return <ReactJson src={jsonData} shouldCollapse={shouldCollapse} />;
    } else if (discovery && !discovery.ab_test_id) {
      return <div>No ab test ID</div>;
    }
    return <div>No ab test data</div>;
  };

  if (!discovery) {
    return null;
  }

  const isMarketingDiscovery = discovery.platform === PL_MARKETING;

  const assets = discovery.assets;
  const version = props.version || null

  return <div className={`discovery-view ${isMarketingDiscovery ? 'is-marketing' : 'is-usual'}`}>
    <section className="view">
      <AppHeader
        app={app}
        demoMode={demoMode}
        clickBack={clickBack}
        isMarketingDiscovery={isMarketingDiscovery}
        version={version}
        isDraft={discovery.is_draft}
      />
      <div className="hr-row" />
      {(!discovery.fullLoaded || props.forceLoader) && <div className="discovery-loader"><NewLoader /></div>}

      <div className="discovery-view-content">
        <div className="view-left-side">

          <ViewHeader
            data={discovery}
            app={app}
            version={version}
            analytics={analytics}
            siteVersion={siteVersion}
            demoUser={demoUser}
            email={user.email}
            isMarketingDiscovery={isMarketingDiscovery}
            marketingPrevNextList={marketingPrevNextList}
            ABTestData={ABTestData}
            product={props.product}
            disRatingIsEnable={disRatingIsEnable}
            source={source}
          />
          <div className={'tags-wrapper'}>
            {discovery.persona_roles && discovery.persona_roles.length > 0 && <div className="persona-roles">
              <span className="view__header-item-header">
                Personas
              </span>
              <span className="view__header-item-data">
                {discovery.persona_roles.sort((p1, p2) => p1 < p2 ? -1 : 1 ).map(role => <div className="view__header-item"><div className={"persona-role-item"}>{role}</div></div>)}
              </span>
            </div>}
            {discovery.labels && discovery.labels.length > 0 && (<div className={'tags-wrapper-field'} style={discovery.persona_roles && discovery.persona_roles.length === 0 ? {'gridColumn': '1 / 4'} : {}}>
              <h3 className="view__header-item-header">Tags</h3>
              <ViewTags tags={[...discovery.labels, discovery.relevancy > 0 ? "Critical Insight" : null]} />
            </div>)
            }

          </div>

          {discovery.ab_test_id && ABTestData && <ABTestTimeLine
            timeline={ABTestData}
            history={history}
            app={app}
            currentDisID={discovery.id}
          />}
          <div className="assets-data-wrapper">
            <ViewOverview longText={discovery.description} />
            {demoMode && !guest && discovery.type === "indication" && <SuggestionValidationEmail
              discovery={discovery}
              app={app}
              demoUser={demoUser}
              analytics={analytics}
              insightLinkCode={insightLinkCode}
            />}
          </div>



          {isMarketingDiscovery &&
            <h2 className="campaign-preview-title">Campaign Preview</h2>}

          {htmls && htmls.length > 1 &&
            <div className="email-preview" >
              <Tabs className="tabs" animated={{ inkBar: true, tabPane: false }} >
                {htmls.map((html, i) => (
                  <TabPane tab={`Email #${i + 1}`} key={i} >
                    {html.text && <h3 style={{ textAlign: 'center', marginBottom: '1rem' }}>{html.text}</h3>}
                    <iframe src={html.url_annotated} style={{ width: '100%', minHeight: '55rem', border: '1px solid silver' }}>
                    </iframe>
                  </TabPane>
                ))}
              </Tabs>
            </div>
          }

          {htmls && htmls.length === 1 &&
            <div className="email-preview" >
              {htmls[0].text && <h3 style={{ textAlign: 'center', marginBottom: '1rem' }}>{htmls[0].text}</h3>}
              <iframe src={htmls[0].url_annotated} style={{ width: '100%', minHeight: '55rem', border: '1px solid silver' }}>
              </iframe>
            </div>
          }


          {modalImagesUrl && isMarketingDiscovery && <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => changeModalIsOpen(false)}
            className="modal"
            overlayClassName="modal__overlay modal__overlay_gallery marketing"
          >
            <img src={close_modal_ico} className="close-image-gallery" onClick={() => changeModalIsOpen(false)} />
            <div className="gallery-discovery-descr">
              <div className="gallery-discovery-descr-content">
                <h1>{discovery.title}</h1>
                <div className="gallery-discovery-descr-text">{discovery.description}</div>
                <div className="gallery-discovery-descr-data">
                  <div className="gallery-discovery-descr-data-type">
                    Type:
                    <strong>{intelDiscoveryTypeDictionary[
                      discovery.type
                    ]}</strong>
                  </div>
                  |
                  <div className="gallery-discovery-descr-data-date">
                    Date:
                    <strong>{moment(discovery.start_time).format('ll')}</strong>
                  </div>
                </div>
                <ViewTags tags={[...(discovery.labels || []), discovery.relevancy > 0 ? "Critical Insight" : null]} />

              </div>
            </div>


            <div className="slick-image-slider-wrapper">
              <Slider {...slickGallerySettings} key={`gallery-insight`} initialSlide={modalInitSlide || 0}>
                {modalImagesUrl.map((imgSrc, i) => (<>
                  <img
                    src={imgSrc}
                    alt="experiment-image"
                    className="view__experiment__modal-image"
                  />
                </>))}
              </Slider>
            </div>

          </Modal>}

          {isMarketingDiscovery && discovery.fullLoaded && (!htmls || htmls.length === 0) && assets && assets.length > 0 && <div style={{ width: '100%', margin: 'auto' }}>
            <Slider {...slickSettings}>
              {assets.filter(item => (discovery.thumbnail !== item.url_annotated) || htmls.length === 0).
                map((item, key) => renderSliderItem(item, key, null, assets))}
            </Slider>
          </div>}


          {discovery.iframes && <PricingIframe iframes={discovery.iframes} />}

          {!isMarketingDiscovery && discovery.fullLoaded && <div className="assets-data-wrapper">
            <ViewExperimentTabs
              insight={discovery}
              type={discovery.type}
              reachRate={reachRate}
              ABTestData={ABTestData}
              app={app}
              key={discovery.id}
              isBlurred={demoMode && !guest && discovery.type !== "indication"}
            />
            {demoMode && !guest && discovery.type !== "indication" && <SuggestionValidationEmail
              discovery={discovery}
              app={app}
              demoUser={demoUser}
              analytics={analytics}
              insightLinkCode={insightLinkCode}
            />}
            {demoMode && !guest && discovery.type === "indication" && <div className="blurry-back" />}
          </div>

          }

          {isAdmin &&
            user &&
            user.email !== "itay@watchful.ai" && (
            <div>
                Discovery JSON:
              <div>{renderJsonView(discovery, true)}</div>
            </div>
          )}
        </div>
        {discovery.fullLoaded && !demoMode &&
          <div className="view-right-side">
            <DisCommentsList
              insightID={discovery.id}
              comments={discovery.comments}
              userData={props.userData}
              postUserDiscoveryComment={props.postUserDiscoveryComment}
              editUserDiscoveryComment={props.editUserDiscoveryComment}
              deleteUserDiscoveryComment={props.deleteUserDiscoveryComment}
              analytics={analytics}
              discovery={discovery}
              app={app}
              product={props.product}
            />

            {discovery.timeline && discovery.timeline.length > 0 && userTriggeredFlow()}
            {isMarketingDiscovery && <CampaignTimeline dates={discovery.timeline || []} />}
            {relatedInsights && relatedInsights.length > 0 && !demoMode &&
              <div className="related-wrap">
                <h2>Related Discoveries</h2>
                <div className="extra-text">
                  View discoveries that are similar to this discovery
                </div>
                {relatedInsights && relatedInsights.filter(rel => rel && rel.discovery && rel.app).map((rel, i) => 
                  <RelatedDiscovery
                    discovery={rel.discovery}
                    app={rel.app}
                    isMarketingDiscovery={isMarketingDiscovery}
                    onClick={(discovery) => {
                      props.relatedCallback(discovery.item.insightId)
                      currentItemModalChange(discovery);
                      visibilitySneakPeakChange(true);
                      sourceChange('related_discovery') }} />)
                }
              </div>
            }
          </div>}
      </div>
      {currentItemModal &&
        <SneakPeakInsightModal
          closeModalAndRemoveViewedInsights={() => visibilitySneakPeakChange(false)}
          currentItem={currentItemModal}
          insights={[currentItemModal]}
          visibility={visibilitySneakPeak}
          analytics={analytics}
          user={user}
          isMarketingDiscovery={isMarketingDiscovery}
          source={source}
        />
      }
    </section>
  </div>
}

export default DiscoveryView;