import React, { useState, useEffect, FC, ReactElement } from 'react'
import { Modal, Button, Checkbox, Input, Popover } from 'antd';
import { LICENCES_DATA, L_BENCHMARK } from "../../../shared/consts"
import { ILabel } from "../../../interfaces/Label"
import { ISubscription } from "../../../interfaces/Subscription"
import { Product } from "../../../interfaces/Product"
import AppDrawer from "../../../common/appDrawer/AppDrawer"
import FilterTags from "../../../components/FilterTags/index"
import { MATCH_TYPES } from "../../intelligence/Marketing/FilterTypes";
import analyticsService from "../../../services/analyticsService";
import "./SubscriptionsModal.scss"

type SubscriptionsProps = {
  tagsList: ILabel[],
  products: Product[],
  licences: any,
  createSubscription: (data: any) => void,
  editSubscription?: (data: any) => void,
  windowIsOpen: boolean,
  closeWindow: (args1: boolean) => void,
  subscription?: ISubscription | null,
  analytics: analyticsService,
}

const SubscriptionModal: FC<SubscriptionsProps> = ({
  tagsList,
  products,
  licences,
  windowIsOpen,
  closeWindow,
  subscription,
  analytics,
  editSubscription,
  createSubscription
}): ReactElement | null => {

  let ftags = subscription ? subscription.labels.map(l => String(l.id)) : []

  if (subscription && subscription.critical_insight) {
    ftags.unshift("-1")
  }

  const [modalShow, modalShowChange] = useState(windowIsOpen)
  const [errors, errorsChange] = useState<string[]>([])
  const [subscrName, subscrNameChange] = useState(subscription ? subscription.name : '')
  const [filterTags, filterTagsChange] = useState(subscription ? ftags : [])
  const [currentProducts, currentProductsChange] = useState(subscription ? subscription.products.map(l => l.id) : [])
  //@ts-ignore
  const [currentLicences, currentLicencesChange] = useState<string[]>(subscription ? Object.keys(licences).filter((l: string) => l !== L_BENCHMARK && subscription[l]) :
    Object.keys(licences).filter((l: string) => l !== L_BENCHMARK && licences[l]))
  const [filterTagsMatch, setFilterTagsMatch] = useState(subscription ?
    (subscription.tag_filter === 'any' ? MATCH_TYPES[0] : MATCH_TYPES[1])
    : MATCH_TYPES[0])

  useEffect(() => {
    modalShowChange(windowIsOpen)
  }, [windowIsOpen])

  const handleAppChange = (product: Product) => {
    //@ts-ignore
    if (currentProducts.indexOf(product.id) !== -1) {
      currentProductsChange(currentProducts.filter(ca => ca != product.id))
    } else {
      //@ts-ignore
      currentProductsChange([...currentProducts, product.id])
    }
  }

  const handleCheckboxChange = (e: any) => {
    let val = e.target.value,
      copyCurrentLicences = [...currentLicences];

    if (currentLicences.includes(val)) {
      currentLicencesChange(copyCurrentLicences.filter(l => l !== val))
    } else {
      copyCurrentLicences.push(val)
      currentLicencesChange(copyCurrentLicences)
    }
  }

  const handleAllLicences = () => {
    currentLicencesChange(Object.keys(licences).filter((l: string) => l !== L_BENCHMARK))
  }

  const validationData = (errs: string[]) => {
    if (subscrName === '') {
      errs.push('Subscription name is required field')
    }
    if (currentLicences.length === 0) {
      errs.push('At least 1 solution must be chosen')
    }
    return errs
  }

  const createSubscriptionHandle = () => {
    let data = {
      "name": subscrName,
      "intelligence": currentLicences.includes('intelligence'),
      "marketing": currentLicences.includes('marketing'),
      "frequency": "weekly",
      "tag_filter": filterTagsMatch === MATCH_TYPES[0] ? 'any' : 'all',
      "product_ids": currentProducts,
      "label_ids": filterTags.filter(tag => tag !== "-1"),
      "critical_insight": filterTags.includes("-1"),
      "email": true
    }
    let errs = validationData([])

    if (errs.length === 0) {
      createSubscription(data)
      closeWindow(false)
      analytics.analytic(`User created new subscription`, data);
    } else {
      errorsChange(errs)
      setTimeout(() => errorsChange([]), 3000)
    }
  }

  const editSubscriptionHandle = () => {
    let data = {
      "name": subscrName,
      "intelligence": currentLicences.includes('intelligence'),
      "marketing": currentLicences.includes('marketing'),
      "frequency": subscription ? subscription.frequency : "weekly",
      "tag_filter": filterTagsMatch === MATCH_TYPES[0] ? 'any' : 'all',
      "product_ids": currentProducts,
      "label_ids": filterTags.filter(tag => tag !== "-1"),
      "critical_insight": filterTags.includes("-1"),
      "email": true
    }

    let errs = validationData([])
    if (errs.length === 0) {
      if (editSubscription && subscription) {
        editSubscription({ id: subscription.id, data: data })
      }
      closeWindow(false)
    } else {
      errorsChange(errs)
      setTimeout(() => errorsChange([]), 3000)
    }
  }

  return (
    <div className="subscription-add-modal-wrapper">
      <Modal
        title={subscription && subscription.name ? `Edit Subscription` : `Create Subscription`}
        open={modalShow}
        onCancel={() => closeWindow(false)}
        wrapClassName='modal-subscription'
        key="modal-add-subscription"
        maskClosable={false}
        footer={[
          <Button key="cancel" type="default" onClick={() => closeWindow(false)}>Cancel</Button>,
          subscription && subscription.name ?
            <Button key="submit" type="default" onClick={editSubscriptionHandle}>Save</Button>
            :
            <Button key="submit" type="default" onClick={createSubscriptionHandle}>Create</Button>
        ]}
      >
        <div className="add-subs-descr">
          Create a Subscription to a specific insight topic and get notified when a new insight with the same topic is published.
        </div>
        <div className="add-subs-title">
          <span className={'text'}>SUBSCRIPTION NAME</span>
          <span className={'star'}>*</span>

        </div>
        <Input value={subscrName} onChange={(e) => subscrNameChange(e.target.value)} placeholder={'Subscription Name'} />
        <div className="add-subs-specs">
          <div className={"add-subs-title"}>
            <span className={'text'}>SUBSCRIPTION SPECIFICATIONS</span>
          </div>

          <div className="specs-wrapper">
            <div className="spec spec-tag" >
              {tagsList &&
                <Popover placement="bottomLeft" title={null}
                  destroyTooltipOnHide={true}
                  content={
                    <FilterTags
                      tagsList={tagsList}
                      filterTags={filterTags}
                      //@ts-ignore
                      filterTagsChange={filterTagsChange}
                      setFilterTagsMatch={setFilterTagsMatch}
                      //@ts-ignore
                      filterTagsMatch={filterTagsMatch}
                      page="all-discoveries"
                      maxAllowedTags={10}
                    />
                  }
                  trigger="click"
                  overlayClassName={`popover-appdrawer  filter-wrap  all-discoveries all-discoveries-tags`}
                >
                  <Button className="filter-btn-new">
                    Tags
                    {<span className={`filter-count ${filterTags.length === 0 ? '' : 'filter-count-padding'}`}>
                      {filterTags.length === 0 ? 'All Tags' : filterTags.length}
                    </span>}
                  </Button>
                </Popover>}
              <div className="spec-tag-match-type">
                <span className="spec-tag-match-crl"></span>
                {filterTagsMatch}</div>
              <div className="spec-value spec-tag-value">

                {filterTags.map(fTagID => {
                  const t = tagsList.find(tag => tag.id === Number(fTagID))
                  if (!t) { return null }
                  return <div className="tags__tags-tag-element" data-tag-id={fTagID}>{t.name}</div>
                })}

              </div>
            </div>
            <div className="spec spec-product" >
              {products && <Popover placement="bottomLeft" title={null}
                destroyTooltipOnHide={true}
                content={
                  //@ts-ignore
                  <AppDrawer appsList={products.filter(pr => pr.apps.some(app => app.has_experiments))}
                    currentApps={currentProducts}
                    currentAppsChange={currentProductsChange}
                    isProduct={true}
                    page="all-discoveries"
                    appChange={handleAppChange}
                    clearCurrentApps={() => currentProductsChange([])}
                    maxAppsSelected={20}
                  />
                } trigger="click" overlayClassName={`popover-appdrawer  filter-wrap  all-discoveries all-discoveries-apps`}>
                <Button className="filter-btn-new">
                  Apps
                  {<span className={`filter-count ${currentProducts.length === 0 ? '' : 'filter-count-padding'}`}>
                    {currentProducts.length === 0 ? null : currentProducts.length}
                  </span>}
                </Button>
              </Popover>}
              <div className="spec-value spec-product-value">
                {currentProducts.map(curProductID => {
                  const pr = products.find(p => p.id === Number(curProductID))
                  if (!pr) { return null }
                  return <img src={pr.thumbnail} key={curProductID} />
                })}
              </div>
            </div>

            <div className="spec spec-licence" >

              <Popover placement="bottomLeft" title={null}
                destroyTooltipOnHide={true}
                content={
                  <div className="licences-modal">
                    <div className="licences-wrapper">
                      {Object.keys(licences).filter((l: string) => l !== L_BENCHMARK && licences[l]).map((l: string, i: number) => <Checkbox
                        //@ts-ignore
                        checked={currentLicences.includes(l)}
                        value={l}
                        key={i}
                        //@ts-ignore
                        onChange={handleCheckboxChange}>{LICENCES_DATA[l]} </Checkbox>)}
                    </div>
                    <div className="licences-btn-all">
                      <Button onClick={handleAllLicences}>All</Button>
                    </div>
                  </div>
                } trigger="click" overlayClassName={`all-discoveries filter-wrap subscription-licences-popup`}>
                <Button className="filter-btn-new">
                  Solutions
                  {<span className={`filter-count ${currentLicences.length === 0 ? '' : 'filter-count-padding'}`}>
                    {currentLicences.length === 0 ? null : currentLicences.length}
                  </span>}
                </Button>
              </Popover>
              <div className="spec-value spec-licence-value">

                {currentLicences.map((cL: string, i: number) => <div key={i}>
                  <span className="licence-mark">✓</span>
                  {/* @ts-ignore */}
                  {LICENCES_DATA[cL]}</div>)}
              </div>
            </div>




          </div>
        </div>
        <div className={'mandatory-wrapper'}>
          <span className={'star'}>*</span>
          <span className={'text'}>Mandatory fields</span>
        </div>

        <div className="subscription-errors">
          {errors.map(er => <div className="subscription-error">{er}</div>)}
        </div>

      </Modal>
    </div>
  )
}

export default SubscriptionModal