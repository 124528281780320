import React, { useEffect, useMemo, useRef } from "react";
import { Card } from ".";
import clsx from "clsx";
import moment from "moment";
import card_separator_ico from "assets/images/icons/context-timeline/card-separator.svg";
import { useContextTimeline } from "pages/ContextTimeline/context";
import "./styles.scss";
import { Popover, Tooltip } from "antd";
import caret_ico from "assets/images/icons/caret-down.svg";
import { ReactComponent as InfoIco } from "assets/images/icons/info-new-dark.svg";
import { SameDayEventsPopover } from "./same-day-events-popover";
import { useIsOpen } from "pages/ContextTimeline/hooks/useIsOpen";

type Props = {
  card: Card;
};

export const ListCard = ({ card }: Props) => {
  const { handleChangeFilter, date, eventName, eventsByDateList } =
    useContextTimeline();
  const { isOpen, close, onOpenChange } = useIsOpen({});
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      ref.current &&
      moment(card.date).format("DD/MM/YYYY") ===
        moment(date).format("DD/MM/YYYY")
    ) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }
  }, [date]);

  const currentSelectedEvent = useMemo(() => {
    const curEvent = card.events?.find((event) => event.name === eventName);

    if (!curEvent) {
      return card.events?.[0];
    }

    return curEvent;
  }, [eventName]);

  const eventsWithSameName = useMemo(
    () =>
      eventsByDateList?.filter((eventByDate) =>
        eventByDate.events.find(
          (event) => event.name === currentSelectedEvent?.name
        )
      ),
    [eventsByDateList, currentSelectedEvent?.name]
  );

  const positionOutOfTotal = useMemo(() => {
    // find index of the current date
    const index = eventsWithSameName?.findIndex(
      (eventByDate) =>
        moment(eventByDate.date).format("DD/MM/YYYY") ===
        moment(card.date).format("DD/MM/YYYY")
    );

    return (index || 0) + 1;
  }, [eventsWithSameName, card.date]);

  return card.isGap ? (
    <div className="gap-card">
      <div>{`<${card.gap} Days>`}</div>
    </div>
  ) : (
    <div
      ref={ref}
      className={clsx("card", {
        selected:
          moment(card.date).format("DD/MM/YYYY") ===
          moment(date).format("DD/MM/YYYY"),
      })}
      key={card.date}
      onClick={() => {
        if (!card.date) return;

        handleChangeFilter?.("date", card.date);
        handleChangeFilter?.("insight_id", "");

        if (!currentSelectedEvent) return;

        handleChangeFilter?.("event_name", currentSelectedEvent.name);
      }}
    >
      <div
        className={clsx("card-heading", {
          multi: card.events?.length && card.events?.length > 1,
        })}
      >
        <div className="card-date-events">
          <span className="event-date">
            {moment(card?.date).format("MMM DD/YY")}
          </span>
          <span className="event-title">
            {currentSelectedEvent?.description && (
              <Tooltip
                overlayClassName="info-header-tooltip"
                placement="bottom"
                title={currentSelectedEvent.description}
              >
                <InfoIco />
              </Tooltip>
            )}
            {`${currentSelectedEvent?.name} (${positionOutOfTotal}/${
              eventsWithSameName?.length || 0
            })`}
            {card.events?.length && card.events?.length > 1 && (
              <Popover
                placement="bottom"
                title={null}
                open={isOpen}
                destroyTooltipOnHide={true}
                trigger="click"
                content={<SameDayEventsPopover card={card} onClose={close} />}
                overlayClassName={clsx(
                  "popover-appdrawer  filter-wrap   all-discoveries all-discoveries-apps fixed-filters"
                )}
                onOpenChange={onOpenChange}
                overlayStyle={{ width: "215px", height: "auto" }}
              >
                <span className="event-count">
                  +{card.events.length - 1}
                  <img src={caret_ico} />
                </span>
              </Popover>
            )}
          </span>
        </div>
        <img src={card_separator_ico} />
      </div>
      <div className="card-content">
        {card.emailCount ? (
          <div className="email-count">Email campaign ({card.emailCount})</div>
        ) : null}
        {card.pushCount ? (
          <div className="push-count">Push Notification ({card.pushCount})</div>
        ) : null}
      </div>
    </div>
  );
};
