import React, { ReactElement } from 'react'
import { IReport } from '../../../interfaces/Reports';
import moment from "moment"

import watchful_icon from "../../../assets/images/watchful_icon.svg"


interface Props {
  report: IReport,
  handlerReportNotificationClick: (noteReport: IReport) => void,
  options: any
}

export default function ReportNote({
  report,
  handlerReportNotificationClick,
  options
}: Props): ReactElement {


  return (
    <div className={`report-item ${!report.app_read_at ? 'unread' : ''} ${report.url ? 'clickable' : ''}`}
      onClick={() => handlerReportNotificationClick(report)} data-testid={`note ${report.id}`}
      {...(report.url && { 'data-test-url': report.url })}
    >
      <div className="report-app-data">
        <div className={`app-icon-wrapper ${report.screenshot_url ? 'real':'def'}`}>
          {report.screenshot_url ? <img src={report.screenshot_url } className="app-icon" /> :
            <img src={watchful_icon} className="app-icon" />}
        </div>
      </div>
      <div className="report-descr">
        <div className="report-descr-text">{report.name}</div>
        <div className="report-time-diff">
          {moment(report.upload_date).format(options.format)}
        </div>

      </div>
    </div>
  )
}
