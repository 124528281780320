import React from "react";
import { isEmpty } from "lodash";
import { durationFormatter } from "../../../../helpers/numbersHelper";
import PerformanceNoData from "../PerformanceSummary/PerformanceNoData"

import "./PerformanceSubEvents.scss"

const HeaderRow = () => (
  <div className="subevents-box__row subevents-box__header">
    <span className="subevents-box__row__name">SUB-EVENT</span>
    <span className="subevents-box__row__start">TIME</span>
  </div>
);

const Row = ({ name, time = 0 }) => {
  const timeFormattedObj = durationFormatter(time, 0);
  return (
    <div className={`subevents-box__row ${!name ? "disabled" : ""}`}>
      <span className="subevents-box__row__name">
        {name || "There are no subevents"}
      </span>
      <span className="subevents-box__row__start">
        {timeFormattedObj.number}{" "}
        <span className="suffix">{timeFormattedObj.suffix}</span>
      </span>
    </div>
  );
};

export default function PerformanceSubEvents({
  mainEventMetadata,
  compareEventMetadata,
  mainEventSubevents,
  compareEventSubevents,
  mainTimeLinePerc,
  comprTimeLinePerc
}) {
  const hasMainSubevents = !isEmpty(mainEventSubevents);
  const hasCompareSubevents = !isEmpty(compareEventSubevents);
  if (!hasMainSubevents && !hasCompareSubevents) {
    return null;
  }

  const getSubeventMetadata = (name, eventMetadata) => {
    let subeventMetadata = undefined;
    eventMetadata &&
      eventMetadata.performance_events.forEach(event => {
        event.subevents.forEach(subevent => {
          if (subevent.name === name) {
            subeventMetadata = subevent;
          }
        });
      });
    return subeventMetadata;
  };

  return (
    <div className="performance-sub-events-new">
      {mainEventSubevents ? <div className="subevents-box compare">
        <div className="timeLine">
          <div className={`t-1`} style={{ width: `${mainTimeLinePerc}%` }}></div>
        </div>
        <HeaderRow />
        {mainEventSubevents ? mainEventSubevents.map(subevent => {
          const subeventMetadata = getSubeventMetadata(
            subevent.name,
            mainEventMetadata
          );
          const subeventTime = subevent.end_ts_s_in_event * 1000;
          if (!subeventMetadata) {
            return <Row />; // Empty row - Zero state
          }
          return (
            <Row
              key={subevent.name}
              name={subeventMetadata.title}
              time={subeventTime}
            />
          );
        }) :
          <Row
            key="no-sub-events"
            name="There are no sub-events for this event"
          />
        }
      </div> :
        <PerformanceNoData />
      }
      {compareEventSubevents ? <div className="subevents-box compare">
        <div className="timeLine">
          <div className={`t-2`} style={{ width: `${comprTimeLinePerc}%` }}></div>
        </div>
        <HeaderRow />
        {compareEventSubevents ? compareEventSubevents.map(subevent => {
          const subeventMetadata = getSubeventMetadata(
            subevent.name,
            compareEventMetadata
          );
          const subeventTime = subevent.end_ts_s_in_event * 1000;
          if (!subeventMetadata) {
            return <Row />; // Empty row - Zero state
          }
          if (!subeventMetadata) {
            return <Row />; // Empty row - Zero state
          }
          return (
            <Row
              key={subevent.name}
              name={subeventMetadata.title}
              time={subeventTime}
            />
          );
        }) :
          <Row
            key="no-sub-events-compare"
            name="There are no sub-events for this event"
          />
        }

      </div> :
        <PerformanceNoData />
      }

    </div>
  );
}
