import React, { Component, useState } from 'react';
import copy from 'copy-to-clipboard';
import { Modal, Tooltip, Button, Avatar, Input, Checkbox } from 'antd';
import { AutoComplete } from 'antd';

import { useIsFeatureByUserID } from "../../../helpers/features"

import 'antd/lib/modal/style/css';
import 'antd/lib/tooltip/style/css';
import 'antd/lib/button/style/css';

const { Option } = AutoComplete;

const analyticsInsightShareMethods = {
  byEmail: "email",
  byLink: "link",
}

const ViewModal = ({
  closeModal,
  modalShow,
  linkUrl,
  sendEmail,
  recipient,
  recipients,
  recipientChange,
  comment,
  subject = "discovery",
  whoCanView = `Anyone with this link, will be able to view the discovery`,
  placeHolder = 'Check out this discovery I’ve found on watchful’s platform',
  commentChange,
  linkCode,
  updateLinkData,
  user = {},
  analytics = undefined,
  analyticsData = undefined,
}) => {

  const isCustomerSuccess = useIsFeatureByUserID("users_can_share_all_domain", user.email) && !linkUrl.includes('/reports/')

  return (
    <Modal
      title={`Share ${subject}`}
      open={modalShow}
      onOk={closeModal}
      onCancel={closeModal}
      wrapClassName='view__modal'
      footer={recipients ? [
        <Button key="cancel" type="default" onClick={closeModal}>Cancel</Button>,
        <Button key="submit" type="default" onClick={() => {
          sendEmail();
          analytics?.analytic && analytics.analytic(
            "insights_shared",
            {
              ...analyticsData,
              method: analyticsInsightShareMethods.byEmail,
            }
          );
        }}>
          Send
        </Button>
      ] : null}
    >
      <div className='view__modal__content'>
        <div className="share-via-link">
          <h3>Via Link</h3>
          <p className='view__modal__details'>{whoCanView}</p>
          <div className='view__modal__copy'>
            <p className='view__modal__copy-link'>{linkUrl}</p>
            <Tooltip title="copied!" trigger='click' overlayClassName='view__modal__tooltip' placement="topLeft">
              <div className='view__modal__copy-img' onClick={() => {
                  copy(linkUrl);
                  analytics?.analytic && analytics.analytic(
                    "insights_shared",
                    {
                      ...analyticsData,
                      method: analyticsInsightShareMethods.byLink,
                    }
                  );
                }}>
                <img src={require('../../../assets/images/icons/copy-link.svg')} alt="copy" />
              </div>
            </Tooltip>
          </div>
          {isCustomerSuccess && <div className='share-checkbox-domain'>
            <Checkbox onChange={(e) => updateLinkData(linkCode, !e.target.checked)} > Create a public link</Checkbox>
          </div>}
        </div>
        <hr />
        {recipients && <div className="share-via-email">
          <h3>Via Email</h3>
          <p className='view__modal__details'>Share this {subject} in an email.</p>
          <label className='share-via-email-label' htmlFor="email">Recipient Email</label>
          <div>
            <AutoComplete style={{ width: '100%' }}
              filterOption={(inputValue, option) =>
                option.props.children[1].toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
              }
              overlayClassName="share-discovery-recipients"
              value={recipient} onChange={(rep, ev) => recipientChange(rep)} optionLabelProp="value">
              {recipients.map(r => <Option key={r.email} value={r.email} className="share-discovery-recipients-option">
                {r.picture_url ?
                  <Avatar size={25} src={r.picture_url} className="profile-avatar" />
                  :
                  <Avatar size={25} className="def-avatar" >
                    {r.name ? r.name.slice(0, 2) : r.email.slice(0, 2)}
                  </Avatar>}
                {r.email}
              </Option>)}
            </AutoComplete>
          </div>

          <label className='share-via-email-label' htmlFor="question">Comment</label>
          <textarea
            className='share-via-email-comment'
            name="question"
            id="question"
            value={comment}
            onChange={e => commentChange(e.target.value)}
            placeholder={placeHolder}
          />
        </div>}
      </div>
    </Modal>
  );

}

export default ViewModal;