import React from 'react';

import version_empty from "../../../assets/images/version-empty.svg"
import filter_warning_ico from "../../../assets/images/icons/filter-warning.svg"


const VersionsEmpty = ({ campaignMode = false, hideDescription = false, hideTip = false, text1, text2 }) => {
  return (
    <section className='versions__empty'>
      <img src={version_empty} className='versions__empty-img' alt="" />
      {campaignMode && !hideTip &&
        <div className="filter-warning">
          <img src={filter_warning_ico} />
          Try widening your filters to find what you’re looking for
        </div>}
      {text1 ?
        <h2 className="versions__empty-title">
          {text1}
        </h2>
        : <h2 className="versions__empty-title">
          {campaignMode ? 'No Discoveries Found' : 'No Unseen Discoveries'}
        </h2>
      }
      {!campaignMode && !hideDescription && <p className='versions__empty-paragraph'>
        {text2 ? text2 : 'There were no discovered changes in this release'}
      </p>}
    </section>
  );
}

export default VersionsEmpty;