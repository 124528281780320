import React from "react";
import { withRouter, Route, Redirect, Switch } from "react-router";
import routesData from "../../shared/routesData";
import Discovery from "../../containers/discovery/Discovery";

import ProductsPage from "../../containers/products/ProductsPage"
import BiWeekly from "../../containers/BiWeeklyGallery/BiWeekly"
import MarketingInsightView from "../../containers/intelligence/Marketing/MarketingInsightView"
import Account from "../../containers/account/Account"
import GraphPage from "../../containers/GraphPage/GraphPage"

import { pagesTypes } from "../../shared/consts";
import { getLandPageForUser } from "../../helpers/usersHelper"
import { connect } from "react-redux";

import { siteVersions } from "../../shared/consts";
import IntelligenceProductPage from "../../containers/products-discoveries/IntelligenceProductPage"
import ABTestsPage from "../../containers/ABTests/ABTestsPage"
import TopicsPage from "../../containers/topics/TopicsPage"
import PerformanceCompare from "../../containers/benchmark/BenchmarkCompare/BenchmarkComparePage"
import BenchmarkMetricsPage from "../../containers/benchmark/BenchmarkMetrics/BenchmarkMetricsPage"

import FallbackOldURLS from "../../containers/versions/FallbackOldURLS"
import { UserJourneysPage } from "../../containers/UserJourneys/UserJourneysPage";
import { MarketingCampaignsPage } from "../../containers/MarketingCampaings";
import { MarketingOverviewPage } from "../../containers/MarketingOverview"
import { IntelPage } from "pages/Intel";
import { ContextTimelinePage } from "pages/ContextTimeline";

const Routes = (props) => {

  const renderRoutes = () => {
    const routes = [];
    const { url } = props.match;
    const { routesType } = routesData;
    const firstPage = getLandPageForUser(props.user);

    const availItems = props.userData.pages;
    if (availItems.includes(pagesTypes.DASH_OVERVIEW)) {
      routes.push(<Route
        key={pagesTypes.DASH_OVERVIEW}
        exact
        path="/overview"
        analytics={props.analytics}
        component={routesType[pagesTypes.DASH_OVERVIEW].component}
      />)

      routes.push(<Route
        key={pagesTypes.ALL_DISCOVERIES}
        exact
        path="/all-discoveries"
        analytics={props.analytics}
        component={routesType[pagesTypes.ALL_DISCOVERIES].component}
      />)

      routes.push(<Route
        key='product-page'
        exact
        path="/products/:productID"
        analytics={props.analytics}
        component={ProductsPage}
      />)

    }

    if (availItems.includes(pagesTypes.VERSIONS) || props.siteVersion === siteVersions.DEMO) {
      routes.push(
        <Route
          key={`${pagesTypes.VERSIONS}`}
          exact
          path="/intelligence/"
          component={FallbackOldURLS}
        />,
        <Route
          key={`${pagesTypes.VERSIONS}`}
          exact
          path="/intelligence/:type"
          component={FallbackOldURLS}
        />,
        <Route
          key={`${pagesTypes.VERSIONS}-app`}
          exact
          path="/intelligence/:type/:app_id"
          component={FallbackOldURLS}
        />,
        <Route
          key={`${pagesTypes.VERSIONS}-vers`}
          exact
          path={`/intelligence/:type/:app_id/:release_id`}
          component={FallbackOldURLS}
        />,
        <Route
          key={`${pagesTypes.VERSIONS}-ins`}
          exact
          path={`/intelligence/versions/:app_id/:release_id/:id`}
          component={Discovery}
        />)
    }

    if (availItems.includes(pagesTypes.VERSIONS) || props.siteVersion === siteVersions.DEMO) {
      routes.push(
        <Route
          key={`${pagesTypes.VERSIONS}`}
          exact
          path="/product_intelligence/"
          component={IntelPage}
        />,
        <Route
          key={`${pagesTypes.TOPICS}`}
          exact
          path="/product_intelligence/topics/"
          component={TopicsPage}
        />,
        <Route
          key={`${pagesTypes.USER_JOURNEYS}`}
          exact
          path="/product_intelligence/user_journeys/:app_id?/"
          component={UserJourneysPage}
        />,
        <Route
          key={`${pagesTypes.AB_TESTS}`}
          exact
          path="/product_intelligence/ab-tests/"
          component={ABTestsPage}
        />,
        <Route
          key={`${pagesTypes.VERSIONS}`}
          exact
          path="/product_intelligence/:product_id/"
          component={IntelligenceProductPage}
        />,
      )
    }

    if (availItems.includes(pagesTypes.MARKETING) || props.siteVersion == siteVersions.DEMO) {
      routes.push(<Route
        key={`${pagesTypes.MARKETING_CAMPAIGNS}`}
        exact
        path="/marketing"
        component={routesType[pagesTypes.MARKETING_CAMPAIGNS].component}
      />)
      routes.push(<Route
        key={`${pagesTypes.MARKETING_CAMPAIGNS}`}
        exact
        path="/marketing/campaigns"
        component={MarketingCampaignsPage}
      />)
      routes.push(<Route
        key={`${pagesTypes.MARKETING_MASS_MARKETING}`}
        exact
        path="/marketing/mass_market"
        component={MarketingOverviewPage}
      />)
      routes.push(<Route
        key={`${pagesTypes.CONTEXT_TIMELINE}`}
        exact
        path="/marketing/context_timeline"
        component={ContextTimelinePage}
      />)
      routes.push(<Route
        key={`${pagesTypes.MARKETING}-view`}
        exact
        path="/marketing/:insightID"
        component={MarketingInsightView}
      />)

    }

    if (availItems.includes(pagesTypes.BENCHMARK_COMPARE)) {

      routes.push(
        <Route
          key={`${pagesTypes.BENCHMARK_COMPARE}`}
          exact
          path="/benchmark/compare"
          analytics={props.analytics}
          component={PerformanceCompare}
        />,
        <Route
          key={`${pagesTypes.BENCHMARK_COMPARE}-ids`}
          exact
          path="/benchmark/compare/:eventIDs"
          analytics={props.analytics}
          component={PerformanceCompare}
        />,
        <Route
          key={`bench-metrics-ids`}
          exact
          path="/benchmark/compare/metrics/:eventID"
          analytics={props.analytics}
          component={BenchmarkMetricsPage}
        />
          
      )
    }

    if (availItems.includes(pagesTypes.BENCHMARK_RANKS)) {
      routes.push(
        <Route
          key={pagesTypes.BENCHMARK_RANKS}
          exact
          path="/benchmark/ranks"
          analytics={props.analytics}
          component={routesType[pagesTypes.BENCHMARK_RANKS].component}
        />)
    }

    routes.push(<Route
      key={'account'}

      exact
      path="/account"
      render={props => (
        <Account {...props} analytics={props.appAnalytics} />
      )}
    />)

    routes.push(<Route
      key={'report-view'}

      exact
      path="/reports/:id"
      component={routesType[pagesTypes.REPORTSVIEW].component}

    />)

    routes.push(<Route
      key={'reports-list'}

      exact
      path="/reports"
      component={routesType[pagesTypes.REPORTSLIST].component}
    />)

    routes.push(<Route
      key={'subscriptions-list'}

      exact
      path="/subscriptions"
      component={routesType[pagesTypes.SUBSCRIPTIONS].component}
    />)
    routes.push(<Route
      key={'eula'}

      exact
      path="/eula"
      component={routesType[pagesTypes.EULA].component}
    />)

    routes.push(<Route
      key={'bi_weekly'}

      exact
      path="/bi_weekly/:highlight_id"
      component={BiWeekly}
    />)

    routes.push(<Route
      key={'privacy'}

      exact
      path="/privacy"
      component={routesType[pagesTypes.PRIVACY].component}
    />)

    routes.push(<Route
      key={'graph_view'}

      exact
      path="/app_graph/:graph_id"
      component={GraphPage}
    />)

    routes.push(<Redirect key="red-1" from='/versions/:package_name/:release_id/:id' to='/intelligence/versions/:app_id/:release_id/:id' />)
    routes.push(<Redirect key="red-2" from='/versions/:package_name/:release_id' to='/intelligence/versions/:app_id/:release_id' />)
    routes.push(<Redirect key="red-3" from='/versions/:package_name' to='/intelligence/versions/:app_id' />)
    routes.push(<Redirect key="red-4" from='/versions' to='/intelligence/versions' />)

    routes.push(<Route key="old-redir" exact path={`/:type/:package_name/:id`} component={Discovery} />)
    routes.push(<Redirect key="def-redir" to={`/${firstPage}`} />)

    return routes;
  }
  const routes = renderRoutes()

  return (
    <Switch>
      {/* Content routes, all of those will be presented as content in the dashboard (with header and side bar etc.) */}
      {routes}
    </Switch>
  );

}


export default withRouter(connect(({ appData, userData }) => ({
  user: appData.user,
  userData: userData,
  siteVersion: appData.siteVersion
}), null)(Routes));
