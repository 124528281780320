import React, { useEffect, useRef, useCallback, useState } from "react";
import _ from "lodash"

export const useThrottle = (cb, delay) => {
  const options = { leading: true, trailing: false }; // add custom lodash options
  const cbRef = useRef(cb);
  // use mutable ref to make useCallback/throttle not depend on `cb` dep
  useEffect(() => { cbRef.current = cb; });
  return useCallback(
    _.throttle((...args) => cbRef.current(...args), delay, options),
    [delay]
  );
}

export const useDebounce = (cb, delay, opt) => {
  // ...
  const inputsRef = useRef(cb); // mutable ref like with useThrottle
  useEffect(() => { inputsRef.current = { cb, delay }; }); //also track cur. delay
  return useCallback(
    _.debounce((...args) => {
      // Debounce is an async callback. Cancel it, if in the meanwhile
      // (1) component has been unmounted (see isMounted in snippet)
      // (2) delay has changed
      if (inputsRef.current.delay === delay)
        inputsRef.current.cb(...args);
    }, delay
    ),
    [delay, _.debounce]
  );
}
