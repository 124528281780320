import { useState } from "react";

type Props = {
  isOpenDefault?: boolean;
};

export const useIsOpen = ({ isOpenDefault = false }: Props) => {
  const [isOpen, setIsOpen] = useState(isOpenDefault);

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  const onOpenChange = (newOpen: boolean) => {
    if (newOpen) {
      open();
    } else {
      close();
    }
  };

  return { isOpen, toggleIsOpen, open, close, onOpenChange };
};
