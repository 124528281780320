import { all } from "redux-saga/effects";
import ABTestsSagas from "./abTestsSagas";
import biWeeklySagas from "./biWeeklySagas";
import DashboardSagas from "./dashboardSagas";
import DirectLinkSagas from "./directLinkSagas";
import graphSagas from "./graphSaga";
import notificationsSagas from "./notificationsSagas";
import overviewSagas from "./overviewSagas";
import PerformanceSagas from "./performanceSagas";
import personaSagas from "./personaSagas";
import productSagas from "./productSagas";
import reportsSagas from "./reportsSagas";
import subscriptionsSagas from "./subscriptionsSagas";
import userDiscoveriesSagas from "./userDiscoveriesSagas";
import { userJourneysSagas } from "./userJoureysSagas";
import userSagas from "./userSagas";

export default function* rootSaga() {
  yield all([
    ...DashboardSagas,
    ...ABTestsSagas,
    ...PerformanceSagas,
    ...DirectLinkSagas,
    ...overviewSagas,
    ...userSagas,
    ...productSagas,
    ...personaSagas,
    ...notificationsSagas,
    ...reportsSagas,
    ...subscriptionsSagas,
    ...biWeeklySagas,
    ...graphSagas,
    ...userDiscoveriesSagas,
    ...userJourneysSagas,
  ]);
}
