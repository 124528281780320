import React, { useState, useEffect, useRef, FC, ReactElement } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { ISubscription } from '../../interfaces/Subscription'

import { Table, message, Input, Popconfirm, Tooltip } from "antd"
import { LICENCES, LICENCES_DATA, L_INTELLIGENCE, L_MARKETING, intelligenceOnlyType, marketingOnlyType } from "../../shared/consts"

import { getSubscriptions } from "../../redux/acts/subscriptions"
import { MATCH_TYPES } from "../../containers/intelligence/Marketing/FilterTypes";

import moment from 'moment';

import SubscriptionModal from "./subscriptionsModal/SubscriptionModal"
import SubscriptionButton from "./subscriptionButton/subscriptionButton"
import { getDiscoveriesLabels } from "../../redux/acts/labels"
import { ILabel } from '../../interfaces/Label'
import { IState, Product } from '../../interfaces/Product'

import { createSubscription, editSubscription, deleteSubscription } from "../../redux/acts/subscriptions"
import { getUserLicence } from "../../helpers/usersHelper"

import sub_edit_ico from "../../assets/images/new_design/icons/sub-edit.svg"
import sub_delete_ico from "../../assets/images/new_design/icons/sub-delete.svg"
import watchful_icon from "../../assets/images/watchful_icon.svg"
import watchful_empty_icon from "../../assets/images/new_design/icons/watchful-logo-stroke.svg"
import version_empty from "../../assets/images/version-empty.svg"
import SubscriptionEmpty from "./SubscriptionEmpty"

import './SubscriptionsList.scss'





type SubscriptionsListProps = {
  match: any,
  history: { push(url: string): void; length: number; goBack(): void; replace(url: string): void; location: any },
  subscriptions: ISubscription[],
  analytics: any,
  getSubscriptions: () => void,
  tagsList: ILabel[],
  createSubscription: (args1: any) => void,
  editSubscription: (data: any) => void,
  deleteSubscription: (data: any) => void,
  getDiscoveriesLabels: () => void,
  subscriptionsError: string,
  products: Product[],
  userData: any
}

const SubscriptionsList: FC<SubscriptionsListProps> = ({
  match,
  history,
  analytics,
  subscriptions,
  products,
  getSubscriptions,
  tagsList,
  createSubscription,
  editSubscription,
  deleteSubscription,
  userData,
  getDiscoveriesLabels,
  subscriptionsError = ''
}): ReactElement | null => {

  const [openSubscriptionModal, openSubscriptionModalChange] = useState(history.location.state && history.location.state.openNewModal)
  const [editSub, editSubChange] = useState<ISubscription | null>(null)

  useEffect(() => {
    if (subscriptionsError !== '') {
      message.error('Something went wrong');
    }
  }, [subscriptionsError])

  const columns = [
    {
      title: '',
      dataIndex: 'img',
      key: 'img',
      render: (text: string, sub: ISubscription) => <div className="subscription-icon">
        <img src={sub.insight_cnt_unread === 0 ? watchful_empty_icon : watchful_icon} />
      </div>,
      width: '5rem'
    },
    {
      title: 'Subscription Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (sub1: ISubscription, sub2: ISubscription) => sub1.name < sub2.name ? 1 : -1,
      render: (text: string, sub: ISubscription) =>
        <div className={`subscription-name`}>
          <div className="subscription-created">{moment(sub.created).format('ll')}</div>
          <h2 onClick={() => handleClick(sub)}>{text}</h2>
        </div>,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Tags',
      dataIndex: 'name',
      key: 'tags',
      width: '22rem',
      render: (text: string, sub: ISubscription) =>
        <div className={`subscription-tags`}>
          <div className="subscription-tags-match">{sub.tag_filter === 'any' ? MATCH_TYPES[0] : MATCH_TYPES[1]}</div>
          <div className="subscription-tags-wrapper">
            {sub.critical_insight && <div className="tags__tags-tag-element" data-tag-id="-1" key={'-1'}>critical insight</div>}
            {sub.labels.map((tag, i) => {
              return <div className="tags__tags-tag-element" key={i}>{tag.name}</div>
            })}
            {sub.labels.length === 0 && <strong className='all-tags'>All Tags</strong>}
          </div>
        </div>,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Apps',
      dataIndex: 'products',
      key: 'products',
      width: '14rem',
      render: (text: string, sub: ISubscription) =>
        <div className={`subscription-products`}>
          {sub.products.map((product, i) => {
            return <Tooltip title={product.name} key={i}><img src={product.thumbnail} /></Tooltip>
          })}
        </div>,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Product',
      dataIndex: 'licences',
      key: 'licences',
      render: (text: string, sub: ISubscription) =>
        <div className={`subscription-licences-wrap`}>
          {sub[L_INTELLIGENCE] && <div className="subscription-licence ">{LICENCES_DATA[L_INTELLIGENCE]}</div>}
          {sub[L_MARKETING] && <div className="subscription-licence ">{LICENCES_DATA[L_MARKETING]}</div>}
        </div>,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Frequency',
      dataIndex: 'frequency',
      key: 'frequency',
      render: (text: string, sub: ISubscription) =>
        <div className={`subscription-frequency-wrap`}>
          {sub.email ? sub.frequency : "never"}
        </div>,
    },
    {
      title: <span><span style={{ color: '#FC0E0E' }}>Unseen</span>/# of discoveries</span>,
      dataIndex: 'num_discovers',
      key: 'num_discovers',
      render: (text: string, sub: ISubscription) =>
        <div className={`subscription-discoveries`}>
          <div className="subscription-discoveries-value" onClick={() => handleClick(sub)}>
            <span style={{ color: '#FC0E0E' }}>{sub.insight_cnt_unread}</span>
            /
            <span style={{ color: '#A6A8AD' }}>{sub.insight_cnt_total}</span>
          </div>
          <div className="sub-management">
            <img src={sub_edit_ico} onClick={() => openEditSubscription(sub)} />
            <Popconfirm
              placement="topLeft"
              title={'Are you sure?'}
              onConfirm={() => deleteSubscriptionHandle(sub.id)} okText="Yes" cancelText="No">
              <img src={sub_delete_ico} />
            </Popconfirm>

          </div>
        </div>,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];

  const openEditSubscription = (sub: ISubscription) => {
    editSubChange(sub)
    openSubscriptionModalChange(true)
  }

  const deleteSubscriptionHandle = (id: number) => {
    deleteSubscription({ id })
  }

  const handleClick = (sub: ISubscription) => {
    let intel_types = Object.keys(intelligenceOnlyType)
    let marketing_types = Object.keys(marketingOnlyType)
    let mix_types = ''

    if (sub[L_INTELLIGENCE] && !sub[L_MARKETING]) {
      mix_types = intel_types.join(',')
    } else if (!sub[L_INTELLIGENCE] && sub[L_MARKETING]) {
      mix_types = marketing_types.join(',')
    }


    let URL = `/all-discoveries?daysAgo=1825&filterStatus=${sub.critical_insight ? 'meaningful' : ''}&currentPageSize=40&filterType=${mix_types}&product_ids=${sub.products.map(p => p.id)}&order_by_start_time=desc&currentPage=1&${sub.tag_filter === 'all' ? 'label_ids_all' : 'label_ids_any'}=${sub.labels.map(l => l.id)}`
    history.push(URL)
  }

  useEffect(() => {
    getSubscriptions();
    if (!tagsList) {
      getDiscoveriesLabels()
    }
  }, [])

  return (
    <div className="subscription-list-page">
      <h1 className="h1-title">
        My Subscriptions
      </h1>
      <SubscriptionButton onClick={() => openSubscriptionModalChange(true)} />
      <div className="hr-row"></div>
      {subscriptions && subscriptions.length > 0 && <div className="subscriptions-table-wrapper">


        <Table
          //@ts-ignore
          columns={columns}
          dataSource={subscriptions}
          pagination={false}
          rowKey="id"
        //rowClassName={(record, index) => record.tags && record.tags.length > 0 ? 'has-tags' : ''}
        />
      </div>}

      {subscriptions && subscriptions.length === 0 && <SubscriptionEmpty />}

      {openSubscriptionModal && <SubscriptionModal
        analytics={analytics}
        products={products}
        tagsList={tagsList}
        subscription={editSub}
        editSubscription={editSubscription}
        windowIsOpen={openSubscriptionModal}
        closeWindow={() => { openSubscriptionModalChange(false); editSubChange(null) }}
        createSubscription={createSubscription}
        licences={getUserLicence(userData)} />}

    </div>
  )
}

export default withRouter(
  //@ts-ignore
  connect((state: IState) => {
    return {
      user: state.appData.user,
      analytics: state.appData.user.analytics,
      subscriptions: state.subscriptions.subscriptionsList,
      subscriptionsError: state.subscriptions.subscriptionsError,
      tagsList: state.labels.tagsList ? [{ id: -1, name: 'critical insight' }, ...state.labels.tagsList] : null,
      userData: state.userData,
      products: state.overview.products,
    }
  }, {
    getSubscriptions,
    createSubscription,
    getDiscoveriesLabels,
    editSubscription,
    deleteSubscription
    //@ts-ignore
  })(SubscriptionsList))

