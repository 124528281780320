import React, { useState } from "react";
import "./menu-toggle.scss";
import { ReactComponent as MobileCloseIcon } from "assets/images/icons/newSideBar/MobileClose.svg";
import { ReactComponent as MobileOpenIcon } from "assets/images/icons/newSideBar/MobileOpen.svg";

export const MenuToggle = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = (open: boolean) => () => {
    const menuContainer = document.querySelector(".sidebar-wrap");
    const menu = document.querySelector(".nav2");
    
    if(!menuContainer || !menu) return;

    if(open) {
        menuContainer.classList.add("open");
        menu.classList.add("open");
    } else {
        menuContainer.classList.remove("open");
        menu.classList.remove("open");
    }

    setIsOpen(open);
  };

  return (
    <div className="menu-toggle">
      {isOpen ? (
        <MobileCloseIcon onClick={toggleOpen(false)} />
      ) : (
        <MobileOpenIcon onClick={toggleOpen(true)} />
      )}
    </div>
  );
};
