import { Consts } from '../shared/consts';

export const compareStrings = (string1, string2) => {
  if (!string1 || !string2 || typeof string1 !== 'string' || typeof string2 !== 'string') return null;
  return string1.toLowerCase().trim() === string2.toLowerCase().trim();
}

export const compareStringsArray = (string, arrString) => {
  if (!string || !arrString || !Array.isArray(arrString)) return null;
  arrString = arrString.map(item => item.toLowerCase().trim());
  string = string.toLowerCase().trim();
  if (arrString.indexOf(string) >= 0) {
    return true;
  }
  return false;
}

export const capitalizeFirstLetter = (text) => {
  return text.charAt(0).toUpperCase() + text.substr(1);
}

export const isReleasesType = (text) => {
  for (let str of Consts.releasesTypes) {
    if (compareStrings(text, str)) {
      return true;
    }
  }
  return false;
}

