import React, { useEffect, useState } from "react";
import "./ShareOfVoice.scss";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Entry, MultiSelect } from "../common/MultiSelect/MultiSelect";
import { App } from "containers/MarketingOverview/utils/types";
import clsx from "clsx";
import {
  calculateExactPercentages,
  fitStringToLength,
  isMobileDevice,
} from "containers/MarketingOverview/utils/helpers";
import { trackMarketingOverviewEvent } from "containers/MarketingOverview/utils/analytics";
import { Tooltip } from "antd";

type AppInsightsByFocusArea = {
  app: App;
  byFocusArea: {
    id: number;
    name: string;
    count: number;
    color: string;
    textColor: string;
  }[];
};

type FocusArea = {
  id: number;
  name: string;
  color: string;

  ids: number[];
};

type Props = {
  focusAreas: FocusArea[];
  appInsightsByFocusAreas: AppInsightsByFocusArea[];
};

const INITIAL_FOCUS_AREAS = 5;
const MAX_LABEL_LENGTH = 15;
const MAX_LABEL_LENGTH_MOBILE = 15;

export const ShareOfVoice = ({
  focusAreas,
  appInsightsByFocusAreas,
}: Props) => {
  const [currentFocusAreas, setCurrentFocusAreas] = useState<
    AppInsightsByFocusArea[]
  >([]);
  const isMobile = isMobileDevice();

  useEffect(() => {
    const initialFocusAreas = focusAreas
      .slice(0, INITIAL_FOCUS_AREAS)
      .map((focusArea) => ({
        ...focusArea,
        isSelected: true,
      }));

    handleMultiSelectChange(initialFocusAreas);
  }, [appInsightsByFocusAreas]);

  const handleMultiSelectChange = (entries: Entry[]) => {
    const selectedFocusAreas = entries
      .filter((entry) => entry.isSelected)
      .reduce<number[]>((acc, curr) => [...acc, ...(curr.ids || [])], []);

    // analytics for selection
    entries.forEach((entry) => {
      const isEntryAlreadySelected = currentFocusAreas.some(
        (currentFocusArea) =>
          currentFocusArea.byFocusArea.some(({ id }) => id === entry.id)
      );

      if (entry.isSelected === isEntryAlreadySelected) {
        return;
      }

      const appsWithFocusArea = appInsightsByFocusAreas.filter(
        (appInsightsByFocusArea) =>
          appInsightsByFocusArea.byFocusArea.find(({ id }) => id === entry.id)
      );

      trackMarketingOverviewEvent("chart_category_clicked", {
        app_names: appsWithFocusArea.map(({ app }) => app?.displayName),
        chart_name: "Share Of Voice",
        category_name: entry.name,
        is_selected: !!entry.isSelected,
      });
    });

    const newAppInsightsByFocusAreas = appInsightsByFocusAreas.map(
      (appInsightsByFocusArea) => ({
        ...appInsightsByFocusArea,
        byFocusArea: appInsightsByFocusArea.byFocusArea.filter((byFocusArea) =>
          selectedFocusAreas.includes(byFocusArea.id)
        ),
      })
    );

    const sortedAppInsightsByFocusAreas = newAppInsightsByFocusAreas.sort(
      (a, b) => {
        const aTotal = a.byFocusArea.reduce((acc, curr) => acc + curr.count, 0);
        const bTotal = b.byFocusArea.reduce((acc, curr) => acc + curr.count, 0);

        return bTotal - aTotal;
      }
    );

    setCurrentFocusAreas(sortedAppInsightsByFocusAreas);
  };

  // plugin to center text in doughnut chart
  const centerTextPlugin = {
    id: "centerTextPlugin",
    afterDatasetsDraw: (chart: any) => {
      const {
        ctx,
        data: { datasets },
      } = chart;

      ctx.save();

      const totalInsights: number = datasets[0].originalData.reduce(
        (acc: number, curr: number) => acc + curr,
        0
      );

      const x = chart.getDatasetMeta(0).data[0]._model.x;
      const y = chart.getDatasetMeta(0).data[0]._model.y;

      ctx.font = "16px Inter";
      ctx.fillStyle = "rgba(69, 69, 69, 0.64)";
      ctx.textAlign = "center";

      ctx.fillText("Total", x, y - 10);

      ctx.fillStyle = "#000000";
      ctx.font = "24px Inter";

      ctx.fillText(totalInsights.toString(), x, y + 15);
    },
  };

  const isAllEmpty = currentFocusAreas.every(
    (appInsightsByFocusArea) => appInsightsByFocusArea.byFocusArea.length === 0
  );

  return (
    <div className="sov-container">
      <MultiSelect
        selectTitle="Focus Areas"
        entries={focusAreas}
        onSelectionChange={handleMultiSelectChange}
      />
      <div className="sov-charts-container">
        {isAllEmpty && (
          <div className="sov-chart-container">
            <div style={{ height: isMobile ? 200 : 350 }} />
          </div>
        )}
        {currentFocusAreas.map(
          (appInsightsByFocusArea) =>
            appInsightsByFocusArea.byFocusArea.length > 0 && (
              <div
                className={clsx("sov-chart-container", {
                  "customer-app": appInsightsByFocusArea.app?.isCustomerOwnApp,
                })}
                key={appInsightsByFocusArea.app?.id}
              >
                <div className="left-part">
                  <img src={appInsightsByFocusArea.app?.icon} />
                  {appInsightsByFocusArea.byFocusArea.map((byFocusArea) => (
                    <div className="persona-container" key={byFocusArea.id}>
                      <div
                        className="persona-color"
                        style={{ backgroundColor: byFocusArea.color }}
                      />
                      <Tooltip
                        overlayClassName="info-header-tooltip"
                        title={byFocusArea.name}
                      >
                        <div className="persona-name">
                          {fitStringToLength(
                            byFocusArea.name,
                            isMobile
                              ? MAX_LABEL_LENGTH_MOBILE
                              : MAX_LABEL_LENGTH
                          )}
                        </div>
                      </Tooltip>
                      <div className="persona-count">{byFocusArea.count}</div>
                    </div>
                  ))}
                </div>
                <div className="right-part">
                  <Doughnut
                    width={isMobile ? 250 : 390}
                    height={isMobile ? 250 : 300}
                    plugins={[centerTextPlugin, ChartDataLabels]}
                    options={{
                      tooltips: {
                        enabled: true,
                      },
                      legend: {
                        display: false,
                      },
                      hover: {
                        mode: null,
                      },
                      plugins: {
                        datalabels: {
                          color: appInsightsByFocusArea.byFocusArea.map(
                            (byFocusArea) => byFocusArea.textColor
                          ),
                          font: {
                            size: isMobile ? 10 : 14,
                            family: "Inter",
                          },
                          formatter: (value: number, ctx: any) => {
                            const counts = ctx.chart.data.datasets[0].data;
                            const exactPercentages =
                              calculateExactPercentages(counts);
                            const percentage = exactPercentages[ctx.dataIndex];

                            return `${percentage}%`;
                          },
                        },
                      },
                    }}
                    data={{
                      labels: appInsightsByFocusArea.byFocusArea.map(
                        (byFocusArea) => byFocusArea.name
                      ),
                      datasets: [
                        {
                          data: appInsightsByFocusArea.byFocusArea.map(
                            (byFocusArea) => {
                              const totalInsights =
                                appInsightsByFocusArea.byFocusArea.reduce(
                                  (acc, curr) => acc + curr.count,
                                  0
                                );

                              const percentage =
                                (byFocusArea.count / totalInsights) * 100;

                              if (percentage < 3) {
                                return Math.round((3 / 100) * totalInsights);
                              }

                              return byFocusArea.count;
                            }
                          ),
                          originalData: appInsightsByFocusArea.byFocusArea.map(
                            (byFocusArea) => byFocusArea.count
                          ),
                          backgroundColor:
                            appInsightsByFocusArea.byFocusArea.map(
                              (byFocusArea) => byFocusArea.color
                            ),
                        },
                      ],
                    }}
                  />
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
};
