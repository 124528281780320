import React, { useEffect, useState } from "react";

const BASE_PLAYBACK_RATE = 0.3

function PerformanceVideoManager({
  videoURL,
  videoTime = 0,
  videoMode,
  videoCoords,
}) {
  let videoElementRef = React.createRef();

  const [videoReady, setVideoReady] = useState(false);

  useEffect(() => {
    if (videoElementRef && videoElementRef.current) {
      videoElementRef.current.onloadeddata = function (e) {
        setVideoReady(true);
        e.currentTarget.currentTime = videoCoords.start
        e.currentTarget.playbackRate = BASE_PLAYBACK_RATE
        videoCoords.end = e.currentTarget.duration
      };
    }
    return () => {
      if (videoElementRef && videoElementRef.current) {
        videoElementRef.current.onloadeddata = undefined;
        setVideoReady(false);
      }
    };
  }, []);


  useEffect(() => {
    if (videoReady &&
      videoElementRef &&
      videoElementRef.current
    ) {
      if (Number(videoTime) > Number(videoElementRef.current.duration) || Number(videoTime) <= 0) {
        videoElementRef.current.currentTime = videoElementRef.current.duration
      } else {
        videoElementRef.current.currentTime = videoTime + 1 / 3; // video has 0.3s part before real event started
      }
    }
  }, [videoTime]); 

  return (
    <div className="video-player-wrapper">
      <video
        preload="auto"
        ref={videoElementRef}
        className={``}
        src={videoURL}
      >
        This is a video placeholder
      </video>
      {videoMode && <div className={`timeline ${videoMode}`}>
        {videoTime.toFixed(1)}s
      </div>
      }
    </div>
  );
}

// WARN: no need  making this component memoized because videotime property constantly changing
export default PerformanceVideoManager;
