import {
  takeLatest,
  call,
  put,
  fork,
} from "redux-saga/effects";
import * as userApi from "../api/userApi";

import * as commentsApi from "../api/disComments";

import {
  GET_USER_DISCOVERIES,
  POST_USER_DISCOVERY_COMMENT,
  EDIT_USER_DISCOVERY_COMMENT,
  DELETE_USER_DISCOVERY_COMMENT,

  getUserDiscoveriesSuccess,
  postUserDiscoveryCommentSuccess,
  editUserDiscoveryCommentSuccess,
  deleteUserDiscoveryCommentSuccess
} from '../acts/userDiscoveries'


function* getUserDiscoveriesSaga(action) {
  const result = yield call(userApi.getUserDiscoveries)
  yield put(getUserDiscoveriesSuccess(result.data))
}

function* postUserDiscoveriesCommentSaga(action) {
  const result = yield call(commentsApi.postUserDiscoveryCommentAPI, action.payload)
  yield put(postUserDiscoveryCommentSuccess({
    insight_id: action.payload.insight_id,
    ...result.data.data
  }))
}
function* deleteUserDiscoveriesCommentSaga(action) {
  const result = yield call(commentsApi.deleteUserDiscoveryCommentAPI, action.payload)
  yield put(deleteUserDiscoveryCommentSuccess(action.payload))
}
function* editUserDiscoveriesCommentSaga(action) {
  const result = yield call(commentsApi.editUserDiscoveryCommentAPI, action.payload)
  yield put(editUserDiscoveryCommentSuccess({
    insight_id: action.payload.insight_id,
    comment_id: action.payload.comment_id,
    comment:result.data,
  }))
}


//watchers
function* watchGetUserDiscoveries() {
  yield takeLatest(GET_USER_DISCOVERIES, getUserDiscoveriesSaga);
}
function* watchPostUserDiscoveriesComment() {
  yield takeLatest(POST_USER_DISCOVERY_COMMENT, postUserDiscoveriesCommentSaga);
}
function* watchDeleteUserDiscoveriesComment() {
  yield takeLatest(DELETE_USER_DISCOVERY_COMMENT, deleteUserDiscoveriesCommentSaga);
}
function* watchEditUserDiscoveriesComment() {
  yield takeLatest(EDIT_USER_DISCOVERY_COMMENT, editUserDiscoveriesCommentSaga);
}

const userDiscoveriesSagas = [
  fork(watchGetUserDiscoveries),
  fork(watchPostUserDiscoveriesComment),
  fork(watchDeleteUserDiscoveriesComment),
  fork(watchEditUserDiscoveriesComment)
];

export default userDiscoveriesSagas;
