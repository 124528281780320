import React, { useMemo } from "react";

import { Checkbox, Button, Col, Tooltip } from "antd";
import "./UserTypeFilter.scss";
import {
  UserRoleType,
  UserType,
} from "containers/MarketingCampaings/utils/types";
import { ReactComponent as InfoIco } from "assets/images/icons/info-new-dark.svg";

type Props = {
  userTypes: UserType[];
  userRoleTypes: UserRoleType[];
  selectedUserTypes: UserType[];
  selectedUserRoleTypes: UserRoleType[];
  onSelect: (userTypeIds: number[], userRoleTypeIds: number[]) => void;
};

enum TypeKind {
  role = "role",
  user = "user",
}

const createTypeId = (id: number, typeKind: TypeKind) => {
  return `${id} ${typeKind}`;
};

const getIdAndItsKind = (typeId: string) => {
  const [id, kind] = typeId.split(" ");
  return { id: Number(id), kind };
};

export const UserTypeFilter = ({
  selectedUserTypes,
  selectedUserRoleTypes,
  userTypes,
  userRoleTypes,
  onSelect,
}: Props) => {
  const selectableUserTypes = useMemo(
    () => userTypes.filter((userType) => userType.roles.length === 0),
    [userTypes]
  );

  const typeIds = useMemo(() => {
    const userTypesIds = selectableUserTypes.map((selectableUserType) =>
      createTypeId(selectableUserType.id, TypeKind.user)
    );
    const userRoleTypesIds = userRoleTypes.map((userRoleType) =>
      createTypeId(userRoleType.id, TypeKind.role)
    );
    const result = userRoleTypesIds.concat(userTypesIds);
    return result;
  }, [selectableUserTypes, userRoleTypes]);

  const selectedTypeIds = useMemo(() => {
    const resultSelectedUserTypes = selectedUserTypes.map((item) =>
      createTypeId(item.id, TypeKind.user)
    );
    const resultSelectedUserRoleTypes = selectedUserRoleTypes.map((item) =>
      createTypeId(item.id, TypeKind.role)
    );
    const result = resultSelectedUserRoleTypes.concat(resultSelectedUserTypes);
    return result;
  }, [selectedUserTypes, selectedUserRoleTypes]);

  const handleSelectType = (selectedTypeIds: any[]) => {
    const selectedUserTypeIds: number[] = [];
    const selectedUserRoleTypeIds: number[] = [];
    selectedTypeIds.forEach((idAndItsKind) => {
      const { id, kind } = getIdAndItsKind(idAndItsKind);
      (kind === TypeKind.user
        ? selectedUserTypeIds
        : selectedUserRoleTypeIds
      ).push(id);
    });
    onSelect(selectedUserTypeIds, selectedUserRoleTypeIds);
  };
  return (
    <div className="user-type-popup">
      <div className="items-selected">
        <div className="items-selected-count">
          {`${selectedTypeIds.length} Selected`}
        </div>
        {selectedTypeIds.length !== 0 ? (
          <Button
            onClick={() => {
              onSelect([], []);
            }}
            className="all ant-btn-dark"
          >
            Clear
          </Button>
        ) : (
          <Button
            onClick={() => {
              const idsAndItsKind = typeIds.map((typeId) =>
                getIdAndItsKind(typeId)
              );
              const selectedUserTypeIds = idsAndItsKind
                .filter((item) => item.kind === TypeKind.user)
                .map((userType) => userType.id);
              const selectedUserRoleTypeIds = idsAndItsKind
                .filter((item) => item.kind === TypeKind.role)
                .map((userRoleType) => userRoleType.id);

              onSelect(selectedUserTypeIds, selectedUserRoleTypeIds);
            }}
            className="all ant-btn-dark"
          >
            All
          </Button>
        )}
      </div>
      <div className="filters-content">
        <Checkbox.Group onChange={handleSelectType} value={selectedTypeIds}>
          <div className="filters-content-checkbox-wrapper">
            {selectableUserTypes.map((userType) => (
              <CheckboxWrapper
                key={createTypeId(userType.id, TypeKind.user)}
                type={userType}
                typeKind={TypeKind.user}
              />
            ))}
            {userRoleTypes.map((userRoleType) => (
              <CheckboxWrapper
                key={createTypeId(userRoleType.id, TypeKind.role)}
                type={userRoleType}
                typeKind={TypeKind.role}
              />
            ))}
          </div>
        </Checkbox.Group>
      </div>
    </div>
  );
};

type CheckboxWrapperProps = {
  type: UserType | UserRoleType;
  typeKind: TypeKind;
};

const CheckboxWrapper = ({ type, typeKind }: CheckboxWrapperProps) => {
  return (
    <Col>
      <Checkbox
        className="user-type-popup-checkbox-group-item"
        value={createTypeId(type.id, typeKind)}
        style={{
          backgroundColor: type.bgColor,
          color: type.textColor,
        }}
      >
        <span className="user-type-popup-checkbox-group-tem-label-text">
          {typeKind === TypeKind.user
            ? (type as UserType).title
            : (type as UserRoleType).name}
        </span>
        <span className="user-type-popup-checkbox-group-tem-label-icon-container">
          <Tooltip
            overlayClassName="info-header-tooltip"
            placement="top"
            title={type.description ?? "No description"}
          >
            <InfoIco fill={type.textColor} />
          </Tooltip>
        </span>
      </Checkbox>
    </Col>
  );
};
