import React, {  useState, useLayoutEffect } from "react";
import mixPanel from "mixpanel-browser";
import { withLastLocation } from "react-router-last-location";
import awsService from "../../services/awsService";
import config from "../../shared/config";
import storageService from "../../services/storageService";
import { connect } from "react-redux";
import { Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { getLandPageForUser } from "../../helpers/usersHelper"
import {
  setUser,
  getAllAppsData,
  initDashboard,
  setAppsData,
  setSiteVersion,
  changePasswordFirstLogin,
} from "../../redux/actions/dashboardActions";
import {
  clearPerformancePageMetadata,
} from "../../redux/actions/performanceActions";
import {
  setCampaignDemoMode
} from "../../redux/actions/overviewActions";
import { getLinkData } from "../../redux/actions/directLinkActions";
import { siteVersions } from "../../shared/consts";

import Modal from "../../components/modal/Modal";
import ContactForm from "../../components/contactForm/ContactForm";
import { PasswordResetModal } from "./passwordResetModal/PasswordResetModal";
import { InputText } from "../../components/inputText/InputText";
import bgVideo from "../../assets/bg-video.mp4"
import logo from "../../assets/images/login/logo.png"
import analyticsService from "../../services/analyticsService";


function Login(props) {

  const aws = new awsService();
  const analytics = analyticsService.getInstance();

  const [email, emailChange] = useState("")
  const [password, passwordChange] = useState("")

  const [errorMsg, errorMsgChange] = useState("")
  const [loggedIn, loggedInChange] = useState(false)
  const [firstEnter, firstEnterChange] = useState(true)
  const [isLoading, isLoadingChange] = useState(false)

  const [isSSOLoading, isSSOLoadingChange] = useState(false)

  const [isModalOpen, isModalOpenChange] = useState(false)
  const [isConModalOpen, isConModalOpenChange] = useState(false)
  

  const redirectLogin = () => {
    let { lastLocation, location} = props;
    let pathname = "";

    if (lastLocation) {
      pathname = lastLocation.pathname;
    }
    if (location.state && location.state.fromDashboard) {
      pathname = "";
    }
    pathname = (pathname === "" ? getLandPageForUser(props.user) : pathname)
    props.setSiteVersion(siteVersions.FULL);
    window.location.href = pathname;
  };


  const handleModalOpen = (e) => {
    if (e) {
      isModalOpenChange(true)
    } else {
      isModalOpenChange(false)
      isConModalOpenChange(true)
    }
  }
  const handleModalClose = (e) => {
    if (e) e.stopPropagation();
    isModalOpenChange(false)
    isConModalOpenChange(false)
  }
  const ssoLogin = e => {
    isSSOLoadingChange(true)
  }
  const handleChange = type => event => {
    const { value } = event.target;
    if (type === "email") {
      emailChange(value)
    } else if (type === "password") {
      passwordChange(value)
    }
  };
  const handleSubmit = event => {
    event.preventDefault();
    isLoadingChange(true)
    errorMsgChange("")
    aws.login(email, password).then(onLogin, badLogin);
  };

  const badLogin = err => {
    if (!firstEnter) {
      errorMsgChange("Invalid username or password")
      isLoadingChange(false)
    }
    firstEnterChange(false)
    isLoadingChange(false)
  };
  const onLogin = token => {
    const storage = new storageService();
    errorMsgChange("")
    // check if get validate token
    if (token) {
      storage.setSessionItem("usertoken", token);
      // get user cognito details from aws token.
      const cognitoUser = aws.parseJwt(token);
      analytics.email = cognitoUser.email;
      aws.setCredentials(token);
      loggedInChange(true)
      const user = aws.getCurrentUser();
      analytics.username = user.username;
      if (config.mixpanelKey) {
        mixPanel.identify(cognitoUser.email);
        mixPanel.people.set({ $cognito: user.username });
        mixPanel.people.set({ $name: cognitoUser.email });
        analytics.analytic(`Login`)
      }
      props.setUser({ ...cognitoUser, analytics });
    } else {
      firstEnterChange(false)
    }
  };


  //EFFECTS
  useLayoutEffect(() => {
    const footer = document.getElementById("footer");
    if (footer) {
      footer.style.display = "none";
    }

    // check if user are already login(cognito)
    aws.authUser().then(onLogin, badLogin);

    return () => {
      if (footer) {
        footer.style.display = "block";
      }
    }
  }, [])

  if (loggedIn) {
    return redirectLogin();
  }



  const loginElement = <div className="side-block login-card">
    <h2>Sign In</h2>
    <form id="sign-up" method="post" name="sign-up" onSubmit={handleSubmit}>

      <InputText
        placeholder="Email"
        label="Email"
        type="email"
        value={email}
        onChange={handleChange("email")}
        autoFocus={true}
        disabled={isLoading}
      />
      <InputText
        label="Password"
        placeholder="Password"
        value={password}
        onChange={handleChange("password")}
        autoFocus={false}
        password
        disabled={isLoading}
      />

      <div className="bottom-wrapper">
        <input id="remember-checkbox" type="checkbox" tabIndex={3} />
        <label htmlFor="remember-checkbox">Remember Me</label>
        <PasswordResetModal onLogin={onLogin} />
        {/* <a href>Forgot Password?</a> */}
      </div>
      {errorMsg && <div className="login__form-field login__form-field-error">
        <span>{errorMsg}</span>
      </div>}
      <div className="btn-wrapper">
        <button type="submit" tabIndex={4} className="red-button"
          disabled={
            email === "" ||
            password === "" ||
            isLoading
          }>
          <div className={'login-text'}>Login</div>
          {isLoading && <div className={'spinner-wrapper'}>
            <Spin indicator={<LoadingOutlined
              style={{
                fontSize: 24,
                color: "white",
              }}
              spin

            />} />
          </div>
          }

        </button>
      </div>
      <div className="or-strike-through">
        <div className='strike-through' />
        <div className='or'>OR</div>
      </div>

      <div className='sso-button'>
        <button onClick={ssoLogin} disabled={isSSOLoading}>
          <div className={'login-with-sso-text'}>
            <a href={"https://auth.watchful.ai/login?response_type=token&client_id=1o8hfattv5d7pv8st57e1e3lum&redirect_uri=https://app.watchful.ai"}>Login with SSO</a>
          </div>
          {isSSOLoading && <div className={'spinner-wrapper'}>
            <Spin indicator={<LoadingOutlined
              style={{
                fontSize: 24,
                color: "red",
              }}
              spin

            />} />
          </div>}
        </button>
      </div>

    </form>
  </div>

  return (
    <div className="login" id="login-page">
      <Modal
        isModalOpen={isModalOpen}
        closeModal={handleModalClose}
      >
        <ContactForm
          closeModal={handleModalClose}
          openModal={handleModalOpen}
          isConModalOpen={isConModalOpen}
        />
      </Modal>
      <div className="video-wrapper">
        <video autoPlay muted loop id="bg-video">
          <source src={bgVideo} type="video/mp4" />
          Sorry, your browser doesn't support embedded videos.
        </video>
        <div className="video-overlay" />
      </div>
      <div className="login-wrapper">
        <div className="side-block login-intro">
          <a href="/">
            <img src={logo} alt="logo" />
          </a>

          <h3>Welcome to</h3>
          <h1>Watchful <strong>Platform</strong></h1>
          <p>The product intelligence solution trusted by <span className="cond-bold">FORTUNE</span> ® <span className="cond-bold">500</span> teams to stay ahead of their competition. </p>


          <div className="no-account">
            <div className="dashed-line" />
            <div className="no-account-wrapper">
              <span>Don’t have an account?</span>
              <a title="Request Account" type="button" href="https://www.watchful.ai/?noSp=1#get-demo-form">
                Request Account
              </a>
            </div>
          </div>
        </div>

        {loginElement}

      </div>
    </div>
  );
}

const mapStateToProps = ({ appData, linkData, userData }) => {
  return {
    apps: appData.apps,
    appSelect: appData.appSelect,
    linkData,
    user: appData.user,
    userData,
  };
};

export default withLastLocation(
  connect(mapStateToProps, {
    setUser,
    getAllAppsData,
    initDashboard,
    getLinkData,
    setAppsData,
    setSiteVersion,
    clearPerformancePageMetadata,
    setCampaignDemoMode,
    changePasswordFirstLogin,
  })(Login)
);
