export const abTestsTypes = {
  ACTIVE: "active",
  INTEGRATED: "integrated",
  ABANDONED: "abandoned",
  ABANDONED_IN_INTEGRATED: "abandoned_in_integrated"
};

export const marketingCampaignsTypes = {
  "email_campaigns": "Email",
  "inapp_campaigns": "In-App / On-Site",
  "push_notification_campaigns": "Push Notifications"
}

export const PL_WEB = 'Web'
export const PL_ANDROID = 'Android'
export const PL_IOS = 'iOS'
export const PL_WINDOWS = 'Windows'
export const PL_CHROME = 'Chrome Extension'
export const PL_MARKETING = 'Marketing'

export const abTestsOrder = [
  abTestsTypes.ACTIVE,
  abTestsTypes.INTEGRATED,
  abTestsTypes.ABANDONED
];

export const pagesTypes = {
  AB_TESTS: "ab_tests",
  INTELLIGENCE_OVERVIEW: "intelligence_overview",
  DASH_OVERVIEW: "dash_overview",
  INDICATIONS: "indications",
  SCREEN_CHANGES: "screen_changes",
  NEW_FEATURES: "new_features",
  FEATURE_REMOVED: 'removed_features',
  PRICING: "pricing",
  HASHFLAGS: "hashflags",
  VERSIONS: "versions",
  FIRST_PAGE: "overview",
  RAW_AB_TESTS_DATA: "raw_ab_tests",
  INTELLIGENCE: "intelligence",
  BENCHMARK: "benchmark",
  BENCHMARK_COMPARE: "benchmark_compare",
  BENCHMARK_RANKS: "benchmark_ranks",
  MARKETING: "marketing",
  MARKETING_CAMPAIGNS: "marketing_campaigns",
  MARKETING_MASS_MARKETING: "marketing_mass_marketing",
  CONTEXT_TIMELINE: 'context_timeline',
  ALL_DISCOVERIES: 'all_discoveries',
  REPORTSLIST: 'reports_list',
  REPORTSVIEW: 'reports_view',
  SUBSCRIPTIONS: 'subscriptions',
  EULA: 'eula',
  PRIVACY: 'privacy',
  PRODUCT_INTELLIGENCE: 'product_intelligence',
  TOPICS: 'topics',
  USER_JOURNEYS: 'user_journeys'
};

export const defaultPages = {
  'yahoo-corp': "benchmark/overview"
}

export const siteVersions = {
  FULL: "full",
  DEMO: "demo"
};

export const Consts = {
  experimentTypes: {
    ON: "on",
    OFF: "off"
  },
  releasesTypes: ["screen_change", "new_feature"],
  mobileSize: 600,
  tabPortSize: 900
};

export const versionInfoTypes = {
  STRINGS: "strings",
  SIZES: "size"
};

export const discoveryTypesHeaders = {
  ab_tests: "Experiments",
  indications: "Indications",
  screen_changes: "UI Changes",
  new_features: "New Features",
  removed_features: "Removed Features",
}

export const intelDiscoveryTypeDictionary = {
  ab_test: "Experiment",
  indication: "Indication",
  screen_change: "UI Change",
  new_feature: "New Feature",
  removed_feature: "Removed Feature",
  inapp_campaign: 'In-App Campaign',
  email_campaign: 'Email Campaign'
};

export const typeInfo ={
  ab_test: "A single or multi variant change that is tested on a controlled group to evaluate an hypothesis of a specific" +
    " desired outcome- such as increased conversion, engagement, or simply a more refined user experience. Experiments" +
    " be matured and evolved over time until they are integrated as a feature, or abandoned all-together.",
  indication: "Signals of a feature, or feature set, which are currently being developed but not yet deployed and are" +
    " not generally available/exposed. Typically, indications develop into experiments but they can also develop into" +
    " new features or UI changes.",
  screen_change: "Visual changes on an existing user interface that can consist of strings, layouts and designs changes.",
  new_feature: "New product functionalities & capabilities which were not part of a previous version of the app, and" +
    " are fully available to the intended user group.",
  removed_feature: "Features and functionalities that existed in the previous versions and were removed in this",
};

export const intelligenceOnlyType  = {
  ab_test: "Experiment",
  indication: "Indication",
  screen_change: "UI Change",
  new_feature: "New Feature",
  removed_feature: "Removed Feature",
};
export const marketingOnlyType  = {
  email_campaign: 'Email Campaign',
  inapp_campaign: 'In-App Campaign',
  push_notification_campaign: 'Push Notification',
};

export const UNSEEN = 'unseen'
export const VIEWED = 'viewed'
export const MEANINGFUL = 'meaningful'
export const FEATURED = 'featured'
export const MARKED = 'marked'

export const ALL_TIME = 1825
export const daysAgoList = [
  { title: "Last 7 Days", value: 7 },
  { title: "Last 30 Days", value: 30 },
  { title: "Last 60 Days", value: 60 },
  { title: "All Time", value: ALL_TIME } // Not really "All Time" but the last 5 years
]


export const mapStateLabels = {
  [UNSEEN]: 'Unseen Discoveries',
  [VIEWED]: 'Viewed Discoveries',
  [MEANINGFUL]: 'Significant Discoveries',
  [FEATURED]: 'Featured Discoveries',
  [MARKED]: 'Marked by me'
}

export const LOCATIONS_EXTRA = {
  "JP": {
    coords: {
      cx: 577,
      cy: 235
    },
    name: 'Tokyo',
    is_active: true,
    location: 'JP',
    dataRate: {
      up: '1000MB',
      down: '1000MB'
    },
  },
  "US": {
    coords: {
      cx: 90,
      cy: 225
    },
    name: 'San Jose (CA)',
    is_active: true,
    location: 'US',
    dataRate: {
      up: '500MB',
      down: '500MB'
    },
  },
  "IL": {
    coords: {
      cx: 378,
      cy: 225
    },
    name: 'Tel-Aviv',
    is_active: true,
    location: 'IL',
    dataRate: {
      up: '500MB',
      down: '500MB'
    },
  },
  "def": {
    coords: {
      cx: 10,
      cy: 10
    },
    name: 'default',
    is_active: true,
    location: 'XX',
    dataRate: {
      up: '0MB',
      down: '0MB'
    },
    samplesPerDay: []
  },
  "DE": {
    coords: {
      cx: 333,
      cy: 205
    },
    name: 'Germany',
    is_active: true,
    location: 'DE',
    dataRate: {
      up: '1000MB',
      down: '1000MB'
    },
    samplesPerDay: []
  },
  "IN": {
    coords: {
      cx: 473,
      cy: 270
    },
    name: 'India',
    is_active: true,
    location: 'IN',
    dataRate: {
      up: '500MB',
      down: '500MB'
    },
    samplesPerDay: []
  },
}

export const L_INTELLIGENCE = 'intelligence'
export const L_BENCHMARK = 'benchmark'
export const L_MARKETING = 'marketing'
export const LICENCES = [L_INTELLIGENCE, L_BENCHMARK, L_MARKETING]
export const LICENCES_DATA = {
  [L_INTELLIGENCE]: 'Product Intelligence',
  [L_BENCHMARK]: 'Product Benchmark',
  [L_MARKETING]:  'Marketing Campaigns'
}

// TODO: REMOVE
export const RESTRICTED_DOORDASH_EMAILS = [
  'test-deliveroo@deliveroo.com'
]

// TODO:REMOVE
export const daysAgoListRestricted = [
  { title: "Last 7 Days", value: 7 },
  { title: "Last 30 Days", value: 30 },
]