class storageService {

    setItem = (key, val) => {
      localStorage.setItem(key, val);
    }

    getItem = key => {
      return localStorage.getItem(key);
    }

    clear = () => {
      localStorage.clear();
      sessionStorage.clear();
    }

    setSessionItem = (key, val) => {
      sessionStorage.setItem(key, val);
    }

    getSessionItem = key => {
      return sessionStorage.getItem(key);
    }
}

export default storageService


