import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";

const breakpoints = [
  { query: "(max-height:870px)", tableHeight: "53vh" },
  { query: "(max-height:960px)", tableHeight: "55vh" },
  { query: "(max-height:1065px)", tableHeight: "50vh" },
  { query: "(max-height:1200px)", tableHeight: "55vh" },
];

const DefaultHeight = "60vh";

const useTableHeight = () => {
  const matchedQueries: boolean[] = [
    useMediaQuery(breakpoints[0].query),
    useMediaQuery(breakpoints[1].query),
    useMediaQuery(breakpoints[2].query),
    useMediaQuery(breakpoints[3].query),
  ];

  const tableHeight = React.useMemo(() => {
    const matchedBreakpoint = breakpoints.find(
      (_, index) => matchedQueries[index]
    );
    return matchedBreakpoint ? matchedBreakpoint.tableHeight : DefaultHeight;
  }, [
    matchedQueries[0],
    matchedQueries[1],
    matchedQueries[2],
    matchedQueries[3],
  ]);

  return tableHeight;
};

export default useTableHeight;
