import React, { useMemo, useState } from "react";
import "./IntelFilters.scss";
import caret_ico from "assets/images/icons/caret-down.svg";
import { Popover } from "antd";
import { AppDrawer } from "components/AppDrawer";
import { Persona, Product } from "pages/Intel/utils/types";
import { HandleIntelFilterChangeType } from "pages/Intel/hooks/useIntelFilters";
import moment, { Moment } from "moment";
import { daysAgoList } from "shared/consts";
import { PlatformDrawer } from "./PlatformDrawer";
import ios_ico from "assets/images/icons/newIntel/ios.svg";
import android_ico from "assets/images/icons/newIntel/android.svg";
import web_ico from "assets/images/icons/newIntel/web.svg";
import { isMobileDevice } from "containers/MarketingOverview/utils/helpers";
import { PLATFORM } from "pages/Intel/utils/consts";
import { TimeFilter } from "components/TimeFilter";
import clsx from "clsx";
import { trackIntelEvent } from "pages/Intel/utils/analytics";
import device_ico from "assets/images/icons/device.svg";
import dna_ico from "assets/images/icons/dna.svg";
import calendar_ico from "assets/images/icons/calendar.svg";
import x_ico from "assets/images/icons/x-no-outline.svg";

type Props = {
  daysAgo: number;
  customRange: boolean;
  customRangeTime: Moment[];
  platforms: string[];
  handleUpdateFilter: HandleIntelFilterChangeType;
  products: Product[];
  selectedProductIds: number[];
  personas: Persona[];
  selectedPersonaIds: number[];
};

const MAX_APPS_TO_DISPLAY = 3;

const PLATFORM_TO_IMAGE = {
  ios: ios_ico,
  android: android_ico,
  web: web_ico,
};

export const IntelFilters = ({
  selectedProductIds,
  customRange,
  customRangeTime,
  daysAgo,
  handleUpdateFilter,
  platforms,
  products,
  personas,
  selectedPersonaIds,
}: Props) => {
  const isMobile = isMobileDevice();
  const [platformsFilterOpen, setPlatformsFilterOpen] = useState(false);
  const [timeFrameOpen, setTimeFrameOpen] = useState(false);
  const [appsOpen, setAppsOpen] = useState(false);
  const timeFrameStartDate = customRange
    ? customRangeTime[0]
    : moment().subtract(daysAgo, "days");
  const timeFrameEndDate = customRange ? customRangeTime[1] : moment();

  const productsList = products.map((product) => ({
    id: product.id,
    name: product.name,
    thumbnail: product.icon,
  }));
  const selectedProducts =
    selectedProductIds.length > 0
      ? products.filter((app) => selectedProductIds.includes(app.id))
      : [];

  const platformCounts = useMemo(() => {
    const counts: Record<keyof typeof PLATFORM, number> = {
      ios: 0,
      android: 0,
      web: 0,
    };
    const currentProducts = selectedProductIds.length
      ? selectedProducts
      : products;

    currentProducts.forEach(({ apps }) => {
      apps.forEach(({ platform, insightsCnt }) => {
        counts[platform.toLowerCase() as keyof typeof PLATFORM] += insightsCnt;
      });
    });

    return counts;
  }, [selectedProducts]);

  const selectedPersonas = personas.filter((persona) =>
    selectedPersonaIds.includes(persona.id)
  );

  const handleSelectProduct = (product: { id: number }) => {
    const productId = product.id.toString();
    const productIdStrings = selectedProductIds.map((id) => id.toString());

    if (productIdStrings.includes(productId)) {
      handleUpdateFilter(
        "product_id",
        productIdStrings.filter((id) => id !== productId)
      );

      return;
    }

    const selectedProduct = products.find((p) => p.id === product.id);

    if (selectedProduct) {
      trackIntelEvent("intel_apps_filter", {
        selected_app_name: selectedProduct?.name,
      });
    }

    handleUpdateFilter("product_id", [...productIdStrings, productId]);
  };

  const handleSelectAllProducts = () => {
    const productIds = products.map((product) => product.id.toString());

    products.forEach((p) =>
      trackIntelEvent("intel_apps_filter", { selected_app_name: p.name })
    );

    handleUpdateFilter("product_id", productIds);
  };

  const handleClearAllProducts = () => {
    handleUpdateFilter("product_id", []);
  };

  const handleDaysAgoChange = (daysAgo: number) => {
    trackIntelEvent("intel_time_interval_filter", {
      number_of_days: daysAgoList.find((d) => d.value === daysAgo)?.value || 30,
    });

    handleUpdateFilter("days_ago", daysAgo.toString());
    handleUpdateFilter("custom_range", "");
    handleUpdateFilter("custom_range_time", []);
    setTimeFrameOpen(false);
  };

  const handleCustomRangeChange = (customRange: boolean) => {
    handleUpdateFilter("custom_range", customRange.toString());
  };

  const handleCustomRangeChangeTimeChange = (args: Moment[]) => {
    const startDate = args[0].toISOString();
    const endDate = args[1].toISOString();

    trackIntelEvent("intel_time_interval_filter", {
      number_of_days: Math.round(
        Math.abs(new Date(startDate).getTime() - new Date(endDate).getTime()) /
          (1000 * 60 * 60 * 24)
      ),
    });

    handleUpdateFilter("days_ago", "");
    handleUpdateFilter("custom_range_time", [startDate, endDate]);
  };

  const onPlatformSelect = (selectedPlatforms: string[]) => {
    const newSelectedPlatform = selectedPlatforms.filter(
      (platform) => platforms.indexOf(platform) === -1
    );

    newSelectedPlatform.forEach((p) => {
      trackIntelEvent("intel_platform_filter", { selected_platform: p });
    });

    handleUpdateFilter("platform", selectedPlatforms);
  };

  const onPersonaSelect = (personaIds: number[]) => {
    handleUpdateFilter(
      "persona_id",
      personaIds.map((id) => id.toString())
    );
  };

  return (
    <>
      <div className="i-top-section">
        <div className="i-filters">
          <div className="i-header-container">
            <div className="header">
              <h1 className="i-heading">Discoveries</h1>
              <div className="i-filters-container">
                <Popover
                  placement="bottom"
                  title={null}
                  destroyTooltipOnHide={true}
                  content={
                    <AppDrawer
                      minAppsSelected={false}
                      appsList={productsList}
                      currentAppsIds={selectedProducts.map(
                        (product) => product.id
                      )}
                      onSelectApp={handleSelectProduct}
                      onClearSelection={handleClearAllProducts}
                      onSelectAll={handleSelectAllProducts}
                      closeDrawer={() => setAppsOpen(false)}
                    />
                  }
                  open={appsOpen}
                  onOpenChange={(open) => setAppsOpen(open)}
                  trigger="click"
                  overlayClassName={clsx(
                    "popover-appdrawer  filter-wrap   all-discoveries all-discoveries-apps fixed-filters",
                    {
                      "mobile-full-screen": isMobile,
                    }
                  )}
                >
                  <div
                    className="select-filter"
                    style={{ minWidth: isMobile ? "18%" : "125px" }}
                  >
                    <div className="select-body">
                      {!isMobile ? (
                        <>
                          <img src={device_ico} />
                          <span>Apps ({selectedProducts.length})</span>
                          <img src={caret_ico} />
                        </>
                      ) : (
                        <>
                          <span>Apps</span>
                          <span className="select-body-count">
                            {selectedProducts.length}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </Popover>
                <Popover
                  placement="bottom"
                  title={null}
                  destroyTooltipOnHide={true}
                  content={
                    <PlatformDrawer
                      onPlatformSelect={onPlatformSelect}
                      selectedPlatforms={platforms}
                      platformCounts={platformCounts}
                      closeDrawer={() => setPlatformsFilterOpen(false)}
                    />
                  }
                  open={platformsFilterOpen}
                  onOpenChange={(open) => setPlatformsFilterOpen(open)}
                  overlayClassName={clsx("fixed-filters", {
                    "mobile-full-screen": isMobile,
                  })}
                  trigger="click"
                >
                  <div
                    className="select-filter"
                    style={{ minWidth: isMobile ? "25%" : "130px" }}
                  >
                    <div className="select-body">
                      {!isMobile ? (
                        <>
                          <img src={dna_ico} />
                          <span>Platform ({platforms.length})</span>
                          <img src={caret_ico} />
                        </>
                      ) : (
                        <>
                          <span>Platforms</span>
                          <span className="select-body-count">
                            {platforms.length}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </Popover>
                {/* Temporary disable user types filter SQUAD2-1329 */}
                {/* <Popover
                  placement="bottom"
                  title={null}
                  destroyTooltipOnHide={true}
                  content={
                    <UserTypeFilter
                      onSelectUserTypes={onPersonaSelect}
                      selectedUserTypes={selectedPersonas}
                      userTypes={personas}
                    />
                  }
                  trigger="click"
                >
                  <div
                    className="select-filter"
                    style={{ minWidth: isMobile ? "29%" : "200px" }}
                  >
                    <span className="select-filter-title">User Types</span>
                    <div className="select-body">
                      {!isMobile ? (
                        <>
                          <span>
                            {selectedPersonas.length > 0
                              ? `${selectedPersonas.length} Selected`
                              : "Select User Types"}
                          </span>
                          <img src={caret_ico} />
                        </>
                      ) : (
                        <>
                          <span>User Types</span>
                          <span className="select-body-count">
                            {selectedPersonas.length}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </Popover> */}
                <Popover
                  placement="bottom"
                  title={null}
                  destroyTooltipOnHide={true}
                  open={timeFrameOpen}
                  onOpenChange={(open) => setTimeFrameOpen(open)}
                  content={
                    <TimeFilter
                      isOpen
                      daysAgoList={daysAgoList}
                      daysAgo={daysAgo}
                      daysAgoChange={handleDaysAgoChange}
                      customRange={customRange}
                      сustomRangeСhange={handleCustomRangeChange}
                      customRangeTime={customRangeTime}
                      customRangeTimeChange={handleCustomRangeChangeTimeChange}
                      oneDay={false}
                      onClose={() => setTimeFrameOpen(false)}
                    />
                  }
                  trigger="click"
                  overlayClassName={clsx(
                    "filter-wrap all-discoveries all-discoveries-time fixed-filters",
                    {
                      "mobile-full-screen": isMobile,
                    }
                  )}
                  overlayStyle={{ width: isMobile ? "35rem" : "75rem" }}
                >
                  <div
                    className="select-filter"
                    style={{ minWidth: isMobile ? "24%" : "180px" }}
                  >
                    <div className="select-body">
                      <img src={calendar_ico} />
                      <div className="select-body-time-frame">
                        <div className="dates">
                          {`${timeFrameStartDate.format(
                            "MMM DD, YYYY"
                          )} - ${timeFrameEndDate.format("MMM DD, YYYY")}`}
                        </div>
                      </div>
                      <img src={caret_ico} />
                    </div>
                  </div>
                </Popover>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="i-selected-filters">
        {selectedProducts.map((product) => (
          <div className="i-select-option" key={product.id}>
            <img className="filter-icon" src={product.icon} />
            <img
              src={x_ico}
              className="close-icon"
              onClick={() => handleSelectProduct({ id: product.id })}
            />
          </div>
        ))}
        {platforms.map((platform) => (
          <div className="i-select-option" key={platform}>
            <span>{platform === "ios" ? "IOS" : platform}</span>
            <img
              src={x_ico}
              className="close-icon"
              onClick={() =>
                onPlatformSelect(platforms.filter((p) => p !== platform))
              }
            />
          </div>
        ))}
      </div>
    </>
  );
};
