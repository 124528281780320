import React from 'react'
import { Button } from 'antd';
import Subscriptions_ico from "../../../assets/images/new_design/sub-btn.svg"

import "./subscriptionButton.scss"

export default function SubscriptionButton({
  onClick
}) {

  return (
    <Button onClick={onClick} className="btn-create-subscription">
      <img src={Subscriptions_ico} />
      Create Subscription
    </Button>
  )
}
