import React, { useEffect } from "react";
import "./styles.scss";
import { Row } from "antd";
import { ContextTimelineProvider } from "./context";
import { Header } from "./components/header";
import { Sidebar } from "./components/sidebar";
import { Main } from "./components/main";
import { RouteComponentProps, withRouter } from "react-router";

const ContextTimelinePageBase = ({ history }: RouteComponentProps) => {
  // to remove global styling
  useEffect(() => {
    const dashboardContainer = document.querySelector(".dashboard__container");
    const searchAccount = document.querySelector(".search-account");

    if (!dashboardContainer || !searchAccount) return;

    dashboardContainer.classList.add("dashboard__container--context-timeline");
    searchAccount.classList.add("searchAccount--context-timeline");

    return () => {
      dashboardContainer.classList.remove(
        "dashboard__container--context-timeline"
      );
      searchAccount.classList.remove("searchAccount--context-timeline");
    };
  }, []);

  return (
    <ContextTimelineProvider history={history}>
      <Row>
        <Header />
        <Sidebar />
        <Main />
      </Row>
    </ContextTimelineProvider>
  );
};

export const ContextTimelinePage = withRouter(ContextTimelinePageBase);
