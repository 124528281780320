import * as aT from "../actions/_actionsTypes";

const INIT_STATE = {
  first_name: null,
  last_name: null,
  picture_url: null,
  role: null,
  department: null,
  phone: null,
  new_preview_picture_url: null,
  name: null,
  dataFetched: false,
  errorChangePass: null,
  successPassChange: false,
  successUserDataChange: null,
  locations: [],
  pages: [],
  subscriptions: [],
  tokenExpireMailSent: null,
  email_preferences: null,
  userNotCreatedMessage: null,
  days_to_trial_expiration: null,
  subs_modal_show_at: null,
  is_org_admin: false,
  userDataFetched: false,
};


export default function (state = INIT_STATE, action) {
  switch (action.type) {
  case aT.GET_USER_NAME_SUCCESS: {
    const { first_name, last_name, picture_url, role, department, phone,
      name, extra, locations_enabled, email_preferences, days_to_trial_expiration, subs_modal_show_at, is_org_admin } = action.payload;
    if (extra.pages.includes('marketing') || extra.pages.includes('intelligence')) {
      extra.pages.push('subscriptions')
    }
    return {
      ...state,
      first_name,
      last_name,
      name,
      picture_url,
      role,
      department,
      phone,
      email_preferences,
      is_org_admin,
      locations: locations_enabled,
      pages: extra.pages,
      features: extra.features,
      dataFetched: true,
      days_to_trial_expiration,
      subs_modal_show_at,
      userDataFetched: true
    };
  }

  case aT.SET_USER_NAME_SUCCESS: {
    const  id = action.payload?.data?.id

    return {
      ...state,
      id: id || state.id,
      successUserDataChange: action.payload.isCreate ? 'created' : 'changed'
    };
  }

  case aT.SET_USER_NAME_FAILED: {
    const error = action.payload;
    return {
      ...state,
      userNotCreatedMessage: error.message
    }
  }

  case aT.SET_USER_NAME_SUCCESS_RESET: {
    return {
      ...state,
      successUserDataChange: null,
      new_preview_picture_url: null
    };
  }

  case aT.SET_USER_PIC_SUCCESS: {
    const { picture_url } = action.payload.data;
    return {
      ...state,
      new_preview_picture_url: picture_url,
    };
  }



  case aT.SET_USER_PASS_ERROR: {
    return {
      ...state,
      errorChangePass: action.payload.response.data
    };
  }

  case aT.SET_USER_PASS_SUCCESS: {
    return {
      ...state,
      successPassChange: true
    };
  }


  case aT.SET_USER_PASS_SUCCESS_RESET: {
    return {
      ...state,
      errorChangePass: null,
      successPassChange: null,
    };
  }


  case aT.DELETE_USER_PIC_SUCCESS: {
    return {
      ...state,
      new_preview_picture_url: null,
      picture_url: null
    };
  }

  case aT.SET_EMAIL_PREFERENCES_SUCCESS: {
    const { email_preferences } = action.payload;
    return {
      ...state,
      email_preferences,
    }
  }

  case aT.CHANGE_PASSWORD_ON_FIRST_LOGIN: {
    return {
      ...state,
      successPassChange: false,
      errorChangePass: null,
    }
  }
  case aT.CHANGE_PASSWORD_ON_FIRST_LOGIN_SUCCESS: {
    return {
      ...state,
      successPassChange: true,
      errorChangePass: null,
    }
  }
  case aT.CHANGE_PASSWORD_ON_FIRST_LOGIN_FAILURE: {
    const { error } = action.payload;
    return {
      ...state,
      successPassChange: false,
      errorChangePass: action.payload,
    }
  }
  case aT.CHECK_NONCE_EXPIRED_SUCCESS: {
    return {
      ...state,
      tokenExpireMailSent: true,
    }
  }
  case aT.CHECK_NONCE_EXPIRED_FAILURE: {
    return {
      ...state,
      tokenExpireMailSent: false,
      errorChangePass: action.payload,
    }
  }

  case aT.SEND_NEW_CHANGE_PWD_MAIL_SUCCESS: {
    return {
      ...state,
      tokenExpireMailSent: true
    }
  }


  default:
    return state;
  }
}
