import { Event } from "pages/ContextTimeline/context/types";
import React from "react";
import "./styles.scss";
import { ListCard } from "./list-card";

export type Card = {
  id: number;
  date?: string;
  events?: Event[];
  pushCount?: number;
  emailCount?: number;

  isGap?: boolean;
  gap?: number;
};

type Props = {
  cards: Card[];
};

export const ScrollableCardsList = ({ cards }: Props) => {
  return (
    <div className="ctl-cards-list">
      {cards.map((card) => (
        <ListCard key={card.date} card={card} />
      ))}
    </div>
  );
};
