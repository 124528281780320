import axios from "axios";
import config from "../../shared/config";

export const getUserFlowsApps = () => {
  const url = `${config.dataApi.backendUrl}/user-flows/apps`;
  return axios.get(url);
};

type GetUserFlowsProps = {
  app_id?: string;
  persona_id?: string;
  app_ids?: string[];
};

export const getUserFlows = ({
  app_id,
  persona_id,
  app_ids,
}: GetUserFlowsProps) => {
  const url = `${config.dataApi.backendUrl}/user-flows`;
  return axios.get(url, {
    params: {
      app_id,
      persona_id,
      ...(app_ids && app_ids.length > 0
        ? { app_ids: app_ids.join(",") }
        : null),
    },
  });
};

export const getUserFlowsSets = ({ app_id, persona_id }: GetUserFlowsProps) => {
  const url = `${config.dataApi.backendUrl}/user-flows/sets/last`;
  return axios.get(url, {
    params: {
      app_id,
      persona_id,
    },
  });
};

export const getUserFlowsPersonas = ({ app_id }: GetUserFlowsProps) => {
  const url = `${config.dataApi.backendUrl}/user-flows/personas`;
  return axios.get(url, {
    params: {
      app_id,
    },
  });
};

type GetUserFlowsSetsLastPerAppProps = {
  user_flow_id: string;
  app_ids?: string[];
  persona_id?: string;
};

export const getUserFlowsSetsLastPerApp = ({
  app_ids,
  persona_id,
  user_flow_id,
}: GetUserFlowsSetsLastPerAppProps) => {
  const url = `${config.dataApi.backendUrl}/user-flows/${user_flow_id}/sets/last-per-app`;
  return axios.get(url, {
    params: {
      ...(app_ids && app_ids.length > 0
        ? { app_ids: app_ids.join(",") }
        : null),
      persona_id,
    },
  });
};

type GetUserFlowSetHistoryProps = {
  user_flow_id: string;
  app_id: string;
  persona_id: string;
};

export const getUserFlowSetHistory = ({
  user_flow_id,
  app_id,
  persona_id,
}: GetUserFlowSetHistoryProps) => {
  const url = `${config.dataApi.backendUrl}/user-flows/${user_flow_id}/sets`;
  return axios.get(url, {
    params: {
      app_id,
      persona_id,
    },
  });
};
