import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'

import { Popover, Button, Select, Input } from 'antd';
import AppDrawer from "../../common/appDrawer/AppDrawer"

import FilterState from './FilterState'
import FilterType from './FilterType'
import FilterTime from './FilterTime'
import FilterSearchQuery from "./FilterSearchQuery"


import GalleryDiscoveries from './GalleryDiscoveries'

import moment from 'moment';

import VersionsEmpty from "../products-discoveries/versionsEmpty/versionsEmpty"

import NewLoader from "../../common/NewLoader/NewLoader";
import FilterSelectBox from "../../components/FiltersSelectBox/index"
import FilterTags from "../../components/FilterTags/index"

import { intelDiscoveryTypeDictionary } from "../../shared/consts"
import { INSIGHT_CAMPAIGN_SUBTYPE } from '../intelligence/Marketing/configsSubtypes'

import { mapStateLabels, UNSEEN, VIEWED, MARKED, MEANINGFUL, FEATURED, ALL_TIME, daysAgoList, abTestsTypes } from '../../shared/consts.js'

import { getDiscoveries, getProducts } from "../../redux/actions/overviewActions"
import { getDiscoveriesLabels } from "../../redux/acts/labels"

import { useDebounce } from "../../helpers/customHooks"
import { MATCH_TYPES } from "../intelligence/Marketing/FilterTypes";

const { Option } = Select



export const DashDiscoveries = ({
  apps,
  discoveries,
  products,
  loadingFilteredDiscoveries,
  user,
  analytics,
  getDiscoveries,
  getProducts,
  history,
  userData,
  getDiscoveriesLabels,
  tagsList
}) => {

  const url = new URLSearchParams(history.location.search)

  const u = (k) => url.get(k)

  const [daysAgo, daysAgoChange] = useState(u('daysAgo') ? Number(u('daysAgo')) : ALL_TIME)
  const [customRange, сustomRangeСhange] = useState(u('customRange') ? true : false)

  const [customRangeTime, customRangeTimeChange] = useState(u('customRangeTime') ? [moment(u('customRangeTime').split(",")[0]),

    moment(u('customRangeTime').split(",")[1])] : [moment(), moment()])

  const [currentProducts, currentProductsChange] = useState(u('product_ids') ? u('product_ids').split(",").map(a_id => Number(a_id)) : [])

  const [searchQuery, searchQueryChange] = useState(u('searchQuery') || '')
  const [sortOrder, sortOrderChange] = useState(u('order_by_start_time') || 'desc')

  const [filterIntelEnabled, filterIntelEnabledChange] = useState(u('filterIntelEnabled') ? Boolean(u('filterIntelEnabled')) : false)
  const [filterMarkEnabled, filterMarkEnabledChange] = useState(u('filterMarkEnabled') ? Boolean(u('filterMarkEnabled')) : false)
  const [filterStatus, filterStatusChange] = useState(u('filterStatus') ? u('filterStatus').split(",") : [])
  const [filterType, filterTypeChange] = useState(u('filterType') ? u('filterType').split(",") : [])
  const [filterTags, filterTagsChange] = useState(u('label_ids_any') ? u('label_ids_any').split(",") : u('label_ids_all') ? u('label_ids_all').split(",") : [])
  const [filterTagsMatch, setFilterTagsMatch] = useState(u('label_ids_any') ? MATCH_TYPES[0] : u('label_ids_all') ? MATCH_TYPES[1] : MATCH_TYPES[0])
  const [filterEmailSubtype, filterEmailSubtypeChange] = useState(u('filterEmailSubtype') ? u('filterEmailSubtype').split(",") : [])
  const [filterInAppSubtype, filterInAppSubtypeChange] = useState(u('filterInAppSubtype') ? u('filterInAppSubtype').split(",") : [])
  const [filterPushNotificationSubtype, filterPushNotificationSubtypeChange] = useState(u('filterPushNotificationSubtype') ? u('filterPushNotificationSubtype').split(",") : [])

  const [currentPage, currentPageChange] = useState(u('currentPage') ? Number(u('currentPage')) : 1)
  const [currentPageSize, currentPageSizeChange] = useState(u('currentPageSize') ? Number(u('currentPageSize')) : 40)


  const [isSticky, setSticky] = useState(false);

  const [isOpenTimeFilter, isOpenTimeFilterChange] = useState(false)
  const ref = useRef(null);

  const requestDiscoveries = (page = 1) => {
    if (!products) {
      return
    }
    if (!discoveries) {
      //first request
      page = currentPage
    }
    const appsFromProducts =
      currentProducts.reduce((acc, product_id) => [...acc, ...products.find(p => p.id === product_id).apps.map(app => app.id)], []).join()
    const vars = {
      num_items: currentPageSize,
      app_id_only: appsFromProducts,
      order_by_start_time: sortOrder,
      page_num: page,
      query: searchQuery
    }
    if (customRange) {
      vars['start_time_gte'] = customRangeTime[0].format('YYYY-MM-DD')
      vars['start_time_lte'] = moment(customRangeTime[1]).format('YYYY-MM-DD')
    } else {
      vars['days_ago'] = daysAgo
    }
    if (filterType.length > 0) {
      vars['type'] = filterType.join()
    } else {
      vars['type'] = ''
    }
    const labelsField = filterTagsMatch === MATCH_TYPES[0] ? 'label_ids_any' : 'label_ids_all';
    if (filterTags.length > 0) {
      vars[labelsField] = filterTags.join()
    } else {
      vars[labelsField] = ''
    }
    if (filterInAppSubtype.length > 0) {
      vars['type'] += `,inapp_campaign:${filterInAppSubtype.join('|')}`
    }
    if (filterEmailSubtype.length > 0) {
      vars['type'] += `,email_campaign:${filterEmailSubtype.join('|')}`
    }
    if (filterPushNotificationSubtype.length > 0) {
      vars['type'] += `,push_notification_campaign:${filterPushNotificationSubtype.join('|')}`
    }
    if (vars['type'] === '') {
      delete vars['type']
    }
    if (vars['type'] && vars['type'][0] === ',') {
      vars['type'] = vars['type'].slice(1)
    }
    if (filterStatus.includes(UNSEEN) && !filterStatus.includes(VIEWED)) {
      vars['read'] = 0
    } else if (filterStatus.includes(VIEWED) && !filterStatus.includes(UNSEEN)) {
      vars['read'] = 1
    }
    if (filterStatus.includes(MEANINGFUL)) {
      vars['relevancy_gte'] = 1
    }
    if (filterStatus.includes(FEATURED)) {
      vars['featured_only'] = 1
    }    
    if (filterStatus.includes(MARKED)) {
      vars['liked_only'] = 1
    }
    vars['ab_test_status'] = []
    if (filterStatus.includes(abTestsTypes.INTEGRATED)) {
      vars['ab_test_status'].push('integrated')
    }
    if (filterStatus.includes(abTestsTypes.ABANDONED)) {
      vars['ab_test_status'].push('abandoned,abandoned_no_data')
    }
    if (filterStatus.includes(abTestsTypes.ACTIVE)) {
      vars['ab_test_status'].push('active')
    }
    const gets = new URLSearchParams(vars).toString()

    updateURL(page)

    getDiscoveries({
      gets: gets
    })
  }

  const updateURL = (page) => {
    let dataForURL = {
      daysAgo,
      currentPageSize,
      filterIntelEnabled: filterIntelEnabled ? '1' : '',
      filterMarkEnabled: filterMarkEnabled ? '1' : '',
      customRange: customRange ? '1' : '',
      filterStatus,
      searchQuery,
      filterType,
      filterEmailSubtype,
      filterInAppSubtype,
      filterPushNotificationSubtype,
      product_ids: currentProducts.join(),
      order_by_start_time: sortOrder,
      currentPage: page || currentPage,
      customRangeTime: customRangeTime.map(date => date.format('YYYY-MM-DD')),
      [filterTagsMatch === MATCH_TYPES[0] ? 'label_ids_any' : 'label_ids_all']: filterTags.join()
    }
    let getsURL = new URLSearchParams(dataForURL).toString()

    history.replace(`${history.location.pathname}?${getsURL}`);
    if (page) {
      currentPageChange(page)
    }

    if (discoveries && discoveries.data) {
      analytics.analytic('All-Discoveries Page', { ...dataForURL, numOfDiscoveries: discoveries.data.length })
    }
  }

  useEffect(updateURL, [searchQuery])

  const debouncedRequest = useDebounce(requestDiscoveries, 800)

  useEffect(debouncedRequest,
    [daysAgo,
      currentProducts,
      sortOrder,
      filterStatus,
      currentPageSize,
      filterType,
      filterEmailSubtype,
      filterInAppSubtype,
      filterPushNotificationSubtype,
      filterIntelEnabled,
      filterTags,
      filterMarkEnabled,
      customRange,
      customRangeTime,
      searchQuery,
      filterTagsMatch,
    ])


  const handleAppChange = (product) => {
    if (currentProducts.indexOf(product.id) !== -1) {
      currentProductsChange(currentProducts.filter(ca => ca != product.id))
    } else {
      currentProductsChange([...currentProducts, product.id])
    }
  }



  const onShowSizeChange = (current, pageSize) => {
    currentPageSizeChange(pageSize)
    currentPageChange(1)
  }

  const onPageChange = (page) => {
    currentPageChange(page)
    requestDiscoveries(page)
  }

  const handleScroll = () => {
    if (ref.current) {
      if (window.pageYOffset >= 166 || window.scrollY >= 166) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    }
  };

  const handleAllReset = () => {
    daysAgoChange(ALL_TIME)
    currentProductsChange([])
    searchQueryChange('')
    sortOrderChange('desc')
    filterIntelEnabledChange(false)
    filterMarkEnabledChange(false)
    filterStatusChange([])
    filterTypeChange([])
    filterTagsChange([])
    filterEmailSubtypeChange([])
    filterPushNotificationSubtypeChange([])
    filterInAppSubtypeChange([])
    currentPageChange(1)
    currentPageSizeChange(40)
    сustomRangeСhange(false)
  }

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    if (!tagsList) {
      getDiscoveriesLabels()
    }
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (!discoveries) {
    return <div className="all-dis-loader">
      <NewLoader />
    </div>
  }

  const filteredDiscoveries = discoveries.data

  const productQTY = Object.keys(intelDiscoveryTypeDictionary).length + INSIGHT_CAMPAIGN_SUBTYPE.length * 3;
  const selectedProductQTY = filterType.length + filterEmailSubtype.length + filterInAppSubtype.length + filterPushNotificationSubtype.length;

  return (
    <div className="dash-discoveries-page">
      <h1 className="h1-title">
        All <strong>Discoveries</strong>
      </h1>
      <div className="reset-all-wrap">
        <Button className="reset-all-btn black" onClick={handleAllReset}>
          Reset All Filters
        </Button>
      </div>
      {isSticky && <div className="dummy-for-scroll"/>}
      {discoveries && discoveries.meta && <div className={`filters ${isSticky ? 'fixed' : ''}`} ref={ref}>
        <div className={"popup"}>
          <Popover placement="bottomLeft" title={null}
            destroyTooltipOnHide={true}
            content={
              <AppDrawer appsList={products}
                currentApps={currentProducts}
                currentAppsChange={currentProductsChange}
                isProduct={true}
                page="all-discoveries"
                appChange={handleAppChange}
                clearCurrentApps={() => currentProductsChange([])}
                className="button"
                maxAppsSelected={20}
              />
            } trigger="click" overlayClassName={`popover-appdrawer  filter-wrap  ${isSticky ? 'fixed' : ''} all-discoveries all-discoveries-apps`}>
            <Button className="filter-btn-new">
              Apps
              {<span className={`filter-count ${currentProducts.length === 0 ? '' : 'filter-count-padding'}`}>
                {currentProducts.length === 0 || currentProducts.length === apps.length ? null : currentProducts.length}
              </span>}
            </Button>
          </Popover>

          <span className="sep"/>
          {tagsList && <Popover placement="bottomLeft" title={null}
            destroyTooltipOnHide={true}
            content={
              <FilterTags tagsList={tagsList}
                filterTags={filterTags}
                filterTagsChange={filterTagsChange}
                setFilterTagsMatch={setFilterTagsMatch}
                filterTagsMatch={filterTagsMatch}
                maxAllowedTags={10}
              />
            } trigger="click" overlayClassName={`popover-appdrawer  filter-wrap  ${isSticky ? 'fixed' : ''} all-discoveries all-discoveries-tags`}>
            <Button className="filter-btn-new">
              Tags
              {<span className={`filter-count ${filterTags.length === 0 ? '' : 'filter-count-padding'}`}>
                {filterTags.length === 0 ? null : filterTags.length}
              </span>}
            </Button>
          </Popover>
          }
          <span className="sep"/>
          <Popover placement="bottomLeft" title={null} content={
            <FilterType
              filterType={filterType}
              filterTypeChange={filterTypeChange}
              filterEmailSubtype={filterEmailSubtype}
              filterEmailSubtypeChange={filterEmailSubtypeChange}
              filterPushNotificationSubtype={filterPushNotificationSubtype}
              filterPushNotificationSubtypeChange={filterPushNotificationSubtypeChange}
              filterInAppSubtype={filterInAppSubtype}
              filterInAppSubtypeChange={filterInAppSubtypeChange}
              filterIntelEnabled={filterIntelEnabled}
              filterIntelEnabledChange={filterIntelEnabledChange}
              filterMarkEnabled={filterMarkEnabled}
              filterMarkEnabledChange={filterMarkEnabledChange}
              counts={discoveries.meta.num_by_type}
              selectedProductQTY={selectedProductQTY}
              page="all-discoveries"
              availableFeatures={
                {
                  isIntelligenceAvailable: true,
                  isMarketingAvailable: true
                }}
            />
          } trigger="click" overlayClassName={`filter-wrap ${isSticky ? 'fixed' : ''} all-discoveries all-discoveries-type`}>
            <Button className="filter-btn-new">
              Product
              {<span className={`filter-count ${selectedProductQTY === 0 ? '' : 'filter-count-padding'}`}>
                {selectedProductQTY === productQTY || selectedProductQTY === 0 ? null : selectedProductQTY}
              </span>}
            </Button>
          </Popover>
          <span className="sep"/>
          <Popover placement="bottomLeft" title={null} content={
            <FilterState
              filterStatus={filterStatus}
              filterStatusChange={filterStatusChange}
              countMeaningful={discoveries.meta.num_meaningful}
              countUnseen={discoveries.meta.num_total - discoveries.meta.num_read}
              countViewed={discoveries.meta.num_read}
              countIntegrated={discoveries.meta.num_integrated}
              countAbandoned={discoveries.meta.num_abandoned}
            />} trigger="click" overlayClassName={`filter-wrap ${isSticky ? 'fixed' : ''} all-discoveries all-discoveries-state`}>
            <Button className="filter-btn-new">
              State
              {<span className={`filter-count ${filterStatus.length === 0 ? '' : 'filter-count-padding'}`}>
                {Object.keys(mapStateLabels).length === filterStatus.length || filterStatus.length === 0 ? null : filterStatus.length}
              </span>}
            </Button>
          </Popover>
          <span className="sep"/>
          <FilterSelectBox
            value={sortOrder}
            onChange={selected => { sortOrderChange(selected) }}
            popupClassName={`${isSticky ? 'fixed' : ''}`}
          >
            <Option value="desc">Newest First</Option>
            <Option value="asc">Oldest First</Option>
          </FilterSelectBox>
          <span className="sep"/>
          <Popover placement="bottomRight" title={null}
            visible={isOpenTimeFilter}
            onVisibleChange={isOpenTimeFilterChange}
            content={
              <FilterTime
                isOpen={isOpenTimeFilter}
                daysAgoList={daysAgoList}
                daysAgo={daysAgo}
                daysAgoChange={daysAgoChange}
                customRange={customRange}
                сustomRangeСhange={сustomRangeСhange}
                customRangeTime={customRangeTime}
                customRangeTimeChange={customRangeTimeChange}
              />} trigger="click" overlayClassName={`filter-wrap all-discoveries ${isSticky ? 'fixed' : ''} all-discoveries-time`}
            overlayStyle={{ width: (customRange ? '75rem' : '18rem') }}>
            <Button className="filter-btn-new">
              {customRange ? 'Custom Range' : daysAgoList.find(d => d.value === daysAgo).title}
            </Button>
          </Popover>

        </div>
      </div>}

      <div className="hr-row-2"/>

      <FilterSearchQuery
        searchQuery={searchQuery}
        searchQueryChange={searchQueryChange}
      />


      {loadingFilteredDiscoveries &&
        <div className="all-dis-loader">
          <NewLoader />
        </div>}

      {!loadingFilteredDiscoveries && filteredDiscoveries.length === 0 && <VersionsEmpty campaignMode={true} />}

      {!loadingFilteredDiscoveries && filteredDiscoveries.length > 0 && <GalleryDiscoveries
        loadingFilteredDiscoveries={loadingFilteredDiscoveries}
        filteredDiscoveries={filteredDiscoveries}
        apps={apps.map(a => a.metadata)}
        usePagination={true}
        currentPage={currentPage}
        onPageChange={onPageChange}
        currentPageSize={currentPageSize}
        onShowSizeChange={onShowSizeChange}
        totalDiscoveries={discoveries.meta.num_total}
        user={user}
        analytics={analytics}
        productName={null}
        handleClick={null}
        source={'all_discoveries'}
      />}

    </div>
  )
}

const mapStateToProps = ({ appData, overview, userData, labels }) => ({
  apps: appData.apps,
  user: appData.user,
  discoveries: overview.filteredDiscoveries,
  loadingFilteredDiscoveries: overview.loadingFilteredDiscoveries,
  analytics: appData.user.analytics,
  products: overview.products,
  userData: userData,
  tagsList: labels.tagsList,
})

const mapDispatchToProps = {
  getDiscoveries,
  getProducts,
  getDiscoveriesLabels
}

export default connect(mapStateToProps, mapDispatchToProps)(DashDiscoveries)
