import React, { FC, ReactElement } from 'react';
import "./DaysToTrialExperation.scss";
import watchful_logo from "../../assets/images/watchful_icon_white.png";
// @ts-ignore
import { Img } from "react-image";
interface IProps {
    daysLeft: number
}

const DaysToTrialExpiration: FC<IProps> = ({
  daysLeft
}): ReactElement => {

  return (
    <div className={"trial main"}>
      <div className={"trial-expiration-text"}>
        <Img className={"img"}
          src={watchful_logo}/>
            Demo account
      </div>
      <div className="expires-in">expires in</div>
      <div className={"days-left"}>{daysLeft === 1? "1 day" : `${daysLeft} days`}</div>

    </div>
  );
}

export default DaysToTrialExpiration;