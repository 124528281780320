import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { menuItems, menuItemsWoutABTest } from "../../shared/routesData";
import { pagesTypes } from "../../shared/consts";
import lock_ico from "../../assets/images/lockIcon.svg";
import { Tooltip } from "antd";

import "./Nav2.scss";

import { useIsFeatureByUserID } from "../../helpers/features";

const Nav2 = ({ user, userData, history, siteSize, customerApp }) => {
  const [selectedKey, selectedKeyChange] = useState([]);
  const ABTestPageAccess = useIsFeatureByUserID("ab_tests_page", user.email);
  const contextTimelineAccess = useIsFeatureByUserID("context_timeline", user.email);

  const curMenuItems = ABTestPageAccess ? menuItems : menuItemsWoutABTest;

  const filteredMenuItems = contextTimelineAccess ? curMenuItems : curMenuItems.map((item) => ({...item, items: item?.items?.filter((i) => i.name !== 'context_timeline')}));

  const getLocation = () => {
    let paths = history.location.pathname.split("/").slice(1);
    if (paths[0] === "overview" || paths[0] === "") {
      return ["dash_overview"];
    } else if (paths[0] === pagesTypes.MARKETING && !paths[1]) {
      return ["marketing", "campaigns"];
    } else if (paths[0] === "product_intelligence" && !paths[1]) {
      return ["intelligence", "product_intelligence"];
    } else if (paths[0] === "product_intelligence" && paths[1] === "ab-tests") {
      return ["intelligence", "ab_tests"];
    } else if (paths[0] === "product_intelligence" && paths[1] === "topics") {
      return ["intelligence", "topics"];
    } else if (paths[0] === "all-discoveries") {
      return ["all_discoveries"];
    } else {
      return paths;
    }
  };

  const isEnableMenu = (item) => {
    return userData.pages.some((page) => page === item.access_key);
  };

  const renderNewSubMenuItem = (subMenuItem, topItem, key) => (
    <div
      className={`sub-link ${
        subMenuItem.name === selectedKey[1] ? "act" : ""
      } ${isEnableMenu(subMenuItem) ? "" : "disable"}`}
    >
      <NavLink to={`${subMenuItem.url}`}>
        <span className={`${topItem.name} menu-label`}>
          <img src={subMenuItem.icon} className={`${subMenuItem.name}-ico`} />
          <span>{subMenuItem.label}</span>
          {/* <span className="new-tag">NEW!</span> */}
          {!isEnableMenu(subMenuItem) && (
            <img src={lock_ico} className="lock-ico" />
          )}
        </span>
      </NavLink>
    </div>
  );

  useEffect(() => {
    const keys = getLocation();
    selectedKeyChange(keys);
  }, [history.location.pathname]);

  return (
    <nav className={`nav2 ${siteSize}`}>
      {customerApp && (
        <div className="nav2-customer-app">
          <img src={customerApp.metadata?.icon} />
          <span>{customerApp.metadata?.display_name?.toLowerCase()}</span>
        </div>
      )}
      {filteredMenuItems.map((topItem, i) =>
        topItem.items ? (
          //Menu with Items
          <div className="top-sub-menu" key={i}>
            <div className="top-menu-title">
              <span className={`${topItem.name} menu-label`}>
                <img src={topItem.icon} />
                <span>{topItem.label}</span>
              </span>
            </div>
            <div className="top-menu-items">
              {topItem.items.map((subMenuItem, i) =>
                subMenuItem.name === "context_timeline" ? (
                  renderNewSubMenuItem(subMenuItem, topItem, i)
                ) : (
                  <div
                    key={i}
                    className={`sub-link ${
                      subMenuItem.name === selectedKey[1] ? "act" : ""
                    } ${isEnableMenu(subMenuItem) ? "" : "disable"}`}
                  >
                    <NavLink to={`${subMenuItem.url}`}>
                      <span className={`${topItem.name} menu-label`}>
                        <img
                          src={subMenuItem.icon}
                          className={`${subMenuItem.name}-ico`}
                        />
                        <span>{subMenuItem.label}</span>
                        {!isEnableMenu(subMenuItem) && (
                          <img src={lock_ico} className="lock-ico" />
                        )}
                      </span>
                    </NavLink>
                  </div>
                )
              )}
            </div>
          </div>
        ) : (
          //Direct Link
          <div className="top-link-container">
            <div
              key={i}
              className={`top-link ${
                topItem.name === selectedKey[0] ? "act" : ""
              }  ${isEnableMenu(topItem) ? "" : "disable"}`}
            >
              <NavLink to={`${topItem.routeData.url}`}>
                <span className={`${topItem.name} menu-label`}>
                  <img
                    src={topItem.routeData.icon}
                    className={`${topItem.name}-ico`}
                  />
                  <span>{topItem.label}</span>
                </span>
              </NavLink>
            </div>
          </div>
        )
      )}
    </nav>
  );
};

const mapStateToProps = ({ userData, appData }) => {
  const customerApp = appData.ownApps.find((app) => {
    const { metadata } = app;

    return metadata.is_customer_own_app === true;
  });

  return {
    userData: userData,
    user: appData.user,
    customerApp,
  };
};

export default withRouter(connect(mapStateToProps, null)(Nav2));
