import React, { useEffect, useState } from "react";
import { Circle, Layer, Stage } from "react-konva";
import { UserFlowAsset } from "../../../../../interfaces/UserJourneys";

type Props = {
  imageObject: UserFlowAsset;
  canvasWidth: number;
  canvasHeight: number;
  onClick?: () => void;
  enableBorder?: boolean;
};

const TAP_RADIUS = 25;

const normalizeX = (x: number, imageWidth: number, canvasWidth: number) =>
  Math.round((canvasWidth * x) / imageWidth);
const normalizeY = (y: number, imageHeight: number, canvasHeight: number) =>
  Math.round((canvasHeight * y) / imageHeight);

export const CanvasedImage = ({
  imageObject,
  canvasWidth,
  canvasHeight,
  onClick,
  enableBorder,
}: Props) => {
  const { url_annotated, action_type, action_coords } = imageObject;
  const [imagePreview, setImagePreview] = useState<HTMLImageElement | null>(
    null
  );

  useEffect(() => {
    const image = new window.Image();
    image.src = url_annotated;
    image.onload = () => {
      setImagePreview(image);
    };
  }, [url_annotated]);

  return (
    <div className={`uj-carousel-image ${enableBorder ? "light-border" : ""}`}>
      <img
        src={url_annotated}
        className="canvased-img"
        style={{ width: canvasWidth, height: canvasHeight }}
      />
      <div className="canvased-image__canvas">
        <Stage width={canvasWidth} height={canvasHeight} onClick={onClick}>
          <Layer>
            {imagePreview && (
              <React.Fragment>
                {action_type === "tap" && action_coords && (
                  <Circle
                    radius={TAP_RADIUS}
                    stroke="#f00000"
                    fill="rgba(233, 7, 7, 0.54)"
                    opacity={0.8}
                    strokeWidth={3}
                    x={normalizeX(
                      // @ts-ignore
                      action_coords.x,
                      imagePreview.width,
                      canvasWidth
                    )}
                    y={normalizeY(
                      // @ts-ignore
                      action_coords.y,
                      imagePreview.height,
                      canvasHeight
                    )}
                  />
                )}
              </React.Fragment>
            )}
          </Layer>
        </Stage>
      </div>
    </div>
  );
};
