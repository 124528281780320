import React, { ReactElement, useEffect, useState } from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setNotificationsRead, setNotificationsSeen } from "../../../redux/acts/notifications"
import Note from "./Note"
import { INotification } from '../../../interfaces/Notification';
import { IState, IDisWithApp } from '../../../interfaces/Product'

import "./NotificationCenter.scss"
import { Button,  notification } from "antd";
import Icon  from "@ant-design/icons";
import moment from "moment"
import { datadogRum } from '@datadog/browser-rum';
import { notificationsSelector } from "./notificationSelector"

import ntf_empty_state from "../../../assets/images/new_design/icons/ntf-empty-state.svg"


interface IProps {
  notifications: INotification[],
  allInsights: any[],
  history: { push(url: string): void; },
  markAllSeenLoading: boolean,
  setNotificationsRead: (arg0: number[]) => void,
  setNotificationsSeen: (arg0: number[]) => void,
  closeNotCenter: () => void,
  analytics: any
}

export const groupsNotificationsTemplate = [{
  label: "Today",
  comparator: [moment().subtract(1, 'day').hour(0).minute(0).second(0).subtract(1, 'minute'), moment().hour(0).minute(0).second(0)],
  humanize: false,
  hideTime: true,
  data: [],
  className: 'today'
},
{
  label: "Last 7 Days",
  comparator: [moment().subtract(7, 'day'), moment().subtract(1, 'day').hour(0).minute(0).second(0).subtract(1, 'minute')],
  humanize: true,
  data: [],
  className: '7days'
},
{
  label: "Older",
  humanize: true,
  data: [],
  comparator: [moment().subtract(3, 'year'), moment().subtract(7, 'day').subtract(1, 'minute')],
  className: 'older'
}
]

export const groupingByTime = (notifications: INotification[]) => {
  let data = [...groupsNotificationsTemplate.map(grp => ({ ...grp }))]
  data.forEach(grp => {
    //@ts-ignore
    grp.data = notifications.filter(ntf =>
      moment(ntf.time).hour(0).minute(0).second(0).isBetween(grp.comparator[0], grp.comparator[1])
    )
  })
  return data
}

const NotificationCenter = ({
  notifications = [],
  allInsights,
  history,
  markAllSeenLoading = false,
  setNotificationsRead,
  setNotificationsSeen,
  closeNotCenter,
  analytics
}: IProps): ReactElement | null => {

  const [groupsNotifications, groupsNotificationsChange] = useState([])

  const handleMarkAllSeen = () => {
    setNotificationsRead(notifications.map(n => Number(n.id)))
  }

  useEffect(() => {
    setNotificationsSeen(notifications.map(n => Number(n.id)))
  }, [])

  useEffect(() => {
    //@ts-ignore
    groupsNotificationsChange(groupingByTime(notifications))
  }, [notifications])

  const handlerNotificationClick = (ntf: INotification) => {
    if (ntf.link) {
      try {
        history.push(ntf.link[0] === '/' ? `${ntf.link}` : `/${ntf.link}`)
        analytics.analytic(`user clicked  on notification ${ntf.link} (${ntf.description})`)
      } catch (er) {
        datadogRum.addError(er, {
          message: 'Click on broken link in notification center',
          link: ntf.link,
          notification_id: ntf.id
        });
      }
    }
    setNotificationsRead([ntf.id])
    closeNotCenter()
  }


  return (
    <div className="notification-center">
      <div className="ntf-ctr-header">
        <h2>Notifications</h2>
        <div className="ntf-mark-all-wrap">
          <Button className="ant-btn-light btn-ntf-mark-all-seen" onClick={handleMarkAllSeen} data-testid="mark-all-as-seen">
            Mark all as Read
            {markAllSeenLoading && <Icon type="loading" />}
          </Button>
        </div>
      </div>
      {notifications.length === 0 ?
        <div className="empty-state-notification">
          <div className="ntf-empty-icon">
            <img src={ntf_empty_state} />
          </div>
          <div className="ntf-empty-header">You Currently Have No Notifications</div>
          <div className="ntf-empty-descr">Check again later for updates about critical discoveries, new platform features and more</div>
        </div>
        :
        <div className="notification-list">
          {groupsNotifications.map((grp: INotification, i: number) =>
            <div className="notification-sub-list-wrap" key={i}>
              {/*@ts-ignore*/}
              <h3>{grp.data.length > 0 ? grp.label : ''}</h3>
              {/*@ts-ignore*/}
              <div className={`notification-sub-list ${grp.className}`}>
                {/*@ts-ignore*/}
                {grp.data.map(n => <Note
                  notification={n}
                  key={n.id}
                  //@ts-ignore
                  options={{ humanize: grp.humanize, format: grp.format, hideTime: grp.hideTime }}
                  handlerNotificationClick={handlerNotificationClick} />)}
              </div>
            </div>
          )}
        </div>
      }
    </div>
  )
}



const mapStateToProps = (state: IState) => {
  return {
    notifications: notificationsSelector('center')(state),
    markAllSeenLoading: state.notifications.markAllSeenLoading,
    allInsights: state.appData.allInsights,
    analytics: state.appData.user.analytics,

  };
};

export default withRouter(
  //@ts-ignore
  connect(mapStateToProps, { setNotificationsRead, setNotificationsSeen })(NotificationCenter)
);
