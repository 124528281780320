import { pagesTypes, LICENCES, L_INTELLIGENCE, L_BENCHMARK, L_MARKETING } from "../shared/consts";


export const isUserAdmin = cognitoUser => {
  if (
    cognitoUser["cognito:groups"] &&
    cognitoUser["cognito:groups"].includes("Admin")
  ) {
    // the current user has admin permissions
    return true;
  }
  return false;
};

export const canUserViewRawData = cognitoUser => {
  if (
    cognitoUser["cognito:groups"] &&
    (cognitoUser["cognito:groups"].includes("Admin") || cognitoUser["cognito:groups"].includes("Feature_RawData"))
  ) {
    // the current user can view raw data
    return true;
  }
  return false;
};

export const getNameFromEmail = user => {
  return user.email.split(/[@\-\.]/)[0];  // eslint-disable-line
}

export const getLandPageForUser = user => {
  return pagesTypes.FIRST_PAGE
}

export const checkAvailLocations = userData => {
  return userData.locations || []
  //return Object.keys(LOCATIONS_EXTRA).filter(loc => loc !== 'def')
}

export const getUserLicence = userData => {
  let availItems = userData.pages;
  return {
    [L_INTELLIGENCE]: availItems.includes(pagesTypes.INTELLIGENCE),
    [L_BENCHMARK]: availItems.includes(pagesTypes.BENCHMARK_COMPARE),
    [L_MARKETING]: availItems.includes(pagesTypes.MARKETING)
  }
}

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};