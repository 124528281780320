import React, { useCallback, useState } from "react";
import awsService from "../../../../services/awsService";
// import '@ant-design/compatible/assets/index.css';
import { Button, notification, Form } from "antd";
import Icon from "@ant-design/icons";
import { InputText } from "../../../../components/inputText/InputText";

export const ResetPasswordView =
  ({ form, next, setUsername, ...props }) => {
    const [loading, setLoading] = useState(false);

    const handleSubmit = useCallback(
      values => {
        setLoading(true);
        const aws = new awsService();
        setUsername(values.email);
        aws
          .forgotPassword(values.email)
          .then(() => {
            next();
          })
          .catch(err => {
            notification.open({
              duration: 7,
              message: "Something went wrong",
              description: err.message,
              icon: <Icon type="exception" style={{ color: "red" }} />
            });
          })
          .finally(() => {
            setLoading(false);
          });
      },
      [next, setUsername]
    );
    return (
      <div className="reset-password-view">
        <h3>Enter the email address you use to Sign in</h3>
        <Form onFinish={handleSubmit} name={"ResetPasswordForm"}>
          {/* <Form.Item className="reset-password__email"> */}
          <Form.Item name="email" rules={[
            { required: true, message: "Please enter your email" },
            {
              type: "email",
              message: "The input is not valid E-mail!"
            }
          ]} >
            <InputText autoFocus={true} label="Email" placeholder="Email" />
          </Form.Item>
          <Button block loading={loading} type="primary" htmlType="submit">
            Email me a recovery link
          </Button>
        </Form>
      </div>
    );
  }

