import { createAction } from 'redux-act';
import * as aT from "../actions/_actionsTypes";

export const GET_ORGANIZATION_USERS = 'GET_ORGANIZATION_USERS'
export const GET_ORGANIZATION_USERS_SUCCESS = 'GET_ORGANIZATION_USERS_SUCCESS'
export const getOrganizationUsers = createAction(GET_ORGANIZATION_USERS);
export const getOrganizationUsersSuccess = createAction(GET_ORGANIZATION_USERS_SUCCESS, (data) => data);

export const DELETE_ORGANIZATION_USER = 'DELETE_ORGANIZATION_USER'
export const DELETE_ORGANIZATION_USER_SUCCESS = 'DELETE_ORGANIZATION_USER_SUCCESS'
export const deleteOrganizationUser = createAction(DELETE_ORGANIZATION_USER);
export const deleteOrganizationUserSuccess = createAction(DELETE_ORGANIZATION_USER_SUCCESS, (data) => data);

export const SEND_INVITE_FOR_USER = 'SEND_INVITE_FOR_USER'
export const SEND_INVITE_FOR_USER_SUCCESS = 'SEND_INVITE_FOR_USER_SUCCESS'
export const SEND_INVITE_FOR_USER_FAILED = 'SEND_INVITE_FOR_USER_FAILED'
export const sendInviteForUser = createAction(SEND_INVITE_FOR_USER);
export const sendInviteForUserSuccess = createAction(SEND_INVITE_FOR_USER_SUCCESS, (data) => data);
export const sendInviteForUserFailed = createAction(SEND_INVITE_FOR_USER_FAILED, (data) => data);

export const CANCEL_INVITE_FOR_USER = 'CANCEL_INVITE_FOR_USER'
export const CANCEL_INVITE_FOR_USER_SUCCESS = 'CANCEL_INVITE_FOR_USER_SUCCESS'
export const CANCEL_INVITE_FOR_USER_FAILED = 'CANCEL_INVITE_FOR_USER_FAILED'
export const cancelInviteForUser = createAction(CANCEL_INVITE_FOR_USER);
export const cancelInviteForUserSuccess = createAction(CANCEL_INVITE_FOR_USER_SUCCESS, (data) => data);
export const cancelInviteForUserFailed = createAction(CANCEL_INVITE_FOR_USER_FAILED, (data) => data);


export const RESEND_INVITE_FOR_USER = 'RESEND_INVITE_FOR_USER'
export const RESEND_INVITE_FOR_USER_SUCCESS = 'RESEND_INVITE_FOR_USER_SUCCESS'
export const RESEND_INVITE_FOR_USER_FAILED = 'RESEND_INVITE_FOR_USER_FAILED'
export const resendInviteForUser = createAction(RESEND_INVITE_FOR_USER);
export const resendInviteForUserSuccess = createAction(RESEND_INVITE_FOR_USER_SUCCESS, (data) => data);
export const resendInviteForUserFailed = createAction(RESEND_INVITE_FOR_USER_FAILED, (data) => data);

const INIT_STATE = {
  orgUsers: null,
  accessRequests: null,
  sendInviteError: null,
  sendInviteSuccess: null,
  cancelInviteError: null,
  resendInviteError: null
};

export default function (state = INIT_STATE, action) {
  switch (action.type) {
  case GET_ORGANIZATION_USERS_SUCCESS: {
    return {
      ...state,
      orgUsers: action.payload.data
    };
  }
  case DELETE_ORGANIZATION_USER_SUCCESS: {
    return {
      ...state,
      orgUsers: state.orgUsers.filter(us => us.id !== Number(action.payload.user_id))
    };
  }

  case aT.GET_ACCESS_REQUESTS_SUCCESS: {
    return {
      ...state,
      accessRequests: action.payload.requests.data
    }
  }
  case aT.SET_ACCESS_REQUEST_STATUS_SUCCESS: {
    const updatedReq = action.payload.request.data
    const accessRequestsCopy = state.accessRequests.map(req => req.id === updatedReq.id ? updatedReq : req)
    return {
      ...state,
      accessRequests: accessRequestsCopy
    }
  }

  case SEND_INVITE_FOR_USER: {
    return {
      ...state,
      sendInviteSuccess: null,
      sendInviteError: null,
    };
  }

  case SEND_INVITE_FOR_USER_SUCCESS: {
    return {
      ...state,
      sendInviteError: null,
      sendInviteSuccess: true,
      accessRequests: [...state.accessRequests, action.payload.data]
    };
  }
  case SEND_INVITE_FOR_USER_FAILED: {
    return {
      ...state,
      sendInviteSuccess: false,
      sendInviteError: action.payload,
    };
  }

  case CANCEL_INVITE_FOR_USER_SUCCESS: {
    return {
      ...state,
      cancelInviteError: null,
      accessRequests: [...state.accessRequests].map(oU => oU.id === action.payload.data.id ? action.payload.data : oU)
    };
  }
  case CANCEL_INVITE_FOR_USER_FAILED: {
    return {
      ...state,
      cancelInviteError: action.payload,
    };
  }

  case RESEND_INVITE_FOR_USER: {
    return {
      ...state,
      resendInviteError: null,
    };
  }
  case RESEND_INVITE_FOR_USER_SUCCESS: {
    return {
      ...state,
      resendInviteError: null,
      accessRequests: [...state.accessRequests].map(oU => oU.id === action.payload.data.id ? action.payload.data : oU)
    };
  }
  case RESEND_INVITE_FOR_USER_FAILED: {
    return {
      ...state,
      resendInviteError: action.payload,
    };
  }

  default:
    return state;
  }
}
