import { IDisWithApp, IDataPoint } from '../../interfaces/Product';
import moment from "moment";
export const setOfColors = ['#000000', "#6929c4", "#1192e8", '#005d5d', "#9f1853", "#fa4d56", '#570408', "#198038", "#002d9c", "#ee538b", "#b28600", "#009d9a", "#012749", "#8a3800", "#a56eff"]

export const customTooltip1 = (tooltipGroups: string[], timeFrame: number) => (points: IDataPoint[], timeFrame: number): string => {
  const sortedPoints = points.sort((a: IDataPoint, b: IDataPoint): number => {
    return b.value - a.value
  })
  const colors = sortedPoints.map((point, i) => {
    return {
      [point.group]: setOfColors[tooltipGroups.findIndex(s => s === point.group)]
    }
  })
  const is3Months = timeFrame === 3;
  let title = is3Months ? `<div class="year" > ${moment(sortedPoints[0].key).format('DD')} </div>` : ``;
  const dateFormat = 'MMMM/YY';
  title += `<strong class="month" > ${moment(sortedPoints[0].key, dateFormat).format('MMMM')} </strong>  <div class="year"> ${moment(sortedPoints[0].key, dateFormat).format('YYYY')} </div>`;
  let tooltipArray = ``;
  sortedPoints.forEach((point: IDataPoint, i: number) => {
    tooltipArray += `<li>
        <div class="datapoint-tooltip">
          <a class="tooltip-color tooltip-3-1-3"></a>
          <a style="background-color: ${colors[i][point.group]}" class="tooltip-color"></a>
          <p class="value">${point.value}</p>
          <p class="label">${point.group}</p>
        </div>
      </li>`;
  });

  return (
    `<div class="content-box">
        <ul class="multi-tooltip">
          <li>
            <div class="datapoint-tooltip ">
               ${title}
            </div>
          </li>
         ${tooltipArray}
       </ul>
        </div>`)
}

export const customLabel1 = (timeFrame: number) => (data: any) => {
  if (timeFrame === 3) {
    return `${data.slice(0, 2)} ${data.slice(3, 6)}`
  } else {
    return data.replace('/', ' ')
  }
  
}