import React from "react";
import "./ChannelSelect.scss";
import { Channel } from "containers/MarketingCampaings/utils/types";
import { trackMarketingEvent } from "containers/MarketingCampaings/utils/analytics";

type Props = {
  channels: Channel[];
  selectedChannelId: string;
  onChannelSelect: (channelId: string) => void;
};

export const ChannelSelect = ({
  channels,
  selectedChannelId,
  onChannelSelect,
}: Props) => {
  const handleChannelClick = (channelId: string) => () => {
    trackMarketingEvent("channel_changed", {
      channel_name: channelId,
    });

    onChannelSelect(channelId);
  };

  const totalAmount = channels.reduce((acc, channel) => {
    return acc + channel.amount;
  }, 0);

  return (
    <div className="mkc-channel-select-container">
      <div
        className={`channel ${!selectedChannelId ? "selected" : ""}`}
        onClick={handleChannelClick("")}
      >
        <div className="title">All Channels</div>
        <div className="amount">{totalAmount}</div>
      </div>
      {channels.map((channel) => (
        <div
          className={`channel ${channel.amount < 1 ? "disabled" : ""} ${
            channel.id.toString() === selectedChannelId ? "selected" : ""
          }`}
          onClick={
            channel.amount > 0
              ? handleChannelClick(channel.id.toString())
              : () => {}
          }
        >
          <div className="title">{channel.title}</div>
          <div className="amount">{channel.amount}</div>
        </div>
      ))}
    </div>
  );
};
