import React from 'react'

import { Select } from 'antd';
import black_cheron_ico from "../../assets/images/icons/chevron_black.svg"

export default function index({
  value,
  onChange,
  children,
  className,
  popupClassName,
  disabled,
  testid
}) {
  return (
    <div className="filter-select-new-wrapper">
      <Select
        value={value}
        data-testid={testid}
        disabled={disabled}
        className={`filter-select-new ${className}`}
        onChange={onChange}
        popupClassName={`filter-select-new-dropdown ${popupClassName}`}
        suffixIcon={
          <img
            className={`chevron`}
            src={black_cheron_ico}
            alt="chevron"
          />
        }
      >
        {children}
      </Select>
    </div>
  )
}
