export const CELLSIZE = 35
export const canvasWidth = 720
export const canvasHeight = 570

export const GRAPHCANVASOPTIONS = {
  layout: {
    hierarchical: false,
  },
  interaction: {
    dragNodes: false,
  },
  autoResize: false,
  nodes: {
    shape: 'box',
    margin: 0.1,
    color: {
      border: '#E6E6E6',
      background: '#E8F0F9',
      highlight: {
        border: '#2E74FF'
        //background: '#2E74FF',
      },
    },
    font: {
      color: '#252C38',
      face: 'Roboto',
      size: 10
    },

    widthConstraint: {
      maximum: CELLSIZE * 2,
      minimum: CELLSIZE * 2,
    },
    heightConstraint: {
      minimum: CELLSIZE * 2,
    },
    shapeProperties: {
      borderRadius: 15,
    },

  },
  edges: {
    color: '#3C4554',
    //smooth: 
    arrows: {
      middle: {
        enabled: true,
        // imageHeight: 17,
        // imageWidth: 17,
        //src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACPSURBVHgB7ZQtDoNAEIU/Gi7QysrWNjhkRU2T3qEH4ABwBQTchnNgkBjAsBKOsECCYrPhbwWCz0zykveSmZeM5fhSsgdJZQ8zi9mEaOAXwgUDGAmxp0KUQC70BvcB3ncmJK8hLdByv6racW5yhqgoFX9efY03vcF9Lgj5v1mNuXVEyybq0Wc5gSzZQf+Nqg7c3hrQa3YnagAAAABJRU5ErkJggg==",
        type: "vee",
      },
      to: {
        enabled: false
      }
    },
    // endPointOffset: {
    //   to: 50,
    // },
  },
  width: `${canvasWidth}px`,
  height: `${canvasHeight}px`,
  physics: {
    enabled: false,
  },
};

export const reformatGraphData = (backGraphData, advancedView) => {
  const gd = {
    nodes: backGraphData.nodes.map(node => {
      const newNode = {
        ...node,
        id: node.id,
        neo4j_label: node.labels[0],
        label: node.name.toUpperCase(),
        x: node.ui_position_x,
        y: node.ui_position_y,
      }
      const insightsOfThisNode = backGraphData.insights.filter(insight => insight.node_id === newNode.id)
        .sort((a, b) =>{
          const priority = Object.keys(insightTypes);
          for (const prio of priority) {
            if (a.type === prio) return -1
            if (b.type === prio) return 1
          }
        });
      if (advancedView &&  insightsOfThisNode.length > 0) {
        newNode.color = {
          background: insightTypes[insightsOfThisNode[0].type].color,
          highlight: {background: insightTypes[insightsOfThisNode[0].type].color, border:'#2B7CE9'}
        };
      }
      return newNode
    }),
    edges: backGraphData.edges.map(edge => ({
      ...edge,
      from: edge.start_node_id,
      to: edge.end_node_id,
      input_type: edge.input_type,
      comment: edge.comment,
      label: '',
      smooth: backGraphData.edges.find(e => e.start_node_id === edge.end_node_id && e.end_node_id === edge.start_node_id) ? {
        enabled: true,
        type: 'curvedCW',
        roundness: 0.2
      } : false
      //swipe_data: swipe2swipe_data(edge.x, edge.x_offset, edge.y, edge.y_offset, edge.swipe_duration),
    })),
  };
  return gd;
};

export const drawGrid = (ctx, step, offsetX = 0, offsetY = 0) => {
  const w = canvasWidth,
    h = canvasHeight;
  ctx.clearRect(0, 0, w, h);
  ctx.beginPath();
  ctx.lineWidth = 1;
  ctx.strokeStyle = '#f1f1f1';
  for (let x = -offsetX, k = 0; x <= w; x += step, k++) {
    ctx.moveTo(x, 0);
    ctx.lineTo(x, h);
  }

  ctx.stroke();
  ctx.beginPath();
  for (let y = -offsetY, k = 0; y <= h; y += step, k++) {
    ctx.moveTo(0, y);
    ctx.lineTo(w, y);
  }
  ctx.stroke();
};

export const insightTypes = {
  // the order of the insights types here is important as this is also the priority of each insight type if there is more than one insight for a node
  indication: {title: 'Indications', color: '#FF2741'},
  ab_test: {title: 'Experiments', color: 'rgba(255, 201, 49, 0.99)'},
  new_feature: {title: 'New features', color: '#A129FF'},
  ui_change: {title: 'UI changes', color: '#17E5A1'},
  removed_features: { title: 'Removed features', color: '#377FFF'}
}
