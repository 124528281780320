import useFlag from './useFlag'

export const useIsFeatureByUserID = (featureName, userEmail) => {
  const [feature, isFetched] = useFlag(featureName);
  if (!isFetched)
    return false

  if (!feature)
    return true //feature doesn't exist(everyone can see the feature)

  if (!!feature && feature.strategies[0].name === 'default' && feature.enabled)
    return true // feature enabled for all users

  if(userEmail && !!feature && feature.enabled && feature.strategies?.[0]?.parameters?.userIds === '*@watchful.ai') {
    return userEmail.includes('@watchful.ai');
  }

  return (!!feature && feature.enabled &&
    feature.strategies.some(strategy => strategy.name === 'userWithId' && strategy.parameters.userIds.indexOf(userEmail) !== -1)) // user has feature in some strategy
}

export const checkFlagForUser = (flagsClient, featureName, userEmail) => {
  const feature = flagsClient.getFlag(featureName)
  if (feature.strategies[0].name === 'default' && feature.enabled)
    return true // feature enabled for all users

  return (feature.enabled && feature.strategies.some(strategy => strategy.name === 'userWithId' && strategy.parameters.userIds.indexOf(userEmail) !== -1)) // user has feature in some strategy
}