import React, { useEffect, useState, useRef } from 'react'

import Graph from "react-graph-vis";
import moment from "moment"

import AppHeader from "../../components/discoveryView/AppHeader";

import open_eye from '../../assets/images/icons/open-eye.svg';
import strike_though_eye from '../../assets/images/icons/strike-through-eye.svg';

import { insightTypes } from './graphHelpers';
import {
  CELLSIZE,
  GRAPHCANVASOPTIONS,
  canvasWidth,
  canvasHeight,

  //methods
  reformatGraphData,
  drawGrid
} from "./graphHelpers"

import "./GraphView.scss"
import SneakPeakInsightModal from "../../components/sneakPeakDiscoveryModal/SneakPeakInsightModal";

export const GraphView = ({
  graph,
  app,
  user,
  graphID,
  allInsights,
  goBackHandle,
}) => {
  const [selectedNodeID, selectedNodeIDChange] = useState(graphID);
  const [advancedView, advancedViewChange] = useState(false);
  const [visibilitySneakPeak, visibilitySneakPeakChange] = useState(false);
  const [selectedInsightID, selectedInsightIDChange] = useState(null);
  const [currentItem, currentItemChange] = useState(null);

  const graphNetwork = useRef(null);
  const graphNetworkBackGrid = useRef(null);
  useEffect(() => {
    const canvas = document.getElementById('graph-back-grid');
    graphNetworkBackGrid.current = canvas.getContext('2d');
    window.gn = graphNetwork.current;
    window.bg = graphNetworkBackGrid.current;
    updateGrid();
  }, []);

  const events = {
    selectNode: params => {
      selectedNodeIDChange(params.nodes[0])
    },
    beforeDrawing: () => updateGrid(),
  }

  const updateGrid = () => {
    if (!graphNetwork.current || !graphNetworkBackGrid.current) {
      return;
    }

    const gn = graphNetwork.current;
    const scale = gn.getScale();
    const unSelectedNode = Object.values(gn.body.nodes).find(n => !gn.getSelectedNodes().includes(n.id));
    let absCoords;
    if (!unSelectedNode) {
      absCoords = { x: 0, y: 0 };
    } else {
      absCoords = gn.canvasToDOM(gn.getPosition(unSelectedNode.id));
    }
    const newCellSize = CELLSIZE * scale;
    const offsetX = newCellSize - absCoords.x % newCellSize;
    const offsetY = newCellSize - absCoords.y % newCellSize;


    drawGrid(
      graphNetworkBackGrid.current,
      newCellSize,
      offsetX,
      offsetY,
    );
  };

  const zoomInHandle = (zoomFactor) => {
    graphNetwork.current.moveTo({
      scale: graphNetwork.current.getScale() * zoomFactor, animation: {
        duration: 100,
        easingFunction: 'easeInQuad'
      }
    })
  };
  const getFormatedInsight = (insight, i) => {
    return {
      item: {
        ...insight,
        ...app,
        metadata: app.metadata,
        app_id: app.metadata.id,
        id: insight.id,
        insightId: insight.id,
      },
      key: i,
    }
  }
  const changeItem = (insight, i) => {
    currentItemChange(getFormatedInsight(insight, i))

  }
  const openModal = (thisInsight)=>{
    selectedInsightIDChange(thisInsight.id);
    currentItemChange(
      getFormatedInsight(allInsights.find(ins => ins.insight.id === thisInsight.id).insight,
        insightsOfSelectedNode.map(ins => ins.id === thisInsight.id).indexOf(true)));
    visibilitySneakPeakChange(true);
  }
  const selectedNode = graph.nodes.find(n => n.id === selectedNodeID);
  const insightsOfSelectedNode = graph.insights.filter(insight => insight.node_id === selectedNode.id);
  const currInsight = insightsOfSelectedNode.find(insight => insight.id === selectedInsightID);

  const eye = <img src={advancedView ? open_eye : strike_though_eye} />;
  return (
    <div className='app-graph-view'>
      <AppHeader
        app={app}
        demoMode={false}
        clickBack={goBackHandle}
        isMarketingDiscovery={false}
        version={{
          release_name: graph.release.name
        }}
      />
      <div className="hr-row" />
      <div className="instructions">
        Explore the app by clicking on the map
      </div>
      <div className="graph-data-wrapper">
        <div className='graph-data'>
          <div className='release-date'>
            <div className="data-name">Release</div>
            <div className="data-value">{moment(graph.release.date).format('ll')}</div>
          </div>
          <div className='graph-screens'>
            <div className="data-name">Screens</div>
            <div className="data-value">{graph.nodes.length}</div>
          </div>
          <div className='graph-discoveries'>
            <div className="data-name">Discoveries</div>
            <div className="data-value"><div className='eye' onClick={() => advancedViewChange(!advancedView)}>{eye}</div> {graph.insights.length}</div>
          </div>
          {/* <div className='persona-location'>
          <div className="data-name">Location</div>
          <div className="data-value">{graph.persona.location}</div>
        </div>
        <div className='persona-name'>
          <div className="data-name">Persona</div>
          <div className="data-value">{graph.persona.name}</div>
        </div> */}
        </div>
        {insightsOfSelectedNode.length > 0 && allInsights.length > 0 &&
        <div className="insights"> {insightsOfSelectedNode.map((insight, i) =>
          <div className="insight-link-wrapper" onClick={() => openModal(insight)} key={i}>
            <div className="info" style={{backgroundColor: insightTypes[insight.type].color}}>i</div>
            <div className="type">{insightTypes[insight.type].title}</div>
          </div>
        )}
        </div>
        }
      </div>
      {graph &&
        <div className='graph-wrap '>


          <div className='graph-canvas'>

            <canvas id="graph-back-grid" width={canvasWidth} height={canvasHeight} />

            <Graph
              key={`graph-${graph.id}-${ advancedView? 'advanced': 'not-advanced' }`}
              graph={reformatGraphData(graph, advancedView)}
              options={GRAPHCANVASOPTIONS}
              events={events}
              getNetwork={network => {
                graphNetwork.current = network;
                network.selectNodes([graphID])
              }}
            />
             
            <div className='zoom-manage'>
              <button onClick={() => zoomInHandle(1.25)}>+</button>
              <button onClick={() => zoomInHandle(0.8)}>-</button>
            </div>
            {advancedView && <div className="legend-wrapper">
              {Object.values(insightTypes).map((type,i) => <div className="type" key={i}><div className="circle" style={{backgroundColor: type.color}}/>{type.title}</div>)}
            </div>}
          </div>
          {selectedNodeID && <div className='node-screenshot'>
            <h3 className='node-title'>{graph.nodes.find(n => n.id === selectedNodeID).name}</h3>
            <div className='node-screenshot-wrap'>
              <img src={selectedNode.screenshot_link} />
            </div>
          </div>}
        </div>
      }
      {selectedInsightID && currInsight &&
      <SneakPeakInsightModal
        closeModalAndRemoveViewedInsights={() => { visibilitySneakPeakChange(false); selectedInsightIDChange(null) }}
        currentItem={currentItem}
        changeItem={changeItem}
        insights={insightsOfSelectedNode.map(insightOfSelectedNode => allInsights.find(ins => ins.insight.id === insightOfSelectedNode.id).insight)}
        visibility={visibilitySneakPeak}
        user={user}
        isMarketingDiscovery={false}
      />
      }
    </div>
  )
}


export default GraphView;