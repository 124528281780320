import { takeEvery, call, fork, put } from 'redux-saga/effects';
import * as actionTypes from '../actions/_actionsTypes';
import * as abTestsAction from '../actions/abTestsActions';
import * as api from '../api/abTestsApi';

function* getABTestTimeLine(action) {
  try {
    const { id } = action.payload;
    const result = yield call(api.getABTestTimeLineAPI, id);
    yield put(abTestsAction.getABTestTimeLineSuccess(result.data));
  } catch (e) {
    yield put(abTestsAction.getABTestTimeLineFailed());
  }
}

function* watchGetABTestsChart() {
  yield takeEvery(actionTypes.GET_AB_TEST_TIMELINE, getABTestTimeLine);
}

function* getReachRate(action) {
  try {
    const { testID } = action.payload;
    const result = yield call(api.getReachRate, testID);
    yield put(abTestsAction.getReachRateChartSuccess(result.data));
  } catch (e) {
    yield put(abTestsAction.getReachRateChartFailed());
  }
}

function* watchReachRate() {
  yield takeEvery(actionTypes.GET_REACH_RATE_CHART, getReachRate);
}

const abTestsSagas = [
  fork(watchGetABTestsChart),
  fork(watchReachRate),
];

export default abTestsSagas;
