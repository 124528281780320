import React, { Component } from "react";
import VersionsListHeader from "./VersionsListHeader/VersionsListHeader";
import { withRouter } from "react-router-dom";
import GalleryDiscoveries from '../../dash-discoveries/GalleryDiscoveries'


class VersionsList extends Component {
  render() {
    const { type, data, selectedTags, app, analytics, user, apps, userDiscoveries, source } = this.props;
    if (!data || Object.keys(data).length === 0) return <React.Fragment />;
    let filteredDiscoveriesByTag = selectedTags.length === 0 ? data
      : data.filter(dis => dis.labels.filter(x => selectedTags.includes(x)).length > 0)
    filteredDiscoveriesByTag = filteredDiscoveriesByTag.map(d => {
      let uD = userDiscoveries.find(uD => uD.insight_id === d.id)
      return {
        ...d,
        ...(uD ? uD : {})
      }
    })

    let mappedFilteredDiscoveriesByTag = filteredDiscoveriesByTag.map(d => ({ ...d, app_id: d.app_id ? d.app_id : app.id }))

    if (mappedFilteredDiscoveriesByTag.length === 0) {
      return null
    }

    return (
      <section className="versions__list">
        <VersionsListHeader type={type} data={data} count={mappedFilteredDiscoveriesByTag.length} />
        <GalleryDiscoveries
          loadingFilteredDiscoveries={false}
          filteredDiscoveries={mappedFilteredDiscoveriesByTag}
          apps={apps ? apps : [app]}
          showType={false}
          user={user}
          analytics={analytics}
          productName={null}
          handleClick={null}
          usePagination={false}
          source={source}
        />
      </section>
    );
  }
}

export default withRouter(VersionsList);
