import React from 'react'
import { Modal, Button, Checkbox } from 'antd'

import push_subs_ico from "../../assets/images/new_design/push-subs_ico.svg"
import "./PushSubsModal.scss"

export default function PushSubsModal({
  visible,
  closeModal,
  nowNowHandle,
  okHandle,
  remebmer,
  rememberCheckboxChange
}) {
  return (
    <Modal
      title={null}
      open={visible}
      className="modal-push-subs"
      width={560}
      footer={null}
      onCancel={closeModal}
    >
      <div className='push-subs-icon'><img src={push_subs_ico} /></div>
      <h1 className='push-subs-title'>Start creating a custom Subscription!</h1>
      <div className='push-subs-descr'>Create custom subscriptions and receive email and system notifications about what's most relevant to you.</div>
      <div className='push-subs-check'><Checkbox checked={remebmer} onChange={rememberCheckboxChange} > Don’t show it again. </Checkbox></div>
      <div className='push-subs-btns'>
        <Button type='default' className='push-subs-btn-not-now' onClick={nowNowHandle}>
          Not now, thanks!
        </Button>
        <Button type='default' className='push-subs-ok' onClick={okHandle}>
          Create now
        </Button>
      </div>
    </Modal>
  )
}
