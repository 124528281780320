import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import "./CampaignCalendar.scss";
import { App } from "containers/MarketingOverview/utils/types";
import { Popover } from "antd";
import { AppTooltip } from "./AppTooltip";
import {
  generateRandomBrightColor,
  isMobileDevice,
} from "containers/MarketingOverview/utils/helpers";

type AppByDate = {
  app: App;
  date: string;
  totalInsights: number;
  email_campaign?: number;
  push_notification_campaign?: number;
  inapp_campaign?: number;
};

type Position = {
  x: number;
  y: number;
  z: number;
  last: boolean;
};

type Props = {
  appsByDate: AppByDate[];
  daysAgo: number;
  customRange: boolean;
  customRangeTime: moment.Moment[];
  showLines?: boolean;
};

export const CampaignCalendar = ({
  appsByDate,
  daysAgo,
  customRange,
  customRangeTime,
  showLines,
}: Props) => {
  const [positions, setPositions] = useState<Position[]>([]);
  const [shouldUpdate, setShouldUpdate] = useState(true);

  const appsByDateWithPositions = useMemo(
    () =>
      appsByDate.map((app) => {
        return {
          ...app,
          x: moment(app.date).format("DD/MM"),
          y: app.totalInsights,
          r: 10,
        };
      }),
    [appsByDate]
  );

  const datasetsByApp = useMemo(
    () =>
      appsByDateWithPositions.reduce((acc: any, cur) => {
        const appIndex = acc.findIndex(
          (el: any) => el.label === cur.app?.displayName
        );

        if (appIndex === -1) {
          return [
            ...acc,
            {
              pointRadius: 0,
              pointBackgroundColor: "transparent",
              pointHoverRadius: 10,
              pointRotation: false,
              fill: false,
              lineTension: 0,
              borderColor: generateRandomBrightColor(0.7),
              label: cur.app?.displayName,
              data: [cur],
            },
          ];
        }

        acc[appIndex].data.push(cur);
        return acc;
      }, []),
    [appsByDateWithPositions]
  );

  const isMobile = isMobileDevice();

  const getDateLabels = () => {
    if (customRange) {
      const startDate = customRangeTime[0];
      const endDate = customRangeTime[1];
      const diff = endDate.diff(startDate, "days");
      const dates = [...Array(diff + 1)].map((el, ind) => {
        return moment(startDate).add(ind, "days");
      });
      return dates.map((date) => date.format("DD/MM"));
    }

    const daysBefore = daysAgo;
    const prevDays = -daysAgo;
    const dates = [...Array(daysBefore)].map((el, ind) => {
      return moment(moment().add(prevDays + daysBefore, "days")).subtract(
        ind,
        "days"
      );
    });
    return dates.reverse().map((date) => date.format("DD/MM"));
  };

  // plugin to get the coordinates of each point and save to state
  const getPositionsPlugin = {
    afterRender: (ctx: any) => {
      if (!shouldUpdate && appsByDate?.length === positions?.length) return;

      const newPositionsFullArr: any[] = [];

      ctx.controller.data.datasets.forEach((dataset: any) => {
        const _meta = dataset._meta;
        const meta = _meta[Object.keys(_meta)[0]];

        if (!meta) return;

        const newPositionsForApp = meta.data.reduce((acc: any, pos: any) => {
          const sameXY = acc.filter(
            (el: any) => el._x === pos._model.x && el.y === pos._model.y
          );

          const { appId, date } = dataset.data[pos._index];

          const coors = {
            x: pos._model.x + sameXY.length * 15,
            y: pos._model.y,
            z: sameXY.length,
            appId,
            date,
          };

          return [...acc, coors];
        }, []);

        newPositionsFullArr.push(...newPositionsForApp);
      });

      setPositions(newPositionsFullArr);
      setShouldUpdate(false);
    },
    resize: () => {
      setShouldUpdate(true);
    },
  };

  useEffect(() => {
    setShouldUpdate(true);
  }, [appsByDate]);

  const renderPosition = (pos: any, idx: number) => {
    const curApp = appsByDateWithPositions.find((app) => {
      return app.app.id === pos.appId && app.date === pos.date;
    });

    if (!curApp) return null;

    return (
      <Popover
        key={idx}
        trigger="hover"
        placement="top"
        overlayClassName="cc-app-tooltip"
        content={
          <AppTooltip
            appId={curApp?.app?.id}
            date={curApp?.date}
            emailInsights={curApp?.email_campaign || 0}
            inAppInsights={curApp?.inapp_campaign || 0}
            pushInsights={curApp?.push_notification_campaign || 0}
          />
        }
      >
        <div
          className="cc-app"
          key={idx}
          style={{
            top: pos.y - 15,
            left: pos.x - 15,
            zIndex: 100 + pos.z,
            backgroundImage: `url('${curApp?.app?.icon}')`,
          }}
        />
      </Popover>
    );
  };

  // adjust chart with if more than 30 labels
  useEffect(() => {
    const chart: any = document.querySelector(".cc-container-body");
    const maxLabelLength = isMobile ? 8 : 60;
    const labels = getDateLabels();

    if (!chart) return;

    if (labels.length >= maxLabelLength) {
      chart.style!.width = `${labels.length * 50}px`;
    } else {
      chart.style!.width = "100%";
    }

    setShouldUpdate(true);
  }, [daysAgo]);

  return (
    <div className="cc-container">
      <div className="cc-container-body">
        <Line
          width={isMobile ? 800 : undefined}
          height={isMobile ? 400 : undefined}
          data={{
            labels: getDateLabels(),
            datasets: datasetsByApp,
          }}
          plugins={[getPositionsPlugin]}
          options={{
            layout: {
              padding: {
                bottom: 30,
              },
            },
            maintainAspectRatio: false,
            responsive: true,
            scales: {
              yAxes: [
                {
                  ticks: {
                    callback: function (value: any) {
                      const val = Number(value);
                      return Math.floor(val) === val ? value : null;
                    },
                    min: 0,
                  },
                },
              ],
              xAxes: [
                {
                  ticks: {
                    autoSkip: false,
                    maxRotation: 45,
                    minRotation: 45,
                  },
                },
              ],
            },
            tooltips: {
              enabled: false,
            },
            showLines,
            legend: {
              display: false,
            },
          }}
        />
        {positions.map(renderPosition)}
      </div>
    </div>
  );
};
