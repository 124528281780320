import * as actionTypes from "../actions/_actionsTypes";

const INIT_STATE = {
  data: [],
  reachRate: [],
};

export default function (state = INIT_STATE, action) {
  switch (action.type) {

  case actionTypes.GET_AB_TEST_TIMELINE_SUCCESS: {
    return {
      ...state,
      data: [...state.data, action.payload.data]
    };
  }

  case actionTypes.GET_REACH_RATE_CHART: {
    return {
      ...state,
      reachRate: action.payload.reachRate
    };
  }
  case actionTypes.GET_REACH_RATE_CHART_SUCCESS: {
    return {
      ...state,
      reachRate: action.payload.reachRate
    };
  }
  case actionTypes.GET_REACH_RATE_CHART_FAILED: {
    return {
      ...state,
      reachRate: []
    };
  }



  default: {
    return state;
  }
  }
}
