import {
  ChannelToColor,
  ChannelToLabel,
  LabelToChannel,
} from "containers/MarketingOverview/utils/consts";
import {
  App,
  ChartValuesToggle,
} from "containers/MarketingOverview/utils/types";
import React, { useEffect, useRef } from "react";
import { Bar } from "react-chartjs-2";
import "./MarketingCampaignChannelsBreakdown.scss";
import { RouteComponentProps, withRouter } from "react-router";
import { isMobileDevice } from "containers/MarketingOverview/utils/helpers";

type Dataset = {
  channel: string;
  insightsByChannel: number[];
  channelBgColor: string;
  countsByChannel: number[];
  percentagesByChannel: number[];
};

type AppByInsightType = App & {
  insightTypes: {
    insightType: string;
    count: number;
    percentage: number;
  }[];
  totalAppInsights: number;
};

type Props = {
  apps: AppByInsightType[];
  selectedChannels: string[];
  chartValues: ChartValuesToggle;
} & RouteComponentProps;

const CHART_STEP_SIZE = 5;

const MarketingCampaignChannelsBreakdownBase = ({
  apps,
  selectedChannels,
  chartValues,
  history,
}: Props) => {
  const images = apps.map((app) => app.icon);
  const labels = apps.map((app) => app.displayName);
  const isMobile = isMobileDevice();

  const chartRef = useRef<any>();

  const datasets: Dataset[] = selectedChannels.map((channel) => ({
    channel: ChannelToLabel[channel],
    channelBgColor: ChannelToColor[channel],
    insightsByChannel: apps.map((app) => {
      const insightType = app.insightTypes.find(
        (insightType) => insightType.insightType === channel
      );
      const isPercentage = chartValues === "percentages";
      const values = isPercentage
        ? insightType?.percentage
        : insightType?.count;

      return insightType && values ? values : 0;
    }),
    countsByChannel: apps.map((app) => {
      const insightType = app.insightTypes.find(
        (insightType) => insightType.insightType === channel
      );

      return insightType ? insightType?.count : 0;
    }),
    percentagesByChannel: apps.map((app) => {
      const insightType = app.insightTypes.find(
        (insightType) => insightType.insightType === channel
      );

      return insightType ? insightType?.percentage : 0;
    }),
  }));

  const formattedDatasets = datasets.map((dataset) => ({
    label: dataset.channel,
    data: dataset.insightsByChannel,
    backgroundColor: dataset.channelBgColor,
    counts: dataset.countsByChannel,
    percentages: dataset.percentagesByChannel,
  }));

  let maxY =
    Math.floor(
      Math.max(
        ...datasets.map((dataset) => Math.max(...dataset.insightsByChannel))
      ) / CHART_STEP_SIZE
    ) *
      CHART_STEP_SIZE +
    CHART_STEP_SIZE * 2;

  maxY = isMobile ? maxY + CHART_STEP_SIZE * 2 : maxY;

  // plugin to replace x labels with images
  const xScaleImage = {
    id: "xScaleImage",
    afterDatasetsDraw: (chart: any) => {
      const {
        ctx,
        scales,
        data: { images },
      } = chart;
      const x = scales["x-axis-0"];

      ctx.save();

      images.forEach((image: any, index: number) => {
        const label = new Image();
        label.src = image;
        const w = 32;
        ctx.drawImage(
          label,
          x.getPixelForValue(index) - w / 2,
          x.top + 5,
          w,
          w
        );
      });
    },
  };

  // plugin to draw bar percentage overtop of each bar
  const barPercentageOvertop = {
    id: "barPercentageOvertop",
    afterDatasetsDraw: (chart: any) => {
      const {
        ctx,
        data: { datasets, chartValues },
      } = chart;

      ctx.save();

      datasets.forEach((dataset: any, i: number) => {
        const meta = chart.getDatasetMeta(i);
        if (meta.hidden) return;

        meta.data.forEach((element: any, index: number) => {
          const dataString = `${dataset.data[index]}${
            chartValues === "percentages" ? "%" : ""
          }`.toString();

          const textWidth = ctx.measureText(dataString).width;

          ctx.font = "12px Inter";
          ctx.fillStyle = "#000000";
          ctx.fillText(
            dataString,
            element._model.x - textWidth / 2,
            element._model.y - 12
          );
        });
      });
    },
  };

  // adjust chart with if more than 7 labels
  useEffect(() => {
    const chart: any = document.querySelector(".mccb-container-body");
    const maxLabelLength = isMobile ? 2 : 7;

    if (!chart) return;

    if (labels.length > maxLabelLength) {
      chart.style!.width = `${labels.length * 200}px`;
    } else {
      chart.style!.width = "100%";
    }
  }, [labels.length]);

  return (
    <div className="mccb-container">
      {/* CHART 1 - shows overlay for scrolling */}
      <div className="mccb-col-small">
        <Bar
          options={{
            scales: {
              xAxes: [
                {
                  display: false,
                  categoryPercentage: 0,
                  barPercentage: 0,
                },
              ],
              yAxes: [
                {
                  display: false,
                },
              ],
            },
            legend: {
              position: "top",
              align: isMobile ? "start" : "end",
              labels: isMobile
                ? {
                    boxWidth: 15,
                  }
                : {},
            },
            maintainAspectRatio: false,
            responsive: true,
            layout: {
              padding: {
                bottom: 60,
              },
            },
          }}
          data={{
            labels,
            datasets: formattedDatasets,
          }}
        />
      </div>
      {/* CHART 2 - shows sidebar for scrolling */}
      <div className="mccb-side-col">
        <Bar
          height={isMobile ? 258 : 457}
          options={{
            scales: {
              xAxes: [
                {
                  display: false,
                  categoryPercentage: 0,
                  barPercentage: 0,
                },
              ],
              yAxes: [
                {
                  ticks: {
                    min: 0,
                    stepSize: CHART_STEP_SIZE,
                    max:
                      chartValues === "numbers"
                        ? maxY + CHART_STEP_SIZE * 2
                        : maxY,
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
            },
            legend: {
              display: false,
            },
            maintainAspectRatio: false,
            responsive: false,
            layout: {
              padding: {
                bottom: 60,
              },
            },
          }}
          data={{
            labels,
            datasets: formattedDatasets,
          }}
        />
      </div>
      {/* CHART 3 - shows the actual data */}
      <div className="mccb-col-large">
        <div className="mccb-container-body">
          <Bar
            ref={chartRef}
            options={{
              hover: {
                mode: null,
                onHover: (event: any) => {
                  if (!chartRef.current) return;

                  const { chartInstance } = chartRef.current;
                  const point = chartInstance.getElementAtEvent(event);
                  event.target.style.cursor = point.length
                    ? "pointer"
                    : "default";
                },
              },
              onClick: (event: any) => {
                if (!chartRef.current) return;

                const { chartInstance } = chartRef.current;
                const point = chartInstance.getElementAtEvent(event);
                if (!point.length) return;

                const { _model } = point[0];
                const { datasetLabel, label } = _model;
                const app = apps.find((app) => app.displayName === label);
                const type =
                  LabelToChannel[datasetLabel as keyof typeof LabelToChannel];

                if (!app) return;

                const params = new URLSearchParams(history.location.search);
                const daysAgo = params.get("days_ago") || 30;

                history.push(
                  `/marketing/campaigns?app_id=${app.id}&channel=${type}&days_ago=${daysAgo}`
                );
              },
              tooltips: {
                enabled: true,
                callbacks: {
                  label: (tooltipItem: any, data: any) => {
                    const { datasetIndex, index } = tooltipItem;
                    const dataset = data.datasets[datasetIndex];
                    const counts = dataset.counts[index];
                    const percentages = dataset.percentages[index];
                    const value =
                      chartValues === "numbers" ? percentages : counts;

                    return `${value}${chartValues === "numbers" ? "%" : ""} ${
                      dataset.label
                    }`;
                  },
                },
              },
              maintainAspectRatio: false,
              responsive: true,
              layout: {
                padding: {
                  bottom: 60,
                },
              },
              scales: {
                // hide x labels to replace them with images
                xAxes: [
                  {
                    display: false,
                    categoryPercentage: labels.length <= 2 ? 0.2 : 0.35,
                    barPercentage: 1.0,
                  },
                ],
                yAxes: [
                  {
                    ticks: {
                      display: false,
                      min: 0,
                      stepSize: CHART_STEP_SIZE,
                      max:
                        chartValues === "numbers"
                          ? maxY + CHART_STEP_SIZE * 2
                          : maxY,
                    },
                  },
                ],
              },
              legend: {
                labels: {
                  fontColor: "rgba(0, 0, 0, 0)",
                  boxWidth: 0,
                },
              },
            }}
            plugins={[xScaleImage, barPercentageOvertop]}
            data={{
              labels,
              datasets: formattedDatasets,
              images,
              chartValues,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const MarketingCampaignChannelsBreakdown = withRouter(
  MarketingCampaignChannelsBreakdownBase
);
