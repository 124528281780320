import React, { useMemo } from "react";
import { Card } from ".";
import { useContextTimeline } from "pages/ContextTimeline/context";
import moment from "moment";
import "./styles.scss";

type Props = {
  card: Card;
  onClose: () => void;
};

export const SameDayEventsPopover = ({ card, onClose }: Props) => {
  const { eventsByDateList, eventName, handleChangeFilter } =
    useContextTimeline();

  const eventsWithCounts = useMemo(() => {
    return card.events
      ?.filter((e) => e.name !== eventName)
      ?.map((cardEvent) => {
        const sameEvents = eventsByDateList?.filter((eventByDate) =>
          eventByDate.events.find((event) => event.name === cardEvent.name)
        );

        const index = sameEvents?.findIndex(
          (eventByDate) =>
            moment(eventByDate.date).format("DD/MM/YYYY") ===
            moment(card.date).format("DD/MM/YYYY")
        );

        return {
          ...cardEvent,
          totalCount: sameEvents?.length || 0,
          positionOutOfTotal: (index || 0) + 1,
        };
      });
  }, [card.events, eventsByDateList, eventName]);

  return (
    <div className="same-day-events-container">
      <div className="sde-title">Same Day Events</div>
      {eventsWithCounts?.map((event) => (
        <div
          key={event.name}
          className="sde-event"
          onClick={(e) => {
            e.stopPropagation();
            handleChangeFilter?.("event_name", event.name);
            onClose();
          }}
        >
          <div className="sde-event-title">{event.name}</div>
          <div className="sde-event-count">
            ({event.positionOutOfTotal}/{event.totalCount})
          </div>
        </div>
      ))}
    </div>
  );
};
