import React, {FC, ReactElement, SyntheticEvent, useEffect, useState} from 'react';
import {Modal} from "antd";
import { History } from 'history';
import {match} from 'react-router-dom'
// @ts-ignore
import bgVideo from "../../../assets/bg-video.mp4";
import logo from "../../../assets/images/login/logo.png";
import {InputText} from "../../../components/inputText/InputText";
import expired_ico from "../../../assets/images/login/Expired.svg";
import expirationMailSent_ico from "../../../assets/images/login/sentExpirationMail.svg";
import { changePasswordFirstLogin, sendNewChangePWDMail, checkNonceExpired } from "../../../redux/actions/dashboardActions";
import { setUserPassSuccessReset } from '../../../redux/actions/userActions';
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {IState} from "../../../interfaces/Product";

export const USER_CREATION_EXPIRED = 'Your user creation token has expired';

interface Imatch {
  nonce: string;
  email: string;
}

interface IProps {
  history: History;
  match: match<Imatch>;
  changePasswordFirstLogin: (arg0: object) => void;
  sendNewChangePWDMail:  (arg0: object) => void;
  errorChangePass: string;
  successPassChange: boolean | undefined;
  checkNonceExpired: (arg0: object) => void;
  tokenExpireMailSent: boolean;
  setUserPassSuccessReset: () => void;
}

const ChangePWDOnFirstLogin: FC<IProps> = ({
  history,
  match,
  changePasswordFirstLogin,
  errorChangePass,
  successPassChange,
  sendNewChangePWDMail,
  checkNonceExpired,
  tokenExpireMailSent,
  setUserPassSuccessReset,
}): ReactElement => {

  const [email, setEmail] = useState('');
  const [nonce, setNonce] = useState('');
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [userCreationExpired, setUserCreationExpired] = useState(false);

  useEffect(() => {
    if (successPassChange) {
      history.push('/')
      setUserPassSuccessReset()
    }
  },[successPassChange])

  useEffect(() =>{
    if (errorChangePass){
      setIsLoading(false)
      if (USER_CREATION_EXPIRED === errorChangePass) {
        setUserCreationExpired(true)

      }
      setErrorMsg(errorChangePass)

    }
  },[errorChangePass])

  useEffect(()=>{

    if (match.params){
      setNonce(match.params.nonce);
      setEmail(match.params.email)
      checkNonceExpired({
        email:match.params.email,
        nonce:match.params.nonce,
      })
    }
  } ,[match.params])

  const  handleChange = (type: string) => (event: any) => {
    const { value } = event.target;
    switch (type) {
    case 'password':
      setPassword(value);
      break
    case 'confirmedPassword':
      setConfirmedPassword(value)
      break
    }

  };

  const handleCanplaythrough = (e: any) => {
    e.target.playbackRate = 0.7
  }

  const handleChangePWD = () => {
    
    if (password !== confirmedPassword) {
      setErrorMsg( "Passwords don't match! Make sure to repeat passwords correctly.")
      return
    }
    setIsLoading( true);
    setErrorMsg("");
    changePasswordFirstLogin({
      email,
      password,
      nonce,
    })
  };
  const resendMailAfterExpiration =() => {
    sendNewChangePWDMail({
      email,
    }
    )
  }
  const header = tokenExpireMailSent ? <h2 className={'expired-text'} style ={{marginLeft: 0, fontSize: '2.3rem'}}>
        We sent you a <b>new email</b>
  </h2> :
    <h2 className={'expired-text'}>Your user token has <b>expired.</b></h2>
  const changePWDOrExpired = userCreationExpired ?
    <div className="side-block login-card">
      {header}
      <span className={'email'}>{email}</span>

      {tokenExpireMailSent ?
        <img className={'expired expiration-img'} src={expirationMailSent_ico} alt="expiration mail sent" /> :
        <div>  
          <img className={'expired'} src={expired_ico}/>
          <div className="btn-wrapper">
            <button tabIndex={4} className="red-button"
              disabled={
                isLoading || tokenExpireMailSent
              }
              onClick={resendMailAfterExpiration}>
              Get New Email
            </button>
          </div>
        </div>}
    </div>
    :
    <div className="side-block login-card">
      <h2>Please, set a new password</h2>
      <span className='email'>{email}</span>
      <InputText
        label="Create a new password"
        placeholder="New password"
        value={password}
        onChange={handleChange("password")}
        autoFocus={true}
        password
        disabled={isLoading}
        data-testid={'password'}
      />
      <InputText
        label="Repeat password"
        placeholder="Repeat the new password"
        value={confirmedPassword}
        onChange={handleChange("confirmedPassword")}
        autoFocus={false}
        password
        disabled={isLoading}
        data-testid={'confirmedPassword'}
      />

      <div className="login__form-field login__form-field-error">
        <span>{errorMsg}</span>
      </div>
      <div className="btn-wrapper">
        <button  tabIndex={4} className="red-button"
          disabled={
            confirmedPassword === "" || confirmedPassword.length<6  ||
                 password === "" || password.length<6 ||
                 isLoading || userCreationExpired
          }
          onClick={handleChangePWD}
          data-testid={'confirmButton'}
        >
        Confirm
        </button>
      </div>
    </div>
  return (

    <div className="login" id="login-page">

      <Modal
        open={isModalOpen}
        onOk={()=> setIsModalOpen(!isModalOpen)}
        onCancel={()=> setIsModalOpen(!isModalOpen)}
      >
             
      </Modal>
      <div className="video-wrapper">
        <video autoPlay muted loop id="bg-video" onCanPlayThrough={handleCanplaythrough}>
          <source src={bgVideo} type="video/mp4" />
            Sorry, your browser doesn't support embedded videos.
        </video>
        <div className="video-overlay"/>
      </div>
      <div className="login-wrapper">
        <div className="side-block login-intro">
          <img src={logo} alt="logo" />
          <h3>Welcome to</h3>
          <h1>Watchful <strong>Platform</strong></h1>
          <p>The product intelligence solution trusted by <span className="cond-bold">FORTUNE</span> ® <span className="cond-bold">500</span> teams to stay ahead of their competition. </p>
                  
        </div>

        {changePWDOrExpired}

      </div>
    </div>
  );
};

const mapStateToProps = (state: IState) => ({
  errorChangePass: state.userData.errorChangePass,
  successPassChange: state.userData.successPassChange,
  tokenExpireMailSent: state.userData.tokenExpireMailSent,
});

const mapDispatchToProps = {
  changePasswordFirstLogin,
  sendNewChangePWDMail,
  checkNonceExpired,
  setUserPassSuccessReset,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangePWDOnFirstLogin));