import React from "react";

import VersionsListItem from "../../../containers/products-discoveries/versionsList/versionsListItem/VersionsListItem"

import android_logo from "../../../assets/images/icons/playstore_icon.png"
import ios_logo from "../../../assets/images/icons/appstore_icon.png"
import web_logo from "../../../assets/images/icons/web_logo.svg"
import { getDiscoveriesStartTime } from "../../../helpers/generalHelpers"

import moment from "moment"

export default function RelatedDiscovery({ discovery, app, onClick, isMarketingDiscovery }) {
  if (!discovery) {
    return null
  }
  let platform = app.platform
  return <div className="rel-insight" data-testid="related-insight"
    onClick={() => onClick({
      item: {
        ...discovery,
        ...app,
        metadata: app,
        app_id: app.id,
        insightId: discovery.id,
      },
      insightOrReport: 'insight',
    }
    )}>
    <div className="rel-insight-app">
      <div className="app-data">
        <img src={app.icon} className="app-icon" />
        <div className="app-title">{app.display_name}</div>
      </div>
      <div className="ins-release-date">
        {getDiscoveriesStartTime(discovery)}
      </div>
      <div className="ver-platform">
        {!isMarketingDiscovery && <><div className="insight-ver">{discovery.release_name}</div>
          |
          <div className="app-platform">
            {platform === 'Android' && <img src={android_logo} alt={platform} />}
            {platform === 'iOS' && <img src={ios_logo} alt={platform} />}
            {platform === 'Web' && <img src={web_logo} alt={platform} />}
            {app.platform}
          </div>
        </>
        }
      </div>

    </div>
    <div>
      <VersionsListItem item={discovery} noRedirect={true} />
    </div>
  </div>

}