
import { Product, App } from '../../interfaces/Product'

export const getNumberOfInsights = (product:Product, unSeenCriticalInsights:any) => {
  if (!product || !unSeenCriticalInsights) {
    return 0
  }
  const filteredAndroidApp = product.apps.find((app: App) => app.platform.toLowerCase() === 'android');

  const numOfAndroid = filteredAndroidApp && unSeenCriticalInsights[filteredAndroidApp.id] ? unSeenCriticalInsights[filteredAndroidApp.id].length : 0;

  const filteredIOSApp = product.apps.find((app: App) => app.platform.toLowerCase() === 'ios');
  const numOfIOS = filteredIOSApp && unSeenCriticalInsights[filteredIOSApp.id] ? unSeenCriticalInsights[filteredIOSApp.id].length : 0;

  const filteredWebApp = product.apps.find((app: App) => app.platform.toLowerCase() === 'web');
  const numOfWeb = filteredWebApp && unSeenCriticalInsights[filteredWebApp.id] ? unSeenCriticalInsights[filteredWebApp.id].length : 0;

  const filteredMarketingApp = product.apps.find((app: App) => app.platform.toLowerCase() === 'marketing');
  const numOfMarketing = filteredMarketingApp && unSeenCriticalInsights[filteredMarketingApp.id] ? unSeenCriticalInsights[filteredMarketingApp.id].length : 0;

  return numOfAndroid + numOfIOS + numOfWeb + numOfMarketing
};