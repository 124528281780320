import * as actionTypes from './_actionsTypes';

export const getLinkData = (link, guest_token) => ({
  type: actionTypes.GET_LINK_DATA,
  payload: {
    link,
    guest_token
  }
});

export const getLinkDataSuccess = ({ data }) => ({
  type: actionTypes.GET_LINK_DATA_SUCCESS,
  payload: {
    data
  }
});

export const updateLinkData = (link, domain_verification) => ({
  type: actionTypes.UPDATE_LINK_DATA,
  payload: {
    link,
    domain_verification
  }
});

export const updateLinkDataSuccess = ({ data }) => ({
  type: actionTypes.UPDATE_LINK_DATA_SUCCESS,
  payload: {
    data
  }
});

export const getLinkDataFailed = () => ({
  type: actionTypes.GET_LINK_DATA_FAILED
});


export const initLinkData = () => ({
  type: actionTypes.INIT_LINK_DATA,
});

export const createLinkData = ({ appId, email, insightId }) => ({
  type: actionTypes.CREATE_LINK_DATA,
  payload: {
    appId,
    email,
    insightId,
  }
});

export const createLinkDataSuccess = ({ link, insightId, domain_verification = true }) => ({
  type: actionTypes.CREATE_LINK_DATA_SUCCESS,
  payload: {
    link,
    insightId,
    domain_verification
  }
});

export const createLinkDataFailed = () => ({
  type: actionTypes.CREATE_LINK_DATA_FAILED
});

export const getRecipients = () => ({
  type: actionTypes.GET_RECIPIENTS,
});

export const getRecipientsSuccess = ({data}) => ({
  type: actionTypes.GET_RECIPIENTS_SUCCESS,
  payload: data
});

export const sendShareEmail = (data) => ({
  type: actionTypes.SEND_SHARE_EMAIL,
  payload: data
});

export const sendShareEmailSuccess = ({data}) => ({
  type: actionTypes.SEND_SHARE_EMAIL_SUCCESS,
  payload: data
});

export const sendShareBiWeeklyEmail = (data) => ({
  type: actionTypes.SEND_SHARE_BIWEEKLY_EMAIL,
  payload: data
});

export const sendShareBiWeeklyEmailSuccess = ({data}) => ({
  type: actionTypes.SEND_SHARE_BIWEEKLY_EMAIL_SUCCESS,
  payload: data
});