export type Journey = {
  id: number;
  name: string;
  last_changed: string;
  images: { id: number; src: string }[];
};

export type Persona = {
  id: number;
  title: string;
  location: string;
  roles: string[];
};

export type UserJourneyApp = {
  id: number;
  name: string;
  thumbnail: string;
  tracked_since: string;
  journeys: Journey[];
  user_flows: Array<{id: number, personas: Persona[]}>
};

export const FilterKeys = {
  Personas: "personas",
  Apps: "apps",
  Flow: "flow",
};

export type Version = {
  id: number;
  release_date: string;
  release_name: string;
  version_code: number;
};
