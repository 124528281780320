import React, { useState, useEffect, useRef } from 'react'
import { Popover, Button } from "antd"
import moment from "moment"

import FilterTime from '../../dash-discoveries/FilterTime'
import NewLoader from "../../../common/NewLoader/NewLoader";
import PerfEventsGraphsCont from "./PerfEventsGraphsCont"
import { Link } from "react-router-dom";

import blackArrowSlider from "../../../assets/images/icons/blackArrowSlider.svg";
import whiteArrowSlider from "../../../assets/images/icons/whiteArrowSlider.svg";

import "./BenchmarkMetrics.scss"

import {
  buildFormattedDevicesList,
  getTimeFrameFilter,
  restoreEventFromURL,
} from "../BenchmarkCompare/comparePageUtils"

import { daysAgoList, ALL_TIME } from "../../../shared/consts"

import PerformanceAppDrawer from "../BenchmarkCompare/performanceAppDrawer/performanceAppDrawer"


function BenchmarkMetricsView({
  match,
  history,
  performanceApps,
  eventsData,
  deviceLocations,
  perfDeviceModels,
  perfInfrastructure,
  isFetchingPerfData,


  getPerformancePageData,
  analytics
}) {

  const initValues = new URLSearchParams(history.location.search)
  const eventID = match.params.eventID

  const [currentEvent, currentEventChange] = useState(restoreEventFromURL(initValues, Number(eventID), 1, performanceApps, perfDeviceModels))

  const [initURL, initURLChange] = useState(decodeURIComponent(initValues.get('backURL')))

  const [subEvent, subEventChange] = useState(initValues.get('subEvent') || '')

  const [isOpenTimeFilter, isOpenTimeFilterChange] = useState(false)
  const [daysAgo, daysAgoChange] = useState(initValues.get('days_ago') ? Number(initValues.get('days_ago')) : 30)
  const [customRange, customRangeChange] = useState(!!(initValues.get('customRange') && initValues.get('customRange') === '1'))
  const [customRangeTime, customRangeTimeChange] = useState(initValues.get('customRangeTime') ? [moment(initValues.get('customRangeTime').split(",")[0]),
    moment(initValues.get('customRangeTime').split(",")[1])] : [moment(), moment()])

  useEffect(() => {
    if (!currentEvent.id) {
      return
    }
    let currentEventCopy = { ...currentEvent }
    currentEventCopy.key = null
    currentEventChange(currentEventCopy)
  }, [customRange, customRangeTime, daysAgo])

  useEffect(() => {
    analytics.analytic('Metrics Analysis page', {
      'App': currentEvent.app.name,
      'Platform': currentEvent.app.platform,
      'Date range': daysAgo,
      'Custom dates': customRange ? customRangeTime : '',
      'Device': currentEvent.device.product_name,
      'OS version': currentEvent.os_version,
      'Event': currentEvent.app.performance_events.find(pE => pE.id === currentEvent.id).title
    })
  }, [])

  useEffect(() => {
    const ev = currentEvent
    if (ev.id && ev.app && ev.location && ev.device && !ev.key) {
      let data = {
        id: ev.id,
        allMetrics: true,
        filters: [...getTimeFrameFilter(customRange, customRangeTime, daysAgo)]
          .concat([{ filterType: 'device_model', filterValue: ev.device.model }])
          .concat([{ filterType: 'os_version', filterValue: ev.os_version ? ev.os_version : 'All' }])
          .concat([{ filterType: 'orchestrator_location', filterValue: ev.location }])
      }
      getPerformancePageData([data])
    }


    updateURL()
  }, [currentEvent])

  useEffect(() => {
    if (eventsData.length === 0) { return }
    let e = { ...currentEvent }
    let timeFilter = getTimeFrameFilter(customRange, customRangeTime, daysAgo).map(f => f.filterValue).join()
    let hasChanged = false
    if (`${e.id}-${timeFilter},${e.device ? e.device.model : '-'},${e.os_version ? e.os_version : 'All'},${e.location}` === eventsData[0].key) {
      hasChanged = true
      e.data = eventsData[0].data
      e.history = eventsData[0].history
      e.key = eventsData[0].key
    }

    if (hasChanged) {
      currentEventChange(e)
    }
  }, [eventsData])

  const handleAppChange = (appID, indexEvent) => {
    const app = appID !== null ? performanceApps.find(app => app.id === appID) : null
    let currentEventCopy = { ...currentEvent }
    if (app) {
      currentEventCopy = {
        app,
      }
      currentEventCopy.id = app.performance_events.find(ev => ev.name === 'launch')?.id
      currentEventCopy = setDeviceByLocation(currentEventCopy, perfInfrastructure[0].loc)
    }
    currentEventChange(currentEventCopy)
  }
  const handleEventChange = (event, i) => {
    let currentEventCopy = { ...currentEvent }
    currentEventCopy.id = event.value
    currentEventCopy = clearDataForEvent(currentEventCopy)
    currentEventChange(currentEventCopy)
  }
  const handleDeviceChange = (device, i) => {
    let currentEventCopy = { ...currentEvent }
    let perfDevices = currentEventCopy.app.device_models.filter(perfDev => perfDev.model === device.value)
    currentEventCopy.device = perfDevices[0]
    currentEventCopy.os_version = (perfDevices.length === 1 ? perfDevices[0].os_version: '')

    currentEventCopy = clearDataForEvent(currentEventCopy)
    currentEventChange(currentEventCopy)
  }
  const handleOSChange = (os_version, i) => {
    let currentEventCopy = { ...currentEvent }
    currentEventCopy.os_version = os_version.value
    currentEventCopy = clearDataForEvent(currentEventCopy)
    currentEventChange(currentEventCopy)
  }
  const handleLocationChange = (location, i) => {
    let currentEventCopy = { ...currentEvent }
    currentEventCopy = setDeviceByLocation(currentEventCopy, location.value)
    currentEventCopy = clearDataForEvent(currentEventCopy)
    currentEventChange(currentEventCopy)
  }
  const clearDataForEvent = (ev) => {
    ev.key = null
    ev.data = null
    ev.history = null
    return ev
  }
  const setDeviceByLocation = (event, location) => {
    if (!event.app) { return event }
    const devicesList = buildFormattedDevicesList(event.app.device_models, perfInfrastructure, location)

    event.location = location
    if (devicesList.length === 1) {
      event.device = perfDeviceModels.find(perfDev => perfDev.model === devicesList[0].value)
      let perfDevices = event.app.device_models.filter(perfDev => perfDev.model === event.device.model)
      event.os_version = (perfDevices.length === 1 ? perfDevices[0].os_version : '')
    } else {
      event.device = null
      event.os_version = ''
    }
    return event
  }

  const updateURL = () => {
    const toURL = `/benchmark/compare/metrics/${currentEvent.id}`

    let filters = {
      [`device_1`]: currentEvent.device ? currentEvent.device.model : '',
      [`os_version_1`]: currentEvent.os_version || '',
      [`loc_1`]: currentEvent.location || '',
    }

    const gets = new URLSearchParams({
      days_ago: daysAgo,
      subEvent: subEvent,
      customRange: customRange ? 1 : 0,
      customRangeTime: customRangeTime.map(date => date.format('YYYY-MM-DD')),
      ...filters,
    }).toString()
    history.replace(`${toURL}?${gets}&backURL=${encodeURIComponent(initURL)}`);
  }


  return (
    <div className="performance performance-metrics">
      <h3 className="title">Metric Analysis</h3>
      {eventID && <div className='back-btn-wrapper'>
        <Link to={initURL}>
          <Button className='ant-btn-dark'>
            <span className="btn-arrow">
              <img src={blackArrowSlider} className="black-arrow" alt="" />
              <img src={whiteArrowSlider} className="white-arrow" alt="" />
            </span>
            Back
          </Button>
        </Link>
      </div>}
      <div className="benchmark__sub-header">
        <span className="benchmark-select-label">Showing data from</span>
        <Popover placement="bottomRight" title={null}
          visible={isOpenTimeFilter}
          onVisibleChange={() => isOpenTimeFilterChange(!isOpenTimeFilter)}
          content={
            <FilterTime
              isOpen={isOpenTimeFilter}
              daysAgoList={daysAgoList}
              daysAgo={daysAgo}
              daysAgoChange={daysAgoChange}
              customRange={customRange}
              сustomRangeСhange={customRangeChange}
              customRangeTime={customRangeTime}
              customRangeTimeChange={customRangeTimeChange}
              oneDay={false}
            />
          } trigger="click" overlayClassName={`filter-wrap all-discoveries all-discoveries-time`}
          overlayStyle={{ width: (customRange ? '75rem' : '18rem') }}>
          <Button className="filter-btn-new">
            {customRange ? 'Custom Range' : daysAgoList.find(d => d.value == daysAgo).title}
          </Button>
        </Popover>
      </div>

      <div className="performance-app-drawers">
        {currentEvent && <PerformanceAppDrawer
          page="performance_compare"
          isPerformance={true}
          appsList={performanceApps || []}
          perfInfrastructure={perfInfrastructure}
          isMainApp={true}
          selectedApp={currentEvent.app ? performanceApps.find(app => app.id === currentEvent.app.id) : null}
          appChange={appID => handleAppChange(appID, 0)}
          handleEventChange={(event) => handleEventChange(event, 0)}
          handleDeviceChange={(device) => handleDeviceChange(device, 0)}
          handleOSChange={(os_version) => handleOSChange(os_version, 0)}
          handleLocationChange={(loc) => handleLocationChange(loc, 0)}
          event={currentEvent}
          deviceLocations={deviceLocations}
          index={0}
          woutColorLine={true}
        />}
      </div>

      {isFetchingPerfData && <div className="bench-compare-loader">
        <NewLoader />
      </div>}


      {/* DATA 4 History */}
      {!isFetchingPerfData &&
        currentEvent.history && //currentEvent.history.duration_s && currentEvent.history.duration_s.length > 0 &&
        <PerfEventsGraphsCont
          currentEvent={currentEvent}
          subEventChange={subEventChange}
          updateURL={updateURL}
          daysAgo={daysAgo}
          daysAgoChange={daysAgoChange}
          customRange={customRange}
          customRangeChange={customRangeChange}
          customRangeTime={customRangeTime}
          customRangeTimeChange={customRangeTimeChange}
        />
      }

    </div>
  )
}

export default BenchmarkMetricsView