import React from "react";
import "./Footer.scss";
import moment from "moment";
import {Link} from "react-router-dom";

const Footer = ({

}) => {
  return (
    <div className={"footer-wrapper"}>
      <div className={"rectangle1"} />
      <div className={"footer"}>
        <img
          alt="watchful gray icon"
          className={"watchful-img"}
          src="https://static.overlay-tech.com/assets/b94a630a-56d6-423f-807e-bda7fd7e3207.svg"
        />
        <div
          className={
            "watchfulAi2021The360CompetitiveM"
          }
        >
          <strong
            className={
              "watchfulAi2021The360CompetitiveMEmphasis0"
            }
          >
                        Watchful.ai © { moment().year()}
          </strong>
          <div className={"blended"}>
                The 360 Competitive Monitoring Platform
          </div>

        </div>
        <p
          className={"privacyEndUserLicenseAgreement"}
        >
          <Link to={'/privacy'}>Privacy</Link> | <Link to={'/eula'}>End User License Agreement</Link>
        </p>
      </div>
    </div>
  );
};
export default Footer;