import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ClickOutHandler from "react-onclickout";


import head_acc_ico from "../../assets/images/new_design/header/header_acc.svg"
import header_subs_ico from "../../assets/images/new_design/header/header_subs.svg"
import header_logout_ico from "../../assets/images/new_design/header/header_logout.svg"


class DropdownNew extends Component {
  state = {
    menuOpen: false
  };

  handleClick = (optionIndex, value) => {
    this.props.handleChange(optionIndex, value);
    this.closeMenu();
  };

  dropClicked = () => {
    window.scrollTo(0, 0);
    this.setState(state => ({ menuOpen: !state.menuOpen }));
  };

  closeMenu = () => {
    this.setState({ menuOpen: false });
  };

  renderMenu = () => {
    const { menuOpen } = this.state;
    const options = menuOpen ? (
      <ClickOutHandler onClickOut={this.closeMenu}>
        {this.renderMenuOptions()}
      </ClickOutHandler>
    ) : (
      this.renderMenuOptions()
    );
    return options;
  };

  renderMenuOptions = () => {
    const { menuOpen } = this.state;
    const { options, selectOptionIndex, className } = this.props;
    return (
      <ul
        className={`dropdown__menu ${menuOpen ? "dropdown__menu-active" : ""
        } ${className}__menu  ${menuOpen ? `${className}__active` : ""} `}
      >
        {options.map(({ label, value, icon }, index) => (
          selectOptionIndex !== index &&
          <li
            key={index}
            className={`dropdown__label ${className}__label`}
            onClick={() => this.handleClick(index, value)}
          >

            {icon === 'user' && <img src={head_acc_ico} />}
            {icon === 'subscription' && <img src={header_subs_ico} />}
            {icon === 'logout' && <img src={header_logout_ico} />}
            {label}
          </li>
        ))}
      </ul>
    );
  };

  oneItemClicked = () => {
    const { options } = this.props;
    this.props.handleChange(0, options[0].value);
  };

  render() {
    const { selectOption, className, options } = this.props;
    const onlyOneItem = options.length === 1;
    return (
      <div className={`dropdown ${className} ${this.state.menuOpen ? 'open' : ''}`}>
        {!onlyOneItem ? (
          <div
            className={`dropdown__select ${className}__select`}
            onClick={this.dropClicked}
          >
            {selectOption}
          </div>
        ) : (
          <div
            className={`dropdown__select ${className}__select dropdown-nav__no-select`}
            onClick={this.oneItemClicked}
          >
            {selectOption}
          </div>
        )}
        {!onlyOneItem && this.renderMenu()}
      </div>
    );
  }
}

export default withRouter(DropdownNew);
