import axios from "axios";
import config from "../../shared/config";

export const getLinkData = ({ link, guest_token }) => {
  let { backendUrl, linkData } = config.dataApi;
  const url = `${backendUrl}/${linkData}?link=${link}`;
  return axios.get(url, guest_token ? {
    headers: {
      "X-Api-Key": guest_token
    }
  } : {});
};

export const createLinkData = ({ appId, email, insightId }) => {
  let { backendUrl, linkCreate } = config.dataApi;
  const url = `${backendUrl}/${linkCreate}?app_id=${appId}&email=${email}&insight_id=${insightId}`;
  return axios.get(url);
};

export const updateLinkDataAPI = ({ link, domain_verification }) => {
  let { backendUrl, linkCreate } = config.dataApi;
  const url = `${backendUrl}/user/direct-link/${link}`;
  return axios.put(url,{
    domain_verification: domain_verification
  });
};

export const getRecipientsAPI = () => {
  let { backendUrl } = config.dataApi;
  const url = `${backendUrl}/organization/users`;
  return axios.get(url);
};

export const sendShareEmailAPI = (data) => {
  let { backendUrl } = config.dataApi;
  const url = `${backendUrl}/user/shared-mail/insight`;
  return axios.post(url, data);
};

export const sendShareBiWeeklyEmailAPI = (data) => {
  let { backendUrl } = config.dataApi;
  const url = `${backendUrl}/user/shared-mail/bi-weekly`;
  return axios.post(url, data);
};