import React, { useState, version } from "react";
import moment from "moment";
import { ClickableVersionTags } from "../../../components/discoveryView/viewTags/ViewTags";
import storageService from "../../../services/storageService";
import Icon  from "@ant-design/icons";

const VersionsHeader = ({
  releaseName,
  releaseDate,
  minorVersionsCounter,
  versionInsightTags,
  selectedTags,
  setParentSelectedTags,
  versionID,
  versionState,
  recentChanges
}) => {
  const date = moment(releaseDate).format("ll");
  const storage = new storageService();
  const [readBlock, readBlockChange] = useState(JSON.parse(storage.getSessionItem(`read-verids`)) || {})

  function tagSelectionHandler(tag) {
    if (selectedTags.includes(tag)) {
      // remove the tag from the selected tags list
      setParentSelectedTags(selectedTags.filter(listTag => listTag !== tag));
    } else {
      // add the tag to the selected tags list
      setParentSelectedTags(selectedTags.concat(tag));
    }
  }

  const closeBlock = () => {
    let copyReadBlock = {...readBlock}
    copyReadBlock[versionID] = 1
    readBlockChange(copyReadBlock)
    storage.setSessionItem(`read-verids`, JSON.stringify(copyReadBlock))


  }

  return (
    <React.Fragment>
      <div className="versions__header">
        {releaseName && <h2 className="heading versions__header-primary">{`Ver ${releaseName}`}</h2>}
        <h4 className="versions__header-secondary">{date}</h4>
        {Object.keys(versionInsightTags).length > 0 && (
          <ClickableVersionTags
            tags={versionInsightTags}
            selectedTags={selectedTags}
            tagSelectionHandler={tagSelectionHandler}
          />
        )}
        {versionState == "analysis_in_progress" && !readBlock[versionID] &&
          <div className="in-progress-block">
            <h2>IN PROGRESS</h2>
            <div className="descr">We may find additional discoveries for this version, stay tuned. </div>
            <div className="close-block" onClick={closeBlock}>
              <Icon type="close" />
            </div>
          </div>
        }
        {recentChanges &&<div className="recent-changes">
          <h3>What’s New (Dev Release Notes)</h3>
          <div className="change">* {recentChanges}</div>
        </div>}
      </div>
    </React.Fragment>
  );
};

export default VersionsHeader;
