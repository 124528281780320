import React from "react";
import blackArrowSlider from "../../assets/images/icons/blackArrowSlider.svg";
import { Tooltip } from "antd";
import Icon  from "@ant-design/icons";

export const blackArrow = () => {
  return (
    <div className="view-insight-app-arrow" >
      <img src={blackArrowSlider} />
    </div>
  )
};

export const userTriggeredFlow = () => {
  return (
    <h3>User Triggered Flow
      <Tooltip title="Visual timeline of a specific user flow that triggered this discovery" >
        <Icon type="info-circle" style={{ marginLeft: '1rem', color: "#C8C7C7" }} />
      </Tooltip>
    </h3>
  )
};