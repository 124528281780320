import { useContext, useEffect, useState } from 'react';
import  {FlagsContext } from 'react-unleash-flags';

/**
 * A hook to get the value of a flag   https://github.com/fvoordeckers/react-unleash-flags/blob/master/src/hooks/useFlag.ts
 * @param flagName the name of the flag
 */
const useFlag = (flagName) => {
  const [flagValue, setFlagValue] = useState();
  const [isFlagFetched, isFlagFetchedChange] = useState(false);

  const flagsClient = useContext(FlagsContext);


  useEffect(() => {
    if (flagsClient) {

      if (!flagsClient.getFlags().length) {
        // refresh the current flags set if there are no flags yet
        const refresh = async () => {

          await flagsClient.init();

          const refreshedFlag = flagsClient.getFlag(flagName);
          setFlagValue(refreshedFlag);
        };
        refresh();
      } else {
        // return the current flag if available
        const flag = flagsClient.getFlag(flagName);
        setFlagValue(flag);
      }
      isFlagFetchedChange(true);
    }
  }, [flagsClient, flagName]);
  return [flagValue , isFlagFetched];
};

export default useFlag;