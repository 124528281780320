import React, { useEffect, useState } from "react";
import "./styles.scss";
import moment from "moment";
import { Slider } from "antd";
import double_caret_left_ico from "assets/images/icons/context-timeline/double-caret-left.svg";
import double_caret_right_ico from "assets/images/icons/context-timeline/double-caret-right.svg";
import { useContextTimeline } from "pages/ContextTimeline/context";

type Props = {
  startDate: string;
  endDate: string;
};

export const Timeline = ({ endDate, startDate }: Props) => {
  const { eventsList, isSidebarOpen, handleChangeFilter, date, isLoading } =
    useContextTimeline();
  const [scrollMax, setScrollMax] = useState(0);
  const [sliderValue, setSliderValue] = useState(0);

  useEffect(() => {
    const element = document.querySelector(".ctl-cards-list") as HTMLDivElement;

    if (!element) return;

    element.onscroll = () => {
      setSliderValue(element.scrollLeft);
    };

    const maxScrollValue = element.scrollWidth - element.clientWidth;

    setScrollMax(maxScrollValue);
  }, [eventsList, isSidebarOpen]);

  useEffect(() => {
    if (isLoading) return;

    const selectedCard = document.querySelector(
      ".card.selected"
    ) as HTMLDivElement;

    // set slider value to the selected card
    if (selectedCard) {
      const element = document.querySelector(
        ".ctl-cards-list"
      ) as HTMLDivElement;
      const cardRect = selectedCard.getBoundingClientRect();
      const elementRect = element.getBoundingClientRect();

      // Adjust for any scroll position in the container
      const scrollAdjustment = element.scrollLeft;

      // Calculate the new slider value, considering the scroll position
      const newSliderValue =
        cardRect.left - elementRect.left + scrollAdjustment;

      setSliderValue(newSliderValue);
    }
  }, [date, isLoading]);

  const onSliderChange = (value: number) => {
    const element = document.querySelector(".ctl-cards-list") as HTMLDivElement;

    setSliderValue(value);

    if (element) {
      element.scrollLeft = value;
    }
  };

  const onDateChange = (date: string) => {
    handleChangeFilter?.("date", date);
  };

  return (
    <div className="ctl-timeline">
      <img
        src={double_caret_left_ico}
        onClick={() => {
          onDateChange(startDate);
          setSliderValue(0);
        }}
      />
      <div className="ctl-timeline-start-date">
        Active Since: {moment(startDate).format("MMM DD, YYYY")}
      </div>
      <Slider
        style={{ flex: 1 }}
        min={0}
        disabled={scrollMax === 0}
        max={scrollMax}
        value={sliderValue}
        onChange={onSliderChange}
        className="custom-slider"
        tooltipVisible={false}
      />
      <div className="ctl-timeline-end-date">
        Last Activity: {moment(endDate).format("MMM DD, YYYY")}
      </div>
      <img
        src={double_caret_right_ico}
        onClick={() => {
          onDateChange(endDate);
          setSliderValue(scrollMax);
        }}
      />
    </div>
  );
};
