import { gql } from "@apollo/client";

export const GET_MARKETING_APPS_QUERY = gql`
  query GetMarketingApps {
    marketing {
      apps {
        id
        displayName
        icon
        insightsCnt
      }
    }
  }
`;

export const GET_MARKETING_PERSONAS_QUERY = gql`
  query GetMarketingPersonas($appId: Int!) {
    marketing {
      personas(appId: $appId) {
        id
        title
        roles {
          id
          name
        }
        description
      }
    }
  }
`;

export const GET_MARKETING_FOCUS_AREAS_QUERY = gql`
  query GetMarketingFocusAreas($appId: Int!) {
    marketing {
      focusAreas(appId: $appId) {
        id
        name
      }
    }
  }
`;

export const GET_MARKETING_INSIGHTS_QUERY = gql`
  query GetMarketingInsights(
    $appId: Int!
    $offset: Int
    $limit: Int
    $sort: String
    $type: String
    $personaIds: [Int]
    $startTimeGte: Date
    $startTimeLt: Date
    $insightId: Int
    $focusAreaIds: [Int]
    $personaRoleIds: [Int]
  ) {
    marketing {
      insightPage(
        appId: $appId
        personaIds: $personaIds
        personaRoleIds: $personaRoleIds
        startTimeGte: $startTimeGte
        startTimeLt: $startTimeLt
        insightId: $insightId
        focusAreaIds: $focusAreaIds
      ) {
        summary {
          byType {
            type
            count
          }
          totalCount
        }
        insights(offset: $offset, limit: $limit, sort: $sort, type: $type) {
          id
          type
          subType
          startTime
          title
          description
          priority
          thumbnail
          thumbnailOriginal
          focusArea
          labels {
            name
          }
          personas {
            id
            title
            roles {
              id
              name
            }
            description
          }
        }
      }
    }
  }
`;

export const GET_MARKETING_INSIGHT_BY_ID_QUERY = gql`
  query GetMarketingInsightById($insightId: Int!) {
    insight(id: $insightId) @rest(type: "Insight", path: "insight/{args.id}") {
      data {
        id
        title
        description
        type
        platform
        priority
        assets
        labels
        start_time
        thumbnail
      }
    }
  }
`;

export const GET_MARKETING_INSIGHT_PERSONAS_AND_FOCUS_AREA_QUERY = gql`
  query GetMarketingInsights($appId: Int!, $insightId: Int!) {
    marketing {
      insightPage(appId: $appId, insightId: $insightId) {
        insights {
          personas {
            id
            title
            description
            roles {
              id
              name
            }
          }
          focusArea
        }
      }
    }
  }
`;
