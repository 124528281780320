import React, { useState, useEffect, useRef } from 'react'
import { Popover, Button } from "antd"
import moment from "moment"
import { isEmpty } from "lodash";

import FilterTime from '../../dash-discoveries/FilterTime'
import PerformanceSummary from "./PerformanceSummary/PerformanceSummary";
import PerformanceNoData from "./PerformanceSummary/PerformanceNoData";
import PerformanceEventHistoryGraph from "./PerformanceEventHistoryGraph";
import NewLoader from "../../../common/NewLoader/NewLoader";
import StickyHeader from "./StickyHeader/StickyHeader"
import PerfVideoRepr from "./perfVideoRepr"
import PerformanceSubEvents from "./PerformanceSubEvents";
import {
  buildFormattedDevicesList,
  getFormattedEvent,
  getFormattedDevice,
  getFormattedLocation,
  getTimeFrameFilter,
  getSummaryValues,
  restoreEventFromURL,
} from "./comparePageUtils"
import { daysAgoList, ALL_TIME } from "../../../shared/consts"

import PerformanceAppDrawer from "./performanceAppDrawer/performanceAppDrawer"
import empty_state_back from "../../../assets/images/new_design/benchmark/bemch-comp-empty-data.svg"

import "./BenchmarkCompare.scss"

function BenchmarkCompareView({
  match,
  history,
  performanceApps,
  eventsData,
  deviceLocations,
  perfDeviceModels,
  perfInfrastructure,
  isFetchingPerfData,
  getPerformancePageData,
  analytics,
  user
}) {

  const initValues = new URLSearchParams(history.location.search)
  const eventIDs = match.params.eventIDs ? match.params.eventIDs.split(',').map(evID => evID ? Number(evID) : '') : []

  const [currentEvents, currentEventsChange] = useState([0, 1].map(i => restoreEventFromURL(initValues, eventIDs[i], i + 1, performanceApps, perfDeviceModels)))

  const [wasFirstRequest, wasFirstRequestChange] = useState(false)
  const [subEvent, subEventChange] = useState(initValues.get('subEvent') || '')

  const [isOpenTimeFilter, isOpenTimeFilterChange] = useState(false)
  const [daysAgo, daysAgoChange] = useState(initValues.get('days_ago') ? Number(initValues.get('days_ago')) : 30)
  const [customRange, customRangeChange] = useState(initValues.get('customRange') && initValues.get('customRange') === '1' ? true : false)
  const [customRangeTime, customRangeTimeChange] = useState(initValues.get('customRangeTime') ? [moment(initValues.get('customRangeTime').split(",")[0]),
    moment(initValues.get('customRangeTime').split(",")[1])] : [moment(), moment()])

  const stickyHeaderRef = useRef(null)

  useEffect(() => {
    if (!currentEvents.find(ev => ev.data)) {
      return
    }
    let currentEventsCopy = [...currentEvents]
    currentEventsCopy.forEach(ev => {
      ev.key = null
    })
    currentEventsChange(currentEventsCopy)
  }, [customRange, customRangeTime, daysAgo])

  useEffect(() => {
    const data = currentEvents.filter(ev => ev.id && ev.app && ev.location && ev.device && !ev.key).map(curEv => ({
      id: curEv.id,
      allMetrics: true,
      filters: [...getTimeFrameFilter(customRange, customRangeTime, daysAgo)]
        .concat([{ filterType: 'device_model', filterValue: curEv.device.model }])
        .concat([{ filterType: 'os_version', filterValue: curEv.os_version ? curEv.os_version : 'All' }])
        .concat([{ filterType: 'orchestrator_location', filterValue: curEv.location }])
    }))
    if (data.find(ev => ev)) {
      getPerformancePageData(data)
      wasFirstRequestChange(true)
    }
    updateURL()
    sendAnalytics()
  }, [currentEvents])

  useEffect(() => {
    if (eventsData.length === 0) { return }
    let currentEventsCopy = [...currentEvents]
    let timeFilter = getTimeFrameFilter(customRange, customRangeTime, daysAgo).map(f => f.filterValue).join()
    let hasChanged = false
    eventsData.forEach(evData => {
      let updateEvents = currentEventsCopy.filter(e =>
        `${e.id}-${timeFilter},${e.device ? e.device.model : '-'},${e.os_version ? e.os_version : 'All'},${e.location}` === evData.key
      )
      if (updateEvents.length > 0) {
        hasChanged = true
      }
      updateEvents.forEach(updateEvent => {
        updateEvent.data = evData.data
        updateEvent.history = evData.history
        updateEvent.key = evData.key
      })
    })
    if (hasChanged) {
      currentEventsChange(currentEventsCopy)
    }
  }, [eventsData])

  useEffect(() => {
    window.addEventListener("scroll", handlePageScroll, true);
    return () => {
      window.removeEventListener("scroll", handlePageScroll);
    }
  }, [])

  const handleAppChange = (appID, indexEvent) => {

    const app = appID !== null ? performanceApps.find(app => app.id === appID) : null
    let currentEventsCopy = [...currentEvents]
    if (app) {
      currentEventsCopy[indexEvent] = {
        app,
      }
      currentEventsCopy[indexEvent].id = app.performance_events.find(ev => ev.name === 'launch')?.id
      currentEventsCopy[indexEvent] = setDeviceByLocation(currentEventsCopy[indexEvent], perfInfrastructure[0].loc)
    } else {
      currentEventsCopy[indexEvent] = {}
    }
    if (indexEvent !== 0 && currentEventsCopy[indexEvent].app && appID) {
      //MATCHING
      currentEventsCopy[indexEvent].location = currentEventsCopy[0].location
      let origEventName = currentEventsCopy[0].app.performance_events.find(e => e.id === currentEventsCopy[0].id)
      let matchedEvent = currentEventsCopy[indexEvent].app.performance_events.find(e => e.name === origEventName.name)
      if (matchedEvent) {
        clearDataForEvent(currentEventsCopy[indexEvent])
        currentEventsCopy[indexEvent].id = matchedEvent.id
      }
      if (currentEventsCopy[0].device) {
        let compDevicesList = buildFormattedDevicesList(currentEventsCopy[indexEvent].app.device_models, perfInfrastructure, currentEventsCopy[0].location)
        let matchedDevice = compDevicesList.find(cD => cD.value === currentEventsCopy[0].device.model)
        if (matchedDevice) {
          currentEventsCopy[indexEvent].device = perfDeviceModels.find(perfDev => perfDev.model === matchedDevice.value)
          let perfDevices = currentEventsCopy[indexEvent].app.device_models.filter(perfDev => perfDev.model === currentEventsCopy[indexEvent].device.model)
          currentEventsCopy[indexEvent].os_version = (perfDevices.length === 1 ? perfDevices[0].os_version : '')
        }
      }
    }
    currentEventsChange(currentEventsCopy)
  }
  const handleEventChange = (event, i) => {
    let currentEventsCopy = [...currentEvents]
    currentEventsCopy[i].id = event.value
    currentEventsCopy[i] = clearDataForEvent(currentEventsCopy[i])
    if (i === 0 && currentEventsCopy[1].app) {
      let origEventName = currentEventsCopy[i].app.performance_events.find(e => e.id === event.value)
      let matchedEvent = currentEventsCopy[1].app.performance_events.find(e => e.name === origEventName.name)
      if (matchedEvent) {
        clearDataForEvent(currentEventsCopy[1])
        currentEventsCopy[1].id = matchedEvent.id
      }
    }

    currentEventsChange(currentEventsCopy)
  }
  const handleDeviceChange = (device, i) => {
    let currentEventsCopy = [...currentEvents]
    let perfDevices = currentEventsCopy[i].app.device_models.filter(perfDev => perfDev.model === device.value)
    currentEventsCopy[i].device = perfDevices[0]
    currentEventsCopy[i] = clearDataForEvent(currentEventsCopy[i])

    currentEventsCopy[i].os_version = (perfDevices.length === 1 ? perfDevices[0].os_version : '')
    currentEventsChange(currentEventsCopy)
  }
  const handleOSChange = (os_version, i) => {
    let currentEventsCopy = [...currentEvents]
    currentEventsCopy[i].os_version = os_version.value
    currentEventsCopy[i] = clearDataForEvent(currentEventsCopy[i])

    currentEventsChange(currentEventsCopy)
  }
  const handleLocationChange = (location, i) => {
    let currentEventsCopy = [...currentEvents]
    currentEventsCopy[i] = setDeviceByLocation(currentEventsCopy[i], location.value)
    currentEventsCopy[i] = clearDataForEvent(currentEventsCopy[i])
    currentEventsChange(currentEventsCopy)
  }
  const clearDataForEvent = (ev) => {
    ev.key = null
    ev.data = null
    ev.history = null
    return ev
  }
  const setDeviceByLocation = (event, location) => {
    if (!event.app) { return event }
    const devicesList = buildFormattedDevicesList(event.app.device_models, perfInfrastructure, location)

    event.location = location
    if (devicesList.length > 0) {
      event.device = perfDeviceModels.find(perfDev => perfDev.model === devicesList[0].value)
      let perfDevices = event.app.device_models.filter(perfDev => perfDev.model === event.device.model)
      event.os_version = (perfDevices.length === 1 ? perfDevices[0].os_version : '')
    } else {
      event.device = null
      event.os_version = ''
    }
    return event
  }

  const updateURL = () => {
    const toURL = `/benchmark/compare/${currentEvents.map(ev => ev.id).join(',')}`

    let filters = currentEvents.reduce((acc, el, i) => ({
      ...acc,
      [`device_${i + 1}`]: el.device ? el.device.model : '',
      [`os_version_${i + 1}`]: el.os_version || '',
      [`loc_${i + 1}`]: el.location || '',
    }
    ), {})

    const gets = new URLSearchParams({
      days_ago: daysAgo,
      subEvent: subEvent,
      customRange: customRange ? 1 : 0,
      customRangeTime: customRangeTime.map(date => date.format('YYYY-MM-DD')),
      ...filters,
    }).toString()
    history.replace(`${toURL}?${gets}`);
  }

  const sendAnalytics = () => {
    console.log(currentEvents)
    if (currentEvents[0].app === '')
      return
    let mpBody = {
      main_app: currentEvents[0].app.name,
      main_event: currentEvents[0].app.performance_events.find(event => event.id === currentEvents[0].id).title,
      main_device: currentEvents[0].device?.value || currentEvents[0].device?.filterValue,
      main_location: currentEvents[0].location.filterValue,
      main_platform: currentEvents[0].app.platform,
    }
    if (currentEvents[1] && currentEvents[1].id) {

      mpBody.compare_app = currentEvents[1].app.name;
      mpBody.compare_event = currentEvents[1].app.performance_events.find(event => event.id === currentEvents[1].id).title;
      mpBody.compare_device = currentEvents[1].device?.value || currentEvents[0].device?.filterValue;
      mpBody.compare_location = currentEvents[1].location.filterValue;
      mpBody.compare_platform = currentEvents[1].app.platform;
    }

    if (currentEvents[0].id || currentEvents[1].id) {
      analytics.analytic('Performance page', mpBody)
    }
  }

  const handlePageScroll = e => {
    if (stickyHeaderRef && stickyHeaderRef.current) {
      let arr = stickyHeaderRef.current.className.split(" ");
      const vis = "visible";

      if (window.pageYOffset >= 455) {
        // show sticky header
        if (arr.indexOf(vis) === -1) {
          stickyHeaderRef.current.className += " " + vis;
        }
      } else {
        if (arr.indexOf(vis) !== -1) {
          arr.splice(arr.indexOf(vis));
          stickyHeaderRef.current.className = arr.join(" ");
        }
      }
    }
  };

  const handleClickAnalytics = (ev) => {
    analytics.analytic('Explore event metrics', {
      'App': ev.app.name,
      'Platform': ev.app.platform,
      'Date range': daysAgo,
      'Custom dates': customRange ? customRangeTime : '',
      'Device': ev.device.product_name,
      'OS version': ev.os_version,
      'Event': ev.app.performance_events.find(pE => pE.id === ev.id).title
    })
  }

  const appsSummaryValues = currentEvents.map(ev => ev ? getSummaryValues(
    ev.data &&
    ev.data["average_subevents"] &&
    ev.data["average_subevents"].find(elem => elem.name === 'end_time')) : {}
  );

  const timeLinePercMax = Math.max.apply(Math, appsSummaryValues.map(val => val.duration))

  return (
    <div className="performance ">
      <h1 className="h1-title">
        <div className="header">
          Benchmark <div className="medium">Compare</div>
        </div>

      </h1>
      <div className="hr-row" />
      <div className="benchmark__sub-header">
        <span className="benchmark-select-label">Showing data from</span>
        <Popover placement="bottomRight" title={null}
          visible={isOpenTimeFilter}
          onVisibleChange={() => isOpenTimeFilterChange(!isOpenTimeFilter)}
          content={
            <FilterTime
              isOpen={isOpenTimeFilter}
              daysAgoList={daysAgoList}
              daysAgo={daysAgo}
              daysAgoChange={daysAgoChange}
              customRange={customRange}
              сustomRangeСhange={customRangeChange}
              customRangeTime={customRangeTime}
              customRangeTimeChange={customRangeTimeChange}
              oneDay={false}
            />
          } trigger="click" overlayClassName={`filter-wrap all-discoveries all-discoveries-time`}
          overlayStyle={{ width: (customRange ? '75rem' : '18rem') }}>
          <Button className="filter-btn-new">
            {customRange ? 'Custom Range' : daysAgoList.find(d => d.value === daysAgo).title}
          </Button>
        </Popover>
      </div>

      <div className="performance-app-drawers">
        {[0, 1].map((i) => {
          const ev = currentEvents[i]
          return <PerformanceAppDrawer
            page="performance_compare"
            isPerformance={true}
            appsList={performanceApps || []}
            perfInfrastructure={perfInfrastructure}
            isMainApp={i === 0}
            selectedApp={ev.app ? performanceApps.find(app => app.id === ev.app.id) : null}
            appChange={appID => handleAppChange(appID, i)}
            handleEventChange={(event) => handleEventChange(event, i)}
            handleDeviceChange={(device) => handleDeviceChange(device, i)}
            handleOSChange={(os_version) => handleOSChange(os_version, i)}
            handleLocationChange={(loc) => handleLocationChange(loc, i)}
            event={ev}
            deviceLocations={deviceLocations}
            index={i}
            disabled={i !== 0 && !currentEvents[0].app}
            isMatchedEvent={i !== 0 && ev.app && currentEvents[0].app &&
              (getFormattedEvent(currentEvents[0].app.performance_events, currentEvents[0].id)?.label === getFormattedEvent(ev.app.performance_events, ev.id)?.label)}
            key={i}
          />
        }
        )}
      </div>
      {!currentEvents.find(ev => ev.data) && !wasFirstRequest && <div className='bench-compare-empty-state'>
        <img src={empty_state_back} className="empty-state-back" />
        <div className='empty-state-text'>
          <h3>Start an analysis!</h3>
          <div>Select an app above to explore graph analysis and other analytics tools</div>
        </div>
      </div>}

      {isFetchingPerfData && <div className="bench-compare-loader">
        <NewLoader />
      </div>}

      <div className="sticky-header-wrapper"
        ref={stickyHeaderRef}
        onClick={() => {
          document.body.scrollTop = 0; // For Safari
          document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }}>
        <div className="sticky-header-cont">
          {currentEvents.map((ev, i) =>
            <StickyHeader
              visible={ev.app}
              appIcon={ev.app ? ev.app.icon : ''}
              appName={ev.app ? ev.app.name : ''}
              eventName={ev.id ? getFormattedEvent(ev.app.performance_events, ev.id).label : ''}
              selectedDevice={ev.device && ev.app ?
                `${getFormattedDevice(ev.app.device_models, ev.device).label} (${ev.device.platform}) `
                : ''}
              selectedOS={ev.os_version}
              selectedLocation={ev.location ? getFormattedLocation(ev.location).label : ''}
              key={i}
            />)}

        </div>
      </div>

      {/* DATA 1 Event Metrics */}
      {currentEvents.find(ev => ev.data) && (
        <div className="performance__summary">
          <h3 className="title">Event Metrics</h3>
          <div className="extra-text">
            Compare averages across apps. Refreshes daily
          </div>
          <div className="performance__summary-content">
            {currentEvents.map((ev, i) =>
              !isEmpty(ev.data) && ev.data.average_subevents ? (
                <PerformanceSummary
                  key={i}
                  isLoading={appsSummaryValues[i].isLoading}
                  duration={{
                    value: appsSummaryValues[i].duration,
                    //NEED FIX
                    underline: true
                  }}
                  isWinner={!appsSummaryValues.find(sum => sum.duration < appsSummaryValues[i].duration)}
                  timeLinePerc={appsSummaryValues[i].duration / timeLinePercMax * 100}
                  order={i + 1}
                  linksParams={{
                    id: ev.id,
                    customRange,
                    daysAgo,
                    customRangeTime,
                    device_1: ev.device.model,
                    os_version_1: ev.os_version,
                    loc_1: ev.location,
                    backURL: encodeURIComponent(window.location.pathname + window.location.search)
                  }}
                  dataForCompare={ev.history && !(!customRange && daysAgo === ALL_TIME) ? ['duration_s', 'memory_bytes', 'network_rx_bytes', 'network_tx_bytes'].reduce((acc, m) => {
                    acc[m] = ev.history[m].find(p => p.subeventName === 'end_time').value
                    return acc
                  }, {}) : {}}
                  user={user}
                  analytics={analytics}
                  handleClickAnalytics={() => handleClickAnalytics(ev)}
                  comparePeriodText={`vs. previous ${customRange ? `${moment(customRangeTime[1]).diff(moment(customRangeTime[0]), 'days')} d.` : `${daysAgo} d.`}`}
                  aggregatedStats={appsSummaryValues[i].stats.map(
                    (stat, index) => {
                      return {
                        title: stat.title,
                        value: {
                          number: stat.value.number,
                          suffix: stat.value.suffix
                        },
                        rawValue: stat.rawValue,
                        name: stat.name,
                        info: stat.info,
                        underline: true
                      };
                    }
                  )}
                />
              ) :
                <PerformanceNoData
                  key={i} />
            )}
          </div>
        </div>
      )}

      {/* DATA 3 Sub Events */}
      {!isFetchingPerfData &&
        <div className="sub-events-wrapper">
          <PerformanceSubEvents
            performanceApps={performanceApps}
            mainEventMetadata={currentEvents[0].app}
            compareEventMetadata={currentEvents[1] && currentEvents[1].app}
            mainEventStartOffset={
              currentEvents[0].data &&
              currentEvents[0].data.representative_sample &&
              currentEvents[0].data.representative_sample
                .event_start_offset_s
            }
            compareEventStartOffset={
              currentEvents[1] &&
              currentEvents[1].data &&
              currentEvents[1].data.representative_sample &&
              currentEvents[1].data.representative_sample
                .event_start_offset_s
            }
            mainTimeLinePerc={appsSummaryValues[0].duration / timeLinePercMax * 100}
            comprTimeLinePerc={appsSummaryValues[1] ? appsSummaryValues[1].duration / timeLinePercMax * 100 : 0}
            mainEventSubevents={
              currentEvents[0].data &&
              currentEvents[0].data.average_subevents
            }
            compareEventSubevents={
              currentEvents[1] &&
              currentEvents[1].data &&
              currentEvents[1].data.average_subevents
            }
          />
        </div>
      }

      {/* DATA 3 Video */}

      {!isFetchingPerfData &&
        <div className="video-journey-wrapper-new">
          {currentEvents.some(ev => ev.data && ev.data.representative_sample) &&
            <PerfVideoRepr
              selectedEventSubevents={{
                main: currentEvents[0].app.performance_events.find(appEv => appEv.id === currentEvents[0].id).subEvents,
                compare:
                  currentEvents[1] && currentEvents[1].app &&
                  currentEvents[1].app.performance_events.find(appEv => appEv.id === currentEvents[1].id).subEvents,
              }}
              isCompareMode={currentEvents[1].data}
              mainEvData={currentEvents[0].data}
              mainEvApp={currentEvents[0].app}
              compEvData={currentEvents[1] && currentEvents[1].data}
              compEvApp={currentEvents[1] && currentEvents[1].app}
            />}

        </div>
      }

      {/* DATA 4 History */}
      {!isFetchingPerfData &&
        currentEvents.find(ev => ev.history && ev.history.duration_s && ev.history.duration_s.length > 0) &&
        <div className='main-page-chart'>
          <div className="history-graph-wrapper">
            <PerformanceEventHistoryGraph
              isCompareMode={true}
              mainEventMetadata={currentEvents[0].app}
              compareEventMetadata={currentEvents[1] && currentEvents[1].app}
              mainEventHistoryData={currentEvents[0].history && currentEvents[0]?.history?.duration_s?.map(r => ({ ...r, value: r.value * 1000 })) || []}
              compareEventHistoryData={currentEvents[1] && currentEvents[1]?.history?.duration_s && currentEvents[1].history.duration_s.map(r => ({ ...r, value: r.value * 1000 })) || []}
              selectedSubEvent={subEvent}
              handleChangeEventGraph={(val) => {
                subEventChange(val);
                updateURL();
              }}
              selectedDaysAgoValue={daysAgo}
              showDates={true}
              showVerticalGridLines={true}
              centeredPoints={true}
            />
          </div>
        </div>

      }

    </div>
  )
}

export default BenchmarkCompareView