import * as aT from "./_actionsTypes";

export const getAllAppsVersionInfo = (noLoader = false) => ({
  type: aT.GET_ALL_RELEVANT_DISCOVERIES,
  noLoader: noLoader
});

export const removeReadRelevantInsights = () => ({
  type: aT.REMOVE_RELEVANT_READ_INSIGHTS,
})

export const getUnseenCriticalInsights = () => ({
  type: aT.GET_UNSEEN_CRITICAL_INSIGHTS
})

export const getUnseenCriticalInsightsSuccess = data => ({
  type: aT.GET_UNSEEN_CRITICAL_INSIGHTS_SUCCESS,
  payload: {
    data
  }
});

export const setCampaignDemoMode = data => ({
  type: aT.SET_CAMPAIGN_DEMO_MODE,
  payload: {
    data
  }
});

export const getDiscoveries = (data) => ({
  type: aT.GET_DISCOVERIES,
  payload: {
    gets: data.gets
  }
})

export const getDiscoveriesSuccess = data => ({
  type: aT.GET_DISCOVERIES_SUCCESS,
  payload: data
});

export const getDiscoveriesSearch = (data) => ({
  type: aT.GET_DISCOVERIES_SEARCH,
  payload: {
    gets: data.gets
  }
})

export const getDiscoveriesSearchSuccess = data => ({
  type: aT.GET_DISCOVERIES_SEARCH_SUCCESS,
  payload: data
});

export const getProducts = () => ({
  type: aT.GET_PRODUCTS,
})

export const getProductsSuccess = data => ({
  type: aT.GET_PRODUCTS_SUCCESS,
  payload: data
});

export const getProductsStats = data => ({
  type: aT.GET_PRODUCTS_STATS,
  payload: data
});

export const getProductsStatsSuccess = data => ({
  type: aT.GET_PRODUCTS_STATS_SUCCESS,
  payload: data
});

export const setAllDiscoveriesForProductAsSeen = data => ({
  type: aT.SET_ALL_DISCOVERIES_AS_SEEN,
  payload: data
});

export const setAllDiscoveriesForProductAsSeenSuccess = data => ({
  type: aT.SET_ALL_DISCOVERIES_AS_SEEN_SUCCESS,
  payload: data
});
