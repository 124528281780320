import { createSelector } from "reselect";
import moment from "moment";

const analyzedVersions = ({ newOverviewReducer }) => {
  const groupedVersions = [];
  if (newOverviewReducer.currentlyBeingAnalyzedVersions) {
    newOverviewReducer.currentlyBeingAnalyzedVersions.forEach(fv => {
      const parent = groupedVersions.find(gv => gv.app_id === fv.app_id);
      if (parent) {
        parent.childVersions.push(fv)
      } else {
        fv.childVersions = []
        groupedVersions.push(fv)
      }
    })
    return groupedVersions
  }
  return null
};

const versions = ({ newOverviewReducer }) => {
  const filteredVersionsNames = []
  let filteredVersions

  if (newOverviewReducer.completedVersionsWithDiscoveries) {

    filteredVersions = newOverviewReducer.completedVersionsWithDiscoveries.filter(ver => {
      if (ver.is_customer_own_app) {
        if (filteredVersionsNames.includes(ver.release_name)) {
          return false
        } else {
          filteredVersionsNames.push(ver.release_name)
          return true
        }
      }
      return true
    })
  }

  if (filteredVersions) {
    return [...filteredVersions ? filteredVersions.filter(
      el => el.is_customer_own_app || (!el.is_customer_own_app && el.state === 'completed' && el.is_visible)
    ).map(el => {
      return {
        ...el,
        x: moment(el.release_date).format("DD/MM"),
        y: el.insight_cnt,
        r: 10,
      };
    }) : null
    ]
  } else {
    return null
  }

}




const apps = ({ appData }) => appData.apps
const ownApps = ({ appData }) => appData.ownApps

export const analyzedVersionsSelector = createSelector(
  analyzedVersions,
  versions,
  apps,
  ownApps,
  (r, r1, r3, r4) => ({ analyzedVersions: r, versions: r1, apps: r3, ownApps: r4 })
);
