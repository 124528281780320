import {
  takeLatest,
  call,
  put,
  fork,
} from "redux-saga/effects";
import * as productApi from "../api/productApi";
import * as overviewApi from "../api/overviewApi";
import * as aT from "../actions/_actionsTypes";

import {
  getSignificantUnseenDiscoveriesSuccess,
  getDiscoveriesSuccess,
  getTrackedSinceSuccess,
  setDiscoveryRateSuccess
} from '../actions/productActions'

import { 
  getDiscoveriesByProductSuccess, 
  getABTestsSuccess } from "../acts/product_intel"

import {
  getTopLabelsSuccess
} from "../acts/labels"

function* getSignificantUnseenDiscoveries(action) {
  const result = yield call(productApi.getSignificantUnseenDiscoveries, action.payload)
  yield put(getSignificantUnseenDiscoveriesSuccess(result.data))
}

function* getDiscoveriesRequest(action) {
  const {
    gets
  } = action.payload;
  const result = yield call(overviewApi.getDiscoveries, gets)

  yield put(getDiscoveriesSuccess(result.data))
}

function* getTrackedSince(action) {
  const result = yield call(productApi.getTrackedSince, action.payload)
  yield put(getTrackedSinceSuccess(result.data))
}

function* setDiscoveryRateSaga(action) {
  const result = yield call(productApi.setDiscoveryRateAPI, action.payload.insight_id, action.payload.rate)
  yield put(setDiscoveryRateSuccess(result.data))
}

function* getDiscoveriesByProductSaga(action) {
  const result = yield call(productApi.getDiscoveryByProductAPI, action.payload)
  yield put(getDiscoveriesByProductSuccess({
    product_id: action.payload,
    data: result.data
  }))
}

function* getABTestsSaga(action) {
  const result = yield call(productApi.getABTestsAPI, action.payload)
  yield put(getABTestsSuccess({
    data: result.data
  }))
}

function* getTopLabelsSaga(action) {
  const result = yield call(productApi.getTopLabelsAPI, action.payload)
  yield put(getTopLabelsSuccess({
    data: result.data.data
  }))
}

//watchers
function* watchGetSignificantUnseenDiscoveries() {
  yield takeLatest(aT.GET_SIGNIFICANT_UNSEEN_DISCOVERIES, getSignificantUnseenDiscoveries);
}

function* watchGetDiscoveries() {
  yield takeLatest(aT.GET_PRODUCT_DISCOVERIES, getDiscoveriesRequest);
}

function* watchGetTrackedSince() {
  yield takeLatest(aT.GET_TRACKED_SINCE, getTrackedSince);
}

function* watchSetDiscoveryRate() {
  yield takeLatest(aT.SET_DISCOVERY_RATE, setDiscoveryRateSaga);
}

function* watchGetDiscoveriesByProduct() {
  yield takeLatest(aT.GET_DISCOVERIES_BY_PRODUCTS, getDiscoveriesByProductSaga);
}
function* watchABTests() {
  yield takeLatest(aT.GET_AB_TESTS, getABTestsSaga);
}

function* watchGetTopLabels() {
  yield takeLatest(aT.GET_TOP_LABELS, getTopLabelsSaga);
}

const productSagas = [
  fork(watchGetSignificantUnseenDiscoveries),
  fork(watchGetDiscoveries),
  fork(watchGetTrackedSince),
  fork(watchSetDiscoveryRate),
  fork(watchGetDiscoveriesByProduct),
  fork(watchABTests),
  fork(watchGetTopLabels)
];

export default productSagas;
