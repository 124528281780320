import { intersection } from "lodash";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

type Props = {
  onIntersect: () => void;
};

export const useIntersection = ({ onIntersect }: Props) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      onIntersect();
    }
  }, [inView]);

  return { intersectionRef: ref };
};
