import React, { Component } from 'react';
import { Modal } from 'antd';
import 'antd/lib/modal/style/css';
import 'antd/lib/button/style/css';

class viewHeaderChatModal extends Component {

    handleSubmit = (event) => {
      this.props.submitQuestion();
      event.preventDefault();
      this.props.closeModal();
    }

    render() {
      const { closeModal, modalShow, question, email } = this.props;
      return (
        <Modal
          title="Have a Question?"
          open={modalShow}
          onOk={closeModal}
          onCancel={closeModal}
          wrapClassName='view__chat__modal'
          footer={null}
        >
          <div className='view__chat__modal__content'>
            <p className='view__chat__modal__text'>
                        If you have any questions or suggestions regarding this insight, please let us know and we will be happy to assist.
            </p>
            <form className='view__chat__modal__form' onSubmit={this.handleSubmit}>
              <div className='view__chat__modal__form-group'>
                <label className='view__chat__modal__form-label' htmlFor="question">Question</label>
                <textarea
                  className='view__chat__modal__form-textarea'
                  name="question"
                  id="question"
                  value={question}
                  onChange={this.props.handleChange}
                  required
                />
              </div>
              <div className='view__chat__modal__form-group'>
                <label className='view__chat__modal__form-label' htmlFor="email">Your Email</label>
                <input
                  className='view__chat__modal__form-input'
                  type="email"
                  value={email}
                  name='email'
                  id='email'
                  onChange={this.props.handleChange}
                  required
                />
              </div>
              <div className='view__chat__modal__form-button'>
                <button className='ant-btn'>Send</button>
              </div>
            </form>
          </div>
        </Modal>
      );
    }
}

export default viewHeaderChatModal;