import React from "react";
import { withRouter } from "react-router-dom";
import { discoveryTypesHeaders, typeInfo } from "../../../../shared/consts";

import Info from "../../../../assets/images/icons/info.svg";
import { Popover } from "antd";

const TypeToIcoMap = {
  "screen_changes":"ui-changes",
  "ab_tests":"ab-tests",
  "indications":"indications",
  "new_features":"new-features",
  "removed_features": "remove-features"
}


const VersionsListHeader = ({ type, data, count }) => {
  const label = discoveryTypesHeaders[type]
  return (
    <div className="versions__list__header">
      <div className="versions__list__header__primary">
        <img
          src={require(`../../../../assets/images/icons/${TypeToIcoMap[type]}.svg`)}
          alt=""
          className="versions__list__header-img"
        />
        <h3 className="heading versions__list__header-heading">{label} <span className="dis-count">({count})</span></h3>
        <Popover
          content={typeInfo[data[0].type]}
          overlayStyle={{ maxWidth: 500 }}
          overlayClassName="antd-popover-black"
          placement='right'
        >
          <img alt="type-info" src={Info} />
        </Popover>
      </div>
    </div>
  );
};

export default withRouter(VersionsListHeader);
