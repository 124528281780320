import React from "react";
import { Img } from "react-image";
import WatchfulThumbnail from "../../assets/images/icons/default_thumbnail.png";

export default function SupportButton({ supportIcon, supportLink = "mailto:support@watchul.ai" }) {
  return (
    <div
      className="support-slack-button"
      onClick={() => {
        window.open(supportLink, "_blank");
      }}
    >
      <Img src={[supportIcon, WatchfulThumbnail]} alt="slack-logo" />
      <div>
        <span>Questions?</span>
      </div>
    </div>
  );
}
