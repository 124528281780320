import React, { useEffect, useState } from "react";
import { Entry, MultiSelect } from "../common/MultiSelect/MultiSelect";
import "./CampaignPerUserType.scss";
import {
  App,
  ChartValuesToggle,
} from "containers/MarketingOverview/utils/types";
import {
  calculateExactPercentages,
  isMobileDevice,
} from "containers/MarketingOverview/utils/helpers";
import { trackMarketingOverviewEvent } from "containers/MarketingOverview/utils/analytics";
import { Tooltip } from "antd";

type ByPersona = {
  id: number;
  name: string;
  count: number;
  color: string;
  textColor: string;

  ids: number[];
};

type AppsByPersonas = {
  app: App;
  byPersona: ByPersona[];
};

type Persona = {
  id: number;
  name: string;
  color: string;
};

type Props = {
  userTypes: Persona[];
  appsByPersonas: AppsByPersonas[];
  chartValues: ChartValuesToggle;
};

const INITIAL_USER_TYPES = 5;

export const CampaignPerUserType = ({
  userTypes,
  appsByPersonas,
  chartValues,
}: Props) => {
  const [currentAppsByPersonas, setCurrentAppsByPersonas] = useState<
    AppsByPersonas[]
  >([]);
  const isMobile = isMobileDevice();

  useEffect(() => {
    const initialUserTypes = userTypes
      .slice(0, INITIAL_USER_TYPES)
      .map((userType) => ({
        ...userType,
        isSelected: true,
      }));

    handleMultiSelectChange(initialUserTypes);
  }, [appsByPersonas]);

  const handleMultiSelectChange = (entries: Entry[]) => {
    const selectedPersonas = entries
      .filter((entry) => entry.isSelected)
      .reduce<number[]>((acc, curr) => [...acc, ...(curr.ids || [])], []);

    // analytics for selection
    entries.forEach((entry) => {
      const isEntryAlreadySelected = currentAppsByPersonas.some(
        (currentFocusArea) =>
          currentFocusArea.byPersona.some(({ id }) => id === entry.id)
      );

      if (entry.isSelected === isEntryAlreadySelected) {
        return;
      }

      const appsWithPersona = appsByPersonas.filter((appInsightsByFocusArea) =>
        appInsightsByFocusArea.byPersona.find(({ id }) => id === entry.id)
      );

      trackMarketingOverviewEvent("chart_category_clicked", {
        app_names: appsWithPersona.map(({ app }) => app?.displayName),
        chart_name: "Marketing Campaigns Per User Type",
        category_name: entry.name,
        is_selected: !!entry.isSelected,
      });
    });

    const newAppsByPersonas = appsByPersonas.map((appsByPersona) => ({
      ...appsByPersona,
      byPersona: appsByPersona.byPersona.filter((byPersona) =>
        selectedPersonas.includes(byPersona.id)
      ),
    }));

    setCurrentAppsByPersonas(newAppsByPersonas);
  };

  const renderCPUTChartLine = (appInsightsByPersona: ByPersona[]) => {
    const totalInsights = appInsightsByPersona.reduce(
      (acc, curr) => acc + curr.count,
      0
    );

    const counts = appInsightsByPersona.map((byPersona) => byPersona.count);
    const exactPercentages = calculateExactPercentages(counts);

    return (
      <>
        {appInsightsByPersona.map((byPersona, idx) => {
          const tooltipValues =
            chartValues === "numbers"
              ? `${exactPercentages[idx]}%`
              : byPersona.count;

          return (
            exactPercentages[idx] > 0 && (
              <Tooltip
                overlayClassName="cput-line-persona-tooltip"
                title={`${byPersona.name}: ${tooltipValues}`}
                overlayInnerStyle={
                  {
                    "--persona-color": byPersona.color,
                  } as any
                }
              >
                <div
                  className="cput-line-persona"
                  style={{
                    color: byPersona.textColor,
                    backgroundColor: byPersona.color,
                    width: `${Math.max(
                      (byPersona.count / totalInsights) * 100,
                      isMobile ? 6 : 4
                    )}%`,
                  }}
                  key={idx}
                >
                  {chartValues === "numbers"
                    ? byPersona.count
                    : `${exactPercentages[idx]}%`}
                </div>
              </Tooltip>
            )
          );
        })}
      </>
    );
  };

  return (
    <div className="cput-contianer">
      <MultiSelect
        selectTitle="User Types"
        entries={userTypes}
        onSelectionChange={handleMultiSelectChange}
      />
      <div className="cput-chart">
        {currentAppsByPersonas.map(
          (appInsightsByPersona) =>
            appInsightsByPersona.byPersona.length > 0 && (
              <div
                className="cput-app-container"
                key={appInsightsByPersona.app?.id}
              >
                <img
                  className="cput-app-thumbnail"
                  src={appInsightsByPersona.app?.icon}
                />
                <div className="cput-line">
                  {renderCPUTChartLine(appInsightsByPersona.byPersona)}
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
};
