import {
  takeLatest,
  call,
  put,
  fork, takeEvery,
} from "redux-saga/effects";
import * as api from "../api/graphApi";
import { getGraphsByAppSuccess, getGraphByIDSuccess } from '../acts/graphs'

import * as aT from "../actions/_actionsTypes";


function* getGraphsByAppSagas(action: object) {
  // @ts-ignore
  const result = yield call(api.getGraphsByAppAPI, action.payload)
  // @ts-ignore
  yield put(getGraphsByAppSuccess({ data: result.data, app_id: action.payload }))
}

function* getGraphByIDSagas(action: object) {
  // @ts-ignore
  const result = yield call(api.getGraphByIDApi, action.payload)
  // @ts-ignore
  yield put(getGraphByIDSuccess({ data: result.data, graph_id: action.payload }))
}


function* watchGraphsByApp() {
  yield takeEvery(aT.GET_GRAPHS_BY_APP, getGraphsByAppSagas);
}

function* watchGraphByID() {
  yield takeEvery(aT.GET_GRAPH_BY_ID, getGraphByIDSagas);
}

const graphSagas = [
  fork(watchGraphsByApp),
  fork(watchGraphByID)
];

export default graphSagas;

