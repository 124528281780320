import React from "react";
import {  Button } from "antd";

export const SuccessPasswordView = ({ closeAndLogin }) => {
  return (
    <div className="success-password-view">
      <div className="success-password-view__text">
        <img
          src="/images/login/success.svg"
          alt="success"
          width="17"
          height="17"
        />
        <span>
          You have <span className="bold">successfully</span> reset your
          password.
        </span>
      </div>
      <Button block type="primary" onClick={closeAndLogin}>
        Continue To Platform
      </Button>
    </div>
  );
};
