export const INTEL_INSIGHT_TYPES = {
  ab_test: "ab_test",
  indication: "indication",
  screen_change: "screen_change",
  new_feature: "new_feature",
  removed_feature: "removed_feature",
  //   inapp_campaign: "inapp_campaign",
  //   email_campaign: "email_campaign",
};

export const ITEMS_PER_PAGE = 9;

export const INTEL_INSIGHT_TYPES_SELECT = {
  ab_test: "ab_test",
  features: "features",
  screen_change: "screen_change",
  indication: "indication",
};

export const AB_TEST_STATUSES = {
  integrated: "integrated",
  active: "active",
  abandoned: "abandoned",
};

export const INTEL_INSIGHT_TYPES_PROPS = {
  [INTEL_INSIGHT_TYPES.ab_test]: {
    label: "AB Test",
    bgColor: "#FFE7D1",
    textColor: "#B26203",
  },
  [INTEL_INSIGHT_TYPES.indication]: {
    label: "Future Prediction",
    bgColor: "#F4E8FF",
    textColor: "#6C2C91",
  },
  [INTEL_INSIGHT_TYPES.screen_change]: {
    label: "UI Change",
    bgColor: "#D1EFFF",
    textColor: "#0E639C",
  },
  [INTEL_INSIGHT_TYPES.new_feature]: {
    label: "New Feature",
    bgColor: "#D1FFE7",
    textColor: "#0E8C5D",
  },
  [INTEL_INSIGHT_TYPES.removed_feature]: {
    label: "Removed Feature",
    bgColor: "#FFE1D1",
    textColor: "#B26203",
  },
};

export const PLATFORM = {
  ios: "ios",
  android: "android",
  web: "web",
};

export const EXPERIMENTS_SELECT = {
  active: "active",
  integrated: "integrated",
  abandoned: "abandoned",
};

export const FEATURES_SELECT = {
  new_feature: "new_feature",
  removed_feature: "removed_feature",
};
