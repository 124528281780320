import React from 'react'
import { Link } from 'react-router-dom'
import { Tooltip } from 'antd'

import VersionsEmpty from "../products-discoveries/versionsEmpty/versionsEmpty"


const COLORS = [
  '#FF2775',
  '#E127FF',
  '#5F27FF',
  '#2763FF',
  '#27D8FF',
  '#57ACF8',
  '#27FF7D',
  '#ADFF27',
  '#FF8227',
  '#FF4127',
  '#E8F0F9',
  '#252C38',
  '#FFE600',
  '#FFA800',
  'aqua',
  'aquamarine',
  'cadetblue',
  'darkgoldenrod'
]

export default function TopLabelsBar({
  products,
  topLabels,

  daysAgo,
  customRange,
  customRangeTime,

  currentProducts
}) {

  if (!topLabels[0]) {
    return <VersionsEmpty
      campaignMode={false}
      text1={'No data found'}
      text2={'Please select a different platform, product or date'}
      hideTip={true}
    />
  }

  const sortedListProducts = topLabels.map(label => label.products).flat().reduce((acc, pr) => {
    let prAcc = acc.find(el => el.id === pr.id)
    if (prAcc) {
      prAcc.insights_cnt += pr.insights_cnt
    } else {
      acc.push({
        id: pr.id,
        insights_cnt: pr.insights_cnt,
        product_name: products.find(p => p.id === pr.id).name,
        icon: products.find(p => p.id === pr.id).thumbnail
      })
    }
    return acc
  }, []).sort((p1, p2) => p1.insights_cnt > p2.insights_cnt ? -1 : 1).map((pr, i) => ({ ...pr, color: COLORS[i] }))

  if (currentProducts.length > 0) {
    currentProducts.forEach(cPID => {
      if (!sortedListProducts.find(sP => sP.id === cPID)) {
        sortedListProducts.push({
          id: cPID,
          insights_cnt: 0,
          product_name: products.find(p => p.id === cPID).name,
          icon: products.find(p => p.id === cPID).thumbnail,
          color: COLORS[sortedListProducts.length]
        })
      }
    })
  }

  const maxLengthLabel = topLabels.map(l => l.name).reduce((acc, el) => el.length > acc ? el.length : acc, 0)

  const disLenghPer = (100 / topLabels[0].insights_cnt).toFixed(5)

  return (
    <div className='top-labels-wrap'>
      <h3 className='sub-header'> Top 5 Common Tags</h3>
      {topLabels.map((label, i) => <div className='bar-label-line'>
        <span className='bar-label-line-num'>#{i + 1}</span>
        <h3 className='bar-label-line-name' style={{ width: `${maxLengthLabel * 1.1}rem` }}>
          <div className='tags__tags-tag-element'>
            {label.name}
          </div>
        </h3>
        <div className='bar-label-line-products'>
          <div className='bar-label-line-cont' >
            {label.products.slice(0, 9).map(pr => <div
              className='bar-product-part'
              style={{
                width: `${disLenghPer * pr.insights_cnt}%`,
                backgroundColor: sortedListProducts.find(p => p.id === pr.id).color
              }}
            >
              <Tooltip overlayClassName={'bar-pr-tooltip'} title={<div className='bar-pr-tooltip-wrap'>
                <div className='bar-pr-tooltip-name'>{sortedListProducts.find(sr => sr.id === pr.id).product_name}</div>
                <div className='bar-pr-tooltip-count'>{pr.insights_cnt}</div>
              </div>
              }>
                <Link to={`/all-discoveries?daysAgo=${daysAgo}&currentPageSize=40&customRange=${customRange ? 1 : ''}&filterType=ab_test%2Cindication%2Cscreen_change%2Cnew_feature%2Cremoved_feature&filterEmailSubtype=&filterInAppSubtype=&filterPushNotificationSubtype=&product_ids=${pr.id}&order_by_start_time=desc&currentPage=1&customRangeTime=${customRangeTime.map(date => date.format('YYYY-MM-DD'))}&label_ids_any=${label.id}`} >
                  <div className='dummy-tooltip' />
                </Link></Tooltip>
            </div>)}

            <div
              className='bar-product-part other-products'
              style={{
                width: `${(label.insights_cnt - label.products.reduce((acc, el) => acc + el.insights_cnt, 0)) * disLenghPer}%`,
                backgroundColor: 'silver'
              }}
            >
              <Tooltip overlayClassName={'bar-pr-tooltip'}
                title={<div className='bar-pr-tooltip-wrap'>
                  <div className='bar-pr-tooltip-name'>
                    Other products
                  </div>
                  <div className='bar-pr-tooltip-count'>{label.insights_cnt - label.products.reduce((acc, el) => acc + el.insights_cnt, 0)}</div>
                </div>}><div className='dummy-tooltip' /></Tooltip>
            </div>

          </div>
        </div>
        <div className='bar-label-line-total'>{label.insights_cnt}</div>

      </div >)
      }
      <div className='bar-label-line' >
        <span className='bar-label-line-num'> </span>
        <h3 className='bar-label-line-name' style={{ width: `${maxLengthLabel * 1.1}rem` }}>
        </h3>
        <div className='bar-label-line-products'>
          <div className='top-labels-legend'>
            {sortedListProducts.map(sp => <div className='top-labels-legend-part'>
              <Tooltip title={sp.product_name} overlayClassName={'bar-pr-tooltip'}>
                <div className='top-labels-legend-part-color' style={{
                  backgroundColor: sp.color
                }}></div>
                <div className='top-labels-legend-part-icon'>
                  <img src={sp.icon} />
                </div>
              </Tooltip>
            </div>)}
          </div>
        </div>
        <div className='bar-label-line-total'> </div>
      </div>
    </div >
  )
}
