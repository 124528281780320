import React, { useState, useEffect, FC, ReactElement } from 'react';
import ChartContainer from "../../../../components/chartContainer/ChartContainer"
import { IDisWithApp, IDataPoint } from '../../../../interfaces/Product';
import moment from "moment";
import CSelectBox from "../../../../components/UICarbonStyleControls/SelectBox"
import WLineChart from "../../../../components/charts/LineChart/WLineChart";
import { customTooltip1, customLabel1 } from "../../../../components/charts/commonSettings"
import "./UserSegment.scss"

interface UserSegmentProps {
  discoveries: IDisWithApp[],
  timeFrame: number,
}

const getOptions = (tooltipGroups: Array<string>, timeFrame: number): object => {


  return {
    axes: {
      left: {
        title: "Discoveries Found"
      },
      bottom: {
        ticks: {
          formatter: customLabel1(timeFrame)
        }
      }
    },
    height: '35rem',
    tooltip: {
      customHTML: customTooltip1(tooltipGroups, timeFrame)
    },

  };
};

const UserSegmentChart: FC<UserSegmentProps> = ({
  discoveries,
  timeFrame,

}): ReactElement | null => {

  const setOfSegments = Array.from(new Set(discoveries.map(d => d.cam.persona_segments).flat()))

  const [curSegment, curSegmentChange] = useState(setOfSegments[0])

  const getGroupedData = (discoveries: IDisWithApp[], timeFrame: number): IDataPoint[] => {
    let groupedSet: IDataPoint[] = [];
    let allNames: string[] = [];
    let allDates: string[] = [];
    let dateFormat: string = timeFrame === 3 ? 'DD/MMM/YY' : 'MMM/YY';
    const discoveriesForThisSegment = curSegment ? discoveries.filter(d => {
      d.cam.labels?.forEach(label => label ? label.toLowerCase() : null);
      return d.cam.labels?.includes(curSegment.toLowerCase());
    }) : [];

    discoveriesForThisSegment.forEach(dis => {
      let date: string;

      if (timeFrame === 3) {

        date = moment(dis.cam.start_time).startOf('week').format(dateFormat)
      } else {
        date = moment(dis.cam.start_time).format(dateFormat)
      }
      if (!groupedSet.some(item => item.group === dis.app.display_name && item.key === date)) {
        groupedSet.push({ group: dis.app.display_name, key: date, value: 1 })
      } else {

        groupedSet.forEach((item: any) => {
          if (item.group === dis.app.display_name && item.key === date) {
            item.value++;
          }
        })
      }

      // fill in the missing values
      allNames.push(dis.app.display_name)
      allDates.push(date)

    })
    for (let name of allNames) {
      for (let date of allDates) {
        if (!groupedSet.some(item => item.group === name && item.key === date)) {
          groupedSet.push({ group: name, key: date, value: 0 })
        }
      }
    }
    groupedSet = groupedSet.sort((a, b) => {
      return moment(a.key, dateFormat).diff(moment(b.key, dateFormat))
    })
    return groupedSet
  };


  let groupedData: IDataPoint[] = [];


  groupedData = getGroupedData(discoveries, timeFrame);
  const setOfGroup = Array.from(new Set(discoveries.map(d => d.app.display_name)))

  return <div className={'segment-container'}>
    <ChartContainer title="USER SEGMENTATION">
      <div className="extra-header">
        <div className="label">Segmentation:</div>
        <CSelectBox
          black={true}
          value={curSegment}
          onChange={(segment: string) => {
            curSegmentChange(segment)
          }}
        >
          {setOfSegments.map(seg => <CSelectBox.Option value={seg} key={seg}>{seg}</CSelectBox.Option>)}
        </CSelectBox>

      </div>
      <div style={{ minHeight: '25rem', padding: '1rem' }}>
        <WLineChart options={getOptions(setOfGroup, timeFrame)} data={groupedData} />
      </div>
    </ChartContainer>
  </div>
}
export default UserSegmentChart;