import React, { useEffect } from "react";
import "./MarketingOverviewPage.scss";
import { MarketingOverviewFilters } from "./components/MarketingOverviewFilters";
import { HeadingWithTooltip } from "./components/HeadingWithTooltip";
import {
  MarketingAggressiveness,
  MarketingCampaignChannelsBreakdown,
  MassVsPersonalizedCampaigns,
  CampaignPerUserType,
  ShareOfVoice,
  CampaignCalendar,
} from "./components/charts";
import { useOverviewData } from "./hooks/useOverviewData";
import { withRouter } from "react-router";
import {
  MarketingOverviewFiltersParams,
  useOverviewFilters,
} from "./hooks/useOverviewFilters";
import { ChartValuesToggle } from "./utils/types";
import {
  ChartNames,
  eventsData,
  trackMarketingOverviewEvent,
} from "./utils/analytics";
import { useIntersection } from "./hooks/useIntersection";
import NewLoader from "common/NewLoader/NewLoader";
import { Empty } from "antd";

type Props = {
  history: any;
};

const MarketingOverviewPageBase = ({ history }: Props) => {
  const {
    appIds,
    handleUpdateFilter,
    channelIds,
    customRange,
    customRangeTime,
    daysAgo,
    campaignChannelBreakdownChartValues,
    perUserTypeChartValues,
    campaignCalendarShowLines,
  } = useOverviewFilters({ history });

  const onChartValuesToggle =
    (chart: MarketingOverviewFiltersParams) => (val: ChartValuesToggle) => {
      handleUpdateFilter(chart, val as any);
      trackMarketingOverviewEvent("chart_view_changed", {
        chart_name:
          chart ===
          MarketingOverviewFiltersParams.campaign_channel_breakdown_chart_values
            ? "Campaign Channels Breakdown"
            : "Marketing Campaigns Per User Type",
        view_name: val,
      });
    };

  const onChartSwitchToggle =
    (chart: MarketingOverviewFiltersParams) => (val: boolean) => {
      handleUpdateFilter(chart, val ? "true" : "false");
    };

  const {
    apps,
    appsWithInsightsCounts,
    appsByInsightType,
    selectedChannels,
    appsByDateAndType,
    focusAreas,
    appsByFocusArea,
    personas,
    appsByPersonas,
    customerApp,
    appsByCampaignTypes,
    isLoading,
  } = useOverviewData({
    handleUpdateFilter,
    filters: {
      appIds,
      channelIds,
      customRange,
      customRangeTime,
      daysAgo,
    },
  });

  // Analytics

  useEffect(() => {
    trackMarketingOverviewEvent("overview_enter", null);
    eventsData.set("overview_enter", new Date());

    return () => {
      const startDate = eventsData.get("overview_enter");
      const endDate = new Date();
      const secondsSpent = (endDate.getTime() - startDate.getTime()) / 1000;

      trackMarketingOverviewEvent("overview_exit", {
        seconds_spent: secondsSpent,
      });
    };
  }, []);

  const chartViewed = (chartName: ChartNames) => () => {
    trackMarketingOverviewEvent("overview_chart_viewed", {
      chart_name: chartName,
    });
  };

  const { intersectionRef: campaignVolumeRef } = useIntersection({
    onIntersect: chartViewed("Marketing Campaign Volume"),
  });

  const { intersectionRef: campaignChannelsBreakdownRef } = useIntersection({
    onIntersect: chartViewed("Campaign Channels Breakdown"),
  });

  const { intersectionRef: campaignCalendarRef } = useIntersection({
    onIntersect: chartViewed("Campaign Calendar"),
  });

  const { intersectionRef: shareOfVoiceRef } = useIntersection({
    onIntersect: chartViewed("Share Of Voice"),
  });

  const { intersectionRef: campaignPerUserTypeRef } = useIntersection({
    onIntersect: chartViewed("Marketing Campaigns Per User Type"),
  });

  const chartClicked = (chartName: ChartNames) => () => {
    trackMarketingOverviewEvent("overview_chart_clicked", {
      chart_name: chartName,
    });
  };

  const isNoData =
    appsWithInsightsCounts.length === 0 &&
    appsByInsightType.length === 0 &&
    appsByDateAndType.length === 0 &&
    appsByFocusArea.length === 0 &&
    appsByPersonas.length === 0;

  return (
    <div className="mo-container">
      <MarketingOverviewFilters
        customerApp={customerApp}
        channelIds={channelIds}
        customRange={customRange}
        customRangeTime={customRangeTime}
        daysAgo={daysAgo}
        handleUpdateFilter={handleUpdateFilter}
        apps={apps}
        selectedAppIds={appIds}
      />
      {isLoading ? (
        <div className="loading-container">
          <NewLoader />
        </div>
      ) : isNoData ? (
        <div className="no-data-content">
          <Empty />
        </div>
      ) : (
        <div className="mo-content">
          <div
            className="mo-section"
            ref={campaignVolumeRef}
            onClick={chartClicked("Marketing Campaign Volume")}
          >
            <HeadingWithTooltip
              title="Marketing Campaign Volume"
              tooltip="Sheker"
            />
            <MarketingAggressiveness apps={appsWithInsightsCounts} />
          </div>
          <div
            className="mo-section"
            ref={campaignChannelsBreakdownRef}
            onClick={chartClicked("Campaign Channels Breakdown")}
          >
            <HeadingWithTooltip
              showToggle
              currentToggle={campaignChannelBreakdownChartValues}
              onToggle={onChartValuesToggle(
                MarketingOverviewFiltersParams.campaign_channel_breakdown_chart_values
              )}
              title="Campaign Channels Breakdown"
              tooltip="Sheker"
            />
            <MarketingCampaignChannelsBreakdown
              selectedChannels={selectedChannels}
              apps={appsByInsightType}
              chartValues={campaignChannelBreakdownChartValues}
            />
          </div>
          {appsByFocusArea.length > 0 && (
            <div
              className="mo-section"
              ref={shareOfVoiceRef}
              onClick={chartClicked("Share Of Voice")}
            >
              <HeadingWithTooltip title="Share Of Voice" tooltip="Sheker" />
              <ShareOfVoice
                appInsightsByFocusAreas={appsByFocusArea}
                focusAreas={focusAreas}
              />
            </div>
          )}
          <div
            className="mo-section"
            ref={campaignPerUserTypeRef}
            onClick={chartClicked("Marketing Campaigns Per User Type")}
          >
            <HeadingWithTooltip
              showToggle
              currentToggle={perUserTypeChartValues}
              onToggle={onChartValuesToggle(
                MarketingOverviewFiltersParams.per_user_type_chart_values
              )}
              title="Marketing Campaigns Per User Type"
              tooltip="Sheker"
            />
            <CampaignPerUserType
              appsByPersonas={appsByPersonas}
              userTypes={personas}
              chartValues={perUserTypeChartValues}
            />
          </div>
          {appsByDateAndType.length > 0 && (
            <div
              className="mo-section"
              ref={campaignCalendarRef}
              onClick={chartClicked("Campaign Calendar")}
            >
              <HeadingWithTooltip
                title="Campaign Calendar"
                tooltip="Sheker"
                showSwitch
                currentSwitch={campaignCalendarShowLines}
                onSwitch={onChartSwitchToggle(
                  MarketingOverviewFiltersParams.campaign_calendar_show_lines
                )}
              />
              <CampaignCalendar
                daysAgo={daysAgo}
                customRange={customRange}
                appsByDate={appsByDateAndType}
                customRangeTime={customRangeTime}
                showLines={campaignCalendarShowLines}
              />
            </div>
          )}
          {appsByCampaignTypes.length > 0 && apps.length > 0 && (
            <div className="mo-section">
              <HeadingWithTooltip
                title="Mass vs. Personalized Campaigns"
                tooltip="Sheker"
              />
              <MassVsPersonalizedCampaigns
                appsByMassAndPersonalized={appsByCampaignTypes}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const MarketingOverviewPage = withRouter(MarketingOverviewPageBase);
