import React, { useEffect, useState } from "react";
import "./MultiSelect.scss";
import { Checkbox, Popover, Tooltip } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import {
  fitStringToLength,
  isMobileDevice,
} from "containers/MarketingOverview/utils/helpers";
import caret_ico from "assets/images/icons/caret-down.svg";
import x_ico from "assets/images/icons/x-no-outline.svg";

export type Entry = {
  id: number;
  name: string;
  color: string;
  isSelected?: boolean;
  ids?: number[];
};

type Props = {
  entries: Entry[];
  numOfSelectedByDefault?: number;
  maxLabelLength?: number;
  onSelectionChange?: (entries: Entry[]) => void;
  selectTitle: string;
};

const MAX_LABEL_LENGTH = 43;
const MAX_LABEL_LENGTH_MOBILE = 45;

export const MultiSelect = ({
  entries,
  numOfSelectedByDefault = 5,
  maxLabelLength,
  onSelectionChange,
  selectTitle,
}: Props) => {
  const [currentEntries, setCurrentEntries] = useState<Entry[]>([]);
  const isAllSelected = currentEntries.every((entry) => entry.isSelected);
  const isMobile = isMobileDevice();
  const maxLabelDisplayLength =
    maxLabelLength ?? (isMobile ? MAX_LABEL_LENGTH_MOBILE : MAX_LABEL_LENGTH);
  const selectedEntries = currentEntries.filter((entry) => entry.isSelected);

  useEffect(() => {
    let alreadySelected = 0;

    const newEntries = entries.map((entry) => ({
      ...entry,
      isSelected: alreadySelected++ < numOfSelectedByDefault,
    }));

    setCurrentEntries(newEntries);
  }, [entries, numOfSelectedByDefault]);

  const handleCheck = ({ target: { value, checked } }: CheckboxChangeEvent) => {
    const newEntries = currentEntries.map((entry) => {
      if (entry.id === value) {
        return {
          ...entry,
          isSelected: checked,
        };
      }

      return entry;
    });

    onSelectionChange?.(newEntries);
    setCurrentEntries(newEntries);
  };

  const handleAllSelect = ({ target: { checked } }: CheckboxChangeEvent) => {
    const newEntries = currentEntries.map((entry) => ({
      ...entry,
      isSelected: checked,
    }));

    onSelectionChange?.(newEntries);
    setCurrentEntries(newEntries);
  };

  const handleClearSelection = () => {
    const newEntries = currentEntries.map((entry) => ({
      ...entry,
      isSelected: false,
    }));

    onSelectionChange?.(newEntries);
    setCurrentEntries(newEntries);
  };

  const renderCheckboxes = () => {
    return currentEntries.map((entry) => (
      <Checkbox
        key={entry.id}
        className="multi-select-cb"
        checked={entry.isSelected}
        value={entry.id}
        onChange={handleCheck}
      >
        <Tooltip
          title={entry.name}
          placement="top"
          overlayClassName="info-header-tooltip"
        >
          <div className="multi-select-cb-content">
            <div
              style={{ backgroundColor: entry.color }}
              className="multi-select-color"
            />
            <p className="content-name">{entry.name}</p>
          </div>
        </Tooltip>
      </Checkbox>
    ));
  };

  return (
    <div className="multi-select-container">
      {isMobile && (
        <Popover
          placement="bottom"
          title={null}
          destroyTooltipOnHide={true}
          content={
            <div className="multi-select-dropdown-content">
              {renderCheckboxes()}
            </div>
          }
          trigger="click"
          overlayClassName={`filter-wrap all-discoveries all-discoveries-time`}
          overlayStyle={{ width: "25rem" }}
        >
          <div className="multi-select-dropdown">
            {selectTitle} ({selectedEntries.length})
            <img src={caret_ico} />
          </div>
        </Popover>
      )}
      <div className="multi-select-clean">
        <Checkbox
          className="multi-select-cb select-all"
          checked={isAllSelected}
          onChange={handleAllSelect}
        >
          All
        </Checkbox>
        <div className="clean-selection" onClick={handleClearSelection}>
          Clear Selection
        </div>
      </div>
      {isMobile && (
        <div className="multi-select-selected">
          {selectedEntries.map((entry) => (
            <div className="multi-select-selected-item" key={entry.id}>
              <img
                src={x_ico}
                onClick={() => {
                  handleCheck({
                    target: { value: entry.id, checked: false },
                  } as any);
                }}
              />
              <div className="dot" style={{ backgroundColor: entry.color }} />
              {fitStringToLength(entry.name, maxLabelDisplayLength)}
            </div>
          ))}
        </div>
      )}
      {!isMobile && renderCheckboxes()}
    </div>
  );
};
