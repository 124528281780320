import React, { useState, useCallback } from "react";
import { Modal } from "antd";
import "./passwordResetModal.scss";
import { passwordResetSteps } from "./steps";
import { TextHeading } from "../../../components/textHeading/TextHeading";
import X from "../../../assets/images/icons/x.svg";
import awsService from "../../../services/awsService";

export const PasswordResetModal = ({ onLogin }) => {
  const [visibility, setVisibility] = useState(false);

  const [step, setStep] = useState(0);
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  const [confirmationCode, setConfirmationCode] = useState(null);
  const View = passwordResetSteps[step];
  const handleCloseWithPopup = useCallback(() => {
    Modal.confirm({
      // title: "Do you want to delete these items?",
      className: "cancel-reset-password-modal",

      content: (
        <div className="content">
          <TextHeading title="Are you SUre?" />

          <div className="text">
            You are about to{" "}
            <span className="bold">cancel your password reset</span> are you
            sure you want to do this?{" "}
            <span className="ghost">your password will not change</span>
          </div>
        </div>
      ),
      icon: null,

      width: 427,

      okType: "default",
      cancelButtonProps: {
        type: "primary"
      },
      cancelText: "No, Resume Password Reset",
      okText: "Yes, Cancel",
      onOk() {
        setVisibility(false);
        setTimeout(() => setStep(0), 500);
      },
      onCancel() {}
    });
  }, []);
  const handleClose = useCallback(() => {
    setVisibility(false);
    setTimeout(() => setStep(0), 500);
  }, []);

  const handleCloseAndLogin = useCallback(() => {
    handleClose();
    const aws = new awsService();
    aws.login(username, password).then(onLogin);
  }, [handleClose, onLogin, password, username]);
  return (
	  <div className="forgot-password">
	  <a

        type="link"
        className="forgot-password__trigger"
        onClick={() => setVisibility(true)}
      >
        Forgot password?
      </a>
      <Modal
        title={""}
        width={427}
        open={visibility}
        centered
        maskClosable={false}
        maskStyle={{
          backgroundColor: "rgba(2, 13, 23, 0.9)"
        }}
        onCancel={View.noPopupOnClose ? handleClose : handleCloseWithPopup}
        footer={null}
        closable={View.title !== "Success"}
        closeIcon={<img src={X} alt="close-icon" />}
        className="forgot-password__modal"
      >
        <TextHeading title={View.title} />
        <View.component
          confirmationCode={confirmationCode}
          setConfirmationCode={setConfirmationCode}
          setPassword={setPassword}
          username={username}
          setUsername={setUsername}
          next={() => setStep(step + 1)}
          close={handleClose}
          closeAndLogin={handleCloseAndLogin}
        />
      </Modal>
    </div>
  );
};
