import axios from "axios";
import config from "../../shared/config";
const l = (...a) => console.log('userApi', ...a);
const checkUrl = (url) => {
  if (url.endsWith('undefined')) {
    l('BAD URL', url);
    throw new Error('undfeined url');
  }
}

export const getUserData = () => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/user/preferences/details`;
  checkUrl(url);
  return axios.get(url);
};

export const setUserDetails = (user) => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/user/preferences/details`;
  checkUrl(url);
  return axios.post(url, user);
};

export const setUserPass = (old_password, new_password) => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/user/preferences/password`;
  checkUrl(url);
  return axios.post(url, {
    old_password, new_password
  });
};

export const setUserPic = (pic) => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/user/preferences/picture`;
  checkUrl(url);
  return axios.post(url, {
    data: pic
  });
};

export const deleteUserPic = () => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/user/preferences/picture`;
  checkUrl(url);
  return axios.delete(url);
};

export const createNewUser = () => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/user`;
  checkUrl(url);
  return axios.post(url);
};

export const setEmailPref = (emailPref) => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/user/preferences/subscriptions`;
  checkUrl(url);
  return axios.put(url, { ...emailPref });
}

export const changePWDOnFirstLogin = (data) => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/user/nonce`;
  checkUrl(url);
  return axios.post(url, data);
}

export const sendNewChangePWDMail = (data) => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/user/new-change-pwd-email`;
  checkUrl(url);
  return axios.post(url, data);
}

export const checkNonceExpired = (data) => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/user/${data.email}/nonce/${data.nonce}`;
  checkUrl(url);
  return axios.get(url);
}

export const getUserDiscoveries = () => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/user/insights`;
  return axios.get(url);
}

export const getAccessRequestsAPI = () => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/organization/access-requests`;
  return axios.get(url);
}

export const setAccessRequestStatusAPI = ({ request_id, state }) => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/organization/access-requests/${request_id}`;
  return axios.put(url, { state });
}

export const getOrganizationUsersAPI = () => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/organization/users`;
  return axios.get(url);
}

export const deleteOrganizationUserAPI = ({ user_id }) => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/organization/users/${user_id}`;
  return axios.put(url, {
    is_disabled: true
  });
}



export const sendInviteForUserAPI = ({ first_name, last_name, email }) => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/organization/invitations`;
  return axios.post(url, {
    first_name,
    last_name,
    email
  });
}

export const cancelInviteForUserAPI = ({ invitation_id }) => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/organization/invitations/${invitation_id}`;
  return axios.put(url, { "state": "invitation_canceled" });
}

export const resendInviteForUserAPI = ({ invitation_id }) => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/organization/invitations/${invitation_id}/invitation-email`;
  return axios.post(url, {});
}