import React from 'react'
import { Modal } from "antd";

import "./ModalBroadwayTheater.scss"

import close_modal_ico from "../../../assets/images/icons/close_modal.svg"

export default function ModalBroadwayTheater({
  modalIsOpen,
  closeModal,
  platform,
  content,
}) {
  return (
    <Modal
      title={null}
      open={modalIsOpen}
      onCancel={closeModal}
      style={{ top: 0 }}
      wrapClassName={`modal__overlay modal-broadway-theater ${platform}`}
      footer={null}
      width={window.innerWidth - 100}
      maskClosable={true}
      transitionName=""
      maskTransitionName=""
    >
      <img src={close_modal_ico} className="close-image-gallery" onClick={closeModal} />
      <div className={`slick-image-slider-wrapper `}>
        {content}
      </div>
    </Modal>
  )
}

