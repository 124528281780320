import React from "react";
import "./AppTooltip.scss";
import { RouteComponentProps, withRouter } from "react-router";
import moment from "moment";

type Props = {
  appId: number;
  emailInsights: number;
  pushInsights: number;
  inAppInsights: number;
  date: string;
} & RouteComponentProps;

const AppTooltipBase = ({
  history,
  emailInsights,
  appId,
  date,
  inAppInsights,
  pushInsights,
}: Props) => {
  const handleViewClick = () => {
    const fromDate = moment(date).toISOString();
    const toDate = moment(date).add(1, "days").toISOString();

    history.push(
      `/marketing/campaigns?app_id=${appId}&custom_range=true&custom_range_time=${fromDate}&custom_range_time=${toDate}`
    );
  };

  return (
    <div className="app-tooltip">
      <div>Email - {emailInsights}</div>
      {/* SQUAD2-1244: remove in app-banners from timeline chart */}
      {/* <div>In - App Banner - {inAppInsights}</div> */}
      <div>Push Notification - {pushInsights}</div>
      <a onClick={handleViewClick}>View campaigns</a>
    </div>
  );
};

export const AppTooltip = withRouter(AppTooltipBase);
