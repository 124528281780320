import { Col, Row } from "antd";
import React from "react";
import "./styles.scss";
import { Filters } from "./filters";

export const Header = () => {
  return (
    <Col span={24} className="ctl-header">
      <Row gutter={48}>
        <Col flex="250px" style={{ alignItems: "center" }}>
          <h1 className="ctl-header-title">Context Timeline</h1>
        </Col>
        <Col flex="auto" style={{ alignItems: "center" }}>
          <Filters />
        </Col>
      </Row>
    </Col>
  );
};
