import React, { useEffect, useMemo, useRef, useState } from "react";
import { ChannelSelect } from "./ChannelSelect";
import {
  useMaterialReactTable,
  MRT_ColumnDef,
  MRT_Virtualizer,
  MaterialReactTable,
  MRT_SortingState,
  MRT_Column,
} from "material-react-table";
import "./MarketingCampaignsTable.scss";
import info_ico from "assets/images/icons/info-new.svg";
import { ReactComponent as InfoIco } from "assets/images/icons/info-new-dark.svg";
import chevron_ico from "assets/images/icons/chevron-right.svg";
import { Popover, Tooltip } from "antd";
import { ChangeCampaignsFilterType } from "../../hooks/useCampaignsFilters";
import {
  App,
  Channel,
  Insight,
  UserRoleType,
  UserType,
} from "../../utils/types";
import {
  CAMPAIGNS_FILTERS,
  MARKETING_INSIGHT_TYPES,
  TABLE_SORT_FIELDS,
  TYPE_TO_CHANNEL,
} from "../../utils/conts";
import { MarketingInsightPreviewModal } from "./MarketingInsightPreviewModal";
import {
  getPersonaTitle,
  getPriority,
  getShortenedString,
} from "containers/MarketingCampaings/utils/helpers";
import { LinearProgress, useMediaQuery } from "@mui/material";
import { trackMarketingEvent } from "containers/MarketingCampaings/utils/analytics";
import useTableHeight from "containers/MarketingCampaings/hooks/useTableHeight";

type Props = {
  channelId: string;
  handleChangeFilter: ChangeCampaignsFilterType;
  tableData: Insight[];
  channels: Channel[];
  isFetching: boolean;
  totalFetched: number;
  totalDBRowCount: number;
  fetchNextPage: () => void;
  userTypes: UserType[];
  userRoleTypes: UserRoleType[];
  appId: string;
  selectedInsight: any;
  currentApp: App;
};

const MAX_USER_LEN = 35;
const MAX_TAG_LEN = 25;

export const MarketingCampaignsTable = ({
  channelId,
  tableData,
  handleChangeFilter,
  channels,
  isFetching,
  totalDBRowCount,
  totalFetched,
  fetchNextPage,
  selectedInsight,
  userTypes,
  userRoleTypes,
  appId,
  currentApp,
}: Props) => {
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const rowVirtualizerInstanceRef =
    useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);

  const handlePreviewToggle = (insightId: string) => () => {
    handleChangeFilter(CAMPAIGNS_FILTERS.insight_id, insightId);
  };

  const renderTooltipHeader = (
    tooltipText: string,
    column: MRT_Column<Insight, unknown>
  ) => {
    return (
      <div className="info-header">
        {column.columnDef.header}
        <Tooltip
          overlayClassName="info-header-tooltip"
          placement="top"
          title={tooltipText}
          onOpenChange={() => {
            trackMarketingEvent("information_clicked", {
              column_name: column.columnDef.header,
            });
          }}
        >
          <img src={info_ico} />
        </Tooltip>
      </div>
    );
  };

  const columns = useMemo<MRT_ColumnDef<Insight>[]>(
    () => [
      {
        accessorKey: "title",
        header: "Title",
        size: 360,
        enableSorting: false,
        Cell: ({ cell, row }) => {
          useEffect(() => {
            trackMarketingEvent("insight_row_viewed", {
              insight_id: row.original.id,
              insight_channel: row.original.type,
              insight_title: row.original.title,
              insight_focus_area: row.original.focusArea || "",
              selected_app_name: currentApp.displayName,
            });
          }, []);

          return (
            <div className="insight-name-container">
              <div className="insight-name">{cell.getValue()}</div>
              <img
                className="expand-icon"
                src={chevron_ico}
                onClick={() => cell.getContext().row.toggleExpanded()}
              />
            </div>
          );
        },
        Header: ({ column }) =>
          renderTooltipHeader(
            "Campaign headline (i.e. email subject, in-app banner title)",
            column
          ),
      },
      {
        accessorKey: "type",
        header: "Channel",
        size: 153,
        Cell: ({ renderedCellValue }: any) => (
          <span>{TYPE_TO_CHANNEL[renderedCellValue]}</span>
        ),
        Header: ({ column }) =>
          renderTooltipHeader(
            "Marketing channel used (i.e. email, push, in-app banner)",
            column
          ),
      },
      {
        accessorKey: "focusArea",
        header: "Focus area",
        enableSorting: false,
        size: 220,
        Cell: ({ renderedCellValue }: any) => (
          <div className="list-container">
            {renderedCellValue && (
              <div className="item">{renderedCellValue}</div>
            )}
          </div>
        ),
        Header: ({ column }) =>
          renderTooltipHeader(
            "Main theme/objective of the marketing campaign",
            column
          ),
      },
      {
        accessorKey: "subType",
        header: "Sub Type",
        enableSorting: false,
        size: 180,
        Cell: ({ renderedCellValue }: any) => (
          <div className="list-container">
            {renderedCellValue && (
              <div className="item">{renderedCellValue}</div>
            )}
          </div>
        ),
      },
      {
        accessorKey: "startTime",
        header: "Sent Date",
        size: 163,
        Cell: ({ renderedCellValue }: any) => {
          const currentDate = new Date(renderedCellValue);
          const userLocale = navigator.language || "en-US";
          const formattedDate = new Intl.DateTimeFormat(userLocale, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            timeZone: "UTC",
          }).format(currentDate);

          return <span>{formattedDate}</span>;
        },
        Header: ({ column }) =>
          renderTooltipHeader("Date the campaign was delivered", column),
      },
      {
        accessorKey: "labels",
        header: "Tags",
        size: 300,
        enableSorting: false,
        Cell: ({ renderedCellValue }: any) => (
          <div className="list-container">
            {renderedCellValue.slice(0, 3).map((label: any) => (
              <div className="item" id={label.name}>
                {getShortenedString(label.name, MAX_TAG_LEN)}
              </div>
            ))}
            {renderedCellValue.length > 3 && (
              <Popover
                title={null}
                placement="left"
                content={
                  <div className="marketing-list-container-preview">
                    {renderedCellValue.map((label: any) => (
                      <div className="item" id={label.name}>
                        {label.name}
                      </div>
                    ))}
                  </div>
                }
              >
                <div className="view-more">+{renderedCellValue.length - 3}</div>
              </Popover>
            )}
          </div>
        ),
        Header: ({ column }) =>
          renderTooltipHeader(
            "Created by our analysts to group campaigns by topic.",
            column
          ),
      },
      {
        accessorKey: "personasAndRoles",
        header: "User Types Seen",
        size: 320,
        enableSorting: false,
        Cell: ({ renderedCellValue }: any) => {
          const personas = (renderedCellValue.personas ?? []).filter(
            (persona: any) =>
              persona.roles == null || persona.roles.length === 0
          );
          const roles = renderedCellValue.personaRoles ?? [];
          const personasAndRoles = roles.concat(personas);
          return (
            <div className="list-container">
              {personasAndRoles.slice(0, 3).map((type: any) => (
                <div
                  className="item"
                  style={{
                    backgroundColor: type.bgColor,
                    color: type.textColor,
                  }}
                >
                  <div className="item-text">
                    {getShortenedString(type?.title ?? type.name, MAX_USER_LEN)}
                  </div>
                  <div className="item-icon">
                    <Tooltip
                      overlayClassName="info-header-tooltip"
                      placement="top"
                      title={type?.description || "No description"}
                    >
                      <InfoIco fill={type.textColor} />
                    </Tooltip>
                  </div>
                </div>
              ))}
              {personasAndRoles.length > 3 && (
                <Popover
                  title={null}
                  placement="left"
                  content={
                    <div className="marketing-list-container-preview">
                      {personasAndRoles.map((type: any) => (
                        <div
                          className="item"
                          style={{
                            backgroundColor: type.bgColor,
                            color: type.textColor,
                          }}
                        >
                          <div className="item-text">
                            {type?.title ?? type.name}
                          </div>
                          <div className="item-icon">
                            <Tooltip
                              overlayClassName="info-header-tooltip"
                              placement="top"
                              title={type?.description || "No description"}
                            >
                              <InfoIco fill={type.textColor} />
                            </Tooltip>
                          </div>
                        </div>
                      ))}
                    </div>
                  }
                >
                  <div className="view-more">
                    +{personasAndRoles.length - 3}
                  </div>
                </Popover>
              )}
            </div>
          );
        },
        Header: ({ column }) =>
          renderTooltipHeader(
            "All user types who received the campaign. To see which user segments were not targeted, click on the 'insight' row.",
            column
          ),
      },
      {
        accessorKey: "priority",
        header: "Priority",
        size: 180,
        Cell: ({ renderedCellValue }: any) => (
          <span className={`priority-${getPriority(renderedCellValue)}`}>
            {renderedCellValue}
          </span>
        ),
        Header: ({ column }) =>
          renderTooltipHeader(
            "Our analysts tag insights by priority. Key findings will be tagged as 'High Priority'.",
            column
          ),
      },
      {
        accessorKey: "thumbnail",
        header: "Media",
        size: 280,
        enableSorting: false,
        Cell: ({ renderedCellValue, row }: any) => {
          const isPush =
            row.original.type ===
            MARKETING_INSIGHT_TYPES.push_notification_campaign;

          const imageSrc = isPush
            ? row.original.thumbnailOriginal || row.original.thumbnail
            : renderedCellValue;

          return imageSrc ? (
            <Popover
              title={null}
              placement="left"
              content={
                <div>
                  <img
                    style={{
                      maxWidth: "40vw",
                      maxHeight: "40vw",
                      overflow: "scroll",
                    }}
                    src={imageSrc}
                  />
                </div>
              }
            >
              <div className={`image-container ${isPush ? "contained" : ""}`}>
                <img src={imageSrc} />
              </div>
            </Popover>
          ) : null;
        },
        Header: ({ column }) =>
          renderTooltipHeader("Sample image from the campaign.", column),
      },
    ],
    []
  );

  const onChannelSelect = (channelId: string) => {
    handleChangeFilter(CAMPAIGNS_FILTERS.channel, channelId);
    document.querySelector("#mkc-table")?.scrollTo(0, 0);
  };

  const fetchMoreOnBottomReached = (
    containerRefElement?: HTMLDivElement | null
  ) => {
    if (containerRefElement) {
      const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
      //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
      if (
        scrollHeight - scrollTop - clientHeight < 400 &&
        !isFetching &&
        totalFetched < totalDBRowCount
      ) {
        fetchNextPage();
      }
    }
  };

  const tableHeight = useTableHeight();

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    manualSorting: true,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableBottomToolbar: false,
    enablePagination: false,
    enableColumnOrdering: true,
    enableFullScreenToggle: false,
    enableExpandAll: false,
    enableExpanding: false,
    enableStickyHeader: true,
    enableRowVirtualization: true,
    rowVirtualizerInstanceRef: rowVirtualizerInstanceRef,
    rowVirtualizerOptions: { overscan: 4 },
    state: {
      showProgressBars: isFetching,
      sorting,
    },

    renderTopToolbar: () => isFetching && <LinearProgress />,
    onSortingChange: setSorting,
    muiTableContainerProps: {
      id: "mkc-table",
      sx: {
        height: tableHeight,
      },
      ref: tableContainerRef,
      onScroll: (
        event: any //add an event listener to the table container element
      ) => fetchMoreOnBottomReached(event.target),
    },
    initialState: {
      columnVisibility: {
        "mrt-row-expand": false,
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        "&:hover td": {
          cursor: "pointer",
          backgroundColor: "#F0F4FF !important",
        },
        ...(row.index % 2 === 0
          ? {
              td: {
                backgroundColor: "#f5f5f5 !important",
              },
            }
          : {}),
      },
      onClick: handlePreviewToggle(row.original.id.toString()),
    }),
    muiTableBodyCellProps: {
      sx: {
        color: "#616065",
        fontSize: "13px",
        fontFamily: "Inter",
        fontWeight: 400,
        lineHeight: "190%",
        textTransform: "capitalize",
        maxHeight: "120px !important",
        padding: "16px 8x !important",
        display: "flex",
        alignItems: "baseline",
        borderRight: "1px solid #DEDEDE",
        ":first-child": {
          borderLeft: "1px solid #DEDEDE",
        },
      },
    },
    muiTableHeadCellProps: {
      sx: {
        fontSize: "13px",
        padding: "20px 16px !important",
        backgroundColor: "#f5f5f5",
        color: "#44434A",
        fontWeight: 500,
        fontFamily: "Inter",
        textTransform: "capitalize",
        borderTop: "1px solid #DEDEDE",
        borderBottom: "1px solid #DEDEDE",
        borderRight: "1px solid #DEDEDE",
        "&:first-child": {
          borderLeft: "1px solid #DEDEDE",
          borderRadius: "8px 0 0 0",
        },
        "&:last-child": {
          borderRadius: "0 8px 0 0",
        },
      },
    },
  });

  useEffect(() => {
    const sort = sorting[0];

    if (sort) {
      const { id, desc } = sort;
      const currentSortStr = desc
        ? TABLE_SORT_FIELDS.desc[id]
        : TABLE_SORT_FIELDS.asc[id];

      trackMarketingEvent("sort_column_clicked", {
        sort_column: id,
      });

      handleChangeFilter(CAMPAIGNS_FILTERS.sort, currentSortStr);
    } else {
      handleChangeFilter(CAMPAIGNS_FILTERS.sort, "");
    }
  }, [sorting]);

  useEffect(() => {
    const tableContainer = document.getElementById("mkc-table");
    const topScrollContainer = document.getElementById("mkc-table-top-scroll");
    const childScroll = document.getElementById("mkc-table-top-scroll-content");

    // connect the bottom scroll to top scroll
    if (tableContainer && topScrollContainer && childScroll) {
      childScroll.style.width = `${tableContainer.scrollWidth}px`;

      topScrollContainer.onscroll = () => {
        tableContainer.scrollLeft = topScrollContainer.scrollLeft;
      };

      tableContainer.onscroll = () => {
        topScrollContainer.scrollLeft = tableContainer.scrollLeft;
      };
    }

    //a check on mount to see if the table is already scrolled to the bottom and immediately needs to fetch more data
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  return (
    <div>
      <ChannelSelect
        selectedChannelId={channelId}
        onChannelSelect={onChannelSelect}
        channels={channels}
      />
      <div className="mkc-table-top-scroll" id="mkc-table-top-scroll">
        <div id="mkc-table-top-scroll-content">&nbsp;</div>
      </div>
      <div className="mkc-table-container">
        <MaterialReactTable table={table} />
      </div>
      <MarketingInsightPreviewModal
        appId={appId}
        appName={currentApp?.displayName}
        open={selectedInsight ? true : false}
        insightId={selectedInsight?.id}
        type={selectedInsight?.type}
        title={selectedInsight?.title}
        date={selectedInsight?.start_time}
        priority={selectedInsight?.priority || ""}
        labels={selectedInsight?.labels || []}
        onClose={handlePreviewToggle("")}
        focusArea={selectedInsight?.focusArea || ""}
        messageContent=""
        userTypes={userTypes.filter(
          (userType) =>
            !Array.isArray(userType.roles) || userType.roles.length === 0
        )}
        userRoleTypes={userRoleTypes}
        userTypesSeen={
          selectedInsight?.personas.filter(
            (persona: any) =>
              !Array.isArray(persona.roles) || persona.roles.length === 0
          ) || []
        }
        userRoleTypesSeen={selectedInsight?.personaRoles || []}
        insightDescription={selectedInsight?.description}
        assets={
          selectedInsight?.assets?.toSorted(
            (a: any, b: any) => a.order_index - b.order_index
          ) || []
        }
        thumbnail={selectedInsight?.thumbnail}
      />
    </div>
  );
};
