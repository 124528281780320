import React, { Component } from "react";
import Dropdown from "../Dropdown";
import {
  compareStrings,
  compareStringsArray
} from "../../../services/stringsService";
import storageService from "../../../services/storageService";
import { withRouter } from "react-router-dom";
import { Avatar } from 'antd';

import { getNameFromEmail } from "../../../helpers/usersHelper";

import icon_arrow_dropdown_menu from '../../../assets/images/icons/down-black.svg'

class DropdownMenu extends Component {
  state = {
    options: [],
    selectOption: null,
    selectOptionIndex: null,
  };

  componentDidMount = () => {
    const selectOptionIndex = 0;
    const options = this.getDropdownOptions();
    const selectOption = this.getSelectOption({ options, selectOptionIndex });
    this.setState({ options, selectOption, selectOptionIndex });
  };

  formatUserName = (user, userData) => {
    let name = getNameFromEmail(user)
    return <>

      {userData.picture_url ?
        <Avatar src={userData.picture_url} className="header__avatar" size="large"/>
        :
        <Avatar className="header__avatar" size="large">
          {userData.first_name && userData.last_name ?
            `${userData.first_name[0].toUpperCase()}${userData.last_name[0].toUpperCase()}` :
            getNameFromEmail(user)[0].toUpperCase()}
        </Avatar>
      }
      <span className="header__username">
        {userData.first_name ? `${userData.first_name} ${userData.last_name}`
          : name.toUpperCase()} </span>
    </>
  }

  componentDidUpdate = prvProps => {
    if ((this.props.user !== prvProps.user) ||
      (this.props.userData.first_name !== prvProps.userData.first_name)
      || (this.props.userData.picture_url !== prvProps.userData.picture_url)) {
      const { user, userData } = this.props;
      const selectedType = { value: user.sub, label: this.formatUserName(user, userData) };
      //   const options = [...this.state.options];
      const options = this.getDropdownOptions();
      options[0] = selectedType;
      const selectOption = this.getSelectOption({
        options,
        selectOptionIndex: 0
      });
      this.setState({ options, selectOption });
    }

  };

  getDropdownOptions = () => {
    const { user } = this.props;
    const options = [];
    if (user.sub && user.email) {
      options.push({ value: user.sub, label: user.email });
      options.push({ value: "Account", label: "My Account", icon: "user" });
      options.push({ value: "Subscriptions", label: "My Subscriptions", icon: "subscription" });
      options.push({ value: "Log Out", label: "Log Out", icon: "logout" });
    } else {
      options.push({ value: "Log In", label: "Log In" });
    }
    return options;
  };

  getSelectOption = ({ options, selectOptionIndex }) => {
    return (
      <>
        {options[selectOptionIndex].label}
        {options.length > 1 && (
          <img
            src={icon_arrow_dropdown_menu}
            className="dropdown__select-arrow"
            alt=""
          />
        )}
      </>
    );
  };

  handleChange = (selectOptionIndex, value) => {
    if (compareStringsArray(value, ["Log Out", "Log In"])) {
      const storage = new storageService();
      storage.clear();
      this.props.history.push("/login");
    } else if (compareStrings(value, "Account")) {
      this.props.history.push("/account");
    } else if (value === 'Subscriptions') {
      this.props.history.push("/subscriptions");
    }
  };

  render() {
    return (
      <Dropdown
        className="dropdown-menu"
        handleChange={this.handleChange}
        visible={true}
        {...this.state}
      />
    );
  }
}

export default withRouter(DropdownMenu);
