import React from 'react'
import { mapStateLabels, UNSEEN, VIEWED, MEANINGFUL, FEATURED, abTestsTypes, MARKED } from '../../shared/consts.js'

import { Checkbox, Button } from 'antd';

export default function FilterState({
  filterStatus,
  filterStatusChange,
  countMeaningful,
  countUnseen,
  countViewed,
  countIntegrated,
  countAbandoned
}) {
  return (
    <div className="filters-popup state">
      <div className="items-selected static">
        <div className="items-selected-count">{`${filterStatus.length} Selected`} </div>
        <div className="items-selected-values">{filterStatus.map(state => mapStateLabels[state]).join(', ')} </div>
        {filterStatus.length !== 0 ?
          <Button onClick={() => filterStatusChange([])} className="all ant-btn-dark">
            Clear
          </Button>
          :
          <Button onClick={() => filterStatusChange([UNSEEN, VIEWED, MEANINGFUL])} className="all ant-btn-dark">
            All
          </Button>
        }
      </div>
      <div className="filters-content">
        <Checkbox.Group options={[
          {
            label: `${mapStateLabels[UNSEEN]} (${countUnseen})`,
            value: UNSEEN
          },
          {
            label: `${mapStateLabels[VIEWED]} (${countViewed})`,
            value: VIEWED
          },
          {
            label: `${mapStateLabels[MEANINGFUL]} (${countMeaningful})`,
            value: MEANINGFUL
          },
          {
            label: `${mapStateLabels[FEATURED]} `,
            value: FEATURED
          },
          {
            label: `${mapStateLabels[MARKED]} `,
            value: MARKED
          },
          {
            label: `Experiment ${abTestsTypes.INTEGRATED} (${countIntegrated})`,
            value: abTestsTypes.INTEGRATED
          },
          {
            label: `Experiment ${abTestsTypes.ABANDONED} (${countAbandoned})`,
            value: abTestsTypes.ABANDONED
          },
          {
            label: `Experiment ${abTestsTypes.ACTIVE}`,
            value: abTestsTypes.ACTIVE
          },
        ]}
        value={filterStatus} onChange={(selected) => filterStatusChange(selected)} />
      </div>
    </div>
  )
}
