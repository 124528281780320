import axios from "axios";
import config from "../../shared/config";

export const getGraphsByAppAPI = (app_id: string) => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/app/${app_id}/graphs`
  return axios.get(url);
};

export const getGraphByIDApi = (graph_id: string) => {
  const { backendUrl } = config.dataApi;
  let url = `${backendUrl}/graphs/${graph_id}`
  return axios.get(url);
};
