import React from 'react'

import { Checkbox, Button } from 'antd';

export default function FilterPlatform({
  filterStatus,
  filterStatusChange,
  listProductPlatforms
}) {


  return (
    <div className="filters-popup state">
      <div className="items-selected static">
        <div className="items-selected-count">{`${filterStatus.length} Selected`} </div>
        {/* <div className="items-selected-values">{filterStatus.map(state => mapStateLabels[state]).join(', ')} </div> */}
        {filterStatus.length !== 0 &&
          <Button onClick={() => filterStatusChange([])} className="all ant-btn-dark">
            Clear
          </Button>}
      </div>
      <div className="filters-content">
        <Checkbox.Group options={listProductPlatforms.map(a => ({
          label: a.platform,
          value: a.id
        })
        )}
        value={filterStatus} onChange={(selected) => filterStatusChange(selected)} />
      </div>
    </div>
  )
}
