import React, { ReactElement, useEffect, useState } from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ReportNote from "./ReportsNote"
import { IReport } from '../../../interfaces/Reports';
import { IState } from '../../../interfaces/Product'

import { Button,  notification } from "antd";
import Icon  from "@ant-design/icons";
import moment from "moment"
import { setReportsNotificationsSeen, setReportsNotificationsRead } from "../../../redux/acts/reports"

import "./ReportsCenter.scss"

import rep_empty_state from "../../../assets/images/new_design/icons/empty-report.svg"

interface IProps {
  reports: IReport[],
  allInsights: any[],
  history: { push(url: string): void; },
  markAllSeenLoading: boolean,
  setReportsNotificationsRead: (arg0: number[]) => void,
  setReportsNotificationsSeen: (arg0: number[]) => void,
  closeReportCenter: () => void,
  analytics: any
}


const ReportsCenter = ({
  reports = [],
  allInsights,
  history,
  markAllSeenLoading = false,
  setReportsNotificationsRead,
  setReportsNotificationsSeen,
  closeReportCenter,
  analytics
}: IProps): ReactElement | null => {

  const handleMarkAllRead = () => {
    setReportsNotificationsRead(reports.map(n => Number(n.id)))
  }

  useEffect(() => {
    setReportsNotificationsSeen(reports.map(n => Number(n.id)))
  }, [])

  const handlerReportNotificationClick = (report: IReport) => {
    if (report.url) {
      history.push(`/reports/${report.id}?source=reports_dropdown`)
    }
    closeReportCenter()
  }

  const handleViewAll = () => {
    history.push(`/reports/`)
    closeReportCenter()
  }

  return (
    <div className="report-center">
      <div className="report-ctr-header">
        <h2>Reports</h2>
        <div className="report-buttons">
          <Button className="ant-btn-light btn-report-mark-all-seen"
            onClick={handleMarkAllRead}
            disabled={reports.length === 0}
            data-testid="mark-all-as-seen">
            Mark all as Read
            {markAllSeenLoading && <Icon type="loading" />}
          </Button>
          <Button className="ant-btn-light view-all" disabled={reports.length === 0} onClick={handleViewAll}>
            View All
          </Button>
        </div>
      </div>
      {reports.length === 0 ?
        <div className="empty-state-report">
          <div className="report-empty-icon">
            <img src={rep_empty_state} />
          </div>
          <div className="report-empty-header">Welcome to your Reporting center</div>
          <div className="report-empty-descr">There are currently no reports available for you, check again later for updates.</div>
        </div>
        :
        <div className="report-list">

          {reports.map(n => <ReportNote
            report={n}
            key={n.id}
            //@ts-ignore
            options={{ humanize: true, format: "LL" }}
            handlerReportNotificationClick={handlerReportNotificationClick}
          />)}

        </div>
      }
    </div>
  )
}



const mapStateToProps = (state: IState) => {
  return {
    reports: state.reports.reports,
    analytics: state.appData.user.analytics,

  };
};

export default withRouter(
  //@ts-ignore
  connect(mapStateToProps, {
    setReportsNotificationsSeen,
    setReportsNotificationsRead
  })(ReportsCenter)
);
