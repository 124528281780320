import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { uniqBy, isEqual, invoke, map, intersectionBy, debounce } from "lodash";
import { AutoComplete, Input } from "antd";
import {SearchOutlined} from "@ant-design/icons";
import SearchResultRow from "./SearchResultRow";

import {
  setSearchFilterByApp,
  clearFilterByApp
} from "../../../redux/actions/dashboardActions";

import { getDiscoveriesSearch } from "../../../redux/actions/overviewActions"

import {ALL_TIME, marketingOnlyType} from "../../../shared/consts"
import {insightURL} from "../../../helpers/appsHelper";

const MINIMUM_SEARCH_QUERY_LENGTH = 2;
const MAX_RESULTS = 30;

const Option = AutoComplete.Option;
class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.searchInputRef = React.createRef();
    window.__sss = this.searchInputRef;
    this.handleSearchRowSelect.bind(this);

    this.state = {
      canSearch: true,
      searchActive: false,
      currentQuery: props.searchWord || "",
      result: [],
      selectedTags: []
    };
  }

  componentDidMount() {
    document
      .getElementById("auto-suggest")
      .addEventListener("transitionend", e => {
        if (e.propertyName === "width") {
          this.searchInputRef && this.searchInputRef.focus();
        }
      });
    document.addEventListener("keyup", e => {
      e.preventDefault()
      e.stopPropagation()
      let key = e.keyCode;
      if (key === 191 && e.shiftKey) {
        this.handleOpenSearch();
      }

      if (key === "Escape" || key === "Esc" || key === 27) {
        this.handleCloseSearch();
      }
    });
    window.addEventListener("click", e => {
      if (
        this.state.searchActive &&
        document.querySelector("#auto-suggest") &&
        // Click outside of autocomplette
        !invoke(
          document.querySelector("#auto-suggest"),
          "contains",
          e.target
        ) &&
        // Tricky moment: node element contains method doesn't work for path or svg tag
        !["svg", "path"].includes(e.target.tagName) &&
        !invoke(
          document.querySelector(
            ".header__search-bar-dropdown .ant-select-dropdown-menu-root"
          ),
          "contains",
          e.target
        )
      ) {
        this.handleCloseSearch();
      }
    });
    if (!this.props.apps.some(app => !app.loaded)) {
      this.setState({ canSearch: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { apps } = this.props
    if (
      isEqual(prevProps.apps, this.props.apps) &&
      !this.state.canSearch &&
      !this.props.apps.some(app => !app.loaded)
    ) {
      this.setState({ canSearch: true });
    }
    if (this.props.filterByApps.length !== prevProps.filterByApps.length) {
      this.handleSearch();
    }


  }

  componentWillUnmount() {
    document
      .getElementById("auto-suggest")
      .removeEventListener("transitionend", e => {
        if (e.propertyName === "width" && this.searchInputRef) {
          this.searchInputRef.focus();
        }
      });

    document.removeEventListener("keyup", e => {
      let key = e.keyCode;

      if (key === 191 && e.shiftKey) {
        this.handleOpenSearch();
      }

      if (key === "Escape" || key === "Esc" || key === 27) {

        this.handleCloseSearch();
      }
    });
  }

  handleInputChange = inputText => {
    this.setState({ currentQuery: inputText }, () => {
      this.debHandleSearch()
    });
  };

  debHandleSearch = debounce(() => {
    const { currentQuery, selectedTags } = this.state;
    const { filterByApps } = this.props;


    if (
      currentQuery &&
      currentQuery.length > MINIMUM_SEARCH_QUERY_LENGTH &&
      currentQuery.trim() !== ""
    ) {
      const vars = {
        query: currentQuery,
        days_ago: ALL_TIME,
      }
      const gets = new URLSearchParams(vars).toString()

      this.props.getDiscoveriesSearch({
        gets: gets
      })
    }
  }, 700);

  handleOpenSearch = () => {
    if (!this.state.searchActive && this.state.canSearch) {
      this.setState({ searchActive: true });
    }
    if (this.searchInputRef) {
      setTimeout(() => {
        this.searchInputRef.focus();
      }, 100)

    }
  };

  handleCloseSearch = () => {
    let pageBackdrop = document.getElementById("page-backdrop-portal");
    if (this.state.searchActive && pageBackdrop) {
      pageBackdrop.classList.remove("backdrop-enabled");
      pageBackdrop.classList.add("backdrop-disable");
      document.body.style.overflowY = "scroll";
      this.searchInputRef && this.searchInputRef.blur();
      this.props.clearFilterByApp();
      this.setState({
        searchActive: false,
        currentQuery: ''
      });
    }
  };

  handleSearchRowSelect = (value, selectedSearchRow) => {
    const { analytics, history } = this.props;
    const { selectedTags, currentQuery } = this.state;
    if (!isNaN(value) && selectedSearchRow.props.children.props) {
      let insightAppVersion =
        selectedSearchRow.props.children.props.searchRowData.insight
          .current_release_id;
      let app_id =
        selectedSearchRow.props.children.props.searchRowData.metadata.id;

      let insightTitle =
        selectedSearchRow.props.children.props.searchRowData.insight.title;
      const insightType = selectedSearchRow.props.children.props.searchRowData.insight.type;
      const resultApp = this.props.apps.find(
        app => app.metadata.id === app_id
      );

      if (
        selectedSearchRow.props.children.props.searchRowData.insight
          .other_release_id
      ) {
        const otherVersion = resultApp.versions.find(
          version =>
            version.id ===
            selectedSearchRow.props.children.props.searchRowData.insight
              .other_release_id
        );

        if (!otherVersion) {
          insightAppVersion =
            selectedSearchRow.props.children.props.searchRowData.insight
              .current_release_id;
        } else {
          const otherVersionInsights = [
            ...otherVersion.ab_tests,
            ...otherVersion.screen_changes,
            ...otherVersion.new_features,
            ...otherVersion.indications
          ];

          if (otherVersionInsights.find(insight => insight.id === value)) {
            insightAppVersion = otherVersion.id;
          } else {
            insightAppVersion =
              selectedSearchRow.props.children.props.searchRowData.insight
                .current_release_id;
          }
        }
      }

      analytics.analytic('Click search result',
        {
          insight_name: insightTitle,
          insight_type: insightType,
          platform: resultApp.platform,
          insight_URL: insightURL({
            appID: app_id,
            versionID: insightAppVersion,
            insightID: value,
            isMarketing: Object.keys(marketingOnlyType).includes(insightType)}),
        });
      let url
      if (insightAppVersion) {
        url = `/intelligence/versions/${app_id}/${insightAppVersion}/${value}`
      } else {
        url = `/marketing/${value}`
      }
      history.replace(url)
    }
  }





  render() {
    const { currentQuery, searchActive, selectedTags } = this.state;
    const { filterByApps, analytics, discoveries, apps, onlyPerformanceMode, disabled } = this.props;

    if (onlyPerformanceMode) {
      return null
    }


    const dataSource = discoveries.data.map(dis => ({
      insight: dis,
      metadata: apps.find(a => a.metadata.id == dis.app_id) ? apps.find(a => a.metadata.id == dis.app_id).metadata : {}
    }))

    const renderRow = row => (
      <Option
        key={row.insight.id}
        text=""
        className="header__search-bar-option"
      >
        <SearchResultRow searchRowData={row} currentQuery={currentQuery} analytics={analytics} />
      </Option>
    );

    const renderDataSource = () => {
      const infoRow = (children, key, top = 0) => (
        <Option
          key={`before-search-${key}`}
          text=""
          disabled
          className="header__search-bar-info"
          style={{ top: top }}
        >
          {children}
        </Option>
      );
      let results = [];

      if (this.props.loadingSearchDiscoveries) {
        return [infoRow("Loading....")];
      }

      if (
        dataSource &&
        dataSource.length > 0 &&
        (currentQuery !== "" || selectedTags.length > 0 || filterByApps.length)
      ) {
        results = dataSource.slice(0, MAX_RESULTS).map(renderRow);

        results.unshift(
          infoRow(
            `Showing ${results.length === 1
              ? `${results.length} Result`
              : `${results.length} Results`
            } out of ${dataSource.length} Results`,
            "number-of-results"
          )
        );
      } else if (!this.props.loadingSearchDiscoveries &&
        (currentQuery.length > MINIMUM_SEARCH_QUERY_LENGTH ||
          selectedTags.length > 0)
      ) {
        // no results
        results = [infoRow("No results found.")];
      }

      return results;
    };
    return (
      <div className={`${this.props.className} ${disabled ? 'dis' : ''}`} onClick={this.handleOpenSearch}>
        <AutoComplete
          open={searchActive}
          id="auto-suggest"
          onChange={this.handleInputChange}
          value={currentQuery}
          defaultActiveFirstOption={false}
          disabled={!searchActive}
          className={`search-auto-complete ${searchActive ? "active" : ""}`}
          popupClassName={
            this.props.newline
              ? "header__search-bar-dropdown-newline"
              : "header__search-bar-dropdown"
          }
          onSelect={(value, option) =>
            this.handleSearchRowSelect(value, option)
          }
          dataSource={renderDataSource()}
          optionLabelProp="text"
          data-testid={"search-bar"}
          disabled={disabled}
        >
          <Input
            className="search-input"
            disabled={disabled}
            ref={input => {
              this.searchInputRef = input;
            }}
            placeholder="Search..."
            suffix={
              <SearchOutlined />
            }
            data-testid={"text-bar"}
          />
        </AutoComplete>
      </div>
    );
  }
}

const mapStateToProps = ({ appData, overview }) => ({
  apps: appData.apps,
  onlyPerformanceMode: appData.onlyPerformanceMode,
  insightLabelsMap: appData.insightLabelsMap,
  allInsights: appData.allInsights,
  filterByApps: map(appData.filterByApps),
  discoveries: overview.searchedDiscoveries || { data: [] },
  loadingSearchDiscoveries: overview.loadingSearchDiscoveries,
});

const mapDispatchToProps = { setSearchFilterByApp, clearFilterByApp, getDiscoveriesSearch };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchBar)
);
