import React, { useState } from "react";
import "./MarketingOverviewFilters.scss";
import caret_ico from "assets/images/icons/caret-down.svg";
import { Popover, Switch } from "antd";
import { App } from "containers/MarketingOverview/utils/types";
import { HandleOverviewFilterChangeType } from "containers/MarketingOverview/hooks/useOverviewFilters";
import moment, { Moment } from "moment";
import { daysAgoList } from "shared/consts";
import { ChannelSelect } from "./ChannelSelect";
import { trackMarketingOverviewEvent } from "containers/MarketingOverview/utils/analytics";
import { isMobileDevice } from "containers/MarketingOverview/utils/helpers";
import { AppDrawer } from "components/AppDrawer";
import { TimeFilter } from "components/TimeFilter";
import clsx from "clsx";
import device_ico from "assets/images/icons/device.svg";
import calendar_ico from "assets/images/icons/calendar.svg";
import share_ico from "assets/images/icons/share-network.svg";
import x_ico from "assets/images/icons/x-no-outline.svg";
import { ChannelNames } from "./ChannelSelect/ChannelSelect";

type Props = {
  apps: App[];
  channelIds: string[];
  selectedAppIds: number[];
  customerApp?: App;
  handleUpdateFilter: HandleOverviewFilterChangeType;
  daysAgo: number;
  customRange: boolean;
  customRangeTime: Moment[];
};

const MAX_APPS_TO_DISPLAY = 3;

export const MarketingOverviewFilters = ({
  apps,
  selectedAppIds,
  handleUpdateFilter,
  customRange,
  customRangeTime,
  daysAgo,
  channelIds,
  customerApp,
}: Props) => {
  const [timeFrameOpen, setTimeFrameOpen] = useState(false);
  const [channelOpen, setChannelOpen] = useState(false);
  const [appsOpen, setAppsOpen] = useState(false);
  const isMobile = isMobileDevice();

  const appsList = apps.map((app) => ({
    id: app.id,
    name: app.displayName,
    thumbnail: app.icon,
  }));

  const selectedApps =
    selectedAppIds.length > 0
      ? apps.filter((app) => selectedAppIds.includes(app.id))
      : [];

  const timeFrameStartDate = customRange
    ? customRangeTime[0]
    : moment().subtract(daysAgo, "days");
  const timeFrameEndDate = customRange ? customRangeTime[1] : moment();

  const handleSelectApp = (app: { id: number }) => {
    const appId = app.id.toString();
    const appIdStrings = selectedAppIds.map((id) => id.toString());

    const selectedApps = apps.filter((app) =>
      appIdStrings.includes(app.id.toString())
    );
    trackMarketingOverviewEvent("overview_app_filter", {
      selected_app_names: selectedApps.map((app) => app.displayName),
    });

    if (appIdStrings.includes(appId)) {
      handleUpdateFilter(
        "app_id",
        appIdStrings.filter((id) => id !== appId)
      );

      return;
    }

    handleUpdateFilter("app_id", [...appIdStrings, appId]);
  };

  const handleSelectAllApps = () => {
    const appIds = apps.map((app) => app.id.toString());
    handleUpdateFilter("app_id", appIds);

    trackMarketingOverviewEvent("overview_app_filter", {
      selected_app_names: apps.map((app) => app.displayName),
    });
  };

  const handleClearAllApps = () => {
    handleUpdateFilter("app_id", []);

    trackMarketingOverviewEvent("overview_app_filter", {
      selected_app_names: [],
    });
  };

  const handleDaysAgoChange = (daysAgo: number) => {
    handleUpdateFilter("days_ago", daysAgo.toString());
    handleUpdateFilter("custom_range", "false");
    handleUpdateFilter("custom_range_time", []);
    setTimeFrameOpen(false);

    trackMarketingOverviewEvent("overview_time_interval_filter", {
      number_of_days: daysAgo,
    });
  };

  const handleCustomRangeChange = (customRange: boolean) => {
    handleUpdateFilter("custom_range", customRange.toString());
  };

  const handleCustomRangeChangeTimeChange = (args: Moment[]) => {
    const startDate = args[0].toISOString();
    const endDate = args[1].toISOString();

    trackMarketingOverviewEvent("overview_time_interval_filter", {
      number_of_days: moment(endDate).diff(moment(startDate), "days"),
    });

    handleUpdateFilter("days_ago", "");
    handleUpdateFilter("custom_range_time", [startDate, endDate]);
  };

  const handleChannelSelect = (channels: string[]) => {
    trackMarketingOverviewEvent("overview_channel_changed", {
      selected_channel_names: channels,
    });
    handleUpdateFilter("channel_id", channels);
  };

  const handleCurrentAppToggle = () => {
    const currentCustomerAppId = customerApp?.id.toString();
    if (!currentCustomerAppId) return;

    const appIds =
      selectedAppIds.length > 0
        ? selectedAppIds.map((id) => id.toString())
        : apps.map((app) => app.id.toString());

    const appIdsWithoutCurrentApp = appIds.filter(
      (id) => id !== currentCustomerAppId
    );
    const appIdsWithCurrentApp = [
      ...appIdsWithoutCurrentApp,
      currentCustomerAppId,
    ];

    trackMarketingOverviewEvent("overview_comparison_mode_changed", {
      state: appIds.includes(currentCustomerAppId) ? "off" : "on",
    });

    handleUpdateFilter(
      "app_id",
      appIds.includes(currentCustomerAppId)
        ? appIdsWithoutCurrentApp
        : appIdsWithCurrentApp
    );
  };

  return (
    <>
      <div className="mo-top-section">
        <div className="mo-heading-container">
          <h1 className="mo-heading">Marketing Overview</h1>
          <div className="mo-filters">
            <div className="mo-filters-container">
              <Popover
                placement="bottom"
                title={null}
                destroyTooltipOnHide={true}
                content={
                  <AppDrawer
                    appsList={appsList}
                    currentAppsIds={selectedApps.map((app) => app.id)}
                    onSelectApp={handleSelectApp}
                    onClearSelection={handleClearAllApps}
                    onSelectAll={handleSelectAllApps}
                    minAppsSelected={false}
                    closeDrawer={() => setAppsOpen(false)}
                  />
                }
                open={appsOpen}
                onOpenChange={(open) => setAppsOpen(open)}
                trigger="click"
                overlayClassName={clsx(
                  "popover-appdrawer  filter-wrap   all-discoveries all-discoveries-apps fixed-filters",
                  {
                    "mobile-full-screen": isMobile,
                  }
                )}
              >
                <div
                  className="select-filter"
                  style={{ minWidth: isMobile ? "24%" : "160px" }}
                >
                  <div className="select-body">
                    {!isMobile ? (
                      <span>
                        <img src={device_ico} />
                        {`Apps (${selectedApps.length})`}
                      </span>
                    ) : (
                      <>
                        <span>Apps</span>
                        {selectedApps.length > 0 && (
                          <div className="select-body-apps-count">
                            {selectedApps.length}
                          </div>
                        )}
                      </>
                    )}
                    <img src={caret_ico} />
                  </div>
                </div>
              </Popover>
              <Popover
                placement="bottom"
                title={null}
                destroyTooltipOnHide={true}
                content={
                  <ChannelSelect
                    onChannelSelect={handleChannelSelect}
                    selectedChannels={channelIds}
                    closeChannel={() => setChannelOpen(false)}
                  />
                }
                open={channelOpen}
                onOpenChange={(open) => setChannelOpen(open)}
                trigger="click"
                overlayClassName={clsx(
                  "filter-wrap all-discoveries all-discoveries-time fixed-filters",
                  {
                    "mobile-full-screen": isMobile,
                  }
                )}
                overlayStyle={{ width: "23rem" }}
              >
                <div
                  className="select-filter"
                  style={{ minWidth: isMobile ? "26%" : "105px" }}
                >
                  <div className="select-body">
                    {!isMobile ? (
                      <>
                        <img src={share_ico} />
                        <span>{`Channels (${channelIds.length})`}</span>
                        <img src={caret_ico} />
                      </>
                    ) : (
                      <>
                        <span>Channels</span>
                        {channelIds.length > 0 && (
                          <div className="select-body-apps-count">
                            {channelIds.length}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </Popover>
              <Popover
                placement="bottom"
                title={null}
                destroyTooltipOnHide={true}
                open={timeFrameOpen}
                onOpenChange={(open) => setTimeFrameOpen(open)}
                content={
                  <TimeFilter
                    isOpen
                    daysAgoList={daysAgoList.slice(0, -1)}
                    daysAgo={daysAgo}
                    daysAgoChange={handleDaysAgoChange}
                    customRange={customRange}
                    сustomRangeСhange={handleCustomRangeChange}
                    customRangeTime={customRangeTime}
                    customRangeTimeChange={handleCustomRangeChangeTimeChange}
                    oneDay={false}
                    onClose={() => setTimeFrameOpen(false)}
                  />
                }
                trigger="click"
                overlayClassName={clsx(
                  "filter-wrap all-discoveries all-discoveries-time fixed-filters",
                  {
                    "mobile-full-screen": isMobile,
                  }
                )}
                overlayStyle={{ width: isMobile ? "35rem" : "75rem" }}
              >
                <div
                  className="select-filter"
                  style={{ minWidth: isMobile ? "30%" : "180px" }}
                >
                  <div className="select-body">
                    <img src={calendar_ico} />
                    <span>
                      {`${timeFrameStartDate.format(
                        "MMM DD, YYYY"
                      )} - ${timeFrameEndDate.format("MMM DD, YYYY")}`}
                    </span>
                    <img src={caret_ico} style={{ marginLeft: "10px" }} />
                  </div>
                </div>
              </Popover>
              {customerApp && !isMobile && (
                <div className="mo-current-app">
                  <img className="mo-current-app-img" src={customerApp.icon} />
                  <Switch
                    checked={
                      selectedAppIds.includes(customerApp.id) ||
                      !selectedAppIds.length
                    }
                    className="mo-current-app-switch"
                    onChange={handleCurrentAppToggle}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="selected-filters">
        {selectedApps.map((selectedApp) => (
          <div className="selected-filter">
            <img src={selectedApp.icon} className="selected-icon" />
            <img
              src={x_ico}
              className="close-icon"
              onClick={() => {
                handleSelectApp({ id: selectedApp.id });
              }}
            />
          </div>
        ))}
        {channelIds.map((channel) => (
          <div className="selected-filter">
            <span>{ChannelNames[channel]}</span>
            <img
              src={x_ico}
              className="close-icon"
              onClick={() => {
                handleChannelSelect(channelIds.filter((c) => c !== channel));
              }}
            />
          </div>
        ))}
      </div>
    </>
  );
};
