import { takeEvery, call, fork, put } from 'redux-saga/effects';
import * as actionTypes from '../actions/_actionsTypes';
import * as directLinkAction from '../actions/directLinkActions';
import * as api from '../api/directLinkApi';

function* getLinkData(action) {
  try {
    const { link, guest_token } = action.payload;
    const result = yield call(api.getLinkData, { link, guest_token });
    yield put(directLinkAction.getLinkDataSuccess({
      data: {
        ...result.data.data,
        link_code:link,
      }
    }));
  } catch (e) {
    yield put(directLinkAction.getLinkDataFailed());
  }
}

function* watchGetLinkData() {
  yield takeEvery(actionTypes.GET_LINK_DATA, getLinkData);
}

function* createLinkData(action) {
  try {
    const { appId, email, insightId, domain_verification } = action.payload;
    const result = yield call(api.createLinkData, { appId, email, insightId, domain_verification });
    yield put(directLinkAction.createLinkDataSuccess({
      link: result.data.link,
      insightId: insightId
    }));
  } catch (e) {
    yield put(directLinkAction.createLinkDataFailed());
  }
}

function* updateLinkData(action) {
  try {
    const { link, domain_verification } = action.payload;
    const result = yield call(api.updateLinkDataAPI, { link, domain_verification });
    yield put(directLinkAction.updateLinkDataSuccess({
      link: result.data,
    }));
  } catch (e) {
  }
}

function* getRecipientsSaga() {
  const result = yield call(api.getRecipientsAPI);
  yield put(directLinkAction.getRecipientsSuccess(result.data))
}

function* sendShareEmailSaga(action) {
  const result = yield call(api.sendShareEmailAPI, action.payload);
  yield put(directLinkAction.sendShareEmailSuccess(result.data))
}

function* sendShareBiWeeklyEmailSaga(action) {
  const result = yield call(api.sendShareBiWeeklyEmailAPI, action.payload);
  yield put(directLinkAction.sendShareBiWeeklyEmailSuccess(result.data))
}

// -------------------

function* watchCreateLinkData() {
  yield takeEvery(actionTypes.CREATE_LINK_DATA, createLinkData);
}

function* watchUpdateLinkData() {
  yield takeEvery(actionTypes.UPDATE_LINK_DATA, updateLinkData);
}

function* watchGetRecipients() {
  yield takeEvery(actionTypes.GET_RECIPIENTS, getRecipientsSaga);
}

function* watchSendShareEmail() {
  yield takeEvery(actionTypes.SEND_SHARE_EMAIL, sendShareEmailSaga);
}

function* watchSendShareBiWeeklyEmail() {
  yield takeEvery(actionTypes.SEND_SHARE_BIWEEKLY_EMAIL, sendShareBiWeeklyEmailSaga);
}

const directLinkSagas = [
  fork(watchGetLinkData),
  fork(watchUpdateLinkData),
  fork(watchCreateLinkData),
  fork(watchGetRecipients),
  fork(watchSendShareEmail),
  fork(watchSendShareBiWeeklyEmail),
];

export default directLinkSagas;


