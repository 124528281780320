import React, { FC, ReactElement } from 'react';
import "./legal.scss";
import {Link} from "react-router-dom";
interface IProps {

}

const Eula: FC<IProps> = ({

}): ReactElement => {

  return (
    <div className="eula-wrapper">
      <h1 className="lean">End User <strong className="fat">License Agreement</strong></h1>
      <p className={`header large-font`}>
        IMPORTANT – READ CAREFULLY BEFORE DOWNLOADING OR USING THE SOLUTION.
      </p>
      <p>
        This End User License Agreement (“EULA”) is a binding legal agreement between you (“User”) and Watchful Technologies Ltd. (the “Company”, “Watchful”, “we” and “us”) which sets forth the terms and conditions under which Client is entitled to use the Solution that has been developed and is owned by Watchful.
      </p>
      <p>
            BY ACCESSING AND/OR USING THE SOLUTION, THE USER DECLARES THAT IT/HE/SHE HAS CAREFULLY READ, UNDERSTANDS AND ACCEPTS THE TERMS OF THIS EULA AND AGREES TO BE LEGALLY BOUND BY ITS TERMS, INCLUDING THOSE INCORPORATED BY REFERENCE HERETO. IF THE USER DOES NOT AGREE TO THESE TERMS, IT/HE/SHE MUST REFRAIN FROM USING THE SOLUTION IN ANY CAPACITY OR MANNER.
      </p>
      <p>
            FURTHERMORE, BY ACCEPTING THE TERMS OF THIS EULA, THE USER HEREBY WAIVES, IRREVOCABLY, ANY RIGHTS OR REQUIREMENTS UNDER ANY LAWS OR REGULATIONS IN ANY JURISDICTION WHICH REQUIRE AN ORIGINAL (NON-ELECTRONIC) SIGNATURE OR DELIVERY OR RETENTION OF NON-ELECTRONIC RECORDS, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.
      </p>
      <p>
            IF YOU ARE USING THIS SOLUTION ON BEHALF OF OR AT THE DIRECTION OF A WATCHFUL CLIENT, YOU WARRANT AND REPRESENT THAT YOU ARE AUTHORIZED BY SUCH CLIENT TO ACCESS THE SOLUTION, TO ACT ON BEHALF OF THE CLIENT AND TO  BE BOUND BY THE TERMS OF THIS EULA AND TO ANY OTHER TERMS INCORPORATED BY REFERENCE HERETO.
      </p>
      <div className={'place-holder'}/>
      <p>
        <strong>
              1. Definitions
        </strong>

      </p>
      <p className="move-right">
        <div className="letter-margin">(A)</div>  <div><strong>“Client” </strong> refers to an enterprise client that has entered into an MSA with Watchful in connection with Watchful’s provisioning of the SOLUTION.</div>
      </p>
      <p className="move-right">
        <div className="letter-margin">(B)</div> <div><strong>“Client Content” </strong> refers to any content submitted or uploaded to, or transmitted through, the SOLUTION, or otherwise provided or made available to Watchful, by or on behalf of a Client.</div>
      </p>
      <p className="move-right">
        <div className="letter-margin">(C)</div> <div><strong>“MSA”</strong> refers to that certain binding master services agreement (or other similar agreement) executed between the Company and Client in connection with the Company’s provisioning, and such Client’s use of the SOLUTION.</div>
      </p>
      <p className="move-right">
        <div className="letter-margin">(D)</div>  <div><strong>“Platform”</strong> refers to a web-based portal and its backend providing access to an operational intelligence interface that is integrated with the SOLUTION.</div>
      </p>
      <p className="move-right">
        <div className="letter-margin">(E)</div> <div><strong>“SOLUTION”</strong> refers to Watchful’s proprietary mobile research software-as-a-service platform, comprised of the programs, modules, data or components used or developed by Watchful (together with any changes, enhancements, extensions, adaptations, components, additions, patches, improvements and applications thereof now existing and hereafter devised).</div>
      </p>
      <p className="move-right">
        <div className="letter-margin">(F)</div> <div><strong>“Solution Data”</strong> refers to the data generated from a Client and its Users’ authorized use of the SOLUTION.</div>
      </p>
      <p className="move-right">
        <div className="letter-margin">(G)</div> <div><strong>“Support Services”</strong> refers to those support services provided by Watchful in connection with a Client’s right to access and use of the SOLUTION.</div>
      </p>
      <p className="move-right">
        <div className="letter-margin">(H)</div> <div><strong>“Third-Party Content”</strong> means content provided by a third party, that interoperates with the SOLUTION, including open source software, but that is not embedded in or required for use of the SOLUTION.</div>
      </p>
      <p className="move-right">
        <div className="letter-margin">(I)</div> <div><strong>“User/s”</strong> refers to any individual given access rights by a Client to use the Solution on such Client’s behalf, and who is is assigned a Watchful User Account.</div>
      </p>
      <p className="move-right">
        <div className="letter-margin">(J)</div> <div><strong>“Usage Statistics”</strong> refers to any non-identifying, anonymized and encrypted information, data, reporting, suggestions, analyses, and/or intelligence relating to the operation, support, and/or use, of the SOLUTION (such as metadata, aggregated data, analytics, security findings or discoveries, etc.).</div>
      </p>
      <p className="move-right">
        <div className="letter-margin">(K)</div> <div><strong>“Watchful Content”</strong> refers to any content (excluding Client Content) appearing on or in, or otherwise provided or made available via, the SOLUTION.</div>
      </p>
      <p className="move-right">
        <div className="letter-margin">(L)</div> <div><strong>“Watchful User Account” </strong> refers to each issuance of a unique username and password enabling authenticated access to the SOLUTION for a User of the Solution.</div>
      </p>
      <p>
        <strong>
        2. Grant of License
        </strong>
      </p>
      <p className="move-right">
        <div className="letter-margin">(A)</div> <div>Watchful hereby grants Client and each of its User a limited, non-exclusive, non-transferable, non-sublicensable license during the Term (as defined below) to Access and use the SOLUTION in accordance with this EULA and any applicable usage rules implemented by Watchful from time to time during the Term.</div>
      </p>
      <p className="move-right">
        <div className="letter-margin">(B)</div> <div>For the avoidance of any doubt, it is hereby declared and agreed that the SOLUTION and Watchful Content is merely licensed to Client and its Users, but not sold. Watchful may, but is not obligated to, audit and monitor a Client and its Users use of the SOLUTION and Watchful Content.</div>
      </p>
      <p className="move-right">
        <div className="letter-margin">(C)</div> <div>Any documentation that accompanies the SOLUTION is licensed for internal, non-commercial and reference purposes only.</div>
      </p>
      <p className="move-right">
        <div className="letter-margin">(D)</div> <div>Watchful shall provide Client with the Support Services in connection with its and its Users’ use of the SOLUTION as described in the service level agreement contained within the MSA.</div>
      </p>
      <p>
        <strong>
        3. Account
        </strong>
      </p>
      <p className="move-right">
        <div>
            To access the SOLUTION, each User must set up a Watchful User Account. Users shall ensure that all information submitted during their registration process is, and will thereafter remain, complete and accurate. As between Watchful and Client, Client shall be solely responsible and liable for maintaining the confidentiality and security of its Users’ User Account credentials, as well as for all activities that occur under or in such Watchful User Accounts.
          Personal Data (as defined in Watchful’s Privacy Policy available at <Link to={"/privacy"}>https://app.watchful.ai/privacy</Link> – the “Privacy Policy”) collected by Watchful in connection with the foregoing, shall be processed in accordance with the then-current Privacy Policy, which is hereby incorporated into this EULA by reference.
        </div>
      </p>
      <p>
        <strong>
        4. Reservation of Rights and Ownership
        </strong>
      </p>
      <p className="move-right">
        <div className="letter-margin">(A)</div> <div>Watchful Protected Material</div>
      </p>
      <p className="move-right">
        <p className="move-right" style={{ margin:"0rem 0rem 0rem 5rem" }}>
          <div className="letter-margin">1.</div> Watchful (and/or its licensors and suppliers, as applicable) is, and shall be, the sole and exclusive owner of all right, title and interest (including without limitation all Intellectual Property Rights) in and to Watchful protected material, which include: (a) the SOLUTION; (b) Watchful Content; (c) Watchful’s Confidential Information (as defined below); (d) any feedback, suggestions, or ideas for or about the SOLUTION or Watchful Content (collectively, “Feedback”); (e) Usage Statistics; and (f) any improvements, derivative works, and/or modifications of/to any of the foregoing, regardless of inventorship or authorship (collectively “Watchful Protected Materials”). For the avoidance of doubt, it is acknowledged and agreed that Watchful (alone and/or together with its affiliates and service providers) may generate and commercially exploit Usage Statistics, as well as use Client Content for the purpose of enhancing the SOLUTION, and nothing in this EULA shall be deemed to prohibit or otherwise limit such activities.
        </p>
      </p>
      <p className="move-right">
        <p className="move-right" style={{ margin:"0rem 0rem 0rem 5rem" }}>
          <div className="letter-margin">2.</div> Client and its Users shall make, and hereby irrevocably makes, all assignments and/or waivers necessary or reasonably requested by Watchful to ensure and/or provide Watchful (and/or its designee(s)) the ownership rights set forth in this paragraph.
        </p>
      </p>

      <p className="move-right">
        <div className="letter-margin">(B)</div> <div>Client Content</div>
      </p>
      <p className="move-right">
        <p className="move-right" style={{ margin:"0rem 0rem 0rem 5rem" }}>
          <div className="letter-margin">1.</div> As between the Parties, Client is, and shall be, the sole and exclusive owner of all Client Content.
        </p>
      </p>
      <p className="move-right">
        <p className="move-right" style={{ margin:"0rem 0rem 0rem 5rem" }}>
          <div className="letter-margin">2.</div> Unless the SOLUTION specifically requests otherwise, Client shall ensure that no Client Content includes or links to sensitive personal data (as such term is defined in the applicable jurisdiction).
        </p>
      </p>
      <p className="move-right">
        <p className="move-right" style={{ margin:"0rem 0rem 0rem 5rem" }}>
          <div className="letter-margin">3.</div> Client represents and warrants that: (a) no processing of Client Content under this EULA (whether by Watchful, its affiliates, or the hosting providers) will violate any law or regulation, proprietary right, or privacy right; and (b) it has obtained and will maintain all required consents and licenses, and will maintain all ongoing legal basis under relevant privacy laws or regulations (if applicable), necessary to provide, make available, and otherwise expose Client Content to Watchful, its affiliates, and the hosting providers.
        </p>
      </p>
      <p className="move-right">
        <p className="move-right" style={{ margin:"0rem 0rem 0rem 5rem" }}>
          <div className="letter-margin">4.</div> The SOLUTION is not intended to, and will not, operate as a data storage or archiving product or service, and Client agrees not to rely on the SOLUTION for the storage of any Client Content whatsoever. Client is solely responsible and liable for the maintenance and backup of all Client Content.
        </p>
      </p>
      
      <p>
        <strong>
                5. Intellectual Property Rights
        </strong>
      </p>
      <p className="move-right">
        <div className="letter-margin">(A)</div> <div>The SOLUTION, in whole or in part, as well as any extracts or output that Users produce when using the SOLUTION and any other Watchful Protected Material are and shall continue to be owned exclusively by Watchful, including, but not limited, to its structure, organization and code which constitute valuable trade secrets of Watchful. No User shall receive in any manner whatsoever, under any circumstances whatsoever, any source code of or relating to any SOLUTION included in the Watchful Protected Material. It shall also be emphasized that this EULA does not grant Client a license in any source code associated with the SOLUTION.</div>
      </p>
      <p className="move-right">
        <div className="letter-margin">(B)</div> <div>Client hereby declares and agrees that this EULA does not entitle Client to any intellectual property rights including, but not limited to, patents, copyrights, trade- secrets, trademarks or any other rights relating to the SOLUTION, its source code and the Watchful Protected Material.</div>
      </p>
      <p className="move-right">
        <div className="letter-margin">(C)</div> <div>Client further declares and agrees to refrain from copying, modifying, adapting, translating or making derivative works based upon the Watchful Protected Material. Client and its Uers shall also refrain from reverse engineering, decompiling, disassembling or attempting in any other manner whatsoever to discover the source code of the SOLUTION.</div>
      </p>
      <p className="move-right">
        <div className="letter-margin">(D)</div> <div>The Watchful trademarks, service marks and logos are trademarks owned by Watchful. This EULA does not grant Client any rights (including, but not limited to, intellectual property rights), license, title or interest to these trademarks, and Client together with its Users shall refrain from claiming any such rights, license, title or interest with respect to these trademarks. The use of any of these trademarks without the prior written consent of Watchful is strictly prohibited.</div>
      </p>
      <p className="move-right">
        <div className="letter-margin">(E)</div> <div>Any other trademarks, service marks, product names and company names or logos, directly or by reference, are the sole properties of their respective owners. Use of any of these marks, names and logos without the prior written consent of their owners is strictly prohibited.</div>
      </p>
      <p>
        <strong>
        6. Client’s Responsibilities, Restrictions and Obligations
        </strong>
      </p>
      <p className="move-right">
        <div className="letter-margin">(A)</div> <div>Client shall be solely responsible:</div>
      </p>
      <p className="move-right">
        <p className="move-right" style={{ margin:"0rem 0rem 0rem 5rem" }}>
          <div className="letter-margin">1.</div> For selecting qualified Users to use the SOLUTION.
        </p>
      </p>
      <p className="move-right">
        <p className="move-right" style={{ margin:"0rem 0rem 0rem 5rem" }}>
          <div className="letter-margin">2.</div>  For any activities occurring during the use of the SOLUTION by the Users, whether such activity is made through one of the Watchful User Accounts.
        </p>
      </p>
      <p className="move-right">
        <p className="move-right" style={{ margin:"0rem 0rem 0rem 5rem" }}>
          <div className="letter-margin">3.</div>  For using the SOLUTION and Watchful Content for lawful purposes only. Accordingly, Client shall not do anything that encourages conduct which would constitute a criminal offense or which encourages or might encourage “hacking” or “cracking,” or which might give rise to a civil liability or otherwise violate any applicable local or international laws or regulations.
        </p>
      </p>
      <p className="move-right">
        <div className="letter-margin">(B)</div> <div>Client declares and warrants that Watchful shall not be held liable for any network-related problems that can be attributed to the operation of the SOLUTION. Client also acknowledges that internet and network configuration changes might affect the SOLUTION’s performance and accessibility.</div>
      </p>
      <p className="move-right">
        <div className="letter-margin">(C)</div> <div>Client’s obligations under this EULA include compliance with all applicable national and foreign laws, treaties and regulations relating to or arising from each User’s use of the SOLUTION, including, without limitation, those related to the protection of intellectual property, data privacy, and the transmission of technical or personal data. Furthermore, Client shall notify Watchful immediately of any unauthorized known or suspected use of any User Account and of any other known or suspected breach of security, and shall use reasonable efforts to immediately stop any known or suspected unauthorized copying or distribution of content.</div>
      </p>

      <p className="move-right">
        <div className="letter-margin">(D)</div> <div>The following restrictions shall also apply to Client’s use of the SOLUTION:</div>
      </p>
      <p className="move-right">
        <p className="move-right" style={{ margin:"0rem 0rem 0rem 5rem" }}>
          <div className="letter-margin">1.</div> Client’s Users shall refrain from accessing the SOLUTION and Watchful Content in order to (a) create a competitive product or service; (b) create a product using similar ideas, features, functions or graphics of the SOLUTION; or (c) copy any ideas, features, functions or graphics of the SOLUTION.
        </p>
      </p>

      <p className="move-right">
        <p className="move-right" style={{ margin:"0rem 0rem 0rem 5rem" }}>
          <div className="letter-margin">2.</div> Client shall not be entitled to: (i) license, sublicense, sell, resell, transfer, assign, distribute or commercially exploit or make the SOLUTION or Watchful Content available to any third party, in any manner whatsoever; or (ii) create Internet “links” to the SOLUTION or “frame” or “mirror” any content thereof on any other server, wireless or Internet-based device.
        </p>
      </p>
      <p className="move-right">
        <p className="move-right" style={{ margin:"0rem 0rem 0rem 5rem" }}>
          <div className="letter-margin">3.</div> During Client’s use of the SOLUTION, no Users shall (i) send spam or otherwise distribute duplicative or unsolicited messages that violates any applicable laws; (ii) send or store infringing, obscene (including pornography, violence, terror, etc.), threatening, libelous, or otherwise unlawful or tortuous material, including, but not limited to, material harmful to children or violating privacy rights of a third party; (iii) send or store material containing viruses, worms, trojan horses or other harmful computer code, files, scripts, or programs; (iv) attempt to gain unauthorized access to the SOLUTION or its related systems or networks; (vi) violate or cause Watchful to violate any applicable law in any manner whatsoever; or (vii) use the SOLUTION for any other purpose than for Client’s reasonable internal business purposes. Furthermore, Client acknowledges that Watchful assumes no responsibility for monitoring each User’s use of the SOLUTION for inappropriate content or conduct.
        </p>
      </p>
      <p className="move-right">
        <p className="move-right" style={{ margin:"0rem 0rem 0rem 5rem" }}>
          <div className="letter-margin">4.</div> Client shall not transfer any of its rights and obligations under this EULA.
        </p>
      </p>

      <p>
        <strong>
            7. Rights regarding Data
        </strong>
      </p>
      <p className="move-right">
        <div className="letter-margin">(A)</div> <div>Any and all Solution Data generated from Client’s use of the SOLUTION shall be the property of Client.  Notwithstanding the foregoing, Client may not resell, distribute or otherwise provide any (non-affiliate) third party access to the Solution Data without Watchful’s express prior written consent.</div>
      </p>
      <p className="move-right">
        <div className="letter-margin">(B)</div> <div>Client’s rights in the Solution Data notwithstanding, Watchful may use any and all portions of the Solution Data which are aggregated and anonymized without restrictions or limitations of any kind, without any payment due and owing to Client, and without requiring Client’s consent.</div>
      </p>
      <p>
        <strong>
                8. Warranties and Disclaimers
        </strong>
      </p>
      <p className="move-right">
        <div className="letter-margin">(A)</div> <div>THE SOLUTION MAY CONTAIN ERRORS, BUGS AND OTHER PROBLEMS WHICH COULD CAUSE DAMAGES SUCH AS, BUT NOT LIMITED TO, SYSTEM CRASH OR FAILURE. WATCHFUL RESERVES THE RIGHT TO ALTER THE SOLUTION AT ANY TIME ACCORDING TO ITS SOLE DISCRETION, AND ANY RELIANCE ON THE SOLUTION OR ITS QUALITY OR PERFORMANCE SHALL BE AT THE CLIENT’S SOLE RISK. EXCEPT AS EXPRESSLY SET FORTH IN THIS EULA AND ANY OTHER TERMS REFERENCED BY THIS EULA, THE SOLUTION AND SUPPORT SERVICES (IF ANY) ARE PROVIDED TO YOU ON AN “AS-IS” BASIS WITH ALL FAULTS. WATCHFUL HEREBY DISCLAIMS ALL OTHER WARRANTIES AND CONDITIONS, WHETHER EXPRESSED, IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, WARRANTIES AND CONDITIONS OF TITLE OR IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, OR RELIABILITY OR AVAILABILITY, OF ACCURACY OR COMPLETENESS OF RESPONSES, OF RESULTS, OF WORKMANLIKE EFFORT, OF LACK OF VIRUSES, AND OF LACK OF NEGLIGENCE ALL WITH REGARD TO THE SOLUTION AND THE PROVISION OR FAILURE TO PROVIDE SUPPORT OR OTHER SERVICES, INFORMATION, SOLUTION AND RELATED CONTENT THROUGH THE SOLUTION OR OTHERWISE ARISING OUT OF THE USE OF THE SOLUTION. ALSO, THERE IS NO WARRANTY OR CONDITION OF CORRESPONDENCE TO DESCRIPTION NO ORAL OR WRITTEN ADVICE OR INFORMATION PROVIDED BY WATCHFUL, OR ANYONE ON ITS BEHALF, INCLUDING, BUT NOT LIMITED TO, ITS OFFICERS, DIRECTORS, MEMBERS, MANAGERS, EMPLOYEES, AFFILIATES, AGENTS, LICENSORS, CONTRACTORS OR THE LIKE SHALL CREATE OR CONSTITUTE ANY FORM OF WARRANTY. ACCORDINGLY, CLIENT AGREES THAT THE USE OF THE SOLUTION IS AT ITS SOLE RISK, AND THAT NEITHER WATCHFUL NOR ANYONE ON ITS BEHALF, DO NOT AND CAN NOT WARRANT OR BE HELD LIABLE FOR THE PERFORMANCE OR RESULTS THAT CLIENT MAY OBTAIN BY USING THE SOLUTION NOR THAT THE OPERATION OF THE SOLUTION WILL BE ERROR FREE OR MEET ANY REQUIREMENTS.</div>
      </p>
      <p className="move-right">
        <div className="letter-margin">(B)</div> <div>Watchful hereby represents and warrants that:</div>
      </p>
      <p className="move-right">
        <p className="move-right" style={{ margin:"0rem 0rem 0rem 5rem" }}>
          <div className="letter-margin">1.</div>it shall take reasonable measures to prevent unauthorized access to the SOLUTION, and maintain data accuracy and appropriate use of the SOLUTION;
        </p>
      </p>
      <p className="move-right">
        <p className="move-right" style={{ margin:"0rem 0rem 0rem 5rem" }}>
          <div className="letter-margin">2.</div>Watchful will discharge its obligations under the EULA with reasonable skill, care and diligence including Industry practice and in accordance with its own established internal procedures as applicable; and
        </p>
      </p>
      <p className="move-right">
        <p className="move-right" style={{ margin:"0rem 0rem 0rem 5rem" }}>
          <div className="letter-margin">3.</div> Watchful has the right to grant Client the license and right to use the SOLUTION, and, to Watchful’s knowledge, the SOLUTION does not infringe upon the intellectual property right of any third party.
        </p>
      </p>
      <p className="move-right">
        <p className="move-right" style={{ margin:"0rem 0rem 0rem 5rem" }}>
          <div className="letter-margin">4.</div> Watchful’s sole liability for any breach of this warranty shall be, in Watchful’s sole discretion: (i) to replace or repair the defective SOLUTION; or (ii) to refund the price paid for the SOLUTION under the MSA based on a pro rata basis for the period the SOLUTION has not been used by Client. The foregoing warranties are contingent upon Client’s proper use of the SOLUTION, and shall not apply to damage caused by abuse, misuse, alteration, neglect, or unauthorized repair or installation, or by the use or attempted use of the SOLUTION other than that supplied and supported by Watchful. Only if Client informs Watchful of the defect in writing within ten (10) days of delivery, and provides evidence of the date the License was delivered, will Watchful be obligated to honor this warranty. Watchful will use reasonable commercial efforts to repair, replace or refund pursuant to the foregoing warranty within thirty (30) days of being so notified. The foregoing constitutes Client’s sole and exclusive remedy for breach by Watchful of any warranties made under this EULA.
        </p>
      </p>

      <p className="move-right">
        <div className="letter-margin">(C)</div> <div>THE WARRANTIES SET FORTH ABOVE ARE EXCLUSIVE AND IN LIEU OF ALL OTHER WARRANTIES, EXPRESS, IMPLIED OR STATUTORY, WITH RESPECT TO THE SOLUTION, MAINTENANCE SERVICES OR ANY OTHER ITEMS PROVIDED HEREUNDER. WATCHFUL DOES NOT WARRANT THAT THE SOLUTION OR ANY SUPPORT SERVICES, WILL BE DELIVERED OR PERFORMED ERROR-FREE OR WITHOUT INTERRUPTION.</div>
      </p>
      <p>
        <strong>
        9. Limitation of Liability
        </strong>
      </p>
      <p className="move-right">
        <div className="letter-margin">(A)</div> <div>TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL WATCHFUL OR ANYONE ON ITS BEHALF BE HELD LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, CONSEQUENTIAL, SPECIAL, OR EXEMPLARY DAMAGES INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA, CONFIDENTIAL OR OTHER INFORMATION OR OTHER INTANGIBLE LOSSES, FOR CORRUPTION OF DATA, FOR ANY COMPUTER FAILURE, FOR BUSINESS INTERRUPTION, FOR PERSONAL INJURY, FOR LOSS OF PRIVACY, FOR FAILURE TO MEET ANY DUTY INCLUDING OF GOOD FAITH OR OF REASONABLE CARE, FOR NEGLIGENCE, AND FOR ANY OTHER PECUNIARY OR OTHER LOSS WHATSOEVER) (EVEN IF WATCHFUL HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (I) THE USE OR THE INABILITY TO USE THE SOLUTION; (II) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE SOLUTION; (III) UNAUTHORIZED ACCESS TO OR ALTERATION OF CLIENT TRANSMISSIONS OR DATA GENERATED IN CONNECTION WITH THE SOLUTION; (IV) STATEMENTS OR CONDUCT OF ANY THIRD PARTY GENERATED IN CONNECTION WITH THE SOLUTION; OR (V) ANY OTHER MATTER RELATING TO THE SOLUTION.</div>
      </p>
      <p className="move-right">
        <div className="letter-margin">(B)</div> <div>NEITHER WATCHFUL NOR ANYONE ON ITS BEHALF INVOLVED IN CREATING, PRODUCING, MARKETING OR DISTRIBUTING THE SOLUTION, SHALL BE HELD LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES RESULTING FROM USE OF OR INABILITY TO USE THE SOLUTION DUE TO ANY CAUSE WHATSOEVER, INCLUDING ACTS OF GOD, COMMUNICATIONS FAILURE, THEFT, DESTRUCTION OR UNAUTHORIZED ACCESS TO RECORDS OR THE SOLUTION.</div>
      </p>
      <p className="move-right">
        <div className="letter-margin">(C)</div> <div>IN NO EVENT SHALL THE TOTAL LIABILITY OF WATCHFUL AND ANYONE ON ITS BEHALF FOR ALL DAMAGES, LOSSES, CLAIMS AND COSTS, WHETHER IN CONTRACT, TORT OR OTHERWISE, EXCEED THE AGGREGATE AMOUNT PAID BY CLIENT TO WATCHFUL DURING THE IMMEDIATELY PRECEDING PERIOD OF 12 MONTHS.</div>
      </p>
      <p className="move-right">
        <div className="letter-margin">(D)</div> <div>THE FOREGOING EXCLUSIONS AND LIMITATION SHALL APPLY: (A) TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW; (B) EVEN IF A PARTY HAS BEEN ADVISED, OR SHOULD HAVE BEEN AWARE, OF THE POSSIBILITY OF LOSSES, DAMAGES, OR COSTS; (C) EVEN IF ANY REMEDY IN THIS EULA FAILS OF ITS ESSENTIAL PURPOSE; AND (D) REGARDLESS OF THE THEORY OR BASIS OF LIABILITY, AND WHETHER IN CONTRACT, TORT (INCLUDING WITHOUT LIMITATION FOR NEGLIGENCE OR BREACH OF STATUTORY DUTY), STRICT LIABILITY, MISREPRESENTATION, RESTITUTION, OR OTHERWISE.</div>
      </p>
      <p>
        <strong>
        10. Confidentiality and Privacy
        </strong>
      </p>
      <p className="move-right">
        <div className="letter-margin">(A)</div> <div>The collection, use and retention of Client’s confidential information pursuant to this EULA is according to the privacy statement which are set forth on Watchful website: https://www.Watchful.com/privacy-policy/ and constitutes an integral part of this EULA.</div>
      </p>

      <p className="move-right">
        <div className="letter-margin">(B)</div> <div>Client is aware that Watchful’s SOLUTION includes features which may, in different jurisdictions, trigger certain legal obligations in regards to privacy and data protection. Client undertakes to use Watchful’s SOLUTION in complete accordance with all the relevant privacy laws and regulations in the relevant jurisdictions where it acts and resides.</div>
      </p>
      <p className="move-right">
        <div className="letter-margin">(C)</div> <div>The SOLUTION contains confidential information and trade secrets that Watchful developed through the expenditure of a great deal of time and money (“Confidential Information”). Client shall use the Confidential Information solely in accordance with this EULA. This EULA does not transfer to Client in any manner whatsoever any rights including, without limitation, intellectual property rights in the Confidential Information and the Confidential Information shall remain the exclusive property of Watchful. Client shall maintain in strict confidence and refrain from using any information contained in the SOLUTION or Watchful Content in any manner whatsoever, whether by itself or through others, and to refrain from transferring and/or publishing and/ or showing and/or disclosing the Information to any third party, whether directly or indirectly. In addition, Client shall not allow any person, corporate or other third party to copy and/ or duplicate and/ or use the Information, in any manner whatsoever. Any information that is in the public domain would not constitute as confidential information.</div>
      </p>
      <p className="move-right">
        <div className="letter-margin">(D)</div> <div>Client’s undertakings, as stated in this provision, are irrevocable and shall remain in full force and effect at all times, without limitation. Client shall compensate Watchful for all damages arising out of or resulting in any way from Client’s breach of any warranty as provided herein or otherwise provided by law.</div>
      </p>
      <p className="move-right">
        <div className="letter-margin">(E)</div> <div>Client hereby agrees that Watchful may list Client as a customer and reproduce Client’s logo and registered trademark online or in printed materials solely to indicate that Client is or was a client and authorized user of the SOLUTION, unless and until Client notifies Watchful in writing of its refusal of such publicity.</div>
      </p>
      <p>
        <strong>
        11. Term, Termination and Effect of Termination
        </strong>
      </p>
      <p className="move-right">
        <div className="letter-margin">(A)</div> <div>This EULA shall become effective as of the effective date of the MSA and shall be coterminous therewith (the “Term”).  Accordingly, upon any termination of the MSA, this EULA shall likewise immediately terminate.</div>
      </p>
      <p className="move-right">
        <div className="letter-margin">(B)</div> <div>Without derogating from Watchful’s rights under the MSA, Watchful shall also be entitled to terminate this EULA by written notice with immediate effect upon the following events (“Termination for Cause”):</div>
      </p>
      <p className="move-right">
        <p className="move-right" style={{ margin:"0rem 0rem 0rem 5rem" }}>
          <div className="letter-margin">1.</div> A breach of any of the Client’s obligations under this EULA, which breach has not been cured within 5 days from the date the received a written notice from Watchful specifying such breach.
        </p>
      </p>
      <p className="move-right">
        <p className="move-right" style={{ margin:"0rem 0rem 0rem 5rem" }}>
          <div className="letter-margin">2.</div> Current or future, governmental regulations prevent the continued delivery of Watchful’s Services to Client under this EULA.
        </p>
      </p>
      <p className="move-right">
        <div className="letter-margin">(C)</div> <div>Upon termination of this EULA, for any cause whatsoever, the following terms shall apply:</div>
      </p>
      <p className="move-right">
        <p className="move-right" style={{ margin:"0rem 0rem 0rem 5rem" }}>
          <div className="letter-margin">1.</div> Any rights granted to Client under this EULA, shall terminate automatically.
        </p>
      </p>
      <p className="move-right">
        <p className="move-right" style={{ margin:"0rem 0rem 0rem 5rem" }}>
          <div className="letter-margin">2.</div> Client shall remove and destroy any copies of the SOLUTION accessed by its Users.
        </p>
      </p>
      <p className="move-right">
        <p className="move-right" style={{ margin:"0rem 0rem 0rem 5rem" }}>
          <div className="letter-margin">3.</div> Any bankruptcy filed by Client.
        </p>
      </p>
      <p>
        <strong>
                12. Miscellaneous
        </strong>
      </p>
      <p className="move-right">
        <div className="letter-margin">(A)</div> <div><strong>No Waiver.</strong> No delay or omission on the part of Watchful in exercising any right or remedy shall operate as a waiver of such right or remedy or any other right or remedy. A waiver on one occasion shall not be construed as a bar to or a waiver of any right on any future occasion.</div>
      </p>
      <p className="move-right">
        <div className="letter-margin">(B)</div> <div><strong>Governing Law.</strong> This EULA shall be governed, construed, interpreted and enforced according to the laws set forth in the MSA, and any dispute arising hereunder shall be adjudicated in the same jurisdiction as expressly referenced in the MSA.</div>
      </p>
      <p className="move-right">
        <div className="letter-margin">(C)</div> <div><strong>Severability.</strong> In the event that any provision of this EULA or any provisions incorporated by reference to this EULA shall be held by a competent court to be void, invalid, unenforceable or illegal the remaining provisions shall continue in full force and effect.</div>
      </p>
      <p className="move-right">
        <div className="letter-margin">(D)</div> <div><strong>Notices.</strong> Any Notice to be given by either party pursuant to this EULA shall be sent to the notice details set forth in the MSA.</div>
      </p>
      <p className="move-right">
        <div className="letter-margin">(E)</div> <div><strong>Entire EULA.</strong> This EULA, including any provisions incorporated by reference, constitutes the entire agreement with respect to the matters contemplated herein to Watchful’s provision of Services to Client, and shall supersede all prior oral or written communications, understandings and representations with respect to the SOLUTION and to Watchful’s provision of services to Client. In the event that the terms of the MSA and/or any Watchful policies or terms and conditions conflict with the terms of this EULA, including any provisions incorporated by reference to this EULA, the terms of this EULA including any provisions incorporated by reference to this EULA shall prevail.</div>
      </p>
      <p className="move-right">
        <div className="letter-margin">(F)</div> <div><strong>Modifications.</strong> Watchful may change in its sole discretion the terms of this EULA, from time to time, by posting a modified copy of the EULA on Watchful’s website, or by otherwise notifying Client. The changes will be effective and binding as from the date that the notice will be posted; unless a different effective date is specified. Therefore, Watchful recommends to enter, from time to time, to Watchful’s website in order to review information concerning such modifications. CLIENT’s CONTINUED USE OF THE SOLUTION FOLLOWING DELIVERY AND/OR POSTING OF A NOTICE OF MODIFICATION SHALL BE DEEMED AS AN ACCEPTANCE OF SUCH MODIFICATION. IF ANY MODIFICATION SHALL BE UNACCEPTABLE TO CLIENT, THEN CLIENT MUST TERMINATE THIS EULA IMMEDIATELY, AND NOTIFY WATCHFUL IMMEDIATELY SO THAT A TERMINATION OF CLIENT’S RIGHTS HEREUNDER SHALL BE PROCESSED, AND SUCH TERMINATION IS CLIENT’S ONLY RECOURSE.</div>
      </p>
      <p className="move-right">
        <div className="letter-margin">(G)</div> <div><strong>Assignment.</strong> Client’s rights and obligations under this EULA may not be assigned or transferred in any other way, by operation of law or otherwise without the prior written consent of Watchful. Watchful shall have the right to assign Watchful’s rights and obligations under this EULA without the consent of Client to its affiliates or upon any merger or acquisition transaction or the sale of all or substantially all of the assets relating to the SOLUTION.</div>
      </p>

      <p>
            Last Updated: <strong>November 2021</strong>
      </p>


    </div>
  );
}



export default Eula;