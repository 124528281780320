import axios from 'axios';
import config from "../../shared/config";

export const getABTestTimeLineAPI = (id) => {
  const url = `${config.dataApi.backendUrl}/ab-tests/${id}`;
  return axios.get(url);
};

export const getReachRate = (testID) => {
  const url = `${config.dataApi.backendUrl}/ab-tests/${testID}/reach-rate`;
  return axios.get(url);
};