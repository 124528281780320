import React from "react";
import "./ChannelSelect.scss";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Channels } from "containers/MarketingOverview/utils/consts";
import { isMobileDevice } from "containers/MarketingOverview/utils/helpers";
import { CloseShowResults } from "components/CloseShowResults";
import x_ico from "assets/images/icons/x-no-outline.svg";

export const ChannelNames = {
  [Channels.email_campaign]: "Email Campaign",
  [Channels.push_notification_campaign]: "Push Notification",
  [Channels.inapp_campaign]: "In App Campaign",
};

type Props = {
  onChannelSelect: (channels: string[]) => void;
  selectedChannels: string[];
  closeChannel: () => void;
};

export const ChannelSelect = ({
  onChannelSelect,
  selectedChannels,
  closeChannel,
}: Props) => {
  const isMobile = isMobileDevice();

  const handleChannelSelect = (e: CheckboxChangeEvent) => {
    const { checked, value } = e.target;

    const newSelectedChannels = checked
      ? [...selectedChannels, value]
      : selectedChannels.filter((channel) => channel !== value);

    onChannelSelect(newSelectedChannels);
  };

  return (
    <div className="channel-select-container">
      {isMobile && (
        <>
          <div className="mobile-close-x">
            <img onClick={closeChannel} src={x_ico} />
          </div>
          <h5>Select Channels</h5>
        </>
      )}
      {Object.values(Channels).map((channel) => (
        <Checkbox
          key={channel}
          className="channel-select-cb"
          checked={selectedChannels.includes(channel)}
          onChange={handleChannelSelect}
          value={channel}
        >
          {ChannelNames[channel]}
        </Checkbox>
      ))}
      {isMobile && (
        <div className="channel-select-show-results">
          <CloseShowResults onClose={closeChannel} />
        </div>
      )}
    </div>
  );
};
