import React from "react";
import "./CloseShowResults.scss";
import chevron_ico from "assets/images/icons/chevron-left.svg";

type Props = {
  onClose: () => void;
};

export const CloseShowResults = ({ onClose }: Props) => {
  return (
    <div className="csr-container">
      <div className="close" onClick={onClose}>
        <img src={chevron_ico} />
      </div>
      <div className="crs-results" onClick={onClose}>
        Show Results
      </div>
    </div>
  );
};
