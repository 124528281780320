import React from "react";
import "./SidebarFilters.scss";
import { Checkbox } from "antd";
import {
  EXPERIMENTS_SELECT,
  FEATURES_SELECT,
  INTEL_INSIGHT_TYPES_SELECT,
  ITEMS_PER_PAGE,
} from "pages/Intel/utils/consts";
import clsx from "clsx";
import { HandleIntelFilterChangeType } from "pages/Intel/hooks/useIntelFilters";
import { SideFilterCounts } from "pages/Intel/utils/types";
import { isMobileDevice } from "containers/MarketingOverview/utils/helpers";
import { trackIntelEvent } from "pages/Intel/utils/analytics";

type IntelType = {
  id: string;
  title: string;
  amount: number;
};

type Props = {
  selectedType: string;
  selectedExperiments: string[];
  selectedFeatures: string[];
  handleChangeFilter: HandleIntelFilterChangeType;
  experimentsFilterCounts: SideFilterCounts[];
  featuresFilterCounts: SideFilterCounts[];
  types: IntelType[];
};

const getCountByFilterName = (
  filterCounts: SideFilterCounts[],
  name: string
) => {
  return (
    Number(
      filterCounts.find(({ name: filterName }) => filterName === name)?.count
    ) || 0
  );
};

export const SidebarFilters = ({
  selectedType,
  handleChangeFilter,
  selectedExperiments,
  selectedFeatures,
  experimentsFilterCounts,
  featuresFilterCounts,
  types,
}: Props) => {
  const isMobile = isMobileDevice();

  const showExperimentsMobile =
    isMobile && selectedType === INTEL_INSIGHT_TYPES_SELECT.ab_test;
  const showFeaturesMobile =
    isMobile && selectedType === INTEL_INSIGHT_TYPES_SELECT.features;

  const isExperimentsDisabled =
    selectedType !== INTEL_INSIGHT_TYPES_SELECT.ab_test;

  const isFeaturesDisabled =
    selectedType !== INTEL_INSIGHT_TYPES_SELECT.features;

  const handleSelectExperiment = (experiment: string) => () => {
    if (isExperimentsDisabled) return;

    const experiments = selectedExperiments.includes(experiment)
      ? selectedExperiments.filter((exp) => exp !== experiment)
      : [...selectedExperiments, experiment];

    trackIntelEvent("intel_sub_filters", {
      selected_sub_filter: experiment,
    });

    handleChangeFilter("experiments", experiments);
  };

  const onToggleAllExperiments = () => {
    if (isExperimentsDisabled) return;

    const experiments =
      selectedExperiments.length === Object.keys(EXPERIMENTS_SELECT).length
        ? []
        : Object.values(EXPERIMENTS_SELECT);

    experiments.forEach((e) =>
      trackIntelEvent("intel_sub_filters", {
        selected_sub_filter: e,
      })
    );

    handleChangeFilter("experiments", experiments);
  };

  const handleSelectFeature = (feature: string) => () => {
    if (isFeaturesDisabled) return;

    const features = selectedFeatures.includes(feature)
      ? selectedFeatures.filter((feat) => feat !== feature)
      : [...selectedFeatures, feature];

    trackIntelEvent("intel_sub_filters", {
      selected_sub_filter: feature,
    });

    handleChangeFilter("features", features);
  };

  const onToggleAllFeatures = () => {
    if (isFeaturesDisabled) return;

    const features =
      selectedFeatures.length === Object.keys(FEATURES_SELECT).length
        ? []
        : Object.values(FEATURES_SELECT);

    features.forEach((f) =>
      trackIntelEvent("intel_sub_filters", {
        selected_sub_filter: f,
      })
    );

    handleChangeFilter("features", features);
  };

  const activeExperimentCount = getCountByFilterName(
    experimentsFilterCounts,
    EXPERIMENTS_SELECT.active
  );

  const integratedExperimentCount = getCountByFilterName(
    experimentsFilterCounts,
    EXPERIMENTS_SELECT.integrated
  );

  const abandonedExperimentCount = getCountByFilterName(
    experimentsFilterCounts,
    EXPERIMENTS_SELECT.abandoned
  );

  const newFeatureCount = getCountByFilterName(
    featuresFilterCounts,
    FEATURES_SELECT.new_feature
  );

  const removedFeatureCount = getCountByFilterName(
    featuresFilterCounts,
    FEATURES_SELECT.removed_feature
  );

  const handleTypeClick = (typeId: string) => () => {
    trackIntelEvent("intel_type_filter", {
      selected_type: typeId,
    });

    handleChangeFilter("type", typeId);
    handleChangeFilter("offset", "0");
    handleChangeFilter("limit", ITEMS_PER_PAGE.toString());
  };

  const totalAmount = types.reduce((acc, type) => {
    const value =
      type.id === "ab_test"
        ? activeExperimentCount +
          integratedExperimentCount +
          abandonedExperimentCount
        : type.id === "features"
        ? newFeatureCount + removedFeatureCount
        : type.amount;

    return acc + value;
  }, 0);

  return !isMobile ? (
    <div className="i-sidebar-filter-container">
      <div
        className={clsx("i-simple-filter", {
          selected: selectedType === "",
        })}
        onClick={handleTypeClick("")}
      >
        <div className="title">All Discoveries ({totalAmount})</div>
      </div>
      <div
        className={clsx("i-sidebar-filter", {
          selected: selectedType === "ab_test",
        })}
        onClick={handleTypeClick("ab_test")}
      >
        <div className="i-sidebar-filter-heading">
          Experiments (
          {activeExperimentCount +
            integratedExperimentCount +
            abandonedExperimentCount}
          )
        </div>
        <div className="i-sidebar-filter-options">
          <Checkbox
            checked={
              selectedExperiments.length ===
              Object.keys(EXPERIMENTS_SELECT).length
            }
            onChange={onToggleAllExperiments}
            className={clsx("i-sidebar-filter-option all", {
              "all-checkbox-half-selected":
                selectedExperiments.length > 0 &&
                selectedExperiments.length < 3,
            })}
          >
            All (
            {activeExperimentCount +
              integratedExperimentCount +
              abandonedExperimentCount}
            )
          </Checkbox>
          <Checkbox
            checked={selectedExperiments.includes(EXPERIMENTS_SELECT.active)}
            onChange={handleSelectExperiment(EXPERIMENTS_SELECT.active)}
            className="i-sidebar-filter-option"
          >
            Active ({activeExperimentCount})
          </Checkbox>
          <Checkbox
            checked={selectedExperiments.includes(
              EXPERIMENTS_SELECT.integrated
            )}
            onChange={handleSelectExperiment(EXPERIMENTS_SELECT.integrated)}
            className="i-sidebar-filter-option"
          >
            Integrated ({integratedExperimentCount})
          </Checkbox>
          <Checkbox
            checked={selectedExperiments.includes(EXPERIMENTS_SELECT.abandoned)}
            onChange={handleSelectExperiment(EXPERIMENTS_SELECT.abandoned)}
            className="i-sidebar-filter-option"
          >
            Abandoned ({abandonedExperimentCount})
          </Checkbox>
        </div>
      </div>
      <div
        className={clsx("i-sidebar-filter", {
          selected: selectedType === "features",
        })}
        onClick={handleTypeClick("features")}
      >
        <div className="i-sidebar-filter-heading">
          Features ({newFeatureCount + removedFeatureCount})
        </div>
        <div className="i-sidebar-filter-options">
          <Checkbox
            checked={
              selectedFeatures.length === Object.keys(FEATURES_SELECT).length
            }
            onChange={onToggleAllFeatures}
            className={clsx("i-sidebar-filter-option all", {
              "all-checkbox-half-selected":
                selectedFeatures.length > 0 && selectedFeatures.length < 2,
            })}
          >
            All ({newFeatureCount + removedFeatureCount})
          </Checkbox>
          <Checkbox
            checked={selectedFeatures.includes(FEATURES_SELECT.new_feature)}
            onChange={handleSelectFeature(FEATURES_SELECT.new_feature)}
            className="i-sidebar-filter-option"
          >
            New ({newFeatureCount})
          </Checkbox>
          <Checkbox
            checked={selectedFeatures.includes(FEATURES_SELECT.removed_feature)}
            onChange={handleSelectFeature(FEATURES_SELECT.removed_feature)}
            className="i-sidebar-filter-option"
          >
            Removed ({removedFeatureCount})
          </Checkbox>
        </div>
      </div>
      {types
        .filter((type) => type.id !== "ab_test" && type.id !== "features")
        .map((type) => (
          <div
            key={type.id}
            className={clsx("i-simple-filter", {
              selected: selectedType === type.id,
            })}
            onClick={handleTypeClick(type.id)}
          >
            <div className="title">
              {type.title} ({type.amount})
            </div>
          </div>
        ))}
    </div>
  ) : (
    <div className="i-sidebar-filter-container-mobile">
      {showExperimentsMobile && (
        <div className="i-sidebar-filter-options">
          <Checkbox
            checked={
              selectedExperiments.length ===
              Object.keys(EXPERIMENTS_SELECT).length
            }
            onChange={onToggleAllExperiments}
            className={clsx("i-sidebar-filter-option all", {
              "all-checkbox-half-selected":
                selectedExperiments.length > 0 &&
                selectedExperiments.length < 3,
            })}
          >
            All
          </Checkbox>
          <Checkbox
            checked={selectedExperiments.includes(EXPERIMENTS_SELECT.active)}
            onChange={handleSelectExperiment(EXPERIMENTS_SELECT.active)}
            className="i-sidebar-filter-option"
          >
            Active (
            {getCountByFilterName(
              experimentsFilterCounts,
              EXPERIMENTS_SELECT.active
            )}
            )
          </Checkbox>
          <Checkbox
            checked={selectedExperiments.includes(
              EXPERIMENTS_SELECT.integrated
            )}
            onChange={handleSelectExperiment(EXPERIMENTS_SELECT.integrated)}
            className="i-sidebar-filter-option"
          >
            Integrated (
            {getCountByFilterName(
              experimentsFilterCounts,
              EXPERIMENTS_SELECT.integrated
            )}
            )
          </Checkbox>
          <Checkbox
            checked={selectedExperiments.includes(EXPERIMENTS_SELECT.abandoned)}
            onChange={handleSelectExperiment(EXPERIMENTS_SELECT.abandoned)}
            className="i-sidebar-filter-option"
          >
            Abandoned (
            {getCountByFilterName(
              experimentsFilterCounts,
              EXPERIMENTS_SELECT.abandoned
            )}
            )
          </Checkbox>
        </div>
      )}
      {showFeaturesMobile && (
        <div className="i-sidebar-filter-options">
          <Checkbox
            checked={
              selectedFeatures.length === Object.keys(FEATURES_SELECT).length
            }
            onChange={onToggleAllFeatures}
            className={clsx("i-sidebar-filter-option all", {
              "all-checkbox-half-selected":
                selectedFeatures.length > 0 && selectedFeatures.length < 2,
            })}
          >
            All
          </Checkbox>
          <Checkbox
            checked={selectedFeatures.includes(FEATURES_SELECT.new_feature)}
            onChange={handleSelectFeature(FEATURES_SELECT.new_feature)}
            className="i-sidebar-filter-option"
          >
            New (
            {getCountByFilterName(
              featuresFilterCounts,
              FEATURES_SELECT.new_feature
            )}
            )
          </Checkbox>
          <Checkbox
            checked={selectedFeatures.includes(FEATURES_SELECT.removed_feature)}
            onChange={handleSelectFeature(FEATURES_SELECT.removed_feature)}
            className="i-sidebar-filter-option"
          >
            Removed (
            {getCountByFilterName(
              featuresFilterCounts,
              FEATURES_SELECT.removed_feature
            )}
            )
          </Checkbox>
        </div>
      )}
    </div>
  );
};
