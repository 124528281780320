import { Button, Checkbox, Popover } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { Version } from "../../../utils/consts";
import "./VersionSelect.scss";

type Props = {
  selectedVersions: Version[];
  versions: Version[];
  onVersionSelect: (version: Version) => void;
  width?: number;
  maxLen?: number;
};

export const VersionSelect = ({
  selectedVersions,
  versions,
  onVersionSelect,
  width = 220,
}: Props) => {
  const [selectOpen, setSelectOpen] = useState(false);

  const handleVersionSelect = (version: Version) => () => {
    onVersionSelect(version);
  };

  return (
    <div className="uj-filter-versions">
      <Popover
        placement="bottomLeft"
        title={null}
        open={selectOpen}
        onOpenChange={setSelectOpen}
        trigger="click"
        content={
          <div className="uj-version-select-container">
            <div className="uj-version-select-count">
              {selectedVersions.length > 0
                ? `${selectedVersions.length} Selected`
                : "All Selected"}
            </div>
            <div className="uj-versions-select-title">Versions History</div>
            <div className="uj-versions-select-versions">
              {versions.map((version) => (
                <div className="uj-versions-select-option">
                  <Checkbox
                    className="uj-checkbox"
                    onChange={handleVersionSelect(version)}
                    checked={selectedVersions.some(
                      ({ id }) => id === version.id
                    )}
                  >
                    {version.release_name}
                    <span className="uj-date">
                      {moment(version.release_date).format("DD/MM/YYYY")}
                    </span>
                  </Checkbox>
                </div>
              ))}
            </div>
          </div>
        }
      >
        <Button
          className="filter-btn-new platform-mult-selector uj-versions"
          style={{ width: `${width}px` }}
        >
          {selectedVersions.length > 0
            ? `${selectedVersions.length} Versions Selected`
            : "All Versions"}
        </Button>
      </Popover>
    </div>
  );
};
