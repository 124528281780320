import React from 'react';

const PricingIframe = ({ iframes }) => {
  const url = iframes[0].url;
  return (
    <iframe className="pricing__iframe" src={url} title={url}/>
  );
}


export default PricingIframe;