import React from "react";
import "./TextHeading.scss";

export const TextHeading = ({ title }) => {
  return (
    <div className="text-heading">
      <h2>{title}</h2>
    </div>
  );
};
