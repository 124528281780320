import React, { useMemo, useRef } from "react";
import "./styles.scss";
import { Col, Row, Spin } from "antd";
import { Card, ScrollableCardsList } from "./scrollable-cards-list";
import { useContextTimeline } from "pages/ContextTimeline/context";
import clsx from "clsx";
import { Timeline } from "./timeline";
import moment from "moment";
import { InsightsList } from "./insights-list";

export const Main = () => {
  const { isSidebarOpen, eventsByDateList, isLoading, insightsByCurDateList } =
    useContextTimeline();

  const cards: Card[] = useMemo(() => {
    let prevDate: string | null = null;
    const cards: Card[] = [];

    eventsByDateList?.forEach((eventByDate, idx) => {
      if (moment(eventByDate.date).diff(prevDate, "days") > 1) {
        const diff = moment(eventByDate.date).diff(prevDate, "days");

        cards.push({
          id: diff + idx,
          isGap: true,
          gap: diff,
        });
      }

      cards.push({
        id: idx,
        date: eventByDate.date,
        events: eventByDate.events,
        emailCount: eventByDate.events.reduce((acc, curr) => {
          const insights = curr.insights?.filter((insight) =>
            insight.type.includes("email")
          );
          const count = insights?.length || 0;

          return acc + count;
        }, 0),
        pushCount: eventByDate.events.reduce((acc, curr) => {
          const insights = curr.insights?.filter((insight) =>
            insight.type.includes("push")
          );
          const count = insights?.length || 0;

          return acc + count;
        }, 0),
      });

      prevDate = eventByDate.date;
    });

    return cards;
  }, [eventsByDateList]);

  const startDate = useMemo(() => {
    return eventsByDateList?.[0]?.date;
  }, [eventsByDateList]);

  const endDate = useMemo(() => {
    return eventsByDateList?.[eventsByDateList.length - 1]?.date;
  }, [eventsByDateList]);

  return (
    <Col
      flex="auto"
      className={clsx("ctl-content", {
        closed: !isSidebarOpen,
      })}
    >
      <Spin spinning={isLoading}>
        <Row>
          <Col span={24}>
            <ScrollableCardsList cards={cards} />
          </Col>
          <Col span={24}>
            <Timeline
              startDate={startDate || moment().format("YYYY-MM-DD")}
              endDate={endDate || moment().format("YYYY-MM-DD")}
            />
          </Col>
          <Col
            span={24}
            className={clsx("main-content", {
              empty: insightsByCurDateList?.length === 0,
            })}
          >
            <InsightsList />
          </Col>
        </Row>
      </Spin>
    </Col>
  );
};
