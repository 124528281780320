import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { RestLink } from "apollo-link-rest";
import { setContext } from "@apollo/client/link/context";
import config from "./config";
import storageService from "../services/storageService";

const httpLink = createHttpLink({
  uri: `${config.dataApi.backendUrl}/graphql`,
});

const restLink = new RestLink({ uri: `${config.dataApi.backendUrl}/` });

const authLink = setContext((_, { headers }) => {
  const storage = new storageService();
  const token = storage.getItem("usertoken");

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([authLink, restLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      nextFetchPolicy: "cache-only",
    },
  },
});
