
import React, { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withLastLocation } from "react-router-last-location";

import jwt_decode from "jwt-decode";
import { message } from 'antd'
import { FlagsContext } from 'react-unleash-flags';
import { isNull } from "lodash";
import moment from "moment"

//ACTIONS
import {
  setUser,
  getAppsMetadata,
  getAllAppsData,
  createNewUser,
} from "../../redux/actions/dashboardActions";
import { getUserDiscoveries } from "../../redux/acts/userDiscoveries"
import { getUserName } from "../../redux/actions/userActions"
import { getPerformancePageMetadata } from "../../redux/actions/performanceActions";
import { USER_CREATION_FAILED_MESSAGE } from "../../redux/sagas/userSagas";
import { pushSubscriptionUpdate } from "../../redux/acts/subscriptions"
import { getSubscriptions } from "../../redux/acts/subscriptions"

// SERVICES && HELPERS
import analyticsService from "../../services/analyticsService";
import storageService from "../../services/storageService";
import awsService from "../../services/awsService";
import { siteVersions } from "../../shared/consts";
import { updateAxiosToken } from "../../App"

//COMPONENTS
import Routes from "../../components/routes/Routes";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import SupportButton from "../../common/supportButton/SupportButton";
import SubscriptionInitHint from "../subscriptions/SubscriptionInitHint/index"
import PushSubsModal from "../../components/PushSubsModal/PushSubsModal"


// ASSETS
import FBWorkspaceLogo from "../../assets/images/icons/fbWorkspace_logo.png";
import scroll_shveron_svg from "../../assets/images/new_design/scroll-shveron.svg"
import { transformOldMarketingUrlToNewMarketingUrl } from "containers/MarketingCampaings/utils/helpers";
import { isCurrentPathSupportedOnMobile } from "shared/mobile-fix";

const supportButtonParams = {
  fb: {
    icon: FBWorkspaceLogo,
    link: "https://my.workplace.com/groups/2449581048421783/"
  },
};

function Dashboard(props) {

  const aws = new awsService();
  const analytics = analyticsService.getInstance();
  const storage = new storageService();

  const [siteSize, siteSizeChange] = useState('')

  const [openPushSubsModalRemember, openPushSubsModalRememberChange] = useState(false)
  const [openPushSubsModal, openPushSubsModalChange] = useState(false)
  const [openPushSubsModalShown, openPushSubsModalShownChange] = useState(false)
  
  const [showSubscriptionInit, showSubscriptionInitChange] = useState(false)

  const [apiReady, apiReadyChange] = useState(false)
  const [scrollTopShowing, scrollTopShowingChange] = useState(false)

  const flagsClient = useContext(FlagsContext);


  useEffect(() => {
    const { siteVersion, user, getUserName } = props;
    const currentUser = aws.getCurrentUser();
    if (siteVersion === siteVersions.DEMO) {
      return;
    }
    const regex = new RegExp('(?:id_token=)(.*?)(?=&)');
    const hash = window.location.hash;
    let token = '';
    if (hash) {
      token = hash.match(regex)[0].substring(9);
    }

    if (!token) {
      token = storage.getItem("usertoken")
    }
    if (token) {
      let cognitoUser = jwt_decode(token)
      analytics.email = cognitoUser.email;
      analytics.username = cognitoUser.sub;
      aws.setCredentials(token);
      storage.setItem("usertoken", token);
      updateAxiosToken(token)
      props.createNewUser()

      return
    }

    if (!(currentUser) || props.match.params.packageName === "link") {
      // There's no logged in user, send to login
      props.history.push("/login");
      return;
    }

    currentUser.getSession((err, session) => {

      if (err || !session || !session.idToken) {
        storage.clear();
        props.history.push("/login");
        return;
      }
      const cognitoUser = session.idToken.payload;
      analytics.email = cognitoUser.email;
      analytics.username = cognitoUser.sub;
      aws.setCredentials(session.idToken.jwtToken);
      storage.setItem("usertoken", session.idToken.jwtToken);
      updateAxiosToken(session.idToken.jwtToken)
      props.createNewUser()
      props.setUser({ ...cognitoUser, analytics });
      props.getAppsMetadata(cognitoUser)
      props.getUserName(cognitoUser.email);
      apiReadyChange(true)
    });
  }, [])

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    window.addEventListener('scroll', scrollTopShowingHandle);
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
      window.removeEventListener('scroll', scrollTopShowingHandle);
    }
  }, [])

  //HELPERS && HANDLERS
  const updateWindowDimensions = () => {
    if (window.innerWidth >= 1280) {
      return siteSizeChange('large')
    }
    
    // TODO: remove mobile fix
    if (window.screen.width <= 768) {
      return siteSizeChange('large')
    }
    
    return siteSizeChange('normal')
  }
  const scrollTopShowingHandle = () => {
    scrollTopShowingChange(window.pageYOffset > 100)
  }
  const scrollTopHandler = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  const pushSubsOk = () => {
    props.history.push('/subscriptions', { openNewModal: true })
    openPushSubsModalChange(false)
  }
  const pushSubsNotNow = () => {
    openPushSubsModalChange(false)
    const date = (openPushSubsModalRemember ? null : moment().add(1, 'month').format('YYYY-MM-DD'))
    props.pushSubscriptionUpdate(date)
  }

  //EFFECTS
  useEffect(() => {
    if (apiReady && !props.performanceApps && !props.isFetchingPerformancePageMetadata) {
      props.getPerformancePageMetadata({ monthsAgo: 6, without_num_of_samples: 1 });
    }
  }, [apiReady])
  useEffect(() => {
    if (props.subscriptionsInit) {
      showSubscriptionInitChange(true)
    }
  }, [props.subscriptionsInit])
  useEffect(() => {
    if (props.userData.userDataFetched &&
      props.userData.subs_modal_show_at &&
      moment(props.userData.subs_modal_show_at).isBefore(moment()) &&
      props.subscriptionsFetched &&
      props.userData.pages.includes('subscriptions') &&
      props.subscriptions.length === 0 &&
      !openPushSubsModalShown &&
      !props.location.pathname.includes('/bi_weekly/') &&
      !props.location.pathname.includes('/reports/')) {
      openPushSubsModalShownChange(true)
      setTimeout(() => { openPushSubsModalChange(true) }, 2000)
    }
  }, [props.userData])
  useEffect(() => {
    if (!isNull(props.userData.days_to_trial_expiration) && props.userData.days_to_trial_expiration <= 0) {
      window.location.href = "/410.html";
      return
    }
  }, [props.userData.days_to_trial_expiration])
  useEffect(() => {
    if (props.userData.userNotCreatedMessage === USER_CREATION_FAILED_MESSAGE) {
      // this is temp, remove the next lines once yjp signs contract
      message.loading({ content: 'Allocated user amount exceeded, please consult your admin', duration: 0, icon: <div /> })
      storage.setSessionItem("usertoken", null);
      setTimeout(() => message.destroy(), 5000);
      props.history.push("/login");
      return;
    }
  }, [props.userData.userNotCreatedMessage])
  useEffect(() => {
    const userData = props.userData;
    if (userData) {
      analytics.first_name = userData.first_name;
      analytics.last_name = userData.last_name;
      analytics.role = userData.role;
      analytics.department = userData.department;
      analytics.isAdmin = userData.is_org_admin;
      analytics.user_type = userData.first_name ? (userData.is_org_admin ? 'Admin': 'User') : 'Guest';
    }
  }, [props.userData])
  useEffect(() => {
    // has has no experiments apps
    // try performance
    if (!props.location.pathname.includes("/performance") && props.apps.length === 1 && props.apps.find(app => app.metadata.id === -1)) {
      props.history.replace("/benchmark/compare");
    }
  }, [props.apps])
  useEffect(() => {
    const token = storage.getItem("usertoken");
    if (props.userData.successUserDataChange && token) {
      let cognitoUser = jwt_decode(token)

      analytics.email = cognitoUser.email;
      analytics.username = cognitoUser.sub;

      props.setUser({ ...cognitoUser, analytics });
      props.getAppsMetadata(cognitoUser);
      props.getSubscriptions()
      props.getUserName(cognitoUser.email);
      props.getUserDiscoveries()
      apiReadyChange(true)
    }
  }, [props.userData.successUserDataChange])
  useEffect(() => {
    const newMarketingUrl = transformOldMarketingUrlToNewMarketingUrl(props.history.location.pathname, props.history.location.search)
    
    if(newMarketingUrl) {
      console.log(newMarketingUrl)
      props.history.replace(newMarketingUrl);
    }
  }, [props.history.location.pathname])


  const demoMode = props.siteVersion === siteVersions.DEMO;

  if (!apiReady && !demoMode) {
    console.log('Dashboard.render() - waiting for user session...')
    return null
  }

  const isFB =
    props.user?.email &&
    props.user.email.includes("@fb.com");

  const { apps, appsLoaded, user, performanceApps, appData, appSelectedDemo, onlyPerformanceMode, products } = props;
  const isUsualFlow = user && performanceApps && appsLoaded && products
  const perfModeOnlyFlow = onlyPerformanceMode && performanceApps && appData.siteVersion === siteVersions.FULL
  const demoFlow = appSelectedDemo || appData.siteVersion === "demo"

  return <div className="dashboard">

    <div
      id="page-backdrop-portal"
      className={`backdrop backdrop-disable`}
    />
    <Header
      siteSize={siteSize}
      showSearch={
        !demoMode &&
        apps.length > 1 &&
        !apps.find(app => app.metadata.id === -1) &&
        props.location &&
        !props.location.pathname.includes("/performance") // hide search in the performance page
      }
    />
    <div className={`dashboard__container ${(props.currentRequests.length > 0) ? 'blurry' : ''}`}>

      <div className="sidebar-place-dummy"/>
      <main className="dashboard__main">
        {(isUsualFlow || perfModeOnlyFlow || demoFlow) && flagsClient &&
          <Routes isFB={isFB} appAnalytics={analytics} flagsClient={flagsClient} />
        }
        {isFB && (
          <SupportButton
            supportLink={supportButtonParams["fb"].link}
            supportIcon={supportButtonParams["fb"].icon}
          />
        )}
      </main>
      <div className="right-space" />
    </div>

    {scrollTopShowing && <div className="scroll-top" onClick={scrollTopHandler}>
      TO TOP
      <div className="scroll-top-img">
        <img src={scroll_shveron_svg} />
      </div>
    </div>}

    {showSubscriptionInit && <SubscriptionInitHint
      visible={showSubscriptionInit}
      onClose={() => showSubscriptionInitChange(false)}
      onGoToSubscriptions={() => { props.history.push('/subscriptions'); showSubscriptionInitChange(false) }}
    />}

    {openPushSubsModal && <PushSubsModal
      visible={openPushSubsModal}
      closeModal={() => openPushSubsModalChange(false)}
      remebmer={openPushSubsModalRemember}
      rememberCheckboxChange={() => openPushSubsModalRememberChange(!openPushSubsModalRemember)}
      nowNowHandle={pushSubsNotNow}
      okHandle={pushSubsOk}
    />}

    <Footer />
  </div>
}

const mapStateToProps = ({ appData, performance, userData, subscriptions, overview }) => {
  return {
    appData,
    products: overview.products,
    insightLabels: appData.insightLabels,
    performanceApps: performance.apps,
    isFetchingPerformancePageMetadata:
      performance.isFetchingPerformancePageMetadata,
    apps: appData.apps,
    appsLoaded: appData.appsLoaded,
    onlyPerformanceMode: appData.onlyPerformanceMode,
    appSelect: appData.appSelect,
    appSelectedDemo: appData.appSelectedDemo,
    user: appData.user,
    analytics: appData.user.analytics,
    siteVersion: appData.siteVersion,
    loaderStatus: appData.mainLoaderStatus,
    currentRequests: appData.currentRequests,
    userData: userData,
    subscriptionsInit: subscriptions.subscriptionsInit,
    subscriptions: subscriptions.subscriptionsList,
    subscriptionsFetched: subscriptions.subscriptionsFetched
  };
};

export default withRouter(
  withLastLocation(
    connect(mapStateToProps, {
      setUser,
      getAppsMetadata,
      getPerformancePageMetadata,
      getAllAppsData,
      getUserName,
      createNewUser,
      pushSubscriptionUpdate,
      getSubscriptions,
      getUserDiscoveries
    })(Dashboard)
  )
);
