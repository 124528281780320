import cat_transaction_ico from "../../../assets/images/icons/sub_type/transaction.svg"
import cat_mass_market_ico from "../../../assets/images/icons/sub_type/mass_market.svg"
import cat_retention_ico from "../../../assets/images/icons/sub_type/retention.svg"
import cat_prospect_ico from "../../../assets/images/icons/sub_type/prospect.svg"
import cat_lapsed_ico from "../../../assets/images/icons/sub_type/lapsed.svg"
import cat_onboarding_ico from "../../../assets/images/icons/sub_type/onboarding.svg"
export const INSIGHT_CAMPAIGN_SUBTYPE = [
  {
    name: 'prospects_nursery',
    label: 'Prospects/Nursery',
    icon: cat_prospect_ico,
  },
  {
    name: 'onboarding',
    label: 'Onboarding',
    icon: cat_onboarding_ico,
  },
  {
    name: 'retention',
    label: 'Cross-Sell, Up-Sell & Retention',
    icon: cat_retention_ico,
  },
  {
    name: 'transactional',
    label: 'Transactional',
    icon: cat_transaction_ico,
  },
  {
    name: 'lapsed',
    label: 'Lapsed',
    icon: cat_lapsed_ico,
  },
  {
    name: 'mass_market',
    label: 'Mass Market',
    icon: cat_mass_market_ico,
  }
]

export const UNSEEN = 'unseen'
export const VIEWED = 'viewed'
export const MEANINGFUL = 'meaningful'