import React, { Component } from "react";
import { connect } from "react-redux";
import {
  createLinkData,
  getRecipients,
  sendShareEmail,
  updateLinkData,
} from "../../../redux/actions/directLinkActions";
import { compareStrings } from "../../../services/stringsService";
import ViewModal from "../viewModal/ViewModal";
import { Tooltip, Button, message } from "antd";
import { ShareAltOutlined } from "@ant-design/icons";
import share_ico from "assets/images/icons/share.svg";

import "antd/lib/tooltip/style/css";

const PLACE_HOLDER =
  "Check out this discovery I’ve found on watchful’s platform";

export class ViewCopyButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modalShow: false,
      linkUrl: "",
      recipient: null,
      comment: null,
    };
  }
  showModal = () => {
    this.setState({ modalShow: true });
  };

  closeModal = () => {
    this.setState({ modalShow: false });
  };

  sendEmail = () => {
    const {
      insightId,
      user: { email },
      link,
      analytics,
      metadata,
      id,
      title,
    } = this.props;

    let data = {
      link_code: link[insightId],
      to: [this.state.recipient],
      comment: this.state.comment || PLACE_HOLDER,
    };
    console.log(data);
    this.props.sendShareEmail(data);
    this.setState({ modalShow: false });
    analytics.analytic(
      `User ${email} share insight ${insightId} with title ${
        this.props.title
      } to recipient ${this.state.recipient}. Data - ${JSON.stringify(data)}`
    );
  };

  copyLink = (e) => {
    e.stopPropagation();
    const {
      insightId,
      user: { email },
      link,
      analytics,
      metadata,
      id,
      title,
      insightType,
    } = this.props;
    const appId = metadata.id;
    if (!this.props.recipients) {
      this.props.getRecipients();
    }

    if (link === "" || !link || !link[insightId]) {
      this.setState({ loading: true });
      this.props.createLinkData({ appId, email, insightId });
    } else if (link && this.props.recipients) {
      this.setState({ modalShow: true, linkUrl: this.getLink(insightId) });
    }

    analytics.analytic("share_clicked", {
      insight_name: title,
      insight_id: insightId,
      app_name: metadata.name,
      app_id: appId,
    });
  };

  componentDidUpdate = (prvProps) => {
    const { insightId } = this.props;
    if (
      this.props.link &&
      this.state.loading &&
      compareStrings(this.props.status, "success") &&
      this.props.link[insightId]
    ) {
      const linkUrl = this.getLink(insightId);
      this.setState({ loading: false, modalShow: true, linkUrl });
    }
    if (
      !this.state.modalShow &&
      this.props.recipients &&
      this.props.link &&
      !prvProps.link
    ) {
      this.setState({ modalShow: true, linkUrl: this.getLink(insightId) });
    }
    if (
      prvProps.sendEmailStatus === "sending" &&
      this.props.sendEmailStatus === "finish"
    ) {
      message.success("Email was sent");
    }
  };

  getLink = (insightId) => {
    const { origin } = window.location;
    const { link } = this.props;
    return `${origin}/link/${link[insightId]}?source=shared_link`;
  };

  render() {
    const { loading, modalShow, linkUrl } = this.state;
    const { siteVersion, page, insightId, updateLinkData, link, user } =
      this.props;

    return (
      <div
        className={`view__header-export view__header-export__${siteVersion}`}
      >
        {page === "overview" ? (
          <Button icon={<ShareAltOutlined />} onClick={this.copyLink}>
            Share
            {loading && <span className="loader-button loader-button-share" />}
          </Button>
        ) : page === "campaigns" ? (
          <div className="mkc-preview-btn" onClick={this.copyLink}>
            <img src={share_ico} />
          </div>
        ) : !loading ? (
          <Tooltip title="Share">
            <img
              src={require("../../../assets/images/icons/view/share.svg")}
              alt="share discovery"
              onClick={this.copyLink}
              className="view__header__share"
            />
          </Tooltip>
        ) : (
          <span className="loader-button loader-button-share" />
        )}
        <ViewModal
          closeModal={this.closeModal}
          sendEmail={this.sendEmail}
          modalShow={modalShow}
          linkUrl={linkUrl}
          recipients={this.props.recipients}
          key={`modal-share-${insightId}`}
          recipient={this.state.recipient}
          recipientChange={(rep) => this.setState({ recipient: rep })}
          comment={this.state.comment}
          commentChange={(comment) => this.setState({ comment: comment })}
          placeHolder={PLACE_HOLDER}
          linkCode={insightId ? link[insightId] : ""}
          updateLinkData={updateLinkData}
          user={user}
          analytics={this.props.analytics}
          analyticsData={{
            insight_name: this.props.title,
            insight_id: insightId,
            app_name: this.props.metadata?.name,
            app_id: this.props.metadata?.id,
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ appData, linkData }) => {
  return {
    link: linkData.link,
    status: linkData.status,
    user: appData.user,
    siteVersion: appData.siteVersion,
    recipients: linkData.recipients,
    sendEmailStatus: linkData.sendEmailStatus,
    analytics: appData.user.analytics,
  };
};

export default connect(mapStateToProps, {
  createLinkData,
  getRecipients,
  sendShareEmail,
  updateLinkData,
})(ViewCopyButton);
