import React, { Component } from "react";
import { connect } from "react-redux";
import NewLoader from "../NewLoader/NewLoader";
const l = (...a) => console.log('MainLoader',...a);

class MainLoader extends Component {
    state = {}

    render() {
      const { loaderStatus, currentRequests, global } = this.props;
      let rt;
      if (currentRequests.length == 0) {
        rt= <React.Fragment />
      } else {
        rt= (
          <div className={`main__loader ${global ? 'global' : ''}`}>
            <NewLoader />
          </div>
        );
      }
      return rt;
    }
}

const mapStateToProps = ({ appData }) => {
  return {
    currentRequests: appData.currentRequests
  };
};

export default connect(mapStateToProps)(MainLoader);
