import React from 'react'

import { intelDiscoveryTypeDictionary, intelligenceOnlyType } from "../../shared/consts"
import { INSIGHT_CAMPAIGN_SUBTYPE } from '../intelligence/Marketing/configsSubtypes'

import { Checkbox, Button, Select } from 'antd';
import black_chevron_ico from "../../assets/images/icons/chevron_black.svg"

const { Option } = Select

export default function FilterType({
  filterType,
  filterTypeChange,
  filterEmailSubtype,
  filterEmailSubtypeChange,
  filterInAppSubtype,
  filterInAppSubtypeChange,
  filterPushNotificationSubtype,
  filterPushNotificationSubtypeChange,
  filterIntelEnabled,
  filterIntelEnabledChange,
  filterMarkEnabled,
  filterMarkEnabledChange,
  counts,
  selectedProductQTY,
  sortOrder,
  sortOrderChange,
  page,
  availableFeatures,
}) {
  const isIntelligenceAvailable = availableFeatures.isIntelligenceAvailable;
  const isMarketingAvailable = availableFeatures.isMarketingAvailable;

  const intelDiscoveriesQTY = Object.keys(counts).filter(k => !k.includes('_campaign')).reduce((acc, el) => acc + counts[el], 0) || 0
  const markDiscoveriesQTY = Object.keys(counts).filter(k => k.includes('_campaign')).reduce((acc, el) => acc + counts[el], 0) || 0

  const handleTypeClick = (e) => {
    let sel = e.target.checked;
    filterIntelEnabledChange(sel)
    if (sel) {
      filterTypeChange(Object.keys(intelligenceOnlyType))
    } else {
      filterTypeChange([])
    }
  }

  const handleMarkTypeClick = (e) => {
    let sel = e.target.checked;
    filterMarkEnabledChange(sel)
    if (sel) {
      filterEmailSubtypeChange(INSIGHT_CAMPAIGN_SUBTYPE.map(subtype => subtype.name))
      filterInAppSubtypeChange(INSIGHT_CAMPAIGN_SUBTYPE.map(subtype => subtype.name))
      filterPushNotificationSubtypeChange(INSIGHT_CAMPAIGN_SUBTYPE.map(subtype => subtype.name))
    } else {
      filterEmailSubtypeChange([])
      filterInAppSubtypeChange([])
      filterPushNotificationSubtypeChange([])
    }
  }

  const handleAll = () => {
    filterIntelEnabledChange(true)
    filterMarkEnabledChange(true)
    filterTypeChange(Object.keys(intelDiscoveryTypeDictionary));
    filterEmailSubtypeChange(INSIGHT_CAMPAIGN_SUBTYPE.map(subtype => subtype.name));
    filterInAppSubtypeChange(INSIGHT_CAMPAIGN_SUBTYPE.map(subtype => subtype.name));
    filterPushNotificationSubtypeChange(INSIGHT_CAMPAIGN_SUBTYPE.map(subtype => subtype.name));
  }

  const handleClear = () => {
    filterIntelEnabledChange(false)
    filterMarkEnabledChange(false)
    filterTypeChange([]);
    filterEmailSubtypeChange([]);
    filterInAppSubtypeChange([]);
    filterPushNotificationSubtypeChange([]);
  }

  const marketingSubSections = [{
    type: 'email_campaign',
    value: filterEmailSubtype,
    changeFunction: filterEmailSubtypeChange,
    label: 'Email Campaigns'
  }, {
    type: 'inapp_campaign',
    value: filterInAppSubtype,
    changeFunction: filterInAppSubtypeChange,
    label: 'In-App / On-Site Campaigns'
  },
  {
    type: 'push_notification_campaign',
    value: filterPushNotificationSubtype,
    changeFunction: filterPushNotificationSubtypeChange,
    label: 'Push Notification'
  }]
  let typesToShow = {}
  if (isIntelligenceAvailable) {
    typesToShow = {...typesToShow ,...intelligenceOnlyType}
  }


  return (
    <div className="filters-popop state">
      {page === 'all-discoveries' && <div className="items-selected">
        <div className="items-selected-count">
          {`${filterType.length + filterEmailSubtype.length + filterInAppSubtype.length + filterPushNotificationSubtype.length} Selected`}
        </div>
        <div className="items-selected-values">
          {[...filterType.map(f => intelDiscoveryTypeDictionary[f]),
            ...filterEmailSubtype.map(f => INSIGHT_CAMPAIGN_SUBTYPE.find(l => l.name === f).label),
            ...filterInAppSubtype.map(f => INSIGHT_CAMPAIGN_SUBTYPE.find(l => l.name === f).label),
            ...filterPushNotificationSubtype.map(f => INSIGHT_CAMPAIGN_SUBTYPE.find(l => l.name === f).label)
          ].join(', ')}
        </div>
        {selectedProductQTY === 0 ?
          <Button onClick={handleAll} className="all ant-btn-dark">
            All
          </Button>
          :
          <Button onClick={handleClear} className="all ant-btn-dark">
            Clear
          </Button>
        }

      </div>}

      {page === 'product-page' && <div className="filter-header">
        <h1 className="filter-h1">Filter By</h1>

        <Select
          value={sortOrder}
          className="simple sorting-select"
          onChange={selected => { sortOrderChange(selected) }}
          suffixIcon={
            <img
              className={`chevron`}
              src={black_chevron_ico}
              alt="chevron"
            />
          }
        >
          <Option value="desc">Newest First</Option>
          <Option value="asc">Oldest First</Option>
        </Select>

        <Button onClick={handleClear} className="ant-btn-dark">
          Clear All Filters
        </Button>
      </div>}
      {isIntelligenceAvailable &&
      <> 
        <h3>
          <Checkbox checked={filterIntelEnabled} onChange={handleTypeClick} >
          Product Intelligence ({intelDiscoveriesQTY})
          </Checkbox>
        </h3>
        <div className="filters-content">
          <Checkbox.Group options={Object.entries(typesToShow).map(type => (
            {
              label: `${type[1]} ${counts[type[0]] ? `(${counts[type[0]]})` : ''}`,
              value: type[0]
            })
          )}
          value={filterType} onChange={(selected) => filterTypeChange(selected)} name="type" />
        </div></>}
      {isMarketingAvailable && <><h3>
        <Checkbox checked={filterMarkEnabled} onChange={handleMarkTypeClick}>
          Marketing Campaigns ({markDiscoveriesQTY})
        </Checkbox>
      </h3>
      {marketingSubSections.map(sec => (
        <>
          <h4>{sec.label} ({counts[sec.type] || 0})</h4>
          <div className="filters-content inner">
            <Checkbox.Group options={INSIGHT_CAMPAIGN_SUBTYPE.map(subtype => (
              {
                label: subtype.label,
                value: subtype.name,
                key: subtype.name
              })
            )}
            value={sec.value}
            onChange={(selected) => sec.changeFunction(selected)} />
          </div>
        </>
      ))}</>}
    </div>
  )
}
