import React from "react";
import Icon  from "@ant-design/icons";

function tagElement(tag) {
  let _tag = tag;
  if (Array.isArray(tag)) {
    _tag = tag[0];
  }
  return (
    <div
      key={_tag}
      className={`tags__tags-tag-element ${['significant discovery', 'Critical Insight'].includes(tag) ? 'red' : ''}`}
    >
      {_tag}
    </div>
  );
}

function clickableTagElement(tag, count, selected, callback = () => { }, hideCount) {
  let _tag = tag;
  if (Array.isArray(tag)) {
    _tag = tag[0];
  }
  return (
    <div
      key={_tag}
      className={`tags__tags-tag-element clickable ${tag === 'critical insight' ? 'critical' : ''} ${tag[1] ? tag[1] : ""} ${selected ? "selected" : ""
      }`}
      onClick={() => callback(_tag)}
    >
      {_tag} {!hideCount && count > 1 && `(${count})`}
      {selected && (
        <Icon
          type="close-circle"
          theme="filled"
          style={{ marginLeft: 5, position: "relative", right: -3 }}
        />
      )}
    </div>
  );
}

export function ViewTagsSearchRow({ tags }) {
  const tagsElements = tags && tags.map(tag => tag ? tagElement(tag) : null);
  return (
    <div className="tags__search-row">
      <div className="tags__search-row-tags-wrapper">{tagsElements}</div>
    </div>
  );
}

export function ClickableFiltersTags({
  tags,
  selectedTags = [],
  tagSelectionHandler,
  hideCount
}) {
  const tagsElements =
    tags &&
    tags.map((tag, i) =>
      clickableTagElement(
        tag,
        i,
        selectedTags.includes(tag),
        tagSelectionHandler,
        hideCount
      )
    );
  return (
    <div className="tags__tags">
      <div className="tags__tags-tags-wrapper">{tagsElements}</div>
    </div>
  );
}

export function ClickableVersionTags({
  tags,
  selectedTags = [],
  tagSelectionHandler
}) {
  const tagsElements =
    tags &&
    Object.entries(tags)
      .sort((a, b) => { if (a[0] === 'critical insight') return -1; return b[1] - a[1] })
      .map(([tag, count]) =>
        clickableTagElement(
          tag,
          count,
          selectedTags.includes(tag),
          tagSelectionHandler
        )
      );
  return (
    <div className="tags__tags">
      <div className="tags__tags-tags-wrapper">{tagsElements}</div>
    </div>
  );
}

export function ClickableSearchTags({
  tags,
  visible,
  selectedTags = [],
  tagSelectionHandler
}) {
  const tagsElements =
    tags &&
    Object.entries(tags)
      .filter(([tag, tagArray]) => !selectedTags.includes(tag))
      // .sort((a, b) => b[0][1] - a[0][1]) // Sorting alphabetically
      .sort((a, b) => b[1].length - a[1].length) // Sort by tag occurrences
      .map(([tag, tagArray]) =>
        clickableTagElement(
          tag,
          tagArray.length,
          selectedTags.includes(tag),
          tagSelectionHandler
        )
      );
  return (
    <>
      <div className={`tags__tags side-search ${visible && "opened"}`}>
        {visible && (
          <>
            <div className="tags__tags-prefix" style={{ flexBasis: "100%" }}>
              Search by Tags <span>({tagsElements.length} tags)</span>
            </div>
            <p className="tags__tags-prefix-tip">Choose the tags you’re interested in to see what’s new in the industry</p>
            <div
              className="tags__tags-tags-wrapper bottom-gradient"
            >
              {tagsElements}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default function ViewTags({ tags }) {
  const tagsElements = tags && tags.map(tag => tag ? tagElement(tag) : null);
  return (
    <div className="tags__tags">
      <div className="tags__tags-tags-wrapper">{tagsElements}</div>
    </div>
  );
}
