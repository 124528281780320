import React, { useEffect } from "react";
import { HorizontalBar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "./MassVsPersonalizedCampaigns.scss";
import {
  calculateExactPercentages,
  isMobileDevice,
} from "containers/MarketingOverview/utils/helpers";
import { App, CAMPAIGN_TYPES } from "containers/MarketingOverview/utils/types";

type AppByMassAndPersonalized = {
  app: App;
  byCampaignType: {
    campaignType: string;
    count: number;
  }[];
};

type Props = {
  appsByMassAndPersonalized: AppByMassAndPersonalized[];
};

const CHART_STEP_SIZE = 5;

export const MassVsPersonalizedCampaigns = ({
  appsByMassAndPersonalized,
}: Props) => {
  const labels = appsByMassAndPersonalized.map((rec) => rec.app.displayName);
  const images = appsByMassAndPersonalized.map((rec) => rec.app.icon);

  const mass = appsByMassAndPersonalized.map((rec) => {
    const massCampaign = rec.byCampaignType.find(
      (campaignType) => campaignType.campaignType === CAMPAIGN_TYPES.MASS
    );

    return massCampaign ? massCampaign.count : 0;
  });
  const personalized = appsByMassAndPersonalized.map((rec) => {
    const personalizedCampaign = rec.byCampaignType.find(
      (campaignType) =>
        campaignType.campaignType === CAMPAIGN_TYPES.PERSONALIZED
    );

    return personalizedCampaign ? personalizedCampaign.count : 0;
  });
  const isMobile = isMobileDevice();

  const maxX =
    Math.floor(Math.max(...mass, ...personalized) / CHART_STEP_SIZE) *
      CHART_STEP_SIZE +
    CHART_STEP_SIZE;

  // plugin for displaying images at y scale
  const yScaleImage = {
    id: "yScaleImage",
    afterDatasetsDraw: (chart: any) => {
      const { ctx, scales, canvas } = chart;
      const x = scales["x-axis-0"];
      const y = scales["y-axis-0"];

      ctx.save();

      images.forEach((image, index) => {
        const label = new Image();
        label.src = image;
        const w = 32;
        ctx.drawImage(
          label,
          x.left - w - 5,
          y.getPixelForValue(index) - w / 2,
          w,
          w
        );
      });
    },
  };

  // plugin to total number overtop of each bar
  const barNumberOvertop = {
    id: "barNumberOvertop",
    afterDatasetsDraw: (chart: any) => {
      const {
        ctx,
        data: { datasets },
      } = chart;

      ctx.save();

      datasets.forEach((dataset: any, i: number) => {
        const meta = chart.getDatasetMeta(i);
        if (meta.hidden) return;

        meta.data.forEach((element: any, index: number) => {
          const dataString = dataset.data[index].toString();

          const textWidth = ctx.measureText(dataString).width;

          ctx.font = "12px Inter";
          ctx.fillStyle = "#000000";
          ctx.fillText(
            dataString,
            element._model.x + textWidth / 2,
            element._model.y
          );
        });
      });
    },
  };

  // adjust chart with if more than 7 labels
  useEffect(() => {
    const chart: any = document.querySelector(".mvp-container-body");
    const maxLabelLength = isMobile ? 2 : 4;

    if (!chart) return;

    if (labels.length > maxLabelLength) {
      chart.style!.height = `${labels.length * 100}px`;
    } else {
      chart.style!.height = "100%";
    }
  }, [labels.length]);

  return (
    <div className="mvp-container">
      <div className="mvp-container-body">
        <HorizontalBar
          width={isMobile ? 800 : undefined}
          height={isMobile ? 400 : undefined}
          options={{
            hover: {
              mode: null,
            },
            tooltips: {
              enabled: true,
            },
            maintainAspectRatio: false,
            responsive: true,
            layout: {
              padding: {
                left: 45,
                bottom: 30,
              },
            },
            scales: {
              // hide labels on y scale to display images instead
              yAxes: [
                {
                  display: false,
                  categoryPercentage: 0.45,
                  barPercentage: 1.0,
                },
              ],
              xAxes: [
                {
                  ticks: {
                    min: 0,
                    stepSize: CHART_STEP_SIZE,
                    max: maxX,
                  },
                },
              ],
            },
            legend: {
              position: "top",
              align: isMobile ? "start" : "end",
            },
            plugins: {
              datalabels: {
                color: "#000000",
                font: {
                  size: 14,
                  family: "Inter",
                },
                formatter: (value: number, ctx: any) => {
                  const { datasetIndex, dataIndex } = ctx;
                  const massCount = ctx.chart.data.datasets[0].data[dataIndex];
                  const personalizedCount =
                    ctx.chart.data.datasets[1].data[dataIndex];
                  const exactPercentages = calculateExactPercentages([
                    massCount,
                    personalizedCount,
                  ]);
                  const percentage = exactPercentages[datasetIndex];

                  const currentCount =
                    ctx.chart.data.datasets[datasetIndex].data[dataIndex];

                  let shouldShowPercentage = percentage > 0 && currentCount > 3;

                  if (isMobile && percentage > 0 && currentCount < 10) {
                    shouldShowPercentage = false;
                  }

                  return shouldShowPercentage ? `${percentage.toFixed()}%` : "";
                },
              },
            },
          }}
          plugins={[yScaleImage, barNumberOvertop, ChartDataLabels]}
          data={{
            labels,
            datasets: [
              {
                label: "Mass",
                data: mass,
                backgroundColor: "#7891E5",
              },
              {
                label: "Personalized",
                data: personalized,
                backgroundColor: "#AFC2FF",
              },
            ],
          }}
        />
      </div>
    </div>
  );
};
