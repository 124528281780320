import React, { useState, useEffect } from 'react'

import Icon from '../../common/icon/Icon';
import SearchBar from "../../common/searchbar/SearchBar";
import { Link } from "react-router-dom";
import logo from '../../assets/images/icons/watchful_white_gray_background.png';
import { getNumberOfInsights } from "../../containers/products/InsightsForProduct";

export default function TrackedProducts({
  siteSize,
  products,
  unSeenCriticalInsights,
}) {
  const [productsToShow, setProductsToShow] = useState([]);

  const [searchWord, searchWordChange] = useState("")

  useEffect(() => {
    if (products && unSeenCriticalInsights) {
      setProductsToShow(products)
    }
  }, [products, unSeenCriticalInsights])

  const filterCallback = products => {
    setProductsToShow(products);
  };

  const miniHeader = siteSize === 'large' ? <div>
    <h3 className="currently-tracking"> Currently tracking  ({productsToShow ? productsToShow.length : 0})</h3>
    <div className="search-wrap">
      <SearchBar
        searchables={products}
        fieldToSearch={'name'}
        placeHolder={"Search..."}
        onChange={filterCallback}
        searchWord={searchWord}
        searchWordChange={searchWordChange}
        hideIcon={true}
      />
    </div>
  </div> : null;

  return (

    <div className={`products-tracked ${siteSize}`}>
      {miniHeader}
      <div className={'icon-container'}>
        {products && productsToShow && productsToShow.map(product => {

          const marketingApp = product.apps.find(app => app.platform.toLowerCase() === 'marketing');
          const numOfMarketing = marketingApp && unSeenCriticalInsights[marketingApp.id] ? unSeenCriticalInsights[marketingApp.id].length : 0;
          return (
            <div className={`image-item ${getNumberOfInsights(product, unSeenCriticalInsights) + numOfMarketing > 0 ? 'with-dis': ''}`} key={product.id} onClick={() => { searchWordChange(''); console.log(`/products/${product.id}`) }}>
              <Link to={`/products/${product.id}`}>
                <Icon src={product.thumbnail || logo}
                  title={product.name}
                />
              </Link>
            </div>
          )
        })}
      </div>


    </div>

  );
}
