const SUPPORTED_MOBILE_PATHS = [
  "/intelligence/versions/",
  "/mass_market",
  "/product_intelligence",
];

export const isCurrentPathSupportedOnMobile = () => {
  return SUPPORTED_MOBILE_PATHS.some((path) =>
    window.location.pathname.includes(path)
  );
};

// TODO: remove mobile fix
export const updateViewportMeta = () => {
  const width = window.screen.width;
  const metaTag: any = document.querySelector("meta[name=viewport]");
  const isMobileSupportedOnCurrentRoute = isCurrentPathSupportedOnMobile();

  const breakPoint = 768;
  const mobileMeta =
    "width=1440, initial-scale=0.1, maximum-scale=1, shrink-to-fit=no";
  const defaultMeta =
    "width=device-width, initial-scale=1, maximum-scale=1 , shrink-to-fit=yes";

  if (
    width < breakPoint &&
    metaTag?.content !== mobileMeta &&
    !isMobileSupportedOnCurrentRoute
  ) {
    metaTag.content = mobileMeta;

    const stylesheets: any = document.styleSheets;

    for (let i = 0; i < stylesheets.length; i++) {
      let rules;

      try {
        rules = stylesheets[i].rules || stylesheets[i].cssRules;
      } catch (e) {
        continue;
      }

      for (let j = 0; j < rules.length; j++) {
        if (rules[j].media) {
          rules[j].media.mediaText = "not all";
        }
      }
    }
  }

  const isMobileBreakPointAndMetaIsDefault =
    width < breakPoint && metaTag.content === defaultMeta;

  if (isMobileSupportedOnCurrentRoute || isMobileBreakPointAndMetaIsDefault) {
    metaTag.content = defaultMeta;
  }

  const isLoggedIn =
    sessionStorage.getItem("usertoken") || localStorage.getItem("usertoken");

  // this is required to mobile meta adjustment,
  // it needs to reload to get out of the zoomed out view
  if (isMobileSupportedOnCurrentRoute && width < breakPoint && isLoggedIn) {
    const prevRoute = localStorage.getItem("prevRoute");

    if (prevRoute && prevRoute !== window.location.pathname) {
      localStorage.removeItem("prevRoute");
    }

    if (!prevRoute) {
      localStorage.setItem("prevRoute", window.location.pathname);
      window.location.reload();
    }
  } else {
    localStorage.removeItem("prevRoute");
  }
};
