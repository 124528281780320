import React from 'react'
import GalleryDiscoveries from "../dash-discoveries/GalleryDiscoveries";


export default function GalleryAbTests({
  ABTests,
  products,
  user,
  analytics,
  filter,
  daysAgo,
  customRange,
  customRangeTime
}) {
  return (
    <div>
      {products.sort((p1, p2) => ABTests.filter(dis => p1.apps.map(a => a.id).includes(dis.app_id)) >
        ABTests.filter(dis => p2.apps.map(a => a.id).includes(dis.app_id)) ? -1 : 1).map(product => {
        const filteredAbTests = ABTests.filter(dis => product.apps.map(a => a.id).includes(dis.app_id))
        return <div className='app-ab-tests'>
          <h2 className='app-name-ab-test'>
            <img src={product.thumbnail} />
            {product.name}
            <span className='ab-test-count'>({filteredAbTests.length})</span>
          </h2>
          <GalleryDiscoveries
            loadingFilteredDiscoveries={false}
            filteredDiscoveries={filteredAbTests.slice(0, 4)}
            apps={product.apps.map(prApp => ({
              ...prApp,
              icon: prApp.thumbnail,
              display_name: prApp.name
            }))}
            usePagination={false}
            totalDiscoveries={filteredAbTests.length}
            user={user}
            hideAppName={true}
            showType={false}
            analytics={analytics}
            productName={null}
            handleClick={null}
            source={'abtest_page'}
          />
          {filteredAbTests.length > 4 && <div className='ab-test-view-link'>
            <a href={`/all-discoveries?daysAgo=${daysAgo}&currentPageSize=40&filterIntelEnabled=&filterMarkEnabled=&customRange=${customRange ? '1' : ''}&searchQuery=&filterType=ab_test${filter === 'active' ? ',indication' : '' }&filterEmailSubtype=&filterInAppSubtype=&filterPushNotificationSubtype=&product_ids=${product.id}&order_by_start_time=desc&currentPage=1&customRangeTime=${customRangeTime.map(date => date.format('YYYY-MM-DD'))}&label_ids_any=&filterStatus=${filter}`}>View All</a>
          </div>}
        </div>
      })}


    </div>
  )
}
