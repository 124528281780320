import React, {useState} from 'react';
import {Button, Col, Row} from "antd";
import { Img } from "react-image";
import {Link} from "react-router-dom";
import reportIcon from '../../assets/images/icons/view/watchful-red-black.svg'

import ViewModal from "../../components/discoveryView/viewModal/ViewModal";
import {NEWSLETTER_PLACE_HOLDER, REPORTS_PLACE_HOLDER} from "../reports/ReportsList";
import {sendShareMail} from "../reports/ReportUtils";
import share_ico from "../../assets/images/new_design/icons/share_ico_black.svg";
import reports_placeholder from "../../assets/images/new_design/icons/reports-placeholder.svg"

const ReportPreview = ({report, reportLink, user, analytics, recipients, sendReportShare}) => {
  const [shareModalShow, shareModalShowChange] = useState(false);
  const [commentForShare, commentForShareChange] = useState('');
  const [recipientForShare, recipientForShareChange] = useState(null);
  return (
    <div>
      <Row
        gutter={15}
        type="flex"
        align="middle"
        className="sneak-peak-modal__header"
      >
        <Col>
          <Img
            src={reportIcon}
            className="app-icon"
            alt="app-icon"
            loader={<div className="versions__list__item-loader" />}
            width="50"
            height="50"
          />
        </Col>
        <div className="app-name">{report.name}</div>
        <Col className="version-block">
          {user.email &&

          <div className="extra-buttons">
            <Button onClick={() => { shareModalShowChange(true)}}>
              <img src={share_ico} />
              <span>Share</span>
            </Button>

          </div>}
        </Col>
      </Row>
      {report.description && <Row
        gutter={15}
        type="flex"
        align="middle"
        className="sneak-peak-modal__header"
      >
        <div className="report-description">
          {report.description}
        </div>
      </Row>}
      <Row
        gutter={15}
        type="flex"
        align="middle"
        className="sneak-peak-modal__header"
        style={{paddingBottom: '1rem'}}
      >
        
        <div className="report-thumbnail">
          <img src={report.screenshot_url || reports_placeholder}/>
        </div>
        
      </Row>
      <Row
        gutter={15}
        type="flex"
        align="middle"
        className="sneak-peak-modal__header"
        style={{paddingTop: '1rem'}}
      >

        <div className="report-link">
          {user.email ? 
            <Link to={reportLink}
              target="_blank"
              rel="noopener noreferrer">
            View full Report
            </Link>: null
          }
        </div>

      </Row>
      <ViewModal
        closeModal={() => shareModalShowChange(false)}
        sendEmail={() => sendShareMail(report, recipientForShare, sendReportShare, commentForShare, shareModalShowChange, analytics)}
        modalShow={shareModalShow}
        linkUrl={`${window.location.origin}/reports/${report.id}?source=bi_weekly`}
        recipients={recipients}
        recipient={recipientForShare}
        recipientChange={recipientForShareChange}
        comment={commentForShare}
        commentChange={commentForShareChange}
        subject="report"
        placeHolder={report.is_newsletter ? NEWSLETTER_PLACE_HOLDER : REPORTS_PLACE_HOLDER}
        linkCode={''}
        updateLinkData={() => { }}
        user={user}
        whoCanView={"Anyone with this link, will be able to view this report"}
      />
    </div>
  );
};

export default ReportPreview;