import React from "react";

type Props = {
  src: string;
  className?: string;
  onClick?: () => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
};

export class ClickableIFrame extends React.Component<Props> {
  state = {
    iframeMouseOver: false,
  };

  componentDidMount() {
    window.focus();
    window.addEventListener("blur", this.onWindowBlur);
  }

  componentWillUnmount() {
    window.removeEventListener("blur", this.onWindowBlur);
  }

  onWindowBlur = () => {
    const { iframeMouseOver } = this.state;
    if (iframeMouseOver) {
      this.props.onClick?.();
    }
  };

  handleOnMouseOver = () => {
    this.setState({ iframeMouseOver: true });
  };

  handleOnMouseOut = () => {
    window.focus();
    this.setState({ iframeMouseOver: false });
  };

  render() {
    return (
      <>
        <div
          style={{ display: "inline-block", width: "100%", height: "100%" }}
          onMouseOver={() => {
            this.handleOnMouseOver();
            this.props.onMouseOver?.();
          }}
          onMouseOut={() => {
            this.handleOnMouseOut();
            this.props.onMouseOut?.();
          }}
        >
          <iframe
            className={this.props.className}
            src={this.props.src}
          ></iframe>
        </div>
      </>
    );
  }
}
