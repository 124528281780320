import React from "react";
import "@lottiefiles/lottie-player";
import loader from "../../assets/loader.json"

const Loader = () => (
  <div className="loader">
    <lottie-player
      autoplay
      loop
      mode="normal"
      src={JSON.stringify(loader)}
      style={{width: "55rem"}}
    >
    </lottie-player>
  </div>
);

export default Loader;
