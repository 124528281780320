import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Select, Tooltip } from 'antd';
import Icon  from "@ant-design/icons";
import moment from "moment"
import { getPerformanceRanks } from "../../redux/actions/performanceActions"

import BenchmarkTable from "../benchmark/BenchmarkRanks/BenchmarkTable"


const { Option } = Select;


export const BenchmarkRanks = ({
  categoriesDevices,
  categories,
  categoriesEvents,
  deviceModels,
  apps,
  getPerformanceRanks,
  eventTypes,
  rawRanks,
  analytics
}) => {
  const androidDevice = categoriesDevices.find(dev => dev.platform === "Android")
  const androidDeviceModel = androidDevice ? androidDevice.model : undefined;
  const iosDevice = categoriesDevices.find(dev => dev.platform === "iOS")
  const iosDeviceModel = iosDevice? iosDevice.model : undefined;
  const [currentCategory, changeCurrentCategory] = useState(categories[0] && categories[0].name);
  const [currentDevice, changeCurrentDevice] = useState(androidDeviceModel);
  const [ranks, updateRanks] = useState([]);
  const [currentPlatform, currentPlatformChange] = useState('Android');

  //useEffect
  useEffect(() => {
    if(!categoriesEvents[currentCategory] || !Array.isArray(categoriesEvents[currentCategory])) {
      return
    }
    const filters = {
      model_in: currentDevice,
      category_in: currentCategory,
      event_in: categoriesEvents[currentCategory].join()
    }
    filters.from_date = moment().subtract(3, "months").format("YYYY-MM-DD");
    getPerformanceRanks(filters)
  }, [currentCategory, currentDevice])

  useEffect(() => {
    updateRanks([...rawRanks].map(rank => {
      return {
        ...apps.find(app => app.package === rank.package_name),
        rank: rank
      }
    }))
  }, [rawRanks])

  const handleChangeDevice = (dev) => {
    changeCurrentDevice(dev)
    analytics.analytic(`Change benchmark device on Overview`,
      {
        category: currentCategory,
        device: dev,
        platform: currentPlatform,
      })
    currentPlatformChange(categoriesDevices.find(category=>category.model === dev).platform)
  }

  const handleChangeCategory = (val) => {
    changeCurrentCategory(val);
    analytics.analytic("Change benchmark category on Overview",
      {
        category: val,
        device: currentDevice,
        platform: currentPlatform,
      }
    )
  }

  return (
    <div className="dash-over-ranks-table">
      <h3 className="title">Benchmark Ranks</h3>
      <div className="over-bench-ranks-filters">
        <div className="filter-label">Platform</div>
        <Select
          style={{ width: 200, margin: '0 1rem' }}
          value={currentDevice}
          onChange={handleChangeDevice}
        >
          {androidDeviceModel && <Option value={androidDeviceModel}>Android</Option>}
          {iosDeviceModel && <Option value={iosDeviceModel}>iOS</Option>}
        </Select>

        <div className="filter-label">Category</div>
        <Select
          style={{ width: 200, margin: '0 1rem' }}
          onChange={handleChangeCategory}
          value={currentCategory}
        >
          {categories.map(cat =>
            <Option value={cat.name} key={cat.name}>{cat.name}</Option>
          )}
        </Select>

      </div>

      <div className="dash-over-bench-tables">
        {ranks && ranks.length !== 0 && categoriesEvents[currentCategory] && categoriesEvents[currentCategory].sort((ev1, ev2) => ev1 === "launch" ? -1 : 1).map((event, i) => {
          return <div key={event}>
            <h3>{eventTypes.find(ev => ev.name === event).title}
              <Tooltip placement="top" title={eventTypes.find(ev => ev.name === event).description} >
                <Icon type="info-circle" className="ranks-filter-info" />
              </Tooltip>
            </h3>

            <BenchmarkTable apps={ranks.filter(app => app.rank.event_name === event)} noRequest={i != 0} mode={"small"}  daysAgo={7} currentDevice={currentDevice} />
          </div>
        })}
      </div>

      <div className="aggr-device">
        *Calculations above are aggregated based on the device
        <strong>
          {deviceModels.find(dev => dev.model === currentDevice) ? deviceModels.find(dev => dev.model === currentDevice).product_name : ''}
        </strong>
      </div>

    </div>
  )
}

const mapStateToProps = ({ appData, performance }) => ({
  apps: performance.apps || [],
  deviceModels: performance.deviceModels,
  categories: performance.categories,
  eventTypes: performance.eventTypes || [],

  categoriesEvents: performance.categoriesEvents,
  categoriesDevices: performance.categoriesDevices,
  rawRanks: performance.ranks || [],
  analytics: appData.user.analytics,
})

const mapDispatchToProps = {
  getPerformanceRanks
}

export default connect(mapStateToProps, mapDispatchToProps)(BenchmarkRanks)
