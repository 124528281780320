import React, { useState, useEffect, useRef, FC, ReactElement } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { IState } from '../../interfaces/Product'
import { IReport, IShare } from '../../interfaces/Reports'
import { NEWSLETTER_PLACE_HOLDER, REPORTS_PLACE_HOLDER } from "./ReportsList";
import { Button, notification } from "antd"

import "./ReportsView.scss"
import watchful_logo_white from "../../assets/images/new_design/wf-logo-red-white-2021.svg"
import report_prev_ico from "../../assets/images/new_design/report-prev.svg"
import report_next_ico from "../../assets/images/new_design/report-next.svg"
import share_ico from "../../assets/images/new_design/icons/share-ico.svg"
import download_ico from "../../assets/images/new_design/icons/download-ico.svg"
import close_ico from "../../assets/images/new_design/icons/close-ico.svg"

import ViewModal from '../../components/discoveryView/viewModal/ViewModal';
import { getRecipients } from '../../redux/actions/directLinkActions';
import { sendReportShare } from '../../redux/acts/reports';
import { setReportsNotificationsSeen, setReportsNotificationsRead } from "../../redux/acts/reports"
import analyticsService from "../../services/analyticsService";

type IReportView = {
  match: any,
  history: { push(url: string): void; length: number; goBack(): void; replace(url: string): void; location: any },
  reports: IReport[],
  recipients: any,
  setReportsNotificationsRead: (arg0: number[]) => void,
  setReportsNotificationsSeen: (arg0: number[]) => void,

  getRecipients: () => void,
  sendReportShare: (sendData: IShare) => void,
  analytics: analyticsService,
  sharingReport: boolean,
  user: any,
}

const ReportView: FC<IReportView> = ({
  match,
  reports,
  history,
  recipients,
  getRecipients,
  setReportsNotificationsRead,
  setReportsNotificationsSeen,
  sendReportShare,
  analytics,
  user,
  sharingReport,
}): ReactElement | null => {
  const reportID = Number(match.params.id);
  const report = reports.find(r => r.id === reportID)
  const reportIndex = reports.findIndex(r => r.id === reportID)
  const [shareModalShow, shareModalShowChange] = useState(false)
  const [recipientForShare, recipientForShareChange] = useState('')
  const [commentForShare, commentForShareChange] = useState('')

  const url = new URLSearchParams(history.location.search)

  useEffect(() => {
    if (!recipients) {
      getRecipients();
    }

    window.onmessage = (e: any) => {
      if (e.data.action == 'pdfLink') {
        Object.assign(document.createElement('a'), {
          target: '_blank',
          href: e.data.url,
        }).click();
      }
    };

  }, [])

  useEffect(() => {
    if (sharingReport) {
      notification["success"]({
        message: 'Report has been shared!',
        duration: 2
      });
    }
  }, [sharingReport])

  const closeReportView = () => {
    //TEMP SOLUTION
    let from_url = url.get('from')
    history.push(from_url ? from_url : '/overview')
  }

  const prevReportHandler = () => {
    let newID = reports[reportIndex - 1].id
    history.replace(`/reports/${newID}?source=reports_page`)
  }

  const nextReportHandler = () => {
    let newID = reports[reportIndex + 1].id
    history.replace(`/reports/${newID}?source=reports_page`)
  }

  const shareHandler = () => {
    shareModalShowChange(true)
  }
  const sendShareMail = () => {
    sendReportShare({
      report_id: reportID,
      to: [recipientForShare],
      // @ts-ignore
      comment: commentForShare || report.is_newsletter ? NEWSLETTER_PLACE_HOLDER : REPORTS_PLACE_HOLDER,
    });
    shareModalShowChange(false);
    if (report) {
      analytics.analytic(`User shared report ${report.id} - ${report.name} with ${recipientForShare}: ${commentForShare}`)
    }
  }


  useEffect(() => {
    setReportsNotificationsRead([reportID])
    setReportsNotificationsSeen([reportID])
    if (report) {
      analytics.analytic('View report',
        {
          report_name: report.name,
          products: report.products,
          reportUrl: report.preview_url,
          source: url.get('source'),
        })
    }
  }, [match.params.id])

  const handleDownloadReport = () => {
    if (report) {
      analytics.analytic('Download report', {
        name: report.name,
        url: report.url
      })
    }
  }

  if (!report) return null

  return (
    <div className="report-view-page">
      <div className="report-view-header-wrapper">
        <div className="report-view-header">
          <div className="logo-white">
            <img src={watchful_logo_white} />
          </div>
          <h1>{report.name}</h1>
          <div className="report-view-header-buttons">
            <div className="report-share">
              <Button onClick={shareHandler}>
                <img src={share_ico} />
                Share</Button>
            </div>
            <a href={report.url} className="report-download" download onClick={handleDownloadReport}>
              <Button>
                <img src={download_ico} />
                Download</Button>
            </a>
            <div className="report-view-header-buttons-close" onClick={closeReportView}>
              <img src={close_ico} />
            </div>
          </div>
          <Button
            className={`report-btn report-prev-btn ${reportIndex === 0 ? 'disabled' : ''}`}
            disabled={reportIndex === 0}
            onClick={prevReportHandler}
          >
            <img src={report_prev_ico} />
            <span className={`arrow ${reportIndex === 0 ? "opaque-arrow" : ""}`}>Previous Report</span>
          </Button>
          <Button
            className={`report-btn report-next-btn ${reportIndex === reports.length - 1 ? 'disabled': ''}`}
            disabled={reportIndex === reports.length - 1}
            onClick={nextReportHandler}
          >
            <span className={`arrow ${reportIndex === reports.length - 1 ? "opaque-arrow" : ""}`}>Next Report</span>
            <img src={report_next_ico} />
          </Button>
        </div>
      </div>
      <div className="shadow" onClick={closeReportView} />
      <div className="report-pdf-wrapper">
        <iframe
          src={`/renderPDF.html?file=${report.preview_url}`}
          width={window.innerWidth > 850 ? "800px" : window.innerWidth > 520 ? `${window.innerWidth - 50}px` : `100%`}
          height="100%"
          style={{ border: 'none' }} />
      </div>


      <ViewModal
        closeModal={() => shareModalShowChange(false)}
        sendEmail={sendShareMail}
        modalShow={shareModalShow}
        linkUrl={`${window.location.origin}/reports/${report.id}?source=reports_shared`}
        recipients={recipients}
        recipient={recipientForShare}
        recipientChange={recipientForShareChange}
        comment={commentForShare}
        commentChange={commentForShareChange}
        subject="report"
        whoCanView="Logged in users, will be able to view the report"
        placeHolder={report.is_newsletter ? NEWSLETTER_PLACE_HOLDER : REPORTS_PLACE_HOLDER}
        linkCode={''}
        user={user}
        updateLinkData={() => { }}
      />

    </div>
  )
}

export default withRouter(
  //@ts-ignore
  connect((state: IState) => {
    return {
      user: state.appData.user,
      reports: state.reports.reports,
      recipients: state.linkData.recipients,
      analytics: state.appData.user.analytics,
      sharingReport: state.reports.sharingReport,
    }
  }, {
    getRecipients,
    setReportsNotificationsSeen,
    setReportsNotificationsRead,
    sendReportShare,
  })(ReportView))

