import { call, fork, put, takeLatest } from "redux-saga/effects";
import {
  USER_JOURNEYS_ACTIONS,
  getUserFlowHistorySuccess,
  getUserFlowPersonasSuccess,
  getUserFlowsSetSuccess,
  getUserFlowsSetsSuccess,
  getUserFlowsSucceess,
  getUserJourneysAppsSucceess,
} from "../acts/userJourenys";
import {
  getUserFlowSetHistory,
  getUserFlows,
  getUserFlowsApps,
  getUserFlowsPersonas,
  getUserFlowsSetsLastPerApp,
} from "../api/userJourenysApi";

function* getUserJourneysAppsSaga() {
  // @ts-ignore
  const result = yield call(getUserFlowsApps);
  yield put(getUserJourneysAppsSucceess(result.data.data));
}

function* watchGetUserJourneysApps() {
  yield takeLatest(
    USER_JOURNEYS_ACTIONS.GET_USER_JOURNEYS_APPS,
    getUserJourneysAppsSaga
  );
}

function* getUserFlowsSaga(action: any) {
  // @ts-ignore
  const resultUserFlows = yield call(getUserFlows, action.payload);

  yield put(
    getUserFlowsSucceess({
      userFlows: resultUserFlows.data.data,
    })
  );
}

function* watchGetUserFlows() {
  yield takeLatest(USER_JOURNEYS_ACTIONS.GET_USER_FLOWS, getUserFlowsSaga);
}

function* getUserFlowPersonasSaga(action: any) {
  // @ts-ignore
  const res = yield call(getUserFlowsPersonas, action.payload);

  yield put(getUserFlowPersonasSuccess(res.data.data));
}

function* watchGetUserFlowPersonas() {
  yield takeLatest(
    USER_JOURNEYS_ACTIONS.GET_USER_FLOW_PERSONAS,
    getUserFlowPersonasSaga
  );
}

function* getUserFlowsSetsSaga(action: any) {
  // @ts-ignore
  const res = yield call(getUserFlowsSetsLastPerApp, action.payload);

  yield put(getUserFlowsSetsSuccess(res.data.data));
}

function* watchGetUserFlowsSets() {
  yield takeLatest(
    USER_JOURNEYS_ACTIONS.GET_USER_FLOWS_SETS,
    getUserFlowsSetsSaga
  );
}

function* getUserFlowsSetSaga(action: any) {
  // @ts-ignore
  const res = yield call(getUserFlowsSetsLastPerApp, action.payload);

  yield put(
    getUserFlowsSetSuccess({
      user_flow_set: res.data.data[0],
      user_flow_set_id: action.meta.user_flow_set_id,
    })
  );
}

function* watchGetUserFlowsSet() {
  yield takeLatest(
    USER_JOURNEYS_ACTIONS.GET_USER_FLOWS_SET,
    getUserFlowsSetSaga
  );
}

function* getUserFlowHistory(action: any) {
  // @ts-ignore
  const res = yield call(getUserFlowSetHistory, action.payload);

  yield put(getUserFlowHistorySuccess(res.data.data));
}

function* watchGetUserFlowHistory() {
  yield takeLatest(
    USER_JOURNEYS_ACTIONS.GET_USER_FLOW_HISTORY,
    getUserFlowHistory
  );
}

export const userJourneysSagas = [
  fork(watchGetUserJourneysApps),
  fork(watchGetUserFlows),
  fork(watchGetUserFlowPersonas),
  fork(watchGetUserFlowsSets),
  fork(watchGetUserFlowHistory),
  fork(watchGetUserFlowsSet),
];
