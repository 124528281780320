import { gql } from "@apollo/client";

export const GET_MARKETING_APPS_QUERY = gql`
  query GetMarketingApps {
    marketing {
      apps {
        id
        displayName
        icon
        isCustomerOwnApp
      }
    }
  }
`;

export const GET_MARKETING_DATA_QUERY = gql`
  query GetMarketingData(
    $appIds: [Int]
    $insightTypes: [String]
    $startTimeGte: Date
    $startTimeLt: Date
  ) {
    marketing {
      overview(
        appIds: $appIds
        insightTypes: $insightTypes
        startTimeGte: $startTimeGte
        startTimeLt: $startTimeLt
      ) {
        appInsights {
          appId
          count
        }
        appInsightTypes {
          appId
          insightType
          count
        }
        appInsightTypesByDates {
          date
          appId
          insightType
          count
        }
        appFocusAreas {
          appId
          count
          focusArea {
            id
            name
          }
        }
        appPersonas {
          appId
          persona {
            id
            title
            description
            roles {
              id
              name
            }
          }
          count
        }
        appCampaignTypes {
          appId
          campaignType
          count
        }
      }
    }
  }
`;
