import React, { Component, useEffect } from 'react'
import moment from "moment"
import { Tooltip } from 'antd';
import Icon  from "@ant-design/icons";

import defaultLogo from "../../assets/images/watchful_icon_gray.svg"
import marketing_ico from "../../assets/images/icons/dis-card-mark.svg"
import intel_ico from "../../assets/images/icons/dis-card-intel.svg"

import persona_ico from "../../assets/images/new_design/icons/persona-ico.svg"
import rate_star_ico from "../../assets/images/new_design/rate-dis-star.svg"
import rate_star_fill_ico from "../../assets/images/new_design/rate-dis-star-fill.svg"
import rate_star_semifill_ico from "../../assets/images/new_design/rate-dis-star-semifill.svg"
import { getPlatformImage } from "../../helpers/generalHelpers";
import { useIsFeatureByUserID } from "../../helpers/features"
import {MARKETING} from "../../containers/intelligence/Marketing/configsSubtypes";
import {PL_MARKETING} from "../../shared/consts";


export const DiscoveryTypeBadge = ({ isMarketingDiscovery }) => {
  return <div className={`discovery-type ${isMarketingDiscovery ? 'mark' : 'intel'}`}>
    {isMarketingDiscovery
      ?
      <div><img src={marketing_ico} />Campaign</div>
      :
      <div><img src={intel_ico} />Intelligence</div>
    }
  </div>
}

const DiscoveryCard = ({
  data, app, onClick, transparent, showType,
  hideAppName,
  user
}) => {
  const isMarketingDiscovery = data.platform === PL_MARKETING;
  const disRatingIsEnable = useIsFeatureByUserID('discovery_rating', user.email)


  return (
    <div className={`discovery-card ${transparent ? 'transparent' : ''}`} onClick={onClick}>
      {data.relevancy >= 1 &&
        <div className={`importance-indicator ${data.read_at ? '' : 'importance-indicator-animating'}`} />}
      {data.read_at &&
        <div className="discovery-read">
          <div><Icon type="eye" /></div>
          <h3>Discovery Viewed</h3>
          <div className="discovery-read-date">
            {moment(data.read_at).format('MMMM DD, YYYY')}
          </div>
        </div>
      }

      <div className="img-wrap">
        <img src={data.thumbnail || defaultLogo} className={`insight-thumb ${data.thumbnail ? '' : 'default'}`} />

        <div className="img-shadow"/>
      </div>
      <div className="descr">
        {disRatingIsEnable && data.rating_global && <div className='discovery-rating'>
          {[1, 2, 3, 4, 5].map(index =>
            data.rating_global % 1 > 0.29 && data.rating_global % 1 < 0.8 && data.rating_global > index - 1 && data.rating_global < index ?
              <img src={rate_star_semifill_ico} key={index} />
              :
              data.rating_global >= index || (index - data.rating_global <= 0.2) ?
                <img src={rate_star_fill_ico} key={index} /> :
                <img src={rate_star_ico} key={index} />
          )}
        </div>}
        {data.title}
      </div>
      {data.persona_roles.length > 0 && <div className="dis-card-roles">
        <Tooltip overlayClassName='personas-tooltip-wrapper' title={<div className="tooltip-wrapper">{data.persona_roles.map(role => <div className="role-item">{role}</div>)}</div>}>
          <div className="roles-img"><img src={persona_ico} /></div>
        </Tooltip>

        <div className={'roles-wrapper'}>
          {data.persona_roles.join(', ')}
        </div>

      </div>}
      <div className="app-data">
        {!hideAppName && <span><img src={app.icon || defaultLogo} /> {app.display_name}</span>}
        {!isMarketingDiscovery && !showType && <img src={getPlatformImage(app.platform)} className="platform" />}
        {showType && <DiscoveryTypeBadge isMarketingDiscovery={isMarketingDiscovery} />}
      </div>
    </div>
  )
}

export default DiscoveryCard