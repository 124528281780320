import React from "react";

const PerformanceGrayBar = () => {
  return (
    <div className="bar-gray">
      {Array.apply(null, Array(20)).map((i, k) => {
        // if (main && )
        const percentage = (k + 1) * 5;
        return (
          <div
            key={k}
            style={{
              left: percentage + "%"
            }}
            className="bar-mark"
          ></div>
        );
      })}
    </div>
  );
};

export default React.memo(PerformanceGrayBar);
