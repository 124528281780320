import {
  takeLatest,
  call,
  put,
  fork, takeEvery,
} from "redux-saga/effects";
import * as personasAPI from "../api/personaApi";
import {  getPersonasSuccess } from '../acts/personas'
import * as actionTypes from "../actions/_actionsTypes";

import * as aT from "../actions/_actionsTypes";


function* getPersonas(action: object) {
  // @ts-ignore
  const result = yield call(personasAPI.getPersonas, action.payload)
  yield put(getPersonasSuccess(result.data))
}
function* watchPersonas() {
  yield takeEvery(actionTypes.GET_PERSONAS, getPersonas);
}

const personasSagas = [
  fork(watchPersonas),
];

export default personasSagas;

