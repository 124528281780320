import * as aT from "./_actionsTypes";

export const getSignificantUnseenDiscoveries = (data) => ({
  type: aT.GET_SIGNIFICANT_UNSEEN_DISCOVERIES,
  payload: data
});

export const getSignificantUnseenDiscoveriesSuccess = (data) => ({
  type: aT.GET_SIGNIFICANT_UNSEEN_DISCOVERIES_SUCCESS,
  payload: data
});


export const getDiscoveries = (data) => ({
  type: aT.GET_PRODUCT_DISCOVERIES,
  payload: {
    gets: data.gets
  }
})

export const getDiscoveriesSuccess = data => ({
  type: aT.GET_PRODUCT_DISCOVERIES_SUCCESS,
  payload: data
});

export const getTrackedSince = (product_id) => ({
  type: aT.GET_TRACKED_SINCE,
  payload: product_id,
});

export const getTrackedSinceSuccess = data => ({
  type: aT.GET_TRACKED_SINCE_SUCCESS,
  payload: data,
})

export const setDiscoveryRate = data => ({
  type: aT.SET_DISCOVERY_RATE,
  payload: data,
})

export const setDiscoveryRateSuccess = data => ({
  type: aT.SET_DISCOVERY_RATE_SUCCESS,
  payload: data,
})