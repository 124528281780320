import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  initAppData,
  setImpersonateUser
} from "../../redux/actions/dashboardActions";
import { initLinkData } from "../../redux/actions/directLinkActions";
import { getNotifications } from "../../redux/acts/notifications"
import { getReports } from "../../redux/acts/reports"

import { withRouter } from "react-router-dom";
import DropdownMenu from "../../common/dropdown/dropdownMenu/DropdownMenu";
import SearchBar from "./searchBar/SearchBar";

import NotificationCenter from "./notifications/NotificationCenter"
import ReportsCenter from "./reportsCenter/ReportsCenter"

import { siteVersions } from "../../shared/consts";

import {
  compareStrings,
} from "../../services/stringsService";

import Sidebar from "../../components/sidebar/Sidebar"
import { getProducts, getUnseenCriticalInsights } from "../../redux/actions/overviewActions"

import watchful_logo from '../../assets/images/wf-new-logo.svg'
import watchful_logo_small from '../../assets/images/watchful_icon.svg'
import { ReactComponent as notification_bell_ico } from "../../assets/images/new_design/icons/notification_bell.svg"
import reports_ico from "../../assets/images/new_design/report-ico.svg"
import { getSortedProducts } from '../products/ProductsSelector';

import {  Popover, Badge, Button } from "antd"
import Icon  from "@ant-design/icons";
import DaysToTrialExpiration from "../../components/trialLicense/DaysToTrialExpiration";
import AccessRequestModal from "./AccessRequestModal"
import { isMobileDevice } from "containers/MarketingOverview/utils/helpers";
import { MenuToggle } from "./menu-toggle";


const Header = ({
  user,
  apps,
  analytics,
  showSearch,
  className,
  userData,
  history,
  siteSize,
  products,
  notifications = [],
  siteVersion,
  getProducts,
  unSeenCriticalInsights,
  getUnseenCriticalInsights,
  getNotifications,
  getReports,
  reports,
  sharedInsightData,
  guest,
  linkReferrer,
}) => {

  const [notificationCenterOpened, notificationCenterOpenedChange] = useState(false)

  const [reportsCenterOpened, reportsCenterOpenedChange] = useState(false)
  const [searchWord, searchWordChange] = useState()
  const [isRequestModalOpen, isRequestModalOpenChange] = useState(false)


  useEffect(() => {
    //make request only for authorized user
    if (!products && user.email) {
      getProducts()
      getUnseenCriticalInsights()
      getNotifications()
      getReports()
    }
  }, [user]);

  const handleVisibleChange = visible => {
    notificationCenterOpenedChange(visible)
    if (visible) {
      analytics.analytic('User opened notification center');
    }
  };

  const handleVisibleReportChange = visible => {
    reportsCenterOpenedChange(visible)
    if (visible) {
      analytics.analytic('User opened reports center');
    }
  };

  const handleOpenRequestModal = () => {
    isRequestModalOpenChange(true)
    analytics.analytic('Viewer opened request access dialog',
      {
        location: 'shared-insight',
        insight_name: sharedInsightData.insight_name,
        insight_type: sharedInsightData.insight_type,
        product: sharedInsightData.app_name,
        platform: sharedInsightData.platform,
      }
    )
  }


  const fullVersion = compareStrings(siteVersion, siteVersions.FULL);
  const isMobile = isMobileDevice();

  return (
    <header className={`header__wrap ${className}`}>
      <div className={`header-logo `}>
        <img
          src={watchful_logo}
          alt="watchful"
          className={`header__icons-logo large ${!user.email ? 'not-point' : ''}`}
          onClick={() => history.push('/')}
        />
        <img
          src={watchful_logo_small}
          alt="watchful"
          className={`header__icons-logo normal ${!user.email ? 'not-point' : ''}`}
          onClick={() => history.push('/')}
        />

        <div className={`sidebar-wrap ${siteSize}`}>
          <Sidebar
            siteSize={siteSize}
            products={products}
            unSeenCriticalInsights={unSeenCriticalInsights} />
        </div>
      </div>
      <div className="search-account">
        <SearchBar className="header__search-bar" newline={false} apps={apps} analytics={analytics} disabled={!fullVersion} searchWord={searchWord}
          searchWordChange={searchWordChange} />
        <div className="search-flex">
          {reports && fullVersion &&
            <Popover
              content={<ReportsCenter closeReportCenter={() => reportsCenterOpenedChange(false)} />}
              trigger="click"
              placement="bottom"
              overlayClassName="report-center-wrapper"
              visible={reportsCenterOpened}
              onVisibleChange={handleVisibleReportChange}
            >
              <div className="reports-center">
                <Badge count={reports.filter(n => !n.app_seen_at).length}>
                  <img src={reports_ico} />
                </Badge>
              </div>
            </Popover>
          }

          {fullVersion &&
            <Popover
              content={<NotificationCenter closeNotCenter={() => notificationCenterOpenedChange(false)} />}
              trigger="click"
              placement="bottom"
              overlayClassName="notification-center-wrapper"
              visible={notificationCenterOpened}
              onVisibleChange={handleVisibleChange}
            >
              <div className="notification-bell">

                <Badge count={notifications.filter(n => !n.app_seen_at && (n.display_place === 'center' || n.display_place === 'both')).length}>
                  <Icon component={notification_bell_ico} />
                </Badge>
              </div>
            </Popover>
          }

          <div className={showSearch ? 'header__dropdown' : 'big header__dropdown'}>
            <DropdownMenu
              user={user}
              userData={userData}
              white
            />
            {!user.email && <div className="request-btn-wrap"><Button onClick={handleOpenRequestModal}>Request Access</Button></div>}
            {userData.days_to_trial_expiration &&
              <div className={"trial-licence-wrapper"}>
                <DaysToTrialExpiration daysLeft={userData.days_to_trial_expiration} />
              </div>}
          </div>
          {isMobile && <div>
            <MenuToggle />
            </div>}
        </div>
      </div>


      <div className="right-space" />
      
      <AccessRequestModal 
        isRequestModalOpen={isRequestModalOpen}
        isRequestModalOpenChange={isRequestModalOpenChange}
        analytics={analytics}
        sharedInsightData={sharedInsightData}
        guest={guest}
        linkReferrer={linkReferrer}
      />
    </header >
  )
}


const mapStateToProps = (state) => {
  return {
    user: state.appData.user,
    apps: state.appData.apps,
    sharedInsightData: state.appData.sharedInsightData,
    analytics: state.appData.user.analytics,
    userData: state.userData,
    guest: state.linkData.guest,
    linkReferrer: state.linkData.user,
    products: getSortedProducts(state),
    unSeenCriticalInsights: state.overview.unSeenCriticalInsights,
    notifications: state.notifications.notifications,
    allInsights: state.appData.allInsights,
    reports: state.reports.reports,
    siteVersion: state.appData.siteVersion,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    initAppData,
    initLinkData,
    setImpersonateUser,
    getProducts,
    getUnseenCriticalInsights,
    getNotifications,
    getReports
  })(Header)
);
