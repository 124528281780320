import axios from 'axios';
import config from "../../shared/config";

export const postUserDiscoveryCommentAPI = ({insight_id, comment_text}) => {
  const url = `${config.dataApi.backendUrl}/insight/${insight_id}/comments`;
  return axios.post(url, {
    body: comment_text
  });
};

export const deleteUserDiscoveryCommentAPI = ({comment_id}) => {
  const url = `${config.dataApi.backendUrl}/insight/comments/${comment_id}`;
  return axios.delete(url);
};


export const editUserDiscoveryCommentAPI = ({comment_id, comment_text}) => {
  const url = `${config.dataApi.backendUrl}/insight/comments/${comment_id}`;
  return axios.put(url, {
    body: comment_text
  });
};
