export const appsSorterFunction = (a, b) => {
  // regular sort returns numbers before characters
  if (!isNaN(parseInt(a.charAt(0), 10))) {
    // Is a number
    return 1;
  }
  if (!isNaN(parseInt(b.charAt(0), 10))) {
    // Is a number
    return -1;
  }
  return a.localeCompare(b);
};

export const filterDeviceLocationByUser = (deviceLocations, user) => {

  deviceLocations.forEach(loc => {
    loc.disabled = !user.locations.includes(loc.filterValue);
  })
  return deviceLocations
}

export const insightURL = ({appID, versionID, insightID, isMarketing}) => {
  return isMarketing ? `
    https://app.watchful.ai/marketing/${insightID}` :
    `https://app.watchful.ai/intelligence/versions/${appID}/${versionID}/${insightID}`;
};