import React from "react";
import { Dropdown } from "antd"

const MAXVERSIONLENGTH = 10

const CardVersion = ({ icon, title, version, countChildren, platform }) => {
  return (
    <div className={`analyzed-app`}>
      <div className={`icon-wrap app-platform-${platform.toLowerCase()}`}>
        <img src={icon} alt="app-icon" width="30" height="30" />
      </div>
      <div className="text">
        <div className="analyzed-app__title">{title}</div>
        <div className="analyzed-app__version">V.{version.substr(0, MAXVERSIONLENGTH)}{version.length > MAXVERSIONLENGTH - 1 ? '...' : ''}</div>
      </div>
      {countChildren &&
        <div className="analyzed-app-count-children">{countChildren + 1}</div>
      }
    </div>
  );
}

export const OverviewAnalyzedVersion = ({ icon, title, version, children, platform }) => {
  return (
    <div>
      {children.length > 0 ?

        <Dropdown menu={<>{
          children.map(cv => <CardVersion icon={cv.icon} title={cv.title} version={cv.release_name} key={cv.id} platform={cv.platform} />)
        }</>} placement="top" overlayClassName="analyzed-app-children-versions" >
          <div>
            <CardVersion icon={icon} title={title} version={version} countChildren={children.length} platform={platform} />
          </div>
        </Dropdown>
        :
        <div>
          <CardVersion icon={icon} title={title} version={version} platform={platform} />
        </div>}
    </div>);
};
