import {createSelector} from "reselect";

const getReachRate = (state: any) => state.abTestsData.reachRate || {};



export const reachRateSelector = createSelector(
  [ getReachRate ],
  (reachRate: any) => {
    const reachRateDates = Object.keys(reachRate)
    if (reachRateDates) {
      if (reachRate[reachRateDates[0]] && reachRate[reachRateDates[0]].true) {
        reachRateDates.forEach((date: string) => {
          if (!reachRate[date].false) {
            reachRate[date].false = 1 - reachRate[date].true;
          }
        })
      }
      if (reachRate[reachRateDates[0]] && reachRate[reachRateDates[0]].false) {
        reachRateDates.forEach((date:string) => {
          if (!reachRate[date].true) {
            reachRate[date].true = 1 - reachRate[date].false;
          }
        })
      }
           
    }
    return reachRate
  }
);