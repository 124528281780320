import React, { useState } from 'react'
import { Button, Input, Avatar, Popconfirm } from 'antd'
import moment from 'moment'

import comment_edit_ico from "../../../assets/images/icons/comments/comment_edit.svg"
import comment_delete_ico from "../../../assets/images/icons/comments/comment_delete.svg"

export default function DisComment({
  comment,
  userID,
  deleteUserDiscoveryCommentHandle,
  editUserDiscoveryCommentHandle
}) {

  const [editMode, editModeChange] = useState(false)
  const [editModeText, editModeTextChange] = useState(false)

  const sendRequestEditComment = () => {
    editUserDiscoveryCommentHandle({
      comment_text: editModeText,
      comment_id: comment.id
    })
    setTimeout(() => {
      editModeChange(false)
    }, 500);
  }

  let time = comment.created_at

  return (
    <div className='dcl-comment' key={comment.id}>
      <div className='dcl-comment-header'>
        {userID === comment.user.id && !editMode && <div className='dcl-comment-management'>
          <img src={comment_edit_ico} onClick={() => { editModeChange(true); editModeTextChange(comment.body) }} />
          <Popconfirm
            placement="topLeft"
            title={'Are you sure?'}
            onConfirm={() => deleteUserDiscoveryCommentHandle(comment.id)} okText="Yes" cancelText="No"
          >
            <img src={comment_delete_ico} />
          </Popconfirm>
        </div>}
        <div className='dcl-comment-avatar'>
          {comment.user.icon ?
            <Avatar size={30} src={comment.user.icon} className="profile-avatar" />
            :
            <Avatar size={30} className="def-avatar" >
              {comment.user.first_name && comment.user.last_name ?
                `${comment.user.first_name[0].toUpperCase()}${comment.user.last_name[0].toUpperCase()}` :
                comment.user.email.substring(0, 2).toUpperCase()}

            </Avatar>
          }
        </div>
        <div className='dcl-comment-name-time-cont'>
          <div className={`dcl-comment-name ${comment.user.is_disabled ? 'dis' : ''}`}>
            {comment.user.name}
            {comment.user.is_disabled ? <div className='removed'>(Removed User)</div> : ''}
          </div>
          <div className='dcl-comment-time'>{moment.utc(time).local().format('ll')} - {moment.utc(time).local().format('HH:mm')}</div>
        </div>
      </div>
      {editMode ?
        <div className='dcl-comment-edit'>
          <Input.TextArea value={editModeText} onChange={e => editModeTextChange(e.target.value)} rows={3}>
          </Input.TextArea>
          <div className='dcl-comment-edit-btns'>
            <Button className='dcl-comment-edit-cancel' onClick={() => editModeChange(false)}>Cancel</Button>
            <Button disabled={editModeText === ''} className='dcl-comment-edit-send black' onClick={sendRequestEditComment}>Save</Button>
          </div>
        </div>
        :
        <div className='dcl-comment-text'>
          {comment.body}
          {comment.updated_at && <span className='dcl-comment-edited'>(edited)</span>}
        </div>}
    </div>
  )
}
