import { useQuery } from "@apollo/client";
import {
  GET_INSIGHTS_BY_IDS,
  GET_TIMELINE_APPS,
  GET_TIMELINE_DATA,
  GET_TIMELINE_PERSONAS,
} from "gql/marketing/context-timeline";
import { useMemo } from "react";
import {
  App,
  Event,
  EventByDate,
  EventWithInsights,
  Persona,
} from "../context/types";
import {
  GET_MARKETING_INSIGHT_BY_ID_QUERY,
  GET_MARKETING_INSIGHT_PERSONAS_AND_FOCUS_AREA_QUERY,
} from "gql/marketing/campaigns";
import moment from "moment";
import {
  darkenColor,
  generateBrightTransparentColor,
} from "containers/MarketingCampaings/utils/helpers";

type Props = {
  appId: number | null;
  personaId: number | null;
  date: string | null;
  insightId: number | null;
};

export const useTimelineData = ({
  appId,
  personaId,
  date,
  insightId,
}: Props) => {
  const { data: timelineAppsData, loading: timelineAppsLoading } =
    useQuery(GET_TIMELINE_APPS);

  const { data: timelinePersonasData, loading: timelinePersonasLoading } =
    useQuery(GET_TIMELINE_PERSONAS, {
      variables: {
        appId,
      },
      skip: !appId,
    });

  const { data: timelineData, loading: timelineDataLoading } = useQuery(
    GET_TIMELINE_DATA,
    {
      variables: {
        appId,
        personaId,
      },
      skip: !appId || !personaId,
    }
  );

  const timelineApps: App[] = useMemo(() => {
    if (timelineAppsData) {
      return timelineAppsData.marketing.apps;
    }

    return [];
  }, [timelineAppsData]);

  const timelinePersonas: Persona[] = useMemo(() => {
    if (timelinePersonasData) {
      const personas: Persona[] = timelinePersonasData.marketing.personas.map(
        (p: any) => {
          const bgColor = generateBrightTransparentColor(0.3);
          const textColor = darkenColor(bgColor);

          return {
            id: p.id,
            title: p.roles?.[0]?.name || p.title,
            activeFor: p.activeSince || "",
            subscription: p.tariffPlan,
            browsingCadence: p.browsingCadence,
            purchaseBehavior: p.purchaseBehaviour,
            gender: p.gender,
            age: moment().diff(p.birthDate, "years"),
            location: p.location,

            bgColor,
            textColor,
          };
        }
      );

      return personas;
    }

    return [];
  }, [timelinePersonasData]);

  const timelineEvents: Event[] = useMemo(() => {
    if (timelineData) {
      return timelineData.marketing.contextTimeline.events;
    }

    return [];
  }, [timelineData]);

  const timelineEventsByDate: EventByDate[] = useMemo(() => {
    if (!timelineData) {
      return [];
    }

    const eventsByDate = timelineData.marketing.contextTimeline.eventsByDate;
    const insightsByDate =
      timelineData.marketing.contextTimeline.insightsByDate;

    // get all the dates
    const allDates = new Set<string>([]);

    eventsByDate.forEach((eventByDate: any) => {
      allDates.add(eventByDate.date);
    });

    insightsByDate.forEach((insightByDate: any) => {
      allDates.add(insightByDate.date);
    });

    type ResultEntry = {
      date: string;
      events: EventWithInsights[];
    };

    const results: ResultEntry[] = [];

    // for each date, get the events and insights
    allDates.forEach((date) => {
      const events =
        eventsByDate.find((e: any) => e.date === date)?.events || [];
      const insights =
        insightsByDate.find((i: any) => i.date === date)?.insights || [];

      if (events.length) {
        results.push({
          date,
          events: events.map((e: any) => ({
            ...e,
            insights,
          })),
        });
      } else if (insights.length) {
        results.push({
          date,
          events: [
            {
              id: -1,
              insights,
              name: "",
            },
          ],
        });
      }
    });

    return results.sort((a, b) => {
      return moment(a.date).isBefore(b.date) ? -1 : 1;
    });
  }, [timelineData]);

  const insightsIdsByCurDate: number[] = useMemo(() => {
    if (!timelineData) {
      return [];
    }

    return timelineData.marketing.contextTimeline.insightsByDate
      .filter((i: any) => i.date === date)
      .map((i: any) => i.insights.map((insight: any) => insight.id))
      .flat();
  }, [timelineData, date]);

  const { data: insightsByCurDateData, loading: insightsByCurDateLoading } =
    useQuery(GET_INSIGHTS_BY_IDS, {
      variables: {
        appId,
        personaId,
        insightIds: insightsIdsByCurDate,
      },
      skip: !insightsIdsByCurDate.length,
    });

  const insightsByCurDate = useMemo(() => {
    if (insightsByCurDateData) {
      return insightsByCurDateData.marketing.contextTimeline.insights;
    }

    return [];
  }, [insightsByCurDateData]);

  const { data: selectedInsightData, loading: selectedInsightLoading } =
    useQuery(GET_MARKETING_INSIGHT_BY_ID_QUERY, {
      variables: {
        insightId,
      },
      skip: !insightId,
    });

  const {
    data: selectedInsightPersonas,
    loading: selectedInsightPersonasLoading,
  } = useQuery(GET_MARKETING_INSIGHT_PERSONAS_AND_FOCUS_AREA_QUERY, {
    variables: {
      insightId,
      appId,
    },
    skip: !insightId || !appId,
  });

  const selectedInsight = useMemo(() => {
    if (!selectedInsightPersonas || !selectedInsightData) return null;
    const insightData = selectedInsightData.insight.data;

    return {
      ...insightData,
      personas:
        selectedInsightPersonas?.marketing?.insightPage?.insights?.[0]
          ?.personas || [],
      focusArea:
        selectedInsightPersonas?.marketing?.insightPage?.insights?.[0]
          ?.focusArea || null,
    };
  }, [selectedInsightData, selectedInsightPersonas]);

  return {
    timelineApps,
    timelinePersonas,
    timelineEvents,
    timelineEventsByDate,
    insightsByCurDate,
    selectedInsight,

    isLoading:
      timelineAppsLoading ||
      timelinePersonasLoading ||
      timelineDataLoading ||
      insightsByCurDateLoading ||
      selectedInsightLoading ||
      selectedInsightPersonasLoading,
  };
};
