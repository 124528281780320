import React from "react";
import Nav2 from "../../components/Nav2/Nav2";

import TrackedProducts from "./TrackedProducts";

export default function Sidebar({
  siteSize,
  products,
  unSeenCriticalInsights,
}) {
  return (
    <div className="sidebar">
      <Nav2 siteSize={siteSize} />
      {/* <TrackedProducts
        products={products}
        unSeenCriticalInsights={unSeenCriticalInsights}
        siteSize={siteSize}
      /> */}
    </div>
  );
}
