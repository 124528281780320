import {
  takeLatest,
  call,
  put,
  fork,
  select,
  all
} from "redux-saga/effects";
import * as dashboardApi from "../api/dashboardApi";
import * as overviewApi from "../api/overviewApi";
import * as productApi from "../api/productApi";
import {
  GET_ALL_RELEVANT_DISCOVERIES,
  GET_RELEVANT_APPS_INSIGHTS,
  CURRENT_REQUESTS_ADD,
  CURRENT_REQUESTS_REMOVE,
  GET_MARKETING_CAMPAIGNS,
  GET_MARKETING_CAMPAIGNS_SUCCESS,
  GET_UNSEEN_CRITICAL_INSIGHTS,
  GET_UNSEEN_CRITICAL_INSIGHTS_PER_RELEASE,
  SET_ALL_INSIGHTS_VIEWED,
  GET_DISCOVERIES,
  GET_PRODUCTS,
  GET_PRODUCTS_STATS,
  SET_ALL_DISCOVERIES_AS_SEEN,
  GET_DISCOVERIES_SEARCH
} from "../actions/_actionsTypes";


import moment from "moment";

import {
  loaderAddRequest,
  loaderRemoveRequest
} from '../actions/dashboardActions'


import {
  getUnseenCriticalInsightsSuccess,
  getDiscoveriesSuccess,
  getDiscoveriesSearchSuccess,
  getProductsSuccess,
  getProductsStatsSuccess,
  setAllDiscoveriesForProductAsSeenSuccess,
} from '../actions/overviewActions'

import {
  getCompletedVersionsWithDiscoveriesSuccess,
  getBeingAnalyzedVersionsSuccess,
  getFeaturedDiscoveriesSuccess,
  getMarkedDiscoveriesSuccess,

  GET_COMPLETED_VERSIONS_WITH_DISCOVERIES,
  GET_BEING_ANALYZED_VERSIONS,
  GET_FEATURED_DISCOVERIES,
  GET_MARKED_DISCOVERIES
} from "../acts/overview"
import {MARKETING} from "../../containers/intelligence/Marketing/configsSubtypes";
import {PL_MARKETING} from "../../shared/consts";

const getApps = state => state.appData.apps

function* getMarketingCampaignsSaga(action) {
  const apps = yield select(getApps);

  yield put({
    type: CURRENT_REQUESTS_ADD,
    payload: 'getMarketingCampaigns'
  });

  const [marketingCampaignsData] = yield all([
    call(dashboardApi.getMarketingCampaignsAPI, action.payload)
  ]);


  const campaigns = {}
  let lastUpdate = 0;

  Object.values(marketingCampaignsData.data.data).forEach(disObj => {
    let dis = Object.values(disObj)[0]
    let mCampaignType = `${dis.type}s`
    const app = apps.find(app => app.metadata.id == dis.app_id)

    if (!campaigns[mCampaignType]) { campaigns[mCampaignType] = [] }
    campaigns[mCampaignType].push({
      app: app.metadata,
      cam: dis
    })

    if (moment(dis.created).valueOf() > moment(lastUpdate).valueOf()) {
      lastUpdate = dis.created
    }
  })

  yield put({
    type: GET_MARKETING_CAMPAIGNS_SUCCESS, payload: {
      ...campaigns,
      apps: apps.filter(app => app.metadata.platform === PL_MARKETING),
      lastUpdate
    }
  });

  yield put({
    type: CURRENT_REQUESTS_REMOVE,
    payload: 'getMarketingCampaigns'
  });
}


function* getRelevancedInsights(action) {
  const apps = yield select(getApps);

  if (!action.noLoader) {
    yield put(loaderAddRequest('getRelevancedInsights'))
  }

  const result = yield call(dashboardApi.getRelevancedInsights);

  const relevantInsights = result.data.data.map(dis => {
    const discovery = Object.values(dis)[0]
    return {
      metadata: apps.find(a => a.metadata.id == discovery.app_id).metadata,
      insight: discovery
    }
  })

  yield put({ type: GET_RELEVANT_APPS_INSIGHTS, payload: relevantInsights });

  if (!action.noLoader) {
    yield put(loaderRemoveRequest('getRelevancedInsights'))
  }
}

function* getUnseenCriticalInsights() {
  const apps = yield select(getApps);

  const result = yield call(overviewApi.getUnseenCriticalInsights)
  const relevantIdsPerApp = result.data

  Object.keys(relevantIdsPerApp).forEach(app_id => {
    const relevantIds = relevantIdsPerApp[app_id]
    const app = apps.find(app => app.metadata.id == app_id)
    let versionInsights = app ? [
      ...(app["ab_tests"] || []),
      ...(app["screen_changes"] || []),
      ...(app["indications"] || []),
      ...(app["new_features"] || []),
      ...(app["removed_features"] || []),
    ] : [];
    relevantIds.forEach((relID, i) => {
      let ins = versionInsights.find(item => item.id == relID.id)
      if (ins) {
        ins.relevancy = relevantIds[i].relevancy;

        relevantIds[i] = {
          insight: ins,
          insightId: ins.id,
          metadata: app.metadata,
          ...ins,
          ...app.metadata,
          ...relevantIds[i]
        }
      }
    })

  });




  yield put(getUnseenCriticalInsightsSuccess({
    unSeenCriticalInsights: relevantIdsPerApp
  }))
}

function* getDiscoveriesRequest(action) {
  const {
    gets
  } = action.payload;
  const result = yield call(overviewApi.getDiscoveries, gets)

  yield put(getDiscoveriesSuccess(result.data))
}

function* getDiscoveriesSearchRequest(action) {
  const {
    gets
  } = action.payload;
  const result = yield call(overviewApi.getDiscoveries, gets)

  yield put(getDiscoveriesSearchSuccess(result.data))
}

function* getProductsRequest() {
  const result = yield call(productApi.getProducts)
  yield put(getProductsSuccess(result.data))
}

function* getProductsStatsRequest(action) {
  const {
    productID
  } = action.payload;
  const result = yield call(productApi.getProductStats, productID)
  yield put(getProductsStatsSuccess(result.data))
}

function* setAllDisAsSeenForProduct(action) {
  const result = yield call(productApi.setAllDiscoveriesForProductAsSeen, action.payload)
  yield put(setAllDiscoveriesForProductAsSeenSuccess(result.data))
}


// release radar
function* getCompletedVersionsWithDiscoveriesSaga(action) {
  const result = yield call(overviewApi.getCompletedVersionsWithDiscoveriesAPI, action.payload)
  yield put(getCompletedVersionsWithDiscoveriesSuccess(result.data))
}
function* watchGetBeingAnalyzedVersionsSaga(action) {
  const result = yield call(overviewApi.getBeingAnalyzedVersionsAPI, action.payload)
  yield put(getBeingAnalyzedVersionsSuccess(result.data))
}

//FEATURED
function* getFeaturedDiscoveriesSaga(action) {
  const result = yield call(overviewApi.getFeaturedDiscoveriesAPI, action.payload)
  yield put(getFeaturedDiscoveriesSuccess(result.data))
}

//MARKED
function* getMarkedDiscoveriesSaga(action) {
  const result = yield call(overviewApi.getMarkedDiscoveriesAPI, action.payload)
  yield put(getMarkedDiscoveriesSuccess(result.data))
}

//Watchers

function* watchAllAppsVersionInfoSaga() {
  yield takeLatest(GET_ALL_RELEVANT_DISCOVERIES, getRelevancedInsights);
}

function* watchGetAllCampaign() {
  yield takeLatest(GET_MARKETING_CAMPAIGNS, getMarketingCampaignsSaga);
}

function* watchGetUnseenCriticalInsights() {
  yield takeLatest(GET_UNSEEN_CRITICAL_INSIGHTS, getUnseenCriticalInsights);
}


function* watchGetDiscoveries() {
  yield takeLatest(GET_DISCOVERIES, getDiscoveriesRequest);
}

function* watchGetDiscoveriesSearch() {
  yield takeLatest(GET_DISCOVERIES_SEARCH, getDiscoveriesSearchRequest);
}

function* watchGetProducts() {
  yield takeLatest(GET_PRODUCTS, getProductsRequest);
}

function* watchGetProductStats() {
  yield takeLatest(GET_PRODUCTS_STATS, getProductsStatsRequest);
}

function* watchSetAllDisAsSeen() {
  yield takeLatest(SET_ALL_DISCOVERIES_AS_SEEN, setAllDisAsSeenForProduct);
}

// For Release Radar on overview Page
function* watchgetCompletedVersionsWithDiscoveries() {
  yield takeLatest(GET_COMPLETED_VERSIONS_WITH_DISCOVERIES, getCompletedVersionsWithDiscoveriesSaga);
}

function* watchGetBeingAnalyzedVersions() {
  yield takeLatest(GET_BEING_ANALYZED_VERSIONS, watchGetBeingAnalyzedVersionsSaga);
}

function* watchGetFeaturedDiscoveries() {
  yield takeLatest(GET_FEATURED_DISCOVERIES, getFeaturedDiscoveriesSaga);
}

function* watchGetMarkedDiscoveries() {
  yield takeLatest(GET_MARKED_DISCOVERIES, getMarkedDiscoveriesSaga);
}

const overviewSagas = [
  fork(watchAllAppsVersionInfoSaga),
  fork(watchGetAllCampaign),
  fork(watchGetUnseenCriticalInsights),
  fork(watchGetDiscoveries),
  fork(watchGetDiscoveriesSearch),
  fork(watchGetProducts),
  fork(watchGetProductStats),
  fork(watchSetAllDisAsSeen),

  fork(watchgetCompletedVersionsWithDiscoveries),
  fork(watchGetBeingAnalyzedVersions),

  fork(watchGetFeaturedDiscoveries),
  fork(watchGetMarkedDiscoveries)

];

export default overviewSagas;
