
import React, { ReactElement } from 'react'
import { Button, Checkbox, Select, Radio, Tooltip } from 'antd';
import { INSIGHT_CAMPAIGN_SUBTYPE, UNSEEN, VIEWED, MEANINGFUL } from './configsSubtypes'
import { countries } from "../../../shared/countries";
export const MATCH_TYPES = ['Match Any', 'Match All']

interface IProps {
  filteredTypeChange: (args1: string[]) => void,
  filterStatusChange?: (args1: string[]) => void,
  filteredTagsChange: (args1: string[]) => void,
  tags: string[],
  filteredTags: string[],
  filteredType: string[],
  filterStatus?: string[],
  currentCampaignsDataSet: any,
  currentCampaignsSet?: any,
  showClearAllButton?: boolean,
  setMatchType: (arg0: string) => void,
  matchType: string,
  discoveriesData: any[],
}

const FilterTypes = ({
  filteredTypeChange,
  filterStatusChange,
  filteredTagsChange,
  filteredTags,
  filteredType,
  filterStatus,
  currentCampaignsDataSet,
  currentCampaignsSet,
  tags,
  showClearAllButton = true,
  setMatchType,
  matchType,
  discoveriesData,

}: IProps): ReactElement => {


  const clearAll = () => {
    filteredTypeChange && filteredTypeChange([]);
    filterStatusChange && filterStatusChange([]);
    filteredTagsChange && filteredTagsChange([]);
  }

  return (
    <div className="marketing-filter-type">
      {showClearAllButton &&
        <div className={'radio-buttons'}>
          <Radio.Group onChange={(e: any) => setMatchType(e.target.value)} value={matchType}>
            {MATCH_TYPES.map((type: String) => (<Tooltip title={type === MATCH_TYPES[0] ? 'Ex: Tag1 or Tag2 or...' : 'Ex: Tag1 and Tag2 and...'}>
              <Radio value={type}>{type}</Radio>
            </Tooltip>))}
          </Radio.Group>
          <Button onClick={clearAll} className="clear-filter-btn">Clear All Filters</Button>
        </div>}
      {filteredTagsChange &&
        <div>
          <h3>Tags</h3>
          <div>
            <Select
              style={{ width: '100%' }}
              mode="tags"
              //@ts-ignore
              onChange={(selected: any) => { filteredTagsChange(selected.filter(sel => tags.includes(sel))) }}
              tokenSeparators={[',']}
              placeholder="Start typing a relevant tag or choose one from the list"
              showArrow={true}
              dropdownRender={(menu) => <div className="filter-menu">{menu}</div>}
              value={filteredTags}
            >
              {tags.filter(tag => !filteredTags.includes(tag)).map(tag => (
                <Select.Option key={tag} value={tag}>{tag}</Select.Option>
              ))}
            </Select>
          </div>
        </div>
      }
      {filteredTypeChange && <div>
        <h3>Categories</h3>
        <div>

          <Checkbox.Group options={INSIGHT_CAMPAIGN_SUBTYPE.
            map(subType => ({
              //@ts-ignore
              label: (subType.label ? `${subType.label}` : `Without sub type`) + `(${currentCampaignsDataSet.filter(list => list.label == subType.label).reduce((acc, el) => acc + el.data.length, 0)})`,
              value: `${subType.label}`
            }))}
          value={filteredType}
          onChange={(selected: any) => {
            filteredTypeChange(selected)
          }} />
        </div>
        {filterStatus && <h3>Status</h3>}
        {filterStatus && <div>
          <Checkbox.Group options={[
            {
              //@ts-ignore
              label: `Unseen (${currentCampaignsSet.reduce((acc, ins) => {
                const thisDiscoveryData = discoveriesData.find(d=> ins.cam.id  === d.insight_id)
                return thisDiscoveryData && !thisDiscoveryData.read_at ? acc + 1 : acc}, 0
              )})`,
              value: UNSEEN
            },
            {
              //@ts-ignore
              label: `Viewed (${currentCampaignsSet.reduce((acc, ins) => {
                const thisDiscoveryData = discoveriesData.find(d=> ins.cam.id  === d.insight_id)
                return thisDiscoveryData && thisDiscoveryData.read_at ? acc + 1 : acc}, 0
              )})`,
              value: VIEWED
            },
            {
              //@ts-ignore
              label: `Most Meaningful (${currentCampaignsSet.reduce((acc, ins) => {
                const thisDiscoveryData = discoveriesData.find(d=> ins.cam.id  === d.insight_id)
                return thisDiscoveryData && thisDiscoveryData.relevancy > 0 ? acc + 1 : acc}, 0
              )})`,
              value: MEANINGFUL
            },
          ]}
          value={filterStatus} onChange={(selected: any) =>
          //@ts-ignore
            filterStatusChange(selected)} />
        </div>}
      </div>}
    </div >
  )
}

export default FilterTypes
