import { Product, App } from "../interfaces/Product";
import { PL_ANDROID, PL_CHROME, PL_IOS, PL_WEB, PL_WINDOWS } from "../shared/consts";

export const getExistingPlatforms = (product: Product) => {
  return [
    product.apps.find((app: App) => app.platform === PL_ANDROID && app.has_experiments),
    product.apps.find((app: App) => app.platform === PL_IOS && app.has_experiments),
    product.apps.find((app: App) => app.platform === PL_WEB && app.has_experiments),
    product.apps.find((app: App) => app.platform === PL_WINDOWS && app.has_experiments),
    product.apps.find((app: App) => app.platform === PL_CHROME && app.has_experiments)]
}

export const isMarketingForProduct = (product: Product) => {
  let isMarketing = false;
  product.apps.forEach((app: App) => {
    isMarketing = isMarketing || app.platform.toLowerCase() === 'marketing';
  });
  return isMarketing
}

export const isBenchmarkForProduct = (product: Product) => {
  let isPerformance = false;
  product.apps.forEach((app: App) => {
    isPerformance = isPerformance || app.has_performance;
  });
  return isPerformance
}
