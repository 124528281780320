import React from 'react'

import { Tooltip, Badge } from "antd";
import './Icon.scss'
export default function Icon({
  src,
  text,
  onClick,
  badge,
  countBadge,
  title
}) {

  const tooltipWrapper = (child) => text ?
    <Tooltip placement="top" title={text} mouseLeaveDelay={0} overlayClassName="product-name-tooltip">
      {badge ?
        <Badge count={countBadge} showZero={false} className={'white-badge'} >{child}</Badge>
        :
        child
      }
    </Tooltip> : child;

  return (

    <div className={`products-pr-container ${onClick ? 'add-hover' : ''} ${title ? 'with-title' : ''}`} onClick={onClick}>
      {tooltipWrapper(<img src={src} alt={text} className="icon" />)}
      {title && <div className='products-pr-title'>
        {title}
      </div>}
    </div>
  )
}